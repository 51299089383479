import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { TextInput, SwitchInput } from '../../Components';
import { createAPI, updateAPI, getListAllAPI } from '../../services';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {},
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [nome, setNome] = useState('');
  const [gerencialDashboard, setGerencialDashboard] = useState(false);
  const [gerencialVendas, setGerencialVendas] = useState(false);
  const [gerencialDevolucoes, setGerencialDevolucoes] = useState(false);
  const [gerencialPedidos, setGerencialPedidos] = useState(false);
  const [gerencialCompras, setGerencialCompras] = useState(false);
  const [gerencialClientes, setGerencialClientes] = useState(false);
  const [gerencialFornecedores, setGerencialFornecedores] = useState(false);
  const [gerencialAdms, setGerencialAdms] = useState(false);
  const [gerencialSetores, setGerencialSetores] = useState(false);
  const [gerencialConfig, setGerencialConfig] = useState(false);
  const [sistemaPerguntas, setSistemaPerguntas] = useState(false);
  const [sistemaCategoriasPerguntas, setSistemaCategoriasPerguntas] = useState(
    false,
  );
  const [produtoProdutos, setProdutoProdutos] = useState(false);
  const [produtoFamilias, setProdutoFamilias] = useState(false);
  const [produtoInventario, setProdutoInventario] = useState(false);
  const [produtoOfertas, setProdutoOfertas] = useState(false);
  const [produtoPrecosLiberados, setProdutoPrecosLiberados] = useState(false);
  const [produtoCategorias, setProdutoCategorias] = useState(false);
  const [produtoTarasBalanca, setProdutoTarasBalanca] = useState(false);
  const [produtoBaixar, setProdutoBaixar] = useState(false);
  const [produtoLocaisSetores, setProdutoLocaisSetores] = useState(false);
  const [produtoImpostos, setProdutoImpostos] = useState(false);
  const [produtoTabelaLucro, setProdutoTabelaLucro] = useState(false);
  const [
    conferenciasSugestaoProdutos,
    setConferenciasSugestaoProdutos,
  ] = useState(false);
  const [finaceiroDashboard, setFinanceiroDashboard] = useState(false);
  const [finaceiroSessoes, setFinanceiroSessoes] = useState(false);
  const [finaceiroContas, setFinanceiroContas] = useState(false);
  const [finaceiroCartoesCredito, setFinanceiroCartoesCredito] = useState(
    false,
  );
  const [finaceiroTransacoes, setFinanceiroTransacoes] = useState(false);
  const [finaceiroTelaReajustes, setFinanceiroTelaReajustes] = useState(false);
  const [finaceiroVerificacaoNfe, setFinanceiroVerificacaoNfe] = useState(
    false,
  );
  const [finaceiroPix, setFinanceiroPix] = useState(false);
  const [finaceiroFuturologia, setFinanceiroFuturologia] = useState(false);
  const [
    finaceiroTelaTransferencias,
    setFinanceiroTelaTransferencias,
  ] = useState(false);
  const [
    finaceiroTransacoesSimuladas,
    setFinanceiroTransacoesSimuladas,
  ] = useState(false);
  const [finaceiroVendasFaturadas, setFinaceiroVendasFaturadas] = useState(
    false,
  );
  const [televendaClientes, setTelevendaClientes] = useState(false);
  const [televendaAniversariantes, setTelevendaAniversariantes] = useState(
    false,
  );
  const [relatorioMensal, setRelatorioMensal] = useState(false);
  const [relatorioAnual, setRelatorioAnual] = useState(false);
  const [relatorioProdutos, setRelatorioProdutos] = useState(false);
  const [relatorioClientes, setRelatorioClientes] = useState(false);
  const [relatorioIndicadoresKPI, setRelatorioIndicadoresKPI] = useState(false);
  const [
    relatorioCadastramentoClientes,
    setRelatorioCadastramentoClientes,
  ] = useState(false);
  const [relatorioProdutosLocais, setRelatorioProdutosLocais] = useState(false);
  const [
    relatorioProdutosColaborador,
    setRelatorioProdutosColaborador,
  ] = useState(false);
  const [
    relatorioColaboradorFardamentos,
    setRelatorioColaboradorFardamentos,
  ] = useState(false);
  const [globalInventario, setGlobalInventario] = useState(false);
  const [globalRelatorioMensal, setGlobalRelatorioMensal] = useState(false);
  const [globalRelatorioAnual, setGlobalRelatorioAnual] = useState(false);
  const [globalRelatorioProdutos, setGlobalRelatorioProdutos] = useState(false);
  const [globalRelatorioClientes, setGlobalRelatorioClientes] = useState(false);
  const [
    globalRelatorioIndicadoresKPI,
    setGlobalRelatorioIndicadoresKPI,
  ] = useState(false);
  const [
    globalRelatorioCadastramentoClientes,
    setGlobalRelatorioCadastramentoClientes,
  ] = useState(false);
  const [
    globalRelatorioProdutosLocais,
    setGlobalRelatorioProdutosLocais,
  ] = useState(false);
  const [
    globalRelatorioProdutosColaborador,
    setGlobalRelatorioProdutosColaborador,
  ] = useState(false);
  const [
    globalRelatorioColaboradorFardamentos,
    setGlobalRelatorioColaboradorFardamentos,
  ] = useState(false);
  const [maisOpcoesMeiosPagamento, setMaisOpcoesMeiosPagamento] = useState(
    false,
  );
  const [maisOpcoesUnidades, setMaisOpcoesUnidades] = useState(false);
  const [
    maisOpcoesFuncaoFornecedoresFornecedores,
    setMaisOpcoesFuncaoFornecedoresFornecedores,
  ] = useState(false);
  const [
    maisOpcoesCategoriasTransacoes,
    setMaisOpcoesCategoriasTransacoes,
  ] = useState(false);
  const [
    maisOpcoesCategoriasClientes,
    setMaisOpcoesCategoriasClientes,
  ] = useState(false);
  const [maisOpcoesCargos, setMaisOpcoesCargos] = useState(false);
  const [
    maisOpcoesBancosFornecedores,
    setMaisOpcoesBancosFornecedores,
  ] = useState(false);
  const [contabilidadeColaboradores, setContabilidadeColaboradores] = useState(
    false,
  );
  const [
    contabilidadeFuncaoColaboradores,
    setContabilidadeFuncaoColaboradores,
  ] = useState(false);
  const [
    contabilidadeChecagemFardamentos,
    setContabilidadeChecagemFardamento,
  ] = useState(false);
  const [contabilidadeFardamento, setContabilidadeFardamento] = useState(false);
  const [
    contabilidadeTamanhoFardamento,
    setContabilidadeTamanhoFardamento,
  ] = useState(false);
  const [contabilidadeQualidades, setContabilidadeQualidades] = useState(false);
  const [contabilidadeDefeitos, setContabilidadeDefeitos] = useState(false);
  const [contabilidadeFeriados, setContabilidadeFeriados] = useState(false);
  const [
    contabilidadeDataComemorativas,
    setContabilidadeDataComemorativas,
  ] = useState(false);
  const [contabilidadeDescontos, setContabilidadeDescontos] = useState(false);
  const [
    contabilidadeCategoriasDescontos,
    setContabilidadeCategoriasDescontos,
  ] = useState(false);
  const [developmentFeatures, setDevelopmentFeatures] = useState(false);
  const [developmentVersoes, setDevelopmentVersoes] = useState(false);
  const [developmentSoftwares, setDevelopmentSoftwares] = useState(false);
  const [conferenciasEstoque, setConferenciasEstoque] = useState(false);
  const [conferenciasImpressoes, setConferenciasImpressoes] = useState(false);
  const [conferenciasFreezer, setConferenciasFreezer] = useState(false);
  const [equipamentoFreezer, setEquipamentoFreezer] = useState(false);
  const [insumosProdutos, setInsumosProdutos] = useState(false);
  const [insumosCompras, setInsumosCompras] = useState(false);
  const [maloteMalotes, setMaloteMalotes] = useState(false);
  const [maloteSubmalotes, setMaloteSubmalotes] = useState(false);
  const [maloteSangrias, setMaloteSangrias] = useState(false);
  const [maloteTrocos, setMaloteTrocos] = useState(false);
  const [maloteSobras, setMaloteSobras] = useState(false);
  const [maloteSinistros, setMaloteSinistros] = useState(false);
  const [maloteContaGerencial, setMaloteContaGerencial] = useState(false);
  const [
    sistemaCategoriasFrenteCaixa,
    setSistemaCategoriasFrenteCaixa,
  ] = useState(false);
  const [
    fornecimentoFornecedoresInsumo,
    setFornecimentoFornecedoresInsumo,
  ] = useState(false);
  const [
    fornecimentoFornecedoresServicos,
    setFornecimentoFornecedoresServicos,
  ] = useState(false);
  const [
    fornecimentoFornecedoresQualquer,
    setFornecimentoFornecedoresQualquer,
  ] = useState(false);
  const [sistemaCotacoesTemplates, setSistemaCotacoesTemplates] = useState(
    false,
  );
  const [relatorioCartoes, setRelatorioCartoes] = useState(false);
  const [relatorioFluxoCaixa, setRelatorioFluxoCaixa] = useState(false);
  const [relatorioScores, setRelatorioScores] = useState(false);
  const [
    sistemaCategoriasProblemasDespesas,
    setSistemaCategoriasProblemasDespesas,
  ] = useState(false);
  const [sistemaContasTabela, setSistemaContasTabela] = useState(false);
  const [
    sistemaTransacoesConferencias,
    setSistemaTransacoesConferencias,
  ] = useState(false);
  const [
    sistemaDespesasAgendamentos,
    setSistemaDespesasAgendamentos,
  ] = useState(false);
  const [sistemaCaixasFc, setSistemaCaixasFc] = useState(false);
  const [sistemaConferenciaCaixaria, setSistemaConferenciaCaixaria] = useState(
    false,
  );

  const [
    coletorSolicitacaoImpressao,
    setColetorSolicitacaoImpressao,
  ] = useState(false);
  const [
    coletorConferenciaTemperaturaFreezer,
    setColetorConferenciaTemperaturaFreezer,
  ] = useState(false);

  /////////////////////////////////

  const [sistemaGruposColaborador, setSistemaGruposColaborador] = useState(
    false,
  );

  const [sistemaEquipesColaborador, setSistemaEquipesColaborador] = useState(
    false,
  );

  const [sistemaCodinomes, setSistemaCodinomes] = useState(false);

  /////////////////

  const [pessoalTurnos, setPessoalTurnos] = useState(false);

  const [sistemaAtividadesProcesso, setSistemaAtividadesProcesso] = useState(
    false,
  );

  const [
    sistemaDepartamentosColaborador,
    setSistemaDepartamentosColaborador,
  ] = useState(false);

  /////////////////

  const [id, setId] = useState(DEFAULT_ID);
  const [carregando, setCarregando] = useState(false);
  const classes = useStyles({ cor: '#009FD4' });

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function reiniciar() {
    setId(DEFAULT_ID);
    setCarregando(false);
    setNome('');
    setGerencialDashboard(false);
    setGerencialVendas(false);
    setGerencialDevolucoes(false);
    setGerencialPedidos(false);
    setGerencialCompras(false);
    setGerencialConfig(false);
    setGerencialClientes(false);
    setGerencialFornecedores(false);
    setGerencialAdms(false);
    setGerencialSetores(false);
    setProdutoProdutos(false);
    setProdutoFamilias(false);
    setProdutoInventario(false);
    setProdutoOfertas(false);
    setProdutoPrecosLiberados(false);
    setProdutoCategorias(false);
    setProdutoTarasBalanca(false);
    setProdutoBaixar(false);
    setProdutoLocaisSetores(false);
    setProdutoImpostos(false);
    setProdutoTabelaLucro(false);
    setConferenciasSugestaoProdutos(false);
    setFinanceiroDashboard(false);
    setFinanceiroSessoes(false);
    setFinanceiroContas(false);
    setFinanceiroCartoesCredito(false);
    setFinanceiroTransacoes(false);
    setFinanceiroPix(false);
    setFinanceiroFuturologia(false);
    setFinanceiroVerificacaoNfe(false);
    setFinanceiroTelaTransferencias(false);
    setFinanceiroTelaReajustes(false);
    setFinanceiroTransacoesSimuladas(false);
    setFinaceiroVendasFaturadas(false);
    setTelevendaClientes(false);
    setTelevendaAniversariantes(false);
    setRelatorioMensal(false);
    setRelatorioAnual(false);
    setRelatorioProdutos(false);
    setRelatorioClientes(false);
    setRelatorioIndicadoresKPI(false);
    setRelatorioCadastramentoClientes(false);
    setRelatorioProdutosLocais(false);
    setRelatorioProdutosColaborador(false);
    setRelatorioColaboradorFardamentos(false);
    setGlobalInventario(false);
    setGlobalRelatorioAnual(false);
    setGlobalRelatorioCadastramentoClientes(false);
    setGlobalRelatorioClientes(false);
    setGlobalRelatorioColaboradorFardamentos(false);
    setGlobalRelatorioIndicadoresKPI(false);
    setGlobalRelatorioMensal(false);
    setGlobalRelatorioProdutos(false);
    setGlobalRelatorioProdutosColaborador(false);
    setGlobalRelatorioProdutosLocais(false);
    setMaisOpcoesMeiosPagamento(false);
    setMaisOpcoesUnidades(false);
    setMaisOpcoesCategoriasTransacoes(false);
    setMaisOpcoesCategoriasClientes(false);
    setMaisOpcoesCargos(false);
    setMaisOpcoesFuncaoFornecedoresFornecedores(false);
    setMaisOpcoesBancosFornecedores(false);
    setContabilidadeColaboradores(false);
    setContabilidadeFuncaoColaboradores(false);
    setContabilidadeChecagemFardamento(false);
    setContabilidadeFardamento(false);
    setContabilidadeTamanhoFardamento(false);
    setContabilidadeQualidades(false);
    setContabilidadeDefeitos(false);
    setContabilidadeFeriados(false);
    setContabilidadeCategoriasDescontos(false);
    setContabilidadeDescontos(false);
    setContabilidadeDataComemorativas(false);
    setDevelopmentFeatures(false);
    setDevelopmentVersoes(false);
    setDevelopmentSoftwares(false);
    setConferenciasEstoque(false);
    setConferenciasImpressoes(false);
    setConferenciasFreezer(false);
    setInsumosCompras(false);
    setInsumosProdutos(false);
    setSistemaCategoriasPerguntas(false);
    setSistemaPerguntas(false);
    setSistemaCategoriasFrenteCaixa(false);
    setMaloteMalotes(false);
    setMaloteSubmalotes(false);
    setMaloteSangrias(false);
    setMaloteTrocos(false);
    setMaloteSobras(false);
    setMaloteSinistros(false);
    setMaloteContaGerencial(false);
    setFornecimentoFornecedoresInsumo(false);
    setFornecimentoFornecedoresServicos(false);
    setFornecimentoFornecedoresQualquer(false);
    setSistemaCotacoesTemplates(false);
    setRelatorioCartoes(false);
    setRelatorioFluxoCaixa(false);
    setRelatorioScores(false);
    setSistemaCategoriasProblemasDespesas(false);
    setSistemaContasTabela(false);
    setSistemaTransacoesConferencias(false);
    setSistemaDespesasAgendamentos(false);
    setSistemaCaixasFc(false);
    setSistemaConferenciaCaixaria(false);
    setColetorSolicitacaoImpressao(false);
    setColetorConferenciaTemperaturaFreezer(false);
    setSistemaGruposColaborador(false);
    setSistemaEquipesColaborador(false);
    setSistemaCodinomes(false);
    setPessoalTurnos(false);
    setSistemaDepartamentosColaborador(false);
    setSistemaAtividadesProcesso(false);
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'cargos',
        ['id', 'asc'],
        { id: [id] },
        [],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setCarregando(false);
        setNome(data.data[0].nome);
        setGerencialDashboard(!!data.data[0].gerencial_dashboard);
        setGerencialVendas(!!data.data[0].gerencial_vendas);
        setGerencialDevolucoes(!!data.data[0].gerencial_devolucoes);
        setGerencialPedidos(!!data.data[0].gerencial_pedidos);
        setGerencialCompras(!!data.data[0].gerencial_compras);
        setGerencialClientes(!!data.data[0].gerencial_clientes);
        setGerencialFornecedores(!!data.data[0].gerencial_fornecedores);
        setGerencialAdms(!!data.data[0].gerencial_adms);
        setGerencialConfig(!!data.data[0].config);
        setGerencialSetores(!!data.data[0].gerencial_setores);
        setProdutoProdutos(!!data.data[0].produtos_produtos);
        setProdutoFamilias(!!data.data[0].produtos_familias);
        setProdutoInventario(!!data.data[0].produtos_inventario);
        setProdutoOfertas(!!data.data[0].produtos_ofertas);
        setProdutoPrecosLiberados(!!data.data[0].produtos_precos_liberados);
        setProdutoCategorias(!!data.data[0].produtos_categorias);
        setProdutoTarasBalanca(!!data.data[0].produtos_taras);
        setProdutoLocaisSetores(!!data.data[0].produtos_locais_setores);
        setProdutoBaixar(!!data.data[0].produtos_baixar);
        setProdutoImpostos(!!data.data[0].produtos_impostos);
        setProdutoTabelaLucro(!!data.data[0].produtos_tabela_lucro);
        setConferenciasSugestaoProdutos(
          !!data.data[0].conferencias_sugestao_produtos,
        );
        setFinanceiroDashboard(!!data.data[0].financeiro_dashboard);
        setFinanceiroSessoes(!!data.data[0].financeiro_sessoes);
        setFinanceiroContas(!!data.data[0].financeiro_contas);
        setFinanceiroCartoesCredito(!!data.data[0].financeiro_cartoes_credito);
        setFinanceiroTransacoes(!!data.data[0].financeiro_transacoes);
        setFinanceiroPix(!!data.data[0].financeiro_pix);
        setFinanceiroFuturologia(!!data.data[0].financeiro_futurologia);
        setFinanceiroVerificacaoNfe(!!data.data[0].financeiro_nfes);
        setFinanceiroTelaReajustes(!!data.data[0].financeiro_tela_reajustes);
        setFinanceiroTelaTransferencias(
          !!data.data[0].financeiro_tela_tranferencias,
        );
        setFinanceiroTransacoesSimuladas(
          !!data.data[0].financeiro_transacoes_simuladas,
        );
        setFinaceiroVendasFaturadas(!!data.data[0].financeiro_vendas_faturadas);
        setTelevendaClientes(!!data.data[0].televendas_clientes);
        setTelevendaAniversariantes(!!data.data[0].televendas_aniversariantes);
        setRelatorioMensal(!!data.data[0].relatorios_mensal);
        setRelatorioAnual(!!data.data[0].relatorios_anual);
        setRelatorioProdutos(!!data.data[0].relatorios_produtos);
        setRelatorioClientes(!!data.data[0].relatorios_clientes);
        setRelatorioIndicadoresKPI(!!data.data[0].relatorios_indicadores_kpi);
        setRelatorioCadastramentoClientes(
          !!data.data[0].relatorios_cadastramento_clientes,
        );
        setRelatorioProdutosLocais(!!data.data[0].relatorios_produtos_setores);
        setRelatorioProdutosColaborador(
          !!data.data[0].relatorios_produtos_colaborador,
        );
        setRelatorioColaboradorFardamentos(
          !!data.data[0].relatorio_colaborador_fardamento,
        );
        setGlobalInventario(!!data.data[0].global_inventario);
        setGlobalRelatorioAnual(!!data.data[0].global_relatorios_anual);
        setGlobalRelatorioCadastramentoClientes(
          !!data.data[0].global_relatorios_cadastro_clientes,
        );
        setGlobalRelatorioClientes(!!data.data[0].global_relatorios_clientes);
        setGlobalRelatorioColaboradorFardamentos(
          !!data.data[0].global_relatorio_colaborador_fardamento,
        );
        setGlobalRelatorioIndicadoresKPI(
          !!data.data[0].global_relatorios_indicadores_kpi,
        );
        setGlobalRelatorioMensal(!!data.data[0].global_relatorios_mensal);
        setGlobalRelatorioProdutos(!!data.data[0].global_relatorios_produtos);
        setGlobalRelatorioProdutosColaborador(
          !!data.data[0].global_relatorios_produtos_colaborador,
        );
        setGlobalRelatorioProdutosLocais(
          !!data.data[0].global_relatorios_produtos_local,
        );
        setMaisOpcoesMeiosPagamento(data.data[0].mais_opcoes_meios_pagamentos);
        setMaisOpcoesUnidades(!!data.data[0].mais_opcoes_unidades);
        setMaisOpcoesCategoriasTransacoes(
          !!data.data[0].mais_opcoes_categorias_transacoes,
        );
        setMaisOpcoesCategoriasClientes(
          !!data.data[0].mais_opcoes_categorias_clientes,
        );
        setMaisOpcoesCargos(!!data.data[0].mais_opcoes_cargos);
        setMaisOpcoesFuncaoFornecedoresFornecedores(
          !!data.data[0].mais_opcoes_funcao_funcionario_fornecedor,
        );
        setMaisOpcoesBancosFornecedores(
          !!data.data[0].mais_opcoes_banco_fornecedores,
        );
        setContabilidadeColaboradores(
          !!data.data[0].contabilidade_colaboradores,
        );
        setContabilidadeFuncaoColaboradores(
          !!data.data[0].contabilidade_funcoes_colaboradores,
        );
        setContabilidadeChecagemFardamento(
          !!data.data[0].contabilidade_checagem_fardamento,
        );
        setContabilidadeFardamento(!!data.data[0].contabilidade_fardamentos);
        setContabilidadeTamanhoFardamento(
          !!data.data[0].contabilidade_tamanho_fardamento,
        );
        setContabilidadeQualidades(!!data.data[0].contabilidade_qualidades);
        setContabilidadeDefeitos(!!data.data[0].contabilidade_defeitos);
        setContabilidadeFeriados(!!data.data[0].contabilidade_feriados);
        setContabilidadeDescontos(
          !!data.data[0].contabilidade_desconto_funcionario,
        );
        setContabilidadeCategoriasDescontos(
          !!data.data[0].contabilidade_categoria_desconto_funcionario,
        );
        setDevelopmentFeatures(!!data.data[0].development_features);
        setDevelopmentVersoes(!!data.data[0].development_versoes);
        setDevelopmentSoftwares(!!data.data[0].development_softwares);
        setConferenciasEstoque(!!data.data[0].conferencias_estoque);
        setConferenciasImpressoes(!!data.data[0].conferencias_impressoes);
        setConferenciasFreezer(!!data.data[0].conferencias_freezers);
        setEquipamentoFreezer(!!data.data[0].equipamentos_freezers);
        setInsumosProdutos(!!data.data[0].fornecimento_produtos_insumos);
        setInsumosCompras(!!data.data[0].fornecimento_compras_insumos);
        setSistemaPerguntas(!!data.data[0].sistema_pergunta);
        setSistemaCategoriasPerguntas(
          !!data.data[0].sistema_categoria_pergunta,
        );
        setMaloteMalotes(!!data.data[0].malote_malotes);
        setMaloteSubmalotes(!!data.data[0].malote_submalotes);
        setMaloteSangrias(!!data.data[0].malote_sangrias);
        setMaloteTrocos(!!data.data[0].malote_trocos);
        setMaloteSobras(!!data.data[0].malote_sobras);
        setMaloteSinistros(!!data.data[0].malote_sinistros);
        setMaloteContaGerencial(!!data.data[0].malote_conta_gerencial);
        setSistemaCategoriasFrenteCaixa(
          !!data.data[0].sistema_categoria_frente_caixa,
        );
        setFornecimentoFornecedoresInsumo(
          !!data.data[0].fornecimento_fornecedores_insumos,
        );
        setFornecimentoFornecedoresServicos(
          !!data.data[0].fornecimento_fornecedores_servicos,
        );
        setFornecimentoFornecedoresQualquer(
          !!data.data[0].fornecimento_fornecedores_qualquer,
        );
        setSistemaCotacoesTemplates(!!data.data[0].sistema_cotacoes_templates);
        setRelatorioCartoes(!!data.data[0].relatorios_relatorio_cartoes);
        setRelatorioFluxoCaixa(!!data.data[0].relatorios_relatorio_fluxo_caixa);
        setRelatorioScores(!!data.data[0].relatorios_relatorio_scores);
        setContabilidadeDataComemorativas(
          !!data.data[0].sistema_data_comemorativa,
        );
        setSistemaCategoriasProblemasDespesas(
          !!data.data[0].sistema_categorias_problemas_despesas,
        );
        setSistemaContasTabela(!!data.data[0].sistema_contas_tabela);
        setSistemaTransacoesConferencias(
          !!data.data[0].sistema_transacoes_conferencias,
        );
        setSistemaDespesasAgendamentos(
          !!data.data[0].sistema_despesas_agendamentos,
        );
        setSistemaCaixasFc(!!data.data[0].sistema_caixas_fc);
        setSistemaConferenciaCaixaria(
          !!data.data[0].sistema_conferencia_caixaria,
        );

        setColetorSolicitacaoImpressao(
          !!data.data[0].coletor_solicitacao_impressao,
        );
        setColetorConferenciaTemperaturaFreezer(
          !!data.data[0].coletor_conferencia_temperatura_freezer,
        );
        setSistemaGruposColaborador(!!data.data[0].sistema_grupo_colaborador);
        setSistemaEquipesColaborador(!!data.data[0].sistema_equipe_colaborador);
        setSistemaCodinomes(!!data.data[0].sistema_codinomes_colaborador);
        setPessoalTurnos(!!data.data[0].pessoal_turnos);
        setSistemaAtividadesProcesso(
          !!data.data[0].sistema_atividades_processos,
        );
        setSistemaDepartamentosColaborador(
          !!data.data[0].sistema_departamento_colaborador,
        );
        setCarregando(false);
      } else {
        throw 'Este cargo não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      if (id <= 0) {
        await createAPI('cargos', {
          nome,
          gerencial_dashboard: gerencialDashboard,
          gerencial_vendas: gerencialVendas,
          gerencial_devolucoes: gerencialDevolucoes,
          gerencial_pedidos: gerencialPedidos,
          gerencial_compras: gerencialCompras,
          gerencial_clientes: gerencialClientes,
          gerencial_fornecedores: gerencialFornecedores,
          gerencial_adms: gerencialAdms,
          gerencial_setores: gerencialSetores,
          config: gerencialConfig,
          produtos_produtos: produtoProdutos,
          produtos_familias: produtoFamilias,
          produtos_inventario: produtoInventario,
          produtos_ofertas: produtoOfertas,
          produtos_precos_liberados: produtoPrecosLiberados,
          produtos_categorias: produtoCategorias,
          produtos_taras: produtoTarasBalanca,
          produtos_baixar: produtoBaixar,
          produtos_locais_setores: produtoLocaisSetores,
          produtos_impostos: produtoImpostos,
          produtos_tabela_lucro: produtoTabelaLucro,
          conferencias_sugestao_produtos: conferenciasSugestaoProdutos,
          financeiro_dashboard: finaceiroDashboard,
          financeiro_sessoes: finaceiroSessoes,
          financeiro_contas: finaceiroContas,
          financeiro_cartoes_credito: finaceiroCartoesCredito,
          financeiro_transacoes: finaceiroTransacoes,
          financeiro_pix: finaceiroPix,
          financeiro_futurologia: finaceiroFuturologia,
          financeiro_nfes: finaceiroVerificacaoNfe,
          financeiro_tela_tranferencias: finaceiroTelaTransferencias,
          financeiro_tela_reajustes: finaceiroTelaReajustes,
          financeiro_transacoes_simuladas: finaceiroTransacoesSimuladas,
          financeiro_vendas_faturadas: finaceiroVendasFaturadas,
          televendas_clientes: televendaClientes,
          relatorios_mensal: relatorioMensal,
          relatorios_anual: relatorioAnual,
          relatorios_produtos: relatorioProdutos,
          relatorios_clientes: relatorioClientes,
          relatorios_indicadores_kpi: relatorioIndicadoresKPI,
          relatorios_cadastramento_clientes: relatorioCadastramentoClientes,
          relatorios_produtos_setores: relatorioProdutosLocais,
          relatorios_produtos_colaborador: relatorioProdutosColaborador,
          relatorio_colaborador_fardamento: relatorioColaboradorFardamentos,
          global_relatorios_mensal: globalRelatorioMensal,
          global_relatorios_anual: globalRelatorioAnual,
          global_relatorios_produtos: globalRelatorioProdutos,
          global_relatorios_clientes: globalRelatorioClientes,
          global_relatorios_indicadores_kpi: globalRelatorioIndicadoresKPI,
          global_relatorios_cadastro_clientes: globalRelatorioCadastramentoClientes,
          global_relatorios_produtos_local: globalRelatorioProdutosLocais,
          global_relatorios_produtos_colaborador: globalRelatorioProdutosColaborador,
          global_relatorio_colaborador_fardamento: globalRelatorioColaboradorFardamentos,
          global_inventario: globalInventario,
          mais_opcoes_meios_pagamentos: maisOpcoesMeiosPagamento,
          mais_opcoes_unidades: maisOpcoesUnidades,
          mais_opcoes_categorias_transacoes: maisOpcoesCategoriasTransacoes,
          mais_opcoes_categorias_clientes: maisOpcoesCategoriasClientes,
          mais_opcoes_cargos: maisOpcoesCargos,
          mais_opcoes_banco_fornecedores: maisOpcoesBancosFornecedores,
          mais_opcoes_funcao_funcionario_fornecedor: maisOpcoesFuncaoFornecedoresFornecedores,
          contabilidade_colaboradores: contabilidadeColaboradores,
          contabilidade_funcoes_colaboradores: contabilidadeFuncaoColaboradores,
          contabilidade_checagem_fardamento: contabilidadeChecagemFardamentos,
          contabilidade_fardamentos: contabilidadeFardamento,
          contabilidade_tamanho_fardamento: contabilidadeTamanhoFardamento,
          contabilidade_qualidades: contabilidadeQualidades,
          contabilidade_defeitos: contabilidadeDefeitos,
          contabilidade_feriados: contabilidadeFeriados,
          development_features: developmentFeatures,
          development_versoes: developmentVersoes,
          development_softwares: developmentSoftwares,
          conferencias_estoque: conferenciasEstoque,
          conferencias_impressoes: conferenciasImpressoes,
          conferencias_freezers: conferenciasFreezer,
          equipamentos_freezers: equipamentoFreezer,
          televendas_aniversariantes: televendaAniversariantes,
          fornecimento_produtos_insumos: insumosProdutos,
          fornecimento_compras_insumos: insumosCompras,
          sistema_pergunta: sistemaPerguntas,
          sistema_categoria_pergunta: sistemaCategoriasPerguntas,
          contabilidade_desconto_funcionario: contabilidadeDescontos,
          contabilidade_categoria_desconto_funcionario: contabilidadeCategoriasDescontos,
          malote_malotes: maloteMalotes,
          malote_submalotes: maloteSubmalotes,
          malote_sangrias: maloteSangrias,
          malote_trocos: maloteSangrias,
          malote_sobras: maloteSobras,
          malote_sinistros: maloteSinistros,
          malote_conta_gerencial: maloteContaGerencial,
          sistema_categoria_frente_caixa: sistemaCategoriasFrenteCaixa,
          fornecimento_fornecedores_insumos: fornecimentoFornecedoresInsumo,
          fornecimento_fornecedores_servicos: fornecimentoFornecedoresServicos,
          fornecimento_fornecedores_qualquer: fornecimentoFornecedoresQualquer,
          sistema_cotacoes_templates: sistemaCotacoesTemplates,
          relatorios_relatorio_cartoes: relatorioCartoes,
          relatorios_relatorio_fluxo_caixa: relatorioFluxoCaixa,
          relatorios_relatorio_scores: relatorioScores,
          sistema_data_comemorativa: contabilidadeDataComemorativas,
          sistema_categorias_problemas_despesas: sistemaCategoriasProblemasDespesas,
          sistema_contas_tabela: sistemaContasTabela,
          sistema_transacoes_conferencias: sistemaTransacoesConferencias,
          sistema_despesas_agendamentos: sistemaDespesasAgendamentos,
          sistema_caixas_fc: sistemaCaixasFc,
          sistema_conferencia_caixaria: sistemaConferenciaCaixaria,
          coletor_solicitacao_impressao: coletorSolicitacaoImpressao,
          coletor_conferencia_temperatura_freezer: coletorConferenciaTemperaturaFreezer,
          sistema_grupo_colaborador: sistemaGruposColaborador,
          sistema_equipe_colaborador: sistemaEquipesColaborador,
          sistema_codinomes_colaborador: sistemaCodinomes,
          pessoal_turnos: pessoalTurnos,
          sistema_atividades_processos: sistemaAtividadesProcesso,
          sistema_departamento_colaborador: sistemaDepartamentosColaborador,
        });
        setCarregando(false);
        enqueueSnackbar('Cargo criado com sucesso!', {
          variant: 'success',
        });
      } else {
        await updateAPI('cargos', id, {
          nome,
          gerencial_dashboard: gerencialDashboard,
          gerencial_vendas: gerencialVendas,
          gerencial_devolucoes: gerencialDevolucoes,
          gerencial_pedidos: gerencialPedidos,
          gerencial_compras: gerencialCompras,
          gerencial_clientes: gerencialClientes,
          gerencial_fornecedores: gerencialFornecedores,
          gerencial_adms: gerencialAdms,
          gerencial_setores: gerencialSetores,
          config: gerencialConfig,
          produtos_produtos: produtoProdutos,
          produtos_familias: produtoFamilias,
          produtos_inventario: produtoInventario,
          produtos_ofertas: produtoOfertas,
          produtos_precos_liberados: produtoPrecosLiberados,
          produtos_categorias: produtoCategorias,
          produtos_taras: produtoTarasBalanca,
          produtos_baixar: produtoBaixar,
          produtos_locais_setores: produtoLocaisSetores,
          produtos_impostos: produtoImpostos,
          produtos_tabela_lucro: produtoTabelaLucro,
          conferencias_sugestao_produtos: conferenciasSugestaoProdutos,
          financeiro_dashboard: finaceiroDashboard,
          financeiro_sessoes: finaceiroSessoes,
          financeiro_contas: finaceiroContas,
          financeiro_cartoes_credito: finaceiroCartoesCredito,
          financeiro_transacoes: finaceiroTransacoes,
          financeiro_pix: finaceiroPix,
          financeiro_futurologia: finaceiroFuturologia,
          financeiro_nfes: finaceiroVerificacaoNfe,
          financeiro_tela_reajustes: finaceiroTelaReajustes,
          financeiro_tela_tranferencias: finaceiroTelaTransferencias,
          financeiro_transacoes_simuladas: finaceiroTransacoesSimuladas,
          financeiro_vendas_faturadas: finaceiroVendasFaturadas,
          televendas_clientes: televendaClientes,
          relatorios_mensal: relatorioMensal,
          relatorios_anual: relatorioAnual,
          relatorios_produtos: relatorioProdutos,
          relatorios_clientes: relatorioClientes,
          relatorios_indicadores_kpi: relatorioIndicadoresKPI,
          relatorios_cadastramento_clientes: relatorioCadastramentoClientes,
          relatorios_produtos_setores: relatorioProdutosLocais,
          relatorios_produtos_colaborador: relatorioProdutosColaborador,
          relatorio_colaborador_fardamento: relatorioColaboradorFardamentos,
          global_relatorios_mensal: globalRelatorioMensal,
          global_relatorios_anual: globalRelatorioAnual,
          global_relatorios_produtos: globalRelatorioProdutos,
          global_relatorios_clientes: globalRelatorioClientes,
          global_relatorios_indicadores_kpi: globalRelatorioIndicadoresKPI,
          global_relatorios_cadastro_clientes: globalRelatorioCadastramentoClientes,
          global_relatorios_produtos_local: globalRelatorioProdutosLocais,
          global_relatorios_produtos_colaborador: globalRelatorioProdutosColaborador,
          global_relatorio_colaborador_fardamento: globalRelatorioColaboradorFardamentos,
          global_inventario: globalInventario,
          mais_opcoes_meios_pagamentos: maisOpcoesMeiosPagamento,
          mais_opcoes_unidades: maisOpcoesUnidades,
          mais_opcoes_categorias_transacoes: maisOpcoesCategoriasTransacoes,
          mais_opcoes_categorias_clientes: maisOpcoesCategoriasClientes,
          mais_opcoes_cargos: maisOpcoesCargos,
          mais_opcoes_banco_fornecedores: maisOpcoesBancosFornecedores,
          mais_opcoes_funcao_funcionario_fornecedor: maisOpcoesFuncaoFornecedoresFornecedores,
          contabilidade_colaboradores: contabilidadeColaboradores,
          contabilidade_funcoes_colaboradores: contabilidadeFuncaoColaboradores,
          contabilidade_checagem_fardamento: contabilidadeChecagemFardamentos,
          contabilidade_fardamentos: contabilidadeFardamento,
          contabilidade_tamanho_fardamento: contabilidadeTamanhoFardamento,
          contabilidade_qualidades: contabilidadeQualidades,
          contabilidade_defeitos: contabilidadeDefeitos,
          contabilidade_feriados: contabilidadeFeriados,
          development_features: developmentFeatures,
          development_versoes: developmentVersoes,
          development_softwares: developmentSoftwares,
          conferencias_estoque: conferenciasEstoque,
          conferencias_impressoes: conferenciasImpressoes,
          conferencias_freezers: conferenciasFreezer,
          equipamentos_freezers: equipamentoFreezer,
          televendas_aniversariantes: televendaAniversariantes,
          fornecimento_produtos_insumos: insumosProdutos,
          fornecimento_compras_insumos: insumosCompras,
          sistema_pergunta: sistemaPerguntas,
          sistema_categoria_pergunta: sistemaCategoriasPerguntas,
          contabilidade_desconto_funcionario: contabilidadeDescontos,
          contabilidade_categoria_desconto_funcionario: contabilidadeCategoriasDescontos,
          malote_malotes: maloteMalotes,
          malote_submalotes: maloteSubmalotes,
          malote_sangrias: maloteSangrias,
          malote_trocos: maloteSangrias,
          malote_sobras: maloteSobras,
          malote_sinistros: maloteSinistros,
          malote_conta_gerencial: maloteContaGerencial,
          sistema_categoria_frente_caixa: sistemaCategoriasFrenteCaixa,
          fornecimento_fornecedores_insumos: fornecimentoFornecedoresInsumo,
          fornecimento_fornecedores_servicos: fornecimentoFornecedoresServicos,
          fornecimento_fornecedores_qualquer: fornecimentoFornecedoresQualquer,
          sistema_cotacoes_templates: sistemaCotacoesTemplates,
          relatorios_relatorio_cartoes: relatorioCartoes,
          relatorios_relatorio_fluxo_caixa: relatorioFluxoCaixa,
          relatorios_relatorio_scores: relatorioScores,
          sistema_data_comemorativa: contabilidadeDataComemorativas,
          sistema_categorias_problemas_despesas: sistemaCategoriasProblemasDespesas,
          sistema_contas_tabela: sistemaContasTabela,
          sistema_transacoes_conferencias: sistemaTransacoesConferencias,
          sistema_despesas_agendamentos: sistemaDespesasAgendamentos,
          sistema_caixas_fc: sistemaCaixasFc,
          sistema_conferencia_caixaria: sistemaConferenciaCaixaria,
          coletor_solicitacao_impressao: coletorSolicitacaoImpressao,
          coletor_conferencia_temperatura_freezer: coletorConferenciaTemperaturaFreezer,
          sistema_grupo_colaborador: sistemaGruposColaborador,
          sistema_equipe_colaborador: sistemaEquipesColaborador,
          sistema_codinomes_colaborador: sistemaCodinomes,
          pessoal_turnos: pessoalTurnos,
          sistema_atividades_processos: sistemaAtividadesProcesso,
          sistema_departamento_colaborador: sistemaDepartamentosColaborador,
        });
        setCarregando(false);
        enqueueSnackbar('Cargo atualizado com sucesso!', {
          variant: 'success',
        });
      }
      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      reiniciar();
      setOpen(true);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullScreen
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {id < 0 ? `Novo Cargo` : `Editar Cargo`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <div>
                <div>
                  <TextInput
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    label="Descrição"
                  />
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Pessoal
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Escalas"
                      checked={pessoalTurnos}
                      onChange={(e) => setPessoalTurnos(e.target.checked)}
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Vendas
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Vendas"
                      checked={gerencialVendas}
                      onChange={(e) => setGerencialVendas(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Devoluções"
                      checked={gerencialDevolucoes}
                      onChange={(e) => setGerencialDevolucoes(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Pedidos"
                      checked={gerencialPedidos}
                      onChange={(e) => setGerencialPedidos(e.target.checked)}
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Fornecimento
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Compras"
                      checked={gerencialCompras}
                      onChange={(e) => setGerencialCompras(e.target.checked)}
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Fornecedores Mercadorias"
                      checked={gerencialFornecedores}
                      onChange={(e) =>
                        setGerencialFornecedores(e.target.checked)
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Bancos Fornecedores"
                      checked={maisOpcoesBancosFornecedores}
                      onChange={(e) =>
                        setMaisOpcoesBancosFornecedores(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Funções Funcionários Fornecedores"
                      checked={maisOpcoesFuncaoFornecedoresFornecedores}
                      onChange={(e) =>
                        setMaisOpcoesFuncaoFornecedoresFornecedores(
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Fornecedores Insumos"
                      checked={fornecimentoFornecedoresInsumo}
                      onChange={(e) =>
                        setFornecimentoFornecedoresInsumo(e.target.checked)
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Fornecedores Serviços"
                      checked={fornecimentoFornecedoresServicos}
                      onChange={(e) =>
                        setFornecimentoFornecedoresServicos(e.target.checked)
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Outros Contatos"
                      checked={fornecimentoFornecedoresQualquer}
                      onChange={(e) =>
                        setFornecimentoFornecedoresQualquer(e.target.checked)
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Itens de Venda
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Produtos"
                      checked={produtoProdutos}
                      onChange={(e) => setProdutoProdutos(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Famílias"
                      checked={produtoFamilias}
                      onChange={(e) => setProdutoFamilias(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Ofertas"
                      checked={produtoOfertas}
                      onChange={(e) => setProdutoOfertas(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Preços Liberados"
                      checked={produtoPrecosLiberados}
                      onChange={(e) =>
                        setProdutoPrecosLiberados(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Categorias"
                      checked={produtoCategorias}
                      onChange={(e) => setProdutoCategorias(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Baixar"
                      checked={produtoBaixar}
                      onChange={(e) => setProdutoBaixar(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Locais"
                      checked={produtoLocaisSetores}
                      onChange={(e) =>
                        setProdutoLocaisSetores(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Taras Balança"
                      checked={produtoTarasBalanca}
                      onChange={(e) => setProdutoTarasBalanca(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Impostos Produtos"
                      checked={produtoImpostos}
                      onChange={(e) => setProdutoImpostos(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Tabela Lucro"
                      checked={produtoTabelaLucro}
                      onChange={(e) => setProdutoTabelaLucro(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Sugestão Produtos"
                      checked={conferenciasSugestaoProdutos}
                      onChange={(e) =>
                        setConferenciasSugestaoProdutos(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Setores"
                      checked={gerencialSetores}
                      onChange={(e) => setGerencialSetores(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Cotações Templates"
                      checked={sistemaCotacoesTemplates}
                      onChange={(e) =>
                        setSistemaCotacoesTemplates(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Conferências Caixarias"
                      checked={sistemaConferenciaCaixaria}
                      onChange={(e) =>
                        setSistemaConferenciaCaixaria(e.target.checked)
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Financeiro
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Dashboard"
                      checked={finaceiroDashboard}
                      onChange={(e) => setFinanceiroDashboard(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Sessões"
                      checked={finaceiroSessoes}
                      onChange={(e) => setFinanceiroSessoes(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Contas"
                      checked={finaceiroContas}
                      onChange={(e) => setFinanceiroContas(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Caixas"
                      checked={sistemaCaixasFc}
                      onChange={(e) => setSistemaCaixasFc(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Cartões de Crédito"
                      checked={finaceiroCartoesCredito}
                      onChange={(e) =>
                        setFinanceiroCartoesCredito(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Transações"
                      checked={finaceiroTransacoes}
                      onChange={(e) =>
                        setFinanceiroTransacoes(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Pix"
                      checked={finaceiroPix}
                      onChange={(e) => setFinanceiroPix(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Futurologia"
                      checked={finaceiroFuturologia}
                      onChange={(e) =>
                        setFinanceiroFuturologia(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Verificação Nfe"
                      checked={finaceiroVerificacaoNfe}
                      onChange={(e) =>
                        setFinanceiroVerificacaoNfe(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Transferências"
                      checked={finaceiroTelaTransferencias}
                      onChange={(e) =>
                        setFinanceiroTelaTransferencias(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Reajustes"
                      checked={finaceiroTelaReajustes}
                      onChange={(e) =>
                        setFinanceiroTelaReajustes(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Categorias Transações"
                      checked={maisOpcoesCategoriasTransacoes}
                      onChange={(e) =>
                        setMaisOpcoesCategoriasTransacoes(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Transações Simuladas"
                      checked={finaceiroTransacoesSimuladas}
                      onChange={(e) =>
                        setFinanceiroTransacoesSimuladas(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Vendas Faturadas"
                      checked={finaceiroVendasFaturadas}
                      onChange={(e) =>
                        setFinaceiroVendasFaturadas(e.target.checked)
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Contas Tabela"
                      checked={sistemaContasTabela}
                      onChange={(e) => setSistemaContasTabela(e.target.checked)}
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Transações Conferências"
                      checked={sistemaTransacoesConferencias}
                      onChange={(e) =>
                        setSistemaTransacoesConferencias(e.target.checked)
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Despesas Agendamentos"
                      checked={sistemaDespesasAgendamentos}
                      onChange={(e) =>
                        setSistemaDespesasAgendamentos(e.target.checked)
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Malotes
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Malotes"
                      checked={maloteMalotes}
                      onChange={(e) => setMaloteMalotes(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Submalotes"
                      checked={maloteSubmalotes}
                      onChange={(e) => setMaloteSubmalotes(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Sangrias"
                      checked={maloteSangrias}
                      onChange={(e) => setMaloteSangrias(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Troco"
                      checked={maloteTrocos}
                      onChange={(e) => setMaloteTrocos(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Sobras de caixas"
                      checked={maloteSobras}
                      onChange={(e) => setMaloteSobras(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Sinistros"
                      checked={maloteSinistros}
                      onChange={(e) => setMaloteSinistros(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Conta Gerencial"
                      checked={maloteContaGerencial}
                      onChange={(e) =>
                        setMaloteContaGerencial(e.target.checked)
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Televendas
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Clientes"
                      checked={gerencialClientes}
                      onChange={(e) => setGerencialClientes(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Categorias Clientes"
                      checked={maisOpcoesCategoriasClientes}
                      onChange={(e) =>
                        setMaisOpcoesCategoriasClientes(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Aniversariantes"
                      checked={televendaAniversariantes}
                      onChange={(e) =>
                        setTelevendaAniversariantes(e.target.checked)
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Processos
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Atividades"
                      checked={sistemaAtividadesProcesso}
                      onChange={(e) =>
                        setSistemaAtividadesProcesso(e.target.checked)
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Relatório
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Mensal"
                      checked={relatorioMensal}
                      onChange={(e) => setRelatorioMensal(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Anual"
                      checked={relatorioAnual}
                      onChange={(e) => setRelatorioAnual(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Produtos"
                      checked={relatorioProdutos}
                      onChange={(e) => setRelatorioProdutos(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Clientes"
                      checked={relatorioClientes}
                      onChange={(e) => setRelatorioClientes(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Indicadores KPI"
                      checked={relatorioIndicadoresKPI}
                      onChange={(e) =>
                        setRelatorioIndicadoresKPI(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Inventário"
                      checked={produtoInventario}
                      onChange={(e) => setProdutoInventario(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Cadastro Clientes"
                      checked={relatorioCadastramentoClientes}
                      onChange={(e) =>
                        setRelatorioCadastramentoClientes(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Produtos de um local"
                      checked={relatorioProdutosLocais}
                      onChange={(e) =>
                        setRelatorioProdutosLocais(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Produtos de um colaborador"
                      checked={relatorioProdutosColaborador}
                      onChange={(e) =>
                        setRelatorioProdutosColaborador(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Fardamentos Colaboradores"
                      checked={relatorioColaboradorFardamentos}
                      onChange={(e) =>
                        setRelatorioColaboradorFardamentos(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Cartões"
                      checked={relatorioCartoes}
                      onChange={(e) => setRelatorioCartoes(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Fluxo de Caixa"
                      checked={relatorioFluxoCaixa}
                      onChange={(e) => setRelatorioFluxoCaixa(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Scores"
                      checked={relatorioScores}
                      onChange={(e) => setRelatorioScores(e.target.checked)}
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Global
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Relatório Mensal"
                      checked={globalRelatorioMensal}
                      onChange={(e) =>
                        setGlobalRelatorioMensal(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Relatório Anual"
                      checked={globalRelatorioAnual}
                      onChange={(e) =>
                        setGlobalRelatorioAnual(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Relatório Produtos"
                      checked={globalRelatorioProdutos}
                      onChange={(e) =>
                        setGlobalRelatorioProdutos(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Relatório Clientes"
                      checked={globalRelatorioClientes}
                      onChange={(e) =>
                        setGlobalRelatorioClientes(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Indicadores KPI"
                      checked={globalRelatorioIndicadoresKPI}
                      onChange={(e) =>
                        setGlobalRelatorioIndicadoresKPI(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Inventário"
                      checked={globalInventario}
                      onChange={(e) => setGlobalInventario(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Cadastro Clientes"
                      checked={globalRelatorioCadastramentoClientes}
                      onChange={(e) =>
                        setGlobalRelatorioCadastramentoClientes(
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Produtos de um local"
                      checked={globalRelatorioProdutosLocais}
                      onChange={(e) =>
                        setGlobalRelatorioProdutosLocais(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Produtos de um colaborador"
                      checked={globalRelatorioProdutosColaborador}
                      onChange={(e) =>
                        setGlobalRelatorioProdutosColaborador(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Fardamentos Colaboradores"
                      checked={globalRelatorioColaboradorFardamentos}
                      onChange={(e) =>
                        setGlobalRelatorioColaboradorFardamentos(
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Sistema
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Meios de Pagamento"
                      checked={maisOpcoesMeiosPagamento}
                      onChange={(e) =>
                        setMaisOpcoesMeiosPagamento(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Unidades"
                      checked={maisOpcoesUnidades}
                      onChange={(e) => setMaisOpcoesUnidades(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Cargos"
                      checked={maisOpcoesCargos}
                      onChange={(e) => setMaisOpcoesCargos(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Usuários"
                      checked={gerencialAdms}
                      onChange={(e) => setGerencialAdms(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Configurações"
                      checked={gerencialConfig}
                      onChange={(e) => setGerencialConfig(e.target.checked)}
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Perguntas"
                      checked={sistemaPerguntas}
                      onChange={(e) => setSistemaPerguntas(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Categorias Perguntas"
                      checked={sistemaCategoriasPerguntas}
                      onChange={(e) =>
                        setSistemaCategoriasPerguntas(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Categorias Caixas"
                      checked={sistemaCategoriasFrenteCaixa}
                      onChange={(e) =>
                        setSistemaCategoriasFrenteCaixa(e.target.checked)
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Categorias Problemas Despesas"
                      checked={sistemaCategoriasProblemasDespesas}
                      onChange={(e) =>
                        setSistemaCategoriasProblemasDespesas(e.target.checked)
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Contabilidade
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Colaboradores"
                      checked={contabilidadeColaboradores}
                      onChange={(e) =>
                        setContabilidadeColaboradores(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Funções Colaborador"
                      checked={contabilidadeFuncaoColaboradores}
                      onChange={(e) =>
                        setContabilidadeFuncaoColaboradores(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Fardamentos"
                      checked={contabilidadeFardamento}
                      onChange={(e) =>
                        setContabilidadeFardamento(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Tamanho Fardamentos"
                      checked={contabilidadeTamanhoFardamento}
                      onChange={(e) =>
                        setContabilidadeTamanhoFardamento(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Qualidades"
                      checked={contabilidadeQualidades}
                      onChange={(e) =>
                        setContabilidadeQualidades(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Defeitos"
                      checked={contabilidadeDefeitos}
                      onChange={(e) =>
                        setContabilidadeDefeitos(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Checagens Fardamentos"
                      checked={contabilidadeChecagemFardamentos}
                      onChange={(e) =>
                        setContabilidadeChecagemFardamento(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Feriados"
                      checked={contabilidadeFeriados}
                      onChange={(e) =>
                        setContabilidadeFeriados(e.target.checked)
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Descontos"
                      checked={contabilidadeDescontos}
                      onChange={(e) =>
                        setContabilidadeDescontos(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Categorias Descontos"
                      checked={contabilidadeCategoriasDescontos}
                      onChange={(e) =>
                        setContabilidadeCategoriasDescontos(e.target.checked)
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Grupos Colaborador"
                      checked={sistemaGruposColaborador}
                      onChange={(e) =>
                        setSistemaGruposColaborador(e.target.checked)
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Equipes Colaborador"
                      checked={sistemaEquipesColaborador}
                      onChange={(e) =>
                        setSistemaEquipesColaborador(e.target.checked)
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Codinomes"
                      checked={sistemaCodinomes}
                      onChange={(e) => setSistemaCodinomes(e.target.checked)}
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Departamentos"
                      checked={sistemaDepartamentosColaborador}
                      onChange={(e) =>
                        setSistemaDepartamentosColaborador(e.target.checked)
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Development
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Features"
                      checked={developmentFeatures}
                      onChange={(e) => setDevelopmentFeatures(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Software"
                      checked={developmentSoftwares}
                      onChange={(e) =>
                        setDevelopmentSoftwares(e.target.checked)
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Conferências
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Freezers"
                      checked={conferenciasFreezer}
                      onChange={(e) => setConferenciasFreezer(e.target.checked)}
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Equipamentos
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Freezers"
                      checked={equipamentoFreezer}
                      onChange={(e) => setEquipamentoFreezer(e.target.checked)}
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Coletor
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Solicitações Impressão Etiquetas"
                      checked={coletorSolicitacaoImpressao}
                      onChange={(e) =>
                        setColetorSolicitacaoImpressao(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Conferência Temperatura Freezer"
                      checked={coletorConferenciaTemperaturaFreezer}
                      onChange={(e) =>
                        setColetorConferenciaTemperaturaFreezer(
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <Button
              onClick={handleCloseDialog}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
            >
              Cancelar
            </Button>
          </div>
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
