import React, { useState, useRef, useEffect } from 'react';
import { Title } from 'react-admin';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AssignmentIcon from '@material-ui/icons/Assignment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import moment from 'moment';
import PropTypes from 'prop-types'; // ES6

import { api } from '../../../services';
import DateImputMobills from './DateMobillsInput';
import DialogoPDF from './DialogoPDF';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '20px',
  },
  rowHead: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  rowHead2: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  rowResumo: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  negativo: {
    backgroundColor: '#ffc7cf',
  },
  positivo: {
    backgroundColor: '#cbf5d0',
  },
}));

function formatMoeda(valor) {
  return valor.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(true);
  const classes = useRowStyles();
  const classes2 = useStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {`${row.username}: ${row.clientes.length} clientes cadastrados`}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow className={classes2.rowHead2}>
                    <TableCell className={classes2.rowHead2}>ID</TableCell>
                    <TableCell className={classes2.rowHead2}>Cliente</TableCell>
                    <TableCell className={classes2.rowHead2} align="right">
                      Nome Fantasia
                    </TableCell>
                    <TableCell className={classes2.rowHead2} align="right">
                      Razão Social
                    </TableCell>
                    <TableCell className={classes2.rowHead2} align="right">
                      Cidade
                    </TableCell>
                    <TableCell className={classes2.rowHead2} align="right">
                      Bairro
                    </TableCell>
                    <TableCell className={classes2.rowHead2} align="right">
                      Categoria
                    </TableCell>
                    <TableCell className={classes2.rowHead2} align="right">
                      Hora Cadas.
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.clientes.map((historyRow) => (
                    <TableRow key={historyRow.nome}>
                      <TableCell component="th" scope="row">
                        {historyRow.id}
                      </TableCell>
                      <TableCell align="right">{historyRow.nome}</TableCell>
                      <TableCell align="right">
                        {historyRow.nome_fantasia}
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.razao_social}
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.cidade ? historyRow.cidade.nome : ''}
                      </TableCell>
                      <TableCell align="right">{historyRow.bairro}</TableCell>
                      <TableCell align="right">
                        {historyRow.categoria ? historyRow.categoria.nome : ''}
                      </TableCell>
                      <TableCell align="right">
                        {moment(historyRow.created_at).format(
                          'DD/MM/YYYY HH:mm',
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const mesAtual = new Date().getMonth();
const anoAtual = new Date().getFullYear();

export default function CollapsibleTable() {
  const [data, setData] = useState([]);
  const classes = useStyles();
  const [periodo, setPeriodo] = useState({ mes: mesAtual, ano: anoAtual });
  const refDialogoInventario = useRef(null);

  function handleClick() {
    if (refDialogoInventario.current) {
      refDialogoInventario.current.handleOpen();
    }
  }

  useEffect(() => {
    async function getData() {
      const data = await api.get(
        `/relatorio_clientes_cadastramento/${periodo.mes}/${periodo.ano}`,
      );
      setData(data.data);
    }

    getData();
  }, [periodo]);

  return (
    <>
      <Title title="Relatório do cadastros dos clientes" />
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1 }}>filtro locais</div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={handleClick}
            disabled={data.length <= 0}
          >
            <AssignmentIcon />
          </IconButton>
        </div>
        <div>
          <DateImputMobills
            periodo={periodo}
            onChange={(data) => setPeriodo(data)}
          />
        </div>
        <TableContainer component={Paper} className={classes.root}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow className={classes.rowHead}>
                <TableCell />
                <TableCell className={classes.rowHead}>
                  Frente de Caixa
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <Row key={row.username} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <DialogoPDF dados={data} ref={refDialogoInventario} periodo={periodo} />
    </>
  );
}
