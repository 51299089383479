import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import BookIcon from '@material-ui/icons/Book';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import {
  TextInput,
  CpfInput,
  TelefoneInput,
  CnpjInput,
  AutoCompleteRemoto,
  CepInput,
  PrecoInput,
  PorcentagemInput,
  FloatInput,
  IEInput,
  DateInputBonito,
} from '../../../Components';
import { getListAllAPI, api } from '../../../services';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose = () => {} }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [nome, setNome] = useState('');
    const [cpf, setCpf] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [rg, setRg] = useState('');
    const [razaoSocial, setRazaoSocial] = useState('');
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [inscricaoEstadual, setInscricaoEstadual] = useState('');
    const [cidade, setCidade] = useState(null);
    const [bairro, setBairro] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [numero, setNumero] = useState('');
    const [cep, setCep] = useState('');
    const [senhaGerencial, setSenhaGerencial] = useState('');
    const [folgaEdicao, setFolgaEdicao] = useState(0);
    const [valorPadraoRetiradas, setValorPadraoRetiradas] = useState(0);
    const [labelGerencial, setLabelGerencial] = useState('');
    const [logoGerencial, setLogoGerencial] = useState('');
    const [
      descontoAceitavelFuncionario,
      setDescontoAceitavelFuncionario,
    ] = useState(0);

    const [ipi, setIpi] = useState(0);
    const [pis, setPis] = useState(0);
    const [cofins, setCofins] = useState(0);

    const [icmsAi, setIcmsAi] = useState(0);
    const [icmsInt, setIcmsInt] = useState(0);
    const [rbc, setRbc] = useState(0);

    const [irpj, setIrpj] = useState(0);
    const [csll, setCsll] = useState(0);

    const [ppc, setPpc] = useState(0);
    const [pparf, setPparf] = useState(0);
    const [pcf, setPcf] = useState(0);

    const [
      parametroFuturologiaDomingo,
      setParametroFuturologiaDomingo,
    ] = useState(0);
    const [
      parametroFuturologiaSegunda,
      setParametroFuturologiaSegunda,
    ] = useState(0);
    const [parametroFuturologiaTerca, setParametroFuturologiaTerca] = useState(
      0,
    );
    const [
      parametroFuturologiaQuarta,
      setParametroFuturologiaQuarta,
    ] = useState(0);
    const [
      parametroFuturologiaQuinta,
      setParametroFuturologiaQuinta,
    ] = useState(0);
    const [parametroFuturologiaSexta, setParametroFuturologiaSexta] = useState(
      0,
    );
    const [
      parametroFuturologiaSabado,
      setParametroFuturologiaSabado,
    ] = useState(0);

    const [metaAumentoVendas, setMetaAumentoVendas] = useState(0);
    const [metaAumentoVendasLabel, setMetaAumentoVendasLabel] = useState(0);

    const [
      metaMensalCustoOperacional,
      setMetaMensalCustoOperacional,
    ] = useState(0);
    const [
      metaMensalCustoOperacionalLabel,
      setMetaMensalCustoOperacionalLabel,
    ] = useState(0);

    const [metaMensalLucroBruto, setMetaMensalLucroBruto] = useState(0);
    const [metaMensalLucroBrutoLabel, setMetaMensalLucroBrutoLabel] = useState(
      0,
    );

    const [metaMensalLucroLiquido, setMetaMensalLucroLiquido] = useState(0);
    const [
      metaMensalLucroLiquidoLabel,
      setMetaMensalLucroLiquidoLabel,
    ] = useState(0);

    const [metaMensalBalancoFinal, setMetaMensalBalancoFinal] = useState(0);
    const [
      metaMensalBalancoFinalLabel,
      setMetaMensalBalancoFinalLabel,
    ] = useState(0);

    const [metaAnualFaturamentoBruto, setMetaAnualFaturamentoBruto] = useState(
      0,
    );
    const [
      metaAnualFaturamentoBrutoLabel,
      setMetaAnualFaturamentoBrutoLabel,
    ] = useState(0);

    const [
      dataDomingoTrabalhoEquipe1,
      setDataDomingoTrabalhoEquipe1,
    ] = useState(null);

    const [mensagemTelaInicial, setMensagemTelaInicial] = useState('');

    const [carregando, setCarregando] = useState(false);

    const classes = useStyles({ cor: '#009FD4' });

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    async function getDataResource() {
      try {
        const data = await getListAllAPI('config', ['id', 'asc'], { id: [1] }, [
          'cidade.estado',
        ]);
        if (data.data.length > 0) {
          setNome(data.data[0].nome);
          setCpf(data.data[0].cpf);
          setTelefone(data.data[0].telefone);
          setBairro(data.data[0].bairro);
          setLogradouro(data.data[0].logradouro);
          setNumero(data.data[0].numero);
          setEmail(data.data[0].email);
          setRg(data.data[0].rg);
          setRazaoSocial(data.data[0].razao_social);
          setNomeFantasia(data.data[0].nome_fantasia);
          setCnpj(data.data[0].cnpj);
          setInscricaoEstadual(data.data[0].inscricao_estadual);
          setCidade(data.data[0].cidade);
          setCep(data.data[0].cep);
          setSenhaGerencial(data.data[0].senha_alterar_preco_frente_caixa);
          setFolgaEdicao(data.data[0].folga_vendas);
          setValorPadraoRetiradas(
            data.data[0].valor_padrao_retiradas_frete_caixa,
          );
          setLabelGerencial(data.data[0].label_app_gerencial);
          setLogoGerencial(data.data[0].logo_app_gerencial);
          setDescontoAceitavelFuncionario(
            data.data[0].desconto_aceitavel_funcionario,
          );

          setIpi(data.data[0].ipi);
          setPis(data.data[0].pis);
          setCofins(data.data[0].cofins);

          setIcmsAi(data.data[0].icms_ai);
          setIcmsInt(data.data[0].icms_int);
          setRbc(data.data[0].rbc);

          setIrpj(data.data[0].irpj);
          setCsll(data.data[0].csll);

          setPpc(data.data[0].ppc);
          setPparf(data.data[0].pparf);
          setPcf(data.data[0].pcf);

          setParametroFuturologiaDomingo(
            data.data[0].parametro_futurologia_domingo,
          );
          setParametroFuturologiaSegunda(
            data.data[0].parametro_futurologia_segunda,
          );
          setParametroFuturologiaTerca(
            data.data[0].parametro_futurologia_terca,
          );
          setParametroFuturologiaQuarta(
            data.data[0].parametro_futurologia_quarta,
          );
          setParametroFuturologiaQuinta(
            data.data[0].parametro_futurologia_quinta,
          );
          setParametroFuturologiaSexta(
            data.data[0].parametro_futurologia_sexta,
          );
          setParametroFuturologiaSabado(
            data.data[0].parametro_futurologia_sabado,
          );

          setMetaAumentoVendas(data.data[0].meta_aumento_vendas);
          setMetaAumentoVendasLabel(data.data[0].meta_aumento_vendas_label);

          setMetaMensalCustoOperacional(
            data.data[0].meta_mensal_custo_operacional,
          );
          setMetaMensalCustoOperacionalLabel(
            data.data[0].meta_mensal_custo_operacional_label,
          );

          setMetaMensalLucroBruto(data.data[0].meta_mensal_lucro_bruto);
          setMetaMensalLucroBrutoLabel(
            data.data[0].meta_mensal_lucro_bruto_label,
          );

          setMetaMensalLucroLiquido(data.data[0].meta_mensal_lucro_liquido);
          setMetaMensalLucroLiquidoLabel(
            data.data[0].meta_mensal_lucro_liquido_label,
          );

          setMetaMensalBalancoFinal(data.data[0].meta_mensal_balanco_final);
          setMetaMensalBalancoFinalLabel(
            data.data[0].meta_mensal_balanco_final_label,
          );

          setMetaAnualFaturamentoBruto(data.data[0].meta_anual_aumento_vendas);
          setMetaAnualFaturamentoBrutoLabel(
            data.data[0].meta_anual_aumento_vendas_label,
          );
          setDataDomingoTrabalhoEquipe1(
            data.data[0].data_domingo_trabalho_equipe_1
              ? new Date(data.data[0].data_domingo_trabalho_equipe_1)
              : null,
          );
          setMensagemTelaInicial(data.data[0].mensagem_tela_inicial);
          setCarregando(false);
        } else {
          throw 'Estas Configurações não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function handleSalvar() {
      setCarregando(true);
      try {
        await api.put(`config/1`, {
          nome,
          cpf,
          telefone,
          bairro,
          logradouro,
          numero,
          email,
          rg,
          razao_social: razaoSocial,
          nome_fantasia: nomeFantasia,
          cnpj,
          inscricao_estadual: inscricaoEstadual,
          cidade_id: cidade ? cidade.id : null,
          cep,
          senha_alterar_preco_frente_caixa: senhaGerencial,
          folga_vendas: folgaEdicao,
          valor_padrao_retiradas_frete_caixa: valorPadraoRetiradas,
          label_app_gerencial: labelGerencial,
          logo_app_gerencial: logoGerencial,
          desconto_aceitavel_funcionario: descontoAceitavelFuncionario,
          ipi,
          pis,
          cofins,
          icms_ai: icmsAi,
          icms_int: icmsInt,
          rbc,
          irpj,
          csll,
          ppc,
          pparf,
          pcf,
          parametro_futurologia_domingo: parametroFuturologiaDomingo,
          parametro_futurologia_segunda: parametroFuturologiaSegunda,
          parametro_futurologia_terca: parametroFuturologiaTerca,
          parametro_futurologia_quarta: parametroFuturologiaQuarta,
          parametro_futurologia_quinta: parametroFuturologiaQuinta,
          parametro_futurologia_sexta: parametroFuturologiaSexta,
          parametro_futurologia_sabado: parametroFuturologiaSabado,
          meta_aumento_vendas: metaAumentoVendas,
          meta_aumento_vendas_label: metaAumentoVendasLabel,
          meta_mensal_custo_operacional: metaMensalCustoOperacional,
          meta_mensal_custo_operacional_label: metaMensalCustoOperacionalLabel,
          meta_mensal_lucro_bruto: metaMensalLucroBruto,
          meta_mensal_lucro_bruto_label: metaMensalLucroBrutoLabel,
          meta_mensal_lucro_liquido: metaMensalLucroLiquido,
          meta_mensal_lucro_liquido_label: metaMensalLucroLiquidoLabel,
          meta_mensal_balanco_final: metaMensalBalancoFinal,
          meta_mensal_balanco_final_label: metaMensalBalancoFinalLabel,
          meta_anual_aumento_vendas: metaAnualFaturamentoBruto,
          meta_anual_aumento_vendas_label: metaAnualFaturamentoBrutoLabel,
          data_domingo_trabalho_equipe_1: dataDomingoTrabalhoEquipe1 || null,
          mensagem_tela_inicial: mensagemTelaInicial,
        });
        setCarregando(false);
        enqueueSnackbar('Configurações atualizadas com sucesso!', {
          variant: 'success',
        });

        handleCloseDialog();
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleOpen() {
        setCarregando(true);
        getDataResource();
        setOpen(true);
      },
    }));

    function validateNome() {
      let error = '';
      if (!nome) error = 'Este campo é obrigatório';
      else if (nome.length > 80)
        error = 'Número de caracteres maior que o permitido (máximo 80)';

      return error;
    }

    function validateCpf() {
      let error = '';
      if (cpf) {
        if (cpf.length < 11) error = 'O CPF deve ter 11 dígitos';
      }
      return error;
    }

    function validateTelefone() {
      let error = '';
      if (telefone) {
        if (telefone.length < 10)
          error = 'O Telefone deve ter 10 ou 11 dígitos';
      }
      return error;
    }

    function validateBairro() {
      let error = '';
      if (bairro) {
        if (bairro.length > 80)
          error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
      return error;
    }

    function validateLogradouro() {
      let error = '';
      if (logradouro) {
        if (logradouro.length > 80)
          error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
      return error;
    }

    function validateNumero() {
      let error = '';
      if (numero) {
        if (numero.length > 6)
          error = 'Número de caracteres maior que o permitido (máximo 6)';
      }
      return error;
    }

    function validateEmail() {
      let error = '';
      if (email) {
        if (!validateEmail(email)) error = 'Email Inválido';
        if (email.length > 80)
          error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
      return error;
    }

    function validateRg() {
      let error = '';
      if (rg) {
        if (rg.length > 20)
          error = 'Número de caracteres maior que o permitido (máximo 20)';
      }
      return error;
    }

    function validateRazaoSocial() {
      let error = '';
      if (razaoSocial) {
        if (razaoSocial.length > 80)
          error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
      return error;
    }

    function validateNomeFantasia() {
      let error = '';
      if (nomeFantasia) {
        if (nomeFantasia.length > 80)
          error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
      return error;
    }

    function validateCnpj() {
      let error = '';
      if (cnpj) {
        if (cnpj.length < 11) error = 'O CNPJ deve ter 14 dígitos';
      }
      return error;
    }

    function validateInscricaoEstadual() {
      let error = '';
      if (inscricaoEstadual) {
        if (inscricaoEstadual.length >= 20)
          error = 'A Inscrição Estadual deve ser menor que 20 dígitos';
      }
      return error;
    }

    function validateCidade() {
      const error = '';

      return error;
    }

    function validateCep() {
      let error = '';
      if (cep) {
        if (cep.length < 8) error = 'O CEP deve ter 8 dígitos';
      }
      return error;
    }

    function validateSenhaGerencial() {
      let error = '';
      if (!senhaGerencial) error = 'Este campo é obrigatório';
      else if (senhaGerencial.length > 30)
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      return error;
    }

    function validateFolgaEdicao() {
      let error = '';
      if (Number.isNaN(folgaEdicao)) {
        error = 'Campo Obrigatório';
      }
      return error;
    }

    function validateValorPadraoRetiradas() {
      let error = '';
      if (Number.isNaN(valorPadraoRetiradas)) {
        error = 'Campo Obrigatório';
      } else if (valorPadraoRetiradas < 100 || valorPadraoRetiradas > 500) {
        error = 'Valor inválido (100 <= valor <= 500)';
      }
      return error;
    }

    function validateLabelGerencial() {
      let error = '';
      if (labelGerencial) {
        if (labelGerencial.length > 80)
          error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
      return error;
    }

    function validateLogoGerencial() {
      let error = '';
      if (logoGerencial) {
        if (logoGerencial.length > 80)
          error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
      return error;
    }

    function validateDescontoAceitavelFuncionario() {
      let error = '';
      if (Number.isNaN(descontoAceitavelFuncionario)) {
        error = 'Campo Obrigatório';
      } else if (
        descontoAceitavelFuncionario > 100 ||
        descontoAceitavelFuncionario <= 0
      ) {
        error = 'Valor inválido (0 < valor <= 10)';
      }
      return error;
    }

    function validateIpi() {
      let error = '';
      if (Number.isNaN(ipi)) {
        error = 'Campo Obrigatório';
      } else if (ipi < 0 || ipi >= 90) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validatePis() {
      let error = '';
      if (Number.isNaN(pis)) {
        error = 'Campo Obrigatório';
      } else if (pis < 0 || pis >= 90) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validateCofins() {
      let error = '';
      if (Number.isNaN(cofins)) {
        error = 'Campo Obrigatório';
      } else if (cofins < 0 || cofins >= 90) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validateIcmsAi() {
      let error = '';
      if (Number.isNaN(icmsAi)) {
        error = 'Campo Obrigatório';
      } else if (icmsAi < 0 || icmsAi >= 90) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validateIcmsInt() {
      let error = '';
      if (Number.isNaN(icmsInt)) {
        error = 'Campo Obrigatório';
      } else if (icmsInt < 0 || icmsInt >= 90) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validateRbc() {
      let error = '';
      if (Number.isNaN(rbc)) {
        error = 'Campo Obrigatório';
      } else if (rbc < 0 || rbc > 100) {
        error = 'Valor inválido (< 100%)';
      }
      return error;
    }

    function validateIrpj() {
      let error = '';
      if (Number.isNaN(irpj)) {
        error = 'Campo Obrigatório';
      } else if (irpj < 0 || irpj >= 90) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validateCsll() {
      let error = '';
      if (Number.isNaN(csll)) {
        error = 'Campo Obrigatório';
      } else if (csll < 0 || csll >= 90) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validatePpc() {
      let error = '';
      if (Number.isNaN(ppc)) {
        error = 'Campo Obrigatório';
      } else if (ppc < 0 || ppc >= 90) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validatePparf() {
      let error = '';
      if (Number.isNaN(pparf)) {
        error = 'Campo Obrigatório';
      } else if (pparf < 0 || pparf >= 90) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validatePcf() {
      let error = '';
      if (Number.isNaN(ppc)) {
        error = 'Campo Obrigatório';
      } else if (pcf < 0 || pcf >= 90) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validateParametroFuturologiaDomingo() {
      let error = '';
      if (Number.isNaN(parametroFuturologiaDomingo)) {
        error = 'Campo Obrigatório';
      } else if (
        parametroFuturologiaDomingo < 0 ||
        parametroFuturologiaDomingo >= 7
      ) {
        error = 'Valor inválido (< 7)';
      }
      return error;
    }

    function validateParametroFuturologiaSegunda() {
      let error = '';
      if (Number.isNaN(parametroFuturologiaSegunda)) {
        error = 'Campo Obrigatório';
      } else if (
        parametroFuturologiaSegunda < 0 ||
        parametroFuturologiaSegunda >= 7
      ) {
        error = 'Valor inválido (< 7)';
      }
      return error;
    }

    function validateParametroFuturologiaTerca() {
      let error = '';
      if (Number.isNaN(parametroFuturologiaTerca)) {
        error = 'Campo Obrigatório';
      } else if (
        parametroFuturologiaTerca < 0 ||
        parametroFuturologiaTerca >= 7
      ) {
        error = 'Valor inválido (< 7)';
      }
      return error;
    }

    function validateParametroFuturologiaQuarta() {
      let error = '';
      if (Number.isNaN(parametroFuturologiaQuarta)) {
        error = 'Campo Obrigatório';
      } else if (
        parametroFuturologiaQuarta < 0 ||
        parametroFuturologiaQuarta >= 7
      ) {
        error = 'Valor inválido (< 7)';
      }
      return error;
    }

    function validateParametroFuturologiaQuinta() {
      let error = '';
      if (Number.isNaN(parametroFuturologiaQuinta)) {
        error = 'Campo Obrigatório';
      } else if (
        parametroFuturologiaQuinta < 0 ||
        parametroFuturologiaQuinta >= 7
      ) {
        error = 'Valor inválido (< 7)';
      }
      return error;
    }

    function validateParametroFuturologiaSexta() {
      let error = '';
      if (Number.isNaN(parametroFuturologiaSexta)) {
        error = 'Campo Obrigatório';
      } else if (
        parametroFuturologiaSexta < 0 ||
        parametroFuturologiaSexta >= 7
      ) {
        error = 'Valor inválido (< 7)';
      }
      return error;
    }

    function validateParametroFuturologiaSabado() {
      let error = '';
      if (Number.isNaN(parametroFuturologiaSabado)) {
        error = 'Campo Obrigatório';
      } else if (
        parametroFuturologiaSabado < 0 ||
        parametroFuturologiaSabado >= 7
      ) {
        error = 'Valor inválido (< 7)';
      }
      return error;
    }

    function validateMetaAumentoVendas() {
      let error = '';
      if (Number.isNaN(metaAumentoVendas)) {
        error = 'Campo Obrigatório';
      } else if (metaAumentoVendas < 0 || metaAumentoVendas > 100) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validateMetaAumentoVendasLabel() {
      let error = '';
      if (Number.isNaN(metaAumentoVendasLabel)) {
        error = 'Campo Obrigatório';
      } else if (metaAumentoVendasLabel < 0 || metaAumentoVendasLabel > 100) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validateMetaMensalCustoOperacional() {
      let error = '';
      if (Number.isNaN(metaMensalCustoOperacional)) {
        error = 'Campo Obrigatório';
      } else if (
        metaMensalCustoOperacional < 0 ||
        metaMensalCustoOperacional > 100
      ) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validateMetaMensalCustoOperacionalLabel() {
      let error = '';
      if (Number.isNaN(metaMensalCustoOperacionalLabel)) {
        error = 'Campo Obrigatório';
      } else if (
        metaMensalCustoOperacionalLabel < 0 ||
        metaMensalCustoOperacionalLabel > 100
      ) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validateMetaMensalLucroBruto() {
      let error = '';
      if (Number.isNaN(metaMensalLucroBruto)) {
        error = 'Campo Obrigatório';
      } else if (metaMensalLucroBruto < 0 || metaMensalLucroBruto > 100) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validateMetaMensalLucroBrutoLabel() {
      let error = '';
      if (Number.isNaN(metaMensalLucroBrutoLabel)) {
        error = 'Campo Obrigatório';
      } else if (
        metaMensalLucroBrutoLabel < 0 ||
        metaMensalLucroBrutoLabel > 100
      ) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validateMetaMensalLucroLiquido() {
      let error = '';
      if (Number.isNaN(metaMensalLucroLiquido)) {
        error = 'Campo Obrigatório';
      } else if (metaMensalLucroLiquido < 0 || metaMensalLucroLiquido > 100) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validateMetaMensalLucroLiquidoLabel() {
      let error = '';
      if (Number.isNaN(metaMensalLucroLiquidoLabel)) {
        error = 'Campo Obrigatório';
      } else if (
        metaMensalLucroLiquidoLabel < 0 ||
        metaMensalLucroLiquidoLabel > 100
      ) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validateMetaMensalBalancoFinal() {
      let error = '';
      if (Number.isNaN(metaMensalBalancoFinal)) {
        error = 'Campo Obrigatório';
      } else if (metaMensalBalancoFinal < 0 || metaMensalBalancoFinal > 100) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validateMetaMensalBalancoFinalLabel() {
      let error = '';
      if (Number.isNaN(metaMensalBalancoFinalLabel)) {
        error = 'Campo Obrigatório';
      } else if (
        metaMensalBalancoFinalLabel < 0 ||
        metaMensalBalancoFinalLabel > 100
      ) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validateMetaAnualFaturamentoBruto() {
      let error = '';
      if (Number.isNaN(metaAnualFaturamentoBruto)) {
        error = 'Campo Obrigatório';
      } else if (
        metaAnualFaturamentoBruto < 0 ||
        metaAnualFaturamentoBruto > 100
      ) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validateMetaAnualFaturamentoBrutoLabel() {
      let error = '';
      if (Number.isNaN(metaAnualFaturamentoBrutoLabel)) {
        error = 'Campo Obrigatório';
      } else if (
        metaAnualFaturamentoBrutoLabel < 0 ||
        metaAnualFaturamentoBrutoLabel > 100
      ) {
        error = 'Valor inválido (< 90%)';
      }
      return error;
    }

    function validateDataDomingoBaseEquipe1() {
      let error = '';
      if (!dataDomingoTrabalhoEquipe1) {
        error = 'Campo Obrigatório';
      } else if (dataDomingoTrabalhoEquipe1.getDay() !== 0) {
        error = 'Esta data deve ser um domingo!';
      }
      return error;
    }

    function getErros() {
      const errosOld = [''];
      errosOld[0] = validateNome();
      errosOld[1] = validateCpf();
      errosOld[2] = validateTelefone();
      errosOld[3] = validateEmail();
      errosOld[4] = validateRg();
      errosOld[5] = validateRazaoSocial();
      errosOld[6] = validateNomeFantasia();
      errosOld[7] = validateCnpj();
      errosOld[8] = validateInscricaoEstadual();
      errosOld[9] = validateCidade();
      errosOld[10] = validateBairro();
      errosOld[11] = validateLogradouro();
      errosOld[12] = validateNumero();
      errosOld[13] = validateCep();
      errosOld[14] = validateSenhaGerencial();
      errosOld[15] = validateFolgaEdicao();
      errosOld[16] = validateValorPadraoRetiradas();
      errosOld[17] = validateLabelGerencial();
      errosOld[18] = validateLogoGerencial();
      errosOld[19] = validateDescontoAceitavelFuncionario();
      errosOld[20] = validateIpi();
      errosOld[21] = validatePis();
      errosOld[22] = validateCofins();
      errosOld[23] = validateIcmsAi();
      errosOld[24] = validateIcmsInt();
      errosOld[25] = validateRbc();
      errosOld[26] = validateIrpj();
      errosOld[27] = validateCsll();
      errosOld[28] = validatePpc();
      errosOld[29] = validatePparf();
      errosOld[30] = validatePcf();
      errosOld[31] = validateParametroFuturologiaDomingo();
      errosOld[32] = validateParametroFuturologiaSegunda();
      errosOld[33] = validateParametroFuturologiaTerca();
      errosOld[34] = validateParametroFuturologiaQuarta();
      errosOld[35] = validateParametroFuturologiaQuinta();
      errosOld[36] = validateParametroFuturologiaSexta();
      errosOld[37] = validateParametroFuturologiaSabado();
      errosOld[38] = validateMetaAumentoVendas();
      errosOld[39] = validateMetaAumentoVendasLabel();
      errosOld[40] = validateMetaMensalCustoOperacional();
      errosOld[41] = validateMetaMensalCustoOperacionalLabel();
      errosOld[42] = validateMetaMensalLucroBruto();
      errosOld[43] = validateMetaMensalLucroBrutoLabel();
      errosOld[44] = validateMetaMensalLucroLiquido();
      errosOld[45] = validateMetaMensalLucroLiquidoLabel();
      errosOld[46] = validateMetaMensalBalancoFinal();
      errosOld[47] = validateMetaMensalBalancoFinalLabel();
      errosOld[48] = validateMetaAnualFaturamentoBrutoLabel();
      errosOld[49] = validateMetaAnualFaturamentoBruto();
      errosOld[50] = validateDataDomingoBaseEquipe1();
      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    function openBiblia() {}

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          //fullWidth
          //maxWidth="sm"
          fullScreen
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            Editar Configurações
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div>
                <Box p="1em">
                  <Box display="flex">
                    <Box flex={2} mr="1em">
                      <Typography variant="h6" gutterBottom>
                        Dados Pessoais do empresário
                      </Typography>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TextInput
                            fullWidth
                            source="nome"
                            label="Nome"
                            autoFocus
                            error={erros[0] !== ''}
                            helperText={erros[0]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={nome}
                            onChange={(value) => setNome(value.target.value)}
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <CpfInput
                            fullWidth
                            source="cpf"
                            label="CPF"
                            error={erros[1] !== ''}
                            helperText={erros[1]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={cpf}
                            onChange={(value) => setCpf(value.target.value)}
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TelefoneInput
                            fullWidth
                            source="telefone"
                            label="Telefone"
                            error={erros[2] !== ''}
                            helperText={erros[2]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={telefone}
                            onChange={(value) =>
                              setTelefone(value.target.value)
                            }
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <TextInput
                            fullWidth
                            label="Email"
                            source="email"
                            type="email"
                            error={erros[3] !== ''}
                            helperText={erros[3]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={email}
                            onChange={(value) => setEmail(value.target.value)}
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} metaMensalLucroBrutoLabel="0.5em">
                          <TextInput
                            fullWidth
                            source="rg"
                            label="RG"
                            error={erros[4] !== ''}
                            helperText={erros[4]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={rg}
                            onChange={(value) => setRg(value.target.value)}
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" />
                      </Box>

                      <Box mt="1em" />

                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{
                          marginTop: '15px',
                        }}
                      >
                        Dados Empresariais
                      </Typography>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TextInput
                            fullWidth
                            source="razao_social"
                            label="Razão Social"
                            error={erros[5] !== ''}
                            helperText={erros[5]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={razaoSocial}
                            onChange={(value) =>
                              setRazaoSocial(value.target.value)
                            }
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <TextInput
                            fullWidth
                            source="nome_fantasia"
                            label="Nome Fantasia"
                            error={erros[6] !== ''}
                            helperText={erros[6]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={nomeFantasia}
                            onChange={(value) =>
                              setNomeFantasia(value.target.value)
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <CnpjInput
                            fullWidth
                            source="cnpj"
                            label="CNPJ"
                            error={erros[7] !== ''}
                            helperText={erros[7]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={cnpj}
                            onChange={(value) => setCnpj(value.target.value)}
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <IEInput
                            fullWidth
                            source="inscricao_estadual"
                            label="Inscrição Estadual"
                            error={erros[8] !== ''}
                            helperText={erros[8]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={inscricaoEstadual}
                            onChange={(value) =>
                              setInscricaoEstadual(value.target.value)
                            }
                          />
                        </Box>
                      </Box>

                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{
                          marginTop: '15px',
                        }}
                      >
                        Endereço da empresa
                      </Typography>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <AutoCompleteRemoto
                            handleEnter={() => {}}
                            resource="cidades"
                            nested={['estado']}
                            filters={{ ativo: true }}
                            label="Cidade"
                            handleKey={() => {}}
                            value={cidade}
                            onChange={(value) => setCidade(value)}
                            toString={false}
                            fullWidth
                            getOptionSelected={(option, value) =>
                              option.nome === value.nome
                            }
                            getOptionLabel={(option) =>
                              `${option.nome} - ${option.estado.sigla}`
                            }
                            error={erros[9] !== ''}
                            helperText={erros[9]}
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <TextInput
                            fullWidth
                            source="bairro"
                            label="Bairro"
                            error={erros[10] !== ''}
                            helperText={erros[10]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={bairro}
                            onChange={(value) => setBairro(value.target.value)}
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TextInput
                            fullWidth
                            source="logradouro"
                            label="Logradouro"
                            error={erros[11] !== ''}
                            helperText={erros[11]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={logradouro}
                            onChange={(value) =>
                              setLogradouro(value.target.value)
                            }
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <TextInput
                            fullWidth
                            source="numero"
                            label="Nº"
                            error={erros[12] !== ''}
                            helperText={erros[12]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={numero}
                            onChange={(value) => setNumero(value.target.value)}
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <CepInput
                            fullWidth
                            source="cep"
                            label="CEP"
                            error={erros[13] !== ''}
                            helperText={erros[13]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={cep}
                            onChange={(value) => setCep(value.target.value)}
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" />
                      </Box>

                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{
                          marginTop: '15px',
                        }}
                      >
                        Configurações Gerais
                      </Typography>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TextInput
                            fullWidth
                            source="senha_alterar_preco_frente_caixa"
                            label="Senha para permissões no FC"
                            error={erros[14] !== ''}
                            helperText={erros[14]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={senhaGerencial}
                            onChange={(value) =>
                              setSenhaGerencial(value.target.value)
                            }
                          />
                        </Box>
                        <Box flex={1} mr="0.5em" ml="0.5em">
                          <PrecoInput
                            fullWidth
                            source="folga_vendas"
                            label="Folga Edição Venda"
                            error={erros[15] !== ''}
                            helperText={erros[15]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(folgaEdicao)
                                ? ''
                                : String(folgaEdicao)
                            }
                            onChange={(value) =>
                              setFolgaEdicao(parseFloat(value.target.value))
                            }
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PrecoInput
                            fullWidth
                            source="valor_padrao_retiradas_frete_caixa"
                            label="Valor padrão retiradas"
                            error={erros[16] !== ''}
                            helperText={erros[16]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(valorPadraoRetiradas)
                                ? ''
                                : String(valorPadraoRetiradas)
                            }
                            onChange={(value) =>
                              setValorPadraoRetiradas(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TextInput
                            fullWidth
                            source="label_app_gerencial"
                            label="Label Gerencial"
                            error={erros[17] !== ''}
                            helperText={erros[17]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={labelGerencial}
                            onChange={(value) =>
                              setLabelGerencial(value.target.value)
                            }
                          />
                        </Box>
                        <Box flex={1} mr="0.5em" ml="0.5em">
                          <TextInput
                            fullWidth
                            source="logo_app_gerencial"
                            label="Logo Gerencial"
                            error={erros[18] !== ''}
                            helperText={erros[18]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={logoGerencial}
                            onChange={(value) =>
                              setLogoGerencial(value.target.value)
                            }
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PrecoInput
                            fullWidth
                            source="desconto_aceitavel_funcionario"
                            label="Desconto Aceitável Funcionário"
                            error={erros[19] !== ''}
                            helperText={erros[19]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(descontoAceitavelFuncionario)
                                ? ''
                                : String(descontoAceitavelFuncionario)
                            }
                            onChange={(value) =>
                              setDescontoAceitavelFuncionario(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <DateInputBonito
                            todayLabel="Hoje"
                            name="data_pagamento"
                            handleEnter={() => {}}
                            label="Data Domingo Base Trabalho Equipe 1"
                            handleKey={() => {}}
                            value={dataDomingoTrabalhoEquipe1}
                            onChange={(value) => {
                              const newData = new Date(value);
                              setDataDomingoTrabalhoEquipe1(value);
                            }}
                            format="dd MMMM yyyy"
                            fullWidth
                            inputVariant="outlined"
                            error={erros[50] !== ''}
                            helperText={erros[50]}
                            style={{
                              marginTop: '15px',
                            }}
                          />
                        </Box>
                        <Box
                          flex={2}
                          mr="0.5em"
                          ml="0.5em"
                          style={{ display: 'flex' }}
                        >
                          <TextInput
                            fullWidth
                            source="mensagem_tela_inicial"
                            label="Mensagem Tela Inicial"
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={mensagemTelaInicial}
                            onChange={(value) =>
                              setMensagemTelaInicial(value.target.value)
                            }
                            multiline
                            rows={5}
                          />
                          <Tooltip
                            title="Bíblia"
                            placement="bottom"
                            aria-label="add2"
                          >
                            <IconButton
                              aria-label="edit"
                              onClick={() => openBiblia()}
                            >
                              <BookIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>

                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{
                          marginTop: '15px',
                        }}
                      >
                        Valores Padrão Impostos
                      </Typography>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="ipi"
                            label="IPI"
                            error={erros[20] !== ''}
                            helperText={erros[20]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={Number.isNaN(ipi) ? '' : String(ipi)}
                            onChange={(value) =>
                              setIpi(parseFloat(value.target.value))
                            }
                          />
                        </Box>
                        <Box flex={1} mr="0.5em" ml="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="pis"
                            label="PIS"
                            error={erros[21] !== ''}
                            helperText={erros[21]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={Number.isNaN(pis) ? '' : String(pis)}
                            onChange={(value) =>
                              setPis(parseFloat(value.target.value))
                            }
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="cofins"
                            label="COFINS"
                            error={erros[22] !== ''}
                            helperText={erros[22]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={Number.isNaN(cofins) ? '' : String(cofins)}
                            onChange={(value) =>
                              setCofins(parseFloat(value.target.value))
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="icms_ai"
                            label="ICMS AI"
                            error={erros[23] !== ''}
                            helperText={erros[23]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={Number.isNaN(icmsAi) ? '' : String(icmsAi)}
                            onChange={(value) =>
                              setIcmsAi(parseFloat(value.target.value))
                            }
                          />
                        </Box>
                        <Box flex={1} mr="0.5em" ml="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="icms_int"
                            label="ICMS INT"
                            error={erros[24] !== ''}
                            helperText={erros[24]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={Number.isNaN(icmsInt) ? '' : String(icmsInt)}
                            onChange={(value) =>
                              setIcmsInt(parseFloat(value.target.value))
                            }
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="rbc"
                            label="RBC"
                            error={erros[25] !== ''}
                            helperText={erros[25]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={Number.isNaN(rbc) ? '' : String(rbc)}
                            onChange={(value) =>
                              setRbc(parseFloat(value.target.value))
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="irpj"
                            label="IRPJ"
                            error={erros[26] !== ''}
                            helperText={erros[26]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={Number.isNaN(irpj) ? '' : String(irpj)}
                            onChange={(value) =>
                              setIrpj(parseFloat(value.target.value))
                            }
                          />
                        </Box>
                        <Box flex={1} mr="0.5em" ml="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="csll"
                            label="CSLL"
                            error={erros[27] !== ''}
                            helperText={erros[27]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={Number.isNaN(csll) ? '' : String(csll)}
                            onChange={(value) =>
                              setCsll(parseFloat(value.target.value))
                            }
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" />
                      </Box>

                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{
                          marginTop: '15px',
                        }}
                      >
                        Valores Padrão Impostos
                      </Typography>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="ppc"
                            label="PPC"
                            error={erros[28] !== ''}
                            helperText={erros[28]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={Number.isNaN(ppc) ? '' : String(ppc)}
                            onChange={(value) =>
                              setPpc(parseFloat(value.target.value))
                            }
                          />
                        </Box>
                        <Box flex={1} mr="0.5em" ml="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="pparf"
                            label="PPARF"
                            error={erros[29] !== ''}
                            helperText={erros[29]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={Number.isNaN(pparf) ? '' : String(pparf)}
                            onChange={(value) =>
                              setPparf(parseFloat(value.target.value))
                            }
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="pcf"
                            label="PCF"
                            error={erros[30] !== ''}
                            helperText={erros[30]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={Number.isNaN(pcf) ? '' : String(pcf)}
                            onChange={(value) =>
                              setPcf(parseFloat(value.target.value))
                            }
                          />
                        </Box>
                      </Box>

                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{
                          marginTop: '15px',
                        }}
                      >
                        Parâmetros futurologia
                      </Typography>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <FloatInput
                            fullWidth
                            source="parametro_futurologia_domingo"
                            label="Domingo"
                            error={erros[31] !== ''}
                            helperText={erros[31]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(parametroFuturologiaDomingo)
                                ? ''
                                : String(parametroFuturologiaDomingo)
                            }
                            onChange={(value) =>
                              setParametroFuturologiaDomingo(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                        <Box flex={1} mr="0.5em" ml="0.5em">
                          <FloatInput
                            fullWidth
                            source="parametro_futurologia_segunda"
                            label="Segunda-Feira"
                            error={erros[32] !== ''}
                            helperText={erros[32]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(parametroFuturologiaSegunda)
                                ? ''
                                : String(parametroFuturologiaSegunda)
                            }
                            onChange={(value) =>
                              setParametroFuturologiaSegunda(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <FloatInput
                            fullWidth
                            source="parametro_futurologia_terca"
                            label="Terça-Feira"
                            error={erros[33] !== ''}
                            helperText={erros[33]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(parametroFuturologiaTerca)
                                ? ''
                                : String(parametroFuturologiaTerca)
                            }
                            onChange={(value) =>
                              setParametroFuturologiaTerca(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <FloatInput
                            fullWidth
                            source="parametro_futurologia_quarta"
                            label="Quarta-Feira"
                            error={erros[34] !== ''}
                            helperText={erros[34]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(parametroFuturologiaQuarta)
                                ? ''
                                : String(parametroFuturologiaQuarta)
                            }
                            onChange={(value) =>
                              setParametroFuturologiaQuarta(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                        <Box flex={1} mr="0.5em" ml="0.5em">
                          <FloatInput
                            fullWidth
                            source="parametro_futurologia_quinta"
                            label="Quinta-Feira"
                            error={erros[35] !== ''}
                            helperText={erros[35]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(parametroFuturologiaQuinta)
                                ? ''
                                : String(parametroFuturologiaQuinta)
                            }
                            onChange={(value) =>
                              setParametroFuturologiaQuinta(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <FloatInput
                            fullWidth
                            source="parametro_futurologia_sexta"
                            label="Sexta-Feira"
                            error={erros[36] !== ''}
                            helperText={erros[36]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(parametroFuturologiaSexta)
                                ? ''
                                : String(parametroFuturologiaSexta)
                            }
                            onChange={(value) =>
                              setParametroFuturologiaSexta(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <FloatInput
                            fullWidth
                            source="parametro_futurologia_sabado"
                            label="Sábado"
                            error={erros[37] !== ''}
                            helperText={erros[37]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(parametroFuturologiaSabado)
                                ? ''
                                : String(parametroFuturologiaSabado)
                            }
                            onChange={(value) =>
                              setParametroFuturologiaSabado(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                        <Box flex={1} mr="0.5em" ml="0.5em" />
                        <Box flex={1} ml="0.5em" />
                      </Box>

                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{
                          marginTop: '15px',
                        }}
                      >
                        Metas Mensais
                      </Typography>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="meta_aumento_vendas"
                            label="Meta de Aumento de Faturamento Bruto"
                            error={erros[38] !== ''}
                            helperText={erros[38]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(metaAumentoVendas)
                                ? ''
                                : String(metaAumentoVendas)
                            }
                            onChange={(value) =>
                              setMetaAumentoVendas(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="meta_aumento_vendas_label"
                            label="Label Meta de Aumento de Faturamento Bruto"
                            error={erros[39] !== ''}
                            helperText={erros[39]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(metaAumentoVendasLabel)
                                ? ''
                                : String(metaAumentoVendasLabel)
                            }
                            onChange={(value) =>
                              setMetaAumentoVendasLabel(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="meta_mensal_custo_operacional"
                            label="Meta de Diminuição de Custo Operacional"
                            error={erros[40] !== ''}
                            helperText={erros[40]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(metaMensalCustoOperacional)
                                ? ''
                                : String(metaMensalCustoOperacional)
                            }
                            onChange={(value) =>
                              setMetaMensalCustoOperacional(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="meta_mensal_custo_operacional_label"
                            label="Label Meta de Diminuição de Custo Operacional"
                            error={erros[41] !== ''}
                            helperText={erros[41]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(metaMensalCustoOperacionalLabel)
                                ? ''
                                : String(metaMensalCustoOperacionalLabel)
                            }
                            onChange={(value) =>
                              setMetaMensalCustoOperacionalLabel(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="meta_mensal_lucro_bruto"
                            label="Meta de Lucro Bruto"
                            error={erros[42] !== ''}
                            helperText={erros[42]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(metaMensalLucroBruto)
                                ? ''
                                : String(metaMensalLucroBruto)
                            }
                            onChange={(value) =>
                              setMetaMensalLucroBruto(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="meta_mensal_lucro_bruto_label"
                            label="Label Meta de Lucro Bruto"
                            error={erros[43] !== ''}
                            helperText={erros[43]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(metaMensalLucroBrutoLabel)
                                ? ''
                                : String(metaMensalLucroBrutoLabel)
                            }
                            onChange={(value) =>
                              setMetaMensalLucroBrutoLabel(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="meta_mensal_lucro_liquido"
                            label="Meta de Lucro Líquido"
                            error={erros[44] !== ''}
                            helperText={erros[44]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(metaMensalLucroLiquido)
                                ? ''
                                : String(metaMensalLucroLiquido)
                            }
                            onChange={(value) =>
                              setMetaMensalLucroLiquido(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="meta_mensal_lucro_liquido_label"
                            label="Label Meta de Lucro Líquido"
                            error={erros[45] !== ''}
                            helperText={erros[45]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(metaMensalLucroLiquidoLabel)
                                ? ''
                                : String(metaMensalLucroLiquidoLabel)
                            }
                            onChange={(value) =>
                              setMetaMensalLucroLiquidoLabel(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="meta_mensal_balanco_final"
                            label="Meta de Balanço Final"
                            error={erros[46] !== ''}
                            helperText={erros[46]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(metaMensalBalancoFinal)
                                ? ''
                                : String(metaMensalBalancoFinal)
                            }
                            onChange={(value) =>
                              setMetaMensalBalancoFinal(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="meta_mensal_balanco_final_label"
                            label="Label Meta de Balanço Final"
                            error={erros[47] !== ''}
                            helperText={erros[47]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(metaMensalBalancoFinalLabel)
                                ? ''
                                : String(metaMensalBalancoFinalLabel)
                            }
                            onChange={(value) =>
                              setMetaMensalBalancoFinalLabel(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                      </Box>

                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{
                          marginTop: '15px',
                        }}
                      >
                        Metas Anuais
                      </Typography>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="meta_anual_aumento_vendas"
                            label="Meta de Faturamento Bruto"
                            error={erros[48] !== ''}
                            helperText={erros[48]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(metaAnualFaturamentoBruto)
                                ? ''
                                : String(metaAnualFaturamentoBruto)
                            }
                            onChange={(value) =>
                              setMetaAnualFaturamentoBruto(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PorcentagemInput
                            fullWidth
                            source="meta_anual_aumento_vendas_label"
                            label="Label Meta de Faturamento Bruto"
                            error={erros[49] !== ''}
                            helperText={erros[49]}
                            handleEnter={() => {}}
                            handleKey={() => {}}
                            value={
                              Number.isNaN(metaAnualFaturamentoBrutoLabel)
                                ? ''
                                : String(metaAnualFaturamentoBrutoLabel)
                            }
                            onChange={(value) =>
                              setMetaAnualFaturamentoBrutoLabel(
                                parseFloat(value.target.value),
                              )
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={erroExistente || carregando}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
