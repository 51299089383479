import React, { useState, forwardRef, useImperativeHandle } from 'react';

import { useMediaQuery, MenuItem, Select } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { IntegerInput } from '../../Components/Inputs';
import { getUserId } from '../../services/alth';
import { createAPI, updateAPI, getListAllAPI } from '../../services/api';
import { SECONDARY_COLOR } from '../../utils'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

function formatMoeda(valor) {
  return valor !== null && valor !== undefined
    ? valor.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })
    : '';
}

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  rootDialogoSmall: {
    borderRadius: '0px',
  },
  btnAtions: {
    borderRadius: '25px',
  },
  btnAtionsSmall: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose = () => {} }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [copias, setCopias] = useState(0);
    const [id, setId] = useState(DEFAULT_ID);
    const [carregando, setCarregando] = useState(false);
    const [produto, setProduto] = useState(null);

    const classes = useStyles({ cor: 'black' });
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    async function getDataResource(id) {
      try {
        const data = await getListAllAPI(
          'solicitacoes_impressoes_etiqueta_2',
          ['id', 'asc'],
          { id: [id] },
          ['produto.unidade'],
        );
        if (data.data.length > 0) {
          setId(data.data[0].id);
          setProduto(data.data[0].produto);
          setCopias(data.data[0].copias);
          setCarregando(false);
        } else {
          throw 'Esta solicitação de impressão não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function handleSalvar() {
      setCarregando(true);
      try {
        if (id <= 0) {
          setCarregando(false);
          enqueueSnackbar('Solicitação de impressão registrada com sucesso!', {
            variant: 'success',
          });
        } else {
          await updateAPI('solicitacoes_impressoes_etiqueta_2', id, {
            copias,
          });
          setCarregando(false);
          enqueueSnackbar('Solicitação de impressão atualizada com sucesso!', {
            variant: 'success',
          });
        }
        handleCloseDialog();
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleEdit(id) {
        setCarregando(true);
        getDataResource(id);
        setId(id);
        setOpen(true);
      },
    }));

    function validateCopias() {
      let error = '';
      if (copias <= 0 || Number.isNaN(copias)) {
        error = 'Deve ter um valor maior que 0';
      }
      return error;
    }

    function getErros() {
      const errosOld = ['', ''];
      errosOld[0] = validateCopias();
      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    function getDadosProduto() {
      if (produto) {
        return {
          nome: produto.nome,
          precoVenda: produto.preco_venda,
        };
      }
      return {
        nome: '',
        precoVenda: 0,
      };
    }

    const { nome, precoVenda } = getDadosProduto();

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: isSmall ? classes.rootDialogoSmall : classes.rootDialogo,
          }}
          className={isSmall ? classes.rootDialogoSmall : classes.rootDialogo}
          fullWidth={!isSmall}
          fullScreen={isSmall}
          maxWidth={isSmall ? '' : 'sm'}
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: SECONDARY_COLOR, color: 'white' }}
          >
            {id < 0 ? `Nova Impressão` : `Editar Impressão`}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '20px 15px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div>
                <div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '5px' }}>Produto:</div>
                    <div>{nome}</div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '5px' }}>Preco venda:</div>
                    <div>{formatMoeda(precoVenda)}</div>
                  </div>
                </div>
                <IntegerInput
                  handleEnter={() => {}}
                  label="Cópias"
                  handleKey={() => {}}
                  value={Number.isNaN(copias) ? '' : String(copias)}
                  onChange={(value) =>
                    setCopias(parseInt(value.target.value, 10))
                  }
                  fullWidth
                  error={erros[0] !== ''}
                  helperText={erros[0]}
                />
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 15px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                }}
                disabled={erroExistente || carregando}
                variant="contained"
                color="primary"
                fullWidth
              >
                Salvar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
