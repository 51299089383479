import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // color: 'red',
    marginLeft: '15px',
    maxWidth: '150px',
  },
  avatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    color: 'white',
    backgroundColor: '#f44336',
  },
}));

const DescricaoField = (props) => {
  const classes = useStyles();
  // const { source } = props;
  const record = useRecordContext(props);
  const value = record
    ? record.record.transacao
      ? record.record.transacao.conta.nome
      : ''
    : '';
  const sigla = value.length > 0 ? value.charAt(0) : '';
  // const icon = record ? record.record.conta.icon : '';
  // const color = record ? record.record.transacao.conta.color : '';
  const color = record
    ? record.record.transacao
      ? record.record.transacao.conta.color
      : ''
    : '';

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar className={classes.avatar} style={{ backgroundColor: color }}>
        {sigla}
      </Avatar>
      <Tooltip title={value} placement="bottom" aria-label="add2">
        <Typography className={classes.text}>{value}</Typography>
      </Tooltip>
    </div>
  );
};

DescricaoField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default DescricaoField;
