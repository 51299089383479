import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import {
  TextInput,
  SelectInput,
  CpfInput,
  CnpjInput,
  AutoCompleteRemoto,
} from '../../Inputs';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));
/*
const GenericoInput = forwardRef(({ formato, ...props }, ref) => (
  <TemplateNumberFormatInput {...props} formato={formato} ref={ref} />
)); */

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const optionsTipoConta = [
  {
    label: 'Conta Corrente',
    value: 'conta_corrente',
  },
  {
    label: 'Conta Poupança',
    value: 'conta_poupanca',
  },
  {
    label: 'Conta Pagamento',
    value: 'conta_pagamento',
  },
];

const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose, handleSave }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [titular, setTitular] = useState('');
    const [banco, setBanco] = useState(null);
    const [conta, setConta] = useState('');
    const [agencia, setAgencia] = useState('');
    const [tipo, setTipo] = useState('conta_corrente');
    const [cpf, setCpf] = useState('');
    const [cnpj, setCnpj] = useState('');

    const [id, setId] = useState(DEFAULT_ID);

    const classes = useStyles({ cor: '#009FD4' });

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    async function reiniciar() {
      setId(DEFAULT_ID);
      setTitular('');
      setBanco(null);
      setConta('');
      setAgencia('');
      setTipo('conta_corrente');
      setCpf('');
      setCnpj('');
      setOpen(true);
    }

    async function handleSalvar() {
      try {
        if (id <= 0) {
          handleSave(id, titular, banco, conta, agencia, tipo, cpf, cnpj);
          enqueueSnackbar('Registro bancário criado com sucesso!', {
            variant: 'success',
          });
        } else {
          handleSave(id, titular, banco, conta, agencia, tipo, cpf, cnpj);
          enqueueSnackbar('Registro bancário atualizado com sucesso!', {
            variant: 'success',
          });
        }
        handleCloseDialog();
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleCreate() {
        reiniciar();
        setOpen(true);
      },
      handleEdit(id, titular, banco, conta, agencia, tipo, cpf, cnpj) {
        setTitular(titular);
        setBanco(banco);
        setConta(conta);
        setAgencia(agencia);
        setTipo(tipo);
        setCpf(cpf);
        setCnpj(cnpj);
        setId(id);
        setOpen(true);
      },
    }));

    function validateTitular() {
      let error = '';
      if (!titular) error = 'Este campo é obrigatório';
      else if (titular.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
      return error;
    }

    function validateBanco() {
      let error = '';
      if (!banco) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateConta() {
      let error = '';
      if (!conta) error = 'Este campo é obrigatório';
      else if (conta.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
      return error;
    }

    function validateAgencia() {
      let error = '';
      if (!agencia) error = 'Este campo é obrigatório';
      else if (agencia.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
      return error;
    }

    function validateTipo() {
      const error = '';
      return error;
    }

    function validateCpf() {
      let error = '';
      if (cpf) {
        if (cpf.length < 11) error = 'O CPF deve ter 11 dígitos';
      }
      return error;
    }

    function validateCnpj() {
      let error = '';
      if (cnpj) {
        if (cnpj.length < 14) error = 'O CNPJ deve ter 14 dígitos';
      }
      return error;
    }

    function getErros() {
      const errosOld = [''];
      errosOld[0] = validateTitular();
      errosOld[1] = validateBanco();
      errosOld[2] = validateConta();
      errosOld[3] = validateAgencia();
      errosOld[4] = validateTipo();
      errosOld[5] = validateCpf();
      errosOld[6] = validateCnpj();
      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="sm"
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            {id < 0 ? 'Novo Registro Bancário' : 'Editar Registro Bancário'}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            <div>
              <Box flex={1} mr="0.5em">
                <TextInput
                  name="titular"
                  handleEnter={() => {}}
                  label="Titular"
                  handleKey={() => {}}
                  value={titular}
                  onChange={(value) => setTitular(value.target.value)}
                  error={erros[0] !== ''}
                  helperText={erros[0]}
                  fullWidth
                  autoFocus
                />
              </Box>
              <Box flex={1} mr="0.5em">
                <AutoCompleteRemoto
                  name="banco"
                  handleEnter={() => {}}
                  label="Banco"
                  handleKey={() => {}}
                  value={banco}
                  onChange={(value) => setBanco(value)}
                  error={erros[1] !== ''}
                  helperText={erros[1]}
                  fullWidth
                  toString={false}
                  getOptionSelected={(option, value) => option.nome === value.nome}
                  getOptionLabel={(option) => `${option.nome}`}
                  nested={[]}
                  filters={{}}
                  resource="bancos_fornecedores"
                />
              </Box>
              <Box flex={1} mr="0.5em">
                <TextInput
                  name="conta"
                  handleEnter={() => {}}
                  label="Conta"
                  handleKey={() => {}}
                  value={conta}
                  onChange={(value) => setConta(value.target.value)}
                  error={erros[2] !== ''}
                  helperText={erros[2]}
                  fullWidth
                  // formato="######"
                  disabled={!banco}
                />
              </Box>
              <Box flex={1} mr="0.5em">
                <TextInput
                  name="agencia"
                  handleEnter={() => {}}
                  label="Agência"
                  handleKey={() => {}}
                  value={agencia}
                  onChange={(value) => setAgencia(value.target.value)}
                  error={erros[3] !== ''}
                  helperText={erros[3]}
                  fullWidth
                  // formato="######"
                  disabled={!banco}
                />
              </Box>
              <Box flex={1} mr="0.5em">
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel shrink id="demo-simple-select-label">
                    Tipo Conta
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-label"
                    name="tipo"
                    handleEnter={() => {}}
                    label="Tipo Conta"
                    handleKey={() => {}}
                    value={tipo}
                    onChange={(value) => setTipo(value.target.value)}
                    error={erros[4] !== ''}
                    helperText={erros[4]}
                    options={optionsTipoConta}
                    //
                  />
                </FormControl>
              </Box>
              <Box flex={1} mr="0.5em">
                <CpfInput
                  name="cpf"
                  handleEnter={() => {}}
                  label="CPF"
                  handleKey={() => {}}
                  value={cpf}
                  onChange={(value) => setCpf(value.target.value)}
                  error={erros[5] !== ''}
                  helperText={erros[5]}
                  fullWidth
                />
              </Box>
              <Box flex={1} mr="0.5em">
                <CnpjInput
                  name="cnpj"
                  handleEnter={() => {}}
                  label="CNPJ"
                  handleKey={() => {}}
                  value={cnpj}
                  onChange={(value) => setCnpj(value.target.value)}
                  error={erros[6] !== ''}
                  helperText={erros[6]}
                  fullWidth
                />
              </Box>
            </div>
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={erroExistente}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
