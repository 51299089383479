import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import moment from 'moment';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const ActionsField = ({
  tipo,
  handleEdit = () => {},
  handleDelete = () => {},
  handleDescription = () => {},
  ativo,
  ...rest
}) => {
  const classes = useStyles();
  const record = useRecordContext(rest);

  const isRecorrent = record
    ? record.record
      ? !!record.record.grupo_feriado_id
      : false
    : false;

  const dataData = record ? new Date(record.record.data) : new Date();

  const dataAtualInicio = new Date(moment().startOf('day').format());

  const isPassado = dataAtualInicio.getTime() > dataData.getTime();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {isRecorrent && !isPassado ? (
        <IconButton
          aria-label="edit"
          classes={{ root: classes.root }}
          size="small"
        >
          <AutorenewIcon />
        </IconButton>
      ) : null}
      <Tooltip title="Editar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          classes={{ root: classes.root }}
          size="small"
          onClick={() => handleEdit(record.record)}
          disabled={isPassado}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Deletar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="delete"
          classes={{ root: classes.root }}
          size="small"
          onClick={() => handleDelete(record.record)}
          disabled={isPassado}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

ActionsField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ActionsField;
