import React, { useRef, useState, useEffect, useContext } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';

import { makeStyles, Box, Paper } from '@material-ui/core';

import {
  getNomeProduto,
  POR_PESO_VARIAVEL,
  toStringItem,
  ATALHO_AVANCAR,
  ATALHO_VOLTAR,
  POR_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
} from '../../utils';
import {
  DialogoDeletarComSenha,
  DialogoSenha,
  DialogoSomaPesos,
  Tabela,
  DialogoConsultorPrecos,
  DialogoConfirmacao,
  AutoCompleteLocal,
} from '../DialogoEditarVenda/components';
import { VendaContext } from './context';
import DialogoPermissoesFechamento from './DialogoPermissoesFechamento';
import FormItemVenda from './Form';
import useItens from './hooks/useItens';
import LabelCliente from './Labels/LabelCliente';
import LabelEstoque from './Labels/LabelEstoque';
import LabelTeclasAtalho from './Labels/LabelTeclasAtalho';

const useStyles = makeStyles((theme) => ({
  btn: {
    marginTop: '10px',
    opacity: '0.75',
  },
  footer: {
    height: '15vh',
    padding: '10px',
    paddingBottom: '20px',
  },
  headerLivre: {
    height: '17%',
  },
  bodyLivre: {
    height: '83%',
  },
  rootProduto: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
    fontSize: '32px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    textAlign: 'center',
    opacity: '0.75',
  },
}));

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const PESO_POR_PARTES_ACTION = 'F9';
const DELETAR_ITEM_ACTION = 'F2';
const CONSULTOR_PRECOS_ACTION = 'F3';
const CARREGAR_RESTANTE_ACTION = 'F1';
const MUDAR_CAIXARIA_ACTION = 'F7';

const FrenteVenda = ({ irParaTelaInit }) => {
  const {
    venda: { itens, cliente, vendaId, itensEstoque },
    dispatch,
  } = useContext(VendaContext);
  const refDialogoSenha = useRef(null);
  const refDialogoDeletar = useRef(null);
  const refDialogoPermissoesFechamento = useRef(null);

  const refDialogoConsultorPrecos = useRef(null);
  const {
    addNewItem,
    produto,
    refSearch,
    refSidebarInputs,
    enqueueSnackbar,
    selectProduto,
    handleFinalizarVenda,
    changeCaixaria,
  } = useItens(itens, updateItem, vendaId, cliente, irParaTelaInit);

  const refSidebarPesosPorPartes = useRef(null);
  const refDialogoConfirmacao = useRef(null);

  const classes = useStyles();

  const nomeProduto = getNomeProduto(produto, 'RETIRADA DE MERCADORIA');

  function updateItem(body) {
    dispatch({
      type: 'ADD_ITEM',
      item: body,
    });
    if (refSearch.current) refSearch.current.focus();
  }

  function removeItens(indices) {
    dispatch({ type: 'REMOVE_ITEM', indices });
  }

  function recarregarTodosItens() {
    dispatch({ type: 'CARREGAR_RESTANTE' });
  }

  function openConfirmacaoTelaInit() {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Deseja cancelar a retirada de mercadoria!',
        'Tem certeza que deseja cancelar esta retirada de mercadoria!',
        10,
      );
    }
  }

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        if (itens.length > 0) {
          if (refDialogoPermissoesFechamento.current) {
            refDialogoPermissoesFechamento.current.handleOpen(cliente.id);
          }
        } else {
          enqueueSnackbar(
            'É necessário ao menos um item na retirada de mercadoria!',
            {
              variant: 'warning',
            },
          );
        }
        break;
      case CANCELAR_VENDA_ACTION:
        openConfirmacaoTelaInit();
        break;
      case CONSULTOR_PRECOS_ACTION:
        if (refDialogoConsultorPrecos.current) {
          refDialogoConsultorPrecos.current.handleOpen();
        }
        break;
      case PESO_POR_PARTES_ACTION:
        if (produto ? produto.unidade.tipo === POR_PESO_VARIAVEL : false) {
          if (refSidebarPesosPorPartes.current) {
            refSidebarPesosPorPartes.current.handleOpen();
          }
        }
        break;
      case DELETAR_ITEM_ACTION:
        if (itens.length > 0) {
          if (refDialogoDeletar.current) {
            refDialogoDeletar.current.handleOpen();
          }
        }
        break;
      case CARREGAR_RESTANTE_ACTION:
        if (refDialogoConfirmacao.current) {
          refDialogoConfirmacao.current.handleOpen(
            'Carregar mercadorias restante!',
            'Tem certeza de que deseja fazer a carga de todos os itens restantes desta venda, isso apagará todos os itens que vc registro nessa venda parcial?',
            20,
          );
        }
        break;
      case MUDAR_CAIXARIA_ACTION:
        changeCaixaria();
        break;
      default:
        break;
    }
  }

  function handleConfirma(codigo) {
    switch (codigo) {
      case 10:
        irParaTelaInit();
        break;
      case 20:
        recarregarTodosItens();
        break;
      default:
        break;
    }
  }

  const optionsProdutos = itensEstoque
    .filter((item) => !item.usado)
    .map((item) => ({
      ...item.produto,
      usado: item.usado,
      unidadesFinal: item.unidadesFinal,
      pesoFinal: item.pesoFinal,
      unidadesCaixaFinal: item.unidadesCaixaFinal,
    }));

  return (
    <>
      <Box padding="10px" className={classes.header}>
        <Box margin="0px 0px 10px">
          <Paper className={classes.rootProduto}>{nomeProduto}</Paper>
        </Box>
        <AutoCompleteLocal
          autoFocus
          name="search-input"
          ref={refSearch}
          handleEnter={() => {}}
          label=""
          handleKey={(keyCode, keyName) => {
            handleActions(keyName);
          }}
          value={null}
          onChangeLocal={(value) => selectProduto(value)}
          campo="nome"
          options={optionsProdutos}
          margin="10px"
          fullwidth
          style={{
            backgroundColor: 'white',
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        padding="10px"
        className={classes.body}
      >
        <Box flex={1.5} display="flex" flexDirection="column" overflow="auto">
          <Box>
            {cliente && <LabelCliente cliente={cliente} />}
            {produto && (
              <Box mt="15px">
                <LabelEstoque produto={produto} />
              </Box>
            )}
          </Box>
          <LabelTeclasAtalho
            fontSize="13px"
            grid={[
              [
                { atalho: CANCELAR_VENDA_ACTION, label: 'Voltar' },
                { atalho: FECHAR_VENDA_ACTION, label: 'Concluir' },
                { atalho: CONSULTOR_PRECOS_ACTION, label: 'Cons. Preço' },
                {
                  atalho: MUDAR_CAIXARIA_ACTION,
                  label: 'Mudar Caixaria',
                  status: produto
                    ? produto.unidade.tipo === POR_UNIDADE ||
                      produto.unidade.tipo === POR_PESO_FIXO ||
                      produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
                    : false,
                },
              ],
              [
                {
                  atalho: PESO_POR_PARTES_ACTION,
                  label: 'CGP',
                  status: produto
                    ? produto.unidade.tipo === POR_PESO_VARIAVEL
                    : false,
                },
                {
                  atalho: DELETAR_ITEM_ACTION,
                  label: 'Remover item',
                  status: itens.length > 0,
                },
                {
                  atalho: CARREGAR_RESTANTE_ACTION,
                  label: 'Car. Restante',
                },
              ],
            ]}
          />
        </Box>
        <Box padding="0 10px" flex={3}>
          <Tabela
            rows={toStringItem(itens)}
            headCells={[
              {
                field: 'numeroItem',
                label: 'Cód.',
              },
              {
                field: 'produto',
                label: 'Produto',
              },
              {
                field: 'unidades',
                right: true,
                label: 'Unidades',
              },
              {
                field: 'peso',
                right: true,
                label: 'Peso (Kg)',
              },
            ]}
          />
        </Box>
        <Box flex={1.5} display="flex" flexDirection="column" overflow="auto">
          <FormItemVenda
            handleKey={(keyCode, keyName) => {
              handleActions(keyName);
            }}
            handleNewItem={addNewItem}
            disabledSubmit={produto === null}
            ref={refSidebarInputs}
            produto={produto}
            itensEstoque={itensEstoque}
          />
        </Box>
      </Box>
      <DialogoSenha
        ref={refDialogoSenha}
        handleClose={() => {}}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={() => {
          irParaTelaInit();
        }}
        title="Autorização cancelamento de retirada de venda!"
      />
      <DialogoDeletarComSenha
        ref={refDialogoDeletar}
        itens={itens}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={(numeroItem) => {
          removeItens([itens[numeroItem - 1].uidd]);
        }}
        title="Autorização para remover item!"
      />
      <DialogoConsultorPrecos ref={refDialogoConsultorPrecos} />
      <DialogoSomaPesos
        ref={refSidebarPesosPorPartes}
        onChangeTotal={(totais) => {
          if (refSidebarInputs.current) {
            refSidebarInputs.current.redefineValues(
              totais.unidades,
              totais.peso,
            );
          }
        }}
      />
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
      <DialogoPermissoesFechamento
        ref={refDialogoPermissoesFechamento}
        handlePermitido={(responsavel_id, conferente_id) => {
          handleFinalizarVenda(responsavel_id, conferente_id);
        }}
      />
      <KeyboardEventHandler
        handleKeys={[
          FECHAR_VENDA_ACTION,
          CANCELAR_VENDA_ACTION,
          PESO_POR_PARTES_ACTION,
          DELETAR_ITEM_ACTION,
          CONSULTOR_PRECOS_ACTION,
          CARREGAR_RESTANTE_ACTION,
          MUDAR_CAIXARIA_ACTION,
        ]}
        onKeyEvent={(key) => handleActions(key)}
      />
    </>
  );
};

export default FrenteVenda;
