import React, { useRef, useState, useEffect, useContext } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';

import { makeStyles, Box } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';

import {
  DialogoConfirmacao,
  SearchProduto,
  Actions,
  Label,
  LabelEstoque,
  LabelSubtotal,
  // DialogoProduto,
  DialogoProduto2 as DialogoProduto,
} from '../../../..';
import { getUsername, getListAllAPI } from '../../../../../services';
import { toStringLotes } from '../../../../../utils';
// import DialogoCarregarNota from './DialogoCarregarNota';

import {
  LabelTeclasAtalho,
  DialogoDeletarComSenha,
} from '../../../../DialogoEditarVenda/components';
import { useLotes } from '../../hooks';
import { CompraContext } from '../contextCompra';
import DialogoPrecificacao from './DialogoPrecificacao';
import FormLote from './FormLote';
import Tabela from './Tabela/indexCompra';

const useStyles = makeStyles(() => ({
  btn: {
    marginTop: '10px',
    opacity: '0.75',
  },
  header: {
    /* height: '17%', */
  },
  body: {
    /* height: '83%', */
  },
  footer: {
    height: '15vh',
    padding: '10px',
    paddingBottom: '20px',
  },
}));

const FECHAR_COMPRA_ACTION = 'F8';
const CANCELAR_COMPRA_ACTION = 'F4';
const DELETAR_LOTE_ACTION = 'F2';
const CRIAR_PRODUTO_ACTION = 'F9';
const EDITAR_PRODUTO_ACTION = 'F7';
const CARREGAR_NOTA_ACTION = 'F11';

const FrenteCompra = ({
  irParaTelaInit,
  irParaTelaFinalizarCompra,
  showAtendente = true,
}) => {
  const {
    compra: { lotes, idEdit },
    dispatch,
  } = useContext(CompraContext);
  const [atendente, setAtendente] = useState('');
  const refDialogoConfirmacao = useRef(null);
  const refDialogoDeletar = useRef(null);
  const refDialogoPrecificacao = useRef(null);
  const refDialogoCarregarNota = useRef(null);
  const {
    searchHandle,
    addNewItem,
    setSearch,
    produto,
    subTotal,
    refSearch,
    search,
    refSidebarInputs,
    enqueueSnackbar,
    setProduto,
  } = useLotes(lotes, updateItem);

  const classes = useStyles();
  const refDialogoProduto = useRef(null);

  const nomeProduto =
    produto !== null
      ? produto.nome
      : idEdit > 0
      ? 'Editar Compra'
      : 'Nova Compra';

  function updateItem(body) {
    dispatch({
      type: 'ADD_LOTE',
      lote: body,
    });
    if (refSearch.current) refSearch.current.focus();
  }

  async function getAtendente() {
    const username = getUsername();
    setAtendente(username || '');
  }

  useEffect(() => {
    getAtendente();
  }, []);

  function removeItens(indices) {
    dispatch({ type: 'REMOVE_LOTE', indices });
  }

  function handleConfirma(codigo) {
    switch (codigo) {
      case CANCELAR_COMPRA_ACTION:
        irParaTelaInit();
        break;
      default:
        break;
    }
  }

  function openDialogoCarregarNota() {
    if (refDialogoCarregarNota.current) {
      refDialogoCarregarNota.current.handleOpen();
    }
  }

  function handleActions(action) {
    switch (action) {
      case FECHAR_COMPRA_ACTION:
        if (true) {
          irParaTelaFinalizarCompra();
        } else {
          enqueueSnackbar('É necessário ao menos um lote na compra!', {
            variant: 'warning',
          });
        }
        break;
      case CANCELAR_COMPRA_ACTION:
        if (refDialogoConfirmacao.current) {
          refDialogoConfirmacao.current.handleOpen(
            'Calcelamento da compra',
            'Tem certeza que deseja cancelar essa compra!',
            CANCELAR_COMPRA_ACTION,
          );
        }
        break;
      case DELETAR_LOTE_ACTION:
        if (lotes.length > 0) {
          if (refDialogoDeletar.current) {
            refDialogoDeletar.current.handleOpen();
          }
        }
        break;
      case CRIAR_PRODUTO_ACTION:
        // if (refDialogoProduto.current) refDialogoProduto.current.handleOpen(0);
        if (refDialogoProduto.current) refDialogoProduto.current.handleCreate();
        break;
      case EDITAR_PRODUTO_ACTION:
        // if (refDialogoProduto.current) refDialogoProduto.current.handleOpen(1);
        if (refDialogoProduto.current) {
          const idProduto = produto ? produto.id : 0;
          if (idProduto > 0) {
            refDialogoProduto.current.handleEdit(idProduto);
          }
        }
        break;
      /* case CARREGAR_NOTA_ACTION:
        openDialogoCarregarNota();
        break; */
      default:
        break;
    }
  }

  async function updateProduto(idUpdate) {
    try {
      const data = await getListAllAPI(
        'produtos',
        ['id', 'asc'],
        { id: [idUpdate] },
        ['unidade', 'categoria', 'familia'],
      );
      if (data.data.length > 0) {
        setProduto(data.data[0]);
      } else {
        throw 'Este produto não existe!';
      }
    } catch (erros) {
      // nada
    }
  }

  function middlewareSearchHandle(codigo) {
    if (codigo) {
      if (refDialogoPrecificacao.current)
        refDialogoPrecificacao.current.handleOpen(codigo);
    }
    searchHandle(codigo);
  }

  return (
    <>
      <Box padding="10px" className={classes.header}>
        <Box margin="0px 0px 10px">
          <Label label={nomeProduto} />
        </Box>
        <SearchProduto
          label="Pesquisar produto"
          value={search}
          onChange={(e) => setSearch(e)}
          fullwidth
          searchHandle={middlewareSearchHandle}
          ref={refSearch}
          handleKey={(keyCode, keyName) => {
            handleActions(keyName);
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        padding="10px"
        className={classes.body}
      >
        <Box
          flex={1.5}
          display="flex"
          flexDirection="column"
          /* overflow="auto" */
        >
          <div style={{ marginBottom: '15px' }}>
            <Actions
              opcoes={[
                {
                  text: `Cancelar (${CANCELAR_COMPRA_ACTION})`,
                  value: CANCELAR_COMPRA_ACTION,
                  hide: false,
                  disabled: false,
                },
                {
                  text: `Fechar(${FECHAR_COMPRA_ACTION})`,
                  value: FECHAR_COMPRA_ACTION,
                  hide: false,
                  /* disabled: !(lotes.length > 0), */
                },
                {
                  text: `Del. Lote (${DELETAR_LOTE_ACTION})`,
                  value: DELETAR_LOTE_ACTION,
                  hide: false,
                  disabled: !(lotes.length > 0),
                },
                {
                  text: `Novo Prod. (${CRIAR_PRODUTO_ACTION})`,
                  value: CRIAR_PRODUTO_ACTION,
                  hide: false,
                  disabled: false,
                },
                {
                  text: `Editar Prod. (${EDITAR_PRODUTO_ACTION})`,
                  value: EDITAR_PRODUTO_ACTION,
                  hide: false,
                  disabled: !produto,
                },
                /* {
                  text: 'Carregar Nota',
                  value: CARREGAR_NOTA_ACTION,
                  hide: false,
                  disabled: lotes.length > 0,
                }, */
              ]}
              onClick={handleActions}
            />
          </div>

          {/*  { showAtendente && <LabelAtendente atendente={atendente} />} */}
          {produto && (
            <LabelSubtotal valor={subTotal} isOpaco={produto !== null} />
          )}
          {produto && <LabelEstoque produto={produto} />}
          {/* <LabelTeclasAtalho
            fontSize="12px"
            mt={produto ? '15px' : '0px'}
            grid={[
              [
                { atalho: CANCELAR_COMPRA_ACTION, label: 'Cancelar' },
                { atalho: FECHAR_COMPRA_ACTION, label: 'Fechar', status: lotes.length > 0 },
                { atalho: DELETAR_LOTE_ACTION, label: 'Del. Lote', status: lotes.length > 0 },
              ],
              [
                { atalho: CRIAR_PRODUTO_ACTION, label: 'Novo Prod.' },
                { atalho: EDITAR_PRODUTO_ACTION, label: 'Edit. Prod.', status: !!produto },
              ],
            ]}
          /> */}
        </Box>
        <Box padding="0 10px" flex={4}>
          {/* <Table
            removeItens={removeItens}
            rows={lotes}
            headCells={[
              {
                id: 'produto',
                numeric: false,
                disablePadding: true,
                label: 'Produto',
              },
              {
                id: 'validade',
                numeric: true,
                disablePadding: false,
                label: 'Validade',
              },
              {
                id: 'unidades', numeric: true, disablePadding: false, label: 'Unidades',
              },
              {
                id: 'peso', numeric: true, disablePadding: false, label: 'Peso (Kg)',
              },
              {
                id: 'unitario', numeric: true, disablePadding: false, label: 'Unitário',
              },
              {
                id: 'unitarioImposto', numeric: true, disablePadding: false, label: 'Unitário Imposto',
              },
              {
                id: 'cg', numeric: true, disablePadding: false, label: 'CG',
              },
              {
                id: 'total', numeric: true, disablePadding: false, label: 'SubTotal',
              },
            ]}
            toString={toStringLotes}
          /> */}
          <Tabela
            rows={toStringLotes(lotes)}
            headCells={[
              {
                field: 'numeroLote',
                label: 'Cód.',
              },
              /*  {
                field: 'produto',
                label: 'Produto',
              }, */
              {
                field: 'validade',
                label: 'Validade',
                right: true,
              },
              {
                field: 'unidades',
                label: 'Unidades',
                right: true,
              },
              {
                field: 'peso',
                label: 'Peso (Kg)',
                right: true,
              },
              {
                field: 'unitario',
                label: 'Unitário',
                right: true,
              },
              {
                field: 'unitarioImposto',
                label: 'Unitário Imposto',
                right: true,
              },
              {
                field: 'cg',
                label: 'CG',
                right: true,
              },
              {
                field: 'total',
                label: 'SubTotal',
                right: true,
              },
            ]}
          />
        </Box>
        <Box flex={1.5} display="flex" flexDirection="column" overflow="auto">
          <FormLote
            handleKey={(keyCode, keyName) => {
              handleActions(keyName);
            }}
            handleNewItem={addNewItem}
            disabledSubmit={produto === null}
            ref={refSidebarInputs}
            produto={produto}
          />
          {!produto && (
            <LabelSubtotal valor={subTotal} isOpaco={produto === null} />
          )}
        </Box>
      </Box>
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
      <DialogoProduto
        ref={refDialogoProduto}
        /* handleCriarProduto={(produtoAtualizado) => {
          setProduto(produtoAtualizado);
        }}
        handleCloseDialogo={() => {
          if (refSidebarInputs.current) refSidebarInputs.current.focus();
        }}
        produto={produto} */
        handleClose={(idUpdate) => {
          if (refSidebarInputs.current) refSidebarInputs.current.focus();
          if (idUpdate > 0) {
            updateProduto(idUpdate);
          }
        }}
      />
      {/* <DialogoDeletarItemTabela
        ref={refDialogoDeletar}
        itens={lotes}
        labelResource="Lotes"
        handleDelete={(indices) => removeItens(indices)}
        headerDialogo={(
          <div style={{
            display: 'flex', justifyContent: 'space-between', paddingLeft: '56px', marginBottom: '20px', color: 'red',
          }}
          >
            <div style={{ flex: 3 }}>
              Produto
            </div>
            <div style={{ flex: 1 }}>
              Unidades
            </div>
            <div style={{ flex: 1 }}>
              Peso
            </div>
            <div style={{ flex: 1 }}>
              Unitário
            </div>
            <div style={{ flex: 1 }}>
              Unitário Imposto
            </div>
            <div style={{ flex: 1 }}>
              Validade
            </div>
            <div style={{ flex: 1 }}>
              CG
            </div>
            <div style={{ flex: 1 }}>
              SubTotal
            </div>
          </div>
            )}
        getListItemContent={(value, labelId) => (
          <ListItemText
            id={labelId}
            primary={(
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flex: 4 }}>
                  {value.produto.nome}
                </div>
                <div style={{ flex: 1 }}>
                  {disableUnidades(value.produto) ? value.unidades : '-'}
                </div>
                <div style={{ flex: 1 }}>
                  {disablePeso(value.produto) ? formatPeso(value.peso) : '-'}
                </div>
                <div style={{ flex: 1 }}>
                  {formatMoeda(value.unitario)}
                </div>
                <div style={{ flex: 1 }}>
                  {formatMoeda(value.unitarioImposto)}
                </div>
                <div style={{ flex: 1 }}>
                  {formatMoeda(value.cg)}
                </div>
                <div style={{ flex: 1 }}>
                  {moment(new Date(value.validade)).format('DD/MM/YYYY')}
                </div>
                <div style={{ flex: 1 }}>
                  {formatMoeda(value.total)}
                </div>
              </div>
              )}
          />
        )}
      /> */}

      <DialogoDeletarComSenha
        ref={refDialogoDeletar}
        itens={lotes}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={(numeroItem) => {
          removeItens([lotes[numeroItem - 1].uidd]);
        }}
        title="Autorização para remover item!"
      />
      <DialogoPrecificacao
        ref={refDialogoPrecificacao}
        handleClose={(dados) => {
          if (dados) {
            if (refSidebarInputs.current) {
              refSidebarInputs.current.valuesPrecificacao(dados);
            }
          }
        }}
        idCompra={idEdit}
      />
      {/* <DialogoCarregarNota
        ref={refDialogoCarregarNota}
        handleClose={() => {}}
        handleSalvarItensFormatados={(data) => }
      /> */}
      <KeyboardEventHandler
        handleKeys={[
          FECHAR_COMPRA_ACTION,
          CANCELAR_COMPRA_ACTION,
          DELETAR_LOTE_ACTION,
          CRIAR_PRODUTO_ACTION,
          EDITAR_PRODUTO_ACTION,
        ]}
        onKeyEvent={(key) => handleActions(key)}
      />
    </>
  );
};

export default FrenteCompra;
