import React from 'react';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';

import ContentCreate from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import { linkToRecord } from 'ra-core';

const EditButton = ({
  query = '',
  basePath = '',
  label = 'ra.action.edit',
  record,
  icon = defaultIcon,
  resource,
  createquery = (record) => ``,
  disabled = () => false,
  deleteHundle = () => {},
  ...rest
}) => (
  <Button
    // component={Link}
    /* to={
      linkToRecord(basePath, record && record.id) + query + createquery(record)
    } */
    label={label}
    onClick={(e) => {
      stopPropagation(e);
      deleteHundle({ resource, ...record });
    }}
    {...rest}
    disabled={disabled(record)}
  >
    {icon}
  </Button>
);
const defaultIcon = <DeleteIcon />;

const stopPropagation = (e) => e.stopPropagation();

EditButton.propTypes = {
  basePath: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  record: PropTypes.any,
  query: PropTypes.string,
};

export default EditButton;
