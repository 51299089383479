import { useEffect, useRef, useState } from 'react';

import { useSnackbar } from 'notistack';

import { api, getListAllAPI, getUserId } from '../../../../services';

const toleranciaResto = 10;

function useParcelasCompra(
  parcelas,
  itens,
  fornecedor,
  subtotal,
  updateParcela,
  editarModo,
  idEdit,
  numeroNotaFiscal = '',
  loja,
  data_faturamento,
  editarPrazosFornecedor,
) {
  const { enqueueSnackbar } = useSnackbar();
  const refDialogoNota = useRef(null);
  const [podeFecharCompra, setPodeFecharCompra] = useState(true);

  function getValorRestante() {
    let soma = 0;
    for (let i = 0; i < parcelas.length; i += 1) {
      soma += parcelas[i].valor;
    }
    return subtotal - soma;
  }

  function getTotalParcelas() {
    let soma = 0;
    for (let i = 0; i < parcelas.length; i += 1) {
      soma += parcelas[i].valor;
    }
    return soma;
  }

  const resto = getValorRestante();
  const totalParcelas = getTotalParcelas();

  function getMessagesError() {
    const errors = [];
    if (fornecedor === null) {
      errors.push({
        message: "Campo 'fornecedor' não foi preenchido!",
        type: 'warning',
      });
    }
    if (loja === null) {
      errors.push({
        message: "Campo 'loja' não foi preenchido!",
        type: 'warning',
      });
    }
    if (parcelas.length <= 0) {
      errors.push({
        message: 'É necessário ao menos um registro de parcela!',
        type: 'warning',
      });
    }
    if (numeroNotaFiscal !== '') {
      if (numeroNotaFiscal.length < 9) {
        errors.push({
          message: 'Número de nota fiscal em formato inválido!',
          type: 'warning',
        });
      }
      if (data_faturamento === '') {
        errors.push({
          message: 'Data faturamento inválida!',
          type: 'warning',
        });
      }
    }
    const erroDistribuicao =
      resto > toleranciaResto || resto < -1 * toleranciaResto;
    if (erroDistribuicao) {
      errors.push({
        message:
          'O valor total da compra não foi integralmente distribuído nas parcelas!',
        type: 'warning',
      });
    }
    return errors;
  }

  useEffect(() => {
    if (getMessagesError().length === 0) {
      enqueueSnackbar('Agora você pode finalizar a compra!!!', {
        variant: 'success',
      });
    }
  }, [parcelas]);

  function formatDate(date) {
    if (date === '') {
      return null;
    }
    const dataQuebrada = date.split('-');
    const ano = parseInt(dataQuebrada[0], 10);
    const mes = parseInt(dataQuebrada[1], 10) - 1;
    const dia = parseInt(dataQuebrada[2], 10);
    const dataNova = new Date();
    dataNova.setFullYear(ano);
    dataNova.setMonth(mes);
    dataNova.setDate(dia);
    return dataNova;
  }

  function montarObjVenda(prazos) {
    const listaItens = [];
    const listaParcelas = [];

    for (let i = 0; i < itens.length; i += 1) {
      listaItens.push({
        peso: itens[i].peso,
        unidades: itens[i].unidades,
        preco_compra: itens[i].unitario,
        preco_compra_imposto: itens[i].unitarioImposto,
        produto_id: itens[i].produto.id,
        validade: itens[i].validade,
        idIndicadorChange: itens[i].idIndicadorChange,
        cg: itens[i].cg,
      });
    }

    const parcelasCopy = [...parcelas];

    parcelasCopy.sort(
      (a, b) =>
        new Date(a.data_pagamento).getTime() -
        new Date(b.data_pagamento).getTime(),
    );

    for (let i = 0; i < parcelasCopy.length; i += 1) {
      listaParcelas.push({
        conta_id: parcelasCopy[i].conta ? parcelasCopy[i].conta.id : null,
        cartao_credito_id: parcelasCopy[i].cartao
          ? parcelasCopy[i].cartao.id
          : null,
        data_pagamento: parcelasCopy[i].data_pagamento,
        valor: parcelasCopy[i].valor,
        status: parcelasCopy[i].status,
        fatura: parcelasCopy[i].fatura,
        idIndicadorChange: parcelasCopy[i].idIndicadorChange,
        isCartao: parcelasCopy[i].isCartao,
        codigo_boleto: parcelasCopy[i].codigo_boleto,
        dias: prazos[i].dias,
        categoria_problema_despesa_id: parcelasCopy[i].problema
          ? parcelasCopy[i].problema.id
          : null,
        descricao_problema: parcelasCopy[i].descricao_problema,
        agendamento_user_id: parcelasCopy[i].agendamento_user_id,
        desconto: parcelasCopy[i].desconto,
      });
    }

    return {
      listaItens,
      listaParcelas,
      data: new Date(),
      fornecedor_id: fornecedor.id,
      sessao_id: 0,
      numero_nota_fiscal: numeroNotaFiscal,
      data_faturamento:
        numeroNotaFiscal === '' ? null : formatDate(data_faturamento),
      loja: loja ? loja.value : 'CF',
      user_id: getUserId(),
    };
  }

  async function submitCompra(prazos) {
    try {
      const resource = 'compras';
      if (editarModo === false) {
        const objVenda = montarObjVenda(prazos);
        const data = await api.post(`/${resource}/fc`, {
          ...objVenda,
        });
        return data.data.id;
      }
      const objVenda = montarObjVenda(prazos);
      const data = await api.post(`/${resource}/fc/${idEdit}`, {
        ...objVenda,
      });
      return data.data.id;
    } catch (e) {
      return -1;
    }
  }

  function handleOpenNota2(idCompra) {
    if (refDialogoNota.current) {
      refDialogoNota.current.handleOpen(idCompra, 'compra');
    }
  }

  async function getNumberPrazosParcelas(fornecedor_id) {
    const data = await getListAllAPI(
      'prazos_parcelas_fornecedores',
      ['dias', 'asc'],
      { fornecedor_id },
      [],
    );
    return data.data;
  }

  async function handleOpenNota() {
    const errors = getMessagesError();
    if (errors.length <= 0 && podeFecharCompra) {
      const numeroPrazosFornecedor = await getNumberPrazosParcelas(
        fornecedor.id,
      );
      if (numeroPrazosFornecedor.length === parcelas.length) {
        setPodeFecharCompra(false);
        const idCompra = await submitCompra(numeroPrazosFornecedor);
        if (idCompra > 0) {
          handleOpenNota2(idCompra);
        } else {
          setPodeFecharCompra(true);
          enqueueSnackbar('Erro ao registrar a compra!', {
            variant: 'error',
          });
        }
      } else {
        if (numeroPrazosFornecedor.length < parcelas.length) {
          enqueueSnackbar(
            'Número de parcelas maior que o número de parcelas disponibilizadas pelo fornecedor!',
            {
              variant: 'error',
            },
          );
        } else {
          enqueueSnackbar(
            'Número de parcelas menor que o número de parcelas disponibilizadas pelo fornecedor!',
            {
              variant: 'error',
            },
          );
        }
        editarPrazosFornecedor();
        setPodeFecharCompra(true);
      }
    } else {
      for (let i = 0; i < errors.length; i += 1) {
        enqueueSnackbar(errors[i].message, {
          variant: errors[i].type,
        });
      }
    }
  }

  function normalizaItens(itensLocal) {
    const listaItens = [];

    for (let i = 0; i < itensLocal.length; i += 1) {
      listaItens.push({
        peso: itensLocal[i].peso,
        unidades: itensLocal[i].unidades,
        preco_compra: itensLocal[i].unitario,
        preco_compra2: itensLocal[i].unitario,
        produto: itensLocal[i].produto,
        validade: itensLocal[i].validade,
      });
    }

    return listaItens;
  }

  function normalizaParcelas(parcelasLocal) {
    const listaParcelas = [];

    for (let i = 0; i < parcelasLocal.length; i += 1) {
      listaParcelas.push({
        valor: parcelasLocal[i].valor,
        despesa: {
          conta: parcelasLocal[i].conta,
          data_pagamento: parcelasLocal[i].data_pagamento,
          valor: parcelasLocal[i].valor,
          status: parcelasLocal[i].status,
        },
      });
    }

    return listaParcelas;
  }

  function handleOpenNotaLocal() {
    if (refDialogoNota.current) {
      refDialogoNota.current.handleOpenLocal(
        normalizaItens(itens),
        normalizaParcelas(parcelas),
        fornecedor,
        'compra',
      );
    }
  }

  function handleNewItem({
    valor,
    conta,
    data_pagamento,
    status,
    cartao,
    isCartao,
    fatura,
    codigo_boleto,
    problema,
    descricao_problema,
    agendamento_user_id,
    desconto,
  }) {
    updateParcela({
      data_pagamento,
      conta,
      valor,
      uidd: `${isCartao ? cartao.nome : conta.nome}${parcelas.length}`,
      idIndicadorChange: -1,
      status: isCartao ? false : conta.id === 1 ? false : status,
      cartao,
      isCartao,
      fatura,
      codigo_boleto,
      problema,
      descricao_problema,
      agendamento_user_id,
      desconto,
    });
  }

  return {
    resto,
    totalParcelas,
    handleOpenNota,
    refDialogoNota,
    handleNewItem,
    handleOpenNotaLocal,
    enqueueSnackbar,
    podeFecharCompra,
  };
}

export default useParcelasCompra;
