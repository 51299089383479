import { createContext } from 'react';

export const CompraContext = createContext({
  compra: {
    fornecedor: null,
    lotes: [],
    parcelas: [],
    subTotal: 0,
    idEdit: -1,
    numeroNotaFiscal: '',
    data_faturamento: '',
    data_criacao: new Date(),
    loja: {
      nome: 'CARUARU FRIOS',
      value: 'CF',
    },
  },
  dispatch: () => {},
});
