import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // maxWidth: '150px',
    // color: 'red',
  },
}));

const DescricaoField = (props) => {
  const classes = useStyles();

  const value = props ? props.record.nome : '';

  return (
    <Tooltip title={value} placement="bottom" aria-label="add2">
      <Typography className={classes.text}>{value}</Typography>
    </Tooltip>
  );
};

DescricaoField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default DescricaoField;
