import React from 'react';
import { Layout } from 'react-admin';

import Menu from './Menu';
import Appbar from './Toolbar';

const LayoutCustom = (props) => (
  <Layout {...props} appBar={Appbar} menu={Menu} />
);

export default LayoutCustom;
