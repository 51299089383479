import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { SearchProduto, AutoCompleteRemoto } from '../../Components';
import DialogoCreateImpressao from '../../Components/Dialogos/DialogoProduto2/DialogoCreateImpressao';
import { getListAllAPI, api } from '../../services';
import {
  TIPO_OFERTA_PRODUTO_JORNAL,
  TIPO_OFERTA_PRODUTO_LIBERACAO,
  TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE,
  STATUS_ATIVO,
} from '../../utils';
import DialogoOfertaJornal from './DialogoOfertaJornal';
import DialogoOfertaLiberacao from './DialogoOfertaLiberacao';
import DialogoOfertaZerarEstoque from './DialogoZerarEstoque';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [tipo, setTipo] = useState(TIPO_OFERTA_PRODUTO_JORNAL);
  const [search, setSearch] = useState('');
  const [produto, setProduto] = useState(null);

  const refDialogoOfertaLiberacao = useRef(null);
  const refDialogoOfertaZerarEstoque = useRef(null);
  const refDialogoOfertaJornal = useRef(null);

  const refDialogoImpressao = useRef(null);

  const classes = useStyles();

  const refSearch = useRef(null);

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  useImperativeHandle(ref, () => ({
    handleCreate(tipo) {
      setOpen(true);
      setProduto(null);
      setSearch('');
      setTipo(tipo);
    },
    handleEdit(id, tipo) {
      setOpen(false);
      switch (tipo) {
        case TIPO_OFERTA_PRODUTO_JORNAL:
          if (refDialogoOfertaJornal.current) {
            refDialogoOfertaJornal.current.handleEdit(id);
          }
          break;
        case TIPO_OFERTA_PRODUTO_LIBERACAO:
          if (refDialogoOfertaLiberacao.current) {
            refDialogoOfertaLiberacao.current.handleEdit(id);
          }
          break;
        case TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE:
          if (refDialogoOfertaZerarEstoque.current) {
            refDialogoOfertaZerarEstoque.current.handleEdit(id);
          }
          break;
      }
    },
  }));

  const handleChange = (event) => {
    setTipo(event.target.value);
  };

  function analisaCodigo(codigo) {
    if (codigo.length === 7 && codigo.charAt(0) === '2') {
      return {
        tipo: 0,
        data: {
          codigo_interno: codigo.substring(1, 7),
        },
      };
    }
    return {
      tipo: 1,
      data: {},
    };
  }

  /*async function verificaOfertaExistente(produto_id) {
    const response = await api.get(
      `/verifyca-oferta-jornal-produto-existente/${produto_id}`,
    );

    const { status } = response.data;

    return { status };
  }*/

  async function verificaOfertaExistente(produto_id) {
    switch (tipo) {
      case TIPO_OFERTA_PRODUTO_JORNAL: {
        const response = await api.get(
          `/verifyca-oferta-jornal-produto-existente/${produto_id}`,
        );

        const { status } = response.data;

        return { status, message: 'Este produto já possui Oferta no Jornal!' };
      }
      case TIPO_OFERTA_PRODUTO_LIBERACAO: {
        const response = await api.get(
          `/verifyca-oferta-liberacao-produto-existente/${produto_id}`,
        );

        const { status } = response.data;

        return {
          status,
          message: 'Este produto já possui Liberação de Preço!',
        };
      }
      case TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE: {
        const response = await api.get(
          `/verifyca-oferta-zerar-estoque-produto-existente/${produto_id}`,
        );

        const { status } = response.data;

        return {
          status,
          message: 'Este produto já possui Oferta de zerar estoque!',
        };
      }
    }
    return { status: false };
  }

  async function searchHandle(codigo) {
    const { tipo, data } = analisaCodigo(codigo);
    if (tipo === 0) {
      const { codigo_interno, peso } = data;
      const produtoData = await getListAllAPI(
        'produtos',
        ['id', 'asc'],
        {
          codigo_interno,
          is_kit: false,
          is_caixa: false,
          status: STATUS_ATIVO,
        },
        ['unidade'],
      );
      if (produtoData.data.length > 0) {
        const { status } = await verificaOfertaExistente(
          produtoData.data[0].id,
        );
        if (status) {
          setProduto(produtoData.data[0]);
        } else {
          setProduto(null);
          if (refSearch.current) refSearch.current.focus();
          enqueueSnackbar(`Este produto já possui Oferta no Jornal!`, {
            variant: 'error',
          });
        }
      } else {
        setProduto(null);
        if (refSearch.current) refSearch.current.focus();
        enqueueSnackbar(
          `Produto não encontrado com este codigo interno: ${codigo_interno}!`,
          {
            variant: 'error',
          },
        );
      }
    } else {
      const produtoData = await getListAllAPI(
        'produtos',
        ['id', 'asc'],
        {
          codigo,
          is_kit: false,
          is_caixa: false,
          status: STATUS_ATIVO,
        },
        ['unidade'],
      );
      if (produtoData.data.length > 0) {
        const { status } = await verificaOfertaExistente(
          produtoData.data[0].id,
        );
        if (status) {
          setProduto(produtoData.data[0]);
        } else {
          setProduto(null);
          if (refSearch.current) refSearch.current.focus();
          enqueueSnackbar(`Este produto já possui Oferta no Jornal!`, {
            variant: 'error',
          });
        }
      } else {
        setProduto(null);
        if (refSearch.current) refSearch.current.focus();
        enqueueSnackbar(
          `Produto não encotrado com este codigo de barras: ${codigo}!`,
          {
            variant: 'error',
          },
        );
      }
    }

    setSearch('');
  }

  function handleSalvar(produto) {
    setOpen(false);
    switch (tipo) {
      case TIPO_OFERTA_PRODUTO_JORNAL:
        if (refDialogoOfertaJornal.current) {
          refDialogoOfertaJornal.current.handleCreate(produto);
        }
        break;
      case TIPO_OFERTA_PRODUTO_LIBERACAO:
        if (refDialogoOfertaLiberacao.current) {
          refDialogoOfertaLiberacao.current.handleCreate(produto);
        }
        break;
      case TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE:
        if (refDialogoOfertaZerarEstoque.current) {
          refDialogoOfertaZerarEstoque.current.handleCreate(produto);
        }
        break;
    }
    /*setProduto(null);
    setSearch('');
    setTipo(TIPO_OFERTA_PRODUTO_JORNAL);*/
  }

  const nomeProduto = produto ? produto.nome : '';

  function handleCloseLocal(status, idProduto) {
    switch (status) {
      case 0:
        handleCloseDialog();
        if (idProduto > 0) {
          handleCreateImpressaoEtiqueta(idProduto);
        }
        break;
      case -1:
        setOpen(true);
        /*setProduto(null);
        setSearch('');
        setTipo(TIPO_OFERTA_PRODUTO_JORNAL);*/
        break;
      default:
        handleCloseDialog();
        break;
    }
  }

  useEffect(() => {
    if (produto) {
      handleSalvar(produto);
    }
  }, [produto]);

  async function changeProduto(produto) {
    if (produto) {
      const { status, message } = await verificaOfertaExistente(produto.id);
      if (status) {
        setProduto(produto);
      } else {
        setProduto(null);
        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    }
  }

  function handleCreateImpressaoEtiqueta(id) {
    if (id > 0) {
      if (refDialogoImpressao.current) {
        refDialogoImpressao.current.handleCreate(id);
      }
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{
            backgroundColor: '#00796b',
            color: 'white',
          }}
        >
          Definir Oferta
        </DialogTitle>
        <DialogContent
          style={{
            padding: '20px 36px 20px',
            margin: '0',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                Tipo de Oferta
              </InputLabel>
              <Select value={tipo} onChange={handleChange} label="Você deseja">
                <MenuItem value={TIPO_OFERTA_PRODUTO_JORNAL}>
                  Jornal de Oferta
                </MenuItem>
                <MenuItem value={TIPO_OFERTA_PRODUTO_LIBERACAO}>
                  Liberação de Preço
                </MenuItem>
                <MenuItem value={TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE}>
                  Zerar Estoque
                </MenuItem>
              </Select>
            </FormControl>
            <AutoCompleteRemoto
              name="produto"
              handleEnter={() => {}}
              label="Produto"
              handleKey={() => {}}
              value={produto}
              onChange={(value) => changeProduto(value)}
              fullWidth
              toString={false}
              getOptionSelected={(option, value) => option.nome === value.nome}
              getOptionLabel={(option) => `${option.nome}`}
              filters={{ status: STATUS_ATIVO }}
              resource="produtos/search/autocomplete"
              nested={['unidade']}
            />
            {/*<SearchProduto
              label="Pesquisar produto"
              value={search}
              onChange={(e) => setSearch(e)}
              fullwidth
              searchHandle={searchHandle}
              handleKey={(keyCode, keyName) => {}}
              filters={{ status: STATUS_ATIVO }}
          />*/}
            {produto && (
              <div>
                <h2
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {nomeProduto}
                </h2>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions
          style={{
            padding: '32px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={handleCloseDialog}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="contained"
            color="primary"
          >
            Cancelar
          </Button>
          <Box>
            <div className={classes.wrapper}>
              <Button
                //onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                variant="contained"
                color="primary"
                disabled={!produto}
              >
                Prosseguir
              </Button>
            </div>
          </Box>
        </DialogActions>
        <DialogoOfertaJornal
          ref={refDialogoOfertaJornal}
          handleClose={handleCloseLocal}
        />
        <DialogoOfertaLiberacao
          ref={refDialogoOfertaLiberacao}
          handleClose={handleCloseLocal}
        />
        <DialogoOfertaZerarEstoque
          ref={refDialogoOfertaZerarEstoque}
          handleClose={handleCloseLocal}
        />
        <DialogoCreateImpressao
          ref={refDialogoImpressao}
          handleClose={() => {}}
        />
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
