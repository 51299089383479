import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useSnackbar } from 'notistack';

import {
  TextInput,
  IntegerInput,
  AutoCompleteRemoto,
  AutoCompleteLocal,
  DateTimeInputBonito,
  TimeInputBonito,
  DateInputBonito,
} from '../../../Components';
import {
  createAPI,
  updateAPI,
  getListAllAPI,
  getUserId,
} from '../../../services';
import {
  TIPO_RECORRENCIA_NAO_RECORRENCIA,
  TIPO_RECORRENCIA_DIARIA_NORMAL,
  STATUS_ATIVO,
} from '../../../utils';
import DialogoCreateDelegacoes from './DialogoCreateEditDelegacoes';
import DialogoCreateEditSubItens from './DialogoCreateEditSubAtividade';
import DialogoDeletarDelelgacoes from './DialogoDeletarDelegacoes';
import DialogoDeletarSubItens from './DialogoDeletarSubAtividade';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    //borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {
  const { row, editarSubTarefa, deletarSubTarefa } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell
          style={{
            width: 'min-content',
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.nome}
        </TableCell>
        <TableCell component="th" scope="row" />
        <TableCell component="th" scope="row" />
        <TableCell component="th" scope="row" />
        <TableCell align="right">
          <Tooltip title="Editar" placement="bottom" aria-label="add2">
            <IconButton
              aria-label="editar"
              size="small"
              onClick={() => editarSubTarefa(row.id, row.nome, row.detalhes)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar" placement="bottom" aria-label="add2">
            <IconButton
              aria-label="editar"
              size="small"
              onClick={() => deletarSubTarefa(row.id, row.nome, row.detalhes)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>{`Detalhes: ${row.detalhes}`}</Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const optionsTipoRecorrencia = [
  { value: TIPO_RECORRENCIA_NAO_RECORRENCIA, label: 'Sem Recorrência' },
  {
    value: TIPO_RECORRENCIA_DIARIA_NORMAL,
    label: 'Recorrência Diária',
  },
];

function getTipoRecorrencia(value) {
  switch (value) {
    case TIPO_RECORRENCIA_NAO_RECORRENCIA:
      return optionsTipoRecorrencia[0];
    case TIPO_RECORRENCIA_DIARIA_NORMAL:
      return optionsTipoRecorrencia[1];
    default:
      return null;
  }
}

const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [nome, setNome] = useState('');
  const [detalhes, setDetalhes] = useState('');
  const [id, setId] = useState(DEFAULT_ID);
  const [subAtividades, setSubAtividades] = useState([]);
  const [contadorId, setContadorId] = useState(1);
  const [contadorIdDelegacoes, setContadorIdDelegacoes] = useState(1);
  const [itens, setItens] = useState([]);

  const [carregando, setCarregando] = useState(false);

  const refDialogoSubItens = useRef(null);
  const refDialogoDeletarSubItens = useRef(null);

  const refDialogoDelegacao = useRef(null);
  const refDialogoDeletarDelegacao = useRef(null);

  const [value, setValue] = React.useState(0);

  const [urgencia, setUrgencia] = useState(10);

  const [importancia, setImportancia] = useState(10);

  const [tipoRecorrencia, setTipoRecorrencia] = useState('');

  const [departamento, setDepartamento] = useState(null);

  const [tempoPrevisto, setTempoPrevisto] = useState(new Date());

  const [dataEntrega, setDataEntrega] = useState(new Date());

  const classes = useStyles({ cor: '#009FD4' });

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function reiniciar() {
    setId(DEFAULT_ID);
    setNome('');
    setDetalhes('');
    setSubAtividades([]);
    setContadorId(1);
    setContadorIdDelegacoes(1);
    setItens([]);
    setDepartamento(null);
    setTempoPrevisto(null);
    setUrgencia(10);
    setImportancia(10);
    setTipoRecorrencia(optionsTipoRecorrencia[0]);
    setValue(0);
    setDataEntrega(new Date());
    setCarregando(false);
  }

  function formatSubAtividades(itens) {
    return itens.map((item) => ({
      id: item.id,
      nome: item.nome,
      detalhes: item.detalhes,
    }));
  }

  function formatItensDelegacoes(itens) {
    const formatItens = [];
    for (let i = 0; i < itens.length; i++) {
      formatItens.push({
        id: itens[i].id,
        locais: itens[i].locais,
        colaboradores: itens[i].colaboradores,
        gestor: itens[i].gestor,
      });
    }
    return formatItens;
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'atividades_processos',
        ['id', 'asc'],
        { id: [id] },
        [
          'subAtividades',
          'delegacoes.colaboradores',
          'delegacoes.locais',
          'delegacoes.gestor',
          'departamento',
        ],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setNome(data.data[0].nome);
        setDetalhes(data.data[0].detalhes);
        setSubAtividades(formatSubAtividades(data.data[0].subAtividades));
        setItens(formatItensDelegacoes(data.data[0].delegacoes));
        setDepartamento(data.data[0].departamento);

        setUrgencia(data.data[0].grau_urgencia);
        setImportancia(data.data[0].grau_importancia);
        setTipoRecorrencia(getTipoRecorrencia(data.data[0].tipo_recorrencia));

        setDataEntrega(
          data.data[0].data_entrega
            ? new Date(data.data[0].data_entrega)
            : null,
        );

        setTempoPrevisto(
          data.data[0].tempo_previsto
            ? new Date(data.data[0].tempo_previsto)
            : null,
        );

        setValue(0);

        setCarregando(false);
      } else {
        throw 'Esta Atividade de processo não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  function formatItensSave(itens) {
    return itens.map(({ locais, colaboradores, gestor }) => ({
      locais: locais.map(({ id }) => id),
      colaboradores: colaboradores.map(({ id }) => id),
      gestor_id: gestor.id,
    }));
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      if (id <= 0) {
        await createAPI('atividades_processos', {
          nome,
          detalhes,
          subAtividades,
          itens: formatItensSave(itens),
          departamento_colaborador_id: departamento ? departamento.id : null,
          criador_id: getUserId(),
          grau_urgencia: urgencia,
          grau_importancia: importancia,
          tipo_recorrencia: tipoRecorrencia.value,
          tempo_previsto: tempoPrevisto,
          data_entrega:
            tipoRecorrencia.value === TIPO_RECORRENCIA_NAO_RECORRENCIA
              ? dataEntrega
              : null,
        });
        setCarregando(false);
        enqueueSnackbar('Atividade de Processo criada com sucessos!', {
          variant: 'success',
        });
      } else {
        await updateAPI('atividades_processos', id, {
          nome,
          detalhes,
          subAtividades,
          itens: formatItensSave(itens),
          atualizador_id: getUserId(),
          departamento_colaborador_id: departamento ? departamento.id : null,
          grau_urgencia: urgencia,
          grau_importancia: importancia,
          tipo_recorrencia: tipoRecorrencia ? tipoRecorrencia.value : null,
          tempo_previsto: tempoPrevisto,
          data_entrega:
            tipoRecorrencia.value === TIPO_RECORRENCIA_NAO_RECORRENCIA
              ? dataEntrega
              : null,
        });
        setCarregando(false);
        enqueueSnackbar('Atividade de Processos atualizada com sucesso!', {
          variant: 'success',
        });
      }

      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      reiniciar();
      setOpen(true);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setContadorId(1);
      setContadorIdDelegacoes(1);
      setOpen(true);
    },
  }));

  function validateNome() {
    let error = '';
    if (!nome) error = 'Este campo é obrigatório';
    else if (nome.length > 80) {
      error = 'Número de caracteres maior que o permitido (máximo 80)';
    }
    return error;
  }

  function validateDetalhes() {
    let error = '';
    if (detalhes) {
      if (detalhes.length > 500) {
        error = 'Número de caracteres maior que o permitido (máximo 500)';
      }
    }
    return error;
  }

  function validateUrgencia() {
    let error = '';
    if (urgencia <= 0 || Number.isNaN(urgencia)) {
      error = 'Este campo é obrigatório';
    } else if (urgencia > 10) {
      error = 'Este campo não pode ultrapassar o valor de 10';
    }
    return error;
  }

  function validateImportancia() {
    let error = '';
    if (importancia <= 0 || Number.isNaN(importancia)) {
      error = 'Este campo é obrigatório';
    } else if (importancia > 10) {
      error = 'Este campo não pode ultrapassar o valor de 10';
    }
    return error;
  }

  function validateTipoRecorrencia() {
    let error = '';
    if (!tipoRecorrencia) error = 'Este campo é obrigatório';
    return error;
  }

  function validateGestor() {
    const error = '';
    return error;
  }

  function validateDepartamento() {
    let error = '';
    if (!departamento) error = 'Este campo é obrigatório';
    return error;
  }

  function validateTempoPrevisto() {
    let error = '';
    if (!tempoPrevisto) error = 'Este campo é obrigatório';
    return error;
  }

  function validateDataEntrega() {
    let error = '';
    if (tipoRecorrencia) {
      if (tipoRecorrencia.value === TIPO_RECORRENCIA_NAO_RECORRENCIA) {
        if (!dataEntrega) error = 'Este campo é obrigatório';
      }
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', ''];
    errosOld[0] = validateNome();
    errosOld[1] = validateDetalhes();

    errosOld[2] = validateUrgencia();
    errosOld[3] = validateImportancia();
    errosOld[4] = validateTipoRecorrencia();
    errosOld[5] = validateGestor();
    errosOld[6] = validateDepartamento();
    errosOld[7] = validateTempoPrevisto();
    errosOld[8] = validateDataEntrega();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  function criarSubTarefa() {
    if (refDialogoSubItens.current) {
      refDialogoSubItens.current.handleCreate();
    }
  }

  function criarDelegacao() {
    if (refDialogoDelegacao.current) {
      refDialogoDelegacao.current.handleCreate();
    }
  }

  function editarSubTarefa(id, nome, detalhes) {
    if (refDialogoSubItens.current) {
      refDialogoSubItens.current.handleEdit(id, nome, detalhes);
    }
  }

  function editarDelegacao(id, locais, colaboradores, gestor) {
    if (refDialogoDelegacao.current) {
      refDialogoDelegacao.current.handleEdit(id, locais, colaboradores, gestor);
    }
  }

  function deletarSubTarefa(id, nome, detalhes) {
    if (refDialogoDeletarSubItens.current) {
      refDialogoDeletarSubItens.current.handleDelete(id, nome, detalhes);
    }
  }

  function deletarDelegacao(id, locais, colaboradores, gestor) {
    if (refDialogoDeletarDelegacao.current) {
      refDialogoDeletarDelegacao.current.handleDelete(
        id,
        locais,
        colaboradores,
        gestor,
      );
    }
  }

  function handleSaveSubItens(id, nome, detalhes, isCreate) {
    let newArray = subAtividades.slice();

    if (isCreate) {
      newArray.push({
        id: contadorId * -1,
        nome,
        detalhes,
      });
      setContadorId(contadorId + 1);
    } else {
      newArray = newArray.map((item) => {
        if (item.id === id) {
          return {
            id,
            nome,
            detalhes,
          };
        }
        return item;
      });
    }
    setSubAtividades(newArray);
  }

  function handleSaveItensDelegacoes(
    id,
    locais,
    colaboradores,
    gestor,
    isCreate,
  ) {
    let newArray = itens.slice();

    if (isCreate) {
      newArray.push({
        id: contadorIdDelegacoes * -1,
        locais,
        colaboradores,
        gestor,
      });
      setContadorIdDelegacoes(contadorIdDelegacoes + 1);
    } else {
      newArray = newArray.map((item) => {
        if (item.id === id) {
          return {
            id,
            locais,
            colaboradores,
            gestor,
          };
        }
        return item;
      });
    }
    setItens(newArray);
  }

  function handleDeletarSubItens(id) {
    const newArray = subAtividades.slice().filter((item) => item.id !== id);
    setSubAtividades(newArray);
  }

  function handleDeletarItensDelegacoes(id) {
    const newArray = itens.slice().filter((item) => item.id !== id);
    setItens(newArray);
  }

  /*function formatLocais(locais) {
    return locais.map((item) => item.nome).join('; ');
  }*/

  /*function formatColaboradores(colaboradores) {
    return colaboradores.map((item) => item.nome).join('; ');
  }*/

  function formatLocais(locais) {
    const locaisLocal = [...locais];
    locaisLocal.sort((a, b) => a.nome.localeCompare(b.nome));
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
        }}
      >
        {locaisLocal.map((item) => (
          <div>{item.nome}</div>
        ))}
      </div>
    );
  }

  function formatColaboradores(colaboradores) {
    const colaboradoresLocal = [...colaboradores];
    colaboradoresLocal.sort((a, b) => a.nome.localeCompare(b.nome));
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
        }}
      >
        {colaboradoresLocal.map((item) => (
          <div>{item.nome}</div>
        ))}
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const showDataEntrega = tipoRecorrencia
    ? tipoRecorrencia.value === TIPO_RECORRENCIA_NAO_RECORRENCIA
    : false;

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullScreen
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {id < 0
            ? `Nova Atividade de Processos`
            : `Editar Atividade de Processos`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 10px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Box p="1em">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Geral" {...a11yProps(0)} />
                <Tab label="Sub Tarefas" {...a11yProps(1)} />
                <Tab label="Delegações" {...a11yProps(2)} />
              </Tabs>
              <Box display="flex">
                <Box flex={2} mr="1em">
                  <TabPanel value={value} index={0}>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <TextInput
                          name="nome"
                          handleEnter={() => {}}
                          label="Título"
                          handleKey={() => {}}
                          value={nome}
                          onChange={(value) => setNome(value.target.value)}
                          error={erros[0] !== ''}
                          helperText={erros[0]}
                          fullWidth
                          autoFocus
                          variant="outlined"
                        />
                      </Box>
                      <Box
                        flex={1}
                        ml="0.5em"
                        style={{
                          marginTop: '15px',
                        }}
                      >
                        <TimeInputBonito
                          //showTodayButton
                          //todayLabel="Hoje"
                          name="tempo_previsto"
                          handleEnter={() => {}}
                          label="Tempo Previsto"
                          handleKey={() => {}}
                          value={tempoPrevisto}
                          onChange={(value) => setTempoPrevisto(value)}
                          format="HH:mm:ss"
                          fullWidth
                          inputVariant="outlined"
                          ampm={false}
                          views={['hours', 'minutes', 'seconds']}
                          error={erros[7] !== ''}
                          helperText={erros[7]}
                        />
                      </Box>
                    </Box>
                    <Box flex={1}>
                      <TextInput
                        name="detalhes"
                        handleEnter={() => {}}
                        label="Detalhes"
                        handleKey={() => {}}
                        value={detalhes}
                        onChange={(value) => setDetalhes(value.target.value)}
                        error={erros[1] !== ''}
                        helperText={erros[1]}
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                      />
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <IntegerInput
                          name="urgencia"
                          handleEnter={() => {}}
                          label="Urgência"
                          handleKey={() => {}}
                          value={Number.isNaN(urgencia) ? '' : String(urgencia)}
                          onChange={(value) =>
                            setUrgencia(parseInt(value.target.value, 10))
                          }
                          error={erros[2] !== ''}
                          helperText={erros[2]}
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <IntegerInput
                          name="importancia"
                          handleEnter={() => {}}
                          label="Importância"
                          handleKey={() => {}}
                          value={
                            Number.isNaN(importancia) ? '' : String(importancia)
                          }
                          onChange={(value) =>
                            setImportancia(parseInt(value.target.value, 10))
                          }
                          error={erros[3] !== ''}
                          helperText={erros[3]}
                          fullWidth
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <AutoCompleteRemoto
                          id="departamento"
                          name="departamento"
                          label="Departamento"
                          value={departamento}
                          onChange={(value) => setDepartamento(value)}
                          handleKey={() => {}}
                          handleEnter={() => {}}
                          resource="departamentos_colaborador"
                          toString={false}
                          fullwidth
                          //autoFocus
                          filters={{ status: STATUS_ATIVO }}
                          error={erros[6] !== ''}
                          helperText={erros[6]}
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        {/*<AutoCompleteRemoto
                          id="gestor"
                          name="gestor"
                          label="Gestor"
                          value={gestor}
                          onChange={(value) => setGestor(value)}
                          handleKey={() => {}}
                          handleEnter={() => {}}
                          resource="colaboradores"
                          toString={false}
                          fullwidth
                          filters={{ status: STATUS_ATIVO, is_gestor: true }}
                          error={erros[5] !== ''}
                          helperText={erros[5]}
                        />*/}
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <AutoCompleteLocal
                          name="tipo_recorrencia"
                          handleEnter={() => {}}
                          label="Tipo de Recorrência"
                          handleKey={() => {}}
                          value={tipoRecorrencia}
                          onChangeLocal={(value) => setTipoRecorrencia(value)}
                          error={erros[4] !== ''}
                          helperText={erros[4]}
                          options={optionsTipoRecorrencia}
                          //autoFocus
                          campo="label"
                          variant="outlined"
                        />
                      </Box>
                      <Box
                        flex={1}
                        ml="0.5em"
                        style={{
                          marginTop: '15px',
                        }}
                      >
                        {showDataEntrega ? (
                          <DateInputBonito
                            showTodayButton
                            disablePast
                            todayLabel="Hoje"
                            name="data_entrega  "
                            handleEnter={() => {}}
                            label="Data Entrega"
                            handleKey={() => {}}
                            value={dataEntrega}
                            onChange={(value) => {
                              setDataEntrega(value);
                            }}
                            format="dd MMMM yyyy"
                            fullWidth
                            inputVariant="outlined"
                            error={erros[8] !== ''}
                            helperText={erros[8]}
                          />
                        ) : null}
                      </Box>
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <div
                      style={
                        {
                          //flex: 1,
                          //borderRight: '1px solid black',
                          //borderLeft: '1px solid black',
                          //padding: '10px',
                        }
                      }
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          Sub Tarefas
                        </Typography>
                        <Tooltip
                          title="Criar"
                          placement="bottom"
                          aria-label="add2"
                        >
                          <IconButton
                            aria-label="criar"
                            onClick={() => criarSubTarefa()}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="left"
                                style={{
                                  backgroundColor: '#009FD4',
                                }}
                              >
                                Título
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  backgroundColor: '#009FD4',
                                }}
                              >
                                Detalhes
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{
                                  backgroundColor: '#009FD4',
                                }}
                              >
                                Ações
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {subAtividades.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                  {row.nome}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    maxWidth: '200px',
                                    wordBreak: 'break-all',
                                  }}
                                >
                                  {row.detalhes}
                                </TableCell>
                                <TableCell align="right">
                                  <Tooltip
                                    title="Editar"
                                    placement="bottom"
                                    aria-label="add2"
                                  >
                                    <IconButton
                                      aria-label="editar"
                                      size="small"
                                      onClick={() =>
                                        editarSubTarefa(
                                          row.id,
                                          row.nome,
                                          row.detalhes,
                                        )
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    title="Editar"
                                    placement="bottom"
                                    aria-label="add2"
                                  >
                                    <IconButton
                                      aria-label="editar"
                                      size="small"
                                      onClick={() =>
                                        deletarSubTarefa(
                                          row.id,
                                          row.nome,
                                          row.detalhes,
                                        )
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <div
                      style={
                        {
                          //flex: 1,
                          //borderLeft: '1px solid black',
                          //padding: '10px',
                        }
                      }
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          Delegações
                        </Typography>
                        <Tooltip
                          title="Criar"
                          placement="bottom"
                          aria-label="add2"
                        >
                          <IconButton
                            aria-label="criar"
                            onClick={() => criarDelegacao()}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="left"
                                style={{
                                  backgroundColor: '#009FD4',
                                }}
                              >
                                Gestor
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  backgroundColor: '#009FD4',
                                }}
                              >
                                Locais
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  backgroundColor: '#009FD4',
                                }}
                              >
                                Colaboradores
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{
                                  backgroundColor: '#009FD4',
                                }}
                              >
                                Ações
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {itens.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell
                                  align="left"
                                  style={{ wordBreak: 'break-word' }}
                                >
                                  {row.gestor ? row.gestor.nome : '-'}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ wordBreak: 'break-word' }}
                                >
                                  {formatLocais(row.locais)}
                                </TableCell>
                                <TableCell align="left">
                                  {formatColaboradores(row.colaboradores)}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  //style={{ display: 'flex' }}
                                >
                                  <Tooltip
                                    title="Editar"
                                    placement="bottom"
                                    aria-label="add2"
                                  >
                                    <IconButton
                                      aria-label="editar"
                                      size="small"
                                      onClick={() =>
                                        editarDelegacao(
                                          row.id,
                                          row.locais,
                                          row.colaboradores,
                                          row.gestor,
                                        )
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    title="Editar"
                                    placement="bottom"
                                    aria-label="add2"
                                  >
                                    <IconButton
                                      aria-label="editar"
                                      size="small"
                                      onClick={() =>
                                        deletarDelegacao(
                                          row.id,
                                          row.locais,
                                          row.colaboradores,
                                          row.gestor,
                                        )
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </TabPanel>
                </Box>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              disabled={erroExistente || carregando}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
        <DialogoCreateEditSubItens
          ref={refDialogoSubItens}
          handleClose={() => {}}
          handleSave={handleSaveSubItens}
        />
        <DialogoDeletarSubItens
          ref={refDialogoDeletarSubItens}
          handleClose={() => {}}
          handleDelete={handleDeletarSubItens}
        />

        <DialogoCreateDelegacoes
          ref={refDialogoDelegacao}
          handleClose={() => {}}
          handleSave={handleSaveItensDelegacoes}
        />
        <DialogoDeletarDelelgacoes
          ref={refDialogoDeletarDelegacao}
          handleClose={() => {}}
          handleDelete={handleDeletarItensDelegacoes}
        />
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
