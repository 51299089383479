import React, { useState } from 'react';
import { Title } from 'react-admin';

import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
} from '@material-ui/core';
import Avatar2 from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useSnackbar } from 'notistack';

import { api } from '../../../services';
import MobillsInput from './DateInputMobills';

function formatMoeda(valor) {
  return valor.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableCell2 = withStyles((theme) => ({
  head: {
    backgroundColor: 'rgba(136, 14, 79, 0.5)',
    color: 'white',
  },
  body: {
    fontSize: 14,
    color: 'white',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableRowPorcentagem = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(136, 14, 79, 1)',
    color: 'white',
  },
}))(TableRow);

const StyledTableRowTotais = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(136, 14, 79, 1)',
    color: 'white',
  },
}))(TableRow);

function createData(
  nome,
  jan,
  fev,
  mar,
  abr,
  mai,
  jun,
  jul,
  ago,
  set,
  out,
  nov,
  dez,
) {
  return { nome, jan, fev, mar, abr, mai, jun, jul, ago, set, out, nov, dez };
}

const rows = [
  createData(
    'Dinheiro',
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
  ),
  createData(
    'Pix',
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
  ),
  createData(
    'Crédito',
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
  ),
  createData(
    'Débito',
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
  ),
  createData(
    'Voucher',
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
  ),
  createData(
    'Devolução',
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
  ),
  createData(
    'Boleto',
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
  ),
  createData(
    'Total Vendas',
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
  ),
  createData(
    'Desenv. Mensal',
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
  ),
  createData(
    'Compras com NFe',
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
  ),
  createData(
    'Despesas',
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
  ),
  createData(
    'Total de custos',
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
  ),
  createData(
    'Lucro Bruto',
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
  ),
  createData(
    'Lucro Líquido',
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
  ),
  createData(
    'Balanço',
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
    10000000,
  ),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const CardTotal = ({ label, value }) => (
  <Card style={{ borderRadius: '20px', cursor: 'pointer', minWidth: '300px' }}>
    <CardContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <div>
          <div
            style={{
              fontSize: '17px',
              color: '#a1c3d1',
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {label}
          </div>
          <div style={{ fontSize: '20px', color: 'black', fontWeight: 'bold' }}>
            {value}
          </div>
        </div>
      </div>
    </CardContent>
  </Card>
);

const DashbboardContabilidade = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [dados, setDados] = useState({
    linhas: [],
    mes: new Date().getMonth(),
    ano: new Date().getFullYear(),
    kpis: [],
  });
  async function handleData(ano) {
    setLoading(true);
    try {
      const dados = await api.get(`/relatorio/anual/${ano}`);
      setDados({ linhas: dados.data.linhas, ano, kpis: dados.data.kpis });
      // setDados({ linhas: [], ano });
      setLoading(false);
    } catch (e) {
      setDados({ linhas: [], ano });
      setLoading(false);
      enqueueSnackbar(`Erro ao carregar os dados do relatório!`, {
        variant: 'error',
      });
    }
  }
  return (
    <div>
      <div
        style={{
          fontSize: '25px',
          fontWeight: 'bold',
          marginTop: '35px',
          marginBottom: '30px',
        }}
      >
        Relatório Anual
      </div>
      <Title title="Relatório Anual" />
      <div
        style={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div />
        <MobillsInput handleAno={handleData} />
        <Tooltip
          title="Imprimir relatório"
          placement="top"
          aria-label="Imprimir"
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            // onClick={handleClick}
          >
            <AssignmentIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {dados.kpis.map((item) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: '25%',
              padding: '20px',
            }}
          >
            <CardTotal label={item.name} value={item.value} />
          </div>
        ))}
      </div>
      <TableContainer component={Paper}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell align="right">Jan</StyledTableCell>
              <StyledTableCell align="right">Fev</StyledTableCell>
              <StyledTableCell align="right">Mar</StyledTableCell>
              <StyledTableCell align="right">Abr</StyledTableCell>
              <StyledTableCell align="right">Mai</StyledTableCell>
              <StyledTableCell align="right">Jun</StyledTableCell>
              <StyledTableCell align="right">Jul</StyledTableCell>
              <StyledTableCell align="right">Ago</StyledTableCell>
              <StyledTableCell align="right">Set</StyledTableCell>
              <StyledTableCell align="right">Out</StyledTableCell>
              <StyledTableCell align="right">Nov</StyledTableCell>
              <StyledTableCell align="right">Dez</StyledTableCell>
              <StyledTableCell align="right">Total</StyledTableCell>
              {/*  <StyledTableCell align="right">%</StyledTableCell>
              <StyledTableCell align="right">Média</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {dados.linhas.map((row) => (
              <StyledTableRow key={row.nome}>
                <StyledTableCell>{row.nome}</StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.jan)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.fev)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.mar)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.abr)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.mai)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.jun)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.jul)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.ago)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.set)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.out)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.nov)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.dez)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.total)}
                </StyledTableCell>
                {/*  <StyledTableCell align="right">6.34%</StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.dez)}
                </StyledTableCell> */}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DashbboardContabilidade;

/* <StyledTableCell align="right">Total em vendas</StyledTableCell>
<StyledTableCell align="right">
  Desenvilvimento mensal
</StyledTableCell>
<StyledTableCell align="right">Vendas Acumuladas</StyledTableCell>
<StyledTableCell align="right">Compras com NFe</StyledTableCell>
<StyledTableCell align="right">Despesas</StyledTableCell>
<StyledTableCell align="right">Total em custos</StyledTableCell>
<StyledTableCell align="right">Lucro Bruto</StyledTableCell>
<StyledTableCell align="right">Lucro Líquido</StyledTableCell>
<StyledTableCell align="right">Balanço</StyledTableCell> */
