import React, { useState, useRef } from 'react';
import { Title } from 'react-admin';
import { useLocation } from 'react-router-dom';

import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import BarChartIcon from '@material-ui/icons/BarChart';

import { api } from '../../../services';
import DateMobillsInput from './DateInputMobills';
import DialogoEdit from './DialogoEdit';
import DialogoGrafico from './DialogoGrafico';

function makeLabelsCalendario() {
  const dias = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];

  return dias.map((item) => (
    <td
      style={{
        width: 'calc(100%/7)',
        aspectRatio: '2/1',
        border: '1px solid black',
        borderCollapse: 'collapse',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: 'crimson',
        color: 'WHITE',
      }}
    >
      <div style={{ fontSize: '25px' }}>{item}</div>
    </td>
  ));
}

function preencheDiasMesAnterior(dia) {
  const espacos = [];
  for (let i = 0; i < dia; i++) {
    espacos.push(makeDiaCalendario(null));
  }
  return espacos;
}

function getComZero(value) {
  return value < 10 ? `0${value}` : `${value}`;
}

const diaAtual = new Date().getDate();
const mesAtual = new Date().getMonth();
const anoAtual = new Date().getFullYear();

function getStyle(dia, mes, ano) {
  if (dia === diaAtual && mes === mesAtual && ano === anoAtual) {
    return {
      backgroundColor: 'green',
      color: 'white',
    };
  }

  return {};
}

function dataMaiorQueAtual(data) {
  if (data) {
    const dia = data.dia;
    const mes = data.mes;
    const ano = data.ano;

    const dataAtual = new Date();
    const diaAtual = dataAtual.getDate();
    const mesAtual = dataAtual.getMonth();
    const anoAtual = dataAtual.getFullYear();

    if (ano > anoAtual) return true;
    if (ano < anoAtual) return false;
    if (mes > mesAtual) return true;
    if (mes < mesAtual) return false;
    if (dia > diaAtual) return true;
    if (dia < diaAtual) return false;
    return false;
  }
  return true;
}

function makeDiaCalendario(dia, onClick = () => {}) {
  return (
    <>
      {!dataMaiorQueAtual(dia) ? (
        <td
          style={{
            width: 'calc(100%/7)',
            aspectRatio: '1/1',
            borderCollapse: 'collapse',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            flexDirection: 'column',
            border: '1px solid black',
            ...getStyle(dia.dia, dia.mes, dia.ano),
          }}
          onClick={() => onClick(dia.dia, dia.mes, dia.ano)}
        >
          <div style={{ fontSize: '25px' }}>{`${getComZero(dia.dia)}`}</div>
          <div style={{ fontSize: '12px', marginTop: '15px' }}>
            {formatMoeda(dia.preco)}
          </div>
        </td>
      ) : (
        <td
          style={{
            width: 'calc(100%/7)',
            aspectRatio: '1/1',
            border: '1px solid black',
            borderCollapse: 'collapse',
            display: 'flex',
            fontSize: '20px',
            padding: '10px',
          }}
        />
      )}
    </>
  );
}

function formatMoeda(valor) {
  return valor
    ? valor.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })
    : '';
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CotacaoPrecosProdutos = () => {
  const [dados, setDados] = useState([]);
  const [produtoName, setProdutoName] = useState('');
  const refDialogoEdit = useRef(null);
  const query = useQuery();
  const refDialogo = useRef(null);

  async function handleData({ ano, mes }) {
    try {
      const dados = await api.get(
        `/cotacoes_precos_produtos?mes=${mes}&ano=${ano}&produto_id=${query.get(
          'produto_id',
        )}`,
      );
      setDados(dados.data.linhas);
      setProdutoName(dados.data.produtoName);
    } catch (e) {
      setDados([]);
    }
  }

  function handleClickDia(dia, mes, ano) {
    if (refDialogoEdit.current) {
      refDialogoEdit.current.handleOpen(query.get('produto_id'), {
        dia,
        mes,
        ano,
      });
    }
  }

  function handleGrafico() {
    if (refDialogo.current) {
      refDialogo.current.handleOpen();
    }
  }

  return (
    <Box>
      <Title title={`Cotações Preços ${produtoName}`} />
      <div
        style={{
          margin: '40px 0',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div />
        <DateMobillsInput handleData={handleData} />
        <Tooltip title="Gerar Gráfico" placement="top" aria-label="Imprimir">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={handleGrafico}
          >
            <BarChartIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Box display="flex">
        <Box display="flex" justifyContent="center">
          <table
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              borderCollapse: 'collapse',
              width: '50%',
              border: '1px solid black',
            }}
          >
            <tr
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                border: '1px solid black',
              }}
            >
              {makeLabelsCalendario()}
              {dados.length > 0 && preencheDiasMesAnterior(dados[0].dia_semana)}
              {dados.map((item) => makeDiaCalendario(item, handleClickDia))}
            </tr>
          </table>
        </Box>
      </Box>
      <DialogoGrafico ref={refDialogo} linhas={dados} days={dados.length} />
      <DialogoEdit ref={refDialogoEdit} handleClose={() => {}} />
    </Box>
  );
};

export default CotacaoPrecosProdutos;
