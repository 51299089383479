import {
  renameItensUIDD,
  renameParcelasUIDD,
  getSubTotal,
} from '../../../../utils';

const reducer = (state, action) => {
  let arrayNew = state.itens.slice();
  let arrayNewParcelas = state.parcelas.slice();
  switch (action.type) {
    case 'ADD_ITEM':
      return {
        ...state,
        itens: [...arrayNew, action.item],
        subTotal: getSubTotal([...arrayNew, action.item]),
      };
    case 'REMOVE_ITEM':
      for (let i = 0; i < action.indices.length; i += 1) {
        arrayNew = arrayNew.filter((obj) => obj.uidd !== action.indices[i]);
      }
      return {
        ...state,
        itens: [...renameItensUIDD(arrayNew)],
        subTotal: getSubTotal([...arrayNew]),
      };
    case 'UPDATE_ITEMS':
      return {
        ...state,
        itens: [...renameItensUIDD(action.itens)],
        subTotal: getSubTotal([...action.itens]),
      };

    case 'UPDATE_CLIENTE': {
      return {
        ...state,
        cliente: action.cliente,
      };
    }

    case 'ADD_PARCELA':
      return {
        ...state,
        parcelas: [...arrayNewParcelas, action.parcela],
      };
    case 'REMOVE_PARCELA':
      for (let i = 0; i < action.indices.length; i += 1) {
        arrayNewParcelas = arrayNewParcelas.filter(
          (obj) => obj.uidd !== action.indices[i],
        );
      }
      return {
        ...state,
        parcelas: [...renameParcelasUIDD(arrayNewParcelas)],
      };
    case 'UPDATE_PARCELAS':
      return {
        ...state,
        parcelas: [...renameParcelasUIDD(action.parcelas)],
      };

    case 'UPDATE_ID_EDIT':
      return {
        ...state,
        idEdit: action.idEdit,
      };

    case 'UPDATE_IS_EDIT_PRICE':
      return {
        ...state,
        isEditPrice: action.isEditPrice,
      };

    case 'RESETAR_TUDO':
      return {
        ...state,
        isEditPrice: false,
        idEdit: -1,
        itens: [],
        parcelas: [],
        subTotal: 0,
        cliente: null,
        tipoVenda: 0,
        descontoOriginal: 0,
        isEmPartes: false,
        idSession: -1,
      };

    case 'PREENCHER_TUDO':
      return {
        ...state,
        isEditPrice: false,
        idEdit: action.idEdit,
        itens: [...renameItensUIDD(action.itens)],
        subTotal: getSubTotal([...action.itens]),
        parcelas: [...renameParcelasUIDD(action.parcelas)],
        cliente: action.cliente,
        tipoVenda: action.tipoVenda,
        descontoOriginal: action.descontoOriginal,
        isEmPartes: action.is_em_partes,
        idSession: action.idSession,
      };
    case 'PREENCHER_TUDO_PEDIDO':
      return {
        ...state,
        isEditPrice: false,
        idEdit: -1,
        itens: [...renameItensUIDD(action.itens)],
        subTotal: getSubTotal([...action.itens]),
        cliente: action.cliente,
        parcelas: [],
        tipoVenda: 0,
        descontoOriginal: 0,
        isEmPartes: false,
      };
    case 'CHANGE_IS_EM_PARTES':
      return {
        ...state,
        isEmPartes: !state.isEmPartes,
      };
    default:
      return state;
  }
};

export default reducer;
