import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    /* width: theme.spacing(3),
    height: theme.spacing(3), */
  },
}));

const ActionsField = ({
  tipo,
  handleEdit,
  handleDelete,
  handleEfetivar,
  ...rest
}) => {
  const classes = useStyles();
  // const { source } = rest;
  const record = useRecordContext(rest);
  // const value = record ? record.record[source] : '';
  const statusValue = record ? record.record.status : false;
  const disaledDelete = record
    ? record.record.parcela_venda_id ||
      record.record.parcela_compra_id ||
      record.record.status
    : false;

  const disabledEdit = record
    ? (record.record.conta.is_caixa && !!record.record.parcela_venda_id) ||
      (record.record.conta.is_caixa && !record.record.is_transferencia) ||
      record.record.status
    : false;

  const visibleEdit = record
    ? !(record.record.categoria.id === 5 || record.record.categoria.id === 7)
    : true;

  return (
    <div
      style={{
        marginRight: '0px',
        // width: '120px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {/* {tipo !== 'Transferência' && !statusValue && (
        <Tooltip title="Efetivar" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="check"
            classes={{ root: classes.root }}
            size="small"
            onClick={() => handleEfetivar(record)}
          >
            <CheckCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      )} */}
      {visibleEdit && (
        <Tooltip title="Editar" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="edit"
            classes={{ root: classes.root }}
            size="small"
            onClick={() => handleEdit(record)}
            disabled={disabledEdit}
          >
            <EditOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Deletar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="delete"
          classes={{ root: classes.root }}
          size="small"
          onClick={() => handleDelete(record)}
          disabled={disaledDelete}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

ActionsField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ActionsField;
