import { trunc10 } from './arredondamentos';
import {
  POR_PESO_FIXO,
  POR_UNIDADE,
  TIPO_UNIDADE_OFERTA_KG,
  TIPO_UNIDADE_OFERTA_UN,
  TIPO_UNIDADE_OFERTA_CAIXA,
  TIPO_UNIDADE_OFERTA_CAIXETA,
  TIPO_UNIDADE_OFERTA_CAIXA_MAE,
  TIPO_OFERTA_PRODUTO_LIBERACAO,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_PESO_VARIAVEL,
  ZERO_APROXIMADO,
} from './constants';
import { formatPeso } from './formats';

function fatorPesoMedio(produto) {
  if (produto.unidade.tipo === POR_PESO_FIXO) {
    return produto.peso_medio;
  }
  return 1;
}

function valorIntervalo(oferta, qtde) {
  let preco = oferta.valor;
  let continuar = true;
  for (let i = 0; i < oferta.escalonadas.length && continuar; i++) {
    if (oferta.escalonadas[i].quantidade <= qtde) {
      preco = oferta.escalonadas[i].valor;
    } else {
      continuar = false;
    }
  }
  return preco;
}

function valorIntervalo2(oferta, qtde, precoVenda) {
  let preco =
    oferta.tipo !== TIPO_OFERTA_PRODUTO_LIBERACAO ? oferta.valor : precoVenda;
  let continuar = true;
  for (let i = 0; i < oferta.escalonadas.length && continuar; i++) {
    if (oferta.escalonadas[i].quantidade <= qtde) {
      preco = oferta.escalonadas[i].valor;
    } else {
      continuar = false;
    }
  }
  return preco;
}

export function getQuantidadeBasicas(produto, unidades, peso) {
  if (
    produto.unidade.tipo === POR_UNIDADE ||
    produto.unidade.tipo === POR_PESO_FIXO
  ) {
    if (produto.nivelCaixa) {
      switch (produto.nivelCaixa) {
        case 1:
          return { qtde: unidades * produto.unidades_caixa_nivel_1, tipo: 0 };
        case 2:
          return {
            qtde:
              unidades *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2,
            tipo: 0,
          };
        case 3:
          return {
            qtde:
              unidades *
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3,
            tipo: 0,
          };
        default:
          return { qtde: unidades, tipo: 0 };
      }
    } else {
      return { qtde: unidades, tipo: 0 };
    }
  }
  if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
    if (produto.nivelCaixa) {
      switch (produto.nivelCaixa) {
        case 1:
          return { qtde: unidades * produto.peso_caixa_nivel_1, tipo: 1 };
        case 2:
          return {
            qtde:
              unidades *
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2,
            tipo: 1,
          };
        case 3:
          return {
            qtde:
              unidades *
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3,
            tipo: 1,
          };
        default:
          return { qtde: peso, tipo: 1 };
      }
    } else {
      return { qtde: peso, tipo: 1 };
    }
  }
  return { qtde: 0, tipo: 1 };
}

function getQuantidade(tipoUnidade, produto, unidades, peso) {
  const { qtde, tipo } = getQuantidadeBasicas(produto, unidades, peso);
  switch (tipoUnidade) {
    case TIPO_UNIDADE_OFERTA_KG:
      return qtde;
    case TIPO_UNIDADE_OFERTA_UN:
      return qtde;
    case TIPO_UNIDADE_OFERTA_CAIXA: {
      const qtdeCaixa = getQuantidadeEfetiva(produto, 1);
      return qtde / qtdeCaixa;
    }
    case TIPO_UNIDADE_OFERTA_CAIXETA: {
      const qtdeCaixa = getQuantidadeEfetiva(produto, 2);
      return qtde / qtdeCaixa;
    }
    case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
      const qtdeCaixa = getQuantidadeEfetiva(produto, 3);
      return qtde / qtdeCaixa;
    }
    default:
      return qtde;
  }
}

function getPrecoUnitarioVerdadeiro(produto, unidades, peso) {
  if (produto.ofertasProdutos.length > 0) {
    let preco = produto.preco_venda;
    for (let i = 0; i < produto.ofertasProdutos.length; i++) {
      const oferta = produto.ofertasProdutos[i];
      const qtde = getQuantidade(oferta.tipo_unidade, produto, unidades, peso);
      const preco2 = valorIntervalo2(oferta, qtde, produto.preco_venda);
      if (preco2 < preco) {
        preco = preco2;
      }
    }
    return preco;
  }
  return produto.preco_venda;
}

function getPrecoUnitarioVerdadeiroCaixa(produto, unidades, peso) {
  const preco = getPrecoUnitarioVerdadeiro(produto, unidades, peso);
  if (produto.nivelCaixa) {
    switch (produto.nivelCaixa) {
      case 1: {
        const qtde = getQuantidadeEfetiva(produto, 1);
        return preco * qtde * fatorPesoMedio(produto);
      }
      case 2: {
        const qtde = getQuantidadeEfetiva(produto, 2);
        return preco * qtde * fatorPesoMedio(produto);
      }
      case 3: {
        const qtde = getQuantidadeEfetiva(produto, 3);
        return preco * qtde * fatorPesoMedio(produto);
      }
      default:
        return preco;
    }
  } else {
    return preco;
  }
}

///////////////////////////////////////////////////

function getPrecoUnitarioVerdadeiro2(produto, unidades, peso) {
  if (produto.ofertasProdutos.length > 0) {
    let preco = produto.preco_venda;
    for (let i = 0; i < produto.ofertasProdutos.length; i++) {
      const oferta = produto.ofertasProdutos[i];
      if (oferta.tipo !== TIPO_OFERTA_PRODUTO_LIBERACAO) {
        const qtde = getQuantidade(
          oferta.tipo_unidade,
          produto,
          unidades,
          peso,
        );
        const preco2 = valorIntervalo(oferta, qtde);
        if (preco2 < preco) {
          preco = preco2;
        }
      }
    }
    return preco;
  }
  return produto.preco_venda;
}

function getPrecoUnitarioVerdadeiroCaixa2(produto, unidades, peso) {
  const preco = getPrecoUnitarioVerdadeiro2(produto, unidades, peso);
  if (produto.nivelCaixa) {
    switch (produto.nivelCaixa) {
      case 1: {
        const qtde = getQuantidadeEfetiva(produto, 1);
        return preco * qtde * fatorPesoMedio(produto);
      }
      case 2: {
        const qtde = getQuantidadeEfetiva(produto, 2);
        return preco * qtde * fatorPesoMedio(produto);
      }
      case 3: {
        const qtde = getQuantidadeEfetiva(produto, 3);
        return preco * qtde * fatorPesoMedio(produto);
      }
      default:
        return preco;
    }
  } else {
    return preco;
  }
}

////////////////////////////////////////////////

function getQuantidadeCaixaria(produto, nivel) {
  switch (produto.unidade.tipo) {
    case POR_UNIDADE: {
      switch (nivel) {
        case 1:
          return { unidades: produto.unidades_caixa_nivel_1, peso: 0 };
        case 2:
          return {
            unidades:
              produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2,
            peso: 0,
          };
        case 3:
          return {
            unidades:
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3,
            peso: 0,
          };
        default:
          return { unidades: 0, peso: 0 };
      }
      break;
    }
    case POR_PESO_VARIAVEL_SEM_UNIDADE: {
      switch (nivel) {
        case 1:
          return { unidades: 0, peso: produto.peso_caixa_nivel_1 };
        case 2:
          return {
            unidades: produto.unidades_caixa_nivel_2,
            peso: produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2,
          };
        case 3:
          return {
            unidades:
              produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_3,
            peso:
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3,
          };
        default:
          return { unidades: 0, peso: 0 };
      }
    }
    case POR_PESO_VARIAVEL: {
      return { unidades: 0, peso: 0 };
    }
    case POR_PESO_FIXO: {
      switch (nivel) {
        case 1:
          return { unidades: produto.unidades_caixa_nivel_1, peso: 0 };
        case 2:
          return {
            unidades:
              produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2,
            peso: 0,
          };
        case 3:
          return {
            unidades:
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3,
            peso: 0,
          };
        default:
          return { unidades: 0, peso: 0 };
      }
    }
    default:
      return { unidades: 0, peso: 0 };
  }
}

function getQuantidadeEfetiva(produto, nivel) {
  const { unidades, peso } = getQuantidadeCaixaria(produto, nivel);
  if (peso > 0) {
    return peso;
  }
  if (unidades > 0) {
    return unidades;
  }
  return 0;
}

function getQuantidadeCaixariaLabel(produto, nivel) {
  const { unidades, peso } = getQuantidadeCaixaria(produto, nivel);
  if (peso > ZERO_APROXIMADO) {
    return `${formatPeso(peso)} Kg`;
  }
  if (unidades > 0) {
    return `${unidades} Un`;
  }
  return '-';
}

function getNivelCaixaQtde(produto) {
  if (produto.unidades_caixa_nivel_3 > 0) {
    return 3;
  }

  if (produto.unidades_caixa_nivel_2 > 0) {
    return 2;
  }

  if (
    produto.unidades_caixa_nivel_1 > 0 ||
    produto.peso_caixa_nivel_1 > ZERO_APROXIMADO
  ) {
    return 1;
  }

  return 0;
}

function getLabelTipoUnidade(tipo) {
  switch (tipo) {
    case TIPO_UNIDADE_OFERTA_KG:
      return 'Kg';
    case TIPO_UNIDADE_OFERTA_UN:
      return 'Un';
    case TIPO_UNIDADE_OFERTA_CAIXA:
      return 'Caixa Nível 1';
    case TIPO_UNIDADE_OFERTA_CAIXETA:
      return 'Caixa Nível 2';
    case TIPO_UNIDADE_OFERTA_CAIXA_MAE:
      return 'Caixa Nível 3';
    default:
      return '';
  }
}

function getNivelCaixaCodigo(produto, codigo) {
  if (produto) {
    switch (codigo) {
      case produto.codigo_caixa_nivel_1:
        return 1;
      case produto.codigo_caixa_nivel_2:
        return 2;
      case produto.codigo_caixa_nivel_3:
        return 3;
      default:
        return -1;
    }
  } else {
    return -1;
  }
}

function getTotalCaixaTipoOferta(produto, precoUnitario, tipoUnidadeOferta) {
  if (produto) {
    switch (produto.unidade.tipo) {
      case POR_UNIDADE: {
        switch (tipoUnidadeOferta) {
          case TIPO_UNIDADE_OFERTA_CAIXA: {
            return produto.unidades_caixa_nivel_1 * precoUnitario;
          }
          case TIPO_UNIDADE_OFERTA_CAIXETA: {
            return (
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              precoUnitario
            );
          }
          case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
            return (
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3 *
              precoUnitario
            );
          }
          default: {
            return 0;
          }
        }
      }
      case POR_PESO_FIXO: {
        switch (tipoUnidadeOferta) {
          case TIPO_UNIDADE_OFERTA_CAIXA: {
            return (
              produto.unidades_caixa_nivel_1 *
              precoUnitario *
              produto.peso_medio
            );
          }
          case TIPO_UNIDADE_OFERTA_CAIXETA: {
            return (
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              precoUnitario *
              produto.peso_medio
            );
          }
          case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
            return (
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3 *
              precoUnitario *
              produto.peso_medio
            );
          }
          default: {
            return 0;
          }
        }
      }
      case POR_PESO_VARIAVEL_SEM_UNIDADE: {
        switch (tipoUnidadeOferta) {
          case TIPO_UNIDADE_OFERTA_CAIXA: {
            return produto.peso_caixa_nivel_1 * precoUnitario;
          }
          case TIPO_UNIDADE_OFERTA_CAIXETA: {
            return (
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              precoUnitario
            );
          }
          case TIPO_UNIDADE_OFERTA_CAIXA_MAE: {
            return (
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3 *
              precoUnitario
            );
          }
          default: {
            return 0;
          }
        }
      }
      default: {
        return 0;
      }
    }
  }
  return 0;
}

function getLabelTipoUnidadeCaixaria(tipoUnidadeOferta, produto) {
  if (produto) {
    if (tipoUnidadeOferta) {
      switch (tipoUnidadeOferta) {
        case TIPO_UNIDADE_OFERTA_CAIXA:
          return `Caixa Nível 1 (${getQuantidadeCaixariaLabel(produto, 1)})`;
        case TIPO_UNIDADE_OFERTA_CAIXETA:
          return `Caixa Nível 2 (${getQuantidadeCaixariaLabel(produto, 2)})`;
        case TIPO_UNIDADE_OFERTA_CAIXA_MAE:
          return `Caixa Nível 3 (${getQuantidadeCaixariaLabel(produto, 3)})`;
        default:
          return '';
      }
    }
    return '';
  }
  return '';
}

function getOptionsTipoUnidadeOferta(produto) {
  if (produto) {
    const tipo = produto.unidade.tipo;
    switch (tipo) {
      case POR_UNIDADE: {
        const nivel = getNivelCaixaQtde(produto);
        switch (nivel) {
          case 0:
            return [
              {
                label: 'Un',
                value: TIPO_UNIDADE_OFERTA_UN,
              },
            ];
          case 1:
            return [
              {
                label: 'Un',
                value: TIPO_UNIDADE_OFERTA_UN,
              },
              {
                label: 'Caixa Nível 1',
                value: TIPO_UNIDADE_OFERTA_CAIXA,
              },
            ];
          case 2:
            return [
              {
                label: 'Un',
                value: TIPO_UNIDADE_OFERTA_UN,
              },
              {
                label: 'Caixa Nível 1',
                value: TIPO_UNIDADE_OFERTA_CAIXA,
              },
              {
                label: 'Caixa Nível 2',
                value: TIPO_UNIDADE_OFERTA_CAIXETA,
              },
            ];
          case 3:
            return [
              {
                label: 'Un',
                value: TIPO_UNIDADE_OFERTA_UN,
              },
              {
                label: 'Caixa Nível 1',
                value: TIPO_UNIDADE_OFERTA_CAIXA,
              },
              {
                label: 'Caixa Nível 2',
                value: TIPO_UNIDADE_OFERTA_CAIXETA,
              },
              {
                label: 'Caixa Nível 3',
                value: TIPO_UNIDADE_OFERTA_CAIXA_MAE,
              },
            ];
          default:
            return [];
        }
      }
      case POR_PESO_FIXO: {
        const nivel = getNivelCaixaQtde(produto);
        switch (nivel) {
          case 0:
            return [
              {
                label: 'Un',
                value: TIPO_UNIDADE_OFERTA_UN,
              },
            ];
          case 1:
            return [
              {
                label: 'Un',
                value: TIPO_UNIDADE_OFERTA_UN,
              },
              {
                label: 'Caixa Nível 1',
                value: TIPO_UNIDADE_OFERTA_CAIXA,
              },
            ];
          case 2:
            return [
              {
                label: 'Un',
                value: TIPO_UNIDADE_OFERTA_UN,
              },
              {
                label: 'Caixa Nível 1',
                value: TIPO_UNIDADE_OFERTA_CAIXA,
              },
              {
                label: 'Caixa Nível 2',
                value: TIPO_UNIDADE_OFERTA_CAIXETA,
              },
            ];
          case 3:
            return [
              {
                label: 'Un',
                value: TIPO_UNIDADE_OFERTA_UN,
              },
              {
                label: 'Caixa Nível 1',
                value: TIPO_UNIDADE_OFERTA_CAIXA,
              },
              {
                label: 'Caixa Nível 2',
                value: TIPO_UNIDADE_OFERTA_CAIXETA,
              },
              {
                label: 'Caixa Nível 3',
                value: TIPO_UNIDADE_OFERTA_CAIXA_MAE,
              },
            ];
          default:
            return [];
        }
      }
      case POR_PESO_VARIAVEL:
        return [
          {
            label: 'Kg',
            value: TIPO_UNIDADE_OFERTA_KG,
          },
        ];
      case POR_PESO_VARIAVEL_SEM_UNIDADE: {
        const nivel = getNivelCaixaQtde(produto);
        switch (nivel) {
          case 0:
            return [
              {
                label: 'Kg',
                value: TIPO_UNIDADE_OFERTA_KG,
              },
            ];
          case 1:
            return [
              {
                label: 'Kg',
                value: TIPO_UNIDADE_OFERTA_KG,
              },
              {
                label: 'Caixa Nível 1',
                value: TIPO_UNIDADE_OFERTA_CAIXA,
              },
            ];
          case 2:
            return [
              {
                label: 'Kg',
                value: TIPO_UNIDADE_OFERTA_KG,
              },
              {
                label: 'Caixa Nível 1',
                value: TIPO_UNIDADE_OFERTA_CAIXA,
              },
              {
                label: 'Caixa Nível 2',
                value: TIPO_UNIDADE_OFERTA_CAIXETA,
              },
            ];
          case 3:
            return [
              {
                label: 'Kg',
                value: TIPO_UNIDADE_OFERTA_KG,
              },
              {
                label: 'Caixa Nível 1',
                value: TIPO_UNIDADE_OFERTA_CAIXA,
              },
              {
                label: 'Caixa Nível 2',
                value: TIPO_UNIDADE_OFERTA_CAIXETA,
              },
              {
                label: 'Caixa Nível 3',
                value: TIPO_UNIDADE_OFERTA_CAIXA_MAE,
              },
            ];
          default:
            return [];
        }
      }
      default:
        return [];
    }
  }
  return [];
}

function getLabelUnidade(tipo) {
  if (tipo === POR_UNIDADE) {
    return 'un';
  }
  return 'kg';
}

function getTipoUnidade(produto) {
  if (produto) {
    if (produto.unidade.tipo === POR_UNIDADE) {
      return 'Un';
    }
    return 'Kg';
  }
  return '';
}

function getPrecos(produto) {
  if (produto.nivelCaixa) {
    switch (produto.nivelCaixa) {
      case 1:
        return [
          {
            preco: getPrecoUnitarioVerdadeiroCaixa2(
              { ...produto, nivelCaixa: 1 },
              0,
              0,
            ),
            sufixo: 'Cx1',
            qtde: getQuantidadeCaixariaLabel(produto, 1),
          },
        ];
      case 2:
        return [
          {
            preco: getPrecoUnitarioVerdadeiroCaixa2(
              { ...produto, nivelCaixa: 1 },
              0,
              0,
            ),
            sufixo: 'Cx1',
            qtde: getQuantidadeCaixariaLabel(produto, 1),
          },
          {
            preco: getPrecoUnitarioVerdadeiroCaixa2(
              { ...produto, nivelCaixa: 2 },
              0,
              0,
            ),
            sufixo: 'Cx2',
            qtde: getQuantidadeCaixariaLabel(produto, 2),
          },
        ];
      case 3:
        return [
          {
            preco: getPrecoUnitarioVerdadeiroCaixa2(
              { ...produto, nivelCaixa: 1 },
              0,
              0,
            ),
            sufixo: 'Cx1',
            qtde: getQuantidadeCaixariaLabel(produto, 1),
          },
          {
            preco: getPrecoUnitarioVerdadeiroCaixa2(
              { ...produto, nivelCaixa: 2 },
              0,
              0,
            ),
            sufixo: 'Cx2',
            qtde: getQuantidadeCaixariaLabel(produto, 2),
          },
          {
            preco: getPrecoUnitarioVerdadeiroCaixa2(
              { ...produto, nivelCaixa: 3 },
              0,
              0,
            ),
            sufixo: 'Cx3',
            qtde: getQuantidadeCaixariaLabel(produto, 3),
          },
        ];
      default:
        return [];
    }
  } else {
    return [];
  }
}

function getNomeProduto(
  produto,
  mensagem2 = 'NOVO PEDIDO',
  mensagem1 = 'Nenhum Produto',
) {
  if (produto) {
    if (produto.nivelCaixa) {
      switch (produto.nivelCaixa) {
        case 1:
          return `${produto.nome} - cx(${getQuantidadeCaixariaLabel(
            produto,
            1,
          )}) - Nível 1`;
        case 2:
          return `${produto.nome} - cx(${getQuantidadeCaixariaLabel(
            produto,
            2,
          )}) - Nível 2`;
        case 3:
          return `${produto.nome} - cx(${getQuantidadeCaixariaLabel(
            produto,
            3,
          )}) - Nível 3`;
        default:
          return mensagem1;
      }
    } else {
      return produto.nome;
    }
  } else {
    return mensagem2;
  }
}

function formatNegativo(value) {
  return `- (${value})`;
}

function getEstoqueLabel(produto, unidades = null, peso = null) {
  if (produto) {
    const unidadesUtilizadas =
      unidades === null ? produto.unidadesDisponivel : unidades;
    const pesoUtilizadas = peso === null ? produto.pesoDisponivel : peso;
    if (
      produto.unidade.tipo === POR_UNIDADE ||
      produto.unidade.tipo === POR_PESO_FIXO
    ) {
      if (produto.unidades_caixa_nivel_3 > 0) {
        const isNegativa = unidadesUtilizadas < 0;

        const unidadesUtilizadasPositivas = isNegativa
          ? unidadesUtilizadas * -1
          : unidadesUtilizadas;

        const caixaMae = parseInt(
          unidadesUtilizadasPositivas /
            (produto.unidades_caixa_nivel_3 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_1),
          10,
        );
        const resto1 =
          unidadesUtilizadasPositivas %
          (produto.unidades_caixa_nivel_3 *
            produto.unidades_caixa_nivel_2 *
            produto.unidades_caixa_nivel_1);
        const caixetas = parseInt(
          resto1 /
            (produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_1),
          10,
        );
        const resto2 =
          resto1 %
          (produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_1);
        const caixas = parseInt(resto2 / produto.unidades_caixa_nivel_1, 10);
        const unidades = parseInt(resto2 % produto.unidades_caixa_nivel_1, 10);

        const objQtde = [];
        objQtde.push({
          qtde: caixaMae,
          label: `${caixaMae}cx3`,
        });
        objQtde.push({
          qtde: caixetas,
          label: `${caixetas}cx2`,
        });
        objQtde.push({
          qtde: caixas,
          label: `${caixas}cx1`,
        });
        objQtde.push({
          qtde: unidades,
          label: `${unidades}un`,
        });

        let labelRetorno = objQtde
          .filter((item) => item.qtde > 0)
          .map((item) => item.label)
          .join(' + ')
          .trim();

        labelRetorno = labelRetorno
          ? isNegativa
            ? formatNegativo(labelRetorno)
            : labelRetorno
          : '-';

        return labelRetorno;
        //return `${caixaMae}cx3 + ${caixetas}cx2 + ${caixas}cx1 + ${unidades} un`;
      }

      if (produto.unidades_caixa_nivel_2 > 0) {
        const isNegativa = unidadesUtilizadas < 0;

        const unidadesUtilizadasPositivas = isNegativa
          ? unidadesUtilizadas * -1
          : unidadesUtilizadas;

        const caixetas = parseInt(
          unidadesUtilizadasPositivas /
            (produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_1),
          10,
        );
        const resto =
          unidadesUtilizadasPositivas %
          (produto.unidades_caixa_nivel_2 * produto.unidades_caixa_nivel_1);
        const caixas = parseInt(resto / produto.unidades_caixa_nivel_1, 10);
        const unidades = parseInt(resto % produto.unidades_caixa_nivel_1, 10);

        const objQtde = [];
        objQtde.push({
          qtde: caixetas,
          label: `${caixetas}cx2`,
        });
        objQtde.push({
          qtde: caixas,
          label: `${caixas}cx1`,
        });
        objQtde.push({
          qtde: unidades,
          label: `${unidades}un`,
        });

        let labelRetorno = objQtde
          .filter((item) => item.qtde > 0)
          .map((item) => item.label)
          .join(' + ')
          .trim();

        labelRetorno = labelRetorno
          ? isNegativa
            ? formatNegativo(labelRetorno)
            : labelRetorno
          : '-';

        return labelRetorno;
        //return `${caixetas}cx2 + ${caixas}cx1 + ${unidades}un`;
      }

      if (produto.unidades_caixa_nivel_1 > 0) {
        const isNegativa = unidadesUtilizadas < 0;

        const unidadesUtilizadasPositivas = isNegativa
          ? unidadesUtilizadas * -1
          : unidadesUtilizadas;

        const caixas = parseInt(
          unidadesUtilizadasPositivas / produto.unidades_caixa_nivel_1,
          10,
        );
        const unidades = parseInt(
          unidadesUtilizadasPositivas % produto.unidades_caixa_nivel_1,
          10,
        );

        const objQtde = [];
        objQtde.push({
          qtde: caixas,
          label: `${caixas}cx1`,
        });
        objQtde.push({
          qtde: unidades,
          label: `${unidades}un`,
        });

        let labelRetorno = objQtde
          .filter((item) => item.qtde > 0)
          .map((item) => item.label)
          .join(' + ')
          .trim();

        labelRetorno = labelRetorno
          ? isNegativa
            ? formatNegativo(labelRetorno)
            : labelRetorno
          : '-';

        return labelRetorno;
        //return `${caixas}cx1 + ${unidades}un`;
      }

      const isNegativa = unidadesUtilizadas < 0;

      const unidadesUtilizadasPositivas = isNegativa
        ? unidadesUtilizadas * -1
        : unidadesUtilizadas;

      const objQtde = [];
      objQtde.push({
        qtde: unidadesUtilizadasPositivas,
        label: `${unidadesUtilizadasPositivas}un`,
      });

      let labelRetorno = objQtde
        .filter((item) => item.qtde > 0)
        .map((item) => item.label)
        .join(' + ')
        .trim();

      labelRetorno = labelRetorno
        ? isNegativa
          ? formatNegativo(labelRetorno)
          : labelRetorno
        : '-';

      return labelRetorno;
      //return unidadesUtilizadas;
    }
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
      if (produto.unidades_caixa_nivel_3 > 0) {
        const isNegativa = pesoUtilizadas < 0;

        const pesoUtilizadasPositivas = isNegativa
          ? pesoUtilizadas * -1
          : pesoUtilizadas;

        const caixaMae = parseInt(
          pesoUtilizadasPositivas /
            (produto.unidades_caixa_nivel_3 *
              produto.unidades_caixa_nivel_2 *
              produto.peso_caixa_nivel_1),
          10,
        );
        const resto1 =
          pesoUtilizadasPositivas %
          (produto.unidades_caixa_nivel_3 *
            produto.unidades_caixa_nivel_2 *
            produto.peso_caixa_nivel_1);
        const caixetas = parseInt(
          resto1 /
            (produto.unidades_caixa_nivel_2 * produto.peso_caixa_nivel_1),
          10,
        );
        const resto2 =
          resto1 %
          (produto.unidades_caixa_nivel_2 * produto.peso_caixa_nivel_1);
        const caixas = parseInt(resto2 / produto.peso_caixa_nivel_1, 10);
        const unidades = resto2 % produto.peso_caixa_nivel_1;

        const objQtde = [];
        objQtde.push({
          qtde: caixaMae,
          label: `${caixaMae}cx3`,
        });
        objQtde.push({
          qtde: caixetas,
          label: `${caixetas}cx2`,
        });
        objQtde.push({
          qtde: caixas,
          label: `${caixas}cx1`,
        });
        objQtde.push({
          qtde: unidades,
          label: `${formatPeso(unidades)}kg`,
        });

        let labelRetorno = objQtde
          .filter((item) => item.qtde > 0)
          .map((item) => item.label)
          .join(' + ')
          .trim();

        labelRetorno = labelRetorno
          ? isNegativa
            ? formatNegativo(labelRetorno)
            : labelRetorno
          : '-';

        return labelRetorno;
      }

      if (produto.unidades_caixa_nivel_2 > 0) {
        const isNegativa = pesoUtilizadas < 0;

        const pesoUtilizadasPositivas = isNegativa
          ? pesoUtilizadas * -1
          : pesoUtilizadas;

        const caixetas = parseInt(
          pesoUtilizadasPositivas /
            (produto.unidades_caixa_nivel_2 * produto.peso_caixa_nivel_1),
          10,
        );
        const resto =
          pesoUtilizadasPositivas %
          (produto.unidades_caixa_nivel_2 * produto.peso_caixa_nivel_1);
        const caixas = parseInt(resto / produto.peso_caixa_nivel_1, 10);
        const unidades = parseInt(resto % produto.peso_caixa_nivel_1, 10);

        const objQtde = [];
        objQtde.push({
          qtde: caixetas,
          label: `${caixetas}cx2`,
        });
        objQtde.push({
          qtde: caixas,
          label: `${caixas}cx1`,
        });
        objQtde.push({
          qtde: unidades,
          label: `${formatPeso(unidades)}kg`,
        });

        let labelRetorno = objQtde
          .filter((item) => item.qtde > 0)
          .map((item) => item.label)
          .join(' + ')
          .trim();

        labelRetorno = labelRetorno
          ? isNegativa
            ? formatNegativo(labelRetorno)
            : labelRetorno
          : '-';

        return labelRetorno;
        //return `${caixetas}cx2 + ${caixas}cx1 + ${formatPeso(unidades)}kg`;
      }

      if (produto.peso_caixa_nivel_1 > 0) {
        const isNegativa = pesoUtilizadas < 0;

        const pesoUtilizadasPositivas = isNegativa
          ? pesoUtilizadas * -1
          : pesoUtilizadas;

        const caixas = parseInt(
          pesoUtilizadasPositivas / produto.peso_caixa_nivel_1,
          10,
        );
        const unidades = parseInt(
          pesoUtilizadasPositivas % produto.peso_caixa_nivel_1,
          10,
        );

        const objQtde = [];
        objQtde.push({
          qtde: caixas,
          label: `${caixas}cx1`,
        });
        objQtde.push({
          qtde: unidades,
          label: `${formatPeso(unidades)}kg`,
        });

        let labelRetorno = objQtde
          .filter((item) => item.qtde > 0)
          .map((item) => item.label)
          .join(' + ')
          .trim();

        labelRetorno = labelRetorno
          ? isNegativa
            ? formatNegativo(labelRetorno)
            : labelRetorno
          : '-';

        return labelRetorno;
        //return `${caixas}cx1 + ${formatPeso(unidades)}kg`;
      }

      const isNegativa = pesoUtilizadas < 0;

      const pesoUtilizadasPositivas = isNegativa
        ? pesoUtilizadas * -1
        : pesoUtilizadas;

      const objQtde = [];
      objQtde.push({
        qtde: pesoUtilizadasPositivas,
        label: `${formatPeso(pesoUtilizadasPositivas)}kg`,
      });

      let labelRetorno = objQtde
        .filter((item) => item.qtde > 0)
        .map((item) => item.label)
        .join(' + ')
        .trim();

      labelRetorno = labelRetorno
        ? isNegativa
          ? formatNegativo(labelRetorno)
          : labelRetorno
        : '-';

      return labelRetorno;

      //return produto.unidadesDisponivel;
    }
  }
  return '-';
}

//////////////////////////////////////////////////////////////

function analisaCodigo(codigo) {
  if (codigo.length === 13 && codigo.charAt(0) === '2') {
    return {
      tipo: 0,
      data: {
        codigo_interno: codigo.substring(1, 7),
        peso:
          parseInt(codigo.substring(7, 9), 10) +
          parseInt(codigo.substring(9, 12), 10) / 1000,
        digitoVerificador: parseInt(codigo.substring(12, 13), 10),
      },
    };
  }
  return {
    tipo: 1,
    data: {},
  };
}

function getUnitarioCaixa(produto, unitario) {
  if (produto) {
    if (
      produto.unidade.tipo === POR_UNIDADE ||
      produto.unidade.tipo === POR_PESO_FIXO
    ) {
      const pesoMedio =
        produto.unidade.tipo === POR_PESO_FIXO ? produto.peso_medio : 1;
      if (produto.nivelCaixa) {
        switch (produto.nivelCaixa) {
          case 1:
            return unitario / (produto.unidades_caixa_nivel_1 * pesoMedio);
          case 2:
            return (
              unitario /
              (produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                pesoMedio)
            );
          case 3:
            return (
              unitario /
              (produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3 *
                pesoMedio)
            );
          default:
            return 0;
        }
      } else {
        return unitario;
      }
    } else if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
      if (produto.nivelCaixa) {
        switch (produto.nivelCaixa) {
          case 1:
            return unitario / produto.peso_caixa_nivel_1;
          case 2:
            return (
              unitario /
              (produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2)
            );
          case 3:
            return (
              unitario /
              (produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3)
            );
          default:
            return 0;
        }
      } else {
        return unitario;
      }
    } else {
      return unitario;
    }
  } else {
    return 0;
  }
}

function getPesoCaixa(produto) {
  switch (produto.unidade.tipo) {
    case POR_PESO_FIXO: {
      if (produto.nivelCaixa) {
        switch (produto.nivelCaixa) {
          case 1:
            return produto.unidades_caixa_nivel_1 * produto.peso_medio;
          case 2:
            return (
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.peso_medio
            );
          case 3:
            return (
              produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3 *
              produto.peso_medio
            );
          default:
            return 0;
        }
      } else {
        return 0;
      }
    }
    case POR_PESO_VARIAVEL_SEM_UNIDADE: {
      if (produto.nivelCaixa) {
        switch (produto.nivelCaixa) {
          case 1:
            return produto.peso_caixa_nivel_1;
          case 2:
            return produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2;
          case 3:
            return (
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3
            );
          default:
            return 0;
        }
      } else {
        return 0;
      }
    }
    default:
      return 0;
  }
}

function changeCaixariaLocal(produto, setProduto, enqueueSnackbar) {
  const produto2 = { ...produto };
  if (produto2) {
    const hasUnidade =
      produto2.unidade.tipo === POR_UNIDADE ||
      produto2.unidade.tipo === POR_PESO_FIXO ||
      produto2.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE;
    if (hasUnidade) {
      const nivelCaixaAntigo = produto2.nivelCaixa;
      switch (produto2.nivelCaixa) {
        case 1:
          if (produto2.unidades_caixa_nivel_2 > 0) {
            produto2.nivelCaixa = 2;
          } else {
            produto2.nivelCaixa = 0;
          }
          break;
        case 2:
          if (produto2.unidades_caixa_nivel_3 > 0) {
            produto2.nivelCaixa = 3;
          } else {
            produto2.nivelCaixa = 0;
          }
          break;
        case 3:
          produto2.nivelCaixa = 0;
          break;
        default:
          if (
            produto2.unidades_caixa_nivel_1 > 0 ||
            produto2.peso_caixa_nivel_1 > 0
          ) {
            produto2.nivelCaixa = 1;
          } else {
            produto2.nivelCaixa = 0;
          }
          break;
      }
      produto2.nivelCaixaAntigo = nivelCaixaAntigo || -1;
      setProduto(produto2);
      enqueueSnackbar('Nível de caixaria alterado!', {
        variant: 'success',
      });
    }
  }
}

function getTotal2(peso, unidades, precoUnitario, produto) {
  if (produto) {
    if (produto.unidade.tipo === POR_UNIDADE) {
      if (produto.nivelCaixa) {
        return trunc10(unidades * precoUnitario, -2);
      }
      return trunc10(unidades * precoUnitario, -2);
    }
    if (produto.unidade.tipo === POR_PESO_FIXO) {
      if (produto.nivelCaixa) {
        return trunc10(unidades * precoUnitario, -2);
      }
      return trunc10(peso * precoUnitario, -2);
    }
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
      if (produto.nivelCaixa) {
        return trunc10(unidades * precoUnitario, -2);
      }
      return trunc10(peso * precoUnitario, -2);
    }
    return trunc10(peso * precoUnitario, -2);
  }
  return 0;
}

function getUnidadesItemCaixa(produto, unidades) {
  if (
    produto.unidade.tipo === POR_UNIDADE ||
    produto.unidade.tipo === POR_PESO_FIXO
  ) {
    if (produto.nivelCaixa) {
      switch (produto.nivelCaixa) {
        case 1:
          return unidades * produto.unidades_caixa_nivel_1;
        case 2:
          return (
            unidades *
            (produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2)
          );
        case 3:
          return (
            unidades *
            (produto.unidades_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3)
          );
        default:
          return 0;
      }
    } else {
      return unidades;
    }
  } else {
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
      return 0;
    }
    return unidades;
  }
}

function getUnidadesCaixa(produto) {
  if (
    produto.unidade.tipo === POR_UNIDADE ||
    produto.unidade.tipo === POR_PESO_FIXO
  ) {
    if (produto.nivelCaixa) {
      switch (produto.nivelCaixa) {
        case 1:
          return produto.unidades_caixa_nivel_1;
        case 2:
          return (
            produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2
          );
        case 3:
          return (
            produto.unidades_caixa_nivel_1 *
            produto.unidades_caixa_nivel_2 *
            produto.unidades_caixa_nivel_3
          );
        default:
          return 0;
      }
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}

function pesoEfetivo(produto, unidades, peso) {
  if (produto) {
    switch (produto.unidade.tipo) {
      case POR_UNIDADE: {
        return 0;
      }
      case POR_PESO_FIXO: {
        if (produto.nivelCaixa) {
          switch (produto.nivelCaixa) {
            case 1:
              return (
                unidades * produto.unidades_caixa_nivel_1 * produto.peso_medio
              );
            case 2:
              return (
                unidades *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.peso_medio
              );
            case 3:
              return (
                unidades *
                produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3 *
                produto.peso_medio
              );
            default:
              return unidades * produto.peso_medio;
          }
        } else {
          return unidades * produto.peso_medio;
        }
      }
      case POR_PESO_VARIAVEL_SEM_UNIDADE: {
        if (produto.nivelCaixa) {
          switch (produto.nivelCaixa) {
            case 1:
              return unidades * produto.peso_caixa_nivel_1;
            case 2:
              return (
                unidades *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2
              );
            case 3:
              return (
                unidades *
                produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3
              );
            default:
              return peso;
          }
        } else {
          return peso;
        }
      }
      case POR_PESO_VARIAVEL: {
        return peso;
      }
      default:
        return 0;
    }
  }
  return 0;
}

function getValorCompraMedioImposto(produto) {
  if (produto) {
    if (produto.unidade.tipo === POR_UNIDADE) {
      if (produto.nivelCaixa) {
        switch (produto.nivelCaixa) {
          case 1:
            return (
              produto.precoCompraMedioImpostos * produto.unidades_caixa_nivel_1
            );
          case 2:
            return (
              produto.precoCompraMedioImpostos *
              (produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2)
            );
          case 3:
            return (
              produto.precoCompraMedioImpostos *
              (produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3)
            );
          default:
            return 0;
        }
      } else {
        return produto.precoCompraMedioImpostos;
      }
    }
    if (produto.unidade.tipo === POR_PESO_FIXO) {
      if (produto.nivelCaixa) {
        switch (produto.nivelCaixa) {
          case 1:
            return (
              produto.precoCompraMedioImpostos *
              produto.peso_medio *
              produto.unidades_caixa_nivel_1
            );
          case 2:
            return (
              produto.precoCompraMedioImpostos *
              produto.peso_medio *
              (produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2)
            );
          case 3:
            return (
              produto.precoCompraMedioImpostos *
              produto.peso_medio *
              (produto.unidades_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3)
            );
          default:
            return 0;
        }
      } else {
        return produto.precoCompraMedioImpostos;
      }
    }
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
      if (produto.nivelCaixa) {
        switch (produto.nivelCaixa) {
          case 1:
            return (
              produto.precoCompraMedioImpostos * produto.peso_caixa_nivel_1
            );
          case 2:
            return (
              produto.precoCompraMedioImpostos *
              (produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2)
            );
          case 3:
            return (
              produto.precoCompraMedioImpostos *
              (produto.peso_caixa_nivel_1 *
                produto.unidades_caixa_nivel_2 *
                produto.unidades_caixa_nivel_3)
            );
          default:
            return 0;
        }
      } else {
        return produto.precoCompraMedioImpostos;
      }
    }
    return produto.precoCompraMedioImpostos;
  }
  return 0;
}

function getLabelNivelCaixaria(nivel) {
  if (nivel === 1) {
    return 'cx1';
  }

  if (nivel === 2) {
    return 'cx2';
  }

  if (nivel === 3) {
    return 'cx3';
  }

  return '';
}

export {
  getPrecoUnitarioVerdadeiroCaixa,
  getPrecoUnitarioVerdadeiroCaixa2,
  getQuantidadeCaixaria,
  getQuantidadeCaixariaLabel,
  getQuantidadeEfetiva,
  getNivelCaixaQtde,
  getLabelTipoUnidade,
  getNivelCaixaCodigo,
  getTotalCaixaTipoOferta,
  getLabelTipoUnidadeCaixaria,
  getOptionsTipoUnidadeOferta,
  getLabelUnidade,
  getPrecos,
  getNomeProduto,
  getTipoUnidade,
  getEstoqueLabel,
  analisaCodigo,
  getUnitarioCaixa,
  getPesoCaixa,
  changeCaixariaLocal,
  getTotal2,
  getUnidadesItemCaixa,
  getUnidadesCaixa,
  pesoEfetivo,
  getValorCompraMedioImposto,
  getLabelNivelCaixaria,
};
