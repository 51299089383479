import React, { useRef } from 'react';
import {
  DateField,
  ListBase,
  ListToolbar,
  Pagination,
  Datagrid,
  useRefresh,
  Title,
} from 'react-admin';

import { Divider, Card, makeStyles } from '@material-ui/core';

import { stylesMalote } from '../../utils';
import ValorTransferenciaField from '../Transacoes/Fields/ValorTransferenciaField';
import Actions from './ActionsList';
import DialogoChangeStatus from './DialogoChangeStatus';
import DialogoShowSangria from './DialogoShowSangria';
import ActionsField from './Fields/ActionsField';
import CodigoField from './Fields/CodigoField';
import ContaField from './Fields/ContaField';
import FrentistaField from './Fields/FrentistaField';
import ResponsavelField from './Fields/ResponsavelField';
import StatusField from './Fields/StatusField';
import Filter from './Filter';
import DialogoDeletar from './DialogoDeletar'

const useStylesDatagrid = makeStyles((theme) => stylesMalote);

const MyList = ({ ...props }) => {
  const refDialogoChangeStatus = useRef(null);
  const refresh = useRefresh();
  const classes = useStylesDatagrid();
  const refDialogoShowSangria = useRef(null);
  const refDialogoDeletar = useRef(null)

  function changeStatus(record) {
    if (refDialogoChangeStatus.current)
      refDialogoChangeStatus.current.handleOpen(record.record.id);
  }

  const show = (data) => {
    if (refDialogoShowSangria.current) {
      refDialogoShowSangria.current.handleOpen(data.record.id);
    }
  };

  const handleDeletar = (data) => {
    if (refDialogoDeletar.current) {
      refDialogoDeletar.current.handleOpen(data.record);
    }
  };


  function handleClose() {
    refresh();
  }

  return (
    <>
      <Title title="Sangrias" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Divider />
        <div>
          <Datagrid>
            <StatusField
              source="codigo"
              label=""
              headerClassName={classes.dataHeader}
              cellClassName={classes.dataCell}
              sortable={false}
            />
            <CodigoField
              source="codigo"
              label="Código"
              headerClassName={classes.dataHeader}
              cellClassName={classes.dataCell}
              sortable={false}
            />
            <DateField
              source="data_pagamento"
              label="Horário"
              headerClassName={classes.dataHeader}
              cellClassName={classes.dataCell}
              options={{ hour: 'numeric', minute: 'numeric' }}
              sortable={false}
            />
            <ContaField
              label="Caixa"
              source="conta_id"
              sortable={false}
              headerClassName={classes.dataHeader}
              cellClassName={classes.categoriaCell}
            />
            <FrentistaField
              label="Frentista"
              source="responsavel"
              sortable={false}
              headerClassName={classes.dataHeader}
              cellClassName={classes.categoriaCell}
            />
            <ResponsavelField
              label="Responsável"
              source="responsavel"
              sortable={false}
              headerClassName={classes.dataHeader}
              cellClassName={classes.categoriaCell}
            />
            <ValorTransferenciaField
              source="valor"
              textAlign="right"
              label="Valor"
              headerClassName={classes.dataHeader}
              cellClassName={classes.valorCell}
              sortable={false}
            />
            <ActionsField
              source="lalala"
              handleEfetivar={changeStatus}
              handleShow={show}
              handleDeletar={handleDeletar}
              textAlign="right"
              label="Ações"
              headerClassName={classes.dataHeader}
              cellClassName={classes.actionsCell}
              sortable={false}
            />
          </Datagrid>
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoChangeStatus
        ref={refDialogoChangeStatus}
        handleClose={handleClose}
      />
      <DialogoDeletar
        ref={refDialogoDeletar}
        handleClose={handleClose}
      />
      <DialogoShowSangria ref={refDialogoShowSangria} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Sangrias"
    />
  </>
);

export default List2;
