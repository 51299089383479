import React from 'react';
import { Title } from 'react-admin';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: 'black',
    width: '1px',
    whiteSpace: 'nowrap',
    padding: '2px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '2px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
}))(TableCell);

const DashbboardContabilidade = () => (
  <div>
    <div
      style={{
        fontSize: '25px',
        fontWeight: 'bold',
        marginTop: '35px',
        marginBottom: '30px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <h1
        style={{
          fontSize: '25px',
        }}
      >
        Grade de Horários
      </h1>
    </div>
    <Title title="Grade de Horários" />
    <div
      style={{
        display: 'flex',
        gap: '20px',
      }}
    >
      <Table
        aria-label="customized table"
        style={{
          flex: 3,
        }}
      >
        <TableBody>
          <TableRow>
            <StyledTableCell
              colSpan={6}
              style={{
                fontWeight: 'bold',
              }}
            >
              GRADE DE HORÁRIOS ATUALIZADA (VÁLIDO A PARTIR DE 01/07/2023)
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              colSpan={6}
              style={{
                fontWeight: 'bold',
              }}
            >
              MANHÃ
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              colSpan={2}
              style={{
                fontWeight: 'bold',
              }}
            >
              SS
            </StyledTableCell>
            <StyledTableCell
              colSpan={2}
              style={{
                fontWeight: 'bold',
              }}
            >
              ESCALADO
            </StyledTableCell>
            <StyledTableCell
              colSpan={2}
              style={{
                fontWeight: 'bold',
              }}
            >
              ESCALADO (DOMINGO)
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                backgroundColor: '#dae1f3',
              }}
            >
              Entrada 1
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#dae1f3',
              }}
            >
              Entrada 2
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Entrada 1
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Entrada 2
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Entrada 1
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Entrada 2
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>07:00:00</StyledTableCell>
            <StyledTableCell>14:00:00</StyledTableCell>
            <StyledTableCell>07:00:00</StyledTableCell>
            <StyledTableCell>14:00:00</StyledTableCell>
            <StyledTableCell>07:00:00</StyledTableCell>
            <StyledTableCell />
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                backgroundColor: '#dae1f3',
              }}
            >
              Saída 1
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#dae1f3',
              }}
            >
              Saída 2
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Saída 1
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Saída 2
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Saída 1
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Saída 2
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>12:00:00</StyledTableCell>
            <StyledTableCell>16:20:00</StyledTableCell>
            <StyledTableCell>12:00:00</StyledTableCell>
            <StyledTableCell>17:04:00</StyledTableCell>
            <StyledTableCell>13:00:00</StyledTableCell>
            <StyledTableCell />
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                backgroundColor: '#dae1f3',
              }}
            >
              Soma das Horas
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#dae1f3',
              }}
            >
              Soma das Horas
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Soma das Horas
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Soma das Horas
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Soma das Horas
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Soma das Horas
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>05:00:00</StyledTableCell>
            <StyledTableCell>02:20:00</StyledTableCell>
            <StyledTableCell>05:00:00</StyledTableCell>
            <StyledTableCell>03:04:00</StyledTableCell>
            <StyledTableCell>06:00:00</StyledTableCell>
            <StyledTableCell>00:00:00</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                fontWeight: 'bold',
                backgroundColor: '#dae1f3',
              }}
            >
              Carga Horária
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#dae1f3',
              }}
            >
              07:20:00
            </StyledTableCell>
            <StyledTableCell
              style={{
                fontWeight: 'bold',
                backgroundColor: '#fce4d7',
              }}
            >
              Carga Horária
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              08:04:00
            </StyledTableCell>
            <StyledTableCell
              style={{
                fontWeight: 'bold',
                backgroundColor: '#fce4d7',
              }}
            >
              Carga Horária
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              06:00:00
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                fontWeight: 'bold',
              }}
            >
              Almoço
            </StyledTableCell>
            <StyledTableCell>02:00:00</StyledTableCell>
            <StyledTableCell
              style={{
                fontWeight: 'bold',
              }}
            >
              Almoço
            </StyledTableCell>
            <StyledTableCell>02:00:00</StyledTableCell>
            <StyledTableCell
              style={{
                fontWeight: 'bold',
              }}
            >
              Almoço
            </StyledTableCell>
            <StyledTableCell />
          </TableRow>
          <TableRow>
            <StyledTableCell
              colSpan={6}
              style={{
                fontWeight: 'bold',
              }}
            >
              NOITE
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              colSpan={2}
              style={{
                fontWeight: 'bold',
              }}
            >
              SS
            </StyledTableCell>
            <StyledTableCell
              colSpan={2}
              style={{
                fontWeight: 'bold',
              }}
            >
              ESCALADO
            </StyledTableCell>
            <StyledTableCell
              colSpan={2}
              style={{
                fontWeight: 'bold',
              }}
            >
              ESCALADO (DOMINGO)
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                backgroundColor: '#dae1f3',
              }}
            >
              Entrada 1
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#dae1f3',
              }}
            >
              Entrada 2
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Entrada 1
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Entrada 2
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Entrada 1
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Entrada 2
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>12:00:00</StyledTableCell>
            <StyledTableCell>16:20:20</StyledTableCell>
            <StyledTableCell>11:46:00</StyledTableCell>
            <StyledTableCell>16:50:00</StyledTableCell>
            <StyledTableCell>12:00:00</StyledTableCell>
            <StyledTableCell />
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                backgroundColor: '#dae1f3',
              }}
            >
              Saída 1
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#dae1f3',
              }}
            >
              Saída 2
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Saída 1
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Saída 2
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Saída 1
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Saída 2
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>14:20:00</StyledTableCell>
            <StyledTableCell>21:20:00</StyledTableCell>
            <StyledTableCell>14:50:00</StyledTableCell>
            <StyledTableCell>21:50:00</StyledTableCell>
            <StyledTableCell>18:00:00</StyledTableCell>
            <StyledTableCell />
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                backgroundColor: '#dae1f3',
              }}
            >
              Soma das Horas
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#dae1f3',
              }}
            >
              Soma das Horas
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Soma das Horas
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Soma das Horas
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Soma das Horas
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              Soma das Horas
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>02:20:00</StyledTableCell>
            <StyledTableCell>05:00:00</StyledTableCell>
            <StyledTableCell>03:04:00</StyledTableCell>
            <StyledTableCell>05:00:00</StyledTableCell>
            <StyledTableCell>06:00:00</StyledTableCell>
            <StyledTableCell>00:00:00</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                fontWeight: 'bold',
                backgroundColor: '#dae1f3',
              }}
            >
              Carga Horária
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#dae1f3',
              }}
            >
              07:20:00
            </StyledTableCell>
            <StyledTableCell
              style={{
                fontWeight: 'bold',
                backgroundColor: '#fce4d7',
              }}
            >
              Carga Horária
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              08:04:00
            </StyledTableCell>
            <StyledTableCell
              style={{
                fontWeight: 'bold',
                backgroundColor: '#fce4d7',
              }}
            >
              Carga Horária
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: '#fce4d7',
              }}
            >
              06:00:00
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              style={{
                fontWeight: 'bold',
              }}
            >
              Almoço
            </StyledTableCell>
            <StyledTableCell>02:00:00</StyledTableCell>
            <StyledTableCell
              style={{
                fontWeight: 'bold',
              }}
            >
              Almoço
            </StyledTableCell>
            <StyledTableCell>02:00:00</StyledTableCell>
            <StyledTableCell
              style={{
                fontWeight: 'bold',
              }}
            >
              Almoço
            </StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableBody>
      </Table>

      <div
        style={{
          flex: 1,
        }}
      >
        <Table aria-label="customized table22">
          <TableBody>
            <TableRow>
              <StyledTableCell
                colSpan={2}
                style={{
                  fontWeight: 'bold',
                }}
              >
                SUPERPOSIÇÃO
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                style={{
                  fontWeight: 'bold',
                }}
              >
                11:46:00
              </StyledTableCell>
              <StyledTableCell
                rowSpan={2}
                style={{
                  fontWeight: 'bold',
                }}
              >
                00:14:00
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                style={{
                  fontWeight: 'bold',
                }}
              >
                12:00:00
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                style={{
                  fontWeight: 'bold',
                }}
              >
                14:00:00
              </StyledTableCell>
              <StyledTableCell
                rowSpan={2}
                style={{
                  fontWeight: 'bold',
                }}
              >
                00:50:00
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                style={{
                  fontWeight: 'bold',
                }}
              >
                14:50:00
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                style={{
                  fontWeight: 'bold',
                }}
              >
                16:50:00
              </StyledTableCell>
              <StyledTableCell
                rowSpan={2}
                style={{
                  fontWeight: 'bold',
                }}
              >
                00:14:00
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                style={{
                  fontWeight: 'bold',
                }}
              >
                17:04:00
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  </div>
);

export default DashbboardContabilidade;
