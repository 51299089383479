import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  useListContext,
  Title,
  ListContextProvider,
  TextField,
} from 'react-admin';

import { Card, Divider, Tabs, Tab } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Datagrid from '../../Components/Datagrid';
import {
  STATUS_ATIVO,
  STATUS_INATIVO,
  formatMoeda,
  POR_UNIDADE,
  POR_PESO_FIXO,
  TIPO_UNIDADE_OFERTA_KG,
  TIPO_UNIDADE_OFERTA_UN,
  TIPO_UNIDADE_OFERTA_CAIXA,
  TIPO_UNIDADE_OFERTA_CAIXETA,
  TIPO_UNIDADE_OFERTA_CAIXA_MAE,
  TIPO_OFERTA_PRODUTO_JORNAL,
  TIPO_OFERTA_PRODUTO_LIBERACAO,
  getTotalCaixaTipoOferta,
  getLabelTipoUnidade,
  getLabelTipoUnidadeCaixaria,
  getLabelUnidade,
} from '../../utils';
import ActionsField from './ActionsField';
import Actions from './ActionsList';
import DialogoDeletar from './DialogoDeletar';
import DialogoEstenderPrazo from './DialogoEstenderPrazo';
import DialogoTipoOferta from './DialogoTipoOferta';
import DataMaximaField from './Fields/DataMaximaField';
import DataMinimaField from './Fields/DataMinimaField';
import PrecoVendaField from './Fields/PrecoVendaField';
import ProdutoField from './Fields/ProdutoField';
import QtdeMinimaField from './Fields/QtdeMinimaField';
import TempoRestanteField from './Fields/TempoRestanteField';
import ValorField from './Fields/ValorField';
import Filter from './Filter';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#00796b',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const PostPanel = ({ record }) => {
  const tipoUnidade = record.produto.unidade.tipo;

  const labelTipoUnidadeCaixaria = getLabelTipoUnidadeCaixaria(
    record.tipo_unidade,
    record.produto,
  );

  const labelTipoUnidade = getLabelTipoUnidade(record.tipo_unidade);

  return (
    <div>
      {record.escalonadas.length ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            marginBottom: '20px',
            border: '1px solid red',
            padding: '10px',
            borderRadius: '20px',
          }}
        >
          <h3 style={{ marginBottom: '10px', color: '#193bf299' }}>
            Escalonadas:
          </h3>
          <TableContainer
            component={Paper}
            style={{
              borderRadius: '20px',
            }}
          >
            <Table aria-label="customized table">
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: '#00796b',
                  }}
                >
                  <StyledTableCell>{`Quantidade (${labelTipoUnidade})`}</StyledTableCell>
                  <StyledTableCell>{`Preço Unitário / ${getLabelUnidade(
                    tipoUnidade,
                  )}`}</StyledTableCell>
                  {labelTipoUnidadeCaixaria !== '' && (
                    <StyledTableCell>{`Preço ${labelTipoUnidadeCaixaria}`}</StyledTableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {record.escalonadas
                  .sort((a, b) => a.quantidade - b.quantidade)
                  .map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell>{row.quantidade}</StyledTableCell>
                      <StyledTableCell>{`${formatMoeda(
                        row.valor,
                      )}`}</StyledTableCell>
                      {labelTipoUnidadeCaixaria !== '' && (
                        <StyledTableCell>{`${formatMoeda(
                          getTotalCaixaTipoOferta(
                            record.produto,
                            row.valor,
                            record.tipo_unidade,
                          ),
                        )}`}</StyledTableCell>
                      )}
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : null}

      {record.criador && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            marginBottom: '20px',
            border: '1px solid red',
            padding: '10px',
            borderRadius: '20px',
          }}
        >
          <h3 style={{ marginBottom: '10px', color: '#193bf299' }}>Criador:</h3>
          <div>
            <span
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              Usuário:
            </span>
            <span>{record.criador ? record.criador.username : ''}</span>
          </div>
        </div>
      )}

      {record.atualizador && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            marginBottom: '20px',
            border: '1px solid red',
            padding: '10px',
            borderRadius: '20px',
          }}
        >
          <h3 style={{ marginBottom: '10px', color: '#193bf299' }}>
            Último a atualizar:
          </h3>
          <div>
            <span
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              Usuário:
            </span>
            <span>{record.atualizador ? record.atualizador.username : ''}</span>
          </div>
        </div>
      )}

      {record.deletador && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            marginBottom: '20px',
            border: '1px solid red',
            padding: '10px',
            borderRadius: '20px',
          }}
        >
          <h3 style={{ marginBottom: '10px', color: '#193bf299' }}>
            Deletador:
          </h3>
          <div>
            <span
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              Usuário:
            </span>
            <span>{record.deletador ? record.deletador.username : ''}</span>
          </div>
        </div>
      )}
    </div>
  );
};

const tabs = [
  { id: 'ativos', name: 'Ativos' },
  { id: 'inativos', name: 'Inativos' },
];

const MyList = ({ ...props }) => {
  const refDialogoDelete = useRef(null);
  const refDialogoShow = useRef(null);
  const refDialogoEstenderPrazo = useRef(null);
  const refDialogoTipoOferta = useRef(null);
  const refresh = useRefresh();
  const [ativos, setAtivos] = useState([]);
  const [inativos, setInativos] = useState([]);
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;

  function handleCriar() {
    if (refDialogoTipoOferta.current) {
      refDialogoTipoOferta.current.handleCreate(filterValues.tipo);
    }
  }

  function handleEdit({ id, tipo }) {
    if (refDialogoTipoOferta.current) {
      refDialogoTipoOferta.current.handleEdit(id, tipo);
    }
  }

  function handleDeletar({ id }) {
    if (refDialogoDelete.current) {
      refDialogoDelete.current.handleOpen(id);
    }
  }

  function handleShow({ id }) {
    if (refDialogoShow.current) {
      refDialogoShow.current.handleEdit(id);
    }
  }

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      switch (filterValues.status) {
        case STATUS_ATIVO:
          setAtivos(ids);
          break;
        case STATUS_INATIVO:
          setInativos(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.status]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          {
            ...filterValues,
            status: value === 'ativos' ? STATUS_ATIVO : STATUS_INATIVO,
          },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const handleClose = () => {
    refresh();
  };

  /*useEffect(() => {
    const interval = setInterval(() => {
      setVersion((version) => version + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, []);*/

  function handleEstenderOferta({ id }) {
    if (refDialogoEstenderPrazo.current) {
      refDialogoEstenderPrazo.current.handleOpen(id);
    }
  }

  return (
    <>
      <Title title="Ofertas" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Divider />
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status === STATUS_ATIVO ? tabs[0].id : tabs[1].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.status === STATUS_ATIVO && (
            <ListContextProvider value={{ ...listContext, ids: ativos }}>
              <Datagrid {...props}>
                <TextField source="id" label="Id" />
                <ProdutoField
                  source="produto"
                  sortable={false}
                  label="Produto"
                />
                <DataMinimaField
                  source="data_minima"
                  //sortable={false}
                  label="Início"
                />
                <DataMaximaField
                  source="data_maxima"
                  //sortable={false}
                  label="Vencimento"
                />
                <TempoRestanteField
                  source="data_maxima"
                  //sortable={false}
                  label="Tempo Restante"
                />
                <PrecoVendaField
                  source="valor"
                  sortable={false}
                  label="Preço Original de Venda"
                />
                <ValorField
                  source="valor"
                  //sortable={false}
                  label="Preço Oferta"
                />
                {filterValues.tipo === TIPO_OFERTA_PRODUTO_LIBERACAO && (
                  <QtdeMinimaField
                    source="valor"
                    sortable={false}
                    label="Qtde Mínima"
                  />
                )}
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handleEdit={handleEdit}
                  handleShow={handleShow}
                  handleDeletar={handleDeletar}
                  handleEstenderOferta={handleEstenderOferta}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.status === STATUS_INATIVO && (
            <ListContextProvider value={{ ...listContext, ids: inativos }}>
              <Datagrid {...props}>
                <TextField source="id" label="Id" />
                <ProdutoField
                  source="produto"
                  sortable={false}
                  label="Produto"
                />
                <DataMinimaField
                  source="data_minima"
                  //sortable={false}
                  label="Início"
                />
                <DataMaximaField
                  source="data_maxima"
                  //sortable={false}
                  label="Vencimento"
                />
                <TempoRestanteField
                  source="data_maxima"
                  //sortable={false}
                  label="Tempo Restante"
                />
                <ValorField
                  source="valor"
                  sortable={false}
                  label="Preço Oferta"
                />
                {filterValues.tipo === TIPO_OFERTA_PRODUTO_LIBERACAO && (
                  <QtdeMinimaField
                    source="valor"
                    sortable={false}
                    label="Qtde Mínima"
                  />
                )}
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoDeletar ref={refDialogoDelete} handleClose={handleClose} />
      <DialogoEstenderPrazo
        ref={refDialogoEstenderPrazo}
        handleClose={handleClose}
      />
      <DialogoTipoOferta ref={refDialogoTipoOferta} handleClose={handleClose} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      filterDefaultValues={{
        status: STATUS_ATIVO,
        tipo: TIPO_OFERTA_PRODUTO_JORNAL,
      }}
      sort={{ field: 'id', order: 'DESC' }}
    />
  </>
);

export default List2;
