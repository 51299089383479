import React, { useRef } from 'react';
import {
  TextField,
  ReferenceField,
  ShowButton,
  DateField,
  NumberField,
  ListBase,
  ListToolbar,
  Pagination,
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  FunctionField,
  RecordContext,
  useRefresh,
} from 'react-admin';

import { Card } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import Datagrid from '../../Components/Datagrid';
import EditButton from '../../Components/EditButton2';
import ListTemplate from '../../Components/List';
import { formatPreco } from '../../utils';
import ActionsField from './ActionsField';
import Actions from './ActionsList';
import Dialogo from './ContainerDialogoCompra';
import DialogoDataFaturameto from './DialogoDataFaturameto';
import DialogoDeletar from './DialogoDeletar';
import DialogoIcms from './DialogoIcms';
import DialogoShow from './DialogoShow';
import StatusField from './Fields/StatusField2';
import Filter from './Filter';

/* const List = (props) => {
  const refDialogoCompra = useRef(null);
  function handleCriar() {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleCreate();
    }
  }
  function handleEdit({ id }) {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleEdit(id);
    }
  }
  return (
    <>
      <ListTemplate
        {...props}
        filters={<Filter />}
        title="Compras"
        actions={<Actions handleCriar={handleCriar} />}
        filterDefaultValues={{
          periodo: {
            start: new Date(),
            end: new Date(),
          },
        }}
        isDelete
      >
        <ReferenceField
          label="Fornecedor"
          source="fornecedor_id"
          reference="fornecedores"
        >
          <TextField source="nome" />
        </ReferenceField>
        <NumberField
          source="valor"
          locales="pt-BR"
          options={{
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL',
          }}
          label="Valor Total"
        />
        <DateField source="data" label="Data da Compra" />
        <TextField source="numero_nota_fiscal" label="Número nota fiscal" />
        <EditButton editHundle={handleEdit} />
        <ShowButton />
        <Dialogo ref={refDialogoCompra} />
      </ListTemplate>
    </>
  );
};

export default List; */

const PostPanel = (props) => (
  <Show
    {...props}
    /* disable the app title change when shown */
    title="."
    actions={false}
  >
    <SimpleShowLayout>
      <ReferenceManyField
        addLabel={false}
        reference="parcelascompra"
        target="compra_id"
        // pagination={<Pagination />}
      >
        <Datagrid isBlue>
          <StatusField
            source="despesa.status"
            label="Status"
            sortable={false}
            // className={classes.statusContent}
          />
          <DateField source="data_pagamento" label="Data de pagamento" />
          <ReferenceField
            label="Conta"
            source="despesa.conta_id"
            reference="contas"
            sortable={false}
          >
            <TextField source="nome" />
          </ReferenceField>
          <ReferenceField
            label="Categoria Problema"
            source="despesa.categoria_problema_despesa_id"
            reference="categorias-problemas-despesas"
            sortable={false}
          >
            <TextField source="nome" />
          </ReferenceField>
          <NumberField
            source="valor"
            locales="pt-BR"
            options={{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            }}
            label="Valor"
          />
        </Datagrid>
      </ReferenceManyField>
      {/* </Tab> */}
      {/* </TabbedShowLayout> */}
    </SimpleShowLayout>
  </Show>
);

const MyList = ({ ...props }) => {
  const refDialogoCompra = useRef(null);
  const refDialogoDeleteResource = useRef(null);
  const refDialogoIcms = useRef(null);
  const refDialogoDataFaturamento = useRef(null);
  const refDialogoShowCompra = useRef(null);

  const refresh = useRefresh();

  function handleCriar() {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleCreate();
    }
  }
  function handleEdit({ id }) {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleEdit(id);
    }
  }
  function deleteHundle({ id }) {
    if (refDialogoDeleteResource.current)
      refDialogoDeleteResource.current.handleOpen(id);
  }

  function icmsHundle(record) {
    if (refDialogoIcms.current) refDialogoIcms.current.handleOpen(record.id);
  }

  function dataFaturamentoHundle(record) {
    if (refDialogoDataFaturamento.current)
      refDialogoDataFaturamento.current.handleOpen(record.id);
  }

  function handleImprimir(record) {}

  function handleShow(record) {
    if (refDialogoShowCompra.current) {
      refDialogoShowCompra.current.handleOpen(record.id);
    }
  }

  function handleClose() {
    refresh();
  }

  return (
    <>
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <TextField source="id" label="Nº compra" />
          <ReferenceField
            label="Fornecedor"
            source="fornecedor_id"
            reference="fornecedores"
          >
            <TextField source="nome" />
          </ReferenceField>
          <NumberField
            source="valor"
            locales="pt-BR"
            options={{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            }}
            label="Valor Total"
          />

          <DateField source="data" label="Data da Compra" />
          <TextField source="numero_nota_fiscal" label="Número nota fiscal" />
          <NumberField
            source="icms_fronteira"
            locales="pt-BR"
            options={{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            }}
            label="ICMS"
          />
          <FunctionField
            label="ICMS (%)"
            render={(record) => {
              const lala = record.icms_fronteira / record.valor;
              return `${
                Number.isNaN(lala)
                  ? '-'
                  : `${formatPreco(
                      (record.icms_fronteira / record.valor) * 100,
                    )} %`
              }`;
            }}
          />
          <FunctionField
            label="Faturamento"
            render={(record) => {
              if (record.data_faturamento) {
                const data = new Date(record.data_faturamento);
                return `${
                  data.getDate() < 10 ? `0${data.getDate()}` : data.getDate()
                }/${
                  data.getMonth() + 1 < 10
                    ? `0${data.getMonth() + 1}`
                    : data.getMonth() + 1
                }/${data.getFullYear()}`;
              }
              return '-';
            }}
          />
          {/* <DateField source="data_faturamento" label="Data Faturamento" /> */}
          {/* <ChangeStatusButton /> */}
          {/*<EditButton editHundle={handleEdit} />
          <ShowButton />
          <DeleteButton
            label="Editar ICMS"
            deleteHundle={icmsHundle}
            disabled={() => false}
            icon={<EditIcon />}
          />*/}
          {/* <DeleteButton
            label="Editar Data Faturamento"
            deleteHundle={dataFaturamentoHundle}
            disabled={() => false}
            icon={<EditIcon />}
          /> */}
          {/*<DeleteButton
            label="Excluir"
            deleteHundle={deleteHundle}
            disabled={() => false}
        />*/}
          <ActionsField
            source="lalala"
            handleEdit={handleEdit}
            handleShow={handleShow}
            handleDelete={deleteHundle}
            handleEditIcms={icmsHundle}
            handleImprimir={handleImprimir}
            textAlign="right"
            label="Ações"
            sortable={false}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <Dialogo ref={refDialogoCompra} />
      <DialogoDeletar
        ref={refDialogoDeleteResource}
        handleClose={handleClose}
      />
      <DialogoIcms ref={refDialogoIcms} handleClose={handleClose} />
      <DialogoDataFaturameto
        ref={refDialogoDataFaturamento}
        handleClose={handleClose}
      />
      <DialogoShow ref={refDialogoShowCompra} handleClose={() => {}} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Compras"
      filterDefaultValues={{
        periodo: {
          start: new Date(),
          end: new Date(),
        },
      }}
    />
  </>
);

export default List2;
