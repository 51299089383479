import React, { useState, useEffect, useRef } from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { TrainRounded } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useDebounce } from 'use-debounce';

import { AutoCompleteLocal } from '../../../Components';
import { api, getListAllAPI } from '../../../services';
import {
  POR_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  formatPeso,
  STATUS_ATIVO,
} from '../../../utils';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {},
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ACIMA_ESTOQUE = 0;
const ABAIXO_ESTOQUE = 1;
const optionsEstoque = [
  {
    value: ACIMA_ESTOQUE,
    label: 'Acima',
  },
  {
    value: ABAIXO_ESTOQUE,
    label: 'Abaixo',
  },
];

const Filters = ({ setData }) => {
  const [ativo, setAtivo] = useState(true);
  const [categoriaSearch, setCategoriaSearch] = useState('');
  const [produtoSearch, setProdutoSearch] = useState('');
  const [showAtivo, setShowAtivo] = useState(false);
  const [showCategoriaSearch, setShowCategoriaSearch] = useState(false);
  const [showProdutoSearch, setShowProdutoSearch] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const iconButtonRef = useRef(null);
  const [ativoDeounce] = useDebounce(ativo, 500);
  const [categoriaSearchDebounce] = useDebounce(categoriaSearch, 500);
  const [produtoSearchDebounce] = useDebounce(produtoSearch, 500);
  const [showAtivoDebounce] = useDebounce(showAtivo, 500);
  const [showCategoriaSearchDebounce] = useDebounce(showCategoriaSearch, 500);
  const [showProdutoSearchDebounce] = useDebounce(showProdutoSearch, 500);
  const [unidade, setUnidade] = useState(null);
  const [optionsUnidades, setOptionsUnidades] = useState([]);
  const [showUnidades, setShowUnidades] = useState(false);
  const [showUnidadesDebounce] = useDebounce(showUnidades, 500);
  const [unidadeDebounce] = useDebounce(unidade, 500);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [estoque, setEstoque] = useState(null);
  const [showEstoque, setShowEstoque] = useState(false);
  const [estoqueDebounce] = useDebounce(estoque, 500);
  const [showEstoqueDebounce] = useDebounce(showEstoque, 500);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // handleClose();
  };

  useEffect(() => {
    async function getUnidades() {
      const data = await getListAllAPI(
        'unidades',
        ['id', 'asc'],
        { status: STATUS_ATIVO },
        [],
      );
      const unidades = data.data;
      const options = [];
      for (let j = 0; j < unidades.length; j += 1) {
        options.push({
          value: unidades[j].id,
          nome: unidades[j].nome,
        });
      }
      setOptionsUnidades(options);
    }
    getUnidades();
  }, []);

  useEffect(() => {
    async function getData() {
      setOpen(true);
      try {
        const filters = {};
        if (showAtivoDebounce) filters.ativo = ativoDeounce;
        if (showProdutoSearchDebounce)
          filters.produtoSearch = produtoSearchDebounce;
        if (showCategoriaSearchDebounce)
          filters.categoriaSearch = categoriaSearchDebounce;
        if (showUnidadesDebounce && unidade) filters.unidade_id = unidade.value;
        const data = await getListAllAPI(
          'inventario',
          ['id', 'asc'],
          filters,
          [],
        );
        // const data = await api.get('/inventario');
        const categorias = data.data;
        let somaInventarioPrecoTotalCompra = 0;
        let somaInventarioPrecoTotalVenda = 0;
        let somaInventarioUnidades = 0;
        let somaInventarioPeso = 0;
        for (let i = 0; i < categorias.length; i += 1) {
          let somaCategoriaPrecoTotalCompra = 0;
          let somaCategoriaPrecoTotalVenda = 0;
          let somaCategoriaUnidades = 0;
          let somaCategoriaPeso = 0;
          const produtos = [];
          for (let j = 0; j < categorias[i].produtos.length; j += 1) {
            let qtdeFinaceiro = 0;
            let estoque = 0;
            if (
              categorias[i].produtos[j].unidade.tipo === POR_UNIDADE ||
              categorias[i].produtos[j].unidade.tipo === POR_PESO_FIXO
            ) {
              estoque = categorias[i].produtos[j].unidadesDisponivel;
            } else {
              estoque = categorias[i].produtos[j].pesoDisponivel;
            }
            categorias[i].produtos[j].statusEstoque =
              estoque > categorias[i].produtos[j].estoque_minimo;
            const isPode = showEstoqueDebounce
              ? estoqueDebounce
                ? (estoqueDebounce.value === ACIMA_ESTOQUE &&
                    categorias[i].produtos[j].statusEstoque === true) ||
                  (estoqueDebounce.value === ABAIXO_ESTOQUE &&
                    categorias[i].produtos[j].statusEstoque === false)
                : true
              : true;
            const showUnidadesProduto =
              categorias[i].produtos[j].unidade.tipo === POR_UNIDADE ||
              categorias[i].produtos[j].unidade.tipo === POR_PESO_FIXO ||
              categorias[i].produtos[j].unidade.tipo === POR_PESO_VARIAVEL;
            const showPesoProduto =
              categorias[i].produtos[j].unidade.tipo === POR_PESO_VARIAVEL ||
              categorias[i].produtos[j].unidade.tipo === POR_PESO_FIXO ||
              categorias[i].produtos[j].unidade.tipo ===
                POR_PESO_VARIAVEL_SEM_UNIDADE;
            if (isPode) {
              if (categorias[i].produtos[j].unidade.tipo === POR_UNIDADE) {
                qtdeFinaceiro = categorias[i].produtos[j].unidadesDisponivel;
              } else {
                qtdeFinaceiro = categorias[i].produtos[j].pesoDisponivel;
              }
              const precoTotalCompraProduto =
                qtdeFinaceiro * categorias[i].produtos[j].precoCompraMedio;
              const precoTotalVendaProduto =
                qtdeFinaceiro * categorias[i].produtos[j].preco_venda;
              categorias[i].produtos[
                j
              ].precoTotalCompra = precoTotalCompraProduto;
              categorias[i].produtos[
                j
              ].precoTotalVenda = precoTotalVendaProduto;
              categorias[i].produtos[j].lucro =
                precoTotalVendaProduto - precoTotalCompraProduto;
              somaCategoriaPrecoTotalCompra += precoTotalCompraProduto;
              somaCategoriaPrecoTotalVenda += precoTotalVendaProduto;
              if (showUnidadesProduto) {
                somaCategoriaUnidades +=
                  categorias[i].produtos[j].unidadesDisponivel;
              }
              if (showPesoProduto) {
                somaCategoriaPeso += categorias[i].produtos[j].pesoDisponivel;
              }
              categorias[i].produtos[j].unidadesDisponivel = showUnidadesProduto
                ? categorias[i].produtos[j].unidadesDisponivel
                : '-';
              categorias[i].produtos[j].pesoDisponivel = showPesoProduto
                ? formatPeso(categorias[i].produtos[j].pesoDisponivel)
                : '-';
              produtos.push(categorias[i].produtos[j]);
            }
          }
          categorias[i].produtos = produtos;
          categorias[i].precoTotalCompra = somaCategoriaPrecoTotalCompra;
          categorias[i].precoTotalVenda = somaCategoriaPrecoTotalVenda;
          categorias[i].unidadesTotal = somaCategoriaUnidades;
          categorias[i].pesoTotal = formatPeso(somaCategoriaPeso);
          categorias[i].lucro =
            somaCategoriaPrecoTotalVenda - somaCategoriaPrecoTotalCompra;

          somaInventarioPrecoTotalCompra += somaCategoriaPrecoTotalCompra;
          somaInventarioPrecoTotalVenda += somaCategoriaPrecoTotalVenda;
          somaInventarioUnidades += somaCategoriaUnidades;
          somaInventarioPeso += somaCategoriaPeso;
        }
        /* setValorCompraTotal(somaInventarioPrecoTotalCompra);
      setValorVendaTotal(somaInventarioPrecoTotalVenda);
      setRows(categorias); */

        setData({
          rows: categorias,
          valorCompraTotal: somaInventarioPrecoTotalCompra,
          valorVendaTotal: somaInventarioPrecoTotalVenda,
          unidadesTotal: somaInventarioUnidades,
          pesoTotal: formatPeso(somaInventarioPeso),
        });
        setOpen(false);
      } catch (e) {
        setOpen(false);
      }
    }
    getData();
  }, [
    ativoDeounce,
    categoriaSearchDebounce,
    produtoSearchDebounce,
    showAtivoDebounce,
    showCategoriaSearchDebounce,
    showProdutoSearchDebounce,
    showUnidadesDebounce,
    unidadeDebounce,
    estoqueDebounce,
    showEstoqueDebounce,
  ]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          {showAtivo && (
            <div style={{ marginRight: '20px' }}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => setShowAtivo(false)}
              >
                <CloseIcon />
              </IconButton>
              <Switch
                checked={ativo}
                onChange={(e) => setAtivo(e.target.checked)}
                name="checkedA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                margin="normal"
              />
            </div>
          )}
          {showCategoriaSearch && (
            <div style={{ marginRight: '20px' }}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => setShowCategoriaSearch(false)}
              >
                <CloseIcon />
              </IconButton>
              <TextField
                id="categoria"
                label="categoria"
                variant="outlined"
                value={categoriaSearch}
                onChange={(e) => setCategoriaSearch(e.target.value)}
                margin="normal"
              />
            </div>
          )}
          {showProdutoSearch && (
            <div>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => setShowProdutoSearch(false)}
              >
                <CloseIcon />
              </IconButton>
              <TextField
                id="produto"
                label="produto"
                variant="outlined"
                value={produtoSearch}
                onChange={(e) => setProdutoSearch(e.target.value)}
                margin="normal"
              />
            </div>
          )}
          {showUnidades && (
            <div style={{ display: 'flex' }}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => setShowUnidades(false)}
              >
                <CloseIcon />
              </IconButton>
              <AutoCompleteLocal
                name="unidade"
                label="Unidade"
                value={unidade}
                onChangeLocal={(value) => setUnidade(value)}
                options={optionsUnidades}
                autoFocus
                campo="nome"
                // width="200px"
                // style={{ width: '200px' }}
              />
            </div>
          )}
          {showEstoque && (
            <div style={{ display: 'flex' }}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => setShowEstoque(false)}
              >
                <CloseIcon />
              </IconButton>
              <AutoCompleteLocal
                name="estoque"
                label="Estoque Mínimo"
                value={estoque}
                onChangeLocal={(value) => setEstoque(value)}
                options={optionsEstoque}
                autoFocus
                campo="label"
                // width="200px"
                // style={{ width: '200px' }}
              />
            </div>
          )}
        </div>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          ref={iconButtonRef}
          onClick={handleClick}
        >
          <FilterListIcon />
        </IconButton>
      </div>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!showAtivo && (
          <StyledMenuItem onClick={() => setShowAtivo(true)}>
            <ListItemText primary="Ativos" />
          </StyledMenuItem>
        )}
        {!showProdutoSearch && (
          <StyledMenuItem onClick={() => setShowProdutoSearch(true)}>
            <ListItemText primary="Produtos" />
          </StyledMenuItem>
        )}
        {!showCategoriaSearch && (
          <StyledMenuItem onClick={() => setShowCategoriaSearch(true)}>
            <ListItemText primary="Categorias" />
          </StyledMenuItem>
        )}
        {!showUnidades && (
          <StyledMenuItem onClick={() => setShowUnidades(true)}>
            <ListItemText primary="Unidade" />
          </StyledMenuItem>
        )}
        {!showEstoque && (
          <StyledMenuItem onClick={() => setShowEstoque(true)}>
            <ListItemText primary="Estoque Mínimo" />
          </StyledMenuItem>
        )}
      </StyledMenu>
      <Backdrop
        className={classes.backdrop}
        open={open} /* onClick={handleClose} */
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Filters;
