import React, {
  useRef, forwardRef,
} from 'react';

import DialogoProduto from './index3';
import DialogoCreateImpressao from './DialogoCreateImpressao';

const Container = forwardRef(({
  handleClose,
}, ref) => {
  const refDialogoImpressao = useRef(null);
  function handleCloseDialogo(id) {
    handleClose(id);
    if (id > 0) {
      if (refDialogoImpressao.current) {
        refDialogoImpressao.current.handleCreate(id);
      }
    }
  }
  return (
    <>
      <DialogoProduto ref={ref} handleClose={handleCloseDialogo} />
      <DialogoCreateImpressao ref={refDialogoImpressao} handleClose={() => {}} />
    </>
  );
});

export default Container;
