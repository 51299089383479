import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  transacao: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    // color: '#0039cb',
    // backgroundColor: '#0039cb',
  },
}));

const StatusField = (props) => {
  const classes = useStyles();
  const { source } = props;
  const record = useRecordContext(props);
  const value = `${record ? record.record[source] : '#000000'}`;
  return (
    <Avatar
      className={classes.transacao}
      style={{ color: value, backgroundColor: value }}
    />
  );
};

StatusField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default StatusField;
