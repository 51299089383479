import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';
import { useSnackbar } from 'notistack';

import { TextInput, IntegerInput, SwitchInput } from '../../Components';
import { createAPI, updateAPI, getListAllAPI } from '../../services';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    // borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [nome, setNome] = useState('');
  //
  const [criarVendas, setCriarVendas] = useState(false);
  const [consultarPrecos, setConsultarPrecos] = useState(false);
  const [utilizarTroco, setUtilizarTroco] = useState(false);
  const [utilizarTrocoAberturaCaixa, setUtilizarTrocoAberturaCaixa] = useState(
    false,
  );
  const [criarPedido, setCriarPedido] = useState(false);
  const [criarRetirada, setCriarRetirada] = useState(false);
  const [carregarPedido, setCarregarPedido] = useState(false);
  const [criarCliente, setCriarCliente] = useState(false);
  const [editarCliente, setEditarCliente] = useState(false);
  const [pesquisaPorNomes, setPesquisaPorNomes] = useState(false);
  //
  const [tipoPagamentoDinheiro, setTipoPagamentoDinheiro] = useState(false);
  const [tipoPagamentoCartaoCredito, setTipoPagamentoCartaoCredito] = useState(
    false,
  );
  const [tipoPagamentoCartaoDebito, setTipoPagamentoCartaoDebito] = useState(
    false,
  );
  const [tipoPagamentoPixQrcode, setTipoPagamentoPixQrcode] = useState(false);
  const [tipoPagamentoVoucher, setTipoPagamentoVoucher] = useState(false);
  const [tipoPagamentoDevolucao, setTipoPagamentoDevolucao] = useState(false);
  const [tipoPagamentoPixDistancia, setTipoPagamentoPixDistancia] = useState(
    false,
  );
  const [
    tipoPagamentoCreditoFidelidade,
    setTipoPagamentoCreditoFidelidade,
  ] = useState(false);
  //
  const [id, setId] = useState(DEFAULT_ID);

  const [carregando, setCarregando] = useState(false);

  const classes = useStyles({ cor: '#009FD4' });

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function reiniciar() {
    setId(DEFAULT_ID);
    setCarregando(false);
    setNome('');
    setCriarVendas(false);
    setConsultarPrecos(false);
    setUtilizarTroco(false);
    setUtilizarTrocoAberturaCaixa(false);
    setCriarPedido(false);
    setCriarRetirada(false);
    setCarregarPedido(false);
    setCriarCliente(false);
    setEditarCliente(false);
    setTipoPagamentoDinheiro(false);
    setTipoPagamentoCartaoCredito(false);
    setTipoPagamentoCartaoDebito(false);
    setTipoPagamentoPixQrcode(false);
    setTipoPagamentoVoucher(false);
    setTipoPagamentoDevolucao(false);
    setTipoPagamentoPixDistancia(false);
    setTipoPagamentoCreditoFidelidade(false);
    setPesquisaPorNomes(false);
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'categorias_frente_caixas',
        ['id', 'asc'],
        { id: [id] },
        [],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setCarregando(false);
        //
        setNome(data.data[0].nome);
        setCriarVendas(!!data.data[0].criar_venda);
        setConsultarPrecos(!!data.data[0].consultar_precos);
        setUtilizarTroco(!!data.data[0].utilizar_troco);
        setUtilizarTrocoAberturaCaixa(
          !!data.data[0].utilizar_troco_abertura_caixa,
        );
        setCriarPedido(!!data.data[0].criar_pedido);
        setCriarRetirada(!!data.data[0].criar_retirada);
        setCarregarPedido(!!data.data[0].carregar_pedido);
        setCriarCliente(!!data.data[0].criar_cliente);
        setEditarCliente(!!data.data[0].editar_cliente);
        setTipoPagamentoDinheiro(!!data.data[0].tipo_pagamento_dinheiro);
        setTipoPagamentoCartaoCredito(
          !!data.data[0].tipo_pagamento_cartao_credito,
        );
        setTipoPagamentoCartaoDebito(
          !!data.data[0].tipo_pagamento_cartao_debito,
        );
        setTipoPagamentoPixQrcode(!!data.data[0].tipo_pagamento_pix_qrcode);
        setTipoPagamentoVoucher(!!data.data[0].tipo_pagamento_voucher);
        setTipoPagamentoDevolucao(!!data.data[0].tipo_pagamento_devolucao);
        setTipoPagamentoPixDistancia(
          !!data.data[0].tipo_pagamento_pix_distancia,
        );
        setTipoPagamentoCreditoFidelidade(
          !!data.data[0].tipo_pagamento_credito_fidelidade,
        );
        setPesquisaPorNomes(!!data.data[0].pesquisa_por_nomes);
        //
        setCarregando(false);
      } else {
        throw 'Este cargo não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      if (id <= 0) {
        await createAPI('categorias_frente_caixas', {
          nome,
          //
          criar_venda: criarVendas,
          consultar_precos: consultarPrecos,
          utilizar_troco: utilizarTroco,
          utilizar_troco_abertura_caixa: utilizarTrocoAberturaCaixa,
          criar_pedido: criarPedido,
          criar_retirada: criarRetirada,
          carregar_pedido: carregarPedido,
          criar_cliente: criarCliente,
          editar_cliente: editarCliente,
          //
          tipo_pagamento_dinheiro: tipoPagamentoDinheiro,
          tipo_pagamento_cartao_credito: tipoPagamentoCartaoCredito,
          tipo_pagamento_cartao_debito: tipoPagamentoCartaoDebito,
          tipo_pagamento_pix_qrcode: tipoPagamentoPixQrcode,
          tipo_pagamento_voucher: tipoPagamentoVoucher,
          tipo_pagamento_devolucao: tipoPagamentoDevolucao,
          tipo_pagamento_pix_distancia: tipoPagamentoPixDistancia,
          tipo_pagamento_credito_fidelidade: tipoPagamentoCreditoFidelidade,
          pesquisa_por_nomes: pesquisaPorNomes,
          //
        });
        setCarregando(false);
        enqueueSnackbar('Categoria de Caixa criada com sucesso!', {
          variant: 'success',
        });
      } else {
        await updateAPI('categorias_frente_caixas', id, {
          nome,
          //
          criar_venda: criarVendas,
          consultar_precos: consultarPrecos,
          utilizar_troco: utilizarTroco,
          utilizar_troco_abertura_caixa: utilizarTrocoAberturaCaixa,
          criar_pedido: criarPedido,
          criar_retirada: criarRetirada,
          carregar_pedido: carregarPedido,
          criar_cliente: criarCliente,
          editar_cliente: editarCliente,
          //
          tipo_pagamento_dinheiro: tipoPagamentoDinheiro,
          tipo_pagamento_cartao_credito: tipoPagamentoCartaoCredito,
          tipo_pagamento_cartao_debito: tipoPagamentoCartaoDebito,
          tipo_pagamento_pix_qrcode: tipoPagamentoPixQrcode,
          tipo_pagamento_voucher: tipoPagamentoVoucher,
          tipo_pagamento_devolucao: tipoPagamentoDevolucao,
          tipo_pagamento_pix_distancia: tipoPagamentoPixDistancia,
          tipo_pagamento_credito_fidelidade: tipoPagamentoCreditoFidelidade,
          pesquisa_por_nomes: pesquisaPorNomes,
          //
        });
        setCarregando(false);
        enqueueSnackbar('Categoria de Caixa atualizada com sucesso!', {
          variant: 'success',
        });
      }

      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      reiniciar();
      setOpen(true);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  const marginLine = 2;

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        // fullWidth
        // maxWidth="sm"
        fullScreen
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {id < 0 ? `Nova Categoria de Caixa` : `Editar Categoria de Caixa`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <div>
                <div>
                  <TextInput
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    label="Descrição"
                    error={nome === ''}
                    helperText={nome === '' ? 'Este campo é obrigatório!' : ''}
                    autoFocus
                    handleEnter={() => {}}
                    handleKey={() => {}}
                  />
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Funcionalidades
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Criar Venda"
                      checked={criarVendas}
                      onChange={(e) => setCriarVendas(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Consultar Preço"
                      checked={consultarPrecos}
                      onChange={(e) => setConsultarPrecos(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Utilizar Troco"
                      checked={utilizarTroco}
                      onChange={(e) => setUtilizarTroco(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Utilizar Troco Abertura Caixa"
                      checked={utilizarTrocoAberturaCaixa}
                      onChange={(e) =>
                        setUtilizarTrocoAberturaCaixa(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Criar Pedido"
                      checked={criarPedido}
                      onChange={(e) => setCriarPedido(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Criar Retirada"
                      checked={criarRetirada}
                      onChange={(e) => setCriarRetirada(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Carregar Pedido"
                      checked={carregarPedido}
                      onChange={(e) => setCarregarPedido(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Criar Cliente"
                      checked={criarCliente}
                      onChange={(e) => setCriarCliente(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Editar Cliente"
                      checked={editarCliente}
                      onChange={(e) => setEditarCliente(e.target.checked)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Pesquisa por Nomes"
                      checked={pesquisaPorNomes}
                      onChange={(e) => setPesquisaPorNomes(e.target.checked)}
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Meios de pagamento
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Dinheiro"
                      checked={tipoPagamentoDinheiro}
                      onChange={(e) =>
                        setTipoPagamentoDinheiro(e.target.checked)
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Cartão Crédito"
                      checked={tipoPagamentoCartaoCredito}
                      onChange={(e) =>
                        setTipoPagamentoCartaoCredito(e.target.checked)
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Cartão Débito"
                      checked={tipoPagamentoCartaoDebito}
                      onChange={(e) =>
                        setTipoPagamentoCartaoDebito(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Pix Qrcode"
                      checked={tipoPagamentoPixQrcode}
                      onChange={(e) =>
                        setTipoPagamentoPixQrcode(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Voucher"
                      checked={tipoPagamentoVoucher}
                      onChange={(e) =>
                        setTipoPagamentoVoucher(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Devolução"
                      checked={tipoPagamentoDevolucao}
                      onChange={(e) =>
                        setTipoPagamentoDevolucao(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Pix Distância"
                      checked={tipoPagamentoPixDistancia}
                      onChange={(e) =>
                        setTipoPagamentoPixDistancia(e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Crédito Fidelidade"
                      checked={tipoPagamentoCreditoFidelidade}
                      onChange={(e) =>
                        setTipoPagamentoCreditoFidelidade(e.target.checked)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <Button
              onClick={handleCloseDialog}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
            >
              Cancelar
            </Button>
          </div>
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
              disabled={nome === ''}
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
