import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';

const StatusField = (props) => {
  const record = useRecordContext(props);
  const horario = record ? new Date(record.record[props.source]) : new Date();
  return (
    <Typography
      variant="subtitle1"
      style={{
        fontWeight: 'bold',
      }}
    >
      {moment(horario).format('HH:mm')}
    </Typography>
  );
};

StatusField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default StatusField;
