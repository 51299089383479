import React, { useState, useEffect } from 'react';
import { Title, useVersion } from 'react-admin';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import HelpIcon from '@material-ui/icons/Help';
import ListIcon from '@material-ui/icons/List';
import StarIcon from '@material-ui/icons/Star';
import TodayIcon from '@material-ui/icons/Today';
import { startOfDay, endOfDay } from 'date-fns';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { stringify } from 'query-string';

import { DateInputBonito } from '../../../Components';
import { api } from '../../../services';
import MobillsInput from './DateInputMobills';
import MatrizChips from './MatrizChips';

const CardTotal = ({ label, value, onClick }) => (
  <Card
    style={{ borderRadius: '20px', cursor: 'pointer', flex: 1 }}
    onClick={onClick}
  >
    <CardContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <div>
          <div
            style={{
              fontSize: '17px',
              color: '#a1c3d1',
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {label}
          </div>
          <div style={{ fontSize: '20px', color: 'black', fontWeight: 'bold' }}>
            {value}
          </div>
        </div>
      </div>
    </CardContent>
  </Card>
);

const CardTotal2 = ({ label, valor, dica, action, handleAction }) => (
  <Card
    style={{ borderRadius: '20px', cursor: 'pointer', flex: 1 }}
    onClick={() => handleAction(action)}
  >
    <CardContent>
      {dica ? (
        <Tooltip title={dica} TransitionComponent={Zoom}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              height: '100%',
              alignItems: 'center',
            }}
          >
            <div>
              <div
                style={{
                  fontSize: '17px',
                  color: '#a1c3d1',
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {label}
              </div>
              <div
                style={{ fontSize: '20px', color: 'black', fontWeight: 'bold' }}
              >
                {valor}
              </div>
            </div>
          </div>
        </Tooltip>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <div>
            <div
              style={{
                fontSize: '17px',
                color: '#a1c3d1',
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {label}
            </div>
            <div
              style={{ fontSize: '20px', color: 'black', fontWeight: 'bold' }}
            >
              {valor}
            </div>
          </div>
        </div>
      )}
    </CardContent>
  </Card>
);

function formatMoeda(valor) {
  return valor.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
}

function formatPorcentagem(valor, casasDecimais = 2) {
  return valor !== null && valor !== undefined
    ? `${valor.toLocaleString('de-DE', {
        minimumFractionDigits: casasDecimais,
        maximumFractionDigits: casasDecimais,
      })}%`
    : '';
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: 12,
    padding: '5px 5px',
  },
  body: {
    fontSize: 12,
    padding: '5px 5px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
      padding: '10px',
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    // minWidth: 700,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  popper: {
    maxWidth: 500,
  },
}));

const data = [
  {
    dia: 1,
    mes: 1,
    ano: 2022,
    dia_semana: 0,
    dia_do_mes_label: 'segunda-feira, 26 de abril de 2022',
    total_despesas: 10,
    receitas_esperadas: 20,
    provisao_caixa_futuro: 12,
    provisao_caixa_futuro_acumulado: 30,
    porcentagem: 30,
    isFuturo: false,
    total_despesas_nao_pagas: 0,
  },
];

const colors = [
  {
    limit: 70,
    style: {
      backgroundColor: '#28FA6E',
      color: 'black',
    },
    label: 'razão ≤ 70%',
  },
  {
    limit: 100,
    style: {
      backgroundColor: '#FAEF75',
      color: 'black',
    },
    label: '70% < razão ≤ 100%',
  },
  {
    limit: 100,
    style: {
      backgroundColor: '#D66752',
      color: 'black',
    },
    label: 'razão > 100%',
  },
];

function getColor(valor) {
  for (let i = 0; i < colors.length - 1; i++) {
    const { style, limit } = colors[i];
    if (valor <= limit) return style;
  }
  return colors[colors.length - 1].style;
}

function Dica() {
  return (
    <div
      style={
        {
          /* display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start', */
        }
      }
    >
      <div
        style={{
          textAlign: 'center',
          marginBottom: '10px',
          fontSize: '15px',
          padding: '10px',
          width: '500px',
        }}
      >
        Despesas/Receitas (%):
      </div>
      <MatrizChips colors={colors} />
    </div>
  );
}

const diaAtual = new Date().getDate();

function dataOrder(dia, mes, ano) {
  const diaAtual = new Date().getDate();
  const mesAtual = new Date().getMonth();
  const anoAtual = new Date().getFullYear();

  /* if (ano > anoAtual) {
    return true;
  }
  if (ano < anoAtual) {
    return false;
  }
  if (mes > mesAtual) {
    return true;
  }
  if (mes < mesAtual) {
    return false;
  }
  if (dia >= diaAtual) {
    return true;
  }
  return false; */

  return true;
}

function getSigla(mes) {
  switch (mes) {
    case 0:
      return 'Jan';
    case 1:
      return 'Fev';
    case 2:
      return 'Mar';
    case 3:
      return 'Abr';
    case 4:
      return 'Mai';
    case 5:
      return 'Jun';
    case 6:
      return 'Jul';
    case 7:
      return 'Ago';
    case 8:
      return 'Set';
    case 9:
      return 'Out';
    case 10:
      return 'Nov';
    case 11:
      return 'Dez';
    default:
      return '';
  }
}

function DicaDetalhes({ item }) {
  return (
    <Box>
      <div style={{ fontSize: '14px', marginBottom: '10px' }}>
        {item.dia_do_mes_label}
      </div>
      <div style={{ marginBottom: '5px' }}>
        <span style={{ marginRight: '5px', fontWeight: 'bold' }}>
          Total Despesas Não Pagas:
        </span>
        <span>{formatMoeda(item.total_despesas_nao_pagas)}</span>
      </div>
      {!item.isFuturo && (
        <div style={{ marginBottom: '5px' }}>
          <span style={{ marginRight: '5px', fontWeight: 'bold' }}>
            Total Despesas Pagas:
          </span>
          <span>{formatMoeda(item.total_despesas)}</span>
        </div>
      )}
      <div style={{ marginBottom: '5px' }}>
        <span style={{ marginRight: '5px', fontWeight: 'bold' }}>
          Receitas Estimadas:
        </span>
        <span>{formatMoeda(item.receitas_esperadas)}</span>
      </div>
      <div style={{ marginBottom: '5px' }}>
        <span style={{ marginRight: '5px', fontWeight: 'bold' }}>
          Provisão de Caixa Futuro:
        </span>
        <span>{formatMoeda(item.provisao_caixa_futuro)}</span>
      </div>
      {item.isFuturo && (
        <div style={{ marginBottom: '5px' }}>
          <span style={{ marginRight: '5px', fontWeight: 'bold' }}>
            Provisão de Caixa Futuro Acumulado:
          </span>
          <span>{formatMoeda(item.provisao_caixa_futuro_acumulado)}</span>
        </div>
      )}
    </Box>
  );
}

function getComZero(value) {
  return value < 10 ? `0${value}` : `${value}`;
}

function makeDiaCalendario(dia, despesas) {
  const style = dia ? getColor(dia.porcentagem) : {};
  return (
    <>
      {dia ? (
        dataOrder(dia.dia, dia.mes, dia.ano) ? (
          <Tooltip
            title={<DicaDetalhes item={dia} />}
            TransitionComponent={Zoom}
            classes={
              {
                // tooltip: classes.popper,
              }
            }
          >
            <td
              style={{
                width: 'calc(100%/7)',
                aspectRatio: '1/1',

                borderCollapse: 'collapse',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                flexDirection: 'column',
                ...style,
                border: ` ${
                  dia.dia === 1 ? '5px solid white' : '1px solid black'
                }`,
              }}
              onClick={() => {
                despesas(dia);
              }}
              /* onClick={() => {
              }} */
            >
              <div
                style={{
                  display: 'flex',
                  gap: '5px',
                }}
              >
                <div style={{ fontSize: '25px' }}>{`${getComZero(
                  dia.dia,
                )}/${getComZero(dia.mes + 1)}`}</div>
                {dia.label_feriado && (
                  <Tooltip title={dia.label_feriado} placement="top-end">
                    <StarIcon style={{ color: 'yellow' }} />
                  </Tooltip>
                )}
              </div>
              <div style={{ fontSize: '12px', marginTop: '15px' }}>
                {formatMoeda(dia.provisao_caixa_futuro)}
              </div>
            </td>
          </Tooltip>
        ) : (
          <td
            style={{
              width: 'calc(100%/7)',
              aspectRatio: '1/1',
              border: '1px solid black',
              borderCollapse: 'collapse',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div style={{ fontSize: '25px' }}>{dia.dia}</div>
            <div style={{ fontSize: '12px', marginTop: '15px' }}>-</div>
          </td>
        )
      ) : (
        <td
          style={{
            width: 'calc(100%/7)',
            aspectRatio: '1/1',
            border: '1px solid black',
            borderCollapse: 'collapse',
            display: 'flex',
            fontSize: '20px',
            padding: '10px',
          }}
        />
      )}
    </>
  );
}

function makeLabelsCalendario() {
  const dias = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];

  return dias.map((item) => (
    <td
      style={{
        width: 'calc(100%/7)',
        aspectRatio: '2/1',
        border: '1px solid black',
        borderCollapse: 'collapse',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: 'crimson',
        color: 'WHITE',
      }}
    >
      <div style={{ fontSize: '25px' }}>{item}</div>
    </td>
  ));
}

function preencheDiasMesAnterior(dia) {
  const espacos = [];
  for (let i = 0; i < dia; i++) {
    espacos.push(makeDiaCalendario(null));
  }
  return espacos;
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const DashbboardContabilidade = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState({
    linhas: [],
    kpis: [],
    // totalDespesasTotal: 0,
    // totalReceitasTotal: 0,
    // totalDespesasNaoPagas: 0,
    // provisaoCaixaFuturoTotal: 0,
    // kpiFaturamentoDiario: 0,
    // totalDespesasProblematicas: 0,
    // nDespesasProblematicas: 0,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [cenario, setCenario] = useState(2);
  const [diasFrente, setDiaFrente] = useState(30);
  const [diasAtras, setDiasAtras] = useState(0);
  const [dataPagamento, setDataPagamento] = useState(new Date());
  // const [totalDespesasProblematicas, setTotalDespesasProblematicas] = useState(0);
  // const [nDespesasProblematicas, setNDespesasProblematicas] = useState(0);
  const version = useVersion();
  const history = useHistory();

  const [modeList, setModeList] = useState(true);

  const despesas = (data) => {
    const dataMes = new Date();
    dataMes.setFullYear(data.ano);
    dataMes.setDate(data.dia);
    dataMes.setMonth(data.mes);
    dataMes.setDate(data.dia);

    const queryOj = {};

    queryOj.displayedFilters = JSON.stringify({});
    queryOj.filter = JSON.stringify({
      tipo: 'Despesa',
      periodo: {
        start: startOfDay(dataMes),
        end: endOfDay(dataMes),
      },
      showPrazos: true,
    });
    history.push(
      `/transacoes?${stringify(
        queryOj,
      )}&order=ASC&page=1&perPage=100&sort=valor`,
    );
  };

  const despesasPeriodoTodo = (data) => {
    const dataInicio = new Date(moment(dataPagamento).startOf('day').format());
    const dataFinal = new Date(
      moment(dataPagamento).add(diasFrente, 'days').endOf('day').format(),
    );

    const queryOj = {};

    queryOj.displayedFilters = JSON.stringify({});
    queryOj.filter = JSON.stringify({
      tipo: 'Despesa',
      periodo: {
        start: dataInicio,
        end: dataFinal,
      },
      showPrazos: true,
    });
    history.push(
      `/transacoes?${stringify(
        queryOj,
      )}&order=DESC&page=1&perPage=100&sort=data_pagamento`,
    );
  };

  const despesasAtrasadas = (data) => {
    const dataInicioDespesasNaoPagas = new Date(
      moment(new Date().setFullYear(2000)).startOf('year').format(),
    );

    // const dataInicioDespesasNaoPagas = new Date(
    //   moment().subtract(365, 'days').startOf('day').format(),
    // );

    const dataFinalDespesasNaoPagas = new Date(
      moment().subtract(1, 'days').endOf('day').format(),
    );

    const queryOj = {};

    queryOj.displayedFilters = JSON.stringify({});
    queryOj.filter = JSON.stringify({
      tipo: 'Despesa',
      periodo: {
        start: dataInicioDespesasNaoPagas,
        end: dataFinalDespesasNaoPagas,
        original: true,
      },
      showPrazos: true,
      situacoes: [2],
    });
    history.push(
      `/transacoes?${stringify(
        queryOj,
      )}&order=DESC&page=1&perPage=100&sort=data_pagamento`,
    );
  };

  const despesasProblematicas = () => {
    const dataStart = new Date(
      moment(new Date().setFullYear(2000)).startOf('year').format(),
    );

    const dataEnd = new Date(
      moment(new Date().setFullYear(3000)).endOf('year').format(),
    );

    const queryOj = {};

    queryOj.displayedFilters = JSON.stringify({});
    queryOj.filter = JSON.stringify({
      tipo: 'Despesa',
      periodo: {
        start: dataStart,
        end: dataEnd,
      },
      situacoes: [2],
      categorias_problemas: [0],
    });
    history.push(
      `/transacoes?${stringify(
        queryOj,
      )}&order=DESC&page=1&perPage=100&sort=data_pagamento`,
    );
  };

  async function handleData() {
    setLoading(true);
    try {
      const dados = await api.get(
        `/relatorio/futurologia/?acumulado=${dataPagamento}&cenario=${cenario}&dias=${diasFrente}&dias_atras=${diasAtras}`,
      );
      setDados({
        linhas: dados.data.dados,
        kpis: dados.data.kpis,
        // totalDespesasTotal: dados.data.totalDespesasTotal,
        // totalReceitasTotal: dados.data.totalReceitasTotal,
        // totalDespesasNaoPagas: dados.data.totalDespesasNaoPagas,
        // provisaoCaixaFuturoTotal: dados.data.provisaoCaixaFuturoTotal,
        // kpiFaturamentoDiario: dados.data.kpiFaturamentoDiario,
        // totalDespesasProblematicas: dados.data.totalDespesasProblematicas,
        // nDespesasProblematicas: dados.data.nDespesasProblematicas,
      });
      setLoading(false);
    } catch (e) {
      setDados({
        linhas: [],
        kpis: [],
        // totalDespesasTotal: 0,
        // totalReceitasTotal: 0,
        // totalDespesasNaoPagas: 0,
        // provisaoCaixaFuturoTotal: 0,
        // kpiFaturamentoDiario: 0,
        // totalDespesasProblematicas: 0,
        // nDespesasProblematicas: 0,
      });
      setLoading(false);
      enqueueSnackbar(`Erro ao carregar os dados do relatório!`, {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    handleData();
  }, [cenario, dataPagamento, version, diasFrente, diasAtras]);

  function handleAction(action) {
    switch (action) {
      case 1:
        despesasAtrasadas();
        break;
      case 2:
        despesasPeriodoTodo();
        break;
      case 3:
        despesasProblematicas();
        break;
      default:
        break;
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            fontSize: '25px',
            fontWeight: 'bold',
            marginTop: '35px',
            marginBottom: '30px',
          }}
        >
          Relatório de gestão de fluxo de caixa
        </div>
        <Tooltip
          title={<Dica />}
          TransitionComponent={Zoom}
          classes={{
            tooltip: classes.popper,
          }}
        >
          <IconButton aria-label="help">
            <HelpIcon />
          </IconButton>
        </Tooltip>
      </div>

      <div
        style={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ButtonGroup
          color="primary"
          aria-label="outlined primary button group"
          variant="contained"
        >
          <Button
            style={
              !modeList ? { backgroundColor: 'white', color: 'black' } : {}
            }
            onClick={() => setModeList(true)}
          >
            <ListIcon />
          </Button>
          <Button
            style={modeList ? { backgroundColor: 'white', color: 'black' } : {}}
            onClick={() => setModeList(false)}
          >
            <EventAvailableIcon />
          </Button>
        </ButtonGroup>
        <DateInputBonito
          showTodayButton
          todayLabel="Hoje"
          name="data_pagamento"
          handleEnter={() => {}}
          label="Acumular a partir de:"
          handleKey={() => {}}
          value={dataPagamento}
          onChange={(value) => setDataPagamento(value)}
          format="dd MMMM yyyy"
          style={{ width: '200px' }}
          // fullWidth
          disablePast
          inputVariant="outlined"
          InputProps={{
            startAdornment: (
              <>
                <TodayIcon style={{ marginRight: '24px' }} />
              </>
            ),
          }}
        />
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: '200px' }}
          // fullWidth
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Dias atrás
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={diasAtras}
            onChange={(e) => setDiasAtras(e.target.value)}
            label="Cenário"
            color="primary"
            // fullWidth
          >
            <MenuItem value={0}>0 dias</MenuItem>
            <MenuItem value={7}>7 dias</MenuItem>
            <MenuItem value={30}>30 dias</MenuItem>
            <MenuItem value={60}>60 dias</MenuItem>
            <MenuItem value={90}>90 dias</MenuItem>
            <MenuItem value={120}>120 dias</MenuItem>
            <MenuItem value={365}>365 dias</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: '200px' }}
          // fullWidth
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Dias a frente
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={diasFrente}
            onChange={(e) => setDiaFrente(e.target.value)}
            label="Cenário"
            color="primary"
            // fullWidth
          >
            <MenuItem value={7}>7 dias</MenuItem>
            <MenuItem value={30}>30 dias</MenuItem>
            <MenuItem value={60}>60 dias</MenuItem>
            <MenuItem value={90}>90 dias</MenuItem>
            <MenuItem value={120}>120 dias</MenuItem>
            <MenuItem value={365}>365 dias</MenuItem>
          </Select>
        </FormControl>
        <div>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: '200px' }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Cenário
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={cenario}
              onChange={(e) => setCenario(e.target.value)}
              label="Cenário"
              color="primary"
            >
              <MenuItem value={5}>Muito Otimista (+20%)</MenuItem>
              <MenuItem value={1}>Otimista (+10%)</MenuItem>
              <MenuItem value={2}>Realista</MenuItem>
              <MenuItem value={3}>Pessimista (-10%)</MenuItem>
              <MenuItem value={4}>Muito Pessimista (-20%)</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <Box
        display="flex"
        style={{ gap: '10px', marginBottom: '20px', marginTop: '10px' }}
      >
        {dados.kpis.map(({ label, dica, valor, action }) => (
          <CardTotal2
            label={label}
            dica={dica}
            valor={valor}
            action={action}
            handleAction={handleAction}
          />
        ))}
      </Box>
      {/*<Box
        display="flex"
        style={{ gap: '10px', marginBottom: '20px', marginTop: '10px' }}
      >
        <CardTotal
          label="Despesas Atrasadas Totais"
          value={formatMoeda(dados.totalDespesasNaoPagas)}
          onClick={despesasAtrasadas}
        />
        <CardTotal
          label="Despesas Futuras Totais"
          value={formatMoeda(dados.totalDespesasTotal)}
          onClick={despesasPeriodoTodo}
        />
        <CardTotal
          label="Receitas Futuras Totais"
          value={formatMoeda(dados.totalReceitasTotal)}
          onClick={() => {}}
        />
        <CardTotal
          label="Provisão Caixa Futuro Total"
          value={formatMoeda(dados.provisaoCaixaFuturoTotal)}
          onClick={() => {}}
        />
      </Box>
      <Box
        display="flex"
        style={{ gap: '10px', marginBottom: '20px', marginTop: '10px' }}
      >
        <CardTotal
          label="KPI Faturamento Diário"
          value={formatMoeda(dados.kpiFaturamentoDiario)}
          onClick={() => {}}
        />
        <CardTotal
          label="Total Despesas Problemáticas"
          value={formatMoeda(dados.totalDespesasProblematicas)}
          onClick={() => despesasProblematicas()}
        />
        <CardTotal
          label="N Despesas Problemáticas"
          value={dados.nDespesasProblematicas}
          onClick={() => despesasProblematicas()}
        />
        </Box>*/}
      {modeList ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Dia do mês</StyledTableCell>
                {/*<StyledTableCell>Total Despesas Pagas</StyledTableCell>*/}
                <StyledTableCell align="right">
                  Despesas Não Pagas
                </StyledTableCell>
                <StyledTableCell align="right">
                  Receitas Esperadas
                </StyledTableCell>
                <StyledTableCell align="right">Fluxo de Caixa</StyledTableCell>
                <StyledTableCell align="right">
                  Fluxo de Caixa Acumulado
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dados.linhas
                .filter((item) => dataOrder(item.dia, item.mes, item.ano))
                .map((row) => {
                  const style = getColor(row.porcentagem);
                  return (
                    <StyledTableRow
                      key={row.dia_do_mes_label}
                      style={{
                        ...style,
                      }}
                      onClick={() => despesas(row)}
                    >
                      <StyledTableCell
                        style={{
                          ...style,
                        }}
                      >
                        {row.dia_do_mes_label}
                        {row.label_feriado && (
                          <Tooltip
                            title={row.label_feriado}
                            placement="top-end"
                          >
                            <StarIcon style={{ color: 'yellow' }} />
                          </Tooltip>
                        )}
                      </StyledTableCell>
                      {/*<StyledTableCell
                        style={{
                          ...style,
                        }}
                        align="right"
                      >
                        {row.isFuturo ? '-' : formatMoeda(row.total_despesas)}
                      </StyledTableCell>*/}
                      <StyledTableCell
                        style={{
                          ...style,
                        }}
                        align="right"
                      >
                        {formatMoeda(row.total_despesas_nao_pagas)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          ...style,
                        }}
                        align="right"
                      >
                        {formatMoeda(row.receitas_esperadas)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          ...style,
                        }}
                        align="right"
                      >
                        {formatMoeda(row.provisao_caixa_futuro)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          ...style,
                        }}
                        align="right"
                      >
                        {!row.isFuturo
                          ? '-'
                          : formatMoeda(row.provisao_caixa_futuro_acumulado)}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box display="flex">
          <Box boxShadow={10} display="flex" justifyContent="center">
            <table
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                borderCollapse: 'collapse',
                width: '80%',
                border: '1px solid black',
              }}
            >
              <tr
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  width: '100%',
                  border: '1px solid black',
                }}
              >
                {makeLabelsCalendario()}
                {dados.linhas.length > 0 &&
                  preencheDiasMesAnterior(dados.linhas[0].dia_semana)}
                {dados.linhas.map((item) => makeDiaCalendario(item, despesas))}
              </tr>
            </table>
          </Box>
        </Box>
      )}
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DashbboardContabilidade;
