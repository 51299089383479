import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
} from 'react';

import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { getListAllAPI, getListAPI } from '../../../services';
import {
  formatCNPJ,
  formatCPF,
  SECONDARY_COLOR,
  formatMoeda,
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  formatPeso,
  POR_PESO_FIXO,
  formatPreco,
  getPrecoUnitarioVerdadeiroCaixa2,
  getEstoqueLabel,
} from '../../../utils';
import DialogoCreateAvaria from '../DialogoCreateAvaria';
import DialogoCreateCorrecao from '../DialogoCreateCorrecao';
import DialogoDeletarAvaria from '../DialogoDeletarAvaria';
import DialogoDeletarCorrecao from '../DialogoDeletarCorrecao';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
    cursor: 'pointer',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
    cursor: 'pointer',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const DEFAULT_ID = -1;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const perPageAvaria = 10;
const perPageCorrecao = 2;

const DialogoShowProduto = forwardRef(({ handleClose = () => {} }, ref) => {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [id, setId] = useState(DEFAULT_ID);
  const [produto, setProduto] = useState({
    nome: '',
    peso_medio: 0,
    codigo_interno: '',
    codigo: '',
    preco_venda: 0,
    preco_oferta: 0,
    unidadesDisponivel: 0,
    pesoDisponivel: 0,
    precoCompraMedio: 0,
    precoCompraMedioImpostos: 0,
    estoque_minimo: 0,
    estoque: '',
    unidade: {
      nome: '',
      tipo: '',
    },
    familia: {
      nome: '',
    },
    fornecedorPadrao: {
      nome: '',
      nome_fantasia: '',
      razao_social: '',
      logradouro: '',
      bairro: '',
      cidade: '',
      cnpj: '',
      cpf: '',
    },
    tecla: '',
  });
  const [produtoBase, setProdutoBase] = useState({
    nome: '',
    codigo_interno: '',
    codigo: '',
  });
  const [caixarias, setCaixarias] = useState({
    nivel_1: {
      codigo_caixa_nivel: '',
      unidades_caixa_nivel: 0,
      peso_caixa_nivel: 0,
      preco_venda_caixa_nivel: 0,
      preco_oferta_caixa_nivel: 0,
    },

    nivel_2: {
      codigo_caixa_nivel: '',
      unidades_caixa_nivel: 0,
      peso_caixa_nivel: 0,
      preco_venda_caixa_nivel: 0,
      preco_oferta_caixa_nivel: 0,
    },

    nivel_3: {
      codigo_caixa_nivel: '',
      unidades_caixa_nivel: 0,
      peso_caixa_nivel: 0,
      preco_venda_caixa_nivel: 0,
      preco_oferta_caixa_nivel: 0,
    },
  });
  const [locais, setLocais] = useState([]);
  const [categoria, setCategoria] = useState({
    nome: '',
  });
  const [fornecedores, setFornecedores] = useState([]);
  const [impostos, setImpostos] = useState({
    lbv: 0,
    lba: 0,
    ipi: 0,
    pis: 0,
    cofins: 0,
    icms_ai: 0,
    icms_int: 0,
    df: 0,
    cst: '',
    cfop: '',
    rbc: 0,
    ncm: '',
    cst_pis_entrada: '',
    cst_pis_saida: '',
    cst_cofins_entrada: '',
    cst_cofins_saida: '',
    cest: '',
  });
  const [balanca, setBalanca] = useState({
    d1: '',
    dias_validade: 0,
    exibir_dias_validade: false,
    exibir_data_embalagem: false,
    tara: {
      nome: '',
      valor: 0,
      codigo_interno: '',
    },
  });
  const [custos, setCustos] = useState([]);
  const [avarias, setAvarias] = useState([]);
  const [correcoes, setCorrecoes] = useState([]);

  const [carregando, setCarregando] = useState(false);
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(0);

  const [total, setTotal] = useState(0);

  const refDialogoCreateAvaria = useRef(null);
  const refDialogoCreateCorrecao = useRef(null);

  const refDialogoDeleteAvaria = useRef(null);
  const refDialogoDeleteCorrecao = useRef(null);

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  function formatDadosProduto(produto) {
    const dadosFormatados = {};

    dadosFormatados.nome = produto.nome;
    dadosFormatados.codigo = produto.codigo;

    dadosFormatados.preco_venda = formatMoeda(produto.preco_venda);

    dadosFormatados.preco_oferta = formatMoeda(
      getPrecoUnitarioVerdadeiroCaixa2({ ...produto, nivelCaixa: 0 }, 0, 0),
    );

    dadosFormatados.precoCompraMedio = formatMoeda(produto.precoCompraMedio);

    dadosFormatados.precoCompraMedioImpostos = formatMoeda(
      produto.precoCompraMedioImpostos,
    );

    dadosFormatados.tecla = produto.tecla ? produto.tecla : '-';

    dadosFormatados.estoque = getEstoqueLabel(produto);

    dadosFormatados.peso_medio =
      produto.unidade.tipo === POR_PESO_FIXO
        ? `${formatPeso(dadosFormatados.peso_medio)} Kg`
        : '-';

    dadosFormatados.estoque_minimo =
      produto.unidade.tipo === POR_UNIDADE ||
      produto.unidade.tipo === POR_PESO_FIXO
        ? parseInt(produto.estoque_minimo, 10)
        : formatPeso(produto.estoque_minimo);

    if (produto.familia) {
      dadosFormatados.familia = {
        nome: produto.familia.nome,
      };
    } else {
      dadosFormatados.familia = null;
    }

    if (produto.unidade) {
      dadosFormatados.unidade = {
        nome: produto.unidade.nome,
        tipo: produto.unidade.tipo,
      };
    } else {
      dadosFormatados.unidade = null;
    }

    if (produto.fornecedorPadrao) {
      dadosFormatados.fornecedorPadrao = {
        nome: produto.fornecedorPadrao.nome
          ? produto.fornecedorPadrao.nome
          : '-',
        nome_fantasia: produto.fornecedorPadrao.nome_fantasia
          ? produto.fornecedorPadrao.nome_fantasia
          : '-',
        razao_social: produto.fornecedorPadrao.razao_social
          ? produto.fornecedorPadrao.razao_social
          : '-',
        logradouro: produto.fornecedorPadrao.logradouro
          ? produto.fornecedorPadrao.logradouro
          : '-',
        bairro: produto.fornecedorPadrao.bairro
          ? produto.fornecedorPadrao.bairro
          : '-',
        cidade: produto.fornecedorPadrao.cidade
          ? `${produto.fornecedorPadrao.cidade.nome} - ${produto.fornecedorPadrao.cidade.estado.sigla}`
          : '-',
        cnpj: produto.fornecedorPadrao.cnpj
          ? formatCNPJ(produto.fornecedorPadrao.cnpj)
          : '-',
        cpf: produto.fornecedorPadrao.cpf
          ? formatCPF(produto.fornecedorPadrao.cpf)
          : '-',
      };
    } else {
      dadosFormatados.fornecedorPadrao = null;
    }

    dadosFormatados.unidades_caixa_nivel_1 = produto.unidades_caixa_nivel_1;
    dadosFormatados.peso_caixa_nivel_1 = produto.peso_caixa_nivel_1;
    dadosFormatados.unidades_caixa_nivel_2 = produto.unidades_caixa_nivel_2;
    dadosFormatados.unidades_caixa_nivel_3 = produto.unidades_caixa_nivel_3;

    return dadosFormatados;
  }

  function formatDadosProdutoBase(produtoBase) {
    let dadosFormatados = {};

    if (produtoBase) {
      dadosFormatados = {
        nome: produtoBase.nome,
        codigo_interno: produtoBase.codigo_interno,
        codigo: produtoBase.codigo,
      };
    } else {
      dadosFormatados = null;
    }

    return dadosFormatados;
  }

  function formatCaixarias(produto) {
    const dadosFormatados = {};

    if (produto.unidades_caixa_nivel_1 > 0 || produto.peso_caixa_nivel_1 > 0) {
      dadosFormatados.nivel_1 = {
        codigo_caixa_nivel: produto.codigo_caixa_nivel_1
          ? produto.codigo_caixa_nivel_1
          : '-',
        unidades_caixa_nivel:
          produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
            ? `${formatPeso(produto.peso_caixa_nivel_1)} Kg`
            : `${produto.unidades_caixa_nivel_1}`,
        peso_caixa_nivel: produto.peso_caixa_nivel_1,
        preco_venda_caixa_nivel: formatMoeda(
          getPrecoUnitarioVerdadeiroCaixa2(
            { ...produto, ofertasProdutos: [], nivelCaixa: 1 },
            0,
            0,
          ),
        ),
        preco_oferta_caixa_nivel: formatMoeda(
          getPrecoUnitarioVerdadeiroCaixa2({ ...produto, nivelCaixa: 1 }, 0, 0),
        ),
      };
    } else {
      dadosFormatados.nivel_1 = null;
    }

    if (produto.unidades_caixa_nivel_2 > 0) {
      dadosFormatados.nivel_2 = {
        codigo_caixa_nivel: produto.codigo_caixa_nivel_2
          ? produto.codigo_caixa_nivel_2
          : '-',
        unidades_caixa_nivel: produto.unidades_caixa_nivel_2,
        peso_caixa_nivel: produto.peso_caixa_nivel_2,
        preco_venda_caixa_nivel: formatMoeda(
          getPrecoUnitarioVerdadeiroCaixa2(
            { ...produto, ofertasProdutos: [], nivelCaixa: 2 },
            0,
            0,
          ),
        ),
        preco_oferta_caixa_nivel: formatMoeda(
          getPrecoUnitarioVerdadeiroCaixa2({ ...produto, nivelCaixa: 2 }, 0, 0),
        ),
      };
    } else {
      dadosFormatados.nivel_2 = null;
    }

    if (produto.unidades_caixa_nivel_3 > 0) {
      dadosFormatados.nivel_3 = {
        codigo_caixa_nivel: produto.codigo_caixa_nivel_3
          ? produto.codigo_caixa_nivel_3
          : '-',
        unidades_caixa_nivel: produto.unidades_caixa_nivel_3,
        peso_caixa_nivel: produto.peso_caixa_nivel_3,
        preco_venda_caixa_nivel: formatMoeda(
          getPrecoUnitarioVerdadeiroCaixa2(
            { ...produto, ofertasProdutos: [], nivelCaixa: 3 },
            0,
            0,
          ),
        ),
        preco_oferta_caixa_nivel: formatMoeda(
          getPrecoUnitarioVerdadeiroCaixa2({ ...produto, nivelCaixa: 3 }, 0, 0),
        ),
      };
    } else {
      dadosFormatados.nivel_3 = null;
    }

    return dadosFormatados;
  }

  function formatLocais(locais) {
    const dadosFormatados = [];

    if (locais) {
      if (locais.length > 0) {
        for (let i = 0; i < locais.length; i++) {
          dadosFormatados.push({
            descricao: locais[i].nome,
            area: locais[i].area ? `${locais[i].area} m²` : '-',
            setor: locais[i].setor.nome,
          });
        }
      }
    }

    return dadosFormatados;
  }

  function formatCategoria(categoria) {
    let dadosFormatados = {};

    if (categoria) {
      dadosFormatados = {
        nome: categoria.nome,
      };
    } else {
      categoria = null;
    }

    return dadosFormatados;
  }

  function formatImpostos(dadosImpostos) {
    let dadosFormatados = {};

    if (dadosImpostos) {
      if (dadosImpostos.length > 0) {
        const dados = dadosImpostos[0];
        dadosFormatados = {
          lbv: dados.lbv,
          lba: dados.lba,
          ipi: `${formatPreco(dados.ipi)} %`,
          pis: `${formatPreco(dados.pis)} %`,
          cofins: `${formatPreco(dados.cofins)} %`,
          icms_ai: `${formatPreco(dados.icms_ai)} %`,
          icms_int: `${formatPreco(dados.icms_int)} %`,
          df: `${formatPreco(dados.df)} %`,
          cst: dados.cst || '-',
          cfop: dados.cfop || '-',
          rbc: `${formatPreco(dados.rbc)} %`,
          ncm: dados.ncm || '-',
          cst_pis_entrada: dados.cst_pis_entrada || '-',
          cst_pis_saida: dados.cst_pis_saida || '-',
          cst_cofins_entrada: dados.cst_cofins_entrada || '-',
          cst_cofins_saida: dados.cst_cofins_saida || '-',
          cest: dados.cest || '-',
        };
      } else {
        dadosFormatados = null;
      }
    } else {
      dadosFormatados = null;
    }

    return dadosFormatados;
  }

  function formatBalanca(dadosBalanca) {
    let dadosFormatados = {};

    if (dadosBalanca) {
      if (dadosBalanca.length > 0) {
        const dados = dadosBalanca[0];
        dadosFormatados = {
          d1: dados.d1,
          dias_validade: dados.dias_validade,
          exibir_dias_validade: dados.exibir_dias_validade ? 'Sim' : 'Não',
          exibir_data_embalagem: dados.exibir_data_embalagem ? 'Sim' : 'Não',
          tara: {
            nome: dados.taraobj ? dados.taraobj.nome : '',
            valor: dados.taraobj ? `${dados.taraobj.valor}g` : '',
            codigo_interno: dados.taraobj ? dados.taraobj.codigo_interno : '',
          },
        };
      } else {
        dadosFormatados = null;
      }
    } else {
      dadosFormatados = null;
    }

    return dadosFormatados;
  }

  function formatCustos(custos) {
    const dadosFormatados = [];

    if (custos) {
      if (custos.length > 0) {
        for (let i = 0; i < custos.length; i++) {
          dadosFormatados.push({
            id: custos[i].id,
            nome: custos[i].nome,
            preco: formatMoeda(custos[i].preco),
            unidades: custos[i].unidades,
            peso: custos[i].peso,
            total: formatMoeda(custos[i].preco * custos[i].peso),
          });
        }
      }
    }

    return dadosFormatados;
  }

  function formatAvarias(avarias, produto) {
    const dadosFormatados = [];

    if (avarias) {
      if (avarias.length > 0) {
        for (let i = 0; i < avarias.length; i++) {
          dadosFormatados.push({
            id: avarias[i].id,
            data: moment(new Date(avarias[i].created_at)).format(
              'DD/MM/YYYY - HH:mm',
            ),
            unidades: avarias[i].unidades,
            peso: avarias[i].peso,
            user: avarias[i].user ? avarias[i].user.username : '-',
            quantidade: getEstoqueLabel(
              produto,
              avarias[i].unidades,
              avarias[i].peso,
            ),
          });
        }
      }
    }

    return dadosFormatados;
  }

  function formatCorrecoes(correcoes, produto) {
    const dadosFormatados = [];

    if (correcoes) {
      if (correcoes.length > 0) {
        for (let i = 0; i < correcoes.length; i++) {
          dadosFormatados.push({
            id: correcoes[i].id,
            data: moment(new Date(correcoes[i].created_at)).format(
              'DD/MM/YYYY - HH:mm',
            ),
            user: correcoes[i].user ? correcoes[i].user.username : '-',
            unidades: correcoes[i].unidades,
            peso: correcoes[i].peso,
            quantidade: getEstoqueLabel(
              produto,
              correcoes[i].unidades,
              correcoes[i].peso,
            ),
            unidades_antes: correcoes[i].unidades_antes,
            peso_antes: correcoes[i].peso_antes,
            quantidade_antes: correcoes[i].usar_sistema_novo
              ? getEstoqueLabel(
                  produto,
                  correcoes[i].unidades_antes,
                  correcoes[i].peso_antes,
                )
              : '-',
            unidades_depois: correcoes[i].unidades_depois,
            peso_depois: correcoes[i].peso_depois,
            quantidade_depois: correcoes[i].usar_sistema_novo
              ? getEstoqueLabel(
                  produto,
                  correcoes[i].unidades_depois,
                  correcoes[i].peso_depois,
                )
              : '-',
            usar_sistema_novo: correcoes[i].usar_sistema_novo,
          });
        }
      }
    }

    return dadosFormatados;
  }

  function formatFornecedores(fornecedores) {
    const dadosFormatados = [];

    if (fornecedores) {
      if (fornecedores.length > 0) {
        for (let i = 0; i < fornecedores.length; i++) {
          const fornecedor = fornecedores[i];
          dadosFormatados.push({
            nome: fornecedor.nome ? fornecedor.nome : '-',
            nome_fantasia: fornecedor.nome_fantasia
              ? fornecedor.nome_fantasia
              : '-',
            razao_social: fornecedor.razao_social
              ? fornecedor.razao_social
              : '-',
            logradouro: fornecedor.logradouro ? fornecedor.logradouro : '-',
            bairro: fornecedor.bairro ? fornecedor.bairro : '-',
            cidade: fornecedor.cidade
              ? `${fornecedor.cidade.nome} - ${fornecedor.cidade.estado.sigla}`
              : '-',
            cnpj: fornecedor.cnpj ? formatCNPJ(fornecedor.cnpj) : '-',
            cpf: fornecedor.cpf ? formatCPF(fornecedor.cpf) : '-',
          });
        }
      }
    }

    return dadosFormatados;
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'produtos',
        ['id', 'asc'],
        { id: [id], ofertasProdutos: true },
        [
          'custos',
          'locais.setor',
          'locais.colaboradores',
          'fornecedores.cidade.estado',
          'categoria.setor',
          'unidade',
          'familia',
          'dadosBalanca.taraobj',
          'fornecedorPadrao',
          'produtoBase',
          'dadosImposto',
        ],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setProduto(formatDadosProduto(data.data[0]));
        setProdutoBase(formatDadosProdutoBase(data.data[0].produtoBase));
        setCaixarias(formatCaixarias(data.data[0]));
        setLocais(formatLocais(data.data[0].locais));
        setCategoria(formatCategoria(data.data[0].categoria));
        setImpostos(formatImpostos(data.data[0].dadosImposto));
        setBalanca(formatBalanca(data.data[0].dadosBalanca));
        setCustos(formatCustos(data.data[0].custos));
        setFornecedores(formatFornecedores(data.data[0].fornecedores));
        setValue(0);
        setPage(0);
        setCarregando(false);
      } else {
        throw 'Este produto não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setOpen(true);
      setCarregando(true);
      getDataResource(id);
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function getAvarias(page) {
    const response = await getListAPI(
      'avarias',
      ['created_at', 'desc'],
      [page + 1, perPageAvaria],
      { produto_id: id },
      ['user'],
    );
    setTotal(response.total);
    setAvarias(formatAvarias(response.data, produto));
  }

  async function getCorrecoes(page) {
    const response = await getListAPI(
      'correcoes_estoque',
      ['created_at', 'desc'],
      [page + 1, perPageCorrecao],
      { produto_id: id },
      ['user'],
    );
    setTotal(response.total);
    setCorrecoes(formatCorrecoes(response.data, produto));
  }

  useEffect(() => {
    switch (value) {
      case 7:
        getAvarias(page);
        setPage(0);
        break;
      case 8:
        getCorrecoes(page);
        setPage(0);
        break;
    }
  }, [value]);

  useEffect(() => {
    switch (value) {
      case 7:
        getAvarias(page);
        break;
      case 8:
        getCorrecoes(page);
        break;
    }
  }, [page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function handleCreateAvaria() {
    if (refDialogoCreateAvaria.current) {
      refDialogoCreateAvaria.current.handleOpen(id);
    }
  }

  function handleCreateCorrecao() {
    if (refDialogoCreateCorrecao.current) {
      refDialogoCreateCorrecao.current.handleOpen(id);
    }
  }

  function handleDeletarAvaria(id) {
    if (refDialogoDeleteAvaria.current) {
      refDialogoDeleteAvaria.current.handleOpen(id);
    }
  }

  function handleDeletarCorrecao(id) {
    if (refDialogoDeleteCorrecao.current) {
      refDialogoDeleteCorrecao.current.handleOpen(id);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
    >
      <DialogTitle id="form-dialog-title">{`Produto ${produto.nome}`}</DialogTitle>
      <DialogContent dividers>
        <div style={{ height: '100%' }}>
          <Box
            display={{ xs: 'block', sm: 'flex' }}
            flexDirection="column"
            marginBottom="30px"
            height="100%"
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Box p="1em">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label="Geral" {...a11yProps(0)} />
                  <Tab label="Caixarias" {...a11yProps(1)} />
                  <Tab label="Locais" {...a11yProps(2)} />
                  <Tab label="Fornecedores" {...a11yProps(3)} />
                  <Tab label="Impostos" {...a11yProps(4)} />
                  <Tab label="Balança" {...a11yProps(5)} />
                  <Tab label="Custos" {...a11yProps(6)} />
                  <Tab label="Avarias" {...a11yProps(7)} />
                  <Tab label="Correções" {...a11yProps(8)} />
                </Tabs>
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <TabPanel value={value} index={0}>
                      <Box>
                        <Box mt="1em" />
                        <Divider />
                        <Box mt="1em" />
                        <Typography
                          variant="h5"
                          gutterBottom
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          Geral
                        </Typography>
                        <Box
                          display="flex"
                          alignItems="center"
                          mt="20px"
                          fontSize={20}
                        >
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Descrição:
                            </span>
                            {produto.nome}
                          </Box>
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Código:
                            </span>
                            {produto.codigo}
                          </Box>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          mt="20px"
                          fontSize={20}
                        >
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Preço de Venda:
                            </span>
                            {produto.preco_venda}
                          </Box>
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Preço de Oferta:
                            </span>
                            {produto.preco_oferta}
                          </Box>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          mt="20px"
                          fontSize={20}
                        >
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Preço Médio de Compra:
                            </span>
                            {produto.precoCompraMedio}
                          </Box>
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Preço Médio de Compra Com Impostos:
                            </span>
                            {produto.precoCompraMedioImpostos}
                          </Box>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          mt="20px"
                          fontSize={20}
                        >
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Família:
                            </span>
                            {produto.familia ? produto.familia.nome : '-'}
                          </Box>
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Estoque:
                            </span>
                            {produto.estoque}
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          mt="20px"
                          fontSize={20}
                        >
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Peso Médio:
                            </span>
                            {produto.peso_medio}
                          </Box>
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Tecla:
                            </span>
                            {produto.tecla}
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          mt="20px"
                          fontSize={20}
                        >
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Categoria:
                            </span>
                            {categoria.nome}
                          </Box>
                          <Box flex={1}>
                            <span
                              style={{
                                marginRight: '10px',
                              }}
                            >
                              Estoque Mínimo:
                            </span>
                            {produto.estoque_minimo}
                          </Box>
                        </Box>
                      </Box>
                      {produtoBase && (
                        <Box>
                          <Box mt="1em" />
                          <Divider />
                          <Box mt="1em" />
                          <Typography
                            variant="h5"
                            gutterBottom
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Produto Base
                          </Typography>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Descrição:
                              </span>
                              {produtoBase.nome}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Código:
                              </span>
                              {produtoBase.codigo}
                            </Box>
                          </Box>
                        </Box>
                      )}
                      {produto.fornecedorPadrao && (
                        <Box>
                          <Box mt="1em" />
                          <Divider />
                          <Box mt="1em" />
                          <Typography
                            variant="h5"
                            gutterBottom
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Fornecedor Padrão
                          </Typography>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Responsável:
                              </span>
                              {produto.fornecedorPadrao.nome}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Nome Fantasia:
                              </span>
                              {produto.fornecedorPadrao.nome_fantasia}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Razão Social:
                              </span>
                              {produto.fornecedorPadrao.razao_social}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Cidade:
                              </span>
                              {produto.fornecedorPadrao.cidade}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                CNPJ:
                              </span>
                              {produto.fornecedorPadrao.cnpj}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                CPF:
                              </span>
                              {produto.fornecedorPadrao.cpf}
                            </Box>
                          </Box>
                        </Box>
                      )}
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      {caixarias.nivel_1 && (
                        <Box>
                          <Box mt="1em" />
                          <Divider />
                          <Box mt="1em" />
                          <Typography
                            variant="h5"
                            gutterBottom
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Caixa Nível 1
                          </Typography>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Código:
                              </span>
                              {caixarias.nivel_1.codigo_caixa_nivel}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Quantidade:
                              </span>
                              {caixarias.nivel_1.unidades_caixa_nivel}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Preço de Venda:
                              </span>
                              {caixarias.nivel_1.preco_venda_caixa_nivel}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Preço de Oferta:
                              </span>
                              {caixarias.nivel_1.preco_oferta_caixa_nivel}
                            </Box>
                          </Box>
                        </Box>
                      )}
                      {caixarias.nivel_2 && (
                        <Box>
                          <Box mt="1em" />
                          <Divider />
                          <Box mt="1em" />
                          <Typography
                            variant="h5"
                            gutterBottom
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Caixa Nível 2
                          </Typography>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Código:
                              </span>
                              {caixarias.nivel_2.codigo_caixa_nivel}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Quantidade:
                              </span>
                              {caixarias.nivel_2.unidades_caixa_nivel}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Preço de Venda:
                              </span>
                              {caixarias.nivel_2.preco_venda_caixa_nivel}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Preço de Oferta:
                              </span>
                              {caixarias.nivel_2.preco_oferta_caixa_nivel}
                            </Box>
                          </Box>
                        </Box>
                      )}
                      {caixarias.nivel_3 && (
                        <Box>
                          <Box mt="1em" />
                          <Divider />
                          <Box mt="1em" />
                          <Typography
                            variant="h5"
                            gutterBottom
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Caixa Nível 3
                          </Typography>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Código:
                              </span>
                              {caixarias.nivel_3.codigo_caixa_nivel}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Quantidade:
                              </span>
                              {caixarias.nivel_3.unidades_caixa_nivel}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Preço de Venda:
                              </span>
                              {caixarias.nivel_3.preco_venda_caixa_nivel}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Preço de Oferta:
                              </span>
                              {caixarias.nivel_3.preco_oferta_caixa_nivel}
                            </Box>
                          </Box>
                        </Box>
                      )}
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Descrição</StyledTableCell>
                              <StyledTableCell>Área</StyledTableCell>
                              <StyledTableCell>Setor</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {locais.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>
                                  {row.descricao}
                                </StyledTableCell>
                                <StyledTableCell>{row.area}</StyledTableCell>
                                <StyledTableCell>{row.setor}</StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Responsável</StyledTableCell>
                              <StyledTableCell>Nome Fantasia</StyledTableCell>
                              <StyledTableCell>Razão Social</StyledTableCell>
                              <StyledTableCell>CNPJ</StyledTableCell>
                              <StyledTableCell>CPF</StyledTableCell>
                              <StyledTableCell>Cidade</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {fornecedores.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.nome}</StyledTableCell>
                                <StyledTableCell>
                                  {row.nome_fantasia}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.razao_social}
                                </StyledTableCell>
                                <StyledTableCell>{row.cnpj}</StyledTableCell>
                                <StyledTableCell>{row.cpf}</StyledTableCell>
                                <StyledTableCell>{row.cidade}</StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                      {impostos && (
                        <>
                          <Box mt="1em" />
                          <Divider />
                          <Box mt="1em" />
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                IPI (%):
                              </span>
                              {impostos.ipi}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                PIS (%):
                              </span>
                              {impostos.pis}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                COFINS (%):
                              </span>
                              {impostos.cofins}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                ICMS AI (%):
                              </span>
                              {impostos.icms_ai}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                ICMS INT (%):
                              </span>
                              {impostos.icms_int}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                RBC (%):
                              </span>
                              {impostos.rbc}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                DF (%):
                              </span>
                              {impostos.df}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                NCM:
                              </span>
                              {impostos.ncm}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                CST ICMS ENTRADA E SAÍDA:
                              </span>
                              {impostos.cst}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                CFOP:
                              </span>
                              {impostos.cfop}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                CST PIS ENTRADA:
                              </span>
                              {impostos.cst_cofins_entrada}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                CST PIS SAÍDA:
                              </span>
                              {impostos.cst_pis_saida}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                CST COFINS ENTRADA:
                              </span>
                              {impostos.cst_cofins_entrada}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                CST COFINS SAÍDA:
                              </span>
                              {impostos.cst_cofins_saida}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                CEST:
                              </span>
                              {impostos.cst}
                            </Box>
                            <Box flex={1} />
                          </Box>
                          <Box mt="1em" />
                          <Divider />
                          <Box mt="1em" />
                        </>
                      )}
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                      {balanca && (
                        <>
                          <Box mt="1em" />
                          <Divider />
                          <Box mt="1em" />
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Descrição Reduzida:
                              </span>
                              {balanca.d1}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Dias de Validade:
                              </span>
                              {balanca.dias_validade}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Exibir dias de validade:
                              </span>
                              {balanca.exibir_dias_validade}
                            </Box>
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Exibir data de validade:
                              </span>
                              {balanca.exibir_data_embalagem}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            mt="20px"
                            fontSize={20}
                          >
                            <Box flex={1}>
                              <span
                                style={{
                                  marginRight: '10px',
                                }}
                              >
                                Tara:
                              </span>
                              {balanca.tara.valor}
                            </Box>
                            <Box flex={1} />
                          </Box>
                          <Box mt="1em" />
                          <Divider />
                          <Box mt="1em" />
                        </>
                      )}
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Descrição</StyledTableCell>
                              <StyledTableCell>Preço</StyledTableCell>
                              <StyledTableCell>Quantidade</StyledTableCell>
                              <StyledTableCell>Total</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {custos.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>
                                  {row.descricao}
                                </StyledTableCell>
                                <StyledTableCell>{row.preco}</StyledTableCell>
                                <StyledTableCell>{row.peso}</StyledTableCell>
                                <StyledTableCell>{row.total}</StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                    </TabPanel>
                    <TabPanel value={value} index={7}>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => handleCreateAvaria()}
                        >
                          Novo
                        </Button>
                      </div>
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Data</StyledTableCell>
                              <StyledTableCell>Usuário</StyledTableCell>
                              <StyledTableCell>Quantidade</StyledTableCell>
                              <StyledTableCell>Ações</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {avarias.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.data}</StyledTableCell>
                                <StyledTableCell>{row.user}</StyledTableCell>
                                <StyledTableCell>
                                  {row.quantidade}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <IconButton
                                    aria-label="excluir"
                                    size="small"
                                    onClick={() => handleDeletarAvaria(row.id)}
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <TablePagination
                          rowsPerPageOptions={[perPageAvaria]}
                          count={total}
                          rowsPerPage={perPageAvaria}
                          page={page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={() => {}}
                          labelRowsPerPage="Por página"
                          labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to} de ${
                              count !== -1 ? count : `more than ${to}`
                            }`
                          }
                        />
                      </TableContainer>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                    </TabPanel>
                    <TabPanel value={value} index={8}>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => handleCreateCorrecao()}
                        >
                          Novo
                        </Button>
                      </div>
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Data</StyledTableCell>
                              <StyledTableCell>Usuário</StyledTableCell>
                              <StyledTableCell>Qtde Antes</StyledTableCell>
                              <StyledTableCell>Qtde Depois</StyledTableCell>
                              <StyledTableCell>Correção</StyledTableCell>
                              <StyledTableCell>Ações</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {correcoes.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.data}</StyledTableCell>
                                <StyledTableCell>{row.user}</StyledTableCell>
                                <StyledTableCell>
                                  {row.quantidade_antes}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.quantidade_depois}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.quantidade}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <IconButton
                                    aria-label="excluir"
                                    size="small"
                                    onClick={() =>
                                      handleDeletarCorrecao(row.id)
                                    }
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <TablePagination
                          rowsPerPageOptions={[perPageCorrecao]}
                          count={total}
                          rowsPerPage={perPageCorrecao}
                          page={page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={() => {}}
                          labelRowsPerPage="Por página"
                          labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to} de ${
                              count !== -1 ? count : `more than ${to}`
                            }`
                          }
                        />
                      </TableContainer>
                      <Box mt="1em" />
                      <Divider />
                      <Box mt="1em" />
                    </TabPanel>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.wrapper}>
          <Button
            color="primary"
            onClick={() => {
              handleCloseDialog();
            }}
            disabled={carregando}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
          >
            Fechar
          </Button>
          {carregando && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
      <DialogoCreateAvaria
        ref={refDialogoCreateAvaria}
        handleClose={(status) => {
          if (status) getAvarias(page);
        }}
      />
      <DialogoCreateCorrecao
        ref={refDialogoCreateCorrecao}
        handleClose={(status) => {
          if (status) getCorrecoes(page);
        }}
      />
      <DialogoDeletarAvaria
        ref={refDialogoDeleteAvaria}
        handleClose={() => getAvarias(page)}
      />
      <DialogoDeletarCorrecao
        ref={refDialogoDeleteCorrecao}
        handleClose={() => getCorrecoes(page)}
      />
    </Dialog>
  );
});

export default DialogoShowProduto;
