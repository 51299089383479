import React, { useState, useRef, useEffect } from 'react';

import { makeStyles, Box } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useSnackbar } from 'notistack';

import { SearchProduto } from '../../../Components';
import { getListAllAPI, getListAPI } from '../../../services';
import {
  formatMoeda,
  POR_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  STATUS_ATIVO,
  // formatPreco,
} from '../../../utils';
import Tabela from './Tabela';

function formatPreco(valor) {
  return valor !== null && valor !== undefined
    ? `${valor.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`
    : '';
}

/* const dadosLoteAtual = {
  bc: 200,
  qtde: 10,
  cg: 0,
};

const dadosProduto = {
  lb: 10,
  ipi: 0,
  pis: 1.65,
  cofins: 7.5,
  icms_ai: 18,
  icms_int: 18,
  rbc: 100,
  df: 0,
  cst: '123',
  cfop: '1234',
  ncm: '16010000',
};

const dadosConfig = {
  ppc: 2,
  irpj: 15,
  csll: 9,
}; */

function calculatePrecoVenda(dadosLoteAtual, dadosProduto, dadosLoja) {
  const { bc, qtde, cg } = dadosLoteAtual;
  const {
    lb,
    ipi,
    pis,
    cofins,
    icms_ai,
    icms_int,
    rbc,
    df,
    cst,
    cfop,
    ncm,
  } = dadosProduto;
  const { ppc, irpj, csll } = dadosLoja;

  const cn = bc / qtde;
  const ipi_c = (ipi / 100) * cn;
  const pis_c = (pis / 100) * cn;
  const cofins_c = (cofins / 100) * cn;
  const icms_ai_c = (icms_ai / 100) * cn;
  const cli = cn - (ipi_c + pis_c + cofins_c + icms_ai_c);
  const icms_int_c = icms_int * (rbc / 100);
  const pv =
    (cli * (1 + lb / 100)) /
      (1 - (ipi + pis + cofins + icms_int_c + ppc) / 100) +
    cg;

  const ipi_v = (ipi / 100) * pv;
  const pis_v = (pis / 100) * pv;

  const icms_int_s = (icms_int_c / 100) * pv;
  const lb_c = pv * (lb / 100);
  const irpj_c = (irpj / 100) * lb_c;
  const csll_c = (csll / 100) * lb_c;
  const ebitida_c = lb_c - (csll_c + irpj_c);
  const ebitida = ebitida_c / pv;
  const cni = cli / (1 - (ipi + pis + cofins + icms_int_c + ppc) / 100);
  const cf = cni + cg;

  const ppc_c = (ppc / 100) * pv;

  return {
    bc,
    qtde,
    cn,
    cg,
    lb,
    lb_c,
    pv,
    cni,
    cf,
    ebitida,
    ebitida_c,
    ipi,
    ipi_c,
    pis,
    pis_c,
    cofins,
    cofins_c,
    icms_ai,
    icms_ai_c,
    cli,
    ipi_v,
    pis_v,
    icms_int,
    icms_int_c,
    icms_int_s,
    ppc,
    ppc_c,
    irpj,
    irpj_c,
    csll,
    csll_c,
    ncm,
    df,
    cst,
    cfop,
    rbc,
    pv_lucro: pv - lb_c,
  };
}

const tamanhoColunas = 150;

const columns = [
  {
    field: 'bc',
    headerName: 'BC DO ICMS (R$)',
    width: tamanhoColunas * 1.5,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'qtde',
    headerName: 'QTDE',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'cn',
    headerName: 'CN (R$)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'cg',
    headerName: 'CG (R$)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'lb',
    headerName: 'LB (%)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'lb_c',
    headerName: 'LB (R$)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'pv',
    headerName: 'PV (R$)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'cni',
    headerName: 'CNI (R$)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'cf',
    headerName: 'CF (R$)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'ebitida',
    headerName: 'EBITIDA (%)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'ebitida_c',
    headerName: 'EBITIDA (R$)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'pv_lucro',
    headerName: 'PV - Lucro (R$)',
    width: tamanhoColunas * 1.5,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'ipi',
    headerName: 'IPI (%)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'ipi_c',
    headerName: 'IPI (R$)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'pis',
    headerName: 'PIS (%)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'pis_c',
    headerName: 'PIS (R$)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'cofins',
    headerName: 'COFINS (%)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'cofins_c',
    headerName: 'COFINS (R$)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'icms_ai',
    headerName: 'AI (%)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'icms_ai_c',
    headerName: 'AI (R$)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'cli',
    headerName: 'CLI (R$)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'ipi_v',
    headerName: 'IPI V (R$)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'pis_v',
    headerName: 'PIS V (R$)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'icms_int',
    headerName: 'ICMS INT (%)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'icms_int_c',
    headerName: 'ICMS R (%)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'icms_int_s',
    headerName: 'ICMS R (R$)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'ppc',
    headerName: 'PPC (%)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'ppc_c',
    headerName: 'PPC (R$)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'irpj',
    headerName: 'IRPJ (%)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'irpj_c',
    headerName: 'IRPJ (R$)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'csll',
    headerName: 'CSLL (%)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'csll_c',
    headerName: 'CSLL (R$)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'ncm',
    headerName: 'NCM',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'df',
    headerName: 'DF (%)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'cst',
    headerName: 'CST',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'cfop',
    headerName: 'CFOP',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'rbc',
    headerName: 'RBC (%)',
    width: tamanhoColunas,
    headerAlign: 'center',
    align: 'center',
  },
];

function formatConfig(config) {
  return {
    ppc: config.ppc,
    irpj: config.irpj,
    csll: config.csll,
  };
}

function formatProduto(produto) {
  return {
    lb: 10,
    ipi: produto.ipi,
    pis: produto.pis,
    cofins: produto.cofins,
    icms_ai: produto.icms_ai,
    icms_int: produto.icms_int,
    rbc: produto.rbc,
    // rbc: 100,
    df: produto.df,
    cst: produto.cst,
    cfop: produto.cfop,
    ncm: produto.ncm,
  };
}

function getUnidadeFinanceiro(unidades, peso, unidade, pesoMedio) {
  switch (unidade.tipo) {
    case POR_UNIDADE:
      return unidades;
    case POR_PESO_FIXO:
      return unidades * pesoMedio;
    default:
      return peso;
  }
}

function formatLote(lote, unidade, pesoMedio) {
  return {
    bc:
      lote.preco_compra2 *
      getUnidadeFinanceiro(lote.unidades, lote.peso, unidade, pesoMedio),
    qtde: lote.preco_compra2,
    cg: 0,
  };
}

function formatRows(rows) {
  return rows.map((item) => ({
    id: item.id,
    bc: formatPreco(item.bc),
    qtde: item.qtde,
    cn: formatPreco(item.cn),
    cg: formatPreco(item.cg),
    lb: formatPreco(item.lb),
    lb_c: formatPreco(item.lb_c),
    pv: formatPreco(item.pv),
    cni: formatPreco(item.cni),
    cf: formatPreco(item.cf),
    ebitida: formatPreco(item.ebitida),
    ebitida_c: formatPreco(item.ebitida_c),
    pv_lucro: formatPreco(item.pv_lucro),
    ipi: formatPreco(item.ipi),
    ipi_c: formatPreco(item.ipi_c),
    pis: formatPreco(item.pis),
    pis_c: formatPreco(item.pis_c),
    cofins: formatPreco(item.cofins),
    cofins_c: formatPreco(item.cofins_c),
    icms_ai: formatPreco(item.icms_ai),
    icms_ai_c: formatPreco(item.icms_ai_c),
    cli: formatPreco(item.cli),
    ipi_v: formatPreco(item.ipi_v),
    pis_v: formatPreco(item.pis_v),
    icms_int: formatPreco(item.icms_int),
    icms_int_c: formatPreco(item.icms_int_c),
    icms_int_s: formatPreco(item.icms_int_s),
    ppc: formatPreco(item.ppc),
    ppc_c: formatPreco(item.ppc_c),
    irpj: formatPreco(item.irpj),
    irpj_c: formatPreco(item.irpj_c),
    csll: formatPreco(item.csll),
    csll_c: formatPreco(item.csll_c),
    ncm: item.ncm,
    df: formatPreco(item.df),
    cst: item.cst,
    cfop: item.cfop,
    rbc: formatPreco(item.rbc),
  }));
}

const useStyles = makeStyles({
  columnHeader: {
    backgroundColor: 'black',
    color: 'white',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
});

const TestePrecificacao = () => {
  const [config, setConfig] = useState(null);
  const [search, setSearch] = useState('');
  const [produto, setProduto] = useState('');
  const refSearch = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [dadosProduto, setDadosProduto] = useState(null);
  const [dadosLote, setDadosLote] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    async function getConfig() {
      const config = await getListAllAPI(
        'config',
        ['id', 'asc'],
        { id: 1 },
        [],
      );
      setConfig(formatConfig(config.data[0]));
    }
    getConfig();
  }, []);

  function analisaCodigo(codigo) {
    if (codigo.length === 13 && codigo.charAt(0) === '2') {
      return {
        tipo: 0,
        data: {
          codigo_interno: codigo.substring(1, 7),
          peso:
            parseInt(codigo.substring(7, 9), 10) +
            parseInt(codigo.substring(9, 12), 10) / 1000,
          digitoVerificador: parseInt(codigo.substring(12, 13), 10),
        },
      };
    }
    return {
      tipo: 1,
      data: {},
    };
  }

  function makeRow() {
    return [
      {
        uidd: 1,
        ...calculatePrecoVenda(dadosLote, dadosProduto, config),
      },
    ];
  }

  async function searchHandle(codigo) {
    const { tipo, data } = analisaCodigo(codigo);
    if (tipo === 0) {
      const { codigo_interno, peso } = data;
      const produtoData = await getListAllAPI(
        'produtos',
        ['id', 'asc'],
        {
          codigo_interno,
          /* ativo: true, */ is_kit: false,
          is_caixa: false,
          status: STATUS_ATIVO,
        },
        ['unidade', 'dadosImposto'],
      );

      if (produtoData.data.length > 0) {
        const loteData = await getListAPI(
          'lotes',
          ['id', 'desc'],
          [1, 1],
          { produto_id: produtoData.data[0].id },
          [],
        );
        if (loteData.data.length > 0) {
          setProduto(produtoData.data[0].nome);
          setDadosProduto(formatProduto(produtoData.data[0].dadosImposto[0]));
          setDadosLote(
            formatLote(
              loteData.data[0],
              produtoData.data[0].unidade,
              produtoData.data[0].peso_medio,
            ),
          );
          switch (produtoData.data[0].unidade.tipo) {
            case POR_UNIDADE:
              columns[1].headerName = 'QTDE (Un)';
              break;
            default:
              columns[1].headerName = 'QTDE (Kg)';
              break;
          }
        } else {
          setProduto('');
          setDadosProduto(null);
          setDadosLote(null);
          if (refSearch.current) refSearch.current.focus();
          enqueueSnackbar(`Este produto não contém lotes cadastrados!`, {
            variant: 'error',
          });
          columns[1].headerName = 'QTDE';
        }
      } else {
        setProduto('');
        setDadosProduto(null);
        setDadosLote(null);
        if (refSearch.current) refSearch.current.focus();
        enqueueSnackbar(
          `Produto não encotrado com este codigo interno: ${codigo_interno}!`,
          {
            variant: 'error',
          },
        );
        columns[1].headerName = 'QTDE';
      }
    } else {
      const produtoData = await getListAllAPI(
        'produtos',
        ['id', 'asc'],
        {
          codigo,
          /* ativo: true, */ is_kit: false,
          is_caixa: false,
          status: STATUS_ATIVO,
        },
        ['unidade', 'dadosImposto'],
      );
      if (produtoData.data.length > 0) {
        const loteData = await getListAPI(
          'lotes',
          ['id', 'desc'],
          [1, 1],
          { produto_id: produtoData.data[0].id },
          [],
        );
        if (loteData.data.length > 0) {
          setProduto(produtoData.data[0].nome);
          setDadosProduto(formatProduto(produtoData.data[0].dadosImposto[0]));
          setDadosLote(
            formatLote(
              loteData.data[0],
              produtoData.data[0].unidade,
              produtoData.data[0].peso_medio,
            ),
          );
          switch (produtoData.data[0].unidade.tipo) {
            case POR_UNIDADE:
              columns[1].headerName = 'QTDE (Un)';
              break;
            default:
              columns[1].headerName = 'QTDE (Kg)';
              break;
          }
        } else {
          setProduto('');
          setDadosProduto(null);
          setDadosLote(null);
          if (refSearch.current) refSearch.current.focus();
          enqueueSnackbar(`Este produto não contém lotes cadastrados!`, {
            variant: 'error',
          });
          columns[1].headerName = 'QTDE';
        }
      } else {
        const produtoDataCaixa = await getListAllAPI(
          'produtos/caixa',
          ['id', 'asc'],
          { codigo, status: STATUS_ATIVO },
          ['unidade', 'dadosImposto'],
        );
        if (produtoDataCaixa.data.length > 0) {
          const loteData = await getListAPI(
            'lotes',
            ['id', 'desc'],
            [1, 1],
            { produto_id: produtoData.data[0].id },
            [],
          );
          if (loteData.data.length > 0) {
            setProduto(produtoDataCaixa.data[0].nome);
            setDadosProduto(
              formatProduto(produtoDataCaixa.data[0].dadosImposto[0]),
            );
            setDadosLote(
              formatLote(
                loteData.data[0],
                produtoDataCaixa.data[0].unidade,
                produtoDataCaixa.data[0].peso_medio,
              ),
            );
            switch (produtoData.data[0].unidade.tipo) {
              case POR_UNIDADE:
                columns[1].headerName = 'QTDE (Un)';
                break;
              default:
                columns[1].headerName = 'QTDE (Kg)';
                break;
            }
          } else {
            setProduto('');
            setDadosProduto(null);
            setDadosLote(null);
            if (refSearch.current) refSearch.current.focus();
            enqueueSnackbar(`Este produto não contém lotes cadastrados!`, {
              variant: 'error',
            });
            columns[1].headerName = 'QTDE';
          }
        } else {
          setProduto('');
          setDadosProduto(null);
          setDadosLote(null);
          if (refSearch.current) refSearch.current.focus();
          enqueueSnackbar(`Produto não encotrado com este código: ${codigo}!`, {
            variant: 'error',
          });
          if (refSearch.current) refSearch.current.focus();
          columns[1].headerName = 'QTDE';
        }
      }
    }

    setSearch('');
  }

  const rows = config && dadosProduto && dadosLote ? formatRows(makeRow()) : [];

  return (
    <div
      style={{
        backgroundColor: '#DAD2D8',
        padding: '30px',
        marginTop: '20px',
        borderRadius: '20px',
      }}
    >
      <div style={{ margin: '0px 0px' }}>
        <SearchProduto
          label="Pesquisar produto"
          value={search}
          onChange={(e) => setSearch(e)}
          fullwidth
          searchHandle={searchHandle}
          handleKey={(keyCode, keyName) => {}}
          style={{ opacity: 1 }}
        />
      </div>
      <div
        style={{
          margin: '20px 0px 40px',
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '30px',
        }}
      >
        {produto}
      </div>
      <Box
        style={{
          // height: 200,
          // width: '100%',
          backgroundColor: 'white',
          // borderRadius: '20px 20px 0px 0px',
          overflowX: 'auto',
        }}
        boxShadow={2}
        // overflow="scroll"
      >
        <Tabela
          rows={rows}
          headCells={[
            {
              field: 'bc',
              label: 'BC DO ICMS (R$)',
              right: true,
            },
            {
              field: 'qtde',
              label: 'QTDE',
              right: true,
            },
            {
              field: 'cn',
              label: 'CN (R$)',
              right: true,
            },
            {
              field: 'cg',
              label: 'CG (R$)',
              right: true,
            },
            {
              field: 'lb',
              label: 'LB (%)',
              right: true,
            },
            {
              field: 'lb_c',
              label: 'LB (R$)',
              right: true,
            },
            {
              field: 'pv',
              label: 'PV (R$)',
              right: true,
            },
            {
              field: 'cni',
              label: 'CNI (R$)',
              right: true,
            },
            {
              field: 'cf',
              label: 'CF (R$)',
              right: true,
            },
            {
              field: 'ebitida',
              label: 'EBITIDA (%)',
              right: true,
            },
            {
              field: 'ebitida_c',
              label: 'EBITIDA (R$)',
              right: true,
            },
            {
              field: 'pv_lucro',
              label: 'PV - Lucro (R$)',
              right: true,
            },
            {
              field: 'ipi',
              label: 'IPI (%)',
              right: true,
            },
            {
              field: 'ipi_c',
              label: 'IPI (R$)',
              right: true,
            },
            {
              field: 'pis',
              label: 'PIS (%)',
              right: true,
            },
            {
              field: 'pis_c',
              label: 'PIS (R$)',
              right: true,
            },
            {
              field: 'cofins',
              label: 'COFINS (%)',
              right: true,
            },
            {
              field: 'cofins_c',
              label: 'COFINS (R$)',
              right: true,
            },
            {
              field: 'icms_ai',
              label: 'AI (%)',
              right: true,
            },
            {
              field: 'icms_ai_c',
              label: 'AI (R$)',
              right: true,
            },
            {
              field: 'cli',
              label: 'CLI (R$)',
              right: true,
            },
            {
              field: 'ipi_v',
              label: 'IPI V (R$)',
              right: true,
            },
            {
              field: 'pis_v',
              label: 'PIS V (R$)',
              right: true,
            },
            {
              field: 'icms_int',
              label: 'ICMS INT (%)',
              right: true,
            },
            {
              field: 'icms_int_c',
              label: 'ICMS R (%)',
              right: true,
            },
            {
              field: 'icms_int_s',
              label: 'ICMS R (R$)',
              right: true,
            },
            {
              field: 'ppc',
              label: 'PPC (%)',
              right: true,
            },
            {
              field: 'ppc_c',
              label: 'PPC (R$)',
              right: true,
            },
            {
              field: 'irpj',
              label: 'IRPJ (%)',
              right: true,
            },
            {
              field: 'irpj_c',
              label: 'IRPJ (R$)',
              right: true,
            },
            {
              field: 'csll',
              label: 'CSLL (%)',
              right: true,
            },
            {
              field: 'csll_c',
              label: 'CSLL (R$)',
              right: true,
            },
            {
              field: 'ncm',
              label: 'NCM',
              right: true,
            },
            {
              field: 'df',
              label: 'DF (%)',
              right: true,
            },
            {
              field: 'cst',
              label: 'CST',
              right: true,
            },
            {
              field: 'cfop',
              label: 'CFOP',
              right: true,
            },
            {
              field: 'rbc',
              label: 'RBC (%)',
              right: true,
            },
          ]}
        />
        {/* <DataGrid
          rows={rows}
          columns={columns}
          pageSize={1}
          disableSelectionOnClick
          // style={{ borderRadius: '20px 20px 0px 0px' }}
          autoHeight
          hideFooter
          showCellRightBorder={false}
          showColumnRightBorder={false}
          disableColumnMenu
          disableColumnFilter
          classes={{ columnHeader: classes.columnHeader }}
        /> */}
      </Box>
    </div>
  );
};

export default TestePrecificacao;
