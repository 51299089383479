import React from 'react';
import { TextField, EditButton, ReferenceField } from 'react-admin';

import ListTemplate from '../../Components/List';
import Filter from './Filter';

const List = (props) => (
  <ListTemplate
    {...props}
    filters={<Filter />}
    title="Categorias Produtos"
    filterDefaultValues={{ ativo: true }}
    isDelete
    isButtonAtivo
  >
    <TextField source="nome" label="Descrição" />
    <ReferenceField
      label="Setor"
      source="setor_id"
      reference="setores"
      sortable={false}
    >
      <TextField source="nome" />
    </ReferenceField>
    <EditButton />
  </ListTemplate>
);

export default List;
