import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import PropTypes from 'prop-types';

const diasDaSemana = [
  'Domingo',
  'Segunda-Feira',
  'Terça-Feira',
  'Quarta-Feira',
  'Quinta-Feira',
  'Sexta-Feira',
  'Sábado',
];

const DataPagamentoField = ({ ...props }) => {
  const record = useRecordContext(props);

  const nomeFatura = record
    ? record.record
      ? record.record.data_pagamento
        ? diasDaSemana[new Date(record.record.data_pagamento).getDay()]
        : '-'
      : '-'
    : '-';

  return (
    <div>
      <Typography variant="subtitle1">{nomeFatura}</Typography>
    </div>
  );
};

DataPagamentoField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default DataPagamentoField;
