import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';

import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useSnackbar } from 'notistack';

import {
  TextInput,
  AutoCompleteRemoto,
  PesoInput,
  IntegerInput,
  PrecoInput,
  PorcentagemInput,
  TeclaInput,
  SwitchInput,
  CfopInput,
  CstInput,
  NcmInput,
} from '../../Components';
import { createAPI, updateAPI, getListAllAPI } from '../../services';
import { formatMoeda } from '../../utils';

function formatPreco(valor) {
  return valor !== null && valor !== undefined
    ? valor.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : '';
}

const FECHAR_VENDA_ACTION = 'F8';
const CANCELAR_VENDA_ACTION = 'F4';

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  root: {
    flexGrow: 1,
  },
}));

const NCM_POSITION = 0;
const CST_POSITION = 1;
const CFOP_POSITION = 2;
const IPI_POSITION = 3;
const PIS_POSITION = 4;
const COFINS_POSITION = 5;
const ICMS_AI_POSITION = 6;
const ICMS_INT_POSITION = 7;
const DF_POSITION = 8;
const RBC_POSITION = 9;

const DEFAULT_ID = -1;

const inputs = [
  {
    nome: 'ncm',
    defaultValue: '',
    label: 'NCM',
  },
  {
    nome: 'cst',
    defaultValue: '',
    label: 'CST',
  },
  {
    nome: 'cfop',
    defaultValue: '',
    label: 'CFOP',
  },
  {
    nome: 'ipi',
    defaultValue: 0,
    label: 'IPI (%)',
  },
  {
    nome: 'pis',
    defaultValue: 0,
    label: 'PIS (%)',
  },
  {
    nome: 'cofins',
    defaultValue: 0,
    label: 'COFINS (%)',
  },
  {
    nome: 'icms_ai',
    defaultValue: 0,
    label: 'ICMS AI (%)',
  },
  {
    nome: 'icms_int',
    defaultValue: 0,
    label: 'ICMS INT (%)',
  },
  {
    nome: 'df',
    defaultValue: 0,
    label: 'DF (%)',
  },
  {
    nome: 'rbc',
    defaultValue: 0,
    label: 'RBC',
  },
];

const SidebarInputs = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [id, setId] = useState(DEFAULT_ID);

  const [ncm, setNcm] = useState('');
  const [cst, setCst] = useState('');
  const [cfop, setCfop] = useState('');
  const [ipi, setIpi] = useState(0);
  const [pis, setPis] = useState(0);
  const [cofins, setCofins] = useState(0);
  const [icmsAi, setIcmsAi] = useState(0);
  const [icmsInt, setIcmsInt] = useState(0);
  const [df, setDf] = useState(0);
  const [rbc, setRbc] = useState(0);

  const [carregando, setCarregando] = useState(false);

  const refs = useRef([]);

  refs.current = inputs.map(
    (ref, index) => (refs.current[index] = React.createRef()),
  );

  function validateNcm() {
    let error = '';
    if (ncm.length < 8) error = 'O NCM deve ter 8 dígitos';
    return error;
  }

  function validateCst() {
    let error = '';
    if (cst) {
      if (cst.length < 3) error = 'O CST deve ter 3 dígitos';
    }
    return error;
  }

  function validateCfop() {
    let error = '';
    if (cfop) {
      if (cfop.length < 4) error = 'O CFOP deve ter 4 dígitos';
    }
    return error;
  }

  function validateIpi() {
    let error = '';
    if (ipi < 0 || Number.isNaN(ipi)) {
      error = 'Este campo é obrigatório';
    } else if (ipi > 100) {
      error = 'Não pode ultrapassar de 100%!';
    }
    return error;
  }

  function validatePis() {
    let error = '';
    if (pis < 0 || Number.isNaN(pis)) {
      error = 'Este campo é obrigatório';
    } else if (pis > 100) {
      error = 'Não pode ultrapassar de 100%!';
    }
    return error;
  }

  function validateCofins() {
    let error = '';
    if (cofins < 0 || Number.isNaN(cofins)) {
      error = 'Este campo é obrigatório';
    } else if (cofins > 100) {
      error = 'Não pode ultrapassar de 100%!';
    }
    return error;
  }

  function validateIcmsAi() {
    let error = '';
    if (icmsAi < 0 || Number.isNaN(icmsAi)) {
      error = 'Este campo é obrigatório';
    } else if (icmsAi > 100) {
      error = 'Não pode ultrapassar de 100%!';
    }
    return error;
  }

  function validateIcmsInt() {
    let error = '';
    if (icmsInt < 0 || Number.isNaN(icmsInt)) {
      error = 'Este campo é obrigatório';
    } else if (icmsInt > 100) {
      error = 'Não pode ultrapassar de 100%!';
    }
    return error;
  }

  function validateDf() {
    let error = '';
    if (df < 0 || Number.isNaN(df)) {
      error = 'Este campo é obrigatório';
    } else if (df > 100) {
      error = 'Não pode ultrapassar de 100%!';
    }
    return error;
  }

  function validateRbc() {
    let error = '';
    if (rbc < 0 || Number.isNaN(rbc)) {
      error = 'Este campo é obrigatório';
    } else if (rbc > 100) {
      error = 'Não pode ultrapassar de 100%!';
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', '', '', '', '', '', '', '', '', ''];

    errosOld[0] = validateNcm();
    errosOld[1] = validateCst();
    errosOld[2] = validateCfop();
    errosOld[3] = validateIpi();
    errosOld[4] = validatePis();
    errosOld[5] = validateCofins();
    errosOld[6] = validateIcmsAi();
    errosOld[7] = validateIcmsInt();
    errosOld[8] = validateDf();
    errosOld[9] = validateRbc();

    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  const handleCloseDialog = (idUpdate) => {
    setOpen(false);
    handleClose(idUpdate);
  };

  async function reiniciar() {
    try {
      const dataConfig = await getListAllAPI(
        'config',
        ['id', 'asc'],
        { id: 1 },
        [],
      );

      setId(DEFAULT_ID);
      setNcm('');
      setCst('');
      setCfop('');
      setIpi(dataConfig.data[0].ipi);
      setPis(dataConfig.data[0].pis);
      setCofins(dataConfig.data[0].cofins);
      setIcmsAi(dataConfig.data[0].icms_ai);
      setIcmsInt(dataConfig.data[0].icms_int);
      setDf(0);
      setRbc(dataConfig.data[0].rbc);

      setCarregando(false);
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'impostos_produtos',
        ['id', 'asc'],
        { id: [id] },
        [],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setNcm(data.data[0].ncm);
        setCst(data.data[0].cst);
        setCfop(data.data[0].cfop);
        setIpi(data.data[0].ipi);
        setPis(data.data[0].pis);
        setCofins(data.data[0].cofins);
        setIcmsAi(data.data[0].icms_ai);
        setIcmsInt(data.data[0].icms_int);
        setDf(data.data[0].df);
        setRbc(data.data[0].rbc);
        setCarregando(false);
      } else {
        throw 'Este Imposto de Produto não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  async function handleSalvar() {
    if (!erroExistente) {
      setCarregando(true);
      try {
        let idLocal = -1;
        if (id <= 0) {
          const produtoCriado = await createAPI('impostos_produtos', {
            ipi,
            pis,
            cofins,
            icms_ai: icmsAi,
            icms_int: icmsInt,
            ncm,
            df,
            cst,
            cfop,
            rbc,
          });
          setCarregando(false);
          if (produtoCriado.data.id) {
            enqueueSnackbar('Imposto de Produto criado com sucesso!', {
              variant: 'success',
            });
            idLocal = produtoCriado.data.id;
          } else {
            enqueueSnackbar(produtoCriado.data, {
              variant: 'error',
            });
            idLocal = -1;
          }
        } else {
          const produtoAtualizado = await updateAPI('impostos_produtos', id, {
            ipi,
            pis,
            cofins,
            icms_ai: icmsAi,
            icms_int: icmsInt,
            ncm,
            df,
            cst,
            cfop,
            rbc,
          });
          setCarregando(false);
          if (produtoAtualizado.data.id) {
            enqueueSnackbar('Imposto de Produto atualizado com sucesso!', {
              variant: 'success',
            });
            idLocal = id;
          } else {
            enqueueSnackbar(produtoAtualizado.data, {
              variant: 'error',
            });
            idLocal = -1;
          }
        }
        handleCloseDialog(idLocal);
      } catch (erros) {
        setCarregando(false);
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog(-1);
      }
    } else {
      enqueueSnackbar('Existem erros no formulário!', {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      setOpen(true);
      setCarregando(true);
      reiniciar();
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        handleSalvar();
        break;
      case CANCELAR_VENDA_ACTION:
        handleCloseDialog(-1);
        break;
      default:
        break;
    }
  }

  function handleKey(keyCode, keyName) {
    handleActions(keyName);
  }

  function getRefNextInput(index) {
    let position = -1;
    switch (index) {
      case 0:
        position = 1;
        break;
      case 1:
        position = 2;
        break;
      case 2:
        position = 3;
        break;
      case 3:
        position = 4;
        break;
      case 4:
        position = 5;
        break;
      case 5:
        position = 6;
        break;
      case 6:
        position = 7;
        break;
      case 7:
        position = 8;
        break;
      case 8:
        position = 9;
        break;
      case 9:
        position = 0;
        break;
      default:
        break;
    }
    return position;
  }

  function handleNextInput(index) {
    const position = getRefNextInput(index);
    if (position === -1) {
      refs.current[0].current.focus();
      refs.current[0].current.select();
    } else if (refs.current[position].current) {
      refs.current[position].current.focus();
      refs.current[position].current.select();
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
    >
      <DialogTitle id="form-dialog-title">
        {id > 0 ? 'Editar Imposto Produto' : 'Novo Imposto Produto'}
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ height: '100%' }}>
          <Box
            display={{ xs: 'block', sm: 'flex' }}
            flexDirection="column"
            marginBottom="30px"
            height="100%"
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Box p="1em">
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <Typography variant="h6" gutterBottom>
                      Códigos
                    </Typography>

                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <NcmInput
                          name={inputs[0].nome}
                          ref={refs.current[NCM_POSITION]}
                          handleEnter={() => handleNextInput(NCM_POSITION)}
                          label={inputs[0].label}
                          handleKey={handleKey}
                          value={ncm}
                          onChange={(value) => setNcm(value.target.value)}
                          error={erros[0] !== ''}
                          helperText={erros[0]}
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <CstInput
                          name={inputs[1].nome}
                          ref={refs.current[CST_POSITION]}
                          handleEnter={() => handleNextInput(CST_POSITION)}
                          label={inputs[1].label}
                          handleKey={handleKey}
                          value={cst}
                          onChange={(value) => setCst(value.target.value)}
                          error={erros[1] !== ''}
                          helperText={erros[1]}
                          fullWidth
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <CfopInput
                          name={inputs[2].nome}
                          ref={refs.current[CFOP_POSITION]}
                          handleEnter={() => handleNextInput(CFOP_POSITION)}
                          label={inputs[2].label}
                          handleKey={handleKey}
                          value={cfop}
                          onChange={(value) => setCfop(value.target.value)}
                          error={erros[2] !== ''}
                          helperText={erros[2]}
                          fullWidth
                        />
                      </Box>
                    </Box>

                    <Typography variant="h6" gutterBottom>
                      Impostos
                    </Typography>

                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <PorcentagemInput
                          name={inputs[3].nome}
                          ref={refs.current[IPI_POSITION]}
                          handleEnter={() => handleNextInput(IPI_POSITION)}
                          label={inputs[3].label}
                          handleKey={handleKey}
                          value={Number.isNaN(ipi) ? '' : String(ipi)}
                          onChange={(value) =>
                            setIpi(parseFloat(value.target.value))
                          }
                          error={erros[3] !== ''}
                          helperText={erros[3]}
                          fullWidth
                          escala={2}
                          autoFocus
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <PorcentagemInput
                          name={inputs[4].nome}
                          ref={refs.current[PIS_POSITION]}
                          handleEnter={() => handleNextInput(PIS_POSITION)}
                          label={inputs[4].label}
                          handleKey={handleKey}
                          value={Number.isNaN(pis) ? '' : String(pis)}
                          onChange={(value) =>
                            setPis(parseFloat(value.target.value))
                          }
                          error={erros[4] !== ''}
                          helperText={erros[4]}
                          fullWidth
                          escala={2}
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <PorcentagemInput
                          name={inputs[5].nome}
                          ref={refs.current[COFINS_POSITION]}
                          handleEnter={() => handleNextInput(COFINS_POSITION)}
                          label={inputs[5].label}
                          handleKey={handleKey}
                          value={Number.isNaN(cofins) ? '' : String(cofins)}
                          onChange={(value) =>
                            setCofins(parseFloat(value.target.value))
                          }
                          error={erros[5] !== ''}
                          helperText={erros[5]}
                          fullWidth
                          escala={2}
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <PorcentagemInput
                          name={inputs[6].nome}
                          ref={refs.current[ICMS_AI_POSITION]}
                          handleEnter={() => handleNextInput(ICMS_AI_POSITION)}
                          label={inputs[6].label}
                          handleKey={handleKey}
                          value={Number.isNaN(icmsAi) ? '' : String(icmsAi)}
                          onChange={(value) =>
                            setIcmsAi(parseFloat(value.target.value))
                          }
                          error={erros[6] !== ''}
                          helperText={erros[6]}
                          fullWidth
                          escala={2}
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <PorcentagemInput
                          name={inputs[7].nome}
                          ref={refs.current[ICMS_INT_POSITION]}
                          handleEnter={() => handleNextInput(ICMS_INT_POSITION)}
                          label={inputs[7].label}
                          handleKey={handleKey}
                          value={Number.isNaN(icmsInt) ? '' : String(icmsInt)}
                          onChange={(value) =>
                            setIcmsInt(parseFloat(value.target.value))
                          }
                          error={erros[7] !== ''}
                          helperText={erros[7]}
                          fullWidth
                          escala={2}
                        />
                      </Box>
                      <Box flex={1} ml="0.5em">
                        <PorcentagemInput
                          name={inputs[8].nome}
                          ref={refs.current[DF_POSITION]}
                          handleEnter={() => handleNextInput(DF_POSITION)}
                          label={inputs[8].label}
                          handleKey={handleKey}
                          value={Number.isNaN(df) ? '' : String(df)}
                          onChange={(value) =>
                            setDf(parseFloat(value.target.value))
                          }
                          error={erros[8] !== ''}
                          helperText={erros[8]}
                          fullWidth
                          escala={2}
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr="0.5em">
                        <PorcentagemInput
                          name={inputs[9].nome}
                          ref={refs.current[RBC_POSITION]}
                          handleEnter={() => handleNextInput(RBC_POSITION)}
                          label={inputs[9].label}
                          handleKey={handleKey}
                          value={Number.isNaN(rbc) ? '' : String(rbc)}
                          onChange={(value) =>
                            setRbc(parseFloat(value.target.value))
                          }
                          error={erros[9] !== ''}
                          helperText={erros[9]}
                          fullWidth
                          escala={2}
                        />
                      </Box>
                      <Box flex={1} ml="0.5em" />
                      <Box flex={1} ml="0.5em" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCloseDialog(-1)} color="primary">
          Cancelar (F4)
        </Button>
        <div className={classes.wrapper}>
          <Button
            color="primary"
            onClick={() => {
              handleSalvar();
            }}
            disabled={erroExistente || carregando}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
          >
            Cadastrar (F8)
          </Button>
          {carregando && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
});

export default SidebarInputs;
