import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
  },
}));

const StatusField = (props) => {
  const classes = useStyles();
  // const { source } = props;
  // const record = useRecordContext(props);
  // const value = record ? record.record.despesa.status : false;

  const value = props
    ? props.record
      ? props.record.despesa
        ? props.record.despesa.status
        : false
      : false
    : false;
  return (
    <>
      {value ? (
        <Avatar className={classes.confirmado}>
          <CheckIcon style={{ fontSize: 15 }} />
        </Avatar>
      ) : (
        <Avatar className={classes.pendente}>
          <PriorityHighIcon style={{ fontSize: 15 }} />
        </Avatar>
      )}
    </>
  );
};

StatusField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default StatusField;
