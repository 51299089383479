import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { makeStyles } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  transacao: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const StatusField = (props) => {
  const classes = useStyles();
  const { source } = props;
  const record = useRecordContext(props);
  const value = `${record ? record.record[source] : 0}`;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <StarIcon className={classes.transacao} style={{ color: '#ffb400' }} />
      <span style={{ marginLeft: '4px' }}>{value}</span>
    </div>
  );
};

StatusField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default StatusField;
