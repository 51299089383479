import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PropTypes from 'prop-types';

const ActionsField = ({
  tipo,
  handleEdit,
  handleDelete,
  handleEfetivar,
  status_tipo = false,
  ...rest
}) => {
  const record = useRecordContext(rest);
  const statusValue = record ? record.record.status : false;

  const fatura_id = record ? record.record.fatura_id : -1;
  const disaledDelete = record
    ? record.record.parcela_venda_id ||
      record.record.parcela_compra_id ||
      (fatura_id && statusValue)
    : false;

  const disabledEdit = record
    ? (record.record.conta.is_caixa && !!record.record.parcela_venda_id) ||
      (record.record.conta.is_caixa && !record.record.is_transferencia) ||
      (fatura_id && statusValue)
    : false;

  const visibleRemove = record ? record.record.categoria.id !== 7 : true;

  return (
    <div
      style={{
        marginRight: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Efetivar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleEfetivar(record)}
        >
          {!status_tipo ? <CheckCircleOutlineIcon /> : <CancelIcon />}
        </IconButton>
      </Tooltip>
      {visibleRemove && (
        <Tooltip title="Editar" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="edit"
            size="small"
            onClick={() => handleEdit(record)}
            disabled={disabledEdit}
          >
            <EditOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Deletar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => handleDelete(record)}
          disabled={disaledDelete}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

ActionsField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ActionsField;
