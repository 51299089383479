import {
  TIPO_OFERTA_PRODUTO_JORNAL,
  TIPO_OFERTA_PRODUTO_LIBERACAO,
  TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE,
} from '../../utils';

export const choicesTipo = [
  { id: TIPO_OFERTA_PRODUTO_JORNAL, name: 'Jornal Oferta' },
  { id: TIPO_OFERTA_PRODUTO_LIBERACAO, name: 'Liberação de Preço' },
  { id: TIPO_OFERTA_PRODUTO_ZERAR_ESTOQUE, name: 'Zerar Estoque' },
];
