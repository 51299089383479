import React, { useState, useEffect } from 'react';
import { Admin, Resource } from 'react-admin';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import { SnackbarProvider } from 'notistack';

import {
  Layout,
  Login,
  LogoutButton,
  themes,
  Dashboard,
  routes,
} from './layout';
import authProvider from './Providers/AuthProvider';
import dataProvider from './Providers/DataProvider';
import i18nProvider from './Providers/i18nProvider';
import AtividadesProcessos from './Resources/AtividadesProcessos';
import AtividadesProcessosPessoal from './Resources/AtividadesProcessosPessoal';
import Avarias from './Resources/Avarias';
import BancosFornecedores from './Resources/BancosFornecedores';
import CaixasFc from './Resources/CaixasFc';
import CamisasChecagem from './Resources/CamisasChecagem';
import Cargos from './Resources/Cargos';
import CartoesCredito from './Resources/CartoesCredito';
import CartoesCreditoCancelados from './Resources/CartoesCreditoCancelados';
import CategoriaFrenteCaixa from './Resources/CategoriaFrenteCaixa';
import CategoriaProblemasDespesas from './Resources/CategoriaProblemasDespesas';
import Categorias from './Resources/Categorias';
import CategoriasClientes from './Resources/CategoriasCliente';
import CategoriaDescontoFuncionarios from './Resources/CategoriasDescontoFuncionario';
import CategoriaPerguntas from './Resources/CategoriasPerguntas';
import CategoriasTransacao from './Resources/CategoriasTransacao';
import Clientes from './Resources/Clientes';
import ClientesTelevendas from './Resources/ClientesTelevendas';
import CodinomesColaborador from './Resources/CodinomesColaborador';
import Colaboradores from './Resources/Colaboradores';
import Compras from './Resources/Compras';
import ConferenciasEstoque from './Resources/ConferenciasEstoque';
import ConferenciaTemperaturaFrezzer from './Resources/ConferenciaTemperaturaFreezer';
import ConferenciaTemperaturaFreezerColetor from './Resources/ConferenciaTemperaturaFreezerColetor';
//import Config from './Resources/Config';
import Contas from './Resources/Contas';
import ContasTabela from './Resources/ContasTabela';
import CorrecoesConta from './Resources/CorrecoesConta';
import CorrecoesEstoque from './Resources/CorrecoesEstoque';
import CotacoesTemplates from './Resources/CotacoesTemplates';
import DefeitosColaboradores from './Resources/DefeitosColaboradores';
import DepartamentoColaborador from './Resources/DepartamentoColaborador';
import DescontoFuncionario from './Resources/DescontoFuncionario';
import DespesasCartaoCredito from './Resources/DespesasCartaoCredito';
import Devolucoes from './Resources/Devolucoes';
import EquipamentosFreezers from './Resources/EquipamentosFreezer';
import EquipesColaborador from './Resources/EquipesColaborador';
import FamiliaProduto from './Resources/FamiliaProduto';
import Fardamentos from './Resources/Fardamentos';
import Features from './Resources/Features';
import Feriados from './Resources/Feriados';
import FeriadosPessoal from './Resources/FeriadosPessoal';
import FinanceiroReajustes from './Resources/FinanceiroReajustes';
import FinanceiroTransferencias from './Resources/FinanceiroTransferencias';
import Fornecedores from './Resources/Fornecedores';
import FornecedoresCancelados from './Resources/FornecedoresCancelados';
import FornecedoresInsumos from './Resources/FornecedoresInsumos';
import FornecedoresQualquer from './Resources/FornecedoresQualquer';
import FornecedoresServicos from './Resources/FornecedoresServicos';
import FuncaoColaborador from './Resources/FuncaoColaborador';
import FuncaoFuncionarioFornecedor from './Resources/FuncaoFuncionarioFornecedor';
import GruposColaborador from './Resources/GruposColaborador';
import ImpostosProdutos from './Resources/ImpostosProdutos';
import InstanciasAtividadesProcessos from './Resources/InstanciasAtividadesProcessos';
import InstanciasAtividadesProcessosColaborador from './Resources/InstanciasAtividadesProcessosColaborador';
import InstanciasAtividadesProcessosGestor from './Resources/InstanciasAtividadesProcessosGestor';
import LocaisSetores from './Resources/LocaisSetores';
import MaloteMalotes from './Resources/MaloteMalotes';
import MaloteSangrias from './Resources/MaloteSangrias';
import MaloteSinistros from './Resources/MaloteSinistros';
import MaloteSobras from './Resources/MaloteSobras';
import MaloteSubmalotes from './Resources/MaloteSubmalotes';
import MaloteRetiradasReciclaveis from './Resources/MaloteTrocos';
import OfertasJornal from './Resources/OfertasJornal';
import ParcelasFornecedorNaoPagas from './Resources/ParcelasFornecedorNaoPagas';
import ParcelasFornecedorPagas from './Resources/ParcelasFornecedorPagas';
import ParcelasVerificacaoNfe from './Resources/ParcelasVerficacaoNfe';
import Perguntas from './Resources/Perguntas';
import PreVendas from './Resources/PreVendas';
import Produtos from './Resources/Produtos';
import ProdutosVerificacaoCaixaria from './Resources/ProdutosVerificacaoCaixaria';
import QualidadesColaboradores from './Resources/QualidadesColaboradores';
import ResumosAvarias from './Resources/ResumosAvarias';
import ResumosCorrecoesEstoque from './Resources/ResumosCorrecoesEstoque';
import ResumosItensVendas from './Resources/ResumosItensVendas';
import ResumosLotes from './Resources/ResumosLotes';
import Sessoes from './Resources/Sessoes';
import Setores from './Resources/Setores';
import Softwares from './Resources/Softwares';
import SolicitacoesImpressao from './Resources/SolicitacoesImpressao';
import SolicitacoesImpressaoColetor from './Resources/SolicitacoesImpressaoColetor';
import SugestaoProdutos from './Resources/SugestaoProdutos';
import Lucro from './Resources/TabelaLucros';
import TamanhosFardamentos from './Resources/TamanhosFardamentos';
import TaraBalanca from './Resources/TaraBalanca';
import TiposPagamento from './Resources/TiposPagamento';
import Transacoes from './Resources/Transacoes';
import TransacoesLimpas from './Resources/TransacoesLimpas';
import TransacoesPix from './Resources/TransacoesPix';
import TransacoesSimuladas from './Resources/TransacoesSimuladas';
import Unidades from './Resources/Unidades';
import Users from './Resources/Users';
import Vendas from './Resources/Vendas';
import VendasParciais from './Resources/VendasParciais';
import { api } from './services';
import { APP_VERSION } from './utils';

function MensagemDesatualizado({ oldVersion, newVersion }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        //background: 'url(/background.jpg)',
        //backgroundSize: 'cover',
        color: 'red',
      }}
    >
      <h1
        style={{
          textAlign: 'center',
        }}
      >
        Gerencial desatualizado, se quer voltar a usa-lo, por favor abre e feche
        algumas vezes até atualizar
      </h1>
      <h2
        style={{
          textAlign: 'center',
        }}
      >{`Versão Atual: ${oldVersion}`}</h2>
      <h2
        style={{
          textAlign: 'center',
        }}
      >{`Versão Nova: ${newVersion}`}</h2>
    </div>
  );
}

function App() {
  const [version, setVersion] = useState(APP_VERSION);

  async function getVersion() {
    const response = await api.get('/versions');
    const { gerencial } = response.data;

    setVersion(gerencial);
  }

  useEffect(() => {
    getVersion();
  }, []);

  const isAtualizado = version === APP_VERSION;

  return (
    <>
      {isAtualizado ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
          <SnackbarProvider maxSnack={6}>
            <Admin
              dataProvider={dataProvider}
              i18nProvider={i18nProvider}
              dashboard={Dashboard}
              authProvider={authProvider}
              title="Caruaru Frios"
              loginPage={Login}
              layout={Layout}
              customRoutes={routes}
              theme={themes}
              logoutButton={LogoutButton}
            >
              <Resource
                name="categorias"
                {...Categorias}
                options={{ label: 'Categorias' }}
              />
              <Resource
                name="unidades"
                {...Unidades}
                options={{ label: 'Unidades' }}
              />
              <Resource
                name="clientes"
                {...Clientes}
                options={{ label: 'Clientes' }}
              />
              <Resource
                name="fornecedores"
                {...Fornecedores}
                options={{ label: 'Fornecedores' }}
              />
              <Resource
                name="fornecedores_cancelados"
                {...FornecedoresCancelados}
                options={{ label: 'Fornecedores Cancelados' }}
              />
              <Resource
                name="produtos"
                {...Produtos}
                options={{ label: 'Produtos' }}
              />
              <Resource
                name="tipospagamento"
                {...TiposPagamento}
                options={{ label: 'Meio de Pagamento' }}
              />
              <Resource
                name="sessoes"
                {...Sessoes}
                options={{ label: 'Sessões' }}
              />
              <Resource
                name="vendas"
                {...Vendas}
                options={{ label: 'Vendas' }}
              />
              <Resource name="entradascaixa" />
              <Resource name="retiradascaixa" />
              <Resource name="saidascaixa" />
              <Resource name="pagamentosemdinheiro" />
              <Resource
                name="compras"
                {...Compras}
                options={{ label: 'Compras' }}
              />
              <Resource name="produtossearch" />
              <Resource name="config" />
              <Resource
                name="users"
                {...Users}
                options={{ label: 'Usuários' }}
              />
              <Resource
                name="contas"
                {...Contas}
                options={{ label: 'Contas' }}
              />
              <Resource name="cidades" />
              <Resource name="caixas" />
              <Resource name="despesas" />
              <Resource
                name="transacoes"
                {...Transacoes}
                options={{ label: 'Transações' }}
              />
              <Resource
                name="transacoes_pix"
                {...TransacoesPix}
                options={{ label: 'Pix' }}
              />
              <Resource name="categorias_transacao" />
              <Resource
                name="categorias_transacao_cards"
                {...CategoriasTransacao}
                options={{ label: 'Categ. Transações' }}
              />
              <Resource name="receitas" />
              <Resource name="transferencias" />
              <Resource name="avarias" {...Avarias} />
              <Resource name="correcoes_estoque" {...CorrecoesEstoque} />
              <Resource name="correcoes_conta" {...CorrecoesConta} />
              <Resource
                name="devolucoes"
                {...Devolucoes}
                options={{ label: 'Devoluções' }}
              />
              <Resource name="itensvenda" />
              <Resource name="lotes" />
              <Resource name="parcelascompra" />
              <Resource name="parcelasvenda" />

              <Resource name="retiradas_sessoes" />
              <Resource name="depositos_sessoes" />
              <Resource name="ajustes_sessoes" />
              <Resource name="contas_normal" />
              <Resource
                name="familias_produtos"
                {...FamiliaProduto}
                options={{ label: 'Famílias' }}
              />
              <Resource
                name="categorias_cliente"
                {...CategoriasClientes}
                options={{ label: 'Categ. Clientes' }}
              />
              <Resource
                name="features"
                {...Features}
                options={{ label: 'Features' }}
              />
              <Resource
                name="cartoes_credito"
                {...CartoesCredito}
                options={{ label: 'Cartões de crédito' }}
              />
              <Resource
                name="cartoes_credito_cancelados"
                {...CartoesCreditoCancelados}
                options={{ label: 'Cartões de crédito cancelados' }}
              />
              <Resource
                name="despesas_cartao_credito"
                {...DespesasCartaoCredito}
                options={{ label: 'Despesa Cartão' }}
              />
              <Resource
                name="setores"
                {...Setores}
                options={{ label: 'Setores' }}
              />
              <Resource
                name="conferencias_itens_estoque"
                {...ConferenciasEstoque}
                options={{ label: 'Confer. de estoque' }}
              />
              {/*<Resource
              name="solicitacoes_impressoes_etiqueta_2"
              {...SolicitacoesImpressao}
              options={{ label: 'Impres. de etiquetas' }}
  />*/}
              <Resource
                name="pre_vendas"
                {...PreVendas}
                options={{ label: 'Pedidos vendas' }}
              />
              <Resource name="itensprevenda" />
              <Resource
                name="cargos"
                {...Cargos}
                options={{ label: 'Cargos do sistema' }}
              />
              <Resource
                name="locais_setores"
                {...LocaisSetores}
                options={{ label: 'Locais' }}
              />
              <Resource
                name="taras_balanca"
                {...TaraBalanca}
                options={{ label: 'Taras Balança' }}
              />
              <Resource name="dados_produto_balanca" />
              <Resource name="dados_produto_imposto" />
              <Resource
                name="funcoes_colaboradores"
                {...FuncaoColaborador}
                options={{ label: 'Funções' }}
              />
              <Resource
                name="colaboradores"
                {...Colaboradores}
                options={{ label: 'Colaboradores' }}
              />
              <Resource
                name="equipamentos_freezer"
                {...EquipamentosFreezers}
                options={{ label: 'Freezers' }}
              />
              <Resource name="locais_produtos" />
              <Resource
                name="conferencias_temperatura_freezers"
                {...ConferenciaTemperaturaFrezzer}
                options={{ label: 'Confer. Freezers' }}
              />
              <Resource name="fornecedores_produtos" />
              <Resource
                name="impostos_produtos"
                {...ImpostosProdutos}
                options={{ label: 'Impostos' }}
              />
              <Resource
                name="clientes_televendas"
                {...ClientesTelevendas}
                options={{ label: 'Clientes Televendas' }}
              />
              <Resource
                name="tabela_lucros"
                {...Lucro}
                options={{ label: 'Tabela Lucro' }}
              />
              <Resource
                name="camisas_checagem"
                {...CamisasChecagem}
                options={{ label: 'Checagem Camisas' }}
              />
              <Resource
                name="fardamentos"
                {...Fardamentos}
                options={{ label: 'Fardamentos' }}
              />
              <Resource
                name="tamanhos_fardamentos"
                {...TamanhosFardamentos}
                options={{ label: 'Tamanhos Fardamentos' }}
              />
              <Resource
                name="feriados"
                {...Feriados}
                options={{ label: 'Feriados' }}
              />
              <Resource
                name="qualidades_colaborador"
                {...QualidadesColaboradores}
                options={{ label: 'Qualidades' }}
              />
              <Resource
                name="defeitos_colaborador"
                {...DefeitosColaboradores}
                options={{ label: 'Defeitos' }}
              />
              <Resource
                name="bancos_fornecedores"
                {...BancosFornecedores}
                options={{ label: 'Bancos Fornecedores' }}
              />
              <Resource
                name="softwares"
                {...Softwares}
                options={{ label: 'Softwares' }}
              />
              <Resource
                name="sugestao_produtos"
                {...SugestaoProdutos}
                options={{ label: 'Sugestão Produtos' }}
              />
              <Resource name="custos_produtos" />
              <Resource
                name="parcelas_vendas_nfe_verificado"
                {...ParcelasVerificacaoNfe}
                options={{ label: 'Verificação Notas Vendas' }}
              />
              <Resource
                name="financeiro_tela_transferencias"
                {...FinanceiroTransferencias}
                options={{ label: 'Transferências' }}
              />
              <Resource
                name="financeiro_tela_reajustes"
                {...FinanceiroReajustes}
                options={{ label: 'Ajustes' }}
              />
              <Resource
                name="funcoes_funcionarios_fornecedores"
                {...FuncaoFuncionarioFornecedor}
                options={{ label: 'Funções Funcionários Fornecedores' }}
              />
              <Resource
                name="transacoes_simuladas"
                {...TransacoesSimuladas}
                options={{ label: 'Transações Simuladas' }}
              />
              <Resource name="templates_depositos_caixas" />
              <Resource
                name="categorias_perguntas"
                {...CategoriaPerguntas}
                options={{ label: 'Categoria de perguntas' }}
              />
              <Resource
                name="categorias_descontos_funcionarios"
                {...CategoriaDescontoFuncionarios}
                options={{ label: 'Categoria de desconto funcionário' }}
              />
              <Resource
                name="perguntas"
                {...Perguntas}
                options={{ label: 'Perguntas' }}
              />
              <Resource
                name="descontos_funcionarios"
                {...DescontoFuncionario}
                options={{ label: 'Descontos Funcionarios' }}
              />
              <Resource
                name="parcelas_nao_pagas_fornecedores"
                {...ParcelasFornecedorNaoPagas}
                options={{ label: 'Parcelas não pagas' }}
              />
              <Resource
                name="parcelas_pagas_fornecedores"
                {...ParcelasFornecedorPagas}
                options={{ label: 'Parcelas pagas' }}
              />
              <Resource
                name="malote_sangrias"
                {...MaloteSangrias}
                options={{ label: 'Sangrias' }}
              />
              <Resource
                name="malote_malotes"
                {...MaloteMalotes}
                options={{ label: 'Malotes' }}
              />
              <Resource
                name="malote_submalotes"
                {...MaloteSubmalotes}
                options={{ label: 'Submalotes' }}
              />
              <Resource
                name="malote_trocos"
                {...MaloteRetiradasReciclaveis}
                options={{ label: 'Trocos' }}
              />
              <Resource
                name="malote_sinistros"
                {...MaloteSinistros}
                options={{ label: 'Sinistros' }}
              />
              <Resource
                name="malote_sobras"
                {...MaloteSobras}
                options={{ label: 'Sobras' }}
              />
              <Resource
                name="categorias_frente_caixas"
                {...CategoriaFrenteCaixa}
                options={{ label: 'Categorias Caixas' }}
              />
              <Resource
                name="fornecedores_insumos"
                {...FornecedoresInsumos}
                options={{ label: 'Fornecedores Insumos' }}
              />
              <Resource
                name="fornecedores_servicos"
                {...FornecedoresServicos}
                options={{ label: 'Fornecedores Serviços' }}
              />
              <Resource
                name="fornecedores_qualquer"
                {...FornecedoresQualquer}
                options={{ label: 'Outros Contatos' }}
              />
              <Resource
                name="fluxo_de_caixa_produtos_estoque_vendas"
                {...ResumosItensVendas}
              />
              <Resource
                name="fluxo_de_caixa_produtos_estoque_compras"
                {...ResumosLotes}
              />
              <Resource
                name="fluxo_de_caixa_produtos_estoque_avarias"
                {...ResumosAvarias}
              />
              <Resource
                name="fluxo_de_caixa_produtos_estoque_correcoes"
                {...ResumosCorrecoesEstoque}
              />
              <Resource name="vendas_parciais_itens" />
              <Resource name="vendas_parciais" {...VendasParciais} />
              <Resource
                name="contas_tabela"
                {...ContasTabela}
                options={{ label: 'Contas (Tabela)' }}
              />
              <Resource
                name="transacoes_limpas"
                {...TransacoesLimpas}
                options={{ label: 'Transacões Conferência' }}
              />
              <Resource
                name="cotacoes_templates"
                {...CotacoesTemplates}
                options={{ label: 'Cotações' }}
              />
              <Resource name="datas-comemorativas" />
              <Resource
                name="categorias-problemas-despesas"
                {...CategoriaProblemasDespesas}
                options={{ label: 'Categorias Problemas Despesas' }}
              />
              <Resource
                name="caixas_fc"
                {...CaixasFc}
                options={{ label: 'Caixas' }}
              />
              <Resource name="fornecedores_todos_tipos" />
              <Resource
                name="produtos-caixaria-verificacao"
                {...ProdutosVerificacaoCaixaria}
                options={{ label: 'Verificação Caixaria' }}
              />
              <Resource
                name="ofertas_jornal_produtos"
                {...OfertasJornal}
                options={{ label: 'Ofertas' }}
              />

              <Resource
                name="solicitacoes_impressoes_etiqueta_2"
                {...SolicitacoesImpressaoColetor}
                options={{ label: 'Solicitações Impressões Etiquetas' }}
              />
              <Resource
                name="conferencias_temperatura_freezers_2"
                {...ConferenciaTemperaturaFreezerColetor}
                options={{ label: 'Conferência Temperatura Freezer' }}
              />

              <Resource
                name="grupos_colaborador"
                {...GruposColaborador}
                options={{ label: 'Grupos Colaborador' }}
              />
              <Resource
                name="equipes_colaborador"
                {...EquipesColaborador}
                options={{ label: 'Equipes Colaborador' }}
              />

              <Resource
                name="codinomes_colaborador"
                {...CodinomesColaborador}
                options={{ label: 'Codinomes Colaborador' }}
              />
              <Resource
                name="atividades_processos"
                {...AtividadesProcessos}
                options={{ label: 'Atividades Templates' }}
              />

              <Resource
                name="atividades_processos_pessoal"
                {...AtividadesProcessosPessoal}
                options={{ label: 'Atividades Templates' }}
              />
              <Resource
                name="feriados_pessoal"
                {...FeriadosPessoal}
                options={{ label: 'Feriados' }}
              />
              <Resource
                name="departamentos_colaborador"
                {...DepartamentoColaborador}
                options={{ label: 'Departamentos Colaboradores' }}
              />
              <Resource
                name="instancias_atividades_processos"
                {...InstanciasAtividadesProcessos}
                options={{ label: 'Atividades' }}
              />
              <Resource
                name="instancias_atividades_processos_colaborador"
                {...InstanciasAtividadesProcessosColaborador}
                options={{ label: 'Atividades Colaborador' }}
              />
              <Resource
                name="instancias_atividades_processos_gestor"
                {...InstanciasAtividadesProcessosGestor}
                options={{ label: 'Atividades Gestor' }}
              />
              <Resource name="atividades_processos_limpa" />
            </Admin>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      ) : (
        <MensagemDesatualizado oldVersion={APP_VERSION} newVersion={version} />
      )}
    </>
  );
}

export default App;
