import React, { useState, useRef, useEffect } from 'react';
import { Title } from 'react-admin';
import { useLocation, useHistory } from 'react-router-dom';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import InputIcon from '@material-ui/icons/Input';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { stringify } from 'query-string';

import PeriodoInput from '../../../Components/Inputs/PeriodoInput';
import { api } from '../../../services';
import Dialogo from './Dialogo';
import DialogoInputs from './DialogoInputs';

function formatMoeda(valor) {
  return valor.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
}

function formatPorcentagem(valor, casasDecimais = 2) {
  return valor !== null && valor !== undefined
    ? `${valor.toLocaleString('de-DE', {
        minimumFractionDigits: casasDecimais,
        maximumFractionDigits: casasDecimais,
      })}%`
    : '';
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableCell2 = withStyles((theme) => ({
  head: {
    backgroundColor: 'rgba(136, 14, 79, 0.5)',
    color: 'white',
  },
  body: {
    fontSize: 14,
    color: 'white',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableRowPorcentagem = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(136, 14, 79, 1)',
    color: 'white',
  },
}))(TableRow);

const StyledTableRowTotais = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(136, 14, 79, 1)',
    color: 'white',
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function TabelaMeioPagamento({ list, title, total, totalInput }) {
  const classes = useStyles();
  return (
    <div>
      <h3
        style={{
          textAlign: 'center',
          marginBottom: '10px',
          marginTop: '30px',
        }}
      >
        {title}
      </h3>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Horário</StyledTableCell>
              <StyledTableCell>Caixa</StyledTableCell>
              <StyledTableCell>Frentista</StyledTableCell>
              <StyledTableCell>nº Venda</StyledTableCell>
              <StyledTableCell align="right">Valor</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>
                  {moment(new Date(row.venda.data)).format('DD/MM/YYYY HH:mm')}
                </StyledTableCell>
                <StyledTableCell>{row.venda.sessao.caixa.nome}</StyledTableCell>
                <StyledTableCell>
                  {row.venda.sessao.user.username}
                </StyledTableCell>
                <StyledTableCell>{row.venda.numero}</StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.valor)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
            <StyledTableRow key={`total-${title}-1`}>
              <StyledTableCell />
              <StyledTableCell />
              <StyledTableCell />
              <StyledTableCell>Total Calculado:</StyledTableCell>
              <StyledTableCell align="right">
                {formatMoeda(total)}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={`total-${title}-2`}>
              <StyledTableCell />
              <StyledTableCell />
              <StyledTableCell />
              <StyledTableCell>Total Entrada:</StyledTableCell>
              <StyledTableCell align="right">
                {formatMoeda(totalInput)}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={`total-${title}-3`}>
              <StyledTableCell />
              <StyledTableCell />
              <StyledTableCell />
              <StyledTableCell>Diferença:</StyledTableCell>
              <StyledTableCell align="right">
                {formatMoeda(total - totalInput)}
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const DashbboardContabilidade = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [periodo, setPeriodo] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [pixQrCodeInputValue, setPixQrCodeInputValue] = useState(0);
  const [creditoInputValue, setCreditoInputValue] = useState(0);
  const [debitoInputValue, setDebitoInputValue] = useState(0);
  const [vonchuerInputValue, setVonchuerInputValue] = useState(0);

  //const history = useHistory();

  const refDialogo = useRef(null);
  const refDialogoInputs = useRef(null);

  const query = useQuery();

  const periodoQuery = query.get('periodo');

  function handleOpenDialogo() {
    if (refDialogo.current) {
      refDialogo.current.handleOpen();
    }
  }

  function handleOpenDialogoInputs() {
    if (refDialogoInputs.current) {
      refDialogoInputs.current.handleOpen();
    }
  }

  function isMaisDe4Dias() {
    const time4Dias = 4 * 24 * 60 * 60 * 1000;
    const timeCalculado = periodo.end.getTime() - periodo.start.getTime();
    return time4Dias < timeCalculado;
  }

  async function handleData(periodo2 = null) {
    const intervalo = periodo2 || periodo;
    if (!isMaisDe4Dias()) {
      setLoading(true);
      try {
        const queryOj = {};
        queryOj.filter = JSON.stringify({
          periodo: intervalo,
        });
        const dados = await api.get(`/relatorio-cartoes?${stringify(queryOj)}`);
        setDados(dados.data);
        setLoading(false);
      } catch (e) {
        setDados(null);
        setLoading(false);
        enqueueSnackbar(`Erro ao carregar os dados do relatório!`, {
          variant: 'error',
        });
      }
    } else {
      enqueueSnackbar(`Não é possível analizar um período de mais de 4 dias!`, {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    if (periodoQuery) {
      const periodoQueryObj = JSON.parse(periodoQuery);
      const dataStart = new Date();
      dataStart.setTime(periodoQueryObj.start);
      const dataEnd = new Date();
      dataEnd.setTime(periodoQueryObj.end);

      const periodo2 = {
        start: dataStart,
        end: dataEnd,
      };

      setPeriodo(periodo2);
      handleData(periodo2);
    }
  }, [periodoQuery]);

  function getDados() {
    if (dados) {
      return {
        pixQrCodeTotal: dados.pixQrCodeTotal,
        pixQrCodeList: dados.pixQrCodeList,
        pixADistanciaTotal: dados.pixADistanciaTotal,
        pixADistanciaList: dados.pixADistanciaList,
        creditoTotal: dados.creditoTotal,
        creditoList: dados.creditoList,
        debitoTotal: dados.debitoTotal,
        debitoList: dados.debitoList,
        vonchuerTotal: dados.vonchuerTotal,
        vonchuerList: dados.vonchuerList,
      };
    }
    return {
      pixQrCodeTotal: 0,
      pixQrCodeList: [],
      pixADistanciaTotal: 0,
      pixADistanciaList: [],
      creditoTotal: 0,
      creditoList: [],
      debitoTotal: 0,
      debitoList: [],
      vonchuerTotal: 0,
      vonchuerList: [],
    };
  }

  const {
    pixQrCodeTotal,
    pixQrCodeList,
    pixADistanciaTotal,
    pixADistanciaList,
    creditoTotal,
    creditoList,
    debitoTotal,
    debitoList,
    vonchuerTotal,
    vonchuerList,
  } = getDados();

  return (
    <div>
      <div
        style={{
          fontSize: '25px',
          fontWeight: 'bold',
          marginTop: '35px',
          marginBottom: '30px',
        }}
      >
        Relatório Cartões
      </div>
      <Title title="Relatório Mensal" />
      <div
        style={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <PeriodoInput
          name="periodo"
          value={periodo}
          onChange={(e) => setPeriodo(e.target.value)}
        />
        <div>
          <Tooltip title="KKKKKKKKK" placement="top" aria-label="pesquisar">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={handleOpenDialogoInputs}
            >
              <InputIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Pesquisar" placement="top" aria-label="pesquisar">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={() => handleData()}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Imprimir relatório"
            placement="top"
            aria-label="Imprimir"
          >
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={handleOpenDialogo}
            >
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <TabelaMeioPagamento
        title="Pix QrCode"
        list={pixQrCodeList}
        total={pixQrCodeTotal}
        totalInput={pixQrCodeInputValue}
      />
      <TabelaMeioPagamento
        title="Pix à Distância"
        list={pixADistanciaList}
        total={pixADistanciaTotal}
        totalInput={pixADistanciaTotal}
      />
      <TabelaMeioPagamento
        title="Débito"
        list={debitoList}
        total={debitoTotal}
        totalInput={debitoInputValue}
      />
      <TabelaMeioPagamento
        title="Crédito"
        list={creditoList}
        total={creditoTotal}
        totalInput={creditoInputValue}
      />
      <TabelaMeioPagamento
        title="Vonchuer"
        list={vonchuerList}
        total={vonchuerTotal}
        totalInput={vonchuerInputValue}
      />
      <Dialogo
        ref={refDialogo}
        dados={{
          pixQrCodeTotal,
          pixQrCodeList,
          pixQrCodeInputValue,
          pixADistanciaTotal,
          pixADistanciaList,
          creditoTotal,
          creditoList,
          creditoInputValue,
          debitoTotal,
          debitoList,
          debitoInputValue,
          vonchuerTotal,
          vonchuerList,
          vonchuerInputValue,
        }}
        periodo={periodo}
      />
      <DialogoInputs
        ref={refDialogoInputs}
        handleSalvar={(data) => {
          setPixQrCodeInputValue(data.pixQrCodeInputValue);
          setCreditoInputValue(data.creditoInputValue);
          setDebitoInputValue(data.debitoInputValue);
          setVonchuerInputValue(data.vonchuerInputValue);
        }}
      />
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DashbboardContabilidade;
