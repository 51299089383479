import React, { useRef } from 'react';
import {
  DateField,
  ListBase,
  ListToolbar,
  Pagination,
  Datagrid,
  useRefresh,
  Title,
} from 'react-admin';

import { Divider, Card, makeStyles } from '@material-ui/core';

import { stylesMalote } from '../../utils';
import ValorTransferenciaField from '../Transacoes/Fields/ValorTransferenciaField';
import Actions from './ActionsList';
import DialogoCriarSubmalote from './DialogoCreate';
import DialogoCriarTroco from './DialogoCriarTroco';
import DialogoEditarSubmalote from './DialogoEditarSubmalote';
import DialogoShowSubmalote from './DialogoShowSubmalote';
import ActionsField from './Fields/ActionsField';
import AtualizadorField from './Fields/AtualizadorField';
import CodigoField from './Fields/CodigoField';
import CriadorField from './Fields/CriadorField';
import IsCriadoManualmenteField from './Fields/IsCriadoManualmenteField';
import Filter from './Filter';

const useStylesDatagrid = makeStyles((theme) => stylesMalote);

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const classes = useStylesDatagrid();
  const refDialogoShowSubmalote = useRef(null);
  const refDialogoCriarTroco = useRef(null);
  const refDialogoEditarSubmalote = useRef(null);
  const refDialogoCriarSubmaloteCompatibilidade = useRef(null);

  const edit = (data) => {
    if (refDialogoEditarSubmalote.current) {
      refDialogoEditarSubmalote.current.handleOpen(data.record.id);
    }
  };

  const criarTroco = (data) => {
    if (refDialogoCriarTroco.current) {
      refDialogoCriarTroco.current.handleOpen(data.record.id);
    }
  };

  const show = (data) => {
    if (refDialogoShowSubmalote.current) {
      refDialogoShowSubmalote.current.handleOpen(data.record.id);
    }
  };

  const criarSubmaloteCompatibilidade = (data) => {
    if (refDialogoCriarSubmaloteCompatibilidade.current) {
      refDialogoCriarSubmaloteCompatibilidade.current.handleOpen();
    }
  };

  function handleClose() {
    refresh();
  }

  return (
    <>
      <Title title="Submalotes" />
      <ListToolbar
        filters={props.filters}
        actions={
          <Actions
            {...props}
            handleCriarCompatibilizacao={criarSubmaloteCompatibilidade}
          />
        }
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Divider />
        <div>
          <Datagrid>
            <IsCriadoManualmenteField
              source="is_artificial"
              label="Criado Manualmente"
              headerClassName={classes.dataHeader}
              cellClassName={classes.dataCell}
              sortable={false}
            />
            <CodigoField
              source="codigo"
              label="Código"
              headerClassName={classes.dataHeader}
              cellClassName={classes.dataCell}
              sortable={false}
            />
            <DateField
              source="data_pagamento"
              label="Criação"
              headerClassName={classes.dataHeader}
              cellClassName={classes.dataCell}
              options={{ hour: 'numeric', minute: 'numeric' }}
              sortable={false}
            />
            <CriadorField
              label="Criador"
              source="responsavel"
              sortable={false}
              headerClassName={classes.dataHeader}
              cellClassName={classes.categoriaCell}
            />
            <AtualizadorField
              label="Quem Atualizou"
              source="responsavel"
              sortable={false}
              headerClassName={classes.dataHeader}
              cellClassName={classes.categoriaCell}
            />
            <ValorTransferenciaField
              source="valor"
              textAlign="right"
              label="Valor"
              headerClassName={classes.dataHeader}
              cellClassName={classes.valorCell}
              sortable={false}
            />
            <ActionsField
              source="lalala"
              handleEdit={edit}
              handleShow={show}
              handleDesmembrar={criarTroco}
              textAlign="right"
              label="Ações"
              headerClassName={classes.dataHeader}
              cellClassName={classes.actionsCell}
              sortable={false}
            />
          </Datagrid>
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoShowSubmalote ref={refDialogoShowSubmalote} />
      <DialogoCriarTroco ref={refDialogoCriarTroco} handleClose={handleClose} />
      <DialogoEditarSubmalote
        ref={refDialogoEditarSubmalote}
        handleClose={handleClose}
      />
      <DialogoCriarSubmalote
        ref={refDialogoCriarSubmaloteCompatibilidade}
        handleClose={handleClose}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Submalotes"
    />
  </>
);

export default List2;
