import React, { useState, useEffect } from 'react';
import { useVersion } from 'react-admin';

import {
  useMediaQuery,
  Box,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import { api, getListAllAPI, getOneAPI } from '../../services';
import { formatPreco, PRIMARY_COLOR } from '../../utils';
import MobillsInput from '../Pages/RelatorioVendaMensal/DateInputMobills';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    background: theme.palette.primary.main,
    color: '#fff',
    padding: 20,
    marginTop: theme.spacing(2),
    marginBottom: '1em',
  },
}));

function getColor(valor, meta) {
  if (valor < 0)
    return {
      background: '#ffcccc',
      color: '#e60000',
    };
  if (valor < meta)
    return {
      background: 'white',
      color: 'black',
    };
  return {
    background: '#ccffcd',
    color: '#00e604',
  };
}

const CardTotal = ({ label, valor, meta, isMeta, onClick }) => {
  const { background, color } = getColor(valor, meta);
  return (
    <Card
      style={{
        borderRadius: '20px',
        cursor: 'pointer',
        flex: 1,
        backgroundColor: background,
        color,
        maxWidth: '33%',
        minWidth: '20%',
      }}
      onClick={onClick}
    >
      <CardContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <div>
            <div
              style={{
                fontSize: '20px',
                marginBottom: '20px',
                display: 'flex',
                alignItems: 'center',
                color: 'black',
              }}
            >
              {label}
            </div>
            <div
              style={{
                fontSize: '30px',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {valor < 0 ? '-' : `${formatPreco(valor)}%`}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

function diasNoMes(mes, ano) {
  const data = new Date(ano, mes, 0);
  return data.getDate();
}

function formatDadosMensalItem(title, value, labelValue, diasRestantes) {
  const faltante = 100 - value < 0 ? 0 : 100 - value;
  return {
    label: title,
    kpis: [
      {
        label: 'Progresso',
        valor: value,
        meta: labelValue,
        isMeta: true,
      },
      {
        label: 'Falta para completar',
        valor: value < 0 ? value : faltante,
        meta: labelValue,
        isMeta: false,
      },
      {
        label: 'Média restante',
        valor:
          value < 0 ? value : diasRestantes <= 0 ? 0 : faltante / diasRestantes,
        meta: labelValue,
        isMeta: false,
      },
    ],
  };
}

function formatDadosMensal(dados) {
  const dadosFormatados = [];
  const registro = dados.length > 0 ? dados[0] : null;

  if (!registro) return [];

  const mes = new Date().getMonth();
  const ano = new Date().getFullYear();

  const diaAtual = new Date().getDate();

  const totalDias = diasNoMes(mes + 1, ano);
  const diasRestantes = totalDias - (diaAtual - 1);

  dadosFormatados.push(
    formatDadosMensalItem(
      'Meta de Aumento de Faturamento Bruto',
      registro.meta_faturamento_valor_final,
      registro.meta_faturamento,
      diasRestantes,
    ),
  );

  dadosFormatados.push(
    formatDadosMensalItem(
      'Meta de Diminuição de Custo Operacional',
      registro.meta_custo_operacional_valor_final,
      registro.meta_custo_operacional,
      diasRestantes,
    ),
  );

  dadosFormatados.push(
    formatDadosMensalItem(
      'Meta de Lucro Bruto',
      registro.meta_lucro_bruto_valor_final,
      registro.meta_lucro_bruto,
      diasRestantes,
    ),
  );

  dadosFormatados.push(
    formatDadosMensalItem(
      'Meta de Lucro Líquido',
      registro.meta_lucro_liquido_valor_final,
      registro.meta_lucro_liquido,
      diasRestantes,
    ),
  );

  dadosFormatados.push(
    formatDadosMensalItem(
      'Meta de Balanço Final',
      registro.meta_balanco_final_valor_final,
      registro.meta_balanco_final,
      diasRestantes,
    ),
  );

  return dadosFormatados;
}

function formatDadosAnualItem(title, value, labelValue, mesesRestantes) {
  const faltante = 100 - value < 0 ? 0 : 100 - value;
  return {
    label: title,
    kpis: [
      {
        label: 'Progresso',
        valor: value,
        meta: labelValue,
        isMeta: true,
      },
      {
        label: 'Falta para completar',
        valor: value < 0 ? value : faltante,
        meta: labelValue,
        isMeta: false,
      },
      {
        label: 'Média restante',
        valor:
          value < 0
            ? value
            : mesesRestantes <= 0
            ? 0
            : faltante / mesesRestantes,
        meta: labelValue,
        isMeta: false,
      },
    ],
  };
}

function formatDadosAnual(dados) {
  const dadosFormatados = [];
  const registro = dados.length > 0 ? dados[0] : null;

  if (!registro) return [];

  const mes = new Date().getMonth();

  const totalMeses = 12;
  const mesesRestantes = totalMeses - mes;

  dadosFormatados.push(
    formatDadosAnualItem(
      'Meta de Aumento de Faturamento Bruto',
      registro.meta_faturamento_valor_final,
      registro.meta_faturamento,
      mesesRestantes,
    ),
  );

  return dadosFormatados;
}

const Grafico = () => {
  const classes = useStyles();
  const version = useVersion();
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState({
    gruposMensal: [],
    gruposAnual: [],
    mes: new Date().getMonth(),
    ano: new Date().getFullYear(),
    mensagem: '',
  });
  const { enqueueSnackbar } = useSnackbar();

  async function handleData({ ano, mes }) {
    setLoading(true);
    try {
      //const dados = await api.get(`/dashboard/kpi/${ano}/${mes}`);
      const data = new Date();
      data.setDate(4);
      data.setFullYear(ano);
      data.setMonth(mes);
      data.setDate(4);

      const dataMensal = await getListAllAPI(
        'dados_mes',
        ['id', 'asc'],
        { mes: data.getMonth(), ano: data.getFullYear() },
        [],
      );

      const dataAnual = await getListAllAPI(
        'dados_ano',
        ['id', 'asc'],
        { ano: data.getFullYear() },
        [],
      );

      const config = await getOneAPI('config', 1, []);

      setDados({
        gruposMensal: formatDadosMensal(dataMensal.data),
        gruposAnual: formatDadosAnual(dataAnual.data),
        mes,
        ano,
        mensagem: config.data.mensagem_tela_inicial,
      });
      setLoading(false);
    } catch (e) {
      setDados({ gruposMensal: [], gruposAnual: [], mes, ano, mensagem: '' });
      setLoading(false);
      enqueueSnackbar(`Erro ao carregar os dados!`, {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    handleData({ ano: dados.ano, mes: dados.mes });
  }, [version]);

  return (
    <>
      <Card className={classes.root}>
        <Box display="flex">
          <Box flex="1">
            <Typography variant="h5" component="h2" gutterBottom>
              SEJA BEM VINDO AO MÓDULO GERENCIAL DA CARUARU FRIOS!
            </Typography>
            <Box maxWidth="40em" />
          </Box>
        </Box>
      </Card>

      <Card style={{ padding: '20px' }}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <h2 style={{ textAlign: 'center' }}>Mensagem do Dia</h2>
          <span>{dados.mensagem}</span>
        </Box>
      </Card>

      <div
        style={{
          margin: '10px 0 20px',
        }}
      >
        <MobillsInput handleData={handleData} />
      </div>
      {dados.gruposMensal.length > 0 && (
        <h1
          style={{
            textAlign: 'center',
            margin: '20px',
            color: PRIMARY_COLOR,
          }}
        >
          Indicadores Mensais
        </h1>
      )}
      {dados.gruposMensal.map(({ label, kpis }) => (
        <div style={{ marginBottom: '30px' }}>
          <h2>{label}</h2>
          <Box
            display="flex"
            style={{
              gap: '10px',
              marginBottom: '20px',
              marginTop: '30px',
              flexWrap: 'wrap',
            }}
          >
            {kpis.map((item) => (
              <CardTotal
                label={
                  item.isMeta
                    ? `${item.label} (${formatPreco(item.meta)}%)`
                    : `${item.label}`
                }
                valor={item.valor}
                meta={item.meta}
                isMeta={item.isMeta}
                onClick={() => {}}
              />
            ))}
          </Box>
        </div>
      ))}
      {dados.gruposAnual.length > 0 && (
        <h1
          style={{
            textAlign: 'center',
            margin: '20px',
            color: PRIMARY_COLOR,
          }}
        >
          Indicadores Anuais
        </h1>
      )}
      {dados.gruposAnual.map(({ label, kpis }) => (
        <div style={{ marginBottom: '30px' }}>
          <h2>{label}</h2>
          <Box
            display="flex"
            style={{
              gap: '10px',
              marginBottom: '20px',
              marginTop: '30px',
              flexWrap: 'wrap',
            }}
          >
            {kpis.map((item) => (
              <CardTotal
                label={
                  item.isMeta
                    ? `${item.label} (${formatPreco(item.meta)}%)`
                    : `${item.label}`
                }
                valor={item.valor}
                meta={item.meta}
                isMeta={item.isMeta}
                onClick={() => {}}
              />
            ))}
          </Box>
        </div>
      ))}
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Grafico;
