import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { PrecoInput } from '../../../../Inputs';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    // backgroundColor: (props) => props.cor,
    // color: 'white',
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    // backgroundColor: 'rgba(0, 0, 0, 0.12)',
    // color: 'red',
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const inputs = [
  {
    nome: 'precoCompra',
    defaultValue: 0,
    label: 'Preco de Compra',
  },
  {
    nome: 'ipi',
    defaultValue: 0,
    label: 'IPI (R$)',
  },
  {
    nome: 'pis',
    defaultValue: 0,
    label: 'PIS (R$)',
  },
  {
    nome: 'cofins',
    defaultValue: 0,
    label: 'COFINS (R$)',
  },
  {
    nome: 'icms',
    defaultValue: 0,
    label: 'ICMS (R$)',
  },
];

const PRECO_COMPRA_POSITION = 0;
const IPI_POSITION = 1;
const PIS_POSITION = 2;
const COFINS_POSITION = 3;
const ICMS_POSITION = 4;

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose, usarBaseCalculo, handleSalvar }, ref) => {
    const [open, setOpen] = useState(false);
    const [precoCompra, setPrecoCompra] = useState(0);
    const [ipi, setIpi] = useState(0);
    const [pis, setPis] = useState(0);
    const [cofins, setCofins] = useState(0);
    const [icms, setIcms] = useState(0);

    const refsInputs = useRef([]);

    refsInputs.current = inputs.map(
      (ref, index) => (refsInputs.current[index] = React.createRef()),
    );

    const classes = useStyles();

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    useImperativeHandle(ref, () => ({
      handleOpen() {
        setOpen(true);
        setPrecoCompra(0);
        setIpi(0);
        setPis(0);
        setCofins(0);
        setIcms(0);
      },
    }));

    function validatePrecoCompra() {
      let error = '';
      if (Number.isNaN(precoCompra) || precoCompra <= 0)
        error = 'Valor inválido!';
      return error;
    }

    function validateIpi() {
      let error = '';
      if (Number.isNaN(ipi) || ipi < 0) error = 'Valor inválido!';
      return error;
    }

    function validatePis() {
      let error = '';
      if (Number.isNaN(pis) || pis < 0) error = 'Valor inválido!';
      return error;
    }

    function validateCofins() {
      let error = '';
      if (Number.isNaN(cofins) || cofins < 0) error = 'Valor inválido!';
      return error;
    }

    function validateIcms() {
      let error = '';
      if (Number.isNaN(icms) || icms < 0) error = 'Valor inválido!';
      return error;
    }

    function getErros() {
      const errosImpostos = ['', '', '', '', ''];
      errosImpostos[0] = validatePrecoCompra();
      errosImpostos[1] = validateIpi();
      errosImpostos[2] = validatePis();
      errosImpostos[3] = validateCofins();
      errosImpostos[4] = validateIcms();

      //
      return errosImpostos;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    function getRefNextInput(index) {
      let position = -1;
      switch (index) {
        case 0:
          position = 1;
          break;
        case 1:
          position = 2;
          break;
        case 2:
          position = 3;
          break;
        case 3:
          position = 4;
          break;
        case 4:
          position = 0;
          break;
        default:
          break;
      }
      return position;
    }

    function handleNextInput(index) {
      const position = getRefNextInput(index);
      if (position === -1) {
        refsInputs.current[0].current.focus();
        refsInputs.current[0].current.select();
      } else if (refsInputs.current[position].current) {
        refsInputs.current[position].current.focus();
        refsInputs.current[position].current.select();
      }
    }

    const FECHAR_VENDA_ACTION = 'F8';
    const CANCELAR_VENDA_ACTION = 'F4';

    async function handleSalvarLocal() {
      if (erroExistente) {
        /// ///////
      } else {
        handleSalvar(precoCompra + ipi + pis + cofins + icms);
        setOpen(false);
      }
    }

    function handleActions(action) {
      switch (action) {
        case FECHAR_VENDA_ACTION:
          handleSalvar();
          break;
        case CANCELAR_VENDA_ACTION:
          handleCloseDialog();
          break;
        default:
          break;
      }
    }

    function handleKey(keyCode, keyName) {
      handleActions(keyName);
    }

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="sm"
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{
              backgroundColor: '#f44336',
              color: 'white',
            }}
          >
            {usarBaseCalculo
              ? 'Compor preço unitário'
              : 'Compor base de cálculo'}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            <div style={{ display: 'flex' }}>
              <Box>
                <Box display="flex">
                  <Box flex={1} mr="0.5em">
                    <PrecoInput
                      name={inputs[0].nome}
                      ref={refsInputs.current[PRECO_COMPRA_POSITION]}
                      handleEnter={() => handleNextInput(PRECO_COMPRA_POSITION)}
                      label={inputs[0].label}
                      handleKey={handleKey}
                      value={
                        Number.isNaN(precoCompra) ? '' : String(precoCompra)
                      }
                      onChange={(value) =>
                        setPrecoCompra(parseFloat(value.target.value))
                      }
                      error={erros[0] !== ''}
                      helperText={erros[0]}
                      fullWidth
                      escala={2}
                      autoFocus
                    />
                  </Box>

                  <Box flex={1} mr="0.5em">
                    <PrecoInput
                      name={inputs[1].nome}
                      ref={refsInputs.current[IPI_POSITION]}
                      handleEnter={() => handleNextInput(IPI_POSITION)}
                      label={inputs[1].label}
                      handleKey={handleKey}
                      value={Number.isNaN(ipi) ? '' : String(ipi)}
                      onChange={(value) =>
                        setIpi(parseFloat(value.target.value))
                      }
                      error={erros[1] !== ''}
                      helperText={erros[1]}
                      fullWidth
                      escala={2}
                    />
                  </Box>

                  <Box flex={1} mr="0.5em">
                    <PrecoInput
                      name={inputs[2].nome}
                      ref={refsInputs.current[PIS_POSITION]}
                      handleEnter={() => handleNextInput(PIS_POSITION)}
                      label={inputs[2].label}
                      handleKey={handleKey}
                      value={Number.isNaN(pis) ? '' : String(pis)}
                      onChange={(value) =>
                        setPis(parseFloat(value.target.value))
                      }
                      error={erros[2] !== ''}
                      helperText={erros[2]}
                      fullWidth
                      escala={2}
                    />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="0.5em">
                    <PrecoInput
                      name={inputs[3].nome}
                      ref={refsInputs.current[COFINS_POSITION]}
                      handleEnter={() => handleNextInput(COFINS_POSITION)}
                      label={inputs[3].label}
                      handleKey={handleKey}
                      value={Number.isNaN(cofins) ? '' : String(cofins)}
                      onChange={(value) =>
                        setCofins(parseFloat(value.target.value))
                      }
                      error={erros[3] !== ''}
                      helperText={erros[3]}
                      fullWidth
                      escala={2}
                    />
                  </Box>

                  <Box flex={1} mr="0.5em">
                    <PrecoInput
                      name={inputs[4].nome}
                      ref={refsInputs.current[ICMS_POSITION]}
                      handleEnter={() => handleNextInput(ICMS_POSITION)}
                      label={inputs[4].label}
                      handleKey={handleKey}
                      value={Number.isNaN(icms) ? '' : String(icms)}
                      onChange={(value) =>
                        setIcms(parseFloat(value.target.value))
                      }
                      error={erros[4] !== ''}
                      helperText={erros[4]}
                      fullWidth
                      escala={2}
                    />
                  </Box>

                  <Box flex={1} mr="0.5em" />
                </Box>
              </Box>
            </div>
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={handleCloseDialog}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
            >
              Fechar
            </Button>
            <Button
              onClick={handleSalvarLocal}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
              disabled={erroExistente}
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
