import React, { useState, useEffect } from 'react';
import { Title } from 'react-admin';

import { TextField } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { LastPage } from '@material-ui/icons';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SearchIcon from '@material-ui/icons/Search';
import { useSnackbar } from 'notistack';
import { stringify } from 'query-string';
import { useDebounce } from 'use-debounce';

import { api, getListAPI } from '../../../services';
import Filtros from './Filtros';

function formatMoeda(valor) {
  return valor.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
}

function formatPeso(valor) {
  return valor !== null && valor !== undefined
    ? valor.toLocaleString('de-DE', {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      })
    : '';
}

function formatPorcentagem(valor, casasDecimais = 2) {
  return valor !== null && valor !== undefined
    ? `${valor.toLocaleString('de-DE', {
        minimumFractionDigits: casasDecimais,
        maximumFractionDigits: casasDecimais,
      })}%`
    : '';
}

const createQueryURL = (sort, range, filter, nested) => {
  const queryOj = {};
  if (sort) queryOj.sort = JSON.stringify([sort[0], sort[1]]);
  if (range) queryOj.range = JSON.stringify([range[0], range[1]]);
  if (filter) queryOj.filter = JSON.stringify(filter);
  if (nested) queryOj.nested = JSON.stringify(nested);

  return stringify(queryOj);
};

async function getListAPI2(resorce, sort, range, filter, nested) {
  const url = `/${resorce}?${createQueryURL(sort, range, filter, nested)}`;
  const data = await api.get(url);
  return {
    data: data.data.data,
    total: parseInt(data.data.total, 10),
    lastPage: parseInt(data.data.lastPage, 10),
  };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const DashbboardContabilidade = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState({
    linhas: [],
  });
  const [periodo, setPeriodo] = useState(0);
  const [sort, setSort] = useState('vendas');
  const [nome, setNome] = useState('');
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const { enqueueSnackbar } = useSnackbar();

  const [nomeDebounce] = useDebounce(nome, 500);

  /*   async function handleData2() {
    setLoading(true);
    try {
      const data = await getListAPI(
        'relatorio/produtos',
        [sort, 'asc'],
        [page + 1, rowsPerPage],
        { nome, periodo },
        [''],
      );
      setDados({ linhas: data.data });
      setTotal(data.total);
      setLoading(false);
    } catch (e) {
      setDados({ linhas: [] });
      setTotal(0);
      setLoading(false);
      enqueueSnackbar(`Erro ao carregar os dados do relatório!`, {
        variant: 'error',
      });
    }
  } */
  /* 
  const paginateGood = (array, page_size, page_number) => {
    const indexMin = page_number * page_size;
    const indexMax = indexMin + page_size;
    const paginatedArray = array.filter(
      (x, index) => index >= indexMin && index < indexMax,
    );

    return paginatedArray;
  }; */

  /*   function orderVector(vector, sortField, sortValue, page_size, page_number) {
    const arrayOrdenado = vector.sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortValue === 'asc' ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortValue === 'asc' ? 1 : -1;
      return 0;
    });
    return paginateGood(arrayOrdenado, page_size, page_number);
  } */

  /*   async function handleData() {
    setLoading(true);
    const sortLocal = sort;
    const pageLocal = page;
    const rowsPerPageLocal = rowsPerPage;
    const nomeLocal = nome;
    const periodoLocal = periodo;

    try {
      let rowsPorPartes = [];
      const data = await getListAPI2(
        'relatorio/produtos2',
        [sortLocal, 'asc'],
        [1, 100],
        { nome: nomeLocal, periodo: periodoLocal },
        [''],
      );
      const lastPage = data.lastPage;
      rowsPorPartes = [...data.data];
      for (let i = 2; i <= lastPage; i += 1) {
        const data = await getListAPI(
          'relatorio/produtos2',
          [sortLocal, 'asc'],
          [i, 100],
          { nome: nomeLocal, periodo: periodoLocal },
          [''],
        );
        rowsPorPartes = [...rowsPorPartes, ...data.data];
      }
      rowsPorPartes = orderVector(
        rowsPorPartes,
        sortLocal,
        sortLocal === 'vendas' ||
          sortLocal === 'unidades' ||
          sortLocal === 'peso'
          ? 'desc'
          : 'asc',
        rowsPerPageLocal,
        pageLocal,
      );
      setDados({ linhas: rowsPorPartes });
      setTotal(data.total);
      setLoading(false);
    } catch (e) {
      setDados({ linhas: [] });
      setTotal(0);
      setLoading(false);
      enqueueSnackbar(`Erro ao carregar os dados do relatório!`, {
        variant: 'error',
      });
    }
  } */

  /*  useEffect(() => {
    handleData();
  }, [periodo, sort, nome, page, rowsPerPage]); */

  async function handleData() {
    setLoading(true);
    const sortLocal = sort;
    const pageLocal = page;
    const rowsPerPageLocal = rowsPerPage;
    const nomeLocal = nome;
    const periodoLocal = periodo;

    try {
      const data = await getListAPI2(
        'relatorio/produtos3',
        [sortLocal, 'desc'],
        [pageLocal + 1, rowsPerPageLocal],
        { nome: nomeLocal, periodo: periodoLocal },
        [''],
      );
      setDados({ linhas: data.data });
      setTotal(data.total);
      setLoading(false);
    } catch (e) {
      setDados({ linhas: [] });
      setTotal(0);
      setLoading(false);
      enqueueSnackbar(`Erro ao carregar os dados do relatório!`, {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    handleData();
  }, [periodo, sort, nomeDebounce, page, rowsPerPage]);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function getColorCurvaAbc(value) {
    if (value > 0 && value <= 50) {
      return '#82f291';
    }

    if (value > 50 && value <= 80) {
      return '#ecf76f';
    }

    if (value > 80 && value <= 100) {
      return '#f26a6a';
    }

    return 'white';
  }

  return (
    <div>
      <div
        style={{
          fontSize: '25px',
          fontWeight: 'bold',
          marginTop: '35px',
          marginBottom: '30px',
          textAlign: 'center',
        }}
      >
        Relatório Produtos
      </div>
      <Title title="Relatório Produtos" />
      <div
        style={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ padding: '10px' }}>
          <TextField
            id="outlined-basic"
            label="Descrição"
            variant="outlined"
            fullWidth
            value={nome}
            onChange={(e) => {
              setNome(e.target.value);
            }}
            /* InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={(value) => setNome(value)}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }} */
          />
        </div>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">
            Período
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={periodo}
            onChange={(event) => setPeriodo(event.target.value)}
            label="Período"
          >
            <MenuItem value={0}>Ontem</MenuItem>
            <MenuItem value={1}>3 últimos dias</MenuItem>
            <MenuItem value={2}>7 últimos dias</MenuItem>
            <MenuItem value={3}>14 últimos dias</MenuItem>
            <MenuItem value={4}>30 últimos dias</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div style={{ display: 'flex' }}>
        {/* <div style={{ flex: 1 }}>
          <Filtros
            periodo={periodo}
            sort={sort}
            handlePeriodo={(value) => setPeriodo(value)}
            handleSort={(value) => setSort(value)}
            handleNome={(value) => setNome(value)}
          />
        </div> */}
        <Paper
          style={{
            flex: 3,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Descrição</StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => setSort('unidades')}
                    style={{ cursor: 'pointer' }}
                  >
                    Unidades
                    {sort === 'unidades' && (
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        size="small"
                        style={{ color: 'white' }}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => setSort('peso')}
                    style={{ cursor: 'pointer' }}
                  >
                    Peso
                    {sort === 'peso' && (
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        size="small"
                        style={{ color: 'white' }}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => setSort('vendas')}
                    style={{ cursor: 'pointer' }}
                  >
                    Vendas (R$)
                    {sort === 'vendas' && (
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        size="small"
                        style={{ color: 'white' }}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => setSort('vendas')}
                    style={{ cursor: 'pointer' }}
                  >
                    Vendas (%)
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => setSort('vendas')}
                    style={{ cursor: 'pointer' }}
                  >
                    Vendas Acumulada(%)
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => setSort('lucro')}
                    style={{ cursor: 'pointer' }}
                  >
                    Lucro (R$)
                    {sort === 'lucro' && (
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        size="small"
                        style={{ color: 'white' }}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => setSort('lucro')}
                    style={{ cursor: 'pointer' }}
                  >
                    Lucro (%)
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    onClick={() => setSort('lucro')}
                    style={{ cursor: 'pointer' }}
                  >
                    Lucro Acumulado(%)
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dados.linhas.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell>{row.nome}</StyledTableCell>
                    <StyledTableCell align="right">
                      {row.unidades > 0 ? row.unidades : '-'}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.peso > 0 ? formatPeso(row.peso) : '-'}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.vendas > 0 ? formatMoeda(row.vendas) : '-'}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.vendasPorcentagem > 0
                        ? formatPorcentagem(row.vendasPorcentagem, 6)
                        : '-'}
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        backgroundColor:
                          sort === 'vendas'
                            ? getColorCurvaAbc(
                                row.vendasPorcentagemAcumulada,
                                6,
                              )
                            : 'white',
                      }}
                    >
                      {row.vendasPorcentagemAcumulada > 0 && sort === 'vendas'
                        ? formatPorcentagem(row.vendasPorcentagemAcumulada, 6)
                        : '-'}
                    </StyledTableCell>

                    <StyledTableCell align="right">
                      {row.lucro > 0 ? formatMoeda(row.lucro) : '-'}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.lucroPorcentagem > 0
                        ? formatPorcentagem(row.lucroPorcentagem, 6)
                        : '-'}
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        backgroundColor:
                          sort === 'lucro'
                            ? getColorCurvaAbc(row.lucroPorcentagemAcumulada, 6)
                            : 'white',
                      }}
                    >
                      {row.lucroPorcentagemAcumulada > 0 && sort === 'lucro'
                        ? formatPorcentagem(row.lucroPorcentagemAcumulada, 6)
                        : '-'}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Por página"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : `more than ${to}`}`
            }
          />
        </Paper>
      </div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DashbboardContabilidade;
