import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { useHistory, useLocation } from 'react-router-dom';

import { Box, makeStyles, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import PropTypes from 'prop-types';

import { getOneAPI } from '../../../services';

const StyledBadgeVenda = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadgeCompra = withStyles((theme) => ({
  badge: {
    backgroundColor: '#f44336',
    color: '#f44336',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // color: 'red',
    marginLeft: '15px',
    maxWidth: '150px',
  },
  avatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    color: 'white',
    backgroundColor: '#f44336',
    cursor: 'pointer',
  },
}));

const DescricaoField = ({ recordPai, ...props }) => {
  const classes = useStyles();
  const record = useRecordContext(props);
  const value = record ? record.record.parcelaCompra.compra.id : '';
  const history = useHistory();

  async function irParaCompra() {
    history.push(`/compras/${value}/show/2`);
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <StyledBadgeCompra
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
        <Avatar
          className={classes.avatar}
          style={{ backgroundColor: '#44b700' }}
          onClick={(e) => {
            irParaCompra();
          }}
        >
          C
        </Avatar>
      </StyledBadgeCompra>
      <Tooltip title={value} placement="bottom" aria-label="add2">
        <Typography className={classes.text}>{value}</Typography>
      </Tooltip>
    </div>
  );
};

DescricaoField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default DescricaoField;
