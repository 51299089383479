import React from 'react';
import { AppBar } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import UserMenu from '../UserMenu';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});

const AppBarCustom = (props) => {
  const classes = useStyles();
  return (
    <>
      <AppBar {...props} userMenu={<UserMenu />} elevation={1}>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
      </AppBar>
    </>
  );
};

export default AppBarCustom;
