import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { getListAllAPI } from '../../../services';
import {
  STATUS_ATIVO,
  TURNO_MANHA,
  TURNO_NOITE,
  TURNO_12_HORAS,
  TIPO_FERIADO_DIA_COMUM,
} from '../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function TableTotal({ rows, title, field }) {
  const total = rows.reduce(
    (accumulator, currentValue) => accumulator + currentValue[field],
    0,
  );
  return (
    <Table aria-label="collapsible table" size="small">
      <TableHead>
        <TableRow>
          <TableCell
            align="left"
            style={{
              backgroundColor: '#009FD4',
              color: 'white',
              fontWeight: 'bold',
            }}
          >
            {title}
          </TableCell>
          <TableCell
            align="left"
            style={{
              backgroundColor: '#009FD4',
              color: 'white',
              fontWeight: 'bold',
            }}
          >
            Total
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((item) => (
          <TableRow key={`${title} - ${item.id}`}>
            <TableCell align="left">{item.nome}</TableCell>
            <TableCell align="left">{item[field]}</TableCell>
          </TableRow>
        ))}
        <TableRow key={`${title} - total`}>
          <TableCell
            align="left"
            style={{
              //color: '#009FD4',
              color: 'black',
              fontSize: '17px',
              fontWeight: 'bold',
            }}
          >
            Total
          </TableCell>
          <TableCell
            align="left"
            style={{
              //color: '#009FD4',
              color: 'black',
              fontSize: '17px',
              fontWeight: 'bold',
            }}
          >
            {total}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

function getLabelTurno(value) {
  switch (value) {
    case TURNO_MANHA:
      return 'Manhã';
    case TURNO_NOITE:
      return 'Noite';
    case TURNO_12_HORAS:
      return '12H';
    default:
      return '';
  }
}

function formatColaborador(dados) {
  const dadosFormatados = [];
  for (let i = 0; i < dados.length; i++) {
    dadosFormatados.push({
      id: dados[i].id,
      nome: dados[i].nome,
      turnoValor: dados[i].turno,
      codinome: dados[i].codinome ? dados[i].codinome.nome : '-',
      turno: getLabelTurno(dados[i].turno),
      grupo_id: dados[i].grupo_colaborador_id
        ? dados[i].grupo_colaborador_id
        : 0,
      equipe_id: dados[i].grupoColaborador
        ? dados[i].grupoColaborador.equipe_colaborador_id
        : 0,
      funcao_id: dados[i].funcao ? dados[i].funcao.id : 0,
      grupo: dados[i].grupoColaborador ? dados[i].grupoColaborador.nome : '-',
      equipe: dados[i].grupoColaborador
        ? dados[i].grupoColaborador.equipe
          ? dados[i].grupoColaborador.equipe.nome
          : '-'
        : '-',
      funcao: dados[i].funcao ? dados[i].funcao.nome : '-',
    });
  }
  return dadosFormatados;
}

function getTotal(turno, colaboradores, field, value) {
  let total = 0;
  for (let i = 0; i < colaboradores.length; i++) {
    if (turno === '') {
      if (colaboradores[i][field] === value) {
        total += 1;
      }
    } else if (colaboradores[i].turnoValor === turno) {
      if (colaboradores[i][field] === value) {
        total += 1;
      }
    }
  }
  return total;
}

function formatEquipes(dados, colaboradores) {
  const dadosFormatados = [];
  for (let i = 0; i < dados.length; i++) {
    dadosFormatados.push({
      id: dados[i].id,
      nome: dados[i].nome,
      total_manha: getTotal(
        TURNO_MANHA,
        colaboradores,
        'equipe_id',
        dados[i].id,
      ),
      total_tarde: getTotal(
        TURNO_NOITE,
        colaboradores,
        'equipe_id',
        dados[i].id,
      ),
      total_12: getTotal(
        TURNO_12_HORAS,
        colaboradores,
        'equipe_id',
        dados[i].id,
      ),
      total: getTotal('', colaboradores, 'equipe_id', dados[i].id),
    });
  }
  return dadosFormatados;
}

function formatGrupos(dados, colaboradores) {
  const dadosFormatados = [];
  for (let i = 0; i < dados.length; i++) {
    dadosFormatados.push({
      id: dados[i].id,
      nome: dados[i].nome,
      total_manha: getTotal(
        TURNO_MANHA,
        colaboradores,
        'grupo_id',
        dados[i].id,
      ),
      total_tarde: getTotal(
        TURNO_NOITE,
        colaboradores,
        'grupo_id',
        dados[i].id,
      ),
      total_12: getTotal(
        TURNO_12_HORAS,
        colaboradores,
        'grupo_id',
        dados[i].id,
      ),
      total: getTotal('', colaboradores, 'grupo_id', dados[i].id),
    });
  }
  return dadosFormatados;
}

function formatFuncoes(dados, colaboradores) {
  const dadosFormatados = [];
  for (let i = 0; i < dados.length; i++) {
    dadosFormatados.push({
      id: dados[i].id,
      nome: dados[i].nome,
      total_manha: getTotal(
        TURNO_MANHA,
        colaboradores,
        'funcao_id',
        dados[i].id,
      ),
      total_tarde: getTotal(
        TURNO_NOITE,
        colaboradores,
        'funcao_id',
        dados[i].id,
      ),
      total_12: getTotal(
        TURNO_12_HORAS,
        colaboradores,
        'funcao_id',
        dados[i].id,
      ),
      total: getTotal('', colaboradores, 'funcao_id', dados[i].id),
    });
  }
  return dadosFormatados;
}

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose = () => {} }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [colaboradores, setColaboradores] = useState([]);
    const [equipes, setEquipes] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [funcoes, setFuncoes] = useState([]);

    const [carregando, setCarregando] = useState(false);

    async function getEquipes(colaboradores) {
      const data = await getListAllAPI(
        'equipes_colaborador',
        ['id', 'asc'],
        {},
        [],
      );
      return formatEquipes(data.data, colaboradores);
    }

    async function getGrupos(colaboradores) {
      const data = await getListAllAPI(
        'grupos_colaborador',
        ['id', 'asc'],
        {},
        [],
      );
      return formatGrupos(data.data, colaboradores);
    }

    async function getFuncoes(colaboradores) {
      const data = await getListAllAPI(
        'funcoes_colaboradores',
        ['id', 'asc'],
        { status: STATUS_ATIVO },
        [],
      );
      return formatFuncoes(data.data, colaboradores);
    }

    async function getColaborador(horarios, dia) {
      const data = await getListAllAPI(
        'colaboradores',
        ['id', 'asc'],
        {
          status: STATUS_ATIVO,
          is_funcionario_interno: true,
        },
        ['codinome', 'grupoColaborador.equipe', 'funcao'],
      );

      return formatColaborador(data.data, horarios, dia);
    }

    async function getDados() {
      if (!carregando) {
        setCarregando(true);
        try {
          const colaboradores = await getColaborador();

          const equipes = await getEquipes(colaboradores);
          const grupos = await getGrupos(colaboradores);
          const funcoes = await getFuncoes(colaboradores);

          setColaboradores(colaboradores);

          setEquipes(equipes);
          setGrupos(grupos);
          setFuncoes(funcoes);

          setCarregando(false);
        } catch (e) {
          setColaboradores([]);

          setEquipes([]);
          setGrupos([]);
          setFuncoes([]);

          setCarregando(false);
          enqueueSnackbar(`Erro ao carregar os dados do relatório!`, {
            variant: 'error',
          });
        }
      }
    }

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    useImperativeHandle(ref, () => ({
      handleOpen() {
        setCarregando(true);
        setOpen(true);
        getDados();
      },
    }));

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          fullScreen
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            Resumo dos Turnos
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            <div
              style={{
                margin: '10px 0 10px',
                borderRadius: '20px',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                alignItems: 'center',
              }}
            >
              <h1
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '10px',
                }}
              >
                Resumo dos Turnos
              </h1>
              <div
                style={{
                  margin: '10px 0 10px',
                  borderRadius: '20px',
                  padding: '10px',
                  display: 'flex',
                  gap: '20px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    borderRadius: '20px',
                    border: '2px solid #009FD4',
                    padding: '10px',
                  }}
                >
                  <h2
                    style={{
                      margin: '20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    Manhã
                  </h2>
                  <div>
                    <TableTotal
                      rows={equipes}
                      title="Equipe"
                      field="total_manha"
                    />
                  </div>
                  <Divider
                    style={{ backgroundColor: '#009FD4', height: '2px' }}
                  />
                  <div>
                    <TableTotal
                      rows={grupos}
                      title="Grupo"
                      field="total_manha"
                    />
                  </div>
                  <Divider
                    style={{ backgroundColor: '#009FD4', height: '2px' }}
                  />
                  <div>
                    <TableTotal
                      rows={funcoes}
                      title="Função"
                      field="total_manha"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    borderRadius: '20px',
                    border: '2px solid #009FD4',
                    padding: '10px',
                  }}
                >
                  <h2
                    style={{
                      margin: '20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    Noite
                  </h2>
                  <div>
                    <TableTotal
                      rows={equipes}
                      title="Equipe"
                      field="total_tarde"
                    />
                  </div>
                  <Divider
                    style={{ backgroundColor: '#009FD4', height: '2px' }}
                  />
                  <div>
                    <TableTotal
                      rows={grupos}
                      title="Grupo"
                      field="total_tarde"
                    />
                  </div>
                  <Divider
                    style={{ backgroundColor: '#009FD4', height: '2px' }}
                  />
                  <div>
                    <TableTotal
                      rows={funcoes}
                      title="Função"
                      field="total_tarde"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    borderRadius: '20px',
                    border: '2px solid #009FD4',
                    padding: '10px',
                  }}
                >
                  <h2
                    style={{
                      margin: '20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    12 h
                  </h2>
                  <div>
                    <TableTotal
                      rows={equipes}
                      title="Equipe"
                      field="total_12"
                    />
                  </div>
                  <Divider
                    style={{ backgroundColor: '#009FD4', height: '2px' }}
                  />
                  <div>
                    <TableTotal rows={grupos} title="Grupo" field="total_12" />
                  </div>
                  <Divider
                    style={{ backgroundColor: '#009FD4', height: '2px' }}
                  />
                  <div>
                    <TableTotal
                      rows={funcoes}
                      title="Função"
                      field="total_12"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    borderRadius: '20px',
                    border: '2px solid #009FD4',
                    padding: '10px',
                  }}
                >
                  <h2
                    style={{
                      margin: '20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    Todos os Turnos
                  </h2>
                  <div>
                    <TableTotal rows={equipes} title="Equipe" field="total" />
                  </div>
                  <Divider
                    style={{ backgroundColor: '#009FD4', height: '2px' }}
                  />
                  <div>
                    <TableTotal rows={grupos} title="Grupo" field="total" />
                  </div>
                  <Divider
                    style={{ backgroundColor: '#009FD4', height: '2px' }}
                  />
                  <div>
                    <TableTotal rows={funcoes} title="Função" field="total" />
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
