import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import PrintIcon from '@material-ui/icons/Print';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSnackbar } from 'notistack';

import { imprimirNotaVenda } from '../../services/impressora';

const ActionsField = ({
  handleEdit,
  handleEfetivar,
  handleShow,
  handleResumoMercadoriasRestantes,
  handleMudarTipoVenda,
  ...rest
}) => {
  const record = useRecordContext(rest);
  const statusValue = record ? record.record.cancelado : false;
  const isEmPartes = record ? record.record.is_em_partes : false;
  const { enqueueSnackbar } = useSnackbar();

  function getDados() {
    if (record) {
      return {
        ...record.record,
        resource: 'vendas',
      };
    }
    return {};
  }

  async function imprimir(id) {
    if (id > 0) {
      const codigo = await imprimirNotaVenda(id);
      if (codigo < 0) {
        enqueueSnackbar('Erro ao imprimir a nota!', {
          variant: 'error',
        });
      }
    }
  }

  return (
    <div
      style={{
        marginRight: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {isEmPartes ? (
        <Tooltip title="Mercadorias" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="edit"
            size="small"
            onClick={() => handleResumoMercadoriasRestantes(record.record)}
          >
            <ListAltIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <div />
      )}

      <Tooltip title="Mudar Tipo Venda" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleMudarTipoVenda(getDados())}
        >
          <MergeTypeIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Status" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleEfetivar(getDados())}
        >
          {statusValue ? <CheckCircleOutlineIcon /> : <CancelIcon />}
        </IconButton>
      </Tooltip>

      <Tooltip title="Editar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleEdit(record.record)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleShow(record.record)}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Imprimir" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="imprimir"
          size="small"
          onClick={() => imprimir(record ? record.record.id : -1)}
        >
          <PrintIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ActionsField;
