import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { useHistory, useLocation } from 'react-router-dom';

import { Box, makeStyles, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import PropTypes from 'prop-types';

import { getOneAPI } from '../../../services';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // color: 'red',
    marginLeft: '15px',
    maxWidth: '150px',
  },
  avatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    color: 'white',
    backgroundColor: '#f44336',
    cursor: 'pointer',
  },
}));

function makeObjFatura(diaPagamento, mes, ano) {
  const dataPagamentoFatura = new Date();
  dataPagamentoFatura.setDate(diaPagamento);
  dataPagamentoFatura.setMonth(mes);
  dataPagamentoFatura.setFullYear(ano);
  const descricaoFatura = `${format(
    dataPagamentoFatura,
    "dd 'de' MMM 'de' yyyy",
    {
      locale: ptBR,
    },
  )}`;
  return descricaoFatura;
}

const DescricaoField = ({ ...props }) => {
  const classes = useStyles();
  const record = useRecordContext();

  const nomeFatura = record.record
    ? record.record.despesa.fatura
      ? makeObjFatura(
          record.record.despesa.fatura.cartao.dia_pagamento,
          record.record.despesa.fatura.mes,
          record.record.despesa.fatura.ano,
        )
      : '-'
    : '-';

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
      <div
        style={{ fontSize: '17px', fontWeight: 'bold', marginRight: '10px' }}
      >
        Fatura:
      </div>
      <Typography
        variant="subtitle1"
        // component="h2"
        // align="center"
        style={
          {
            /* fontWeight: 'bold', */
          }
        }
      >
        {nomeFatura}
      </Typography>
    </div>
  );
};

DescricaoField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default DescricaoField;
