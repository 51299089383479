import {
  login,
  logout,
  setUsername,
  isAuthenticated,
  setUserId,
  api,
} from '../services';

export default {
  login: ({ username, password }) =>
    (async function () {
      const response = await api.post('/login/gerencial', {
        username,
        password,
      });
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      const { token, refreshToken, user_id } = response.data;
      login(token, refreshToken);
      setUsername(username);
      setUserId(user_id);
    })(),
  logout: () =>
    (async function () {
      const response = await api.delete('/logout/gerencial');
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      logout();
    })(),
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      logout();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => (isAuthenticated() ? Promise.resolve() : Promise.reject()),
  getPermissions: () => {
    const role = {};
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
