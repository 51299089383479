import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PropTypes from 'prop-types';

const ActionsField = ({ handleEfetivar, ...rest }) => {
  const record = useRecordContext(rest);
  const value = record ? record.record.visible : false;

  return (
    <div
      style={{
        marginRight: '0px',
        width: '120px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip title="Efetivar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleEfetivar(record)}
        >
          {!value ? <CheckCircleOutlineIcon /> : <CancelIcon />}
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ActionsField;
