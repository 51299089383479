import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
  DateField,
  NumberField,
  ReferenceField,
  TextField,
  ListBase,
  ListToolbar,
  Pagination,
  ShowButton,
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  FunctionField,
  useVersion,
  useListContext,
  useRefresh,
  ListContextProvider,
  // Datagrid,
} from 'react-admin';

import {
  useMediaQuery,
  Divider,
  Tabs,
  Tab,
  CardContent,
  Card,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { stringify } from 'query-string';

import Datagrid from '../../Components/Datagrid';
import DialogoDeleteResource from '../../Components/DialogoDeleteResource';
import { api } from '../../services';
import { formatMoeda } from '../../utils';
import { writeCodigoBarras } from '../../utils/codigoBarras';
import ActionsField from './ActionsField';
import Actions from './ActionsList';
import DialogoSenha from './DialogoSenha';
import ContaField from './Fields/ContaField';
import FrentistaField from './Fields/FrentistaField';
import StatusField from './Fields/StatusField';
import Filter from './Filter';

function getColor(valor) {
  if (valor < 0)
    return {
      background: '#ffcccc',
      color: '#e60000',
    };

  return {
    background: '#ccffcd',
    color: '#00e604',
  };
}

const tabs = [
  { id: 'nao-utilizado', name: 'Não Utilizadas' },
  { id: 'utilizado', name: 'Utilizadas' },
];

const CardTotal = ({
  label,
  valor,
  style = {},
  isPreco = true,
  isColor = false,
}) => {
  const { background, color } = getColor(valor);
  return (
    <Card
      style={{
        borderRadius: '20px',
        cursor: 'pointer',
        flex: 1,
        backgroundColor: isColor ? background : 'white',
        color: isColor ? color : 'black',
        /* maxWidth: '33%',
        minWidth: '20%', */
        width: '50%',
        ...style,
      }}
    >
      <CardContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <div>
            <div
              style={{
                fontSize: '20px',
                marginBottom: '20px',
                display: 'flex',
                alignItems: 'center',
                /* color: 'black', */
              }}
            >
              {label}
            </div>
            <div
              style={{
                fontSize: '30px',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {isPreco ? `${formatMoeda(valor)}` : valor}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const PostPanel = (props) => (
  <Show {...props} title="." actions={false}>
    <SimpleShowLayout>
      <ReferenceManyField
        addLabel={false}
        reference="itensprevenda"
        target="pre_venda_id"
      >
        <Datagrid isBlue>
          <ReferenceField
            label="Produto"
            source="produto_id"
            reference="produtos"
            sortable={false}
          >
            <TextField source="nome" />
          </ReferenceField>
          <TextField source="unidades" label="Unidades" />
          <TextField source="peso" label="Peso (Kg)" />
          <NumberField
            source="preco_venda"
            locales="pt-BR"
            options={{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            }}
            label="Preço Unitário Venda"
          />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

function SectionTotal() {
  const listContext = useListContext();
  const version = useVersion();
  const { ids, filterValues, setFilters, displayedFilters, data } = listContext;
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  async function getDados() {
    setLoading(true);
    const dados = await api.get(
      `/total_prevendas?${stringify({
        filter: JSON.stringify({
          periodo: filterValues.periodo,
          conta_id: filterValues.conta_id,
          frentista_id: filterValues.frentista_id,
          valor: filterValues.valor,
          id: filterValues.id,
          cliente_id: filterValues.cliente_id,
        }),
      })}`,
    );
    setTotal(dados.data.total);
    setLoading(false);
  }

  useEffect(() => {
    getDados();
  }, [
    filterValues.periodo,
    filterValues.conta_id,
    filterValues.frentista_id,
    filterValues.valor,
    filterValues.id,
    filterValues.cliente_id,
    version,
  ]);

  return (
    <div
      style={{
        margin: '10px 0',
      }}
    >
      <CardTotal label="Total" valor={total} />
    </div>
  );
}

const MyList = ({ ...props }) => {
  const refDialogoDeleteResource = useRef(null);
  const refDialogoSenha = useRef(null);
  const refresh = useRefresh();
  const { enqueueSnackbar } = useSnackbar();

  /// ///////////
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [naoUtilizados, setNaoUtilizados] = useState([]);
  const [utilizados, setUtilizados] = useState([]);

  useEffect(() => {
    if (ids && ids !== filterValues.is_utilizado) {
      switch (filterValues.is_utilizado) {
        case false:
          setNaoUtilizados(ids);
          break;
        case true:
          setUtilizados(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.is_utilizado]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          { ...filterValues, is_utilizado: value === 'utilizado' },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );
  /// /////////////

  function handleCriar() {}

  function handleChangeStatus({ id }) {
    if (refDialogoSenha.current) refDialogoSenha.current.handleOpen(id);
  }

  function handleClose() {
    refresh();
  }

  return (
    <>
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <SectionTotal />
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.is_utilizado ? tabs[1].id : tabs[0].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.is_utilizado === false && (
            <ListContextProvider value={{ ...listContext, ids: naoUtilizados }}>
              <Datagrid {...props}>
                <TextField source="id" label="Nº Pré venda" />
                <ReferenceField
                  label="Cliente"
                  source="cliente_id"
                  reference="clientes"
                >
                  <TextField source="nome" />
                </ReferenceField>
                <ContaField label="Caixa" source="conta_id" sortable={false} />
                <FrentistaField
                  label="Frentista"
                  source="responsavel"
                  sortable={false}
                />
                <NumberField
                  source="valor"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor Total"
                />
                <DateField
                  source="created_at"
                  label="Data da Pré Venda"
                  showTime
                />

                <ActionsField
                  source="lalala"
                  handleChangeStatus={handleChangeStatus}
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.is_utilizado === true && (
            <ListContextProvider value={{ ...listContext, ids: utilizados }}>
              <Datagrid {...props}>
                <TextField source="id" label="Nº Pré venda" />
                <ReferenceField
                  label="Cliente"
                  source="cliente_id"
                  reference="clientes"
                >
                  <TextField source="nome" />
                </ReferenceField>
                <ContaField label="Caixa" source="conta_id" sortable={false} />
                <FrentistaField
                  label="Frentista"
                  source="responsavel"
                  sortable={false}
                />
                <NumberField
                  source="valor"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor Total"
                />
                <DateField
                  source="created_at"
                  label="Data da Pré Venda"
                  showTime
                />
                <ActionsField
                  source="lalala"
                  handleChangeStatus={handleChangeStatus}
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoSenha
        ref={refDialogoSenha}
        handleClose={handleClose}
        enqueueSnackbar={enqueueSnackbar}
      />
      <DialogoDeleteResource ref={refDialogoDeleteResource} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Compras"
      filterDefaultValues={{
        periodo: {
          start: new Date(),
          end: new Date(),
        },
        is_utilizado: false,
      }}
    />
  </>
);

export default List2;
