import React, { useState, forwardRef, useImperativeHandle } from 'react';

import { Box } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    // backgroundColor: (props) => props.cor,
    // color: 'white',
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    // backgroundColor: 'rgba(0, 0, 0, 0.12)',
    // color: 'red',
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose, dados, colunas, produto }, ref) => {
    const [open, setOpen] = useState(false);

    const classes = useStyles();

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    useImperativeHandle(ref, () => ({
      handleOpen() {
        setOpen(true);
      },
    }));

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="md"
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{
              backgroundColor: '#f44336',
              color: 'white',
            }}
          >
            {`Detalhes Precificação: ${produto}`}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            <div>
              <Typography
                variant="h4"
                gutterBottom
                style={{ marginTop: '20px' }}
              >
                Venda
              </Typography>

              <Box display="flex" flexWrap="wrap">
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={<Typography>Preço de Venda</Typography>}
                  >
                    <span
                      style={{
                        fontWeight: 'bold',
                        color: 'red',
                        cursor: 'pointer',
                      }}
                    >
                      Preço de Venda
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.pv}`}</span>
                </div>
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={<Typography>Lucro Bruto</Typography>}
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      Lucro Bruto
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.lb} = `}</span>
                  <span>{dados.lb_c}</span>
                </div>
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={<Typography>Ebitida</Typography>}
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      Ebitida
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.ebitida} = `}</span>
                  <span>{dados.ebitida_c}</span>
                </div>
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={
                      <Typography>Programa de Integração Social</Typography>
                    }
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      PIS
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.pis} = `}</span>
                  <span>{dados.pis_v}</span>
                </div>
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={
                      <Typography>
                        Imposto sobre Produtos Industrializados
                      </Typography>
                    }
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      IPI
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.ipi} = `}</span>
                  <span>{dados.ipi_v}</span>
                </div>
              </Box>

              <Typography
                variant="h4"
                gutterBottom
                style={{ marginTop: '20px' }}
              >
                Compra
              </Typography>

              <Box display="flex" flexWrap="wrap">
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={<Typography>Base de Cálculo</Typography>}
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      Base de Cálculo
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.bc} = `}</span>
                  <span>{dados.pv}</span>
                </div>
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={<Typography>Quantidade</Typography>}
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      QTDE
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.pv} = `}</span>
                  <span>{dados.pv}</span>
                </div>
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={<Typography>Preço de Compra</Typography>}
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      Preço Unitário (CN)
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.pv} = `}</span>
                  <span>{dados.pv}</span>
                </div>
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={
                      <Typography>
                        Imposto sobre Circulação de Mercadorias e Serviços
                        Alíquota Interestadual
                      </Typography>
                    }
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      ICMS AI
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.icms_ai} = `}</span>
                  <span>{dados.icms_ai_c}</span>
                </div>
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={
                      <Typography>
                        Imposto sobre Circulação de Mercadorias e Serviços
                        Alíquota Interna
                      </Typography>
                    }
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      ICMS INT
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.icms_int} = `}</span>
                  <span>{dados.icms_int_c}</span>
                </div>
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={
                      <Typography>Programa de Integração Social</Typography>
                    }
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      PIS
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.pis} = `}</span>
                  <span>{dados.pis_c}</span>
                </div>
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={
                      <Typography>
                        Contribuição para Financiamento da Seguridade Social
                      </Typography>
                    }
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      CONFINS
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.cofins} = `}</span>
                  <span>{dados.cofins_c}</span>
                </div>
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={<Typography>CF</Typography>}
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      CF
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.cf}`}</span>
                </div>
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={<Typography>Custo Geral por unidade</Typography>}
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      CG
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.cg}`}</span>
                </div>
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={<Typography>Desoneração da Folha</Typography>}
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      DF
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.df}`}</span>
                </div>
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={<Typography>CNI</Typography>}
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      CNI
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.cni}`}</span>
                </div>
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={<Typography>CLI</Typography>}
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      CLI
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.cli}`}</span>
                </div>
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={<Typography>Redução da Base de Cálculo</Typography>}
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      RBC
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.rbc}`}</span>
                </div>
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={
                      <Typography>
                        Contribuição Social sobre o Lucro Líquido
                      </Typography>
                    }
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      CSLL
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.csll} = `}</span>
                  <span>{dados.csll_c}</span>
                </div>
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={
                      <Typography>
                        Imposto de Rendas de Pessoa Jurídica
                      </Typography>
                    }
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      IRPJ
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.irpj} = `}</span>
                  <span>{dados.irpj_c}</span>
                </div>
              </Box>

              <Typography
                variant="h4"
                gutterBottom
                style={{ marginTop: '20px' }}
              >
                Provisões
              </Typography>

              <Box display="flex" flexWrap="wrap">
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={<Typography>Provisão Perdas de Cartão</Typography>}
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      PPC
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.ppc} = `}</span>
                  <span>{dados.ppc_c}</span>
                </div>
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={
                      <Typography>Provisão Perdas por Avarias </Typography>
                    }
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      PPARF
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.pparf} = `}</span>
                  <span>{dados.pparf_c}</span>
                </div>
                <div style={{ width: '50%', margin: '10px 0' }}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={<Typography>Provisão de Custos Fixos</Typography>}
                  >
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      PCF
                    </span>
                  </Tooltip>
                  <span>{` = ${dados.pcf} = `}</span>
                  <span>{dados.pcf_c}</span>
                </div>
              </Box>
            </div>
          </DialogContent>
          <DialogActions
            style={{
              padding: '32px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={handleCloseDialog}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="outlined"
              color="primary"
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
