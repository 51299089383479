import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useState,
} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useSnackbar } from 'notistack';

import {
  IntegerInput,
  AutoCompleteRemoto,
  DialogoConfirmacao,
  TextInput,
} from '../../../Components';
import { api, getUserId, getListAllAPI } from '../../../services';
import {
  formatMoeda,
  SECONDARY_COLOR,
  ATALHO_VOLTAR,
  ATALHO_AVANCAR,
  MALOTE_MALOTE_OPTION,
  MALOTE_SANGRIA_OPTION,
  MALOTE_SUBMALOTE_OPTION,
  MALOTE_TROCO_OPTION,
  STATUS_ATIVO,
} from '../../../utils';
import { formatarPacote } from '../../../utils/malote';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: 'white',
    fontSize: 12,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const PROXIMO_ACTION = ATALHO_AVANCAR;

const notasPermitidasInit = {
  cedulas_200_reais: true,
  cedulas_100_reais: true,
  cedulas_50_reais: true,
  cedulas_20_reais: true,
  cedulas_10_reais: true,
  cedulas_5_reais: false,
  cedulas_2_reais: false,
  moedas_1_real: false,
  moedas_50_centavos: false,
  moedas_25_centavos: false,
  moedas_10_centavos: false,
  moedas_5_centavos: false,
};

const DialogoContadorCedulas = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = React.useState(false);
  const [conta, setConta] = useState(null);
  const [pacoteAtual, setPacoteAtual] = useState(null);
  const [pacotes, setPacotes] = useState([]);
  const [notasPermitidas, setNotasPermitidas] = useState(notasPermitidasInit);
  const [etapa, setEtapa] = useState(0);
  const [codigo, setCodigo] = useState('');
  const [notasCorrigidas, setNotasCorrigidas] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const refDialogoConfirmacao = useRef(null);

  function resetValues() {
    setConta(null);
    setPacotes([]);
    setNotasPermitidas(notasPermitidasInit);
    setEtapa(0);
    setPacoteAtual(null);
    setNotasCorrigidas(null);
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      resetValues();
      setOpen(true);
    },
  }));

  function handleCloseLocal() {
    setOpen(false);
  }

  const handleChange = (event) => {
    setNotasPermitidas({
      ...notasPermitidas,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeNotasCorrigidas = (field, value) => {
    setNotasCorrigidas({
      ...notasCorrigidas,
      [field]: value,
    });
  };

  function getLabelEtapa() {
    switch (etapa) {
      case 0:
        return 'Etapa 1';
      case 1:
        return 'Etapa 2';
      default:
        return '';
    }
  }

  function proximaEtapa() {
    if (etapa === 0) setEtapa(1);
    else if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Finalização da criação do malote',
        'Deseja realmente finalizar a criação do malote?',
        {
          code: 1,
          data: null,
        },
      );
    }
  }

  function etapaAnterior() {
    if (etapa === 0) handleCloseLocal();
    else setEtapa(0);
  }

  const errorConta = conta ? '' : 'Este campo é obrigatório.';

  const errorNotasPermitidas = !Object.values(notasPermitidas).some(
    (item) => item,
  );

  const disabledNextEtapa1 = errorConta || errorNotasPermitidas;

  async function salvarDados() {
    try {
      api.post('/malote_malotes', {
        pacotes,
        conta_id: conta ? conta.id : -1,
        notasPermitidas,
        user_id: getUserId(),
      });
      handleCloseLocal();
      handleClose();
    } catch (e) {
      handleCloseLocal();
    }
  }

  async function handleConfirma({ code, data }) {
    switch (code) {
      case 1:
        salvarDados();
        break;
      case 2:
        setPacotes(pacotes.filter((item) => item.codigo !== data));
        break;
      default:
        break;
    }
  }

  function isHasPacote(codigo) {
    return pacotes.some((item) => item.codigo === codigo);
  }

  async function getPacote() {
    const codigoFormatado = codigo.trim();
    let atual = null;
    if (codigoFormatado.length > 0) {
      if (!isHasPacote(codigoFormatado)) {
        const data = await getListAllAPI(
          'malote_pacotes',
          ['id', 'asc'],
          { codigo: codigoFormatado },
          [],
        );
        if (data.data.length > 0) atual = formatarPacote(data.data[0]);
        else atual = null;
      } else {
        enqueueSnackbar(`Este pacote já foi bipado neste malote!`, {
          variant: 'error',
        });
      }
    }
    if (pacoteAtual) {
      if (!isHasPacote(pacoteAtual.codigo)) {
        addPacote({
          ...pacoteAtual,
          notas: notasCorrigidas,
        });
        if (atual) {
          setPacoteAtual(atual);
          setNotasCorrigidas(atual.notas);
        } else {
          setPacoteAtual(null);
          setNotasCorrigidas(null);
        }
      }
    } else if (atual) {
      setPacoteAtual(atual);
      setNotasCorrigidas(atual.notas);
    } else {
      setPacoteAtual(null);
      setNotasCorrigidas(null);
      enqueueSnackbar(`Este pacote não existe ou já foi utilizado!`, {
        variant: 'error',
      });
    }
    setCodigo('');
  }

  function removerPacote(codigo) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Remover Pacote',
        'Deseja realmente remover este pacote?',
        {
          code: 2,
          data: codigo,
        },
      );
    }
  }

  const getCoeficiente = (value) => (value ? 1 : 0);

  function addPacote(pacote) {
    if (!isHasPacote(pacote.codigo)) {
      setPacotes([...pacotes, pacote]);
    }
  }

  function calculaTotal() {
    let moedas_5_centavos = 0;
    let moedas_10_centavos = 0;
    let moedas_25_centavos = 0;
    let moedas_50_centavos = 0;
    let moedas_1_real = 0;
    let cedulas_2_reais = 0;
    let cedulas_5_reais = 0;
    let cedulas_10_reais = 0;
    let cedulas_20_reais = 0;
    let cedulas_50_reais = 0;
    let cedulas_100_reais = 0;
    let cedulas_200_reais = 0;

    for (let i = 0; i < pacotes.length; i++) {
      moedas_5_centavos += pacotes[i].notas.moedas_5_centavos;
      moedas_10_centavos += pacotes[i].notas.moedas_10_centavos;
      moedas_25_centavos += pacotes[i].notas.moedas_25_centavos;
      moedas_50_centavos += pacotes[i].notas.moedas_50_centavos;
      moedas_1_real += pacotes[i].notas.moedas_1_real;
      cedulas_2_reais += pacotes[i].notas.cedulas_2_reais;
      cedulas_5_reais += pacotes[i].notas.cedulas_5_reais;
      cedulas_10_reais += pacotes[i].notas.cedulas_10_reais;
      cedulas_20_reais += pacotes[i].notas.cedulas_20_reais;
      cedulas_50_reais += pacotes[i].notas.cedulas_50_reais;
      cedulas_100_reais += pacotes[i].notas.cedulas_100_reais;
      cedulas_200_reais += pacotes[i].notas.cedulas_200_reais;
    }

    const obj = {
      moedas_5_centavos,
      moedas_10_centavos,
      moedas_25_centavos,
      moedas_50_centavos,
      moedas_1_real,
      cedulas_2_reais,
      cedulas_5_reais,
      cedulas_10_reais,
      cedulas_20_reais,
      cedulas_50_reais,
      cedulas_100_reais,
      cedulas_200_reais,
    };

    const total =
      moedas_5_centavos * 0.05 +
      moedas_10_centavos * 0.1 +
      moedas_25_centavos * 0.25 +
      moedas_50_centavos * 0.5 +
      moedas_1_real * 1 +
      cedulas_2_reais * 2 +
      cedulas_5_reais * 5 +
      cedulas_10_reais * 10 +
      cedulas_20_reais * 20 +
      cedulas_50_reais * 50 +
      cedulas_100_reais * 100 +
      cedulas_200_reais * 200;

    const totalMalote =
      moedas_5_centavos *
        0.05 *
        getCoeficiente(notasPermitidas.moedas_5_centavos) +
      moedas_10_centavos *
        0.1 *
        getCoeficiente(notasPermitidas.moedas_10_centavos) +
      moedas_25_centavos *
        0.25 *
        getCoeficiente(notasPermitidas.moedas_25_centavos) +
      moedas_50_centavos *
        0.5 *
        getCoeficiente(notasPermitidas.moedas_50_centavos) +
      moedas_1_real * 1 * getCoeficiente(notasPermitidas.moedas_1_real) +
      cedulas_2_reais * 2 * getCoeficiente(notasPermitidas.cedulas_2_reais) +
      cedulas_5_reais * 5 * getCoeficiente(notasPermitidas.cedulas_5_reais) +
      cedulas_10_reais * 10 * getCoeficiente(notasPermitidas.cedulas_10_reais) +
      cedulas_20_reais * 20 * getCoeficiente(notasPermitidas.cedulas_20_reais) +
      cedulas_50_reais * 50 * getCoeficiente(notasPermitidas.cedulas_50_reais) +
      cedulas_100_reais *
        100 *
        getCoeficiente(notasPermitidas.cedulas_100_reais) +
      cedulas_200_reais *
        200 *
        getCoeficiente(notasPermitidas.cedulas_200_reais);

    const totalSubmalote = total - totalMalote;

    return {
      ...obj,
      total,
      totalMalote,
      totalSubmalote,
    };
  }

  function calculaTotalAtual() {
    let moedas_5_centavos = 0;
    let moedas_10_centavos = 0;
    let moedas_25_centavos = 0;
    let moedas_50_centavos = 0;
    let moedas_1_real = 0;
    let cedulas_2_reais = 0;
    let cedulas_5_reais = 0;
    let cedulas_10_reais = 0;
    let cedulas_20_reais = 0;
    let cedulas_50_reais = 0;
    let cedulas_100_reais = 0;
    let cedulas_200_reais = 0;

    if (pacoteAtual && notasCorrigidas) {
      moedas_5_centavos += Number.isNaN(notasCorrigidas.moedas_5_centavos)
        ? 0
        : notasCorrigidas.moedas_5_centavos;
      moedas_10_centavos += Number.isNaN(notasCorrigidas.moedas_10_centavos)
        ? 0
        : notasCorrigidas.moedas_10_centavos;
      moedas_25_centavos += Number.isNaN(notasCorrigidas.moedas_25_centavos)
        ? 0
        : notasCorrigidas.moedas_25_centavos;
      moedas_50_centavos += Number.isNaN(notasCorrigidas.moedas_50_centavos)
        ? 0
        : notasCorrigidas.moedas_50_centavos;
      moedas_1_real += Number.isNaN(notasCorrigidas.moedas_1_real)
        ? 0
        : notasCorrigidas.moedas_1_real;
      cedulas_2_reais += Number.isNaN(notasCorrigidas.cedulas_2_reais)
        ? 0
        : notasCorrigidas.cedulas_2_reais;
      cedulas_5_reais += Number.isNaN(notasCorrigidas.cedulas_5_reais)
        ? 0
        : notasCorrigidas.cedulas_5_reais;
      cedulas_10_reais += Number.isNaN(notasCorrigidas.cedulas_10_reais)
        ? 0
        : notasCorrigidas.cedulas_10_reais;
      cedulas_20_reais += Number.isNaN(notasCorrigidas.cedulas_20_reais)
        ? 0
        : notasCorrigidas.cedulas_20_reais;
      cedulas_50_reais += Number.isNaN(notasCorrigidas.cedulas_50_reais)
        ? 0
        : notasCorrigidas.cedulas_50_reais;
      cedulas_100_reais += Number.isNaN(notasCorrigidas.cedulas_100_reais)
        ? 0
        : notasCorrigidas.cedulas_100_reais;
      cedulas_200_reais += Number.isNaN(notasCorrigidas.cedulas_200_reais)
        ? 0
        : notasCorrigidas.cedulas_200_reais;
    }

    const obj = {
      moedas_5_centavos,
      moedas_10_centavos,
      moedas_25_centavos,
      moedas_50_centavos,
      moedas_1_real,
      cedulas_2_reais,
      cedulas_5_reais,
      cedulas_10_reais,
      cedulas_20_reais,
      cedulas_50_reais,
      cedulas_100_reais,
      cedulas_200_reais,
    };

    const totalAtual =
      moedas_5_centavos * 0.05 +
      moedas_10_centavos * 0.1 +
      moedas_25_centavos * 0.25 +
      moedas_50_centavos * 0.5 +
      moedas_1_real * 1 +
      cedulas_2_reais * 2 +
      cedulas_5_reais * 5 +
      cedulas_10_reais * 10 +
      cedulas_20_reais * 20 +
      cedulas_50_reais * 50 +
      cedulas_100_reais * 100 +
      cedulas_200_reais * 200;

    return {
      ...obj,
      totalAtual,
    };
  }

  const {
    moedas_5_centavos,
    moedas_10_centavos,
    moedas_25_centavos,
    moedas_50_centavos,
    moedas_1_real,
    cedulas_2_reais,
    cedulas_5_reais,
    cedulas_10_reais,
    cedulas_20_reais,
    cedulas_50_reais,
    cedulas_100_reais,
    cedulas_200_reais,
    total,
    totalMalote,
    totalSubmalote,
  } = calculaTotal();

  const { totalAtual } = calculaTotalAtual();

  function getValueNotasCorrigidas(field) {
    if (pacoteAtual && notasCorrigidas) {
      return notasCorrigidas[field];
    }
    return 0;
  }

  function getCountPacotes() {
    if (etapa === 1) {
      let qtdeSangrias = 0;
      let qtdeTrocos = 0;
      let qtdeMalotes = 0;
      let qtdeSubmalotes = 0;
      for (let i = 0; i < pacotes.length; i++) {
        switch (pacotes[i].tipo) {
          case 'Malote':
            qtdeMalotes++;
            break;
          case 'Sangria':
            qtdeSangrias++;
            break;
          case 'Submalote':
            qtdeSubmalotes++;
            break;
          case 'Troco':
            qtdeTrocos++;
            break;
        }
      }

      return ` (Sangrias: ${qtdeSangrias}; Trocos: ${qtdeTrocos}; Malotes: ${qtdeMalotes}; Submalotes: ${qtdeSubmalotes})`;
    }
    return '';
  }

  const labelQtde = getCountPacotes();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseLocal}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        scroll="paper"
        fullWidth
        maxWidth={etapa === 0 ? 'xs' : 'md'}
        fullScreen={etapa !== 0}
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ color: 'white', backgroundColor: SECONDARY_COLOR }}
        >
          <div>{`Montagem Malote - ${getLabelEtapa()}${labelQtde}`}</div>
        </DialogTitle>
        <DialogContent dividers>
          {etapa === 0 ? (
            <div>
              <AutoCompleteRemoto
                name="conta"
                handleEnter={() => {}}
                label="Conta"
                handleKey={() => {}}
                value={conta}
                onChange={(value) => setConta(value)}
                error={!!errorConta}
                helperText={errorConta}
                fullWidth
                toString={false}
                getOptionSelected={(option, value) =>
                  option.nome === value.nome
                }
                getOptionLabel={(option) => `${option.nome}`}
                nested={[]}
                resource="contas_normal"
                filters={{ status: STATUS_ATIVO }}
              />
              <h2
                style={{
                  margin: '20px 0 10px',
                  textAlign: 'center',
                }}
              >
                Notas permitidas
              </h2>
              {errorNotasPermitidas && (
                <div
                  style={{
                    color: 'red',
                    margin: '5px 0',
                  }}
                >
                  Ao menos um tipo de nota deve ser usado no malote!
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={notasPermitidas.cedulas_200_reais}
                        onChange={handleChange}
                        name="cedulas_200_reais"
                        color="primary"
                      />
                    }
                    label="R$: 200,00"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={notasPermitidas.cedulas_100_reais}
                        onChange={handleChange}
                        name="cedulas_100_reais"
                        color="primary"
                      />
                    }
                    label="R$: 100,00"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={notasPermitidas.cedulas_50_reais}
                        onChange={handleChange}
                        name="cedulas_50_reais"
                        color="primary"
                      />
                    }
                    label="R$: 50,00"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={notasPermitidas.cedulas_20_reais}
                        onChange={handleChange}
                        name="cedulas_20_reais"
                        color="primary"
                      />
                    }
                    label="R$: 20,00"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={notasPermitidas.cedulas_10_reais}
                        onChange={handleChange}
                        name="cedulas_10_reais"
                        color="primary"
                      />
                    }
                    label="R$: 10,00"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={notasPermitidas.cedulas_5_reais}
                        onChange={handleChange}
                        name="cedulas_5_reais"
                        color="primary"
                      />
                    }
                    label="R$: 5,00"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={notasPermitidas.cedulas_2_reais}
                        onChange={handleChange}
                        name="cedulas_2_reais"
                        color="primary"
                      />
                    }
                    label="R$: 2,00"
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={notasPermitidas.moedas_1_real}
                        onChange={handleChange}
                        name="moedas_1_real"
                        color="primary"
                      />
                    }
                    label="R$: 1,00"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={notasPermitidas.moedas_50_centavos}
                        onChange={handleChange}
                        name="moedas_50_centavos"
                        color="primary"
                      />
                    }
                    label="R$: 0,50"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={notasPermitidas.moedas_25_centavos}
                        onChange={handleChange}
                        name="moedas_25_centavos"
                        color="primary"
                      />
                    }
                    label="R$: 0,25"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={notasPermitidas.moedas_10_centavos}
                        onChange={handleChange}
                        name="moedas_10_centavos"
                        color="primary"
                      />
                    }
                    label="R$: 0,10"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={notasPermitidas.moedas_5_centavos}
                        onChange={handleChange}
                        name="moedas_5_centavos"
                        color="primary"
                      />
                    }
                    label="R$: 0,05"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {pacoteAtual ? (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '10px',
                        gap: '20px',
                      }}
                    >
                      <div>
                        <span
                          style={{
                            fontWeight: 'bold',
                            marginRight: '5px',
                          }}
                        >
                          {`${pacoteAtual.tipo} (${pacoteAtual.data}):`}
                        </span>
                        <span>{`${formatMoeda(totalAtual)}`}</span>
                      </div>
                      <div>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          Responsável:{' '}
                        </span>
                        <span>{`${pacoteAtual.responsavel}`}</span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '20px',
                        padding: '10px',
                        border: '1px solid black',
                        borderRadius: '10px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                            padding: '5px',
                            borderRadius: '5px',
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            R$: 200,00
                          </span>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <IntegerInput
                            handleEnter={() => {}}
                            label=""
                            handleKey={() => {}}
                            value={
                              Number.isNaN(
                                getValueNotasCorrigidas('cedulas_200_reais'),
                              )
                                ? ''
                                : String(
                                    getValueNotasCorrigidas(
                                      'cedulas_200_reais',
                                    ),
                                  )
                            }
                            onChange={(value) =>
                              handleChangeNotasCorrigidas(
                                'cedulas_200_reais',
                                parseInt(value.target.value, 10),
                              )
                            }
                            fullWidth
                            style={{
                              margin: '0',
                              padding: '0',
                              width: '50px',
                            }}
                            styleInput={{
                              padding: '0',
                              textAlign: 'center',
                            }}
                          />
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            =
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {formatMoeda(
                              200 *
                                getValueNotasCorrigidas('cedulas_200_reais'),
                            )}
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                            padding: '5px',
                            borderRadius: '5px',
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            R$: 100,00
                          </span>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <IntegerInput
                            handleEnter={() => {}}
                            label=""
                            handleKey={() => {}}
                            value={
                              Number.isNaN(
                                getValueNotasCorrigidas('cedulas_100_reais'),
                              )
                                ? ''
                                : String(
                                    getValueNotasCorrigidas(
                                      'cedulas_100_reais',
                                    ),
                                  )
                            }
                            onChange={(value) =>
                              handleChangeNotasCorrigidas(
                                'cedulas_100_reais',
                                parseInt(value.target.value, 10),
                              )
                            }
                            fullWidth
                            style={{
                              margin: '0',
                              padding: '0',
                              width: '50px',
                            }}
                            styleInput={{
                              padding: '0',
                              textAlign: 'center',
                            }}
                          />
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            =
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {formatMoeda(
                              100 *
                                getValueNotasCorrigidas('cedulas_100_reais'),
                            )}
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                            padding: '5px',
                            borderRadius: '5px',
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            R$: 50,00
                          </span>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <IntegerInput
                            handleEnter={() => {}}
                            label=""
                            handleKey={() => {}}
                            value={
                              Number.isNaN(
                                getValueNotasCorrigidas('cedulas_50_reais'),
                              )
                                ? ''
                                : String(
                                    getValueNotasCorrigidas('cedulas_50_reais'),
                                  )
                            }
                            onChange={(value) =>
                              handleChangeNotasCorrigidas(
                                'cedulas_50_reais',
                                parseInt(value.target.value, 10),
                              )
                            }
                            fullWidth
                            style={{
                              margin: '0',
                              padding: '0',
                              width: '50px',
                            }}
                            styleInput={{
                              padding: '0',
                              textAlign: 'center',
                            }}
                          />
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            =
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {formatMoeda(
                              50 * getValueNotasCorrigidas('cedulas_50_reais'),
                            )}
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                            padding: '5px',
                            borderRadius: '5px',
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            R$: 20,00
                          </span>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <IntegerInput
                            handleEnter={() => {}}
                            label=""
                            handleKey={() => {}}
                            value={
                              Number.isNaN(
                                getValueNotasCorrigidas('cedulas_20_reais'),
                              )
                                ? ''
                                : String(
                                    getValueNotasCorrigidas('cedulas_20_reais'),
                                  )
                            }
                            onChange={(value) =>
                              handleChangeNotasCorrigidas(
                                'cedulas_20_reais',
                                parseInt(value.target.value, 10),
                              )
                            }
                            fullWidth
                            style={{
                              margin: '0',
                              padding: '0',
                              width: '50px',
                            }}
                            styleInput={{
                              padding: '0',
                              textAlign: 'center',
                            }}
                          />
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            =
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {formatMoeda(
                              20 * getValueNotasCorrigidas('cedulas_20_reais'),
                            )}
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                            padding: '5px',
                            borderRadius: '5px',
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            R$: 10,00
                          </span>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <IntegerInput
                            handleEnter={() => {}}
                            label=""
                            handleKey={() => {}}
                            value={
                              Number.isNaN(
                                getValueNotasCorrigidas('cedulas_10_reais'),
                              )
                                ? ''
                                : String(
                                    getValueNotasCorrigidas('cedulas_10_reais'),
                                  )
                            }
                            onChange={(value) =>
                              handleChangeNotasCorrigidas(
                                'cedulas_10_reais',
                                parseInt(value.target.value, 10),
                              )
                            }
                            fullWidth
                            style={{
                              margin: '0',
                              padding: '0',
                              width: '50px',
                            }}
                            styleInput={{
                              padding: '0',
                              textAlign: 'center',
                            }}
                          />
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            =
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {formatMoeda(
                              10 * getValueNotasCorrigidas('cedulas_10_reais'),
                            )}
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                            padding: '5px',
                            borderRadius: '5px',
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            R$: 5,00
                          </span>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <IntegerInput
                            handleEnter={() => {}}
                            label=""
                            handleKey={() => {}}
                            value={
                              Number.isNaN(
                                getValueNotasCorrigidas('cedulas_5_reais'),
                              )
                                ? ''
                                : String(
                                    getValueNotasCorrigidas('cedulas_5_reais'),
                                  )
                            }
                            onChange={(value) =>
                              handleChangeNotasCorrigidas(
                                'cedulas_5_reais',
                                parseInt(value.target.value, 10),
                              )
                            }
                            fullWidth
                            style={{
                              margin: '0',
                              padding: '0',
                              width: '50px',
                            }}
                            styleInput={{
                              padding: '0',
                              textAlign: 'center',
                            }}
                          />
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            =
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {formatMoeda(
                              5 * getValueNotasCorrigidas('cedulas_5_reais'),
                            )}
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                            padding: '5px',
                            borderRadius: '5px',
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            R$: 2,00
                          </span>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <IntegerInput
                            handleEnter={() => {}}
                            label=""
                            handleKey={() => {}}
                            value={
                              Number.isNaN(
                                getValueNotasCorrigidas('cedulas_2_reais'),
                              )
                                ? ''
                                : String(
                                    getValueNotasCorrigidas('cedulas_2_reais'),
                                  )
                            }
                            onChange={(value) =>
                              handleChangeNotasCorrigidas(
                                'cedulas_2_reais',
                                parseInt(value.target.value, 10),
                              )
                            }
                            fullWidth
                            style={{
                              margin: '0',
                              padding: '0',
                              width: '50px',
                            }}
                            styleInput={{
                              padding: '0',
                              textAlign: 'center',
                            }}
                          />
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            =
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {formatMoeda(
                              2 * getValueNotasCorrigidas('cedulas_2_reais'),
                            )}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                            padding: '5px',
                            borderRadius: '5px',
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            R$: 1,00
                          </span>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <IntegerInput
                            handleEnter={() => {}}
                            label=""
                            handleKey={() => {}}
                            value={
                              Number.isNaN(
                                getValueNotasCorrigidas('moedas_1_real'),
                              )
                                ? ''
                                : String(
                                    getValueNotasCorrigidas('moedas_1_real'),
                                  )
                            }
                            onChange={(value) =>
                              handleChangeNotasCorrigidas(
                                'moedas_1_real',
                                parseInt(value.target.value, 10),
                              )
                            }
                            fullWidth
                            style={{
                              margin: '0',
                              padding: '0',
                              width: '50px',
                            }}
                            styleInput={{
                              padding: '0',
                              textAlign: 'center',
                            }}
                          />
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            =
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {formatMoeda(
                              1 * getValueNotasCorrigidas('moedas_1_real'),
                            )}
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                            padding: '5px',
                            borderRadius: '5px',
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            R$: 0,50
                          </span>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <IntegerInput
                            handleEnter={() => {}}
                            label=""
                            handleKey={() => {}}
                            value={
                              Number.isNaN(
                                getValueNotasCorrigidas('moedas_50_centavos'),
                              )
                                ? ''
                                : String(
                                    getValueNotasCorrigidas(
                                      'moedas_50_centavos',
                                    ),
                                  )
                            }
                            onChange={(value) =>
                              handleChangeNotasCorrigidas(
                                'moedas_50_centavos',
                                parseInt(value.target.value, 10),
                              )
                            }
                            fullWidth
                            style={{
                              margin: '0',
                              padding: '0',
                              width: '50px',
                            }}
                            styleInput={{
                              padding: '0',
                              textAlign: 'center',
                            }}
                          />
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            =
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {formatMoeda(
                              0.5 *
                                getValueNotasCorrigidas('moedas_50_centavos'),
                            )}
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                            padding: '5px',
                            borderRadius: '5px',
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            R$: 0,25
                          </span>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <IntegerInput
                            handleEnter={() => {}}
                            label=""
                            handleKey={() => {}}
                            value={
                              Number.isNaN(
                                getValueNotasCorrigidas('moedas_25_centavos'),
                              )
                                ? ''
                                : String(
                                    getValueNotasCorrigidas(
                                      'moedas_25_centavos',
                                    ),
                                  )
                            }
                            onChange={(value) =>
                              handleChangeNotasCorrigidas(
                                'moedas_25_centavos',
                                parseInt(value.target.value, 10),
                              )
                            }
                            fullWidth
                            style={{
                              margin: '0',
                              padding: '0',
                              width: '50px',
                            }}
                            styleInput={{
                              padding: '0',
                              textAlign: 'center',
                            }}
                          />
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            =
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {formatMoeda(
                              0.25 *
                                getValueNotasCorrigidas('moedas_25_centavos'),
                            )}
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                            padding: '5px',
                            borderRadius: '5px',
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            R$: 0,10
                          </span>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <IntegerInput
                            handleEnter={() => {}}
                            label=""
                            handleKey={() => {}}
                            value={
                              Number.isNaN(
                                getValueNotasCorrigidas('moedas_10_centavos'),
                              )
                                ? ''
                                : String(
                                    getValueNotasCorrigidas(
                                      'moedas_10_centavos',
                                    ),
                                  )
                            }
                            onChange={(value) =>
                              handleChangeNotasCorrigidas(
                                'moedas_10_centavos',
                                parseInt(value.target.value, 10),
                              )
                            }
                            fullWidth
                            style={{
                              margin: '0',
                              padding: '0',
                              width: '50px',
                            }}
                            styleInput={{
                              padding: '0',
                              textAlign: 'center',
                            }}
                          />
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            =
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {formatMoeda(
                              0.1 *
                                getValueNotasCorrigidas('moedas_10_centavos'),
                            )}
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                            padding: '5px',
                            borderRadius: '5px',
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            R$: 0,05
                          </span>
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            X
                          </span>
                          <IntegerInput
                            handleEnter={() => {}}
                            label=""
                            handleKey={() => {}}
                            value={
                              Number.isNaN(
                                getValueNotasCorrigidas('moedas_5_centavos'),
                              )
                                ? ''
                                : String(
                                    getValueNotasCorrigidas(
                                      'moedas_5_centavos',
                                    ),
                                  )
                            }
                            onChange={(value) =>
                              handleChangeNotasCorrigidas(
                                'moedas_5_centavos',
                                parseInt(value.target.value, 10),
                              )
                            }
                            fullWidth
                            style={{
                              margin: '0',
                              padding: '0',
                              width: '50px',
                            }}
                            styleInput={{
                              padding: '0',
                              textAlign: 'center',
                            }}
                          />
                          <span
                            style={{
                              color: 'red',
                            }}
                          >
                            =
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {formatMoeda(
                              0.05 *
                                getValueNotasCorrigidas('moedas_5_centavos'),
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '10px',
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setPacoteAtual(null);
                          setNotasCorrigidas(null);
                        }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={totalAtual === 0}
                        onClick={() => {
                          addPacote({
                            ...pacoteAtual,
                            notas: notasCorrigidas,
                          });
                          setPacoteAtual(null);
                          setNotasCorrigidas(null);
                        }}
                      >
                        Confirmar
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div>Nenhum pacote selecionado</div>
                )}
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '10px',
                    }}
                  >
                    <div>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        Malote:{' '}
                      </span>
                      <span>{`${formatMoeda(totalMalote)}`}</span>
                    </div>
                    <div>
                      <span
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        Submalote:{' '}
                      </span>
                      <span>{`${formatMoeda(totalSubmalote)}`}</span>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      gap: '20px',
                      padding: '10px',
                      border: '1px solid black',
                      borderRadius: '10px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          gap: '5px',
                          backgroundColor: notasPermitidas.cedulas_200_reais
                            ? 'green'
                            : 'white',
                          color: notasPermitidas.cedulas_200_reais
                            ? 'white'
                            : 'black',
                          padding: '5px',
                          borderRadius: '5px',
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          R$: 200,00
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          X
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {cedulas_200_reais}
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          =
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {formatMoeda(200 * cedulas_200_reais)}
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: '5px',
                          backgroundColor: notasPermitidas.cedulas_100_reais
                            ? 'green'
                            : 'white',
                          color: notasPermitidas.cedulas_100_reais
                            ? 'white'
                            : 'black',
                          padding: '5px',
                          borderRadius: '5px',
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          R$: 100,00
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          X
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {cedulas_100_reais}
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          =
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {formatMoeda(100 * cedulas_100_reais)}
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: '5px',
                          backgroundColor: notasPermitidas.cedulas_50_reais
                            ? 'green'
                            : 'white',
                          color: notasPermitidas.cedulas_50_reais
                            ? 'white'
                            : 'black',
                          padding: '5px',
                          borderRadius: '5px',
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          R$: 50,00
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          X
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {cedulas_50_reais}
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          =
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {formatMoeda(50 * cedulas_50_reais)}
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: '5px',
                          backgroundColor: notasPermitidas.cedulas_20_reais
                            ? 'green'
                            : 'white',
                          color: notasPermitidas.cedulas_20_reais
                            ? 'white'
                            : 'black',
                          padding: '5px',
                          borderRadius: '5px',
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          R$: 20,00
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          X
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {cedulas_20_reais}
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          =
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {formatMoeda(20 * cedulas_20_reais)}
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: '5px',
                          backgroundColor: notasPermitidas.cedulas_10_reais
                            ? 'green'
                            : 'white',
                          color: notasPermitidas.cedulas_10_reais
                            ? 'white'
                            : 'black',
                          padding: '5px',
                          borderRadius: '5px',
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          R$: 10,00
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          X
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {cedulas_10_reais}
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          =
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {formatMoeda(10 * cedulas_10_reais)}
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: '5px',
                          backgroundColor: notasPermitidas.cedulas_5_reais
                            ? 'green'
                            : 'white',
                          color: notasPermitidas.cedulas_5_reais
                            ? 'white'
                            : 'black',
                          padding: '5px',
                          borderRadius: '5px',
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          R$: 5,00
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          X
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {cedulas_5_reais}
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          =
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {formatMoeda(5 * cedulas_5_reais)}
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: '5px',
                          backgroundColor: notasPermitidas.cedulas_2_reais
                            ? 'green'
                            : 'white',
                          color: notasPermitidas.cedulas_2_reais
                            ? 'white'
                            : 'black',
                          padding: '5px',
                          borderRadius: '5px',
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          R$: 2,00
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          X
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {cedulas_2_reais}
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          =
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {formatMoeda(2 * cedulas_2_reais)}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          gap: '5px',
                          backgroundColor: notasPermitidas.moedas_1_real
                            ? 'green'
                            : 'white',
                          color: notasPermitidas.moedas_1_real
                            ? 'white'
                            : 'black',
                          padding: '5px',
                          borderRadius: '5px',
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          R$: 1,00
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          X
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {moedas_1_real}
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          =
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {formatMoeda(1 * moedas_1_real)}
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: '5px',
                          backgroundColor: notasPermitidas.moedas_50_centavos
                            ? 'green'
                            : 'white',
                          color: notasPermitidas.moedas_50_centavos
                            ? 'white'
                            : 'black',
                          padding: '5px',
                          borderRadius: '5px',
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          R$: 0,50
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          X
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {moedas_50_centavos}
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          =
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {formatMoeda(0.5 * moedas_50_centavos)}
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: '5px',
                          backgroundColor: notasPermitidas.moedas_25_centavos
                            ? 'green'
                            : 'white',
                          color: notasPermitidas.moedas_25_centavos
                            ? 'white'
                            : 'black',
                          padding: '5px',
                          borderRadius: '5px',
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          R$: 0,25
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          X
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {moedas_25_centavos}
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          =
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {formatMoeda(0.25 * moedas_25_centavos)}
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: '5px',
                          backgroundColor: notasPermitidas.moedas_10_centavos
                            ? 'green'
                            : 'white',
                          color: notasPermitidas.moedas_10_centavos
                            ? 'white'
                            : 'black',
                          padding: '5px',
                          borderRadius: '5px',
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          R$: 0,10
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          X
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {moedas_10_centavos}
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          =
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {formatMoeda(0.1 * moedas_10_centavos)}
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: '5px',
                          backgroundColor: notasPermitidas.moedas_5_centavos
                            ? 'green'
                            : 'white',
                          color: notasPermitidas.moedas_5_centavos
                            ? 'white'
                            : 'black',
                          padding: '5px',
                          borderRadius: '5px',
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          R$: 0,05
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          X
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {moedas_5_centavos}
                        </span>
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          =
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {formatMoeda(0.05 * moedas_5_centavos)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <TextInput
                name="codigo"
                handleEnter={() => getPacote()}
                label="Código"
                handleKey={() => {}}
                value={codigo}
                onChange={(value) => setCodigo(value.target.value)}
                fullWidth
                autoFocus
                style={{
                  marginTop: '40px',
                }}
              />
              {pacotes.length > 0 && (
                <>
                  <h2
                    style={{
                      margin: '20px 0 10px',
                      textAlign: 'center',
                    }}
                  >
                    Pacotes
                  </h2>
                  <div>
                    <TableContainer
                      component={Paper}
                      style={{
                        height: '100%',
                        width: '100%',
                        overflow: 'auto',
                      }}
                    >
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Código</StyledTableCell>
                            <StyledTableCell>Tipo</StyledTableCell>
                            <StyledTableCell>Data</StyledTableCell>
                            <StyledTableCell>Responsável</StyledTableCell>
                            <StyledTableCell>Valor</StyledTableCell>
                            <StyledTableCell>Ações</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {pacotes.reverse().map((row) => (
                            <StyledTableRow key={row.codigo}>
                              <StyledTableCell component="th" scope="row">
                                {row.codigo}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {row.tipo}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {row.data}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {row.responsavel}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                {row.valor}
                              </StyledTableCell>
                              <StyledTableCell component="th" scope="row">
                                <IconButton
                                  aria-label="detalhes"
                                  size="small"
                                  onClick={() => removerPacote(row.codigo)}
                                >
                                  <DeleteOutlineIcon />
                                </IconButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px',
          }}
        >
          <Button
            onClick={etapaAnterior}
            color="secondary"
            variant="contained"
            tabindex="-1"
          >
            {etapa === 0
              ? `Fechar (${CANCELAR_VENDA_ACTION})`
              : `Voltar (${CANCELAR_VENDA_ACTION})`}
          </Button>
          <Button
            onClick={proximaEtapa}
            color="secondary"
            variant="contained"
            tabindex="-1"
            disabled={etapa === 0 ? disabledNextEtapa1 : false}
          >
            {etapa === 0
              ? `Próximo (${PROXIMO_ACTION})`
              : `Finalizar (${PROXIMO_ACTION})`}
          </Button>
        </DialogActions>
        <DialogoConfirmacao
          ref={refDialogoConfirmacao}
          handleConfirma={handleConfirma}
        />
      </Dialog>
    </div>
  );
});

export default DialogoContadorCedulas;
