import React, { useRef, useState, useEffect, useContext } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';

import { makeStyles, Box, Paper } from '@material-ui/core';

import { getListAllAPI } from '../../../../../../services';
import {
  POR_PESO_VARIAVEL,
  ATALHO_AVANCAR,
  ATALHO_VOLTAR,
  POR_UNIDADE,
  POR_PESO_FIXO,
  STATUS_ATIVO,
  getNomeProduto,
  toStringItem,
  formatItens,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
} from '../../../../../../utils';
import {
  DialogoDeletarComSenha,
  DialogoSenha,
  DialogoSomaPesos,
  SearchProduto,
  LabelCaixa,
  LabelSubtotal,
  LabelTeclasAtalho,
  Tabela,
  DialogoConsultorPrecos,
  DialogoPedido,
  LabelCaixaria,
  DialogoConfirmacao,
} from '../../../../components';
import { useItens } from '../../../../hooks';
import { VendaContext } from '../../context';
import FormItemVenda from './Form';

const useStyles = makeStyles((theme) => ({
  btn: {
    marginTop: '10px',
    opacity: '0.75',
  },
  header: {
    /*  height: '17%', */
  },
  body: {
    /* height: '83%', */
  },
  footer: {
    height: '15vh',
    padding: '10px',
    paddingBottom: '20px',
  },
  headerLivre: {
    height: '17%',
  },
  bodyLivre: {
    height: '83%',
  },
  rootProduto: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
    fontSize: '32px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    textAlign: 'center',
    opacity: '0.75',
  },
}));

const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const PESO_POR_PARTES_ACTION = 'F9';
const DELETAR_ITEM_ACTION = 'F2';
const CONSULTOR_PRECOS_ACTION = 'F3';
const CARREGAR_PEDIDO_ACTION = 'F7';
const MUDAR_CAIXARIA_ACTION = 'F10';

const FrenteVenda = ({ irParaTelaInit, irParaTelaFinalizarVenda }) => {
  const {
    venda: { itens, isEditPrice, idEdit },
    dispatch,
  } = useContext(VendaContext);
  const refDialogoSenha = useRef(null);
  const refDialogoPedido = useRef(null);
  const refDialogoDeletar = useRef(null);

  const refDialogoConsultorPrecos = useRef(null);
  const {
    searchHandle,
    addNewItem,
    setSearch,
    produto,
    subTotal,
    refSearch,
    search,
    refSidebarInputs,
    enqueueSnackbar,
    changeCaixaria,
  } = useItens(itens, updateItem);

  const refSidebarPesosPorPartes = useRef(null);
  const refDialogoConfirmacao = useRef(null);

  const classes = useStyles();

  const nomeProduto = getNomeProduto(produto, 'EDITAR VENDA');

  function updateItem(body) {
    dispatch({
      type: 'ADD_ITEM',
      item: body,
    });
    if (refSearch.current) refSearch.current.focus();
  }

  function removeItens(indices) {
    dispatch({ type: 'REMOVE_ITEM', indices });
  }

  function openConfirmacaoTelaInit() {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Deseja cancelar a edição dessa venda!',
        'Tem certeza que deseja cancelar a edição dessa venda!',
        10,
      );
    }
  }

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        if (itens.length > 0) {
          irParaTelaFinalizarVenda();
        } else {
          enqueueSnackbar('É necessário ao menos um item na venda!', {
            variant: 'warning',
          });
        }
        break;
      case CANCELAR_VENDA_ACTION:
        /* if (refDialogoSenha.current) { refDialogoSenha.current.handleOpen(); } */
        // irParaTelaInit();
        openConfirmacaoTelaInit();
        break;
      case CONSULTOR_PRECOS_ACTION:
        if (refDialogoConsultorPrecos.current) {
          refDialogoConsultorPrecos.current.handleOpen();
        }
        break;
      case CARREGAR_PEDIDO_ACTION:
        if (refDialogoPedido.current) {
          refDialogoPedido.current.handleOpen();
        }
        break;
      case PESO_POR_PARTES_ACTION:
        if (produto ? produto.unidade.tipo === POR_PESO_VARIAVEL : false) {
          if (refSidebarPesosPorPartes.current) {
            refSidebarPesosPorPartes.current.handleOpen();
          }
        }
        break;
      case DELETAR_ITEM_ACTION:
        if (itens.length > 0) {
          if (refDialogoDeletar.current) {
            refDialogoDeletar.current.handleOpen();
          }
        }
        break;
      case MUDAR_CAIXARIA_ACTION:
        changeCaixaria();
        break;
      default:
        break;
    }
  }

  async function handlePedido(id) {
    try {
      const data = await getListAllAPI(
        'pre_vendas',
        ['id', 'asc'],
        { id: [id] },
        ['itens.produto.unidade', 'cliente.cidade.estado', 'cliente.categoria'],
      );
      if (data.data.length <= 0) {
        throw 'Este pedido não existe!';
      }
      dispatch({
        type: 'PREENCHER_TUDO_PEDIDO',
        itens: formatItens(data.data[0].itens),
        cliente: data.data[0].cliente,
      });
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
    }
  }

  function handleConfirma(codigo) {
    switch (codigo) {
      case 10:
        irParaTelaInit();
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Box padding="10px" className={classes.header}>
        <Box margin="0px 0px 10px">
          <Paper className={classes.rootProduto}>{nomeProduto}</Paper>
        </Box>
        <SearchProduto
          label="Pesquisar produto"
          value={search}
          onChange={(e) => setSearch(e)}
          fullwidth
          searchHandle={searchHandle}
          ref={refSearch}
          handleKey={(keyCode, keyName) => {
            handleActions(keyName);
          }}
          filters={{ is_search: true, status: STATUS_ATIVO }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        padding="10px"
        className={classes.body}
      >
        <Box
          flex={1.5}
          display="flex"
          flexDirection="column"
          /* justifyContent="space-between" */
          overflow="auto"
        >
          <Box>
            {/*  <LabelCaixa /> */}
            <Box mt="0px">
              <LabelSubtotal valor={subTotal} />
            </Box>
          </Box>
          {produto && (
            <Box mt="15px">
              <LabelCaixaria produto={produto} />
            </Box>
          )}
          <LabelTeclasAtalho
            fontSize="13px"
            grid={[
              [
                { atalho: CANCELAR_VENDA_ACTION, label: 'Voltar' },
                { atalho: FECHAR_VENDA_ACTION, label: 'Concluir' },
                { atalho: CONSULTOR_PRECOS_ACTION, label: 'Cons. Preço' },
                {
                  atalho: MUDAR_CAIXARIA_ACTION,
                  label: 'Mudar Caixaria',
                  status: produto
                    ? produto.unidade.tipo === POR_UNIDADE ||
                      produto.unidade.tipo === POR_PESO_FIXO ||
                      produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
                    : false,
                },
              ],
              [
                {
                  atalho: PESO_POR_PARTES_ACTION,
                  label: 'CGP',
                  status: produto
                    ? produto.unidade.tipo === POR_PESO_VARIAVEL
                    : false,
                },
                {
                  atalho: DELETAR_ITEM_ACTION,
                  label: 'Remover item',
                  status: itens.length > 0,
                },
                { atalho: CARREGAR_PEDIDO_ACTION, label: 'Car. Pedido' },
              ],
            ]}
          />
        </Box>
        <Box padding="0 10px" flex={4}>
          <Tabela
            rows={toStringItem(itens)}
            headCells={[
              {
                field: 'numeroItem',
                label: 'Cód.',
              },
              {
                field: 'produto',
                label: 'Produto',
              },
              {
                field: 'unidades',
                right: true,
                label: 'Unidades',
              },
              {
                field: 'peso',
                right: true,
                label: 'Peso (Kg)',
              },
              {
                field: 'unitario',
                right: true,
                label: 'Unitário',
              },
              {
                field: 'total',
                right: true,
                label: 'SubTotal',
              },
            ]}
          />
        </Box>
        <Box flex={1.5} display="flex" flexDirection="column" overflow="auto">
          <FormItemVenda
            handleKey={(keyCode, keyName) => {
              handleActions(keyName);
            }}
            handleNewItem={addNewItem}
            disabledSubmit={produto === null}
            ref={refSidebarInputs}
            editPrice={isEditPrice}
            produto={produto}
            tipoTransacao="venda"
          />
        </Box>
      </Box>
      <DialogoSenha
        ref={refDialogoSenha}
        handleClose={() => {}}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={() => {
          irParaTelaInit();
        }}
        title="Autorização cancelamento da edição da venda!"
      />
      <DialogoDeletarComSenha
        ref={refDialogoDeletar}
        itens={itens}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={(numeroItem) => {
          removeItens([itens[numeroItem - 1].uidd]);
        }}
        title="Autorização para remover item!"
      />
      <DialogoConsultorPrecos ref={refDialogoConsultorPrecos} />
      <DialogoPedido ref={refDialogoPedido} handlePedido={handlePedido} />
      <DialogoSomaPesos
        ref={refSidebarPesosPorPartes}
        onChangeTotal={(totais) => {
          if (refSidebarInputs.current) {
            refSidebarInputs.current.redefineValues(
              totais.unidades,
              totais.peso,
            );
          }
        }}
      />
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
      <KeyboardEventHandler
        handleKeys={[
          FECHAR_VENDA_ACTION,
          CANCELAR_VENDA_ACTION,
          PESO_POR_PARTES_ACTION,
          DELETAR_ITEM_ACTION,
          CONSULTOR_PRECOS_ACTION,
          CARREGAR_PEDIDO_ACTION,
          MUDAR_CAIXARIA_ACTION,
        ]}
        onKeyEvent={(key) => handleActions(key)}
      />
    </>
  );
};

export default FrenteVenda;
