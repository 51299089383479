import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
    // color: 'red',
  },
}));

const DataNegociadaField = (props) => {
  const classes = useStyles();

  const record = useRecordContext(props);

  const value = record
    ? record.record.data_negociada
      ? moment(new Date(record.record.data_negociada)).format('DD/MM/YYYY')
      : '-'
    : '-';

  return <Typography className={classes.text}>{value}</Typography>;
};

export default DataNegociadaField;
