import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AlarmIcon from '@material-ui/icons/Alarm';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'notistack';

import {
  PrecoInput,
  DateTimeInputBonito,
  IntegerInput,
} from '../../../Components';
import {
  getListAllAPI,
  createAPI,
  updateAPI,
  getUserId,
  getManyAPI,
} from '../../../services';
import {
  POR_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  TIPO_UNIDADE_OFERTA_KG,
  TIPO_UNIDADE_OFERTA_UN,
  TIPO_UNIDADE_OFERTA_CAIXA,
  TIPO_UNIDADE_OFERTA_CAIXETA,
  TIPO_UNIDADE_OFERTA_CAIXA_MAE,
  getNivelCaixaQtde,
  getLabelTipoUnidade,
  getQuantidadeCaixariaLabel,
  getPrecoUnitarioVerdadeiroCaixa2,
  getOptionsTipoUnidadeOferta,
} from '../../../utils';
import DialogoIntervalo from '../DialogoIntervalo';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const TAMANHO_PEQUENO = 'sm';

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState(-1);
  const [produto, setProduto] = useState(null);
  const [valor, setValor] = useState(0);
  const [dataMinima, setDataMinima] = useState(new Date());
  const [dataMaxima, setDataMaxima] = useState(new Date());
  const [tipoUnidadeOferta, setTipoUnidadeUnidade] = useState(
    TIPO_UNIDADE_OFERTA_UN,
  );
  const [qtde, setQtde] = useState(0);

  const classes = useStyles();
  const [tamanho, setTamanho] = useState(TAMANHO_PEQUENO);
  const [carregando, setCarregando] = useState(false);

  const refDialogoIntervalo = useRef(null);

  const handleChange = (event) => {
    setTipoUnidadeUnidade(event.target.value);
  };

  const handleCloseDialog = (status, idProduto = -1) => {
    setOpen(false);
    handleClose(status, idProduto);
  };

  async function getProduto(id) {
    const data = await getManyAPI(
      'produtos/search/autocomplete',
      { id: [id], ofertasProdutos: true },
      ['unidade'],
    );
    if (data.data.length > 0) {
      const produto = data.data[0];
      return {
        ...produto,
        preco_oferta: getPrecoUnitarioVerdadeiroCaixa2(
          { ...produto, nivelCaixa: 0 },
          0,
          0,
        ),
      };
    }
    throw 'Este produto não existe!';
  }

  function getUnidadeInicial(produto) {
    if (produto) {
      const tipo = produto.unidade.tipo;
      switch (tipo) {
        case POR_UNIDADE:
          return TIPO_UNIDADE_OFERTA_UN;
        case POR_PESO_FIXO:
          return TIPO_UNIDADE_OFERTA_UN;
        case POR_PESO_VARIAVEL:
          return TIPO_UNIDADE_OFERTA_KG;
        case POR_PESO_VARIAVEL_SEM_UNIDADE:
          return TIPO_UNIDADE_OFERTA_KG;
        default:
          return TIPO_UNIDADE_OFERTA_UN;
      }
    }
    return TIPO_UNIDADE_OFERTA_UN;
  }

  async function reiniciar(produto) {
    try {
      const dataMinimaInitial = new Date();
      const timeNovo = dataMinimaInitial.getTime() + 30 * 60 * 1000;
      const novoData = new Date();
      novoData.setTime(timeNovo);
      setId(-1);
      setProduto(await getProduto(produto.id));
      setValor(0);
      setDataMinima(dataMinimaInitial);
      setDataMaxima(novoData);
      setTamanho(TAMANHO_PEQUENO);
      setQtde(0);
      setTipoUnidadeUnidade(getUnidadeInicial(produto));
      setCarregando(false);
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(0);
    }
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'ofertas_liberacao_produtos',
        ['id', 'asc'],
        { id: [id] },
        [],
      );
      if (data.data.length > 0) {
        setProduto(await getProduto(data.data[0].produto_id));
        setValor(data.data[0].valor);
        setDataMinima(new Date(data.data[0].data_minima));
        setDataMaxima(new Date(data.data[0].data_maxima));
        setTamanho(TAMANHO_PEQUENO);
        //setEscalonadas(data.data[0].escalonadas);
        setQtde(data.data[0].escalonadas[0].quantidade);
        setTipoUnidadeUnidade(data.data[0].tipo_unidade);
        setCarregando(false);
      } else {
        throw 'Esta Liberação de Preço não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(0);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      if (id <= 0) {
        await createAPI('ofertas_liberacao_produtos', {
          cliente_id: null,
          produto_id: produto.id,
          data_minima: dataMinima,
          data_maxima: dataMaxima,
          user_id: getUserId(),
          valor,
          escalonadas: [
            {
              id: 1,
              valor,
              quantidade: qtde,
            },
          ],
          tipo_unidade: tipoUnidadeOferta,
        });
        setCarregando(false);
        enqueueSnackbar(`Liberação de Preço criada com sucesso!`, {
          variant: 'success',
        });
      } else {
        await updateAPI('ofertas_liberacao_produtos', id, {
          cliente_id: null,
          produto_id: produto.id,
          data_minima: dataMinima,
          data_maxima: dataMaxima,
          user_id: getUserId(),
          valor,
          escalonadas: [
            {
              id: 1,
              valor,
              quantidade: qtde,
            },
          ],
          tipo_unidade: tipoUnidadeOferta,
        });
        setCarregando(false);
        enqueueSnackbar(`Liberação de Preço atualizada com sucesso!`, {
          variant: 'success',
        });
      }
      handleCloseDialog(0, produto.id);
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(0);
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate(produto = null) {
      setCarregando(true);
      reiniciar(produto);
      setOpen(true);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setOpen(true);
      setId(id);
    },
  }));

  function validateProduto() {
    let error = '';
    if (!produto) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateValor() {
    let error = '';
    if (valor <= 0 || Number.isNaN(valor)) {
      error = 'Este campo é obrigatório';
    } else if (produto) {
      if (produto.preco_venda > produto.preco_oferta) {
        if (valor >= produto.preco_oferta) {
          error =
            'Este valor não pode ser maior ou igual que o preço da oferta!';
        }
      } else if (valor >= produto.preco_venda) {
        error =
          'Este valor não pode ser maior ou igual que o preço original de venda!';
      }
    }
    return error;
  }

  function validateQuantidade() {
    let error = '';
    if (qtde <= 0 || Number.isNaN(qtde)) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function getErros() {
    const errosOld = ['', ''];
    errosOld[0] = validateProduto();
    errosOld[1] = validateValor();
    errosOld[2] = validateQuantidade();
    return errosOld;
  }

  const erros = getErros();

  function hasError() {
    for (let i = 0; i < erros.length; i += 1) {
      if (erros[i] !== '') return true;
    }
    return false;
  }

  const erroExistente = hasError();

  const nomeProduto = produto ? produto.nome : '';

  const optionsTipoUnidadeOferta = getOptionsTipoUnidadeOferta(produto);

  function valorMenorMultiplo15(valor) {
    const resto = valor % 15;

    return valor - resto;
  }

  function addTempo() {
    const timeInicio = dataMinima.getTime();
    const timeFim = dataMaxima.getTime();

    const minutosDiferenca = parseInt((timeFim - timeInicio) / (60 * 1000), 10);

    let novosMinutos = minutosDiferenca + 15;

    novosMinutos = novosMinutos > 120 ? 15 : valorMenorMultiplo15(novosMinutos);

    const timeNovo = dataMinima.getTime() + novosMinutos * 60 * 1000;
    const novoData = new Date();
    novoData.setTime(timeNovo);
    setDataMaxima(novoData);
  }

  function getIntervaloAtual() {
    const timeInicio = dataMinima.getTime();
    const timeFim = dataMaxima.getTime();

    const minutos = parseInt((timeFim - timeInicio) / (60 * 1000), 10);

    return minutos;
  }

  function editarTempo() {
    if (refDialogoIntervalo.current) {
      refDialogoIntervalo.current.handleOpen(getIntervaloAtual());
    }
  }

  const isOferta = produto ? produto.preco_venda > produto.preco_oferta : false;

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullWidth
        maxWidth={tamanho}
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={() => handleCloseDialog(0)}
          style={{ backgroundColor: '#00796b', color: 'white' }}
        >
          {id <= 0 ? `Nova Liberação de preço` : `Editar Liberação de preço`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '20px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                {produto && (
                  <>
                    <div
                      style={{
                        marginTop: '20px',
                      }}
                    >
                      <h2
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        {nomeProduto}
                      </h2>
                    </div>
                    <div>
                      <div style={{ marginTop: '15px' }}>
                        <PrecoInput
                          name="precoVenda"
                          handleEnter={() => {}}
                          label="Preço de Venda"
                          handleKey={() => {}}
                          value={
                            Number.isNaN(produto.preco_venda)
                              ? ''
                              : String(produto.preco_venda)
                          }
                          onChange={(value) => {}}
                          fullWidth
                          variant="standard"
                          disabled
                        />
                      </div>
                      {isOferta && (
                        <div style={{ marginTop: '15px' }}>
                          <PrecoInput
                            name="precoOferta"
                            handleEnter={() => {}}
                            label="Preço de Oferta"
                            handleKey={() => {}}
                            value={
                              Number.isNaN(produto.preco_oferta)
                                ? ''
                                : String(produto.preco_oferta)
                            }
                            onChange={(value) => {}}
                            fullWidth
                            variant="standard"
                            disabled
                          />
                        </div>
                      )}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: '15px',
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          style={{
                            fontSize: '15px',
                            //marginRight: '10px',
                          }}
                        >
                          Tipo Unidade:
                        </Typography>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={tipoUnidadeOferta}
                          onChange={handleChange}
                        >
                          {optionsTipoUnidadeOferta.map(({ label, value }) => (
                            <MenuItem value={value}>{label}</MenuItem>
                          ))}
                        </Select>
                      </div>
                      <div style={{ marginTop: '0px' }}>
                        <PrecoInput
                          name="precoOferta"
                          handleEnter={() => {}}
                          label="Preço da liberação"
                          handleKey={() => {}}
                          value={Number.isNaN(valor) ? '' : String(valor)}
                          onChange={(value) => {
                            setValor(parseFloat(value.target.value));
                          }}
                          error={erros[1] !== ''}
                          helperText={erros[1]}
                          fullWidth
                          variant="standard"
                          autoFocus
                        />
                      </div>
                      <Box flex={1} mr="0.5em">
                        <IntegerInput
                          name="quantidade"
                          handleEnter={() => {}}
                          label={`Quantidade mínima (${getLabelTipoUnidade(
                            tipoUnidadeOferta,
                          )})`}
                          handleKey={() => {}}
                          value={Number.isNaN(qtde) ? '' : String(qtde)}
                          onChange={(value) =>
                            setQtde(parseInt(value.target.value, 10))
                          }
                          error={erros[2] !== ''}
                          helperText={erros[2]}
                          variant="standard"
                          fullWidth
                        />
                      </Box>
                      <div style={{ marginTop: '15px' }}>
                        <DateTimeInputBonito
                          showTodayButton
                          todayLabel="Hoje"
                          name="dataMinima"
                          handleEnter={() => {}}
                          label="Horário início da liberação de preço"
                          handleKey={() => {}}
                          value={dataMinima}
                          onChange={(value) => setDataMinima(value)}
                          format="dd MMMM yyyy - HH:mm"
                          fullWidth
                          variant="standard"
                          maxDate={dataMaxima}
                          disabled
                        />
                      </div>
                      <div style={{ marginTop: '25px', display: 'flex' }}>
                        <DateTimeInputBonito
                          showTodayButton
                          todayLabel="Hoje"
                          name="dataLimite"
                          handleEnter={() => {}}
                          label="Horário limite da liberação de preço"
                          handleKey={() => {}}
                          value={dataMaxima}
                          onChange={(value) => {
                            if (value.getTime() > dataMinima.getTime()) {
                              setDataMaxima(value);
                            }
                          }}
                          format="dd MMMM yyyy - HH:mm"
                          fullWidth
                          variant="standard"
                          minDate={
                            new Date().getTime() > dataMinima.getTime()
                              ? new Date()
                              : dataMinima
                          }
                          disabled
                        />
                        <Tooltip
                          title="Mudar para próximos 15 minutos"
                          placement="bottom"
                          aria-label="add2"
                        >
                          <IconButton
                            aria-label="editar"
                            size="small"
                            onClick={() => addTempo()}
                          >
                            <AlarmIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Editar período"
                          placement="bottom"
                          aria-label="add2"
                        >
                          <IconButton
                            aria-label="editar"
                            size="small"
                            onClick={() => editarTempo()}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={() => handleCloseDialog(id)}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
            variant="contained"
            color="primary"
          >
            Cancelar
          </Button>
          <Box>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={erroExistente || carregando}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Box>
        </DialogActions>
        <DialogoIntervalo
          ref={refDialogoIntervalo}
          handleSalvar={(value) => {
            const timeNovo = dataMinima.getTime() + value * 60 * 1000;
            const novoData = new Date();
            novoData.setTime(timeNovo);
            setDataMaxima(novoData);
          }}
        />
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
