import React, { useState, useRef, useEffect, useContext } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';

import { Box } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import {
  DialogoConfirmacao,
  DialogoNota,
  DialogoFornecedor,
  Table,
  AutoCompleteRemoto,
  Actions,
  DialogoDeletarItemTabela,
  LabelSubtotal,
  LabelTotalParcelas,
  AutoCompleteLocal,
} from '../../../..';
import logo from '../../../../../assets/logo.png';
import { getListAllAPI, getUsername } from '../../../../../services';
import {
  formatCPF,
  formatCNPJ,
  formatMoeda,
  STATUS_ATIVO,
  toStringParcelaCompra,
} from '../../../../../utils';
import {
  LabelTeclasAtalho,
  Tabela,
  DialogoDeletarComSenha,
} from '../../../../DialogoEditarVenda/components';
import { useParcelasCompra } from '../../hooks';
import { CompraContext } from '../contextCompra';
import ContainerNumeroNotaFiscalInput from './ContainerNumeroNotaFiscalInput';
import FormParcelaCompra from './FormParcelaCompra';

const useStyles = makeStyles((theme) => ({
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textField: {},
  btnImprimir: {
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.4,
  },
  header: {
    /*  height: '17%', */
  },
  body: {
    /* height: '83%', */
  },
  footer: {
    height: '15vh',
    padding: '10px',
    paddingBottom: '20px',
  },
}));

const VOLTAR_ACTION = 'F4';
const FINALIZAR_ACTION = 'F8';
const NOVO_FORNECEDOR_ACTION = 'F9';
const DELETAR_PARCELA_ACTION = 'F2';
const EDITAR_FORNECEDOR_ACTION = 'F7';
const IMPRIMIR_ACTION = 'F10';

const optionsLoja = [
  {
    nome: 'CARUARU FRIOS',
    value: 'CF',
  },
  {
    nome: 'CASA DA PIZZA',
    value: 'CP',
  },
];

const FinalizarCompra = ({
  editarModo = false,
  irParaTelaInit,
  irParaTelaFrentedeCaixa,
  showAtendente = true,
}) => {
  const classes = useStyles();
  const { compra, dispatch } = useContext(CompraContext);
  const {
    fornecedor,
    parcelas,
    idEdit,
    numeroNotaFiscal,
    loja,
    data_faturamento,
    data_criacao,
  } = compra;
  const [atendente, setAtendente] = useState('');
  const refSidebarInputs = useRef(null);
  const refFornecedor = useRef(null);
  const refNotaFiscal = useRef(null);
  const refLoja = useRef(null);

  const refDialogoFornecedor = useRef(null);
  const refDialogoDeletar = useRef(null);
  const refDialogoConfirmacao = useRef(null);

  const {
    resto,
    totalParcelas,
    handleOpenNota,
    refDialogoNota,
    handleNewItem,
    handleOpenNotaLocal,
    enqueueSnackbar,
    podeFecharCompra,
  } = useParcelasCompra(
    parcelas,
    compra.lotes,
    fornecedor,
    compra.subTotal,
    updateParcela,
    editarModo,
    idEdit,
    numeroNotaFiscal,
    loja,
    data_faturamento,
    editarPrazosFornecedor,
  );

  function updateParcela(body) {
    dispatch({
      type: 'ADD_PARCELA',
      parcela: body,
    });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (refFornecedor.current) {
        refFornecedor.current.focus();
        refFornecedor.current.select();
      }
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  /*   useEffect(() => {
    if (compra.lotes.length <= 0) irParaTelaFrentedeCaixa();
  }, [compra.lotes]); */

  async function getAtendente() {
    const username = getUsername();
    setAtendente(username || '');
  }

  useEffect(() => {
    getAtendente();
  }, []);

  function removeItens(indices) {
    dispatch({ type: 'REMOVE_PARCELA', indices });
  }

  const handleClose = () => {
    irParaTelaFrentedeCaixa();
  };

  function closeDialogoNota(tipoNota) {
    if (tipoNota === 0) {
      irParaTelaInit();
    }
  }

  function handleActions(action) {
    switch (action) {
      case VOLTAR_ACTION:
        handleClose();
        break;
      case FINALIZAR_ACTION:
        if (podeFecharCompra) {
          openConfirmacao();
        }
        break;
      case NOVO_FORNECEDOR_ACTION:
        // if (refDialogoFornecedor.current) refDialogoFornecedor.current.handleOpen(0);
        if (refDialogoFornecedor.current)
          refDialogoFornecedor.current.handleCreate();
        break;
      case DELETAR_PARCELA_ACTION:
        if (parcelas.length > 0) {
          if (refDialogoDeletar.current) {
            refDialogoDeletar.current.handleOpen();
          }
        }
        break;
      case EDITAR_FORNECEDOR_ACTION:
        // if (refDialogoFornecedor.current) refDialogoFornecedor.current.handleOpen(1);
        if (refDialogoFornecedor.current) {
          const idFornecedor = fornecedor ? fornecedor.id : 0;
          if (idFornecedor > 0) {
            refDialogoFornecedor.current.handleEdit(idFornecedor, 0);
          }
        }
        break;
      case IMPRIMIR_ACTION:
        handleOpenNotaLocal();
        break;
      default:
        break;
    }
  }

  function editarPrazosFornecedor() {
    const idFornecedor = fornecedor ? fornecedor.id : 0;
    if (idFornecedor > 0) {
      refDialogoFornecedor.current.handleEdit(idFornecedor, 4);
    }
  }

  function handleConfirma(codigo) {
    switch (codigo) {
      case 0:
        handleOpenNota();
        break;
      default:
        break;
    }
  }

  function openConfirmacao() {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        idEdit > 0 ? 'Fechamento da edição da compra' : 'Fechamento da compra',
        idEdit > 0
          ? 'Tem certeza que deseja finalizar a edição da compra!'
          : 'Tem certeza que deseja finalizar a compra!',
        0,
      );
    }
  }

  async function updateFornecedor(idUpdate) {
    try {
      const data = await getListAllAPI(
        'fornecedores',
        ['id', 'asc'],
        { id: [idUpdate] },
        ['cidade.estado'],
      );
      if (data.data.length > 0) {
        dispatch({ type: 'UPDATE_FORNECEDOR', fornecedor: data.data[0] });
      } else {
        throw 'Este fornecedor não existe!';
      }
    } catch (erros) {
      // nada
    }
  }

  function gerLabelPrazosFornecedor(fornecedor) {
    let prazoLabel = 'Nenhum prazo cadastrado!';
    if (fornecedor) {
      if (fornecedor.prazosFornecedores) {
        if (fornecedor.prazosFornecedores.length > 0) {
          prazoLabel = fornecedor.prazosFornecedores
            .sort((a, b) => a.dias - b.dias)
            .map((item) => item.dias)
            .join(', ');
        }
      }
    }
    return prazoLabel;
  }

  const prazoLabel = gerLabelPrazosFornecedor(fornecedor);

  return (
    <>
      <Box padding="10px" className={classes.header}>
        <Box
          display="flex"
          css={{
            backgroundColor: 'white',
            opacity: '0.75',
            height: '100%',
            padding: '0px 10px',
            flexDirection: 'column',
          }}
        >
          <Box display="flex" alignItems="center" flex={1}>
            <AutoCompleteRemoto
              id="fornecedor"
              name="fornecedor"
              label="Responsável"
              value={fornecedor}
              onChange={(value) =>
                dispatch({ type: 'UPDATE_FORNECEDOR', fornecedor: value })
              }
              handleKey={(keyCode, keyName) => {
                handleActions(keyName);
              }}
              handleEnter={() => {
                if (refNotaFiscal.current) {
                  refNotaFiscal.current.focus();
                  refNotaFiscal.current.select();
                }
              }}
              resource="fornecedores"
              nested={['cidade.estado', 'prazosFornecedores']}
              toString={false}
              style={{ width: '200px' }}
              fullwidth={false}
              autoFocus
              filters={{ status: STATUS_ATIVO }}
              ref={refFornecedor}
              getOptionSelected={(option, value) => option.nome === value.nome}
              getOptionLabel={(option) =>
                `${option.nome}${
                  option.nome_fantasia ? ` - (${option.nome_fantasia})` : ''
                }`
              }
            />
            <Box marginLeft="10px">
              <AutoCompleteRemoto
                id="fornecedor"
                name="fornecedor"
                label="Nome Fantasia"
                value={fornecedor}
                onChange={(value) =>
                  dispatch({ type: 'UPDATE_FORNECEDOR', fornecedor: value })
                }
                handleKey={(keyCode, keyName) => {
                  handleActions(keyName);
                }}
                handleEnter={() => {
                  if (refNotaFiscal.current) {
                    refNotaFiscal.current.focus();
                    refNotaFiscal.current.select();
                  }
                }}
                resource="fornecedores"
                campo="nome_fantasia"
                nested={['cidade.estado', 'prazosFornecedores']}
                toString={false}
                style={{ width: '200px' }}
                fullwidth={false}
                filters={{ status: STATUS_ATIVO }}
                getOptionSelected={(option, value) =>
                  option.nome_fantasia === value.nome_fantasia
                }
                getOptionLabel={(option) =>
                  `${option.nome_fantasia ? `(${option.nome_fantasia})` : ''}`
                }
              />
            </Box>
            <Box marginLeft="10px">
              <ContainerNumeroNotaFiscalInput
                ref={refNotaFiscal}
                handleKey={(keyCode, keyName) => {
                  handleActions(keyName);
                }}
                handleEnter={() => {
                  if (refLoja.current) {
                    refLoja.current.focus();
                    refLoja.current.select();
                  }
                }}
              />
            </Box>
            <Box marginLeft="10px">
              <AutoCompleteLocal
                id="loja"
                name="loja"
                label="Loja"
                options={optionsLoja}
                value={loja}
                onChangeLocal={(value) =>
                  dispatch({ type: 'UPDATE_LOJA', loja: value })
                }
                handleKey={(keyCode, keyName) => {
                  handleActions(keyName);
                }}
                handleEnter={() => {
                  if (refSidebarInputs.current)
                    refSidebarInputs.current.focus();
                }}
                style={{ width: '200px' }}
                fullwidth={false}
                ref={refLoja}
              />
            </Box>
            {fornecedor && (
              <Box display="flex" justifyContent="space-between" flex={1}>
                <Box
                  marginLeft="10px"
                  display="flex"
                  flexDirection="column"
                  flex={1}
                >
                  <Box
                    marginLeft="0px"
                    fontSize="15px"
                    fontWeight="bold"
                    display="flex"
                    flex={1}
                    alignItems="center"
                  >
                    <p
                      style={{
                        marginBottom: '0px',
                        color: 'red',
                        marginRight: '10px',
                        marginTop: '0px',
                      }}
                    >
                      Razão Social:
                    </p>
                    {fornecedor.razao_social}
                  </Box>
                  <Box
                    marginLeft="0px"
                    fontSize="15px"
                    fontWeight="bold"
                    display="flex"
                    flex={1}
                    alignItems="center"
                  >
                    <p
                      style={{
                        marginBottom: '0px',
                        color: 'red',
                        marginRight: '10px',
                        marginTop: '0px',
                      }}
                    >
                      CPF:
                    </p>
                    {formatCPF(fornecedor.cpf)}
                  </Box>
                  <Box
                    marginLeft="0px"
                    fontSize="15px"
                    fontWeight="bold"
                    display="flex"
                    flex={1}
                    alignItems="center"
                  >
                    <p
                      style={{
                        marginBottom: '0px',
                        color: 'red',
                        marginRight: '10px',
                        marginTop: '0px',
                      }}
                    >
                      CNPJ:
                    </p>
                    {formatCNPJ(fornecedor.cnpj)}
                  </Box>
                  <Box
                    marginLeft="0px"
                    fontSize="15px"
                    fontWeight="bold"
                    display="flex"
                    flex={1}
                    alignItems="center"
                  >
                    <p
                      style={{
                        marginBottom: '0px',
                        color: 'red',
                        marginRight: '10px',
                        marginTop: '0px',
                      }}
                    >
                      Prazos:
                    </p>
                    {prazoLabel}
                  </Box>
                </Box>
                {/* <Box marginLeft="10px" display="flex" flexDirection="column" flex={1}>
                <Box
                marginLeft="0px"
                fontSize="15px"
                fontWeight="bold"
                display="flex" flex={1} justifyContent="flex-start">
                  <p style={{ marginBottom: '0px', color: 'red', marginRight: '10px' }}>Bairro:</p>
                  {fornecedor.bairro}
                </Box>
                <Box
                marginLeft="0px"
                fontSize="15px"
                fontWeight="bold" display="flex" flex={1} justifyContent="flex-start">
                  <p style={{ marginBottom: '0px', color: 'red', marginRight: '10px' }}>Cidade:</p>
                  {fornecedor.cidade ?
                    `${fornecedor.cidade.nome} - ${fornecedor.cidade.estado.sigla}` : ''}
                </Box>
                <Box
                marginLeft="0px"
                fontSize="15px"
                 fontWeight="bold" display="flex" flex={1} justifyContent="flex-start">
                  <p style={{ marginBottom: '0px', color: 'red', marginRight: '10px' }}>
                    Logradouro:
                  </p>
                  {fornecedor.logradouro}
                </Box>
              </Box> */}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        padding="10px"
        className={classes.body}
      >
        <Box flex={1.5} display="flex" flexDirection="column" overflow="auto">
          <div style={{ marginBottom: '15px' }}>
            <Actions
              opcoes={[
                {
                  text: `Voltar (${VOLTAR_ACTION})`,
                  value: VOLTAR_ACTION,
                  hide: false,
                  disabled: false,
                },
                {
                  text: `Finalizar (${FINALIZAR_ACTION})`,
                  value: FINALIZAR_ACTION,
                  hide: false,
                  disabled: resto > 10 || resto < -10,
                  className: resto > 10 || resto < -10 ? '' : 'btnImprimir',
                  // disabled: resto !== 0,
                  // className: resto !== 0 ? '' : 'btnImprimir',
                },
                {
                  text: `Novo Forn. (${NOVO_FORNECEDOR_ACTION})`,
                  value: NOVO_FORNECEDOR_ACTION,
                  hide: false,
                  disabled: false,
                },
                {
                  text: `Editar Forn. (${EDITAR_FORNECEDOR_ACTION})`,
                  value: EDITAR_FORNECEDOR_ACTION,
                  hide: false,
                  disabled:
                    fornecedor === null
                      ? true
                      : fornecedor.id === 1 || fornecedor.id === 2,
                },
                /*  {
                text: `Imprimir (${IMPRIMIR_ACTION})`,
                value: IMPRIMIR_ACTION,
                hide: false,
                disabled: false,
              }, */
                {
                  text: `Del. Parcela (${DELETAR_PARCELA_ACTION})`,
                  value: DELETAR_PARCELA_ACTION,
                  hide: false,
                  disabled: !(parcelas.length > 0),
                },
              ]}
              onClick={handleActions}
            />
          </div>
          <LabelSubtotal valor={compra.subTotal} isOpaco />
          <LabelTotalParcelas total={totalParcelas} totalRestante={resto} />
          {/* <LabelTeclasAtalho
            fontSize="12px"
            grid={[
              [
                { atalho: VOLTAR_ACTION, label: 'Voltar' },
                {
                  atalho: FINALIZAR_ACTION, label: 'Finalizar', status: !(resto > 10 || resto < -10), class: resto > 10 || resto < -10 ? '' : 'btnImprimir',
                },
                { atalho: NOVO_FORNECEDOR_ACTION, label: 'Novo Forn.' },
              ],
              [
                { atalho: EDITAR_FORNECEDOR_ACTION, label: 'Editar Forn.', status: fornecedor === null ? false : !(fornecedor.id === 1 || fornecedor.id === 2) },
                { atalho: IMPRIMIR_ACTION, label: 'Imprimir' },
                { atalho: DELETAR_PARCELA_ACTION, label: 'Del. Parcela', status: parcelas.length > 0 },
              ],
            ]}
          /> */}
        </Box>
        <Box padding="0 10px" flex={4}>
          {/* <Table
            rows={parcelas}
            removeItens={removeItens}
            headCells={[
              {
                id: 'conta', numeric: true, disablePadding: false, label: 'Conta/Cartão',
              },
              {
                id: 'fatura', numeric: true, disablePadding: false, label: 'Fatura',
              },
              {
                id: 'valor', numeric: true, disablePadding: false, label: 'Valor',
              },
              {
                id: 'data_pagamento',
                numeric: true,
                disablePadding: false,
                label: 'Data de Pagamento',
              },
              {
                id: 'status',
                numeric: true,
                disablePadding: false,
                label: 'Status',
              },
            ]}
            toString={toStringParcelaCompra}
          /> */}
          <Tabela
            rows={toStringParcelaCompra(parcelas, fornecedor, data_criacao)}
            headCells={[
              {
                field: 'numeroParcela',
                label: 'Cód.',
              },
              {
                field: 'data_pagamento',
                label: 'Data Original',
                right: true,
              },
              {
                field: 'data_provavel',
                label: 'Data Provável',
                right: true,
              },
              {
                field: 'diferenca_dias',
                label: 'Diferença Provável Original',
                right: true,
              },
              {
                field: 'conta',
                label: 'Conta/Cartão',
              },
              {
                field: 'fatura',
                label: 'Fatura',
                right: true,
              },
              {
                field: 'valor',
                label: 'Valor',
                right: true,
              },
              {
                field: 'status',
                label: 'Status',
                right: true,
              },
              {
                field: 'codigo_boleto',
                label: 'Boleto',
                right: true,
              },
              {
                field: 'problema',
                label: 'Problema',
                right: true,
              },
              {
                field: 'agendamento',
                label: 'Agendamento',
                right: true,
              },
              {
                field: 'desconto',
                label: 'Desconto',
                right: true,
              },
            ]}
          />
        </Box>
        <Box flex={1.5} display="flex" flexDirection="column">
          <FormParcelaCompra
            handleKey={(keyCode, keyName) => {
              handleActions(keyName);
            }}
            ref={refSidebarInputs}
            handleNewItem={handleNewItem}
            resto={resto}
          />
        </Box>
      </Box>
      <KeyboardEventHandler
        handleKeys={[
          VOLTAR_ACTION,
          FINALIZAR_ACTION,
          NOVO_FORNECEDOR_ACTION,
          DELETAR_PARCELA_ACTION,
          EDITAR_FORNECEDOR_ACTION,
          IMPRIMIR_ACTION,
        ]}
        onKeyEvent={(key) => handleActions(key)}
      />
      <DialogoNota
        logo={logo}
        ref={refDialogoNota}
        handleClose={closeDialogoNota}
      />
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
      <DialogoFornecedor
        // logo={logo}
        ref={refDialogoFornecedor}
        /* handleCriarCliente={(clienteAtualizado) => {
          dispatch({ type: 'UPDATE_FORNECEDOR', fornecedor: clienteAtualizado });
        }}
        handleCloseDialogo={() => {
          if (refSidebarInputs.current) refSidebarInputs.current.focus();
        }}
        cliente={fornecedor}
        isCliente={false} */
        handleClose={(idUpdate) => {
          if (refSidebarInputs.current) refSidebarInputs.current.focus();
          if (idUpdate > 0) {
            updateFornecedor(idUpdate);
          }
        }}
      />
      {/* <DialogoDeletarItemTabela
        ref={refDialogoDeletar}
        itens={parcelas}
        labelResource="Parcelas"
        handleDelete={(indices) => removeItens(indices)}
        headerDialogo={(
          <div style={{
            display: 'flex', justifyContent: 'space-between', paddingLeft: '56px', marginBottom: '20px', color: 'red',
          }}
          >
            <div style={{ flex: 4 }}>
              Conta / Cartão
            </div>
            <div style={{ flex: 4 }}>
              Fatura
            </div>
            <div style={{ flex: 1 }}>
              valor
            </div>
            <div style={{ flex: 1 }}>
              Data de pagamento
            </div>
            <div style={{ flex: 1 }}>
              Status
            </div>
          </div>
          )}
        getListItemContent={(value, labelId) => (
          <ListItemText
            id={labelId}
            primary={(
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flex: 4 }}>
                  {value.isCartao ? value.cartao.nome : value.conta.nome}
                </div>
                <div style={{ flex: 1 }}>
                  {value.isCartao ? value.fatura.nome : '-'}
                </div>
                <div style={{ flex: 1 }}>
                  {formatMoeda(value.valor)}
                </div>
                <div style={{ flex: 1 }}>
                  {moment(value.data_pagamento).format('DD/MM/YYYY')}
                </div>
                <div style={{ flex: 1 }}>
                  {value.isCartao ? '-' : (value.status ? 'Pago' : 'Não Pago')}
                </div>
              </div>
            )}
          />
        )}
      /> */}
      <DialogoDeletarComSenha
        ref={refDialogoDeletar}
        itens={parcelas}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={(numeroItem) => {
          const parcelaRemove = parcelas[numeroItem - 1];
          if (
            parcelaRemove.conta.id === 1 &&
            parcelaRemove.status &&
            editarModo
          ) {
            ///
          } else {
            removeItens([parcelaRemove.uidd]);
          }
        }}
        title="Autorização para remover item!"
      />
    </>
  );
};

export default FinalizarCompra;
