import React from 'react';
import {
  Filter as FilterAdmin,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  BooleanInput,
} from 'react-admin';

import { PeriodoInput } from '../../Components/InputsReactAdmin';
import { STATUS_ATIVO } from '../../utils/constants';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Nome" source="nome" />
    {/* <BooleanInput label="Ativos" source="ativo" alwaysOn /> */}
    <BooleanInput label="Aniversariantes" source="is_aniversariantes" />
    <BooleanInput label="Fidelidade" source="is_fidelidade" />
    <BooleanInput label="CPF" source="cpf" />
    <BooleanInput label="CNPJ" source="cnpj" />
    <PeriodoInput
      source="periodo"
      label="Peŕiodo"
      defaultValue={{
        start: new Date(),
        end: new Date(),
      }}
    />
    <ReferenceInput
      fullWidth
      label="Cidade"
      source="cidade_id"
      reference="cidades"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      allowEmpty={false}
    >
      <AutocompleteInput optionText="nome" allowEmpty={false} />
    </ReferenceInput>
    <ReferenceInput
      fullWidth
      label="Adm"
      source="user_id"
      reference="users"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      allowEmpty={false}
      filter={{ status: STATUS_ATIVO }}
    >
      <AutocompleteInput optionText="nome" allowEmpty={false} />
    </ReferenceInput>
    <TextInput label="Bairro" source="bairro" />
    <TextInput label="Razão Social" source="razao_social" />
    <TextInput label="Nome Fantasia" source="nome_fantasia" />
    {/* <ReferenceInput
      source="categoria_cliente_id"
      reference="categorias_cliente"
      label="Categoria"
      allowEmpty={false}
    >
      <SelectInput optionText="nome" allowEmpty={false} />
    </ReferenceInput> */}
    <ReferenceInput
      fullWidth
      source="categoria_cliente_id"
      reference="categorias_cliente"
      label="Categoria"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      perPage={30}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>
  </FilterAdmin>
);

export default Filter;
