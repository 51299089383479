import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';

import { STATUS_ATIVO, STATUS_INATIVO } from '../../utils/constants';

const ActionsField = ({
  handleEdit = () => {},
  handleShow = () => {},
  handleAtivar = () => {},
  handleDesativar = () => {},
  handleDeletar = () => {},
  ...rest
}) => {
  const record = useRecordContext(rest);
  const gerenciado = record
    ? record.record.id === 1 || record.record.id === 2
    : false;

  const status = record ? record.record.status : '';
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {status === STATUS_ATIVO && (
        <Tooltip title="Editar" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="edit"
            size="small"
            onClick={() => handleEdit(record.record)}
            disabled={gerenciado}
          >
            <EditOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          size="small"
          onClick={() => handleShow(record.record)}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
      {status === STATUS_INATIVO && (
        <Tooltip title="Ativar" placement="bottom" aria-label="ativar2">
          <IconButton
            aria-label="ativar"
            size="small"
            onClick={() => handleAtivar(record.record)}
            disabled={gerenciado}
          >
            <CheckCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
      {status === STATUS_ATIVO && (
        <Tooltip title="Desativar" placement="bottom" aria-label="desativar2">
          <IconButton
            aria-label="desativar"
            size="small"
            onClick={() => handleDesativar(record.record)}
            disabled={gerenciado}
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      )}
      {status === STATUS_INATIVO && (
        <Tooltip title="Excluir" placement="bottom" aria-label="excluir2">
          <IconButton
            aria-label="excluir"
            size="small"
            onClick={() => handleDeletar(record.record)}
            disabled={gerenciado}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

ActionsField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ActionsField;
