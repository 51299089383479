import * as React from 'react';
import { useRecordContext } from 'react-admin';

import PropTypes from 'prop-types';

import { formatMoeda, getPrecoUnitarioVerdadeiroCaixa2 } from '../../utils';

const ValorField = (props) => {
  const record = useRecordContext(props);
  const preco_venda = record ? record.record.preco_venda : 0;
  const preco_oferta = record
    ? getPrecoUnitarioVerdadeiroCaixa2(record.record)
    : 0;
  return (
    <>
      {preco_venda > preco_oferta ? (
        <div>
          <div
            style={{
              color: 'red',
              textDecoration: 'line-through',
              fontSize: '12px',
            }}
          >
            {formatMoeda(preco_venda)}
          </div>
          <div
            style={{
              backgroundColor: 'yellow',
              fontWeight: 'bold',
              fontSize: '15px',
            }}
          >
            {formatMoeda(preco_oferta)}
          </div>
        </div>
      ) : (
        <div>{formatMoeda(preco_venda)}</div>
      )}
    </>
  );
};

ValorField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ValorField;
