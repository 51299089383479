import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '400px',
    // color: 'red',
  },
}));

const DataNegociadaField = (props) => {
  const classes = useStyles();

  const record = useRecordContext(props);

  let value = '-';

  if (record) {
    if (record.record.data_negociada) {
      const dataNegociada = new Date(
        moment(new Date(record.record.data_negociada)).endOf('day').format(),
      );

      const dataPagamento = new Date(
        moment(new Date(record.record.data_pagamento)).endOf('day').format(),
      );

      const delta = dataNegociada.getTime() - dataPagamento.getTime();

      value = parseInt(delta / (24 * 60 * 60 * 1000), 10);
    }
  }

  return <Typography className={classes.text}>{value}</Typography>;
};

export default DataNegociadaField;
