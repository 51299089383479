import React, { useState, useRef } from 'react';
import { Title } from 'react-admin';

import { Button } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useSnackbar } from 'notistack';

import { api, getListAPI } from '../../../services';
import MobillsInput from './DateInputMobills';
import DialogoVendaFaturada from './DialogoCreateEdit';

function formatMoeda(valor) {
  return valor.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableCell2 = withStyles((theme) => ({
  head: {
    backgroundColor: 'rgba(136, 14, 79, 0.5)',
    color: 'white',
  },
  body: {
    fontSize: 14,
    color: 'white',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableRowPorcentagem = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(136, 14, 79, 1)',
    color: 'white',
  },
}))(TableRow);

const StyledTableRowTotais = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(136, 14, 79, 1)',
    color: 'white',
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const nomesMeses = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

const DashbboardContabilidade = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState({
    linhas: [],
    ano: new Date().getFullYear(),
  });
  const { enqueueSnackbar } = useSnackbar();
  const refDialogoVendaFaturada = useRef(null);

  async function handleData({ ano }) {
    setLoading(true);
    try {
      const dados = await getListAPI(
        'receitas_faturadas',
        ['mes', 'asc'],
        [1, 50],
        { ano },
        [],
      );
      setDados({ linhas: dados.data, ano });
      setLoading(false);
    } catch (e) {
      setDados({ linhas: [], ano });
      setLoading(false);
      enqueueSnackbar(`Erro ao carregar os dados!`, {
        variant: 'error',
      });
    }
  }

  return (
    <div>
      <div
        style={{
          fontSize: '25px',
          fontWeight: 'bold',
          marginTop: '35px',
          marginBottom: '30px',
        }}
      >
        Vendas Faturadas
      </div>
      <Title title="Vendas Faturadas" />
      <div
        style={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div />
        <MobillsInput handleData={handleData} />
        <div />
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Mês</StyledTableCell>
              <StyledTableCell align="right">Valor</StyledTableCell>
              <StyledTableCell align="right">Ações</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dados.linhas.map((row) => (
              <StyledTableRow key={row.mes}>
                <StyledTableCell>{nomesMeses[row.mes]}</StyledTableCell>
                <StyledTableCell align="right">
                  {formatMoeda(row.valor)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Button
                    onClick={() => {
                      if (refDialogoVendaFaturada.current) {
                        refDialogoVendaFaturada.current.handleEdit(row.id);
                      }
                    }}
                  >
                    Editar
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogoVendaFaturada
        ref={refDialogoVendaFaturada}
        handleClose={() => {
          handleData({
            ano: dados.ano,
          });
        }}
      />
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DashbboardContabilidade;
