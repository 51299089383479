import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  DateField,
  ListContextProvider,
  useListContext,
  ListBase,
  ListToolbar,
  Pagination,
  Datagrid,
  useRefresh,
  Title,
} from 'react-admin';

import { Divider, Tabs, Tab, Card, makeStyles } from '@material-ui/core';

import { stylesMalote } from '../../utils';
import ValorTransferenciaField from '../Transacoes/Fields/ValorTransferenciaField';
import Actions from './ActionsList';
import DialogoChangeStatus from './DialogoChangeStatus';
import DialogoCreate from './DialogoCreate';
import DialogoEdit from './DialogoEdit';
import DialogoShowTroco from './DialogoShowTroco';
import ActionsField from './Fields/ActionsField';
import CodigoField from './Fields/CodigoField';
import ContaField from './Fields/ContaField';
import FrentistaField from './Fields/FrentistaField';
import ResponsavelField from './Fields/ResponsavelField';
import StatusField from './Fields/StatusField';
import Filter from './Filter';
import DialogoDeletar from './DialogoDeletar'

const useStylesDatagrid = makeStyles((theme) => stylesMalote);

const tabs = [
  { id: 'nao_conferido', name: 'Não Utilizadas' },
  { id: 'conferido', name: 'Utilizadas' },
];

const MyList = ({ ...props }) => {
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [confirmados, setConfirmados] = useState([]);
  const [cancelados, setCancelados] = useState([]);
  const refresh = useRefresh();
  const classes = useStylesDatagrid();
  const refDialogoShowTroco = useRef(null);
  const refDialogoChangeStatus = useRef(null);
  const refDialogoCreate = useRef(null);
  const refDialogoDeletar = useRef(null)

  function changeStatus(record) {
    if (refDialogoChangeStatus.current)
      refDialogoChangeStatus.current.handleOpen(record.record.id);
  }

  const show = (data) => {
    if (refDialogoShowTroco.current) {
      refDialogoShowTroco.current.handleOpen(data.record.id);
    }
  };

  const create = (data) => {
    if (refDialogoCreate.current) {
      refDialogoCreate.current.handleOpen();
    }
  };

  const handleDeletar = (data) => {
    if (refDialogoDeletar.current) {
      refDialogoDeletar.current.handleOpen(data.record);
    }
  };

  useEffect(() => {
    if (ids && ids !== filterValues.visible) {
      switch (filterValues.visible) {
        case true:
          setCancelados(ids);
          break;
        case false:
          setConfirmados(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.visible]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          { ...filterValues, visible: value === 'nao_conferido' },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  function handleClose() {
    refresh();
  }

  return (
    <>
      <Title title="Trocos" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} handleCriar={create} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Divider />
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.visible ? tabs[0].id : tabs[1].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.visible === true && (
            <ListContextProvider value={{ ...listContext, ids: cancelados }}>
              <Datagrid>
                {/* <StatusField
                  source="codigo"
                  label=""
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                /> */}
                <CodigoField
                  source="codigo"
                  label="Código"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <DateField
                  source="data_pagamento"
                  label="Horário"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  options={{ hour: 'numeric', minute: 'numeric' }}
                  sortable={false}
                />
                <ContaField
                  label="Caixa"
                  source="conta_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <FrentistaField
                  label="Frentista"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ResponsavelField
                  label="Responsável"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ValorTransferenciaField
                  source="valor"
                  textAlign="right"
                  label="Valor"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.valorCell}
                  sortable={false}
                />
                <ActionsField
                  source="lalala"
                  handleEfetivar={changeStatus}
                  handleShow={show}
                  textAlign="right"
                  label="Ações"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                  sortable={false}
                  handleDeletar={handleDeletar}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.visible === false && (
            <ListContextProvider value={{ ...listContext, ids: confirmados }}>
              <Datagrid>
                {/* <StatusField
                  source="codigo"
                  label=""
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                /> */}
                <CodigoField
                  source="codigo"
                  label="Código"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <DateField
                  source="data_pagamento"
                  label="Horário"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  options={{ hour: 'numeric', minute: 'numeric' }}
                  sortable={false}
                />
                <ContaField
                  label="Caixa"
                  source="conta_id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <FrentistaField
                  label="Frentista"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ResponsavelField
                  label="Responsável"
                  source="responsavel"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ValorTransferenciaField
                  source="valor"
                  textAlign="right"
                  label="Valor"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.valorCell}
                  sortable={false}
                />
                <ActionsField
                  source="lalala"
                  handleEfetivar={changeStatus}
                  handleShow={show}
                  textAlign="right"
                  label="Ações"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                  sortable={false}
                  handleDeletar={handleDeletar}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoEdit ref={refDialogoChangeStatus} handleClose={handleClose} />
      <DialogoShowTroco ref={refDialogoShowTroco} />
      <DialogoCreate ref={refDialogoCreate} handleClose={handleClose} />
      <DialogoDeletar
        ref={refDialogoDeletar}
        handleClose={handleClose}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Trocos"
      filterDefaultValues={{
        visible: true,
      }}
    />
  </>
);

export default List2;
