import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import PropTypes from 'prop-types';

import { TIPO_DESPESA } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
  },
  cartao: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#00796b',
    marginLeft: '10px',
  },
}));

const StatusField = ({ onClick = () => {}, ...props }) => {
  const classes = useStyles();
  const record = useRecordContext(props);
  const status = record
    ? record.record.n_despesas_atrasadas && record.record.tipo === TIPO_DESPESA
    : false;
  //const status = true;

  return (
    <div style={{ display: 'flex' }}>
      {status ? (
        <Tooltip
          title="Há uma despesa mais atrazada do que esta deste fornecedor, para evitar protesto é interessante que seja pago primeiro a mais atrazada."
          placement="bottom"
          aria-label="add2"
        >
          <Avatar
            className={classes.confirmado}
            style={{
              fontSize: 15,
              color: !status ? 'transparent' : 'black',
              backgroundColor: !status ? 'transparent' : '#fcb60e',
              cursor: 'pointer',
              border: status ? '1px solid black' : 'none',
            }}
            onClick={() => {
              if (status) {
                onClick(record.record);
              }
            }}
          >
            <PriorityHighIcon
              style={{
                fontSize: 15,
              }}
            />
          </Avatar>
        </Tooltip>
      ) : (
        <Avatar
          className={classes.confirmado}
          style={{
            fontSize: 15,
            color: !status ? 'transparent' : 'black',
            backgroundColor: !status ? 'transparent' : '#fcb60e',
            cursor: 'pointer',
            border: status ? '1px solid black' : 'none',
          }}
          onClick={() => {
            if (status) {
              onClick(record.record);
            }
          }}
        >
          <PriorityHighIcon
            style={{
              fontSize: 15,
            }}
          />
        </Avatar>
      )}
    </div>
  );
};

StatusField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default StatusField;
