import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { getListAllAPI, getOneAPI } from '../../../../services';
import {
  STATUS_ATIVO,
  TURNO_MANHA,
  TURNO_NOITE,
  TURNO_12_HORAS,
  TIPO_HORARIO_DOMINGO,
  TIPO_FERIADO_DATA_COMEMORATIVA,
  TIPO_FERIADO_FERIADO,
} from '../../../../utils';
import DialogoResumosTurno from './DialogoResumoTurno';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: 'black',
    width: '1px',
    whiteSpace: 'nowrap',
    padding: '5px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '5px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
}))(TableCell);

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    //borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  root: {
    width: '100%',
  },
  root2: {
    /* width: theme.spacing(3),
    height: theme.spacing(3), */
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const horariosInit = [
  {
    label: '07:00 08:00',
  },
  {
    label: '08:00 09:00',
  },
  {
    label: '09:00 10:00',
  },
  {
    label: '10:00 11:00',
  },
  {
    label: '11:00 12:00',
  },
  {
    label: '12:00 13:00',
  },
  {
    label: '13:00 14:00',
  },
  {
    label: '14:00 15:00',
  },
  {
    label: '15:00 16:00',
  },
  {
    label: '16:00 17:00',
  },
  {
    label: '17:00 18:00',
  },
  {
    label: '18:00 19:00',
  },
  {
    label: '19:00 20:00',
  },
  {
    label: '20:00 21:00',
  },
  {
    label: '21:00 22:00',
  },
];

function horariosColaborador2(dados, horarios) {
  const vet = [];
  for (let i = 0; i < horarios.length; i++) {
    vet.push({
      isTrabalha: true,
    });
  }
  return vet;
}

function horariosColaborador(
  dados,
  isSS = false,
  isAbrirLoja = true,
  isDomingo = false,
) {
  const vet = [];
  switch (dados.turno) {
    case TURNO_MANHA: {
      if (!isDomingo) {
        vet.push({
          isTrabalha: true, // 07:00 08:00
        });
        vet.push({
          isTrabalha: true, // 08:00 09:00
        });
        vet.push({
          isTrabalha: true, // 09:00 10:00
        });
        vet.push({
          isTrabalha: true, // 10:00 11:00
        });
        vet.push({
          isTrabalha: true, // 11:00 12:00
        });
        vet.push({
          isTrabalha: false, // 12:00 13:00
        });
        vet.push({
          isTrabalha: false, // 13:00 14:00
        });
        vet.push({
          isTrabalha: true, // 14:00 15:00
        });
        vet.push({
          isTrabalha: true, // 15:00 16:00
        });
        vet.push({
          isTrabalha: !isSS, // 16:00 17:00
        });
        vet.push({
          isTrabalha: false, // 17:00 18:00
        });
        vet.push({
          isTrabalha: false, // 18:00 19:00
        });
        vet.push({
          isTrabalha: false, // 19:00 20:00
        });
        vet.push({
          isTrabalha: false, // 20:00 21:00
        });
        vet.push({
          isTrabalha: false, // 21:00 22:00
        });
      } else {
        vet.push({
          isTrabalha: true, // 07:00 08:00
        });
        vet.push({
          isTrabalha: true, // 08:00 09:00
        });
        vet.push({
          isTrabalha: true, // 09:00 10:00
        });
        vet.push({
          isTrabalha: true, // 10:00 11:00
        });
        vet.push({
          isTrabalha: true, // 11:00 12:00
        });
        vet.push({
          isTrabalha: true, // 12:00 13:00
        });
        vet.push({
          isTrabalha: false, // 13:00 14:00
        });
        vet.push({
          isTrabalha: false, // 14:00 15:00
        });
        vet.push({
          isTrabalha: false, // 15:00 16:00
        });
        vet.push({
          isTrabalha: false, // 16:00 17:00
        });
        vet.push({
          isTrabalha: false, // 17:00 18:00
        });
        vet.push({
          isTrabalha: false, // 18:00 19:00
        });
        vet.push({
          isTrabalha: false, // 19:00 20:00
        });
        vet.push({
          isTrabalha: false, // 20:00 21:00
        });
        vet.push({
          isTrabalha: false, // 21:00 22:00
        });
      }
      break;
    }
    case TURNO_NOITE: {
      if (!isDomingo) {
        vet.push({
          isTrabalha: false, // 07:00 08:00
        });
        vet.push({
          isTrabalha: false, // 08:00 09:00
        });
        vet.push({
          isTrabalha: false, // 09:00 10:00
        });
        vet.push({
          isTrabalha: false, // 10:00 11:00
        });
        vet.push({
          isTrabalha: false, // 11:00 12:00
        });
        vet.push({
          isTrabalha: true, // 12:00 13:00
        });
        vet.push({
          isTrabalha: true, // 13:00 14:00
        });
        vet.push({
          isTrabalha: true, // 14:00 15:00
        });
        vet.push({
          isTrabalha: false, // 15:00 16:00
        });
        vet.push({
          isTrabalha: false, // 16:00 17:00
        });
        vet.push({
          isTrabalha: true, // 17:00 18:00
        });
        vet.push({
          isTrabalha: true, // 18:00 19:00
        });
        vet.push({
          isTrabalha: true, // 19:00 20:00
        });
        vet.push({
          isTrabalha: true, // 20:00 21:00
        });
        vet.push({
          isTrabalha: !isSS, // 21:00 22:00
        });
      } else {
        vet.push({
          isTrabalha: false, // 07:00 08:00
        });
        vet.push({
          isTrabalha: false, // 08:00 09:00
        });
        vet.push({
          isTrabalha: false, // 09:00 10:00
        });
        vet.push({
          isTrabalha: false, // 10:00 11:00
        });
        vet.push({
          isTrabalha: false, // 11:00 12:00
        });
        vet.push({
          isTrabalha: true, // 12:00 13:00
        });
        vet.push({
          isTrabalha: true, // 13:00 14:00
        });
        vet.push({
          isTrabalha: true, // 14:00 15:00
        });
        vet.push({
          isTrabalha: true, // 15:00 16:00
        });
        vet.push({
          isTrabalha: true, // 16:00 17:00
        });
        vet.push({
          isTrabalha: true, // 17:00 18:00
        });
        vet.push({
          isTrabalha: false, // 18:00 19:00
        });
        vet.push({
          isTrabalha: false, // 19:00 20:00
        });
        vet.push({
          isTrabalha: false, // 20:00 21:00
        });
        vet.push({
          isTrabalha: false, // 21:00 22:00
        });
      }
      break;
    }
    case TURNO_12_HORAS: {
      if (!isDomingo) {
        if (isAbrirLoja) {
          vet.push({
            isTrabalha: true, // 07:00 08:00
          });
          vet.push({
            isTrabalha: true, // 08:00 09:00
          });
          vet.push({
            isTrabalha: true, // 09:00 10:00
          });
          vet.push({
            isTrabalha: true, // 10:00 11:00
          });
          vet.push({
            isTrabalha: false, // 11:00 12:00
          });
          vet.push({
            isTrabalha: false, // 12:00 13:00
          });
          vet.push({
            isTrabalha: true, // 13:00 14:00
          });
          vet.push({
            isTrabalha: true, // 14:00 15:00
          });
          vet.push({
            isTrabalha: true, // 15:00 16:00
          });
          vet.push({
            isTrabalha: true, // 16:00 17:00
          });
          vet.push({
            isTrabalha: true, // 17:00 18:00
          });
          vet.push({
            isTrabalha: true, // 18:00 19:00
          });
          vet.push({
            isTrabalha: false, // 19:00 20:00
          });
          vet.push({
            isTrabalha: false, // 20:00 21:00
          });
          vet.push({
            isTrabalha: false, // 21:00 22:00
          });
        } else {
          vet.push({
            isTrabalha: false, // 07:00 08:00
          });
          vet.push({
            isTrabalha: false, // 08:00 09:00
          });
          vet.push({
            isTrabalha: true, // 09:00 10:00
          });
          vet.push({
            isTrabalha: true, // 10:00 11:00
          });
          vet.push({
            isTrabalha: true, // 11:00 12:00
          });
          vet.push({
            isTrabalha: true, // 12:00 13:00
          });
          vet.push({
            isTrabalha: true, // 13:00 14:00
          });
          vet.push({
            isTrabalha: true, // 14:00 15:00
          });
          vet.push({
            isTrabalha: false, // 15:00 16:00
          });
          vet.push({
            isTrabalha: false, // 16:00 17:00
          });
          vet.push({
            isTrabalha: true, // 17:00 18:00
          });
          vet.push({
            isTrabalha: true, // 18:00 19:00
          });
          vet.push({
            isTrabalha: true, // 19:00 20:00
          });
          vet.push({
            isTrabalha: true, // 20:00 21:00
          });
          vet.push({
            isTrabalha: true, // 21:00 22:00
          });
        }
      } else {
        vet.push({
          isTrabalha: true, // 07:00 08:00
        });
        vet.push({
          isTrabalha: true, // 08:00 09:00
        });
        vet.push({
          isTrabalha: true, // 09:00 10:00
        });
        vet.push({
          isTrabalha: true, // 10:00 11:00
        });
        vet.push({
          isTrabalha: false, // 11:00 12:00
        });
        vet.push({
          isTrabalha: false, // 12:00 13:00
        });
        vet.push({
          isTrabalha: true, // 13:00 14:00
        });
        vet.push({
          isTrabalha: true, // 14:00 15:00
        });
        vet.push({
          isTrabalha: true, // 15:00 16:00
        });
        vet.push({
          isTrabalha: true, // 16:00 17:00
        });
        vet.push({
          isTrabalha: true, // 17:00 18:00
        });
        vet.push({
          isTrabalha: false, // 18:00 19:00
        });
        vet.push({
          isTrabalha: false, // 19:00 20:00
        });
        vet.push({
          isTrabalha: false, // 20:00 21:00
        });
        vet.push({
          isTrabalha: false, // 21:00 22:00
        });
      }

      break;
    }
    default:
      break;
  }
  return vet;
}

function getHoras(turno, isSS = false, isAbrirLoja = true) {
  switch (turno) {
    case TURNO_MANHA:
      return isSS ? '7' : '8';
    case TURNO_NOITE:
      return isSS ? '7' : '8';
    case TURNO_12_HORAS:
      return isAbrirLoja ? '10' : '11';
    default:
      return '0';
  }
}

function getSemana(domingo, data) {
  const domingoObj = new Date(moment(domingo).endOf('day').format());
  const dataObj = new Date(moment(data).endOf('day').format());

  const delta = Math.abs(domingoObj.getTime() - dataObj.getTime());

  const value = parseInt(delta / (7 * 24 * 60 * 60 * 1000), 10);

  return value;
}

function verificaFolgaDomingo(semanas, equipe) {
  if (equipe) {
    const resto = semanas % 2;
    const id = equipe.id - 1;
    if (resto === id) {
      return true;
    }
    return false;
  }

  return false;
}

function verificaFeriado(dia, equipe_id) {
  switch (dia.tipo) {
    case TIPO_FERIADO_FERIADO:
      if (dia.equipe_colaborador_id) {
        if (dia.equipe_colaborador_id === equipe_id) {
          return true;
        }
        return false;
      }
      if (equipe_id) {
        return true;
      }
      return false;
    case TIPO_FERIADO_DATA_COMEMORATIVA:
      if (dia.equipe_colaborador_id) {
        if (dia.equipe_colaborador_id === equipe_id) {
          return true;
        }
        return false;
      }
      return true;
    default:
      return true;
  }
}

function getIsFolga(dia_da_folga, dadosDia, domingo, equipe) {
  const semanas = getSemana(domingo, dadosDia.data);

  const isEquipeFeriado = verificaFeriado(dadosDia, equipe ? equipe.id : null);

  return isEquipeFeriado
    ? dia_da_folga === dadosDia.dia_semana
      ? true
      : dadosDia.dia_semana === 0
      ? !verificaFolgaDomingo(semanas, equipe)
      : false
    : true;
}

const GL1 = '';
const GL2 = '';
const GL3 = '';
const C1 = '';

function procuraGerentoes(colaboradores) {
  let isGl1 = false;
  let isGl2 = false;
  let isGl3 = false;
  let isC1 = false;

  for (let i = 0; i < colaboradores.length; i++) {
    if (colaboradores[i].codinome) {
      switch (colaboradores[i].codinome.nome) {
        case GL1:
          isGl1 = true;
          break;
        case GL2:
          isGl2 = true;
          break;
        case GL3:
          isGl3 = true;
          break;
        case C1:
          isC1 = true;
          break;
      }
    }
  }

  if (isGl1) return GL1;
  if (isGl2) return GL2;
  if (isGl3) return GL3;
  if (isC1) return C1;
  return null;
}

function formatColaborador(dados, horarios, dia, domingo) {
  const dadosFormatados = [];

  const gerentaoAbreLoja = procuraGerentoes(dados);

  for (let i = 0; i < dados.length; i++) {
    const isSS = dados[i].grupoColaborador
      ? !dados[i].grupoColaborador.equipe
      : false;

    const codinome = dados[i].codinome ? dados[i].codinome.nome : '-';

    dadosFormatados.push({
      id: dados[i].id,
      nome: dados[i].nome,
      turnoValor: dados[i].turno,
      codinome,
      turno: getLabelTurno(dados[i].turno),
      grupo_id: dados[i].grupo_colaborador_id
        ? dados[i].grupo_colaborador_id
        : 0,
      equipe_id: dados[i].grupoColaborador
        ? dados[i].grupoColaborador.equipe_colaborador_id
        : 0,
      funcao_id: dados[i].funcao ? dados[i].funcao.id : 0,
      grupo: dados[i].grupoColaborador ? dados[i].grupoColaborador.nome : '-',
      equipe: dados[i].grupoColaborador
        ? dados[i].grupoColaborador.equipe
          ? dados[i].grupoColaborador.equipe.nome
          : '-'
        : '-',
      funcao: dados[i].funcao ? dados[i].funcao.nome : '-',
      horarios: horariosColaborador(
        dados[i],
        isSS,
        gerentaoAbreLoja === null ? false : codinome === gerentaoAbreLoja,
        dia.dia_semana === 0 ? true : dia.tipo_horario === TIPO_HORARIO_DOMINGO,
      ),
      isFolga: getIsFolga(
        dados[i].grupoColaborador ? dados[i].grupoColaborador.dia_da_folga : -1,
        dia,
        domingo,
        dados[i].grupoColaborador
          ? dados[i].grupoColaborador.equipe
            ? dados[i].grupoColaborador.equipe
            : null
          : null,
      ),
      horas: getHoras(
        dados[i].turno,
        isSS,
        gerentaoAbreLoja === null ? false : codinome === gerentaoAbreLoja,
      ),
    });
  }
  return dadosFormatados;
}

function getTotal(turno, colaboradores, field, value) {
  let total = 0;
  for (let i = 0; i < colaboradores.length; i++) {
    if (turno === '') {
      if (colaboradores[i][field] === value) {
        total += 1;
      }
    } else if (colaboradores[i].turnoValor === turno) {
      if (colaboradores[i][field] === value) {
        total += 1;
      }
    }
  }
  return total;
}

function formatEquipes(dados, colaboradores) {
  const dadosFormatados = [];
  for (let i = 0; i < dados.length; i++) {
    dadosFormatados.push({
      id: dados[i].id,
      nome: dados[i].nome,
      total_manha: getTotal(
        TURNO_MANHA,
        colaboradores,
        'equipe_id',
        dados[i].id,
      ),
      total_tarde: getTotal(
        TURNO_NOITE,
        colaboradores,
        'equipe_id',
        dados[i].id,
      ),
      total_12: getTotal(
        TURNO_12_HORAS,
        colaboradores,
        'equipe_id',
        dados[i].id,
      ),
      total: getTotal('', colaboradores, 'equipe_id', dados[i].id),
    });
  }
  return dadosFormatados;
}

function formatGrupos(dados, colaboradores) {
  const dadosFormatados = [];
  for (let i = 0; i < dados.length; i++) {
    dadosFormatados.push({
      id: dados[i].id,
      nome: dados[i].nome,
      total_manha: getTotal(
        TURNO_MANHA,
        colaboradores,
        'grupo_id',
        dados[i].id,
      ),
      total_tarde: getTotal(
        TURNO_NOITE,
        colaboradores,
        'grupo_id',
        dados[i].id,
      ),
      total_12: getTotal(
        TURNO_12_HORAS,
        colaboradores,
        'grupo_id',
        dados[i].id,
      ),
      total: getTotal('', colaboradores, 'grupo_id', dados[i].id),
    });
  }
  return dadosFormatados;
}

function formatFuncoes(dados, colaboradores) {
  const dadosFormatados = [];
  for (let i = 0; i < dados.length; i++) {
    dadosFormatados.push({
      id: dados[i].id,
      nome: dados[i].nome,
      total_manha: getTotal(
        TURNO_MANHA,
        colaboradores,
        'funcao_id',
        dados[i].id,
      ),
      total_tarde: getTotal(
        TURNO_NOITE,
        colaboradores,
        'funcao_id',
        dados[i].id,
      ),
      total_12: getTotal(
        TURNO_12_HORAS,
        colaboradores,
        'funcao_id',
        dados[i].id,
      ),
      total: getTotal('', colaboradores, 'funcao_id', dados[i].id),
    });
  }
  return dadosFormatados;
}

function formatHorarios(horarios, colaboradores) {
  const diasFormatados = [];
  for (let i = 0; i < horarios.length; i++) {
    let total = 0;
    for (let j = 0; j < colaboradores.length; j++) {
      if (!colaboradores[j].isFolga) {
        if (colaboradores[j].horarios[i].isTrabalha) {
          total++;
        }
      }
    }
    diasFormatados.push({
      ...horarios[i],
      totalColaboradores: total,
    });
  }
  return diasFormatados;
}

function getLabelTurno(value) {
  switch (value) {
    case TURNO_MANHA:
      return 'Manhã';
    case TURNO_NOITE:
      return 'Noite';
    case TURNO_12_HORAS:
      return '12H';
    default:
      return '';
  }
}

const COLUMN_EQUIPE = 'equipe';
const COLUMN_FUNCAO = 'funcao';
const COLUMN_GRUPO = 'grupo';
const COLUMN_CODINOME = 'codinome';
const COLUMN_COLABORADOR = 'nome';
const COLUMN_TURNO = 'turno';

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose = () => {} }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [colaboradores, setColaboradores] = useState([]);
    const [horarios, setHorarios] = useState(horariosInit);
    const [dadosDia, setDadosDia] = useState(null);

    const [equipes, setEquipes] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [funcoes, setFuncoes] = useState([]);

    const [carregando, setCarregando] = useState(false);

    const classes = useStyles({ cor: '#009FD4' });

    const [columnSort, setColumnSort] = useState([]);

    const refDialogoResumoTurno = useRef(null);

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    useImperativeHandle(ref, () => ({
      handleOpen(data) {
        setCarregando(true);
        setOpen(true);
        getDados(data);
      },
    }));

    async function getEquipes(colaboradores) {
      const data = await getListAllAPI(
        'equipes_colaborador',
        ['id', 'asc'],
        {},
        [],
      );
      return formatEquipes(data.data, colaboradores);
    }

    async function getGrupos(colaboradores) {
      const data = await getListAllAPI(
        'grupos_colaborador',
        ['id', 'asc'],
        {},
        [],
      );
      return formatGrupos(data.data, colaboradores);
    }

    async function getFuncoes(colaboradores) {
      const data = await getListAllAPI(
        'funcoes_colaboradores',
        ['id', 'asc'],
        { status: STATUS_ATIVO },
        [],
      );
      return formatFuncoes(data.data, colaboradores);
    }

    async function getColaborador(horarios, dia, domingo) {
      const data = await getListAllAPI(
        'colaboradores',
        ['id', 'asc'],
        {
          status: STATUS_ATIVO,
          is_funcionario_interno: true,
        },
        ['codinome', 'grupoColaborador.equipe', 'funcao'],
      );

      return formatColaborador(data.data, horarios, dia, domingo);
    }

    async function getDias(data2) {
      const data = await getListAllAPI(
        'feriados',
        ['id', 'asc'],
        {
          dia: data2.dia,
          mes: data2.mes,
          ano: data2.ano,
        },
        [],
      );

      return data.data;
    }

    async function getConfig() {
      const data = await getOneAPI('config', 1, []);
      return data.data;
    }

    async function getDados(data) {
      if (!carregando) {
        setCarregando(true);
        try {
          const config = await getConfig();

          const domingo = config.data_domingo_trabalho_equipe_1
            ? new Date(config.data_domingo_trabalho_equipe_1)
            : null;

          const dias = await getDias(data);
          const dia = dias.length > 0 ? dias[0] : null;
          const colaboradores = await getColaborador(horarios, dia, domingo);

          const equipes = await getEquipes(
            colaboradores.filter((item) => !item.isFolga),
          );
          const grupos = await getGrupos(
            colaboradores.filter((item) => !item.isFolga),
          );
          const funcoes = await getFuncoes(
            colaboradores.filter((item) => !item.isFolga),
          );

          setColaboradores(colaboradores);
          setDadosDia(dia);

          const horariosFormatados = formatHorarios(horarios, colaboradores);
          setHorarios(horariosFormatados);

          setEquipes(equipes);
          setGrupos(grupos);
          setFuncoes(funcoes);

          setCarregando(false);
        } catch (e) {
          setColaboradores([]);
          setDadosDia(null);
          setHorarios(horariosInit);

          setEquipes([]);
          setGrupos([]);
          setFuncoes([]);

          setCarregando(false);
          enqueueSnackbar(`Erro ao carregar os dados do relatório!`, {
            variant: 'error',
          });
        }
      }
    }

    function ordenacao(a, b, index = 0) {
      if (columnSort.length > 0) {
        if (a[columnSort[index].column] > b[columnSort[index].column]) {
          return columnSort[index].direction === 'asc' ? 1 : -1;
        }

        if (a[columnSort[index].column] < b[columnSort[index].column]) {
          return columnSort[index].direction === 'asc' ? -1 : 1;
        }

        if (index < columnSort.length - 1) {
          return ordenacao(a, b, index + 1);
        }
      }

      return 0;
    }

    const colaboradores2 = [...colaboradores];
    colaboradores2.sort(ordenacao);

    function getTotal() {
      let total = 0;
      for (let i = 0; i < horarios.length; i++) {
        total += horarios[i].totalColaboradores;
      }
      return total;
    }

    const totalTotal = getTotal();

    function getDataFormatada() {
      return dadosDia
        ? `${dadosDia.dia >= 10 ? dadosDia.dia : `0${dadosDia.dia}`}/${
            dadosDia.mes + 1 >= 10 ? dadosDia.mes + 1 : `0${dadosDia.mes + 1}`
          }/${dadosDia.ano}`
        : '-';
    }

    function getFilter(column) {
      const item = columnSort.find((item) => item.column === column);
      const index = columnSort.findIndex((item) => item.column === column);

      return {
        item,
        index,
      };
    }

    function adicionaFilter(e, column) {
      if (e.ctrlKey) {
        const filter = getFilter(column);
        const columnSortLocal = [...columnSort];
        if (filter.item) {
          columnSortLocal[filter.index] = {
            column,
            direction: filter.item.direction === 'asc' ? 'desc' : 'asc',
          };
          setColumnSort(columnSortLocal);
        } else {
          columnSortLocal.push({
            column,
            direction: 'asc',
          });
          setColumnSort(columnSortLocal);
        }
      } else {
        const filter = getFilter(column);
        if (filter.item) {
          setColumnSort([
            {
              column,
              direction: filter.item.direction === 'asc' ? 'desc' : 'asc',
            },
          ]);
        } else {
          setColumnSort([
            {
              column,
              direction: 'asc',
            },
          ]);
        }
      }
    }

    const filterEquipe = getFilter(COLUMN_EQUIPE);
    const filterFuncao = getFilter(COLUMN_FUNCAO);
    const filterGrupo = getFilter(COLUMN_GRUPO);
    const filterCodinome = getFilter(COLUMN_CODINOME);
    const filterColaborador = getFilter(COLUMN_COLABORADOR);
    const filterTurno = getFilter(COLUMN_TURNO);

    function openResumo() {
      if (refDialogoResumoTurno.current) {
        refDialogoResumoTurno.current.handleOpen();
      }
    }

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullScreen
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            {`Turnos ${getDataFormatada()}`}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div>
                <Box
                  flex={1}
                  style={{
                    margin: '10px',
                  }}
                >
                  <div>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            colSpan={9}
                            style={{
                              backgroundColor: 'white',
                              fontWeight: 'bold',
                              fontSize: '20px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                                justifyContent: 'center',
                              }}
                            >
                              <span>TURNOS DE SEGUNDA A SÁBADO</span>
                              <Tooltip
                                title="Resumo do turno"
                                placement="bottom"
                                aria-label="add2"
                              >
                                <IconButton
                                  aria-label="edit"
                                  onClick={() => openResumo()}
                                >
                                  <DataUsageIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={2}
                            style={{
                              backgroundColor: 'yellow',
                              whiteSpace: 'break-spaces',
                              fontWeight: 'bold',
                            }}
                          >
                            Almoço Turno da Manhã
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          />
                          <StyledTableCell
                            colSpan={2}
                            style={{
                              backgroundColor: 'yellow',
                              whiteSpace: 'break-spaces',
                              fontWeight: 'bold',
                            }}
                          >
                            Almoço Turno da Noite
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          />
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          />
                          <StyledTableCell
                            colSpan={3}
                            style={{
                              backgroundColor: 'white',
                              fontWeight: 'bold',
                            }}
                          >
                            Válido a partir de:
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                              fontWeight: 'bold',
                            }}
                          >
                            19/06/2023
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: 'white',
                            }}
                          />
                          <StyledTableCell
                            rowSpan={2}
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                                writingMode: 'vertical-rl',
                                transform: 'rotate(180deg)',
                                fontWeight: 'bold',
                              }}
                            >
                              Horas
                            </span>
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell
                            sortDirection={
                              filterFuncao.item
                                ? filterFuncao.item.direction
                                : false
                            }
                          >
                            <TableSortLabel
                              active={!!filterFuncao.item}
                              direction={
                                filterFuncao.item
                                  ? filterFuncao.item.direction
                                  : 'asc'
                              }
                              onClick={(e) => adicionaFilter(e, COLUMN_FUNCAO)}
                            >
                              <p>
                                <span>CARGOS</span>
                                {filterFuncao.item && columnSort.length > 1 ? (
                                  <sub
                                    style={{
                                      color: 'red',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    {filterFuncao.index + 1}
                                  </sub>
                                ) : null}
                                {filterFuncao.item ? (
                                  <span className={classes.visuallyHidden}>
                                    {filterFuncao.item.direction === 'desc'
                                      ? 'sorted descending'
                                      : 'sorted ascending'}
                                  </span>
                                ) : null}
                              </p>
                            </TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell
                            sortDirection={
                              filterCodinome.item
                                ? filterCodinome.item.direction
                                : false
                            }
                          >
                            <TableSortLabel
                              active={!!filterCodinome.item}
                              direction={
                                filterCodinome.item
                                  ? filterCodinome.item.direction
                                  : 'asc'
                              }
                              onClick={(e) =>
                                adicionaFilter(e, COLUMN_CODINOME)
                              }
                            >
                              <p>
                                <span
                                  style={{
                                    whiteSpace: 'nowrap',
                                    writingMode: 'vertical-rl',
                                    transform: 'rotate(180deg)',
                                  }}
                                >
                                  CODINOME
                                </span>
                                {filterCodinome.item &&
                                columnSort.length > 1 ? (
                                  <sub
                                    style={{
                                      color: 'red',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    {filterCodinome.index + 1}
                                  </sub>
                                ) : null}
                                {filterCodinome.item ? (
                                  <span className={classes.visuallyHidden}>
                                    {filterCodinome.item.direction === 'desc'
                                      ? 'sorted descending'
                                      : 'sorted ascending'}
                                  </span>
                                ) : null}
                              </p>
                            </TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell
                            sortDirection={
                              filterColaborador.item
                                ? filterColaborador.item.direction
                                : false
                            }
                          >
                            <TableSortLabel
                              active={!!filterColaborador.item}
                              direction={
                                filterColaborador.item
                                  ? filterColaborador.item.direction
                                  : 'asc'
                              }
                              onClick={(e) =>
                                adicionaFilter(e, COLUMN_COLABORADOR)
                              }
                            >
                              <p>
                                <span>NOME DO COLABORADOR</span>
                                {filterColaborador.item &&
                                columnSort.length > 1 ? (
                                  <sub
                                    style={{
                                      color: 'red',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    {filterColaborador.index + 1}
                                  </sub>
                                ) : null}
                                {filterColaborador.item ? (
                                  <span className={classes.visuallyHidden}>
                                    {filterColaborador.item.direction === 'desc'
                                      ? 'sorted descending'
                                      : 'sorted ascending'}
                                  </span>
                                ) : null}
                              </p>
                            </TableSortLabel>
                          </StyledTableCell>

                          <StyledTableCell
                            sortDirection={
                              filterTurno.item
                                ? filterTurno.item.direction
                                : false
                            }
                          >
                            <TableSortLabel
                              active={!!filterTurno.item}
                              direction={
                                filterTurno.item
                                  ? filterTurno.item.direction
                                  : 'asc'
                              }
                              onClick={(e) => adicionaFilter(e, COLUMN_TURNO)}
                            >
                              <p>
                                <span
                                  style={{
                                    whiteSpace: 'nowrap',
                                    writingMode: 'vertical-rl',
                                    transform: 'rotate(180deg)',
                                  }}
                                >
                                  TURNO
                                </span>
                                {filterTurno.item && columnSort.length > 1 ? (
                                  <sub
                                    style={{
                                      color: 'red',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    {filterTurno.index + 1}
                                  </sub>
                                ) : null}
                                {filterTurno.item ? (
                                  <span className={classes.visuallyHidden}>
                                    {filterTurno.item.direction === 'desc'
                                      ? 'sorted descending'
                                      : 'sorted ascending'}
                                  </span>
                                ) : null}
                              </p>
                            </TableSortLabel>
                          </StyledTableCell>

                          <StyledTableCell
                            sortDirection={
                              filterEquipe.item
                                ? filterEquipe.item.direction
                                : false
                            }
                          >
                            <TableSortLabel
                              active={!!filterEquipe.item}
                              direction={
                                filterEquipe.item
                                  ? filterEquipe.item.direction
                                  : 'asc'
                              }
                              onClick={(e) => adicionaFilter(e, COLUMN_EQUIPE)}
                            >
                              <p>
                                <span
                                  style={{
                                    whiteSpace: 'nowrap',
                                    writingMode: 'vertical-rl',
                                    transform: 'rotate(180deg)',
                                  }}
                                >
                                  EQUIPE
                                </span>
                                {filterEquipe.item && columnSort.length > 1 ? (
                                  <sub
                                    style={{
                                      color: 'red',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    {filterEquipe.index + 1}
                                  </sub>
                                ) : null}
                                {filterEquipe.item ? (
                                  <span className={classes.visuallyHidden}>
                                    {filterEquipe.item.direction === 'desc'
                                      ? 'sorted descending'
                                      : 'sorted ascending'}
                                  </span>
                                ) : null}
                              </p>
                            </TableSortLabel>
                          </StyledTableCell>

                          <StyledTableCell
                            sortDirection={
                              filterGrupo.item
                                ? filterGrupo.item.direction
                                : false
                            }
                          >
                            <TableSortLabel
                              active={!!filterGrupo.item}
                              direction={
                                filterGrupo.item
                                  ? filterGrupo.item.direction
                                  : 'asc'
                              }
                              onClick={(e) => adicionaFilter(e, COLUMN_GRUPO)}
                            >
                              <p>
                                <span
                                  style={{
                                    whiteSpace: 'nowrap',
                                    writingMode: 'vertical-rl',
                                    transform: 'rotate(180deg)',
                                  }}
                                >
                                  GRUPO
                                </span>
                                {filterGrupo.item && columnSort.length > 1 ? (
                                  <sub
                                    style={{
                                      color: 'red',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    {filterGrupo.index + 1}
                                  </sub>
                                ) : null}
                                {filterGrupo.item ? (
                                  <span className={classes.visuallyHidden}>
                                    {filterGrupo.item.direction === 'desc'
                                      ? 'sorted descending'
                                      : 'sorted ascending'}
                                  </span>
                                ) : null}
                              </p>
                            </TableSortLabel>
                          </StyledTableCell>
                          {horarios.map(({ label }) => (
                            <StyledTableCell
                              style={{
                                whiteSpace: 'break-spaces',
                                fontWeight: 'bold',
                              }}
                            >
                              {label}
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {colaboradores2
                          .filter((item) => !item.isFolga)
                          .map((colaborador) => (
                            <TableRow>
                              <StyledTableCell
                                style={{
                                  textAlign: 'start',
                                }}
                              >
                                {colaborador.funcao}
                              </StyledTableCell>
                              <StyledTableCell>
                                {colaborador.codinome}
                              </StyledTableCell>

                              <StyledTableCell
                                style={{
                                  textAlign: 'start',
                                }}
                              >
                                {colaborador.nome}
                              </StyledTableCell>
                              <StyledTableCell>
                                {colaborador.turno}
                              </StyledTableCell>
                              <StyledTableCell>
                                {colaborador.equipe}
                              </StyledTableCell>
                              <StyledTableCell>
                                {colaborador.grupo}
                              </StyledTableCell>
                              {colaborador.horarios.map((item) => (
                                <>
                                  {item.isTrabalha ? (
                                    <StyledTableCell
                                      style={{ backgroundColor: 'yellow' }}
                                    >
                                      X
                                    </StyledTableCell>
                                  ) : (
                                    <StyledTableCell />
                                  )}
                                </>
                              ))}

                              <StyledTableCell>
                                {colaborador.horas}
                              </StyledTableCell>
                            </TableRow>
                          ))}
                        <TableRow>
                          <StyledTableCell />
                          <StyledTableCell />
                          <StyledTableCell />
                          <StyledTableCell />
                          <StyledTableCell />
                          <StyledTableCell
                            style={{
                              backgroundColor: 'yellow',
                            }}
                          >
                            Total
                          </StyledTableCell>

                          {horarios.map((item) => (
                            <StyledTableCell
                              style={{
                                backgroundColor: 'yellow',
                              }}
                            >
                              {item.totalColaboradores}
                            </StyledTableCell>
                          ))}
                          <StyledTableCell
                            style={{
                              backgroundColor: 'yellow',
                            }}
                          >
                            {totalTotal}
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Box>
              </div>
            )}
          </DialogContent>
          <DialogoResumosTurno
            ref={refDialogoResumoTurno}
            equipes={equipes}
            grupos={grupos}
            funcoes={funcoes}
            data={getDataFormatada()}
          />
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
