import React, { useState, useRef, useEffect, useContext } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';

import { Box, makeStyles } from '@material-ui/core';

import { getListAllAPI } from '../../../../../../services';
import {
  formatCPF,
  formatCNPJ,
  toStringParcela,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  ATALHO_AVANCAR,
  ATALHO_VOLTAR,
  STATUS_ATIVO,
} from '../../../../../../utils';
import {
  DialogoCliente,
  DialogoConfirmacao,
  DialogoDeletarComSenha,
  DialogoSenha,
  AutoCompleteRemoto,
  LabelSubtotal,
  LabelTeclasAtalho,
  LabelTotalParcelas,
  Tabela,
  DialogoConsultorPrecos,
  DialogoLiberarParcelaDesconto,
  LabelCaixa,
  DialogoConsultorClienteCompatibilizacao,
} from '../../../../components';
import { useParcelas } from '../../../../hooks';
import { VendaContext } from '../../context';
import FormParcela from './Form';

const useStyles = makeStyles((theme) => ({
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textField: {},
  btnImprimir: {
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.4,
  },
  header: {
    /* height: '17%', */
  },
  body: {
    /* height: '83%', */
  },
  footer: {
    height: '15vh',
    padding: '10px',
    paddingBottom: '20px',
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const VOLTAR_ACTION = ATALHO_VOLTAR;
const FINALIZAR_ACTION = ATALHO_AVANCAR;
const NOVO_CLIENTE_ACTION = 'F9';
const DELETAR_ITEM_ACTION = 'F2';
const EDITAR_CLIENTE_ACTION = 'F7';
const CONSULTOR_PRECOS_ACTION = 'F3';

const CONSULTOR_CLIENTE_COMPATIBILIZACAO = 'F10';

const colorHeader = SECONDARY_COLOR;

const FinalizarVenda = ({
  irParaTelaInit,
  irParaTelaFrentedeCaixa,
  getSessionId,
}) => {
  const classes = useStyles();

  const { venda, dispatch } = useContext(VendaContext);
  const {
    cliente,
    parcelas,
    idEdit,
    descontoOriginal,
    isEmPartes,
    idSession,
  } = venda;
  const refSidebarInputs = useRef(null);
  const refCliente = useRef(null);
  const refDialogoSenha = useRef(null);
  const refDialogoConsultorPrecos = useRef(null);

  const refDialogoCliente = useRef(null);
  const refDialogoDeletar = useRef(null);
  const refDialogoConfirmacao = useRef(null);
  const refDialogoLiberarParcela = useRef(null);

  const refDialogoConsultorClienteCompatibilizacao = useRef(null);

  const [folga, setFolga] = useState(0);

  const {
    resto,
    podeEncerrarTransacao,
    hasPagamentoDinheiro,
    totalParcelas,
    handleFinalizarVenda,
    handleNewItem,
    getPodeUsarParcela,
    hasPagamentoNaoPadrao,
    enqueueSnackbar,
    podeFecharVenda,
    descontoDisponivelCliente,
    descontoUsadoCliente,
  } = useParcelas(
    parcelas,
    venda.itens,
    cliente,
    venda.subTotal,
    getSessionId(),
    updateParcela,
    irParaTelaInit,
    idEdit,
    folga,
    descontoOriginal,
    idSession,
  );

  function updateParcela(body) {
    dispatch({
      type: 'ADD_PARCELA',
      parcela: body,
    });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (refCliente.current) {
        refCliente.current.focus();
        refCliente.current.select();
      }
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (venda.itens.length <= 0) irParaTelaFrentedeCaixa();
  }, [venda.itens]);

  function removeItens(indices) {
    dispatch({ type: 'REMOVE_PARCELA', indices });
  }

  async function getFolga() {
    const config = await getListAllAPI('config', ['id', 'asc'], { id: 1 }, []);
    setFolga(config.data[0].folga_vendas);
  }

  useEffect(() => {
    getFolga();
  }, []);

  /*  const handleClose = () => {
    irParaTelaFrentedeCaixa();
  };

  function closeDialogoNota(tipoNota) {
    if (tipoNota === 0) {
      irParaTelaInit();
    }
  } */

  function handleActions(action) {
    switch (action) {
      case VOLTAR_ACTION:
        // if (refDialogoSenha.current) { refDialogoSenha.current.handleOpen(); }
        irParaTelaFrentedeCaixa();
        break;
      case FINALIZAR_ACTION:
        if (podeFecharVenda) {
          openConfirmacao();
        }
        break;
      case NOVO_CLIENTE_ACTION:
        if (refDialogoCliente.current) refDialogoCliente.current.handleCreate();
        break;
      case DELETAR_ITEM_ACTION:
        if (parcelas.length > 0) {
          if (refDialogoDeletar.current) {
            refDialogoDeletar.current.handleOpen();
          }
        }
        break;
      case CONSULTOR_PRECOS_ACTION:
        if (refDialogoConsultorPrecos.current) {
          refDialogoConsultorPrecos.current.handleOpen();
        }
        break;
      case EDITAR_CLIENTE_ACTION:
        if (refDialogoCliente.current) {
          const idCliente = cliente ? cliente.id : 0;
          if (idCliente > 0) {
            refDialogoCliente.current.handleEdit(idCliente);
          }
        }
        break;
      case CONSULTOR_CLIENTE_COMPATIBILIZACAO:
        if (refDialogoConsultorClienteCompatibilizacao.current) {
          refDialogoConsultorClienteCompatibilizacao.current.handleOpen();
        }
        break;
      default:
        break;
    }
  }

  function handleConfirma(codigo) {
    switch (codigo) {
      case 0:
        handleFinalizarVenda();
        break;
      default:
        break;
    }
  }

  function openConfirmacao() {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Fechamento da edição da venda',
        'Tem certeza que deseja finalizar a edição da venda!',
        0,
      );
    }
  }

  async function updateCliente(idUpdate) {
    try {
      const data = await getListAllAPI(
        'clientes',
        ['id', 'asc'],
        { id: [idUpdate] },
        ['cidade.estado', 'categoria'],
      );
      if (data.data.length > 0) {
        dispatch({ type: 'UPDATE_CLIENTE', cliente: data.data[0] });
      } else {
        throw 'Este cliente não existe!';
      }
    } catch (erros) {
      // nada
    }
  }

  return (
    <>
      <Box padding="10px" className={classes.header}>
        <Box
          display="flex"
          css={{
            backgroundColor: 'white',
            opacity: '0.75',
            height: '100%',
            padding: '0px 10px',
            flexDirection: 'column',
          }}
        >
          <Box display="flex" alignItems="center" flex={1}>
            {/* <AutoCompleteRemoto
              id="cliente"
              name="cliente"
              label="Responsável"
              value={cliente}
              onChange={(value) => {
                // dispatch({ type: 'UPDATE_CLIENTE', cliente: value })
              }}
              handleKey={(keyCode, keyName) => {
                handleActions(keyName);
              }}
              handleEnter={() => {
                if (refSidebarInputs.current) refSidebarInputs.current.focus();
              }}
              resource="clientes"
              nested={['cidade.estado', 'categoria']}
              toString={false}
              style={{ width: '300px', margin: 0, padding: 0 }}
              fullwidth={false}
              autoFocus
              filters={{ ativo: true }}
              ref={refCliente}
              getOptionSelected={(option, value) => option.nome === value.nome}
              getOptionLabel={(option) =>
                `${option.nome}${
                  option.nome_fantasia ? ` - (${option.nome_fantasia})` : ''
                }`
              }
            />
            <Box marginLeft="10px">
              <AutoCompleteRemoto
                id="cliente"
                name="cliente"
                label="Nome Fantasia"
                value={cliente}
                onChange={(value) => {
                  // dispatch({ type: 'UPDATE_CLIENTE', cliente: value })
                }}
                handleKey={(keyCode, keyName) => {
                  handleActions(keyName);
                }}
                handleEnter={() => {
                  if (refSidebarInputs.current)
                    refSidebarInputs.current.focus();
                }}
                resource="clientes"
                campo="nome_fantasia"
                nested={['cidade.estado', 'categoria']}
                toString={false}
                style={{ width: '300px' }}
                fullwidth={false}
                filters={{ ativo: true }}
              /> */}
            <AutoCompleteRemoto
              id="cliente"
              name="cliente"
              label="CPF ou CNPJ"
              value={cliente}
              onChange={(value) => {
                // dispatch({ type: 'UPDATE_CLIENTE', cliente: value });
              }}
              handleKey={(keyCode, keyName) => {
                handleActions(keyName);
              }}
              handleEnter={() => {
                if (refSidebarInputs.current) refSidebarInputs.current.focus();
              }}
              campo="cpf"
              resource="clientes_pesquisa_fc"
              nested={['cidade.estado', 'categoria']}
              toString={false}
              style={{ width: '350px', margin: 0, padding: 0 }}
              fullwidth={false}
              autoFocus
              filters={{ ativo: true, status: STATUS_ATIVO }}
              ref={refCliente}
              getOptionSelected={(option, value) =>
                option.cpf === value.cpf || option.cnpj === value.cnpj
              }
              getOptionLabel={(option) => `${option.cpf} - ${option.cnpj}`}
            />
            {/* <Box marginLeft="10px">
              <AutoCompleteRemoto
                id="cliente"
                name="cliente"
                label="CNPJ"
                value={cliente}
                onChange={(value) => {
                  // dispatch({ type: 'UPDATE_CLIENTE', cliente: value })
                }}
                handleKey={(keyCode, keyName) => {
                  handleActions(keyName);
                }}
                handleEnter={() => {
                  if (refSidebarInputs.current)
                    refSidebarInputs.current.focus();
                }}
                resource="clientes"
                campo="cnpj"
                nested={['cidade.estado', 'categoria']}
                toString={false}
                style={{ width: '300px' }}
                fullwidth={false}
                filters={{ ativo: true }}
              />
            </Box> */}
            {cliente && (
              <Box
                display="flex"
                justifyContent="space-between"
                flex={1}
                style={{
                  border: `1px solid ${PRIMARY_COLOR}`,
                  marginLeft: '10px',
                  padding: '5px',
                  borderRadius: '5px',
                }}
              >
                <Box
                  marginLeft="10px"
                  display="flex"
                  flexDirection="column"
                  flex={1}
                >
                  <Box
                    marginLeft="0px"
                    fontSize="15px"
                    fontWeight="bold"
                    display="flex"
                    flex={1}
                  >
                    <p
                      className={classes.textOverflow}
                      style={{
                        marginBottom: '0px',
                        color: colorHeader,
                        marginRight: '10px',
                      }}
                    >
                      Responsável:
                    </p>
                    {cliente.nome}
                  </Box>
                  <Box
                    marginLeft="0px"
                    fontSize="15px"
                    fontWeight="bold"
                    display="flex"
                    flex={1}
                    alignItems="center"
                  >
                    <p
                      className={classes.textOverflow}
                      style={{
                        marginBottom: '0px',
                        color: colorHeader,
                        marginRight: '10px',
                        marginTop: '0px',
                      }}
                    >
                      Razão Social:
                    </p>
                    {cliente.razao_social}
                  </Box>
                  <Box
                    marginLeft="0px"
                    fontSize="15px"
                    fontWeight="bold"
                    display="flex"
                    flex={1}
                    alignItems="center"
                  >
                    <p
                      className={classes.textOverflow}
                      style={{
                        marginBottom: '0px',
                        color: colorHeader,
                        marginRight: '10px',
                        marginTop: '0px',
                      }}
                    >
                      CPF:
                    </p>
                    {formatCPF(cliente.cpf)}
                  </Box>
                  <Box
                    marginLeft="0px"
                    fontSize="15px"
                    fontWeight="bold"
                    display="flex"
                    flex={1}
                    alignItems="center"
                  >
                    <p
                      className={classes.textOverflow}
                      style={{
                        marginBottom: '0px',
                        color: colorHeader,
                        marginRight: '10px',
                        marginTop: '0px',
                      }}
                    >
                      CNPJ:
                    </p>
                    {formatCNPJ(cliente.cnpj)}
                  </Box>
                </Box>
                <Box
                  marginLeft="10px"
                  display="flex"
                  flexDirection="column"
                  flex={1}
                  alignItems="center"
                >
                  <Box
                    marginLeft="0px"
                    fontSize="15px"
                    fontWeight="bold"
                    display="flex"
                    flex={1}
                    justifyContent="flex-start"
                  >
                    <p
                      className={classes.textOverflow}
                      style={{
                        marginBottom: '0px',
                        color: colorHeader,
                        marginRight: '10px',
                      }}
                    >
                      Nome Fantasia:
                    </p>
                    {cliente.nome_fantasia}
                  </Box>
                  <Box
                    marginLeft="0px"
                    fontSize="15px"
                    fontWeight="bold"
                    display="flex"
                    flex={1}
                    justifyContent="flex-start"
                    alignItems="center"
                    width="100%"
                  >
                    <p
                      className={classes.textOverflow}
                      style={{
                        marginBottom: '0px',
                        color: colorHeader,
                        marginRight: '10px',
                        marginTop: '0px',
                      }}
                    >
                      Bairro:
                    </p>
                    {cliente.bairro}
                  </Box>
                  <Box
                    marginLeft="0px"
                    fontSize="15px"
                    fontWeight="bold"
                    display="flex"
                    flex={1}
                    justifyContent="flex-start"
                    alignItems="center"
                    width="100%"
                  >
                    <p
                      className={classes.textOverflow}
                      style={{
                        marginBottom: '0px',
                        color: colorHeader,
                        marginRight: '10px',
                        marginTop: '0px',
                      }}
                    >
                      Cidade:
                    </p>
                    {cliente.cidade
                      ? `${cliente.cidade.nome} - ${cliente.cidade.estado.sigla}`
                      : ''}
                  </Box>
                  <Box
                    marginLeft="0px"
                    fontSize="15px"
                    fontWeight="bold"
                    display="flex"
                    flex={1}
                    justifyContent="flex-start"
                    alignItems="center"
                    width="100%"
                  >
                    <p
                      className={classes.textOverflow}
                      style={{
                        marginBottom: '0px',
                        color: colorHeader,
                        marginRight: '10px',
                        marginTop: '0px',
                      }}
                    >
                      Logradouro:
                    </p>
                    {cliente.logradouro}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        padding="10px"
        className={classes.body}
      >
        <Box
          flex={1.5}
          display="flex"
          flexDirection="column"
          overflow="auto"
          justifyContent="space-between"
        >
          <Box>
            <LabelCaixa
              descontoDisponivelCliente={descontoDisponivelCliente}
              descontoUsadoCliente={descontoUsadoCliente}
              cliente={cliente}
              isEmPartes={isEmPartes}
            />
            <LabelTotalParcelas total={totalParcelas} totalRestante={resto} />
            <Box mt="15px">
              <LabelSubtotal valor={venda.subTotal} />
            </Box>
            <LabelTeclasAtalho
              grid={[
                [
                  { atalho: VOLTAR_ACTION, label: 'Voltar' },
                  {
                    atalho: FINALIZAR_ACTION,
                    label: 'Concluir',
                    status:
                      podeFecharVenda && !(resto < -1 * folga || resto > folga),
                    class:
                      resto < -1 * folga || resto > folga ? '' : 'btnImprimir',
                  },
                  { atalho: NOVO_CLIENTE_ACTION, label: 'Novo cliente' },
                ],
                [
                  {
                    atalho: EDITAR_CLIENTE_ACTION,
                    label: 'Editar cliente',
                    status: !(cliente === null ? true : cliente.id === 1),
                  },
                  {
                    atalho: DELETAR_ITEM_ACTION,
                    label: 'Remover parcela',
                    status: parcelas.length > 0,
                  },
                  { atalho: CONSULTOR_PRECOS_ACTION, label: 'Cons. Preço' },
                  {
                    atalho: CONSULTOR_CLIENTE_COMPATIBILIZACAO,
                    label: 'Comp. Cliente',
                  },
                ],
              ]}
            />
          </Box>
        </Box>
        <Box padding="0 10px" flex={4}>
          <Tabela
            rows={toStringParcela(parcelas)}
            headCells={[
              {
                field: 'numeroItem',
                label: 'Código',
              },
              {
                field: 'tipo_pagamento',
                label: 'Meio de Pagamento',
              },
              {
                field: 'valor',
                right: true,
                label: 'Valor',
              },
              {
                field: 'data_pagamento',
                right: true,
                label: 'Data de Pagamento',
              },
              {
                field: 'valor_recebido',
                right: true,
                label: 'Valor Recebido',
              },
              {
                field: 'troco',
                right: true,
                label: 'Troco',
              },
            ]}
          />
        </Box>
        <Box flex={1.5} display="flex" flexDirection="column">
          <FormParcela
            handleKey={(keyCode, keyName) => {
              handleActions(keyName);
            }}
            ref={refSidebarInputs}
            handleNewItem={(data) => {
              if (data.tipo_pagamento.id === 9) {
                if (refDialogoLiberarParcela.current) {
                  refDialogoLiberarParcela.current.handleOpen(data);
                }
              } else {
                handleNewItem(data);
              }
            }}
            resto={resto}
            validateParcela={getPodeUsarParcela}
            hasPagamentoDinheiro={hasPagamentoDinheiro}
            hasPagamentoNaoPadrao={hasPagamentoNaoPadrao}
            parcelas={parcelas}
            cliente={cliente}
            descontoDisponivelCliente={descontoDisponivelCliente}
            descontoUsadoCliente={descontoUsadoCliente}
          />
        </Box>
      </Box>
      <KeyboardEventHandler
        handleKeys={[
          VOLTAR_ACTION,
          FINALIZAR_ACTION,
          NOVO_CLIENTE_ACTION,
          DELETAR_ITEM_ACTION,
          EDITAR_CLIENTE_ACTION,
          CONSULTOR_CLIENTE_COMPATIBILIZACAO,
        ]}
        onKeyEvent={(key) => handleActions(key)}
      />
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
      <DialogoCliente
        ref={refDialogoCliente}
        handleClose={(idUpdate) => {
          if (refSidebarInputs.current) refSidebarInputs.current.focus();
          if (idUpdate > 0) {
            updateCliente(idUpdate);
          }
        }}
      />
      <DialogoSenha
        ref={refDialogoSenha}
        handleClose={() => {}}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={() => {
          irParaTelaFrentedeCaixa();
        }}
        mensagemSucesso="Venda liberada para edição"
        title="Autorização voltar à tela de itens!"
      />
      <DialogoDeletarComSenha
        ref={refDialogoDeletar}
        itens={parcelas}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={(numeroItem) => {
          removeItens([parcelas[numeroItem - 1].uidd]);
        }}
        title="Autorização para remover parcela!"
        mensagemRemovido="Parcela removida com sucesso!"
      />
      <DialogoConsultorPrecos ref={refDialogoConsultorPrecos} />
      <DialogoLiberarParcelaDesconto
        ref={refDialogoLiberarParcela}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={(data) => {
          handleNewItem(data);
        }}
      />
      <DialogoConsultorClienteCompatibilizacao
        handleSelecionado={() => {}}
        handleFechado={() => {}}
        ref={refDialogoConsultorClienteCompatibilizacao}
      />
    </>
  );
};

export default FinalizarVenda;
