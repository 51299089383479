import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';

import { Paper, Box, Button } from '@material-ui/core';
import moment from 'moment';

import { getListAllAPI } from '../../../../../../services';
import { A_VISTA } from '../../../../../../utils';
import {
  AutoCompleteLocal,
  DateInput,
  PrecoInput,
} from '../../../../../Inputs';

const SidebarInputs = forwardRef(
  (
    {
      handleNewItem,
      handleKey = () => {},
      disabledSubmit = false,
      resto,
      validateParcela,
      hasPagamentoDinheiro,
      hasPagamentoNaoPadrao,
      parcelas,
      margin = 'normal',
      cliente,
      descontoDisponivelCliente,
      descontoUsadoCliente,
    },
    ref,
  ) => {
    const [tipoPagamento, setTipoPagamento] = useState(null);
    const [valor, setValor] = useState(0);
    const [dataPagamento, setDataPagamento] = useState(
      moment().format('YYYY-MM-DD'),
    );
    const [valorRecebido, setValorRecebido] = useState(0);
    const [erros, setErros] = useState(['', '', '', '']);
    const [meioPagamentosOptions, setMeioPagamentosOptions] = useState([]);
    const [
      meioPagamentosUsaveisOptions,
      setMeioPagamentosUsaveisOptions,
    ] = useState([]);
    const refs = useRef([]);
    const refButton = useRef(null);

    const isFidelidade = cliente ? cliente.is_fidelidade : false;

    function updateOptionsUsaveis(meioPagamentosOptions) {
      const usaveisNew = [];
      let pagamentoDinheiro = null;
      let pagamentoCartao = null;
      for (let i = 0; i < meioPagamentosOptions.length; i += 1) {
        if (meioPagamentosOptions[i].id === 3) {
          pagamentoCartao = meioPagamentosOptions[i];
        }
        if (meioPagamentosOptions[i].id === 11) {
          if (isFidelidade) {
            usaveisNew.push(meioPagamentosOptions[i]);
          }
        } else if (meioPagamentosOptions[i].id === 1) {
          pagamentoDinheiro = meioPagamentosOptions[i];
          if (hasPagamentoDinheiro !== true) {
            usaveisNew.push(meioPagamentosOptions[i]);
          }
        } else if (
          meioPagamentosOptions[i].id === 3 ||
          meioPagamentosOptions[i].id === 4 ||
          meioPagamentosOptions[i].id === 5 ||
          meioPagamentosOptions[i].id === 7 ||
          meioPagamentosOptions[i].id === 8 ||
          meioPagamentosOptions[i].id === 9 ||
          meioPagamentosOptions[i].id === 10
        ) {
          usaveisNew.push(meioPagamentosOptions[i]);
        } else if (hasPagamentoNaoPadrao !== true) {
          usaveisNew.push(meioPagamentosOptions[i]);
        }
      }
      setMeioPagamentosUsaveisOptions(usaveisNew);
      if (hasPagamentoDinheiro === true) {
        setTipoPagamento(pagamentoCartao);
      } else {
        setTipoPagamento(pagamentoDinheiro);
      }
    }

    function updateOptionsUsaveis2(meioPagamentosOptions) {
      const usaveisNew = [];
      for (let i = 0; i < meioPagamentosOptions.length; i += 1) {
        if (meioPagamentosOptions[i].id === 11) {
          if (isFidelidade) {
            usaveisNew.push(meioPagamentosOptions[i]);
          }
        } else if (meioPagamentosOptions[i].id === 1) {
          if (hasPagamentoDinheiro !== true) {
            usaveisNew.push(meioPagamentosOptions[i]);
          }
        } else if (
          meioPagamentosOptions[i].id === 3 ||
          meioPagamentosOptions[i].id === 4 ||
          meioPagamentosOptions[i].id === 5 ||
          meioPagamentosOptions[i].id === 7 ||
          meioPagamentosOptions[i].id === 8 ||
          meioPagamentosOptions[i].id === 9 ||
          meioPagamentosOptions[i].id === 10
        ) {
          usaveisNew.push(meioPagamentosOptions[i]);
        } else if (hasPagamentoNaoPadrao !== true) {
          usaveisNew.push(meioPagamentosOptions[i]);
        }
      }
      setMeioPagamentosUsaveisOptions(usaveisNew);
    }

    useEffect(() => {
      async function getMeioPagamentos() {
        const dataTiposPagamento = await getListAllAPI(
          'tipospagamento',
          ['id', 'asc'],
          { ativo: true },
          [],
        );

        setMeioPagamentosOptions(dataTiposPagamento.data);
        updateOptionsUsaveis(dataTiposPagamento.data);
      }
      getMeioPagamentos();
    }, []);

    useEffect(() => {
      updateOptionsUsaveis(meioPagamentosOptions);
    }, [parcelas]);

    useEffect(() => {
      updateOptionsUsaveis2(meioPagamentosOptions);
    }, [cliente]);

    function hideValorRecebido() {
      return !tipoPagamento
        ? true
        : tipoPagamento.dinheiro_vivo && tipoPagamento.modalidade === A_VISTA;
    }

    function disabledDataPagamento() {
      return !tipoPagamento ? true : tipoPagamento.modalidade === A_VISTA;
    }

    function validateTipoPagamento() {
      let error = '';
      if (!tipoPagamento) {
        error = 'Este campo é obrigatório';
      } else {
        const { status, message } = validateParcela(tipoPagamento.id);
        if (status === false) {
          error = message;
        }
      }
      return error;
    }

    function validateValor() {
      let error = '';
      if (valor <= 0 || Number.isNaN(valor)) {
        error = 'Este campo é obrigatório';
      } else if (tipoPagamento) {
        if (tipoPagamento.id === 11) {
          if (valor > descontoDisponivelCliente - descontoUsadoCliente) {
            error =
              'Este valor é maior que o desconto disponível para este cliente!';
          } else if (valor % 100 !== 0) {
            error = 'Este valor deve ser múltplo de 100!';
          }
        }
      }

      return error;
    }

    function validateDataPagamento() {
      let error = '';
      if (dataPagamento === null || dataPagamento === '')
        error = 'Data Inválida!';
      return error;
    }

    function validateValorRecebido() {
      let error = '';
      if (tipoPagamento) {
        if (
          tipoPagamento.dinheiro_vivo &&
          tipoPagamento.modalidade === A_VISTA
        ) {
          if (valorRecebido <= 0 || Number.isNaN(valorRecebido)) {
            error = 'Este campo é obrigatório';
          } else if (valorRecebido < valor)
            error =
              'O valor recebido não poder ser menor que o valor da parcela!';
        }
      }
      return error;
    }

    function getErros() {
      const errosOld = erros.slice();
      errosOld[0] = validateTipoPagamento();
      errosOld[1] = validateValor();
      errosOld[2] = validateDataPagamento();
      errosOld[3] = validateValorRecebido();
      return errosOld;
    }

    useEffect(() => {
      setErros(getErros());
    }, [tipoPagamento, valor, dataPagamento, valorRecebido]);

    const inputs = [
      {
        nome: 'tipo_pagamento',
        defaultValue: null,
        label: 'Meio de pagamento',
        resource: 'tipospagamento',
        nested: [],
        filters: { ativo: true },
      },
      {
        nome: 'valor',
        defaultValue: 0,
        label: 'Valor real da parcela',
      },
      {
        nome: 'data_pagamento',
        defaultValue: moment().format('YYYY-MM-DD'),
        label: 'Data de Pagamento',
      },
      {
        nome: 'valor_recebido',
        defaultValue: 0,
        label: 'Valor bruto recebido do cliente',
      },
    ];

    refs.current = inputs.map(
      (ref, index) => (refs.current[index] = React.createRef()),
    );

    function resetValues() {
      setErros(['', '', '', '']);
      setValor(inputs[1].defaultValue);
      setDataPagamento(inputs[2].defaultValue);
      setValorRecebido(inputs[3].defaultValue);
    }

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    function buildData(data) {
      const ano = parseInt(data.substring(0, 4), 10);
      const mes = parseInt(data.substring(5, 7), 10) - 1;
      const dia = parseInt(data.substring(8, 10), 10);

      const dataFormatada = new Date();
      dataFormatada.setFullYear(ano);
      dataFormatada.setDate(dia);
      dataFormatada.setMonth(mes);
      dataFormatada.setDate(dia);

      return dataFormatada;
    }

    function handleSubmit() {
      if (!hasError()) {
        if (refs.current[0].current) {
          refs.current[0].current.focus();
          refs.current[0].current.select();
        }
        handleNewItem({
          tipo_pagamento: tipoPagamento,
          valor: parseFloat(valor),
          data_pagamento: buildData(dataPagamento),
          valor_recebido: parseFloat(valorRecebido),
          troco: parseFloat(valorRecebido) - parseFloat(valor),
        });
        resetValues();
      }
    }

    useImperativeHandle(ref, () => ({
      focus(index = 0) {
        if (refs.current[index].current) {
          refs.current[index].current.focus();
          refs.current[index].current.select();
        }
      },
      submit() {
        handleSubmit();
      },
    }));

    function getRefNextInput(index) {
      let position = -1;
      switch (index) {
        case 0:
          position = 1;
          break;
        case 1:
          if (!disabledDataPagamento()) position = 2;
          else if (hideValorRecebido()) position = 3;
          if (Number.isNaN(parseFloat(valor))) setValor(resto);
          break;
        case 2:
          if (hideValorRecebido()) position = 3;
          break;
        default:
          break;
      }
      return position;
    }

    function handleNextInput(index) {
      const position = getRefNextInput(index);
      if (position === -1) {
        if (refButton.current && !disabledSubmit) {
          refButton.current.click();
        }
      } else if (refs.current[position].current) {
        refs.current[position].current.focus();
        refs.current[position].current.select();
      }
    }

    return (
      <Paper elevation={3} style={{ opacity: '0.75' }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          padding="15px"
          overflow="auto"
        >
          <AutoCompleteLocal
            name={inputs[0].nome}
            ref={refs.current[0]}
            handleEnter={() => handleNextInput(0)}
            label={inputs[0].label}
            handleKey={handleKey}
            value={tipoPagamento}
            onChangeLocal={(value) => setTipoPagamento(value)}
            error={erros[0] !== ''}
            helperText={erros[0]}
            campo="nome"
            options={meioPagamentosUsaveisOptions}
            margin={margin}
          />
          <PrecoInput
            name={inputs[1].nome}
            ref={refs.current[1]}
            handleEnter={() => handleNextInput(1)}
            label={inputs[1].label}
            handleKey={handleKey}
            value={Number.isNaN(valor) ? '' : String(valor)}
            onChange={(value) => setValor(parseFloat(value.target.value))}
            error={erros[1] !== ''}
            helperText={erros[1]}
            margin={margin}
          />
          <DateInput
            name={inputs[2].nome}
            ref={refs.current[2]}
            handleEnter={() => handleNextInput(2)}
            label={inputs[2].label}
            handleKey={handleKey}
            value={dataPagamento}
            onChange={(value) => setDataPagamento(value.target.value)}
            error={erros[2] !== ''}
            helperText={erros[2]}
            disabled={disabledDataPagamento()}
            margin={margin}
          />
          {hideValorRecebido() && (
            <PrecoInput
              name={inputs[3].nome}
              ref={refs.current[3]}
              handleEnter={() => handleNextInput(3)}
              label={inputs[3].label}
              handleKey={handleKey}
              value={Number.isNaN(valorRecebido) ? '' : String(valorRecebido)}
              onChange={(value) =>
                setValorRecebido(parseFloat(value.target.value))
              }
              error={erros[3] !== ''}
              helperText={erros[3]}
              margin={margin}
            />
          )}
          {(!tipoPagamento
            ? true
            : tipoPagamento.dinheiro_vivo &&
              tipoPagamento.modalidade === A_VISTA) && (
            <PrecoInput
              name="troco"
              handleEnter={() => {}}
              label="Troco"
              handleKey={handleKey}
              value={
                Number.isNaN(valorRecebido - valor)
                  ? ''
                  : String(valorRecebido - valor)
              }
              onChange={() => {}}
              disabled
              margin={margin}
            />
          )}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            ref={refButton}
            disabled={hasError()}
          >
            Adicionar à lista
          </Button>
        </Box>
      </Paper>
    );
  },
);

export default SidebarInputs;
