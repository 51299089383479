import {
  renameParcelasCompraUIDD,
  renameItensUIDD,
  getSubTotal,
} from '../../../../utils';

const reducer = (state, action) => {
  let arrayNew = state.lotes.slice();
  let arrayNewParcelas = state.parcelas.slice();
  switch (action.type) {
    case 'ADD_LOTE':
      return {
        ...state,
        lotes: [...arrayNew, action.lote],
        subTotal: getSubTotal([...arrayNew, action.lote]),
      };
    case 'REMOVE_LOTE':
      for (let i = 0; i < action.indices.length; i += 1) {
        arrayNew = arrayNew.filter((obj) => obj.uidd !== action.indices[i]);
      }
      return {
        ...state,
        lotes: [...renameItensUIDD(arrayNew)],
        subTotal: getSubTotal([...arrayNew]),
      };
    case 'UPDATE_LOTES':
      return {
        ...state,
        lotes: [...renameItensUIDD(action.lotes)],
        subTotal: getSubTotal([...action.lotes]),
      };

    case 'UPDATE_FORNECEDOR':
      return {
        ...state,
        fornecedor: action.fornecedor,
      };

    case 'UPDATE_NUMERO_NOTA_FISCAL':
      return {
        ...state,
        numeroNotaFiscal: action.numeroNotaFiscal,
      };

    case 'UPDATE_DATA_FATURAMENTO':
      return {
        ...state,
        data_faturamento: action.data_faturamento,
      };

    case 'ADD_PARCELA':
      return {
        ...state,
        parcelas: [...arrayNewParcelas, action.parcela],
      };
    case 'REMOVE_PARCELA':
      for (let i = 0; i < action.indices.length; i += 1) {
        arrayNewParcelas = arrayNewParcelas.filter(
          (obj) => obj.uidd !== action.indices[i],
        );
      }
      return {
        ...state,
        parcelas: [...renameParcelasCompraUIDD(arrayNewParcelas)],
      };
    case 'UPDATE_PARCELAS':
      return {
        ...state,
        parcelas: [...renameParcelasCompraUIDD(action.parcelas)],
      };

    case 'UPDATE_ID_EDIT':
      return {
        ...state,
        idEdit: action.idEdit,
      };

    case 'UPDATE_LOJA':
      return {
        ...state,
        loja: action.loja,
      };

    case 'RESETAR_TUDO':
      return {
        ...state,
        idEdit: -1,
        lotes: [],
        parcelas: [],
        subTotal: 0,
        fornecedor: null,
        numeroNotaFiscal: '',
        data_faturamento: '',
        data_criacao: new Date(),
        loja: {
          nome: 'CARUARU FRIOS',
          value: 'CF',
        },
      };

    case 'PREENCHER_TUDO':
      return {
        ...state,
        idEdit: action.idEdit,
        lotes: [...renameItensUIDD(action.lotes)],
        subTotal: getSubTotal([...action.lotes]),
        parcelas: [...renameParcelasCompraUIDD(action.parcelas)],
        fornecedor: action.fornecedor,
        numeroNotaFiscal: action.numeroNotaFiscal,
        data_faturamento: action.data_faturamento,
        data_criacao: action.data_criacao,
        loja: action.loja,
      };
    default:
      return state;
  }
};

export default reducer;
