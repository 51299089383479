import React from 'react';

import { Box, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import HelpIcon from '@material-ui/icons/Help';

const Dica = ({ title, description = '' }) => (
  <Box>
    <Typography>{title}</Typography>
    <p>{description}</p>
  </Box>
);

export default ({ legenda: { title, description } }) => (
  <Tooltip
    TransitionComponent={Zoom}
    title={<Dica title={title} description={description} />}
  >
    <HelpIcon style={{ cursor: 'pointer' }} />
  </Tooltip>
);
