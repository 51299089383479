import React, {
  useRef,
} from 'react';
import { useReactToPrint } from 'react-to-print';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import DialogoBoletos from '../DialogoBoletos';
import NotaFC from './NotaFCThermalPrinter';
import NotaFCCompra from './NotaFCThermalPrinterCompra';

const useStyles = makeStyles(() => createStyles({
  action: {
    backgroundColor: '#F2EFEA',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function createPageStyle(tam) {
  return `
  @page {
    size: 73mm ${tam}mm;
    margem: 0;
    padding: 0
  }

  @media all {
    .pagebreak {
      display: none;
      margem: 0;
      padding: 0
    }
  }

  @media print and (min-resolution: 300dpi){
    .pagebreak {
      margin-top: 0;
      display: block;
      page-break-before: avoid;
      margin: 0;
      padding: 0;
    }

    html, body {
      height: initial !important;
      overflow: initial !important;
      margin: 0;
      padding: 0;
    }
  }

`;
}

const DialogoNota = ({
  transacao,
  empresa,
  boletos,
  open,
  handleCloseDialog,
  tipoNota,
  tipoTransacao,
}) => {
  const componentRef = React.useRef(null);

  const classes = useStyles();
  const refDialogoBoletos = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => (componentRef.current ? componentRef.current : null),
    pageStyle: createPageStyle(300),
    documentTitle: 'Nota_',
  });

  const handleBoletos = () => {
    if (refDialogoBoletos.current) {
      refDialogoBoletos.current.handleOpen();
    }
  };

  const handleClose = () => {
    handleCloseDialog();
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        fullScreen
      >
        <DialogContent style={{ padding: '0', margin: '0' }}>
          <div style={{
            width: '100%', display: 'flex', justifyContent: 'center',
          }}
          >
            <div style={{
              width: '75mm',
              border: '1px solid black',
              backgroundColor: '#f9f7ce',
            }}
            >
              { tipoTransacao === 'venda'
                ? (
                  <NotaFC
                    ref={componentRef}
                    venda={transacao}
                    empresa={empresa}
                    showItens
                    showParcelas={tipoNota === 0}
                    showDadosCliente
                    showHeader
                    showFooter
                    tipoTransacao={tipoTransacao}
                  />
                )
                : (
                  <NotaFCCompra
                    ref={componentRef}
                    venda={transacao}
                    empresa={empresa}
                    showItens
                    showParcelas={tipoNota === 0}
                    showDadosCliente
                    showHeader
                    showFooter
                    tipoTransacao={tipoTransacao}
                  />
                )}
            </div>
          </div>

        </DialogContent>
        <DialogActions className={classes.action}>
          <div>
            {boletos.length > 0 && (
            <Button
              onClick={handleBoletos}
              color="secondary"
              variant="contained"
              style={{ marginRight: '10px' }}
            >
              Boletos
            </Button>
            )}
            <Button
              color="secondary"
              variant="contained"
              onClick={handleClose}
              style={{ marginRight: '10px' }}
            >
              Fechar
            </Button>
            <Button
              onClick={handlePrint}
              color="secondary"
              variant="contained"
              autoFocus
            >
              Imprimir
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <DialogoBoletos ref={refDialogoBoletos} boletos={boletos} />
    </div>
  );
};

export default DialogoNota;
