import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  // TextInput,,
  PorcentagemInput,
  // PesoGramasInput,
  CfopInput,
  CstInput,
  NcmInput,
  IntegerInput,
  PesoInput,
  PrecoInput,
  DateInput,
  CestInput,
} from '../../../..';
import { updateAPI, getListAllAPI } from '../../../../../services';
import { POR_UNIDADE, POR_PESO_FIXO, formatMoeda } from '../../../../../utils';
import DialogoCreateImpressao from '../../../DialogoProduto2/DialogoCreateImpressao';
import DialogoComposicaoBaseCalculo from './DialogoComposicaoBaseCalculo';
import DialogoDetalhesPrecificacao from './DialogoDetalhesPrecificacao';
import DicaInput from './DicaInput';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {/* <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography> */}
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function formatPreco(valor) {
  return valor !== null && valor !== undefined
    ? `R$: ${valor.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`
    : '';
}

function formatPorcentagem(valor) {
  return valor !== null && valor !== undefined
    ? `${valor.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} %`
    : '';
}

const DEFAULT_ID = -1;

const inputs = [
  {
    nome: 'ipi',
    defaultValue: 0,
    label: 'IPI (%)',
    labelCompleto: 'Imposto sobre Produtos Industrializados',
  }, // 0
  {
    nome: 'pis',
    defaultValue: 0,
    label: 'PIS (%)',
    labelCompleto: 'Programa de Integração Social',
  }, // 1
  {
    nome: 'cofins',
    defaultValue: 0,
    label: 'COFINS (%)',
    labelCompleto: 'Contribuição para Financiamento da Seguridade Social',
  }, // 2
  {
    nome: 'icms_ai',
    defaultValue: 0,
    label: 'ICMS AI (%)',
    labelCompleto:
      'Imposto sobre Circulação de Mercadorias e Serviços Interestadual',
  }, // 3
  {
    nome: 'icms_int',
    defaultValue: 0,
    label: 'ICMS INT (%)',
    labelCompleto: 'Imposto sobre Circulação de Mercadorias e Serviços',
  }, // 4
  {
    nome: 'df',
    defaultValue: 0,
    label: 'DF (%)',
    labelCompleto: 'Desoneração da Folha',
  }, // 5
  {
    nome: 'rbc',
    defaultValue: 0,
    label: 'RBC',
    labelCompleto: '',
  }, // 6
  {
    nome: 'ncm',
    defaultValue: '',
    label: 'NCM',
    labelCompleto: 'Nomenclatura Comum do Mercosul',
  }, // 7
  {
    nome: 'cst',
    defaultValue: '',
    label: 'CST',
    labelCompleto: 'Código de Situação Tributária ICMS de Entrada e Saída',
  }, // 8
  {
    nome: 'cfop',
    defaultValue: '',
    label: 'CFOP',
    labelCompleto: 'Código Fiscal de Operações e Prestações',
  }, // 9
  {
    nome: 'lb',
    defaultValue: '',
    label: 'Lucro (%)',
    labelCompleto: 'Lucro Bruto',
  }, // 10
  {
    nome: 'desconto_caixaria',
    defaultValue: '',
    label: 'Desconto Caixaria',
    labelCompleto: 'Desconto Caixaria',
  }, // 11
  {
    nome: 'qtde',
    defaultValue: '',
    label: 'Qtde',
    labelCompleto: '',
  }, // 12
  {
    nome: 'bc',
    defaultValue: '',
    label: 'Base de Cálculo',
    labelCompleto: 'Base de Cálculo',
  }, // 13
  {
    nome: 'cg',
    defaultValue: '',
    label: 'CG',
    labelCompleto: '',
  }, // 14
  {
    nome: 'validade',
    defaultValue: moment().format('YYYY-MM-DD'),
    label: 'Validade',
    labelCompleto: 'Validade',
  }, // 15
  {
    nome: 'cst_pis_entrada',
    defaultValue: '',
    label: 'CST PIS Entrada',
    labelCompleto: 'Código de Situação Tributária PIS de Entrada',
  }, // 16
  {
    nome: 'cst_pis_saida',
    defaultValue: '',
    label: 'CST PIS Saída',
    labelCompleto: 'Código de Situação Tributária PIS de Saída',
  }, // 17
  {
    nome: 'cst_cofins_entrada',
    defaultValue: '',
    label: 'CST COFINS Entrada',
    labelCompleto: 'Código de Situação Tributária COFINS de Entrada',
  }, // 18
  {
    nome: 'cst_cofins_saida',
    defaultValue: '',
    label: 'CST COFINS Saída',
    labelCompleto: 'Código de Situação Tributária COFINS de Saída',
  }, // 19
  {
    nome: 'cest',
    defaultValue: '',
    label: 'CEST',
    labelCompleto: 'Código Especificador da Substituição Tributária',
  }, // 20
];

const legendas = {
  bc: {
    title: 'Base de cálculo',
    description: '',
  },
  qtde: {
    title: 'Quantidade',
    description: '',
  },
  cg: {
    title: 'Custo geral por unidade',
    description: '',
  },
  lb: {
    title: 'Lucro bruto',
    description: '',
  },
  descont_caixaria: {
    title: 'Desconto na caixaria',
    description: '',
  },
  validade: {
    title: 'Validade',
    description: '',
  },
  ipi: {
    title: 'Imposto sobre Produtos Industrializados',
    description: '',
  },
  pis: {
    title: 'Programa de Integração Social',
    description: '',
  },
  confins: {
    title: 'Contribuição para Financiamento da Seguridade Social',
    description: '',
  },
  icms_ai: {
    title:
      'Imposto sobre Circulação de Mercadorias e Serviços Alíquota Interestadual',
    description: '',
  },
  icms_int: {
    title:
      'Imposto sobre Circulação de Mercadorias e Serviços Alíquota Interna',
    description: '',
  },
  df: {
    title: 'Desoneração da Folha',
    description: '',
  },
  rbc: {
    title: 'Redução da Base de Cálculo',
    description: '',
  },
  irpj: {
    title: 'Imposto de Rendas de Pessoa Jurídica',
    description: '',
  },
  csll: {
    title: 'Contribuição Social sobre o Lucro Líquido',
    description: '',
  },
  ncm: {
    title: 'Nomenclatura Comum do Mercosul',
    description: '',
  },
  cst: {
    title: 'Código de Situação Tributária',
    description: '',
  },
  cfop: {
    title: 'Código Fiscal de Operações e Prestações',
    description: '',
  },
  preco_compra: {
    title: 'Preço de Compra',
    description: '',
  },
  preco_compra_imposto: {
    title: 'Preço de Compra com Imposto',
    description: '',
  },
  preco_venda: {
    title: 'Preço de Venda',
    description: '',
  },
  ppc: {
    title: 'Provisão Perdas de Cartão',
    description: '',
  },
  pparf: {
    title: 'Provisão Perdas por Avarias',
    description: '',
  },
  pcf: {
    title: 'Provisão de Custos Fixos',
    description: '',
  },

  cst_pis_entrada: {
    title: 'Código de Situação Tributária PIS de Entrada',
    description: '',
  },
  cst_pis_saida: {
    title: 'Código de Situação Tributária PIS de Saída',
    description: '',
  },
  cst_cofins_entrada: {
    title: 'Código de Situação Tributária COFINS de Entrada',
    description: '',
  },
  cst_cofins_saida: {
    title: 'Código de Situação Tributária COFINS de Saída',
    description: '',
  },
  cest: {
    title: 'Código Especificador da Substituição Tributária',
    description: '',
  },
};

const BC_POSITION = 0;
const QTDE_POSITION = 1;
const CG_POSITION = 2;
const LB_POSITION = 3;
const DESCONTO_CAIXARIA_POSITION = 4;
const VALIDADE_POSITION = 5;

const IPI_POSITION = 6;
const PIS_POSITION = 7;
const COFINS_POSITION = 8;
const ICMS_AI_POSITION = 9;
const ICMS_INT_POSITION = 10;
const DF_POSITION = 11;
const RBC_POSITION = 12;
const NCM_POSITION = 13;
const CST_POSITION = 14;
const CFOP_POSITION = 15;

const CST_PIS_ENTRADA_POSITION = 16;
const CST_PIS_SAIDA_POSITION = 17;
const CST_COFINS_ENTRADA_POSITION = 18;
const CST_COFINS_SAIDA_POSITION = 19;
const CEST_POSITION = 20;

function calculatePrecoVenda(dadosLoteAtual, dadosProduto, dadosLoja) {
  const { bc, qtde, cg, cn } = dadosLoteAtual;
  const {
    lb,
    ipi,
    pis,
    cofins,
    icms_ai,
    icms_int,
    rbc,
    df,
    cst,
    cfop,
    ncm,
  } = dadosProduto;
  const { ppc, irpj, csll, pcf, pparf } = dadosLoja;

  const provisoes = ppc + pcf + pparf;

  // const cn = bc / qtde;
  const ipi_c = (ipi / 100) * cn;
  const pis_c = (pis / 100) * cn;
  const cofins_c = (cofins / 100) * cn;
  const icms_ai_c = (icms_ai / 100) * cn;
  const icms_int_c = icms_int * (rbc / 100);

  const cli = cn - (ipi_c + pis_c + cofins_c + icms_ai_c);

  const pv =
    (cli * (1 + lb / 100)) /
      (1 - (ipi + pis + cofins + icms_int_c + provisoes) / 100) +
    cg;

  const ipi_v = (ipi / 100) * pv;
  const pis_v = (pis / 100) * pv;

  const icms_int_s = (icms_int_c / 100) * pv;
  const lb_c = pv * (lb / 100);
  const irpj_c = (irpj / 100) * lb_c;
  const csll_c = (csll / 100) * lb_c;
  const ebitida_c = lb_c - (csll_c + irpj_c);
  const ebitida = (ebitida_c / pv) * 100;

  const cni = cli / (1 - (ipi + pis + cofins + icms_int_c + provisoes) / 100);
  const cf = cni + cg;

  const ppc_c = (ppc / 100) * pv;

  const pcf_c = (pcf / 100) * pv;

  const pparf_c = (pparf / 100) * pv;

  return {
    bc,
    qtde,
    cn,
    cg,
    lb,
    lb_c,
    pv,
    cni,
    cf,
    ebitida,
    ebitida_c,
    ipi,
    ipi_c,
    pis,
    pis_c,
    cofins,
    cofins_c,
    icms_ai,
    icms_ai_c,
    cli,
    ipi_v,
    pis_v,
    icms_int,
    icms_int_c,
    icms_int_s,
    ppc,
    ppc_c,
    irpj,
    irpj_c,
    csll,
    csll_c,
    ncm,
    df,
    cst,
    cfop,
    rbc,
    pv_lucro: pv - lb_c,
    pcf,
    pcf_c,
    pparf,
    pparf_c,
  };
}

function getCn(bc, qtde, pesoMedio, tipoUnidade) {
  if (tipoUnidade === POR_UNIDADE) {
    return bc / qtde;
  }

  if (tipoUnidade === POR_PESO_FIXO) {
    return bc / (qtde * pesoMedio);
  }

  return bc / qtde;
}

function getBc(unitario, qtde, pesoMedio, tipoUnidade) {
  if (tipoUnidade === POR_UNIDADE) {
    return unitario * qtde;
  }

  if (tipoUnidade === POR_PESO_FIXO) {
    return unitario * (qtde * pesoMedio);
  }

  return unitario * qtde;
}

function formatDadosPrecificacao(item) {
  return {
    bc: formatPreco(item.bc),
    qtde: item.qtde,
    cn: formatPreco(item.cn),
    cg: formatPreco(item.cg),
    lb: formatPorcentagem(item.lb),
    lb_c: formatPreco(item.lb_c),
    pv: formatPreco(item.pv),
    cni: formatPreco(item.cni),
    cf: formatPreco(item.cf),
    ebitida: formatPorcentagem(item.ebitida),
    ebitida_c: formatPreco(item.ebitida_c),
    pv_lucro: formatPreco(item.pv_lucro),
    ipi: formatPorcentagem(item.ipi),
    ipi_c: formatPreco(item.ipi_c),
    pis: formatPorcentagem(item.pis),
    pis_c: formatPreco(item.pis_c),
    cofins: formatPorcentagem(item.cofins),
    cofins_c: formatPreco(item.cofins_c),
    icms_ai: formatPorcentagem(item.icms_ai),
    icms_ai_c: formatPreco(item.icms_ai_c),
    cli: formatPreco(item.cli),
    ipi_v: formatPreco(item.ipi_v),
    pis_v: formatPreco(item.pis_v),
    icms_int: formatPorcentagem(item.icms_int),
    icms_int_c: formatPreco(item.icms_int_c),
    icms_int_s: formatPreco(item.icms_int_s),
    ppc: formatPorcentagem(item.ppc),
    ppc_c: formatPreco(item.ppc_c),
    pparf: formatPorcentagem(item.pparf),
    pparf_c: formatPreco(item.pparf_c),
    pcf: formatPorcentagem(item.pcf),
    pcf_c: formatPreco(item.pcf_c),
    irpj: formatPorcentagem(item.irpj),
    irpj_c: formatPreco(item.irpj_c),
    csll: formatPorcentagem(item.csll),
    csll_c: formatPreco(item.csll_c),
    ncm: item.ncm,
    df: formatPorcentagem(item.df),
    cst: item.cst,
    cfop: item.cfop,
    rbc: formatPorcentagem(item.rbc),
  };
}

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose, idCompra }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [nome, setNome] = useState(0);

    const [id, setId] = useState(DEFAULT_ID);

    const [isNota, setIsNota] = useState(true);

    const [usarBaseCalculo, setUsarBaseCalculo] = useState(false);

    const [manterPrecoAnterior, setManterPrecoAnterior] = useState(false);

    const [ipi, setIpi] = useState(0);
    const [pis, setPis] = useState(0);
    const [cofins, setCofins] = useState(0);
    const [icmsAi, setIcmsAi] = useState(0);
    const [icmsInt, setIcmsInt] = useState(0);
    const [df, setDf] = useState(0);
    const [rbc, setRbc] = useState(0);
    const [ncm, setNcm] = useState('');
    const [cst, setCst] = useState('');
    const [cfop, setCfop] = useState('');
    const [lb, setLb] = useState(10);
    const [validade, setValidade] = useState(moment().format('YYYY-MM-DD'));

    const [cstPisEntrada, setCstPisEntrada] = useState('');
    const [cstPisSaida, setCstPisSaida] = useState('');
    const [cstCofinsEntrada, setCstCofinsEntrada] = useState('');
    const [cstCofinsSaida, setCstCofinsSaida] = useState('');
    const [cest, setCest] = useState('');

    const [descontoCaixaria, setDescontoCaixaria] = useState(10);

    const [irpj, setIrpj] = useState(0);
    const [csll, setCsll] = useState(0);

    const [ppc, setPpc] = useState(0);
    const [pparf, setPparf] = useState(0);
    const [pcf, setPcf] = useState(0);

    const [tipoUnidade, setTipoUnidade] = useState('');
    const [pesoMedio, setPesoMedio] = useState(0);

    const [isImpostos, setIsImpostos] = useState(false);

    const [bc, setBc] = useState(0);

    const [cg, setCg] = useState(0);

    const [qtde, setQtde] = useState(0);

    const refDialogoDetalhes = useRef(null);

    const refDialogoImpressao = useRef(null);

    const refDialogoComposicaoBaseCalculo = useRef(null);

    const [precoVendaOriginal, setPrecoVendaOriginal] = useState(0);

    const refsInputs = useRef([]);

    refsInputs.current = inputs.map(
      (ref, index) => (refsInputs.current[index] = React.createRef()),
    );

    const [carregando, setCarregando] = useState(false);

    const classes = useStyles({ cor: '#009FD4' });

    const handleCloseDialog = (dadosRetorno = null) => {
      setOpen(false);
      handleClose(dadosRetorno);
    };

    async function getDataResource(codigo) {
      try {
        const data = await getListAllAPI(
          'produtos',
          ['id', 'asc'],
          { codigo },
          ['dadosImposto', 'unidade'],
        );

        const config = await getListAllAPI(
          'config',
          ['id', 'asc'],
          { id: 1 },
          [],
        );

        if (data.data.length > 0 && config.data.length > 0) {
          setId(data.data[0].id);
          setNome(data.data[0].nome);
          //
          setIpi(data.data[0].dadosImposto[0].ipi);
          setPis(data.data[0].dadosImposto[0].pis);
          setCofins(data.data[0].dadosImposto[0].cofins);
          setIcmsAi(data.data[0].dadosImposto[0].icms_ai);
          setIcmsInt(data.data[0].dadosImposto[0].icms_int);
          setDf(data.data[0].dadosImposto[0].df);
          setRbc(data.data[0].dadosImposto[0].rbc);
          setNcm(data.data[0].dadosImposto[0].ncm);
          setCst(data.data[0].dadosImposto[0].cst);
          setCfop(data.data[0].dadosImposto[0].cfop);
          //
          setCstPisEntrada(data.data[0].dadosImposto[0].cst_pis_entrada);
          setCstPisSaida(data.data[0].dadosImposto[0].cst_pis_saida);
          setCstCofinsEntrada(data.data[0].dadosImposto[0].cst_cofins_entrada);
          setCstCofinsSaida(data.data[0].dadosImposto[0].cst_cofins_saida);
          setCest(data.data[0].dadosImposto[0].cest);
          //
          setIrpj(config.data[0].irpj);
          setCsll(config.data[0].csll);
          //
          setPpc(config.data[0].ppc);
          setPparf(config.data[0].pparf);
          setPcf(config.data[0].pcf);
          //
          setTipoUnidade(data.data[0].unidade.tipo);
          setPesoMedio(data.data[0].peso_medio);
          setLb(data.data[0].lucro_precificacao);
          setIsImpostos(data.data[0].is_impostos);
          setDescontoCaixaria(data.data[0].desconto_caixa);
          //
          setBc(0);
          setCg(0);
          setQtde(0);
          //
          setPrecoVendaOriginal(data.data[0].preco_venda);
          setUsarBaseCalculo(false);
          setManterPrecoAnterior(idCompra > 0);
          //
          setIsNota(true);
          setCarregando(false);
        } else {
          throw 'Este produto não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function handleSalvar() {
      setCarregando(true);
      try {
        await updateAPI('update_produto_processo_precificacao', id, {
          ipi,
          pis,
          cofins,
          icms_ai: icmsAi,
          icms_int: icmsInt,
          ncm,
          df,
          cst,
          cfop,
          rbc,
          preco_venda: dadosPrecificacao.pv,
          lb,
          desconto_caixa: descontoCaixaria,
          manterPrecoAnterior,
          //
          cst_pis_entrada: cstPisEntrada,
          cst_pis_saida: cstPisSaida,
          cst_cofins_entrada: cstCofinsEntrada,
          cst_cofins_saida: cstCofinsSaida,
          cest,
        });
        if (!manterPrecoAnterior) {
          if (refDialogoImpressao.current) {
            refDialogoImpressao.current.handleCreate(id);
          }
        }
        setCarregando(false);
        enqueueSnackbar('Produto atualizado com sucesso!', {
          variant: 'success',
        });
        handleCloseDialog({
          cg,
          preco_compra: dadosPrecificacao.cn,
          preco_compra_impostos: dadosPrecificacao.cni,
          qtde,
          validade,
        });
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleOpen(codigo) {
        setCarregando(true);
        getDataResource(codigo);
        setOpen(true);
      },
    }));

    function validateIpi() {
      let error = '';
      if (ipi < 0 || Number.isNaN(ipi)) {
        error = 'Este campo é obrigatório';
      } else if (ipi > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
      return error;
    }

    function validatePis() {
      let error = '';
      if (pis < 0 || Number.isNaN(pis)) {
        error = 'Este campo é obrigatório';
      } else if (pis > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
      return error;
    }

    function validateCofins() {
      let error = '';
      if (cofins < 0 || Number.isNaN(cofins)) {
        error = 'Este campo é obrigatório';
      } else if (cofins > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
      return error;
    }

    function validateIcmsAi() {
      let error = '';
      if (icmsAi < 0 || Number.isNaN(icmsAi)) {
        error = 'Este campo é obrigatório';
      } else if (icmsAi > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
      return error;
    }

    function validateIcmsInt() {
      let error = '';
      if (icmsInt < 0 || Number.isNaN(icmsInt)) {
        error = 'Este campo é obrigatório';
      } else if (icmsInt > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
      return error;
    }

    function validateDf() {
      let error = '';
      if (df < 0 || Number.isNaN(df)) {
        error = 'Este campo é obrigatório';
      } else if (df > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
      return error;
    }

    function validateRbc() {
      let error = '';
      if (rbc < 0 || Number.isNaN(rbc)) {
        error = 'Este campo é obrigatório';
      } else if (rbc > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
      return error;
    }

    function validateNcm() {
      let error = '';
      if (ncm) {
        if (ncm.length < 8) error = 'O NCM deve ter 8 dígitos';
      }
      return error;
    }

    function validateCst() {
      let error = '';
      if (cst) {
        if (cst.length < 3) error = 'O CST deve ter 3 dígitos';
      }
      return error;
    }

    function validateCfop() {
      let error = '';
      if (cfop) {
        if (cfop.length < 4) error = 'O CFOP deve ter 4 dígitos';
      }
      return error;
    }

    function validateCstPisEntrada() {
      let error = '';
      if (cstPisEntrada) {
        if (cstPisEntrada.length < 3)
          error = 'O CST PIS ENTRADA deve ter 3 dígitos';
      }
      return error;
    }

    function validateCstPisSaida() {
      let error = '';
      if (cstPisSaida) {
        if (cstPisSaida.length < 3)
          error = 'O CST PIS SAÍDA deve ter 3 dígitos';
      }
      return error;
    }

    function validateCstCofinsEntrada() {
      let error = '';
      if (cstCofinsEntrada) {
        if (cstCofinsEntrada.length < 3)
          error = 'O CST COFINS ENTRADA deve ter 3 dígitos';
      }
      return error;
    }

    function validateCstCofinsSaida() {
      let error = '';
      if (cstCofinsSaida) {
        if (cstCofinsSaida.length < 3)
          error = 'O CST COFINS SAÍDA deve ter 3 dígitos';
      }
      return error;
    }

    function validateCest() {
      let error = '';
      if (cest) {
        if (cest.length < 7) error = 'O CEST deve ter 7 dígitos';
      }
      return error;
    }

    /// ///

    function validateLb() {
      let error = '';
      if (lb <= 0 || Number.isNaN(lb)) {
        error = 'Este campo é obrigatório';
      } /* else if (lb > 100) {
      error = 'Não pode ultrapassar de 100%!';
    } */
      return error;
    }

    function validateDescontoCaixaria() {
      let error = '';
      if (descontoCaixaria < 0 || Number.isNaN(descontoCaixaria)) {
        error = 'Este campo é obrigatório';
      } else if (descontoCaixaria > 100) {
        error = 'Não pode ultrapassar de 100%!';
      }
      return error;
    }

    function validateQtde() {
      let error = '';
      if (qtde <= 0 || Number.isNaN(qtde)) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateBc() {
      let error = '';
      if (bc <= 0 || Number.isNaN(bc)) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateCg() {
      let error = '';
      if (cg < 0 || Number.isNaN(cg)) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateValidade() {
      let error = '';
      if (validade === null || validade === '') error = 'Data Inválida!';
      return error;
    }

    function getErros() {
      const errosImpostos = [
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ];
      errosImpostos[0] = validateIpi();
      errosImpostos[1] = validatePis();
      errosImpostos[2] = validateCofins();
      errosImpostos[3] = validateIcmsAi();
      errosImpostos[4] = validateIcmsInt();
      errosImpostos[5] = validateDf();
      errosImpostos[6] = validateRbc();
      errosImpostos[7] = validateNcm();
      errosImpostos[8] = validateCst();
      errosImpostos[9] = validateCfop();
      //
      errosImpostos[10] = validateLb();
      errosImpostos[11] = validateDescontoCaixaria();
      errosImpostos[12] = validateQtde();
      errosImpostos[13] = validateBc();
      errosImpostos[14] = validateCg();
      errosImpostos[15] = validateValidade();
      //
      errosImpostos[16] = validateCstPisEntrada();
      errosImpostos[17] = validateCstPisSaida();
      errosImpostos[18] = validateCstCofinsEntrada();
      errosImpostos[19] = validateCstCofinsSaida();
      errosImpostos[20] = validateCest();
      return errosImpostos;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    function getRefNextInput(index) {
      let position = -1;
      switch (index) {
        case 0:
          position = 1;
          break;
        case 1:
          position = 2;
          break;
        case 2:
          position = 3;
          break;
        case 3:
          position = showDescontoCaixaria ? 4 : 5;
          break;
        case 4:
          position = 5;
          break;
        case 5:
          position = 6;
          break;
        case 6:
          position = 7;
          break;
        case 7:
          position = 8;
          break;
        case 8:
          position = 9;
          break;
        case 9:
          position = 10;
          break;
        case 10:
          position = 11;
          break;
        case 11:
          position = 12;
          break;
        case 12:
          position = 13;
          break;
        case 13:
          position = 14;
          break;
        case 14:
          position = 15;
          break;
        case 15:
          position = 16;
          break;
        case 16:
          position = 17;
          break;
        case 17:
          position = 18;
          break;
        case 18:
          position = 19;
          break;
        case 19:
          position = 20;
          break;
        case 20:
          position = 0;
          break;
        default:
          break;
      }
      return position;
    }

    function handleNextInput(index) {
      const position = getRefNextInput(index);
      if (position === -1) {
        refsInputs.current[0].current.focus();
        refsInputs.current[0].current.select();
      } else if (refsInputs.current[position].current) {
        refsInputs.current[position].current.focus();
        refsInputs.current[position].current.select();
      }
    }

    const FECHAR_VENDA_ACTION = 'F8';
    const CANCELAR_VENDA_ACTION = 'F4';

    function handleActions(action) {
      switch (action) {
        case FECHAR_VENDA_ACTION:
          handleSalvar();
          break;
        case CANCELAR_VENDA_ACTION:
          handleCloseDialog();
          break;
        default:
          break;
      }
    }

    function handleKey(keyCode, keyName) {
      handleActions(keyName);
    }

    const dadosPrecificacao = calculatePrecoVenda(
      {
        bc: usarBaseCalculo ? getBc(bc, qtde, pesoMedio, tipoUnidade) : bc,
        qtde,
        cg,
        cn: usarBaseCalculo ? bc : getCn(bc, qtde, pesoMedio, tipoUnidade),
      },
      {
        lb,
        ipi: isNota ? ipi : 0,
        pis: isNota ? pis : 0,
        cofins: isNota ? cofins : 0,
        icms_ai: isNota ? icmsAi : 0,
        icms_int: isNota ? icmsInt : 0,
        rbc: isNota ? rbc : 100,
        df: isNota ? df : 0,
        cst,
        cfop,
        ncm,
      },
      {
        irpj: isNota ? ipi : 0,
        csll: isNota ? ipi : 0,
        ppc,
        pcf,
        pparf,
      },
    );
    const dadosPrecificacaoFormatados = formatDadosPrecificacao(
      dadosPrecificacao,
    );

    function openDetalhes() {
      if (refDialogoDetalhes.current) {
        refDialogoDetalhes.current.handleOpen();
      }
    }

    const showDescontoCaixaria =
      tipoUnidade === POR_UNIDADE || tipoUnidade === POR_PESO_FIXO;

    function getLabelUnidade(tipo) {
      if (tipo === POR_UNIDADE) {
        return 'Un';
      }
      return 'Kg';
    }

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          /* fullWidth
        maxWidth="sm" */
          fullScreen
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  marginRight: '20px',
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              >{`Precificação: ${nome}`}</div>
              <Chip
                label={
                  isImpostos
                    ? 'Impostos cadastrados'
                    : 'Impostos não cadastrados'
                }
                style={{
                  color: 'white',
                  backgroundColor: isImpostos ? 'green' : 'red',
                  fontSize: '15px',
                  marginRight: '50px',
                }}
              />
            </div>
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                <Box display="flex" flex={4}>
                  <Box flex={2} mr="1em">
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      mt="15px"
                      width="100%"
                    >
                      <Typography variant="h6" gutterBottom>
                        Cálculado
                      </Typography>
                      <Box ml="0.5em">
                        <Button
                          style={{ color: 'white' }}
                          size="small"
                          variant="contained"
                          onClick={openDetalhes}
                          color="primary"
                        >
                          Detalhes
                        </Button>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box
                        flex={2}
                        mr="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        {/* <PrecoInput
                        name="preco_venda"
                        handleEnter={() => {}}
                        label="Preco de Venda"
                        handleKey={() => {}}
                        value={Number.isNaN(dadosPrecificacao.pv) ?
                          '' : String(dadosPrecificacao.pv)}
                        onChange={() => {}}
                        fullWidth
                        escala={2}
                        disabled
                      /> */}
                        <div
                          style={{
                            marginTop: '10px',
                            backgroundColor: 'white',
                            color: 'red',
                            fontSize: '15px',
                            padding: '10px',
                            borderRadius: '13px',
                            border: '5px solid red',
                            flex: 1,
                          }}
                        >
                          <div>Preço de Venda</div>
                          <div
                            style={{ marginTop: '5px', fontSize: '25px' }}
                          >{`${formatMoeda(
                            dadosPrecificacao.pv,
                          )}/${getLabelUnidade(tipoUnidade)}`}</div>
                        </div>
                        <Box ml="5px">
                          <DicaInput legenda={legendas.preco_venda} />
                        </Box>
                      </Box>
                      <Box
                        flex={2}
                        mr="0.5em"
                        ml="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <PrecoInput
                          name="preco_compra"
                          handleEnter={() => {}}
                          label={`Preço Compra/${getLabelUnidade(tipoUnidade)}`}
                          handleKey={() => {}}
                          value={
                            Number.isNaN(dadosPrecificacao.cn)
                              ? ''
                              : String(dadosPrecificacao.cn)
                          }
                          onChange={() => {}}
                          fullWidth
                          escala={2}
                          disabled
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.preco_compra} />
                        </Box>
                      </Box>
                      <Box
                        flex={2}
                        ml="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <PrecoInput
                          name="preco_compra_imposto"
                          handleEnter={() => {}}
                          label={`Preço Compra com Imposto/${getLabelUnidade(
                            tipoUnidade,
                          )}`}
                          handleKey={() => {}}
                          value={
                            Number.isNaN(dadosPrecificacao.cni)
                              ? ''
                              : String(dadosPrecificacao.cni)
                          }
                          onChange={() => {}}
                          fullWidth
                          escala={2}
                          disabled
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.preco_compra_imposto} />
                        </Box>
                      </Box>
                    </Box>

                    {/* <Box display="flex">
                    <Box flex={1} ml="0.5em">
                      <Button onClick={openDetalhes} color="primary">Detalhes</Button>
                    </Box>
                  </Box> */}

                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ marginTop: '10px' }}
                    >
                      Geral
                    </Typography>

                    <Box display="flex">
                      <Box
                        flex={1}
                        mr="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <PrecoInput
                          name={inputs[13].nome}
                          ref={refsInputs.current[BC_POSITION]}
                          handleEnter={() => handleNextInput(BC_POSITION)}
                          label={inputs[13].label}
                          handleKey={handleKey}
                          value={Number.isNaN(bc) ? '' : String(bc)}
                          onChange={(value) =>
                            setBc(parseFloat(value.target.value))
                          }
                          error={erros[13] !== ''}
                          helperText={erros[13]}
                          fullWidth
                          escala={2}
                          autoFocus
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.bc} />
                        </Box>
                      </Box>
                      <Box
                        flex={1}
                        mr="0.5em"
                        ml="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        {tipoUnidade === POR_UNIDADE ||
                        tipoUnidade === POR_PESO_FIXO ? (
                          <IntegerInput
                            name={inputs[12].nome}
                            ref={refsInputs.current[QTDE_POSITION]}
                            handleEnter={() => handleNextInput(QTDE_POSITION)}
                            label={inputs[12].label}
                            handleKey={handleKey}
                            value={Number.isNaN(qtde) ? '' : String(qtde)}
                            onChange={(value) =>
                              setQtde(parseInt(value.target.value, 10))
                            }
                            error={erros[12] !== ''}
                            helperText={erros[12]}
                            fullWidth
                            escala={2}
                          />
                        ) : (
                          <PesoInput
                            name={inputs[12].nome}
                            ref={refsInputs.current[QTDE_POSITION]}
                            handleEnter={() => handleNextInput(QTDE_POSITION)}
                            label={inputs[12].label}
                            handleKey={handleKey}
                            value={Number.isNaN(qtde) ? '' : String(qtde)}
                            onChange={(value) =>
                              setQtde(parseFloat(value.target.value))
                            }
                            error={erros[12] !== ''}
                            helperText={erros[12]}
                            fullWidth
                            escala={2}
                          />
                        )}
                        <Box ml="5px">
                          <DicaInput legenda={legendas.qtde} />
                        </Box>
                      </Box>
                      <Box
                        flex={1}
                        ml="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <PrecoInput
                          name={inputs[14].nome}
                          ref={refsInputs.current[CG_POSITION]}
                          handleEnter={() => handleNextInput(CG_POSITION)}
                          label={inputs[14].label}
                          handleKey={handleKey}
                          value={Number.isNaN(cg) ? '' : String(cg)}
                          onChange={(value) =>
                            setCg(parseFloat(value.target.value))
                          }
                          error={erros[14] !== ''}
                          helperText={erros[14]}
                          fullWidth
                          escala={2}
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.cg} />
                        </Box>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box
                        flex={1}
                        mr="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <PorcentagemInput
                          name={inputs[10].nome}
                          ref={refsInputs.current[LB_POSITION]}
                          handleEnter={() => handleNextInput(LB_POSITION)}
                          label={inputs[10].label}
                          handleKey={handleKey}
                          value={Number.isNaN(lb) ? '' : String(lb)}
                          onChange={(value) =>
                            setLb(parseFloat(value.target.value))
                          }
                          error={erros[10] !== ''}
                          helperText={erros[10]}
                          fullWidth
                          escala={2}
                          // autoFocus
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.lb} />
                        </Box>
                      </Box>
                      {showDescontoCaixaria ? (
                        <Box
                          flex={1}
                          mr="0.5em"
                          ml="0.5em"
                          display="flex"
                          alignItems="center"
                        >
                          <PorcentagemInput
                            name={inputs[11].nome}
                            ref={refsInputs.current[DESCONTO_CAIXARIA_POSITION]}
                            handleEnter={() =>
                              handleNextInput(DESCONTO_CAIXARIA_POSITION)
                            }
                            label={inputs[11].label}
                            handleKey={handleKey}
                            value={
                              Number.isNaN(descontoCaixaria)
                                ? ''
                                : String(descontoCaixaria)
                            }
                            onChange={(value) =>
                              setDescontoCaixaria(
                                parseFloat(value.target.value),
                              )
                            }
                            error={erros[11] !== ''}
                            helperText={erros[11]}
                            fullWidth
                            escala={2}
                          />
                          <Box ml="5px">
                            <DicaInput legenda={legendas.descont_caixaria} />
                          </Box>
                        </Box>
                      ) : (
                        <Box flex={1} ml="0.5em" />
                      )}
                      <Box
                        flex={1}
                        ml="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <DateInput
                          name={inputs[15].nome}
                          ref={refsInputs.current[VALIDADE_POSITION]}
                          handleEnter={() => handleNextInput(VALIDADE_POSITION)}
                          label={inputs[15].label}
                          handleKey={handleKey}
                          value={validade}
                          onChange={(value) => setValidade(value.target.value)}
                          error={erros[15] !== ''}
                          helperText={erros[15]}
                          fullWidth
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.validade} />
                        </Box>
                      </Box>
                    </Box>

                    <Box>
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{ marginTop: '10px' }}
                      >
                        Provisões
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Box
                        flex={1}
                        mr="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <PorcentagemInput
                          name="ppc"
                          handleEnter={() => {}}
                          label="PPC"
                          handleKey={handleKey}
                          value={Number.isNaN(ppc) ? '' : String(ppc)}
                          onChange={() => {}}
                          fullWidth
                          escala={2}
                          disabled
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.ppc} />
                        </Box>
                      </Box>
                      <Box
                        flex={1}
                        mr="0.5em"
                        ml="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <PorcentagemInput
                          name="pparf"
                          handleEnter={() => {}}
                          label="PPARF"
                          handleKey={handleKey}
                          value={Number.isNaN(pparf) ? '' : String(pparf)}
                          onChange={() => {}}
                          fullWidth
                          escala={2}
                          disabled
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.pparf} />
                        </Box>
                      </Box>
                      <Box
                        flex={1}
                        ml="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <PorcentagemInput
                          name="pcf"
                          handleEnter={() => {}}
                          label="PCF"
                          handleKey={handleKey}
                          value={Number.isNaN(pcf) ? '' : String(pcf)}
                          onChange={() => {}}
                          fullWidth
                          escala={2}
                          disabled
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.pcf} />
                        </Box>
                      </Box>
                    </Box>

                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ marginTop: '10px' }}
                    >
                      Impostos
                    </Typography>

                    <Box display="flex">
                      <Box
                        flex={1}
                        mr="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <PorcentagemInput
                          name={inputs[0].nome}
                          ref={refsInputs.current[IPI_POSITION]}
                          handleEnter={() => handleNextInput(IPI_POSITION)}
                          label={inputs[0].label}
                          handleKey={handleKey}
                          value={Number.isNaN(ipi) ? '' : String(ipi)}
                          onChange={(value) =>
                            setIpi(parseFloat(value.target.value))
                          }
                          error={erros[0] !== ''}
                          helperText={erros[0]}
                          fullWidth
                          escala={2}
                          // autoFocus
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.ipi} />
                        </Box>
                      </Box>
                      <Box
                        flex={1}
                        mr="0.5em"
                        ml="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <PorcentagemInput
                          name={inputs[1].nome}
                          ref={refsInputs.current[PIS_POSITION]}
                          handleEnter={() => handleNextInput(PIS_POSITION)}
                          label={inputs[1].label}
                          handleKey={handleKey}
                          value={Number.isNaN(pis) ? '' : String(pis)}
                          onChange={(value) =>
                            setPis(parseFloat(value.target.value))
                          }
                          error={erros[1] !== ''}
                          helperText={erros[1]}
                          fullWidth
                          escala={2}
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.pis} />
                        </Box>
                      </Box>
                      <Box
                        flex={1}
                        ml="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <PorcentagemInput
                          name={inputs[2].nome}
                          ref={refsInputs.current[COFINS_POSITION]}
                          handleEnter={() => handleNextInput(COFINS_POSITION)}
                          label={inputs[2].label}
                          handleKey={handleKey}
                          value={Number.isNaN(cofins) ? '' : String(cofins)}
                          onChange={(value) =>
                            setCofins(parseFloat(value.target.value))
                          }
                          error={erros[2] !== ''}
                          helperText={erros[2]}
                          fullWidth
                          escala={2}
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.confins} />
                        </Box>
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box
                        flex={1}
                        mr="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <PorcentagemInput
                          name={inputs[3].nome}
                          ref={refsInputs.current[ICMS_AI_POSITION]}
                          handleEnter={() => handleNextInput(ICMS_AI_POSITION)}
                          label={inputs[3].label}
                          handleKey={handleKey}
                          value={Number.isNaN(icmsAi) ? '' : String(icmsAi)}
                          onChange={(value) =>
                            setIcmsAi(parseFloat(value.target.value))
                          }
                          error={erros[3] !== ''}
                          helperText={erros[3]}
                          fullWidth
                          escala={2}
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.icms_ai} />
                        </Box>
                      </Box>
                      <Box
                        flex={1}
                        mr="0.5em"
                        ml="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <PorcentagemInput
                          name={inputs[4].nome}
                          ref={refsInputs.current[ICMS_INT_POSITION]}
                          handleEnter={() => handleNextInput(ICMS_INT_POSITION)}
                          label={inputs[4].label}
                          handleKey={handleKey}
                          value={Number.isNaN(icmsInt) ? '' : String(icmsInt)}
                          onChange={(value) =>
                            setIcmsInt(parseFloat(value.target.value))
                          }
                          error={erros[4] !== ''}
                          helperText={erros[4]}
                          fullWidth
                          escala={2}
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.icms_int} />
                        </Box>
                      </Box>
                      <Box
                        flex={1}
                        ml="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <PorcentagemInput
                          name={inputs[5].nome}
                          ref={refsInputs.current[DF_POSITION]}
                          handleEnter={() => handleNextInput(DF_POSITION)}
                          label={inputs[5].label}
                          handleKey={handleKey}
                          value={Number.isNaN(df) ? '' : String(df)}
                          onChange={(value) =>
                            setDf(parseFloat(value.target.value))
                          }
                          error={erros[5] !== ''}
                          helperText={erros[5]}
                          fullWidth
                          escala={2}
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.df} />
                        </Box>
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box
                        flex={1}
                        mr="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <PorcentagemInput
                          name={inputs[6].nome}
                          ref={refsInputs.current[RBC_POSITION]}
                          handleEnter={() => handleNextInput(RBC_POSITION)}
                          label={inputs[6].label}
                          handleKey={handleKey}
                          value={Number.isNaN(rbc) ? '' : String(rbc)}
                          onChange={(value) =>
                            setRbc(parseFloat(value.target.value))
                          }
                          error={erros[6] !== ''}
                          helperText={erros[6]}
                          fullWidth
                          escala={2}
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.rbc} />
                        </Box>
                      </Box>
                      <Box
                        flex={1}
                        mr="0.5em"
                        ml="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <PorcentagemInput
                          name="irpj"
                          handleEnter={() => {}}
                          label="IRFJ"
                          handleKey={() => {}}
                          value={Number.isNaN(irpj) ? '' : String(irpj)}
                          onChange={() => {}}
                          fullWidth
                          escala={2}
                          disabled
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.irpj} />
                        </Box>
                      </Box>
                      <Box
                        flex={1}
                        ml="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <PorcentagemInput
                          name="csll"
                          handleEnter={() => {}}
                          label="CSLL"
                          handleKey={() => {}}
                          value={Number.isNaN(csll) ? '' : String(csll)}
                          onChange={() => {}}
                          fullWidth
                          escala={2}
                          disabled
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.csll} />
                        </Box>
                      </Box>
                    </Box>

                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ marginTop: '10px' }}
                    >
                      Códigos
                    </Typography>

                    <Box display="flex">
                      <Box
                        flex={1}
                        mr="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <NcmInput
                          name={inputs[7].nome}
                          ref={refsInputs.current[NCM_POSITION]}
                          handleEnter={() => handleNextInput(NCM_POSITION)}
                          label={inputs[7].label}
                          handleKey={handleKey}
                          value={ncm}
                          onChange={(value) => setNcm(value.target.value)}
                          error={erros[7] !== ''}
                          helperText={erros[7]}
                          fullWidth
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.ncm} />
                        </Box>
                      </Box>
                      <Box
                        flex={1}
                        mr="0.5em"
                        ml="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <CstInput
                          name={inputs[8].nome}
                          ref={refsInputs.current[CST_POSITION]}
                          handleEnter={() => handleNextInput(CST_POSITION)}
                          label={inputs[8].label}
                          handleKey={handleKey}
                          value={cst}
                          onChange={(value) => setCst(value.target.value)}
                          error={erros[8] !== ''}
                          helperText={erros[8]}
                          fullWidth
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.cst} />
                        </Box>
                      </Box>
                      <Box
                        flex={1}
                        ml="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <CfopInput
                          name={inputs[9].nome}
                          ref={refsInputs.current[CFOP_POSITION]}
                          handleEnter={() => handleNextInput(CFOP_POSITION)}
                          label={inputs[9].label}
                          handleKey={handleKey}
                          value={cfop}
                          onChange={(value) => setCfop(value.target.value)}
                          error={erros[9] !== ''}
                          helperText={erros[9]}
                          fullWidth
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.cfop} />
                        </Box>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box
                        flex={1}
                        mr="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <CstInput
                          name={inputs[16].nome}
                          ref={refsInputs.current[CST_PIS_ENTRADA_POSITION]}
                          handleEnter={() =>
                            handleNextInput(CST_PIS_ENTRADA_POSITION)
                          }
                          label={inputs[16].label}
                          handleKey={handleKey}
                          value={cstPisEntrada}
                          onChange={(value) =>
                            setCstPisEntrada(value.target.value)
                          }
                          error={erros[16] !== ''}
                          helperText={erros[16]}
                          fullWidth
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.cst_pis_entrada} />
                        </Box>
                      </Box>
                      <Box
                        flex={1}
                        mr="0.5em"
                        ml="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <CstInput
                          name={inputs[17].nome}
                          ref={refsInputs.current[CST_PIS_SAIDA_POSITION]}
                          handleEnter={() =>
                            handleNextInput(CST_PIS_SAIDA_POSITION)
                          }
                          label={inputs[17].label}
                          handleKey={handleKey}
                          value={cstPisSaida}
                          onChange={(value) =>
                            setCstPisSaida(value.target.value)
                          }
                          error={erros[17] !== ''}
                          helperText={erros[17]}
                          fullWidth
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.cst_pis_saida} />
                        </Box>
                      </Box>
                      <Box
                        flex={1}
                        ml="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <CstInput
                          name={inputs[18].nome}
                          ref={refsInputs.current[CST_COFINS_ENTRADA_POSITION]}
                          handleEnter={() =>
                            handleNextInput(CST_COFINS_ENTRADA_POSITION)
                          }
                          label={inputs[18].label}
                          handleKey={handleKey}
                          value={cstCofinsEntrada}
                          onChange={(value) =>
                            setCstCofinsEntrada(value.target.value)
                          }
                          error={erros[18] !== ''}
                          helperText={erros[18]}
                          fullWidth
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.cst_cofins_entrada} />
                        </Box>
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box
                        flex={1}
                        mr="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <CstInput
                          name={inputs[19].nome}
                          ref={refsInputs.current[CST_COFINS_SAIDA_POSITION]}
                          handleEnter={() =>
                            handleNextInput(CST_COFINS_SAIDA_POSITION)
                          }
                          label={inputs[19].label}
                          handleKey={() => {}}
                          value={cstCofinsSaida}
                          onChange={(value) =>
                            setCstCofinsSaida(value.target.value)
                          }
                          error={erros[19] !== ''}
                          helperText={erros[19]}
                          fullWidth
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.cst_cofins_saida} />
                        </Box>
                      </Box>
                      <Box
                        flex={1}
                        mr="0.5em"
                        ml="0.5em"
                        display="flex"
                        alignItems="center"
                      >
                        <CestInput
                          name={inputs[20].nome}
                          ref={refsInputs.current[CEST_POSITION]}
                          handleEnter={() => handleNextInput(CEST_POSITION)}
                          label={inputs[20].label}
                          handleKey={() => {}}
                          value={cest}
                          onChange={(value) => setCest(value.target.value)}
                          error={erros[20] !== ''}
                          helperText={erros[20]}
                          fullWidth
                        />
                        <Box ml="5px">
                          <DicaInput legenda={legendas.cest} />
                        </Box>
                      </Box>
                      <Box flex={1} ml="0.5em" />
                    </Box>
                  </Box>
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box flex={1}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ marginTop: '10px', textAlign: 'center' }}
                  >
                    Opções
                  </Typography>

                  <Box flex={1} ml="0.5em" mt="30px">
                    <FormControlLabel
                      value="end"
                      control={
                        <Switch
                          checked={isNota}
                          onChange={(event) => {
                            setIsNota(event.target.checked);
                          }}
                          name="isNota"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      }
                      label="Com Nota"
                      labelPlacement="end"
                    />
                  </Box>

                  <Box flex={1} ml="0.5em" mt="30px">
                    <FormControlLabel
                      value="end"
                      control={
                        <Switch
                          checked={usarBaseCalculo}
                          onChange={(event) => {
                            setUsarBaseCalculo(event.target.checked);
                          }}
                          name="usarBaseCalculo"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      }
                      label="Preco Unitário"
                      labelPlacement="end"
                    />
                  </Box>

                  <Box flex={1} ml="0.5em" mt="30px" mb="20px">
                    <FormControlLabel
                      value="end"
                      control={
                        <Switch
                          checked={manterPrecoAnterior}
                          onChange={(event) => {
                            setManterPrecoAnterior(event.target.checked);
                          }}
                          name="manterPrecoAnterior"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      }
                      label="Manter Preço Original"
                      labelPlacement="end"
                    />
                  </Box>

                  <Box flex={1} ml="0.5em" mt="30px" mb="20px">
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ color: 'white' }}
                      onClick={() => {
                        if (refDialogoComposicaoBaseCalculo.current) {
                          refDialogoComposicaoBaseCalculo.current.handleOpen();
                        }
                      }}
                    >
                      {usarBaseCalculo
                        ? 'Compor Preço Unitário'
                        : 'Compor Base de Cálculo'}
                    </Button>
                  </Box>

                  <Divider />

                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ marginTop: '10px', textAlign: 'center' }}
                  >
                    Detalhes
                  </Typography>

                  <div
                    style={{
                      marginTop: '10px',
                      // backgroundColor: 'white',
                      // color: 'red',
                      fontSize: '15px',
                      padding: '10px',
                      // borderRadius: '13px',
                      // border: '5px solid red',
                      display: 'flex',
                      justifyContent: 'center',
                      // flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        marginRight: '5px',
                      }}
                    >
                      Preço Original:
                    </div>
                    <div style={{ fontSize: '15px' }}>
                      {formatMoeda(precoVendaOriginal)}
                    </div>
                  </div>
                </Box>
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={handleCloseDialog}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
            >
              Fechar
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={erroExistente || carregando}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
              {carregando && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>
        <DialogoDetalhesPrecificacao
          ref={refDialogoDetalhes}
          handleClose={() => {}}
          dados={dadosPrecificacaoFormatados}
          produto={nome}
          colunas={[
            {
              field: 'bc',
              label: 'BC DO ICMS (R$)',
              right: true,
            },
            {
              field: 'qtde',
              label: tipoUnidade === POR_UNIDADE ? 'QTDE (Un)' : 'QTDE (Kg)',
              right: true,
            },
            {
              field: 'cn',
              label: 'CN (R$)',
              right: true,
            },
            {
              field: 'cg',
              label: 'CG (R$)',
              right: true,
            },
            {
              field: 'lb',
              label: 'LB (%)',
              right: true,
            },
            {
              field: 'lb_c',
              label: 'LB (R$)',
              right: true,
            },
            {
              field: 'pv',
              label: 'PV (R$)',
              right: true,
            },
            {
              field: 'cni',
              label: 'CNI (R$)',
              right: true,
            },
            {
              field: 'cf',
              label: 'CF (R$)',
              right: true,
            },
            {
              field: 'ebitida',
              label: 'EBITIDA (%)',
              right: true,
            },
            {
              field: 'ebitida_c',
              label: 'EBITIDA (R$)',
              right: true,
            },
            {
              field: 'pv_lucro',
              label: 'PV - Lucro (R$)',
              right: true,
            },
            {
              field: 'ipi',
              label: 'IPI (%)',
              right: true,
            },
            {
              field: 'ipi_c',
              label: 'IPI (R$)',
              right: true,
            },
            {
              field: 'pis',
              label: 'PIS (%)',
              right: true,
            },
            {
              field: 'pis_c',
              label: 'PIS (R$)',
              right: true,
            },
            {
              field: 'cofins',
              label: 'COFINS (%)',
              right: true,
            },
            {
              field: 'cofins_c',
              label: 'COFINS (R$)',
              right: true,
            },
            {
              field: 'icms_ai',
              label: 'AI (%)',
              right: true,
            },
            {
              field: 'icms_ai_c',
              label: 'AI (R$)',
              right: true,
            },
            {
              field: 'cli',
              label: 'CLI (R$)',
              right: true,
            },
            {
              field: 'ipi_v',
              label: 'IPI V (R$)',
              right: true,
            },
            {
              field: 'pis_v',
              label: 'PIS V (R$)',
              right: true,
            },
            {
              field: 'icms_int',
              label: 'ICMS INT (%)',
              right: true,
            },
            {
              field: 'icms_int_c',
              label: 'ICMS R (%)',
              right: true,
            },
            {
              field: 'icms_int_s',
              label: 'ICMS R (R$)',
              right: true,
            },
            {
              field: 'ppc',
              label: 'PPC (%)',
              right: true,
            },
            {
              field: 'ppc_c',
              label: 'PPC (R$)',
              right: true,
            },
            //
            {
              field: 'pcf',
              label: 'PCF (%)',
              right: true,
            },
            {
              field: 'pcf_c',
              label: 'PCF (R$)',
              right: true,
            },
            {
              field: 'pparf',
              label: 'PPARF (%)',
              right: true,
            },
            {
              field: 'pparf_C',
              label: 'PPARF (R$)',
              right: true,
            },
            //
            {
              field: 'irpj',
              label: 'IRPJ (%)',
              right: true,
            },
            {
              field: 'irpj_c',
              label: 'IRPJ (R$)',
              right: true,
            },
            {
              field: 'csll',
              label: 'CSLL (%)',
              right: true,
            },
            {
              field: 'csll_c',
              label: 'CSLL (R$)',
              right: true,
            },
            {
              field: 'ncm',
              label: 'NCM',
              right: true,
            },
            {
              field: 'df',
              label: 'DF (%)',
              right: true,
            },
            {
              field: 'cst',
              label: 'CST',
              right: true,
            },
            {
              field: 'cfop',
              label: 'CFOP',
              right: true,
            },
            {
              field: 'rbc',
              label: 'RBC (%)',
              right: true,
            },
          ]}
        />
        <DialogoCreateImpressao
          handleClose={() => {}}
          ref={refDialogoImpressao}
        />
        <DialogoComposicaoBaseCalculo
          handleClose={() => {}}
          handleSalvar={(total) => {
            setBc(total);
          }}
          usarBaseCalculo={usarBaseCalculo}
          ref={refDialogoComposicaoBaseCalculo}
        />
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
