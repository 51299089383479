import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { useHistory, useLocation } from 'react-router-dom';

import { Box, makeStyles, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import PropTypes from 'prop-types';

import { getOneAPI } from '../../../services';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // color: 'red',
    marginLeft: '15px',
    maxWidth: '150px',
  },
  avatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    color: 'white',
    backgroundColor: '#f44336',
    cursor: 'pointer',
  },
}));

const DescricaoField = ({ ...props }) => {
  const classes = useStyles();
  const record = useRecordContext();
  const value = record.record ? record.record.despesa.categoria.nome : '';
  const icon = record.record ? record.record.despesa.categoria.icon : '';
  const color = record.record ? record.record.despesa.categoria.color : '';

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
      <div
        style={{ fontSize: '17px', fontWeight: 'bold', marginRight: '10px' }}
      >
        Categoria:{' '}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar className={classes.avatar} style={{ backgroundColor: color }}>
          <Icon style={{ color: 'white', fontSize: 15 }}>{icon}</Icon>
        </Avatar>
        <Tooltip title={value} placement="bottom" aria-label="add2">
          <Typography className={classes.text}>{value}</Typography>
        </Tooltip>
      </div>
    </div>
  );
};

DescricaoField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default DescricaoField;
