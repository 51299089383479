import React, {
  useReducer,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { ThemeProvider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useSnackbar } from 'notistack';

import imagem from '../../assets/background.jpg';
import { getListAPI } from '../../services';
import { formatItens, formatParcelas } from '../../utils';
import InicioFrenteCaixaVenda from './Pages/CreateVenda/_SubPages/Itens';
import FinalizarFrenteCaixaVenda from './Pages/CreateVenda/_SubPages/Parcelas';
import { VendaContext } from './Pages/CreateVenda/context';
import reducerVenda from './Pages/CreateVenda/reducer';
import theme from './themes/default';

const TELA_INICIO_FRENTE_CAIXA = 0;
const TELA_FINALIZAR_FRENTE_CAIXA = 1;

const DialogoCreateVenda = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const [tela, setTela] = useState(TELA_INICIO_FRENTE_CAIXA);
  const { enqueueSnackbar } = useSnackbar();

  const [venda, dispatch] = useReducer(reducerVenda, {
    cliente: null,
    itens: [],
    parcelas: [],
    idEdit: -1,
    isEditPrice: false,
    tipoVenda: 0,
    isEmPartes: false,
  });

  function resetar() {
    dispatch({ type: 'RESETAR_TUDO' });
  }

  const handleCloseDialog = () => {
    setOpen(false);
    resetar();
    setTela(TELA_INICIO_FRENTE_CAIXA);
    handleClose();
  };

  function irParaTelaInit() {
    handleCloseDialog();
  }

  function irParaTelaFinalizarVenda() {
    setTela(TELA_FINALIZAR_FRENTE_CAIXA);
  }

  function irParaTelaFrentedeCaixa() {
    setTela(TELA_INICIO_FRENTE_CAIXA);
  }

  async function getDataVenda(idVenda) {
    try {
      const data = await getListAPI(
        'vendas',
        ['id', 'asc'],
        [1, 1],
        { id: [idVenda] },
        [
          'itens.produto.unidade',
          'parcelas.tipo_pagamento',
          'parcelas.receita.conta',
          'cliente.cidade.estado',
          'cliente.categoria',
          'sessao',
        ],
      );
      if (data.data.length <= 0) {
        throw 'Esta venda não existe!';
      }
      if (data.data[0].sessao.is_fechamento) {
        throw 'Não pode editar esta venda, a sessão ja foi fechada!';
      }
      dispatch({
        type: 'PREENCHER_TUDO',
        idEdit: data.data[0].id,
        itens: formatItens(data.data[0].itens),
        parcelas: formatParcelas(data.data[0].parcelas),
        cliente: data.data[0].cliente,
        isEditPrice: false,
        tipoVenda: !data.data[0].devolucao ? 0 : 1,
        descontoOriginal: data.data[0].total_descontos,
        isEmPartes: data.data[0].is_em_partes,
        idSession: data.data[0].sessao.id,
      });
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      irParaTelaInit();
    }
  }

  useImperativeHandle(ref, () => ({
    handleEdit(idVenda) {
      getDataVenda(idVenda);
      setOpen(true);
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          fullScreen
          style={{
            padding: '0',
            margin: '0',
          }}
        >
          <DialogContent
            style={{
              padding: '0',
              margin: '0',
              backgroundImage: `url(${imagem})`,
              backgroundSize: 'cover',
            }}
            dividers
          >
            <VendaContext.Provider
              value={{ venda, dispatch, openMenu: () => {} }}
            >
              {tela === TELA_INICIO_FRENTE_CAIXA ? (
                <InicioFrenteCaixaVenda
                  irParaTelaInit={irParaTelaInit}
                  irParaTelaFinalizarVenda={irParaTelaFinalizarVenda}
                />
              ) : (
                <FinalizarFrenteCaixaVenda
                  irParaTelaInit={irParaTelaInit}
                  irParaTelaFrentedeCaixa={irParaTelaFrentedeCaixa}
                  getSessionId={() => -1}
                />
              )}
            </VendaContext.Provider>
          </DialogContent>
        </Dialog>
      </div>
    </ThemeProvider>
  );
});

export default DialogoCreateVenda;
