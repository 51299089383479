import React from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import {
  createStyles,
  lighten,
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';

import { getComparator, stableSort } from './sort';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (
    event,
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox" tabindex="-1">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts', tabindex: '-1' }}
            color="primary"
            tabindex="-1"
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              tabindex="-1"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme) => createStyles({
  root: {
  },
  highlight:
      theme.palette.type === 'light'
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = ({ numSelected, actionRemove }) => {
  const classes = useToolbarStyles();

  return (
    <>
      {numSelected > 0 ? (
        <Toolbar
          className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}
          tabindex="-1"
        >
          {numSelected > 0 ? (
            <Typography
              className={classes.title}
              color="inherit"
              variant="subtitle1"
              component="div"
              tabindex="-1"
            >
              {numSelected}
              {' '}
              selecionados
            </Typography>
          ) : null}
          {numSelected > 0 ? (
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => actionRemove()}
                tabindex="-1"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </Toolbar>
      ) : null}
    </>
  );
};

const useStyles = makeStyles((theme) => createStyles({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    width: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  container: {
    height: '100%',
    width: '100%',
    opacity: '0.75',
  },
  container2: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
}));

const TableTemplate = ({
  rows, removeItens, headCells, toString, handleAction = () => {},
  orderInit = null, orderByInit = null,
}) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState(orderInit !== null ? orderInit : 'asc');
  const [orderBy, setOrderBy] = React.useState(orderByInit !== null
    ? headCells[orderByInit].id : headCells[0].id);
  const [selected, setSelected] = React.useState([]);

  const handleRequestSort = (
    event,
    property,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.uidd);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  function removerItens() {
    removeItens(selected);
    setSelected([]);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const rowsFormated = toString(rows);

  return (
    <div className={classes.container2} tabIndex="-1">
      <EnhancedTableToolbar
        numSelected={selected.length}
        actionRemove={removerItens}
      />
      <TableContainer
        className={classes.container}
        elevation={3}
        component={Paper}
      >
        <Table
          className={classes.table}
          aria-labelledby="tableTitle2"
          size={false ? 'small' : 'medium'}
          aria-label="enhanced table"
          stickyHeader
          tabindex="-1"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
          />
          <TableBody tabindex="-1">
            {stableSort(rows, getComparator(order, orderBy)).map(
              (row) => {
                const index = row.orderOriginal;
                const isItemSelected = isSelected(row.uidd);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <StyledTableRow
                    hover
                    onClick={(event) => handleClick(event, row.uidd)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.uidd}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox" tabindex="-1">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId, tabindex: '-1' }}
                        tabindex="-1"
                      />
                    </TableCell>
                    {headCells.map((itemHead, indexHead) => {
                      if (itemHead.isButton) {
                        return (
                          <TableCell align="right">
                            <Button
                              variant="contained"
                              color="secondary"
                              tabindex="-1"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleAction(parseInt(row.uidd, 10), itemHead.id);
                              }}
                            >
                              {itemHead.labelAction}
                            </Button>
                          </TableCell>
                        );
                      }
                      if (indexHead === 0) {
                        return (
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {rowsFormated[index][itemHead.id]}
                          </TableCell>
                        );
                      }
                      return <TableCell align="right">{rowsFormated[index][itemHead.id]}</TableCell>;
                    })}
                  </StyledTableRow>
                );
              },
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableTemplate;
