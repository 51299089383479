import React from 'react';

import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

import { formatMoeda, formatPreco } from '../../../utils';

const styles = StyleSheet.create({
  page: {
    padding: '5mm',
  },
  titleDocument: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    fontWeight: 'bold',
    fontSize: '13',
  },
  subTitleDocument: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'row',
    margin: '5 0',
    fontSize: '11',
    fontWeight: 'bold',
  },
  sessao: {},
  subTitle: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10 0 5',
    fontWeight: 'bold',
    fontSize: '12',
  },
  body: {
    width: '100%',
    fontSize: '8',
  },
  item: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    borderBottom: '1 solid black',
  },
  contentTotais: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#ffff00',
    fontWeight: 'bold',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#dae1f2',
    borderTop: '1 solid black',
    borderBottom: '1 solid black',
  },
  negativo: {
    backgroundColor: '#ffc7cf',
  },
  positivo: {
    backgroundColor: '#cbf5d0',
  },
});

const RelatorioFC = ({
  entradas,
  saidas,
  // transferencias,
  transferenciasEntrada,
  transferenciasSaida,
  vendas,
  devolucoes,
  vendasCanceladas,
  devolucoesCanceladas,
  trocoInicial,
  atendente,
  caixa,
  abertura,
  fechamento,
  saldo,
  saldoCorrigido,
  descontos,
  acrescimos,
  data,
  numeroMedioItensPorVenda,
  numeroVendas,
  numeroVendasCanceladas,
  ticketMedio,
  valorMedioDosItens,
  numeroTotalItensDiferentes,
}) => {
  function somaValor(lista) {
    let soma = 0;
    for (let i = 0; i < lista.length; i += 1) {
      soma += lista[i].valor;
    }
    return soma;
  }

  function totaisDevolucoes(lista) {
    let soma = 0;
    for (let i = 0; i < lista.length; i += 1) {
      soma += lista[i].valorTotal;
    }
    return soma;
  }

  function totaisVenda(vendas) {
    let totalDinheiro = 0;
    let totalCartaoCredito = 0;
    let totalCartaoDebito = 0;
    let totalTedPix = 0;
    let totalOutros = 0;
    let totalVonchuer = 0;
    let totalDescontoDevolucao = 0;
    let totalTotal = 0;

    for (let i = 0; i < vendas.length; i += 1) {
      totalDinheiro += vendas[i].valor;
      totalCartaoCredito += vendas[i].valorCartaoCredito;
      totalCartaoDebito += vendas[i].valorCartaoDebito;
      totalTedPix += vendas[i].valorTedPix;
      totalOutros += vendas[i].valorOutros;
      totalVonchuer += vendas[i].valorVonchuer;
      totalDescontoDevolucao += vendas[i].valorDescontoDevolucao;
      totalTotal += vendas[i].valorTotal;
    }
    return {
      totalDinheiro,
      totalCartaoCredito,
      totalCartaoDebito,
      totalTedPix,
      totalOutros,
      totalVonchuer,
      totalDescontoDevolucao,
      totalTotal,
    };
  }

  const {
    totalDinheiro,
    totalCartaoCredito,
    totalCartaoDebito,
    totalTedPix,
    totalOutros,
    totalTotal,
    totalVonchuer,
    totalDescontoDevolucao,
  } = totaisVenda(vendas);

  const {
    totalDinheiro: totalDinheiroCancelado,
    totalCartaoCredito: totalCartaoCreditoCancelado,
    totalCartaoDebito: totalCartaoDebitoCancelado,
    totalTedPix: totalTedPixCancelado,
    totalOutros: totalOutrosCancelado,
    totalTotal: totalTotalCancelado,
    totalVonchuer: totalVonchuerCancelado,
    totalDescontoDevolucao: totalDescontoDevolucaoCancelado,
  } = totaisVenda(vendasCanceladas);

  const totalDevolucoes = totaisDevolucoes(devolucoes);

  const totalDevolucoesCanceladas = totaisDevolucoes(devolucoesCanceladas);

  const totaisEntradas = somaValor(entradas);
  const totaisSaidas = somaValor(saidas);
  const totaisTransferenciasSaida = somaValor(transferenciasSaida);
  const totaisTransferenciasEntrada = somaValor(transferenciasEntrada);

  const diferenca = fechamento === '-' ? 0 : saldoCorrigido - saldo;

  return (
    <Document author="Caruaru Frios" title={`Relatório ${caixa} ${data}`}>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.body}>
          <View style={styles.titleDocument}>
            <Text>CARUARU FRIOS</Text>
          </View>
          <View style={styles.subTitleDocument}>
            <Text>Relatório Gerencial </Text>
            <Text style={{ color: 'red' }}>{` ${caixa}`}</Text>
            <Text>{` (${data})`}</Text>
          </View>
          <View style={styles.sessao}>
            <View style={styles.subTitle}>
              <Text>Dados de Fechamento</Text>
            </View>
            <View style={styles.container}>
              <View style={styles.header}>
                <View style={styles.item}>
                  <Text>Atendente</Text>
                </View>
                <View style={styles.item}>
                  <Text>Abertura</Text>
                </View>
                <View style={styles.item}>
                  <Text>Fechamento</Text>
                </View>
                <View style={styles.item}>
                  <Text>Troco Inicial</Text>
                </View>
                <View style={styles.item}>
                  <Text>Caixa Virtual</Text>
                </View>
                <View style={styles.item}>
                  <Text>Caixa Real</Text>
                </View>
                <View style={styles.item}>
                  <Text>Diferença</Text>
                </View>
                <View style={styles.item}>
                  <Text>Descontos</Text>
                </View>
                <View style={styles.item}>
                  <Text>Acréscimos</Text>
                </View>
              </View>
              <View style={styles.content}>
                <View style={styles.item}>
                  <Text>{`${atendente}`}</Text>
                </View>
                <View style={styles.item}>
                  <Text>{`${abertura}`}</Text>
                </View>
                <View style={styles.item}>
                  <Text>{`${fechamento}`}</Text>
                </View>
                <View style={styles.item}>
                  <Text>{`${formatMoeda(trocoInicial)}`}</Text>
                </View>
                <View style={styles.item}>
                  <Text>{`${formatMoeda(saldo)}`}</Text>
                </View>
                <View style={styles.item}>
                  <Text>{`${
                    fechamento === '-' ? '-' : formatMoeda(saldoCorrigido)
                  }`}</Text>
                </View>
                <View
                  style={[
                    styles.item,
                    diferenca >= 0 ? styles.positivo : styles.negativo,
                  ]}
                >
                  <Text>{`${
                    fechamento === '-'
                      ? '-'
                      : formatMoeda(saldoCorrigido - saldo)
                  }`}</Text>
                </View>
                <View style={styles.item}>
                  <Text>{`${formatMoeda(descontos)}`}</Text>
                </View>
                <View style={styles.item}>
                  <Text>{`${formatMoeda(acrescimos)}`}</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.sessao}>
            <View style={styles.subTitle}>
              <Text>Indicadores</Text>
            </View>
            <View style={styles.container}>
              <View style={styles.header}>
                <View style={styles.item}>
                  <Text>Ticket Médio</Text>
                </View>
                <View style={styles.item}>
                  <Text>Nº de Vendas</Text>
                </View>
                <View style={styles.item}>
                  <Text>Média Itens por Venda</Text>
                </View>
                <View style={styles.item}>
                  <Text>Valor Médio Itens</Text>
                </View>
                <View style={styles.item}>
                  <Text>Mix de Produtos</Text>
                </View>
              </View>
              <View style={styles.content}>
                <View style={styles.item}>
                  <Text>{`${formatMoeda(ticketMedio)}`}</Text>
                </View>
                <View style={styles.item}>
                  <Text>{`${numeroVendas}`}</Text>
                </View>
                <View style={styles.item}>
                  <Text>{`${formatPreco(numeroMedioItensPorVenda)}`}</Text>
                </View>
                <View style={styles.item}>
                  <Text>{`${formatMoeda(valorMedioDosItens)}`}</Text>
                </View>
                <View style={styles.item}>
                  <Text>{`${numeroTotalItensDiferentes}`}</Text>
                </View>
              </View>
            </View>
          </View>

          {vendas.length > 0 && (
            <View style={styles.sessao}>
              <View style={styles.subTitle}>
                <Text>Vendas</Text>
              </View>
              <View style={styles.container}>
                <View style={styles.header}>
                  <View style={styles.item}>
                    <Text>Nº</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Horário</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Dinheiro</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Débito</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Crédito</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>TED/Pix</Text>
                  </View>
                  {/* <View style={styles.item}>
                  <Text>Outros</Text>
                </View> */}
                  <View style={styles.item}>
                    <Text>Voucher</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Devolução</Text>
                  </View>
                  {/* <View style={styles.item}>
                  <Text>Data</Text>
                </View> */}
                  <View style={styles.item}>
                    <Text>Valor Total</Text>
                  </View>
                </View>
                {vendas.map((item, index) => (
                  <>
                    <View style={styles.content}>
                      <View style={styles.item}>
                        <Text>{item.numero}</Text>
                      </View>
                      <View style={styles.item}>
                        <Text>{item.horario}</Text>
                      </View>
                      <View style={styles.item}>
                        <Text>{formatMoeda(item.valor)}</Text>
                      </View>
                      <View style={styles.item}>
                        <Text>{formatMoeda(item.valorCartaoDebito)}</Text>
                      </View>
                      <View style={styles.item}>
                        <Text>{formatMoeda(item.valorCartaoCredito)}</Text>
                      </View>
                      <View style={styles.item}>
                        <Text>{formatMoeda(item.valorTedPix)}</Text>
                      </View>
                      {/* <View style={styles.item}>
                      <Text>{formatMoeda(item.valorOutros)}</Text>
                    </View> */}
                      <View style={styles.item}>
                        <Text>{formatMoeda(item.valorVonchuer)}</Text>
                      </View>
                      <View style={styles.item}>
                        <Text>{formatMoeda(item.valorDescontoDevolucao)}</Text>
                      </View>
                      {/* <View style={styles.item}>
                      <Text>
                        `
                        { index === 4 ? item.dataOutros : ''}
                      </Text>
                    </View> */}
                      <View style={styles.item}>
                        <Text>{formatMoeda(item.valorTotal)}</Text>
                      </View>
                    </View>
                  </>
                ))}
                <View style={styles.contentTotais}>
                  <View style={styles.item}>
                    <Text>Totais</Text>
                  </View>
                  <View style={styles.item}>
                    <Text />
                  </View>
                  <View style={styles.item}>
                    <Text>
                      {totalDinheiro ? formatMoeda(totalDinheiro) : '-'}
                    </Text>
                  </View>
                  <View style={styles.item}>
                    <Text>
                      {totalCartaoDebito ? formatMoeda(totalCartaoDebito) : '-'}
                    </Text>
                  </View>
                  <View style={styles.item}>
                    <Text>
                      {totalCartaoCredito
                        ? formatMoeda(totalCartaoCredito)
                        : '-'}
                    </Text>
                  </View>
                  <View style={styles.item}>
                    <Text>{totalTedPix ? formatMoeda(totalTedPix) : '-'}</Text>
                  </View>
                  {/* <View style={styles.item}>
                  <Text>{totalOutros ? formatMoeda(totalOutros) : '-'}</Text>
                </View> */}
                  <View style={styles.item}>
                    <Text>
                      {totalVonchuer ? formatMoeda(totalVonchuer) : '-'}
                    </Text>
                  </View>
                  <View style={styles.item}>
                    <Text>
                      {totalDescontoDevolucao
                        ? formatMoeda(totalDescontoDevolucao)
                        : '-'}
                    </Text>
                  </View>
                  {/*  <View style={styles.item}>
                  <Text />
                </View> */}
                  <View style={styles.item}>
                    <Text>{formatMoeda(totalTotal)}</Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {vendasCanceladas.length > 0 && (
            <View style={styles.sessao}>
              <View style={styles.subTitle}>
                <Text>Vendas Canceladas</Text>
              </View>
              <View style={styles.container}>
                <View style={styles.header}>
                  <View style={styles.item}>
                    <Text>Nº</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Horário</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Dinheiro</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Débito</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Crédito</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>TED/Pix</Text>
                  </View>
                  {/* <View style={styles.item}>
                  <Text>Outros</Text>
                </View> */}
                  <View style={styles.item}>
                    <Text>Voucher</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Devolução</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Data</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Valor Total</Text>
                  </View>
                </View>
                {vendasCanceladas.map((item, index) => (
                  <>
                    <View style={styles.content}>
                      <View style={styles.item}>
                        <Text>{item.numero}</Text>
                      </View>
                      <View style={styles.item}>
                        <Text>{item.horario}</Text>
                      </View>
                      <View style={styles.item}>
                        <Text>{formatMoeda(item.valor)}</Text>
                      </View>
                      <View style={styles.item}>
                        <Text>{formatMoeda(item.valorCartaoDebito)}</Text>
                      </View>
                      <View style={styles.item}>
                        <Text>{formatMoeda(item.valorCartaoCredito)}</Text>
                      </View>
                      <View style={styles.item}>
                        <Text>{formatMoeda(item.valorTedPix)}</Text>
                      </View>
                      {/* <View style={styles.item}>
                      <Text>{formatMoeda(item.valorOutros)}</Text>
                    </View> */}
                      <View style={styles.item}>
                        <Text>{formatMoeda(item.valorVonchuer)}</Text>
                      </View>
                      <View style={styles.item}>
                        <Text>{formatMoeda(item.valorDescontoDevolucao)}</Text>
                      </View>
                      <View style={styles.item}>
                        <Text>`{index === 4 ? item.dataOutros : ''}</Text>
                      </View>
                      <View style={styles.item}>
                        <Text>{formatMoeda(item.valorTotal)}</Text>
                      </View>
                    </View>
                  </>
                ))}
                <View style={styles.contentTotais}>
                  <View style={styles.item}>
                    <Text>Totais</Text>
                  </View>
                  <View style={styles.item}>
                    <Text />
                  </View>
                  <View style={styles.item}>
                    <Text>
                      {totalDinheiroCancelado
                        ? formatMoeda(totalDinheiroCancelado)
                        : '-'}
                    </Text>
                  </View>
                  <View style={styles.item}>
                    <Text>
                      {totalCartaoDebitoCancelado
                        ? formatMoeda(totalCartaoDebitoCancelado)
                        : '-'}
                    </Text>
                  </View>
                  <View style={styles.item}>
                    <Text>
                      {totalCartaoCreditoCancelado
                        ? formatMoeda(totalCartaoCreditoCancelado)
                        : '-'}
                    </Text>
                  </View>
                  <View style={styles.item}>
                    <Text>
                      {totalTedPixCancelado
                        ? formatMoeda(totalTedPixCancelado)
                        : '-'}
                    </Text>
                  </View>
                  {/* <View style={styles.item}>
                  <Text>{totalOutrosCancelado ? formatMoeda(totalOutrosCancelado) : '-'}</Text>
                </View> */}
                  <View style={styles.item}>
                    <Text>
                      {totalVonchuerCancelado
                        ? formatMoeda(totalVonchuerCancelado)
                        : '-'}
                    </Text>
                  </View>
                  <View style={styles.item}>
                    <Text>
                      {totalDescontoDevolucaoCancelado
                        ? formatMoeda(totalDescontoDevolucaoCancelado)
                        : '-'}
                    </Text>
                  </View>
                  <View style={styles.item}>
                    <Text />
                  </View>
                  <View style={styles.item}>
                    <Text>{formatMoeda(totalTotalCancelado)}</Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {devolucoes.length > 0 && (
            <View style={styles.sessao}>
              <View style={styles.subTitle}>
                <Text>Devoluções</Text>
              </View>
              <View style={styles.container}>
                <View style={styles.header}>
                  <View style={styles.item}>
                    <Text>Nº</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Horário</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Valor Total</Text>
                  </View>
                </View>
                {devolucoes.map((item) => (
                  <>
                    <View style={styles.content}>
                      <View style={styles.item}>
                        <Text>{item.numero}</Text>
                      </View>
                      <View style={styles.item}>
                        <Text>{item.horario}</Text>
                      </View>
                      <View style={styles.item}>
                        <Text>{formatMoeda(item.valorTotal)}</Text>
                      </View>
                    </View>
                  </>
                ))}
                <View style={styles.contentTotais}>
                  <View style={styles.item}>
                    <Text>Totais</Text>
                  </View>
                  <View style={styles.item}>
                    <Text />
                  </View>
                  <View style={styles.item}>
                    <Text>{formatMoeda(totalDevolucoes)}</Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {devolucoesCanceladas.length > 0 && (
            <View style={styles.sessao}>
              <View style={styles.subTitle}>
                <Text>Devoluções Canceladas</Text>
              </View>
              <View style={styles.container}>
                <View style={styles.header}>
                  <View style={styles.item}>
                    <Text>Nº</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Horário</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Valor Total</Text>
                  </View>
                </View>
                {devolucoesCanceladas.map((item) => (
                  <>
                    <View style={styles.content}>
                      <View style={styles.item}>
                        <Text>{item.numero}</Text>
                      </View>
                      <View style={styles.item}>
                        <Text>{item.horario}</Text>
                      </View>
                      <View style={styles.item}>
                        <Text>{formatMoeda(item.valorTotal)}</Text>
                      </View>
                    </View>
                  </>
                ))}
                <View style={styles.contentTotais}>
                  <View style={styles.item}>
                    <Text>Totais</Text>
                  </View>
                  <View style={styles.item}>
                    <Text />
                  </View>
                  <View style={styles.item}>
                    <Text>{formatMoeda(totalDevolucoesCanceladas)}</Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {entradas.length > 0 && (
            <View style={styles.sessao}>
              <View style={styles.subTitle}>
                <Text>Entradas</Text>
              </View>
              <View style={styles.container}>
                <View style={styles.header}>
                  <View style={[styles.item]}>
                    <Text>Descrição</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Horário</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Valor</Text>
                  </View>
                </View>
                {entradas.map((item) => (
                  <View style={styles.content}>
                    <View style={[styles.item]}>
                      <Text>{item.nome}</Text>
                    </View>
                    <View style={styles.item}>
                      <Text>{item.horario}</Text>
                    </View>
                    <View style={styles.item}>
                      <Text>{formatMoeda(item.valor)}</Text>
                    </View>
                  </View>
                ))}
                <View style={styles.contentTotais}>
                  <View style={styles.item}>
                    <Text>Totais</Text>
                  </View>
                  <View style={[styles.item]}>
                    <Text />
                  </View>
                  <View style={styles.item}>
                    <Text>{formatMoeda(totaisEntradas)}</Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {saidas.length > 0 && (
            <View style={styles.sessao}>
              <View style={styles.subTitle}>
                <Text>Saídas</Text>
              </View>
              <View style={styles.container}>
                <View style={styles.header}>
                  <View style={[styles.item]}>
                    <Text>Descrição</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Horário</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Valor</Text>
                  </View>
                </View>
                {saidas.map((item) => (
                  <View style={styles.content}>
                    <View style={[styles.item]}>
                      <Text>{item.nome}</Text>
                    </View>
                    <View style={styles.item}>
                      <Text>{item.horario}</Text>
                    </View>
                    <View style={styles.item}>
                      <Text>{formatMoeda(item.valor)}</Text>
                    </View>
                  </View>
                ))}
                <View style={styles.contentTotais}>
                  <View style={styles.item}>
                    <Text>Totais</Text>
                  </View>
                  <View style={[styles.item]}>
                    <Text />
                  </View>
                  <View style={styles.item}>
                    <Text>{formatMoeda(totaisSaidas)}</Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {transferenciasSaida.length > 0 && (
            <View style={styles.sessao}>
              <View style={styles.subTitle}>
                <Text>Retiradas</Text>
              </View>
              <View style={styles.container}>
                <View style={styles.header}>
                  <View style={[styles.item]}>
                    <Text>Horário</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Valor</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Para</Text>
                  </View>
                </View>
                {transferenciasSaida.map((item) => (
                  <View style={styles.content}>
                    <View style={[styles.item]}>
                      <Text>{item.horario}</Text>
                    </View>
                    <View style={styles.item}>
                      <Text>{formatMoeda(item.valor)}</Text>
                    </View>
                    <View style={styles.item}>
                      <Text>{item.conta}</Text>
                    </View>
                  </View>
                ))}
                <View style={styles.contentTotais}>
                  <View style={[styles.item]}>
                    <Text />
                  </View>
                  <View style={[styles.item]}>
                    <Text>Totais</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>{formatMoeda(totaisTransferenciasSaida)}</Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {transferenciasEntrada.length > 0 && (
            <View style={styles.sessao}>
              <View style={styles.subTitle}>
                <Text>Depósitos</Text>
              </View>
              <View style={styles.container}>
                <View style={styles.header}>
                  <View style={[styles.item]}>
                    <Text>Horário</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>Valor</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>De</Text>
                  </View>
                </View>
                {transferenciasEntrada.map((item) => (
                  <View style={styles.content}>
                    <View style={[styles.item]}>
                      <Text>{item.horario}</Text>
                    </View>
                    <View style={styles.item}>
                      <Text>{formatMoeda(item.valor)}</Text>
                    </View>
                    <View style={styles.item}>
                      <Text>{item.conta}</Text>
                    </View>
                  </View>
                ))}
                <View style={styles.contentTotais}>
                  <View style={[styles.item]}>
                    <Text />
                  </View>
                  <View style={[styles.item]}>
                    <Text>Totais</Text>
                  </View>
                  <View style={styles.item}>
                    <Text>{formatMoeda(totaisTransferenciasEntrada)}</Text>
                  </View>
                </View>
              </View>
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default RelatorioFC;
