import React, { useState, useRef } from 'react';
import { Title } from 'react-admin';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AssignmentIcon from '@material-ui/icons/Assignment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PropTypes from 'prop-types'; // ES6

import DialogoInventario from '../../../Components/DialogoInventario';
import { getEstoqueLabel } from '../../../utils';
import Filters from './Filters';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '20px',
  },
  rowHead: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  rowHead2: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  rowResumo: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  negativo: {
    backgroundColor: '#ffc7cf',
  },
  positivo: {
    backgroundColor: '#cbf5d0',
  },
}));

function formatMoeda(valor) {
  return valor.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(true);
  const classes = useRowStyles();
  const classes2 = useStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.nome}
        </TableCell>
        <TableCell align="right">{formatMoeda(row.precoTotalCompra)}</TableCell>
        <TableCell align="right">{formatMoeda(row.precoTotalVenda)}</TableCell>
        <TableCell align="right">{formatMoeda(row.lucro)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {/* <Typography variant="h6" gutterBottom component="div">
                Produtos
              </Typography> */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow className={classes2.rowHead2}>
                    <TableCell className={classes2.rowHead2}>Produto</TableCell>
                    <TableCell className={classes2.rowHead2} align="right">
                      Unidades
                    </TableCell>
                    <TableCell className={classes2.rowHead2} align="right">
                      Peso (Kg)
                    </TableCell>
                    <TableCell className={classes2.rowHead2} align="right">
                      Total em Compra
                    </TableCell>
                    <TableCell className={classes2.rowHead2} align="right">
                      Total em Venda
                    </TableCell>
                    <TableCell className={classes2.rowHead2} align="right">
                      Lucro
                    </TableCell>
                    <TableCell className={classes2.rowHead2} align="right">
                      Estoque Mínimo
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.produtos.map((historyRow) => (
                    <TableRow key={historyRow.nome}>
                      <TableCell component="th" scope="row">
                        {historyRow.nome}
                      </TableCell>
                      <TableCell align="right">
                        {getEstoqueLabel(historyRow)}
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.pesoDisponivel}
                      </TableCell>
                      <TableCell align="right">
                        {formatMoeda(historyRow.precoTotalCompra)}
                      </TableCell>
                      <TableCell align="right">
                        {formatMoeda(historyRow.precoTotalVenda)}
                      </TableCell>
                      <TableCell align="right">
                        {formatMoeda(historyRow.lucro)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={
                          historyRow.statusEstoque
                            ? classes2.positivo
                            : classes2.negativo
                        }
                      >
                        {historyRow.statusEstoque ? 'Acima' : 'Abaixo'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
/* 
Row.propTypes = {
  row: PropTypes.any,
}; */

const dataInit = {
  rows: [],
  valorCompraTotal: 0,
  valorVendaTotal: 0,
  pesoTotal: '0,000',
  unidadesTotal: 0,
};

export default function CollapsibleTable() {
  const [data, setData] = useState(dataInit);
  const classes = useStyles();
  const refDialogoInventario = useRef(null);

  function handleClick() {
    if (refDialogoInventario.current) {
      refDialogoInventario.current.handleOpen();
    }
  }

  return (
    <>
      <Title title="Inventário" />
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1 }}>
            <Filters setData={setData} />
          </div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={handleClick}
          >
            <AssignmentIcon />
          </IconButton>
        </div>
        <TableContainer component={Paper} className={classes.root}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow className={classes.rowHead}>
                <TableCell />
                <TableCell className={classes.rowHead}>Categoria</TableCell>
                <TableCell className={classes.rowHead} align="right">
                  Preço Total de Compra
                </TableCell>
                <TableCell className={classes.rowHead} align="right">
                  Preço Total de Venda
                </TableCell>
                <TableCell className={classes.rowHead} align="right">
                  Lucro
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.rows.map((row) => (
                <Row key={row.nome} row={row} />
              ))}
              <TableRow>
                <TableCell
                  align="right"
                  colSpan={2}
                  className={classes.rowResumo}
                />
                <TableCell
                  align="right"
                  colSpan={2}
                  className={classes.rowResumo}
                >
                  Soma Total Compra
                </TableCell>
                <TableCell align="right" className={classes.rowResumo}>
                  {formatMoeda(data.valorCompraTotal)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="right"
                  colSpan={2}
                  className={classes.rowResumo}
                />
                <TableCell
                  align="right"
                  colSpan={2}
                  className={classes.rowResumo}
                >
                  Soma Total Venda
                </TableCell>
                <TableCell align="right" className={classes.rowResumo}>
                  {formatMoeda(data.valorVendaTotal)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="right"
                  colSpan={2}
                  className={classes.rowResumo}
                />
                <TableCell
                  align="right"
                  colSpan={2}
                  className={classes.rowResumo}
                >
                  Lucro total
                </TableCell>
                <TableCell align="right" className={classes.rowResumo}>
                  {formatMoeda(data.valorVendaTotal - data.valorCompraTotal)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <DialogoInventario dados={data} ref={refDialogoInventario} />
    </>
  );
}
