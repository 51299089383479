import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';

import { STATUS_ATIVO, STATUS_INATIVO } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    /* width: theme.spacing(3),
    height: theme.spacing(3), */
  },
}));

const ActionsField = ({
  tipo,
  handleEdit = () => {},
  handleAtivar = () => {},
  handleDesativar = () => {},
  handleDeletar = () => {},
  ativo,
  ...rest
}) => {
  const classes = useStyles();
  const record = useRecordContext(rest);
  const status = record ? record.record.status : '';
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        // width: '120px',
      }}
    >
      <Tooltip title="Editar" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="edit"
          classes={{ root: classes.root }}
          size="small"
          onClick={() => handleEdit(record.record)}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
      {status === STATUS_INATIVO && (
        <Tooltip title="Ativar" placement="bottom" aria-label="ativar2">
          <IconButton
            aria-label="ativar"
            size="small"
            onClick={() => handleAtivar(record.record)}
          >
            <CheckCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
      {status === STATUS_ATIVO && (
        <Tooltip title="Desativar" placement="bottom" aria-label="desativar2">
          <IconButton
            aria-label="desativar"
            size="small"
            onClick={() => handleDesativar(record.record)}
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      )}
      {status === STATUS_INATIVO && (
        <Tooltip title="Excluir" placement="bottom" aria-label="excluir2">
          <IconButton
            aria-label="excluir"
            size="small"
            onClick={() => handleDeletar(record.record)}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

ActionsField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ActionsField;
