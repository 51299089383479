import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import PropTypes from 'prop-types';

import { formatMoeda } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
  },
}));

const ValorField = (props) => {
  const classes = useStyles();
  const record = useRecordContext(props);
  const valor = record ? (record.record ? record.record.valor : 0) : 0;
  const tipo = record
    ? record.record
      ? record.record.tipo
      : 'Despesa'
    : 'Despesa';

  return (
    <div>
      <Typography
        variant="subtitle1"
        style={{
          color: tipo === 'Receita' ? '#4caf67' : '#f44336',
          fontWeight: 'bold',
        }}
      >
        {formatMoeda(valor)}
      </Typography>
    </div>
  );
};

ValorField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ValorField;
