import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import {
  POR_PESO_VARIAVEL,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  getEstoqueLabel,
} from '../../../utils';

function formatPeso(valor) {
  return valor !== null && valor !== undefined
    ? valor.toLocaleString('de-DE', {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      })
    : '';
}

const useStyles = makeStyles((theme) => ({
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
  },
}));

const ValorField = (props) => {
  const record = useRecordContext(props);
  const unidades = record ? record.record.unidades : 0;
  const showUnidades = record
    ? record.record.produto.unidade.tipo !== POR_PESO_VARIAVEL
    : false;
  const produto = record ? record.record.produto : null;
  return (
    <Typography
      variant="subtitle1"
      style={{
        // color: tipo === 'Receita' ? '#4caf67' : '#f44336',
        fontWeight: 'bold',
      }}
    >
      {true ? getEstoqueLabel(produto, unidades) : '-'}
    </Typography>
  );
};

ValorField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ValorField;
