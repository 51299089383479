import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Box, makeStyles, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    /* width: theme.spacing(3),
    height: theme.spacing(3), */
  },
}));

const ActionsField = ({
  tipo,
  handleEdit,
  handleDelete,
  handleEfetivar,
  ...props
}) => {
  const classes = useStyles();
  const record = useRecordContext();
  /* const statusValue = props ? props.record.status : false;
  const disabledEdit = props.record
    ? props.record.despesa.conta.is_caixa &&
      !!props.record.despesa.parcela_venda_id
    : false; */

  const statusValue = record.record ? record.record.despesa.status : false;
  const disabledEdit = record.record
    ? (record.record.despesa.conta.is_caixa &&
        !!record.record.despesa.parcela_venda_id) ||
      record.record.despesa.conta_id === 1
    : false;

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
      <div
        style={{ fontSize: '17px', fontWeight: 'bold', marginRight: '10px' }}
      >
        Ações:
      </div>
      <div style={{ marginRight: '0px' }}>
        {tipo !== 'Transferência' && !statusValue && !record.record.is_cartao && (
          <Tooltip title="Efetivar" placement="bottom" aria-label="add2">
            <IconButton
              aria-label="check"
              classes={{ root: classes.root }}
              size="small"
              onClick={() =>
                handleEfetivar(
                  record.record ? record.record.despesa.id : -1,
                  record.record ? record.record.is_cartao : false,
                  record.record ? record.record.despesa.conta_id : false,
                  statusValue,
                )
              }
            >
              <CheckCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Editar" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="edit"
            classes={{ root: classes.root }}
            size="small"
            onClick={() =>
              handleEdit(
                record.record ? record.record.despesa.id : -1,
                record.record ? record.record.is_cartao : false,
                record.record ? record.record.despesa.conta_id : false,
                statusValue,
              )
            }
            disabled={disabledEdit}
          >
            <EditOutlinedIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

ActionsField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ActionsField;
