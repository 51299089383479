import React, { useEffect, useState, useRef } from 'react';
import { useVersion, Title } from 'react-admin';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { getListAllAPI, getOneAPI } from '../../../services';
import {
  STATUS_ATIVO,
  TURNO_MANHA,
  TURNO_NOITE,
  TURNO_12_HORAS,
  TIPO_FERIADO_DIA_COMUM,
  STATUS_LOJA_FECHADA,
  STATUS_LOJA_ABERTA,
  TIPO_FERIADO_DATA_COMEMORATIVA,
  TIPO_FERIADO_FERIADO,
} from '../../../utils';
import MobillsInput from '../RelatorioVendaMensal/DateInputMobills';
import DialogoResumoTurnoGeral from './DialogoResumoTurnoGeral';
import DialogoTurnos from './DialogoTurnos';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}))(Tooltip);

const StyledTableCell = withStyles((theme) => ({
  head: {
    //backgroundColor: 'green',
    color: 'black',
    //padding: '8px',
    //whiteSpace: 'nowrap',
    //writingMode: 'sideways-lr',
    //textOrientation: 'sideways',
    //width: 'min-content',
    width: '1px',
    whiteSpace: 'nowrap',
    //textAlign: 'center',
    //display: 'flex',
    //justifyContent: 'center',
    padding: '2px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '2px',
    border: '1px solid black',
    textAlign: 'center',
    verticalAlign: 'middle',
    //textAlign: 'center',
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const diasSemana = {
  0: 'Domingo',
  1: 'Segunda - Feira',
  2: 'Terça - Feira',
  3: 'Quarta - Feira',
  4: 'Quinta - Feira',
  5: 'Sexta - Feira',
  6: 'Sábado',
};

const mesesAno = {
  0: 'Janeiro',
  1: 'Fevereiro',
  2: 'Março',
  3: 'Abril',
  4: 'Maio',
  5: 'Junho',
  6: 'Julho',
  7: 'Agosto',
  8: 'Setembro',
  9: 'Outubro',
  10: 'Novembro',
  11: 'Dezembro',
};

function getSemana(domingo, data) {
  const domingoObj = new Date(moment(domingo).endOf('day').format());
  const dataObj = new Date(moment(data).endOf('day').format());

  const delta = Math.abs(domingoObj.getTime() - dataObj.getTime());

  const value = parseInt(delta / (7 * 24 * 60 * 60 * 1000), 10);

  return value;
}

function verificaFolgaDomingo(semanas, equipe) {
  if (equipe) {
    const resto = semanas % 2;
    const id = equipe.id - 1;
    if (resto === id) {
      return true;
    }
    return false;
  }

  return false;
}

function verificaFeriado(dia, equipe_id) {
  switch (dia.tipo) {
    case TIPO_FERIADO_FERIADO:
      if (dia.equipe_colaborador_id) {
        if (dia.equipe_colaborador_id === equipe_id) {
          return true;
        }
        return false;
      }
      if (equipe_id) {
        return true;
      }
      return false;
    case TIPO_FERIADO_DATA_COMEMORATIVA:
      if (dia.equipe_colaborador_id) {
        if (dia.equipe_colaborador_id === equipe_id) {
          return true;
        }
        return false;
      }
      return true;
    default:
      return true;
  }
}

function diasColaborador(dias, diaFolga, domingo, equipe) {
  const vet = [];
  for (let i = 0; i < dias.length; i++) {
    const semanas = getSemana(domingo, dias[i].data);

    const isEquipeFeriado = verificaFeriado(dias[i], equipe ? equipe.id : null);

    vet.push({
      isFeriado: dias[i].tipo === TIPO_FERIADO_FERIADO,
      isLojaFechada: dias[i].status_loja === STATUS_LOJA_FECHADA,
      isFolga: isEquipeFeriado
        ? diaFolga === dias[i].dia_semana
          ? true
          : dias[i].dia_semana === 0
          ? !verificaFolgaDomingo(semanas, equipe)
          : false
        : true,
    });
  }
  return vet;
}

function getLabelTurno(value) {
  switch (value) {
    case TURNO_MANHA:
      return 'Manhã';
    case TURNO_NOITE:
      return 'Noite';
    case TURNO_12_HORAS:
      return '12H';
    default:
      return '';
  }
}

function formatColaborador(dados, dias, domingo) {
  const dadosFormatados = [];
  for (let i = 0; i < dados.length; i++) {
    dadosFormatados.push({
      id: dados[i].id,
      nome: dados[i].nome,
      codinome: dados[i].codinome ? dados[i].codinome.nome : '-',
      turno: getLabelTurno(dados[i].turno),
      grupo_id: dados[i].grupo_colaborador_id
        ? dados[i].grupo_colaborador_id
        : 0,
      equipe_id: dados[i].grupoColaborador
        ? dados[i].grupoColaborador.equipe_colaborador_id
        : 0,
      grupo: dados[i].grupoColaborador ? dados[i].grupoColaborador.nome : '-',
      equipe: dados[i].grupoColaborador
        ? dados[i].grupoColaborador.equipe
          ? dados[i].grupoColaborador.equipe.nome
          : '-'
        : '-',
      funcao: dados[i].funcao ? dados[i].funcao.nome : '-',
      dias: diasColaborador(
        dias,
        dados[i].grupoColaborador ? dados[i].grupoColaborador.dia_da_folga : -1,
        domingo,
        dados[i].grupoColaborador
          ? dados[i].grupoColaborador.equipe
            ? dados[i].grupoColaborador.equipe
            : null
          : null,
      ),
    });
  }
  return dadosFormatados;
}

function formatDias(dias, colaboradores) {
  const diasFormatados = [];
  for (let i = 0; i < dias.length; i++) {
    let total = 0;
    for (let j = 0; j < colaboradores.length; j++) {
      if (!colaboradores[j].dias[i].isFolga) {
        total++;
      }
    }
    diasFormatados.push({
      id: dias[i].id,
      nome: `${dias[i].nome} (${dias[i].id})`,
      dia: dias[i].dia,
      mes: dias[i].mes,
      ano: dias[i].ano,
      data: dias[i].data,
      tipo: dias[i].tipo,
      dia_semana: dias[i].dia_semana,
      status_loja: dias[i].status_loja,
      tipo_horario: dias[i].tipo_horario,
      equipe_colaborador_id: dias[i].equipe_colaborador_id,
      totalColaboradores: total,
    });
  }
  return diasFormatados;
}

const COLUMN_EQUIPE = 'equipe';
const COLUMN_FUNCAO = 'funcao';
const COLUMN_GRUPO = 'grupo';
const COLUMN_CODINOME = 'codinome';
const COLUMN_COLABORADOR = 'nome';
const COLUMN_TURNO = 'turno';

const DashbboardContabilidade = () => {
  const [loading, setLoading] = useState(false);
  const [colaboradores, setColaboradores] = useState([]);
  const [dias, setDias] = useState([]);
  const [data, setData] = useState({
    mes: new Date().getMonth(),
    ano: new Date().getFullYear(),
  });
  const [dataDomingoEquipe1, setDataDomingoEquipe1] = useState(null);
  const refDialogoTurnos = useRef(null);
  const refDialogoResumoTurnoGeral = useRef(null);

  const [columnSort, setColumnSort] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const version = useVersion();

  async function getColaborador(dias, domingo) {
    const data = await getListAllAPI(
      'colaboradores',
      ['id', 'asc'],
      {
        status: STATUS_ATIVO,
        is_funcionario_interno: true,
      },
      ['codinome', 'grupoColaborador.equipe', 'funcao'],
    );

    return formatColaborador(data.data, dias, domingo);
  }

  async function getDias(data2) {
    const data = await getListAllAPI(
      'feriados',
      ['id', 'asc'],
      {
        mes: data2.mes,
        ano: data2.ano,
      },
      [],
    );

    return data.data;
  }

  async function getConfig() {
    const data = await getOneAPI('config', 1, []);
    return data.data;
  }

  async function getDados(data) {
    if (!loading) {
      setLoading(true);
      try {
        const config = await getConfig();

        const domingo = config.data_domingo_trabalho_equipe_1
          ? new Date(config.data_domingo_trabalho_equipe_1)
          : null;

        let dias = await getDias(data);
        const colaboradores = await getColaborador(dias, domingo);
        dias = formatDias(dias, colaboradores);

        setDataDomingoEquipe1(domingo);
        setColaboradores(colaboradores);
        setDias(dias);

        setLoading(false);
      } catch (e) {
        setColaboradores([]);
        setDias([]);

        setLoading(false);
        enqueueSnackbar(`Erro ao carregar os dados do relatório!`, {
          variant: 'error',
        });
      }
    }
  }

  useEffect(() => {
    getDados(data);
  }, [version, data]);

  function formatData(dados) {
    return `${diasSemana[dados.dia_semana]}, ${dados.dia} de ${
      mesesAno[dados.mes]
    } de ${dados.ano}`;
  }

  function ordenacao(a, b, index = 0) {
    if (columnSort.length > 0) {
      if (a[columnSort[index].column] > b[columnSort[index].column]) {
        return columnSort[index].direction === 'asc' ? 1 : -1;
      }

      if (a[columnSort[index].column] < b[columnSort[index].column]) {
        return columnSort[index].direction === 'asc' ? -1 : 1;
      }

      if (index < columnSort.length - 1) {
        return ordenacao(a, b, index + 1);
      }
    }

    return 0;
  }

  const colaboradores2 = [...colaboradores];
  colaboradores2.sort(ordenacao);

  function getTamCell(diaSemana, index, tam) {
    if (index === 0) {
      return tam >= 7 - diaSemana ? 7 - diaSemana : tam;
    }
    if (diaSemana === 0) {
      return tam - 1 - index >= 7 ? 7 : tam - index;
    }
    return -1;
  }

  function getWeekNumber(date) {
    const d = new Date(date);
    const d2 = moment(d).weeks();
    return d2;
  }

  function handleData({ ano, mes }) {
    setData({
      mes,
      ano,
    });
  }

  function openDialogoTurnos(dados) {
    if (refDialogoTurnos.current) {
      refDialogoTurnos.current.handleOpen(dados);
    }
  }

  //////////////////////////////////

  function getFilter(column) {
    const item = columnSort.find((item) => item.column === column);
    const index = columnSort.findIndex((item) => item.column === column);

    return {
      item,
      index,
    };
  }

  function adicionaFilter(e, column) {
    if (e.ctrlKey) {
      const filter = getFilter(column);
      const columnSortLocal = [...columnSort];
      if (filter.item) {
        columnSortLocal[filter.index] = {
          column,
          direction: filter.item.direction === 'asc' ? 'desc' : 'asc',
        };
        setColumnSort(columnSortLocal);
      } else {
        columnSortLocal.push({
          column,
          direction: 'asc',
        });
        setColumnSort(columnSortLocal);
      }
    } else {
      const filter = getFilter(column);
      if (filter.item) {
        setColumnSort([
          {
            column,
            direction: filter.item.direction === 'asc' ? 'desc' : 'asc',
          },
        ]);
      } else {
        setColumnSort([
          {
            column,
            direction: 'asc',
          },
        ]);
      }
    }
  }

  /////////////////////////////////

  const filterEquipe = getFilter(COLUMN_EQUIPE);
  const filterFuncao = getFilter(COLUMN_FUNCAO);
  const filterGrupo = getFilter(COLUMN_GRUPO);
  const filterCodinome = getFilter(COLUMN_CODINOME);
  const filterColaborador = getFilter(COLUMN_COLABORADOR);
  const filterTurno = getFilter(COLUMN_TURNO);

  /////////////////////////////////

  function openResumo() {
    if (refDialogoResumoTurnoGeral.current) {
      refDialogoResumoTurnoGeral.current.handleOpen();
    }
  }

  return (
    <div>
      <div
        style={{
          fontSize: '25px',
          fontWeight: 'bold',
          marginTop: '35px',
          marginBottom: '30px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div />
        <h1
          style={{
            fontSize: '25px',
          }}
        >
          ESCALAS
        </h1>
        <Tooltip title="Resumo dos Turnos" placement="bottom" aria-label="add2">
          <IconButton aria-label="edit" onClick={() => openResumo()}>
            <DataUsageIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Title title="Escalas" />
      <div
        style={{
          margin: '10px 0 20px',
        }}
      >
        <MobillsInput handleData={handleData} />
      </div>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell
              style={{
                backgroundColor: 'white',
              }}
            >
              <span
                style={{
                  whiteSpace: 'nowrap',
                }}
              />
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: 'white',
              }}
            >
              <span
                style={{
                  whiteSpace: 'nowrap',
                }}
              />
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: 'white',
              }}
            >
              <span
                style={{
                  whiteSpace: 'nowrap',
                }}
              />
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: 'white',
              }}
            >
              <span
                style={{
                  whiteSpace: 'nowrap',
                }}
              />
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: 'white',
              }}
            >
              <span
                style={{
                  whiteSpace: 'nowrap',
                }}
              />
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: 'white',
              }}
            >
              <span
                style={{
                  whiteSpace: 'nowrap',
                }}
              />
            </StyledTableCell>
            {dias.map((dia, index) => {
              const tam = getTamCell(dia.dia_semana, index, dias.length);
              return (
                <>
                  {tam >= 0 ? (
                    <StyledTableCell
                      style={{
                        backgroundColor: 'white',
                      }}
                      colSpan={tam}
                    >
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {`SEMANA ${getWeekNumber(new Date(dia.data))}`}
                      </span>
                    </StyledTableCell>
                  ) : null}
                </>
              );
            })}
          </TableRow>
          <TableRow>
            <StyledTableCell
              sortDirection={
                filterFuncao.item ? filterFuncao.item.direction : false
              }
            >
              <TableSortLabel
                active={!!filterFuncao.item}
                direction={
                  filterFuncao.item ? filterFuncao.item.direction : 'asc'
                }
                onClick={(e) => adicionaFilter(e, COLUMN_FUNCAO)}
              >
                <p>
                  <span>CARGOS</span>
                  {filterFuncao.item && columnSort.length > 1 ? (
                    <sub
                      style={{
                        color: 'red',
                        marginLeft: '5px',
                      }}
                    >
                      {filterFuncao.index + 1}
                    </sub>
                  ) : null}
                  {filterFuncao.item ? (
                    <span className={classes.visuallyHidden}>
                      {filterFuncao.item.direction === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </p>
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell
              sortDirection={
                filterCodinome.item ? filterCodinome.item.direction : false
              }
            >
              <TableSortLabel
                active={!!filterCodinome.item}
                direction={
                  filterCodinome.item ? filterCodinome.item.direction : 'asc'
                }
                onClick={(e) => adicionaFilter(e, COLUMN_CODINOME)}
              >
                <p>
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      writingMode: 'vertical-rl',
                      transform: 'rotate(180deg)',
                    }}
                  >
                    CODINOME
                  </span>
                  {filterCodinome.item && columnSort.length > 1 ? (
                    <sub
                      style={{
                        color: 'red',
                        marginLeft: '5px',
                      }}
                    >
                      {filterCodinome.index + 1}
                    </sub>
                  ) : null}
                  {filterCodinome.item ? (
                    <span className={classes.visuallyHidden}>
                      {filterCodinome.item.direction === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </p>
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell
              sortDirection={
                filterColaborador.item
                  ? filterColaborador.item.direction
                  : false
              }
            >
              <TableSortLabel
                active={!!filterColaborador.item}
                direction={
                  filterColaborador.item
                    ? filterColaborador.item.direction
                    : 'asc'
                }
                onClick={(e) => adicionaFilter(e, COLUMN_COLABORADOR)}
              >
                <p>
                  <span>NOME DO COLABORADOR</span>
                  {filterColaborador.item && columnSort.length > 1 ? (
                    <sub
                      style={{
                        color: 'red',
                        marginLeft: '5px',
                      }}
                    >
                      {filterColaborador.index + 1}
                    </sub>
                  ) : null}
                  {filterColaborador.item ? (
                    <span className={classes.visuallyHidden}>
                      {filterColaborador.item.direction === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </p>
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell
              sortDirection={
                filterTurno.item ? filterTurno.item.direction : false
              }
            >
              <TableSortLabel
                active={!!filterTurno.item}
                direction={
                  filterTurno.item ? filterTurno.item.direction : 'asc'
                }
                onClick={(e) => adicionaFilter(e, COLUMN_TURNO)}
              >
                <p>
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      writingMode: 'vertical-rl',
                      transform: 'rotate(180deg)',
                    }}
                  >
                    TURNO
                  </span>
                  {filterTurno.item && columnSort.length > 1 ? (
                    <sub
                      style={{
                        color: 'red',
                        marginLeft: '5px',
                      }}
                    >
                      {filterTurno.index + 1}
                    </sub>
                  ) : null}
                  {filterTurno.item ? (
                    <span className={classes.visuallyHidden}>
                      {filterTurno.item.direction === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </p>
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell
              sortDirection={
                filterEquipe.item ? filterEquipe.item.direction : false
              }
            >
              <TableSortLabel
                active={!!filterEquipe.item}
                direction={
                  filterEquipe.item ? filterEquipe.item.direction : 'asc'
                }
                onClick={(e) => adicionaFilter(e, COLUMN_EQUIPE)}
              >
                <p>
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      writingMode: 'vertical-rl',
                      transform: 'rotate(180deg)',
                    }}
                  >
                    EQUIPE
                  </span>
                  {filterEquipe.item && columnSort.length > 1 ? (
                    <sub
                      style={{
                        color: 'red',
                        marginLeft: '5px',
                      }}
                    >
                      {filterEquipe.index + 1}
                    </sub>
                  ) : null}
                  {filterEquipe.item ? (
                    <span className={classes.visuallyHidden}>
                      {filterEquipe.item.direction === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </p>
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell
              sortDirection={
                filterGrupo.item ? filterGrupo.item.direction : false
              }
            >
              <TableSortLabel
                active={!!filterGrupo.item}
                direction={
                  filterGrupo.item ? filterGrupo.item.direction : 'asc'
                }
                onClick={(e) => adicionaFilter(e, COLUMN_GRUPO)}
              >
                <p>
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      writingMode: 'vertical-rl',
                      transform: 'rotate(180deg)',
                    }}
                  >
                    GRUPO
                  </span>
                  {filterGrupo.item && columnSort.length > 1 ? (
                    <sub
                      style={{
                        color: 'red',
                        marginLeft: '5px',
                      }}
                    >
                      {filterGrupo.index + 1}
                    </sub>
                  ) : null}
                  {filterGrupo.item ? (
                    <span className={classes.visuallyHidden}>
                      {filterGrupo.item.direction === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </p>
              </TableSortLabel>
            </StyledTableCell>
            {dias.map((dia) => (
              <>
                {dia.tipo === TIPO_FERIADO_DIA_COMUM ? (
                  <StyledTableCell
                    style={{
                      backgroundColor: 'white',
                      cursor: 'pointer',
                    }}
                    onClick={
                      dia.status_loja === STATUS_LOJA_ABERTA
                        ? () => openDialogoTurnos(dia)
                        : () => {}
                    }
                  >
                    <span
                      style={{
                        whiteSpace: 'nowrap',
                        writingMode: 'vertical-rl',
                        transform: 'rotate(180deg)',
                      }}
                    >
                      {formatData(dia)}
                    </span>
                  </StyledTableCell>
                ) : (
                  <LightTooltip title={dia.nome}>
                    <StyledTableCell
                      style={{
                        backgroundColor:
                          dia.tipo === TIPO_FERIADO_FERIADO
                            ? 'green'
                            : 'orange',
                        cursor: 'pointer',
                      }}
                      onClick={
                        dia.status_loja === STATUS_LOJA_ABERTA
                          ? () => openDialogoTurnos(dia)
                          : () => {}
                      }
                    >
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                          writingMode: 'vertical-rl',
                          transform: 'rotate(180deg)',
                        }}
                      >
                        {formatData(dia)}
                      </span>
                    </StyledTableCell>
                  </LightTooltip>
                )}
              </>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {colaboradores2.map((colaborador) => (
            <TableRow>
              <StyledTableCell
                style={{
                  textAlign: 'start',
                }}
              >
                {colaborador.funcao}
              </StyledTableCell>
              <StyledTableCell>{colaborador.codinome}</StyledTableCell>
              <StyledTableCell
                style={{
                  textAlign: 'start',
                }}
              >
                {colaborador.nome}
              </StyledTableCell>
              <StyledTableCell>{colaborador.turno}</StyledTableCell>
              <StyledTableCell>{colaborador.equipe}</StyledTableCell>
              <StyledTableCell>{colaborador.grupo}</StyledTableCell>
              {colaborador.dias.map((dia) => (
                <StyledTableCell
                  style={{
                    backgroundColor: !dia.isLojaFechada
                      ? dia.isFolga
                        ? dia.isFeriado
                          ? 'green'
                          : 'white'
                        : 'yellow'
                      : 'green',
                  }}
                >
                  {dia.isLojaFechada ? '' : dia.isFolga ? '' : 'X'}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
          <TableRow>
            <StyledTableCell colSpan={6}>
              Total de Colaboradores Simultâneos
            </StyledTableCell>
            {dias.map((dia) => (
              <StyledTableCell
                style={{
                  backgroundColor: 'yellow',
                }}
              >
                {dia.totalColaboradores}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogoTurnos ref={refDialogoTurnos} />
      <DialogoResumoTurnoGeral ref={refDialogoResumoTurnoGeral} />
    </div>
  );
};

export default DashbboardContabilidade;
