import React, { forwardRef } from 'react';

import { useDebounce } from 'use-debounce';

import AutoCompleteClientes from './Template';

const Search = forwardRef(
  (
    {
      value,
      onChange,
      handleEnter = () => {},
      handleKey = () => {},
      resource,
      label,
      nested = [],
      toString = false,
      filters = {},
      campo = 'nome',
      autoFocus = false,
      ...rest
    },
    forwardedRef,
  ) => {
    const [inputValue, setInputValue] = React.useState('');
    const [valueDebounce] = useDebounce(inputValue, 500);

    function keyPress(keyCode, keyName) {
      if (keyCode === 13) handleEnter();
      else handleKey(keyCode, keyName);
    }

    return (
      <AutoCompleteClientes
        inputValue={inputValue}
        onChange={(newValue) => {
          if (toString) onChange({ target: { name: rest.name, value: JSON.stringify(newValue) } });
          else onChange(newValue);
        }}
        updateValue={(newValue) => setInputValue(newValue)}
        value={toString ? JSON.parse(value) : value}
        label={label}
        valueDebounce={valueDebounce}
        handleKey={keyPress}
        ref={forwardedRef}
        resource={resource}
        nested={nested}
        filters={filters}
        campo={campo}
        autoFocus={autoFocus}
        {...rest}
      />
    );
  },
);

export default Search;
