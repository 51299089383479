import React, { useState, useRef, useEffect } from 'react';
import { Title, useVersion } from 'react-admin';
import { useLocation, useHistory } from 'react-router-dom';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import BarChartIcon from '@material-ui/icons/BarChart';
import { useSnackbar } from 'notistack';
import { stringify } from 'query-string';

import { api } from '../../../services';
import {
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_UNIDADE,
} from '../../../utils/constants';
import DialogoGrafico from './DialogoGrafico';

function formatPeso(valor, casasDecimais = 3) {
  return valor !== null && valor !== undefined
    ? `${valor.toLocaleString('de-DE', {
        minimumFractionDigits: casasDecimais,
        maximumFractionDigits: casasDecimais,
      })} Kg`
    : '';
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableRowPorcentagem = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(136, 14, 79, 1)',
    color: 'white',
  },
}))(TableRow);

const StyledTableRowTotais = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(136, 14, 79, 1)',
    color: 'white',
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const optionsDias = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '7',
    value: 7,
  },
  {
    label: '14',
    value: 14,
  },
  {
    label: '21',
    value: 21,
  },
  {
    label: '28',
    value: 28,
  },
  {
    label: '35',
    value: 35,
  },
  {
    label: '42',
    value: 42,
  },
  {
    label: '60',
    value: 60,
  },
  {
    label: '90',
    value: 90,
  },
  {
    label: '120',
    value: 120,
  },
  {
    label: '360',
    value: 360,
  },
];

const optionsSemana = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '8',
    value: 8,
  },
  {
    label: '12',
    value: 12,
  },
  {
    label: '24',
    value: 24,
  },
  {
    label: '36',
    value: 36,
  },
];

const optionsMeses = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '1',
    value: 12,
  },
  {
    label: '24',
    value: 24,
  },
  {
    label: '36',
    value: 36,
  },
];

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const DashbboardContabilidade = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [produtoName, setProdutoName] = useState('');
  const [dados, setDados] = useState([]);
  const [tipoProduto, setTipoProduto] = useState(1);
  const [tipoPeriodo, setTipoPeriodo] = useState(1);
  const [periodo, setPeriodo] = useState(2);
  const { enqueueSnackbar } = useSnackbar();
  const version = useVersion();
  const query = useQuery();
  const history = useHistory();

  const refDialogo = useRef(null);

  function handleOpenDialogo() {
    if (refDialogo.current) {
      refDialogo.current.handleOpen();
    }
  }

  async function handleData() {
    setLoading(true);
    try {
      const dados = await api.get(
        `/fluxo_de_caixa_produtos_estoque?tipo_periodo=${tipoPeriodo}&periodo=${periodo}&produto_id=${query.get(
          'produto_id',
        )}`,
      );
      setDados(dados.data.linhas);
      setProdutoName(dados.data.produtoName);
      setTipoProduto(dados.data.tipoUnidade);
      setLoading(false);
    } catch (e) {
      setDados([]);
      setProdutoName('');
      setTipoProduto('');
      setLoading(false);
      enqueueSnackbar(`Erro ao carregar os dados do relatório!`, {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    handleData();
  }, [version, periodo]);

  function getLabel() {
    switch (tipoPeriodo) {
      case 0:
        return 'Dia';
      case 1:
        return 'Semana';
      case 2:
        return 'Mês';
      default:
        return [];
    }
  }

  const label = getLabel();

  function getOptions() {
    switch (tipoPeriodo) {
      case 0:
        return optionsDias;
      case 1:
        return optionsSemana;
      case 2:
        return optionsMeses;
      default:
        return [];
    }
  }

  useEffect(() => {
    switch (tipoPeriodo) {
      case 0:
        setPeriodo(optionsDias[0].value);
        break;
      case 1:
        setPeriodo(optionsSemana[0].value);
        break;
      case 2:
        setPeriodo(optionsMeses[0].value);
        break;
    }
  }, [tipoPeriodo]);

  function navegate(resource, filters) {
    const queryOj = {};

    queryOj.displayedFilters = JSON.stringify({});
    queryOj.filter = JSON.stringify(filters);
    history.push(
      `/${resource}?${stringify(
        queryOj,
      )}&order=DESC&page=1&perPage=100&sort=data_pagamento`,
    );
  }

  function navegar(page) {
    switch (page) {
      case 1:
        navegate('fluxo_de_caixa_produtos_estoque_vendas', {
          produto_id: query.get('produto_id'),
        });
        break;
      case 2:
        navegate('fluxo_de_caixa_produtos_estoque_compras', {
          produto_id: query.get('produto_id'),
        });
        break;
      case 3:
        navegate('fluxo_de_caixa_produtos_estoque_avarias', {
          produto_id: query.get('produto_id'),
        });
        break;
      case 4:
        navegate('fluxo_de_caixa_produtos_estoque_correcoes', {
          produto_id: query.get('produto_id'),
        });
        break;
    }
  }

  function getTipo() {
    switch (tipoProduto) {
      case POR_PESO_FIXO:
        return 1;
      case POR_PESO_VARIAVEL:
        return 1;
      case POR_PESO_VARIAVEL_SEM_UNIDADE:
        return 1;
      case POR_UNIDADE:
        return 0;
      default:
        return 0;
    }
  }

  function formatarQtde(valor, tipo) {
    if (tipo === 0) {
      return valor;
    }
    return formatPeso(valor);
  }

  const isUnidade = getTipo();

  return (
    <div>
      <div
        style={{
          fontSize: '25px',
          fontWeight: 'bold',
          marginTop: '35px',
          marginBottom: '30px',
        }}
      >
        {`Relatório Fluxo de Caixa ${''}`}
      </div>
      <Title title={`Relatório Fluxo de Caixa ${''}`} />
      <div
        style={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', gap: '20px' }}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: '150px' }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Tipo de Período
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={tipoPeriodo}
              onChange={(e) => setTipoPeriodo(e.target.value)}
              label="Tipo de Período"
              color="primary"
            >
              <MenuItem value={0}>Dias</MenuItem>
              <MenuItem value={1}>Semanas</MenuItem>
              <MenuItem value={2}>Meses</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: '170px' }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Período de Análise
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={periodo}
              onChange={(e) => setPeriodo(e.target.value)}
              label="Tipo de Período"
              color="primary"
            >
              {getOptions().map(({ label, value }) => (
                <MenuItem value={value}>{label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Tooltip title="Gerar Gráfico" placement="top" aria-label="Imprimir">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={handleOpenDialogo}
          >
            <BarChartIcon />
          </IconButton>
        </Tooltip>
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{label}</StyledTableCell>
              <StyledTableCell align="right">Estoque</StyledTableCell>
              <StyledTableCell
                align="right"
                onClick={() => navegar(1)}
                style={{ cursor: 'pointer' }}
              >
                Vendas
              </StyledTableCell>
              <StyledTableCell
                align="right"
                onClick={() => navegar(2)}
                style={{ cursor: 'pointer' }}
              >
                Compras
              </StyledTableCell>
              <StyledTableCell
                align="right"
                onClick={() => navegar(3)}
                style={{ cursor: 'pointer' }}
              >
                Avarias
              </StyledTableCell>
              <StyledTableCell
                align="right"
                onClick={() => navegar(4)}
                style={{ cursor: 'pointer' }}
              >
                Correções
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dados.map((row) => {
              const tipo = getTipo();
              return (
                <StyledTableRow key={row.label}>
                  <StyledTableCell>{row.label}</StyledTableCell>
                  <StyledTableCell align="right">
                    {formatarQtde(
                      tipo === 0 ? row.unidades_estoque : row.peso_estoque,
                      tipo,
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {formatarQtde(
                      tipo === 0 ? row.unidades_vendas : row.peso_vendas,
                      tipo,
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {formatarQtde(
                      tipo === 0 ? row.unidades_compras : row.peso_compras,
                      tipo,
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {formatarQtde(
                      tipo === 0 ? row.unidades_avarias : row.peso_avarias,
                      tipo,
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {formatarQtde(
                      tipo === 0 ? row.unidades_correcoes : row.peso_correcoes,
                      tipo,
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogoGrafico
        ref={refDialogo}
        tipoProduto={isUnidade}
        linhas={dados}
        days={periodo}
      />
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DashbboardContabilidade;
