import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';

import { Paper, Box, Button } from '@material-ui/core';
import moment from 'moment';

import {
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL,
} from '../../../../../../utils';
import {
  PrecoInput,
  DateInput,
  IntegerInput,
  PesoInput,
} from '../../../../../Inputs';

const FormItemVenda = forwardRef(
  (
    { handleNewItem, handleKey = () => {}, disabledSubmit = false, produto },
    ref,
  ) => {
    const [unidades, setUnidades] = useState(0);
    const [peso, setPeso] = useState(0);
    const [unitario, setUnitario] = useState(0);
    const [cg, setCg] = useState(0);
    const [unitarioImposto, setUnitarioImposto] = useState(0);
    const [validade, setValidade] = useState(moment().format('YYYY-MM-DD'));
    const [erros, setErros] = useState(['', '', '', '', '']);

    const refs = useRef([]);
    const refButton = useRef(null);
    const inputs = [
      {
        nome: 'unidades',
        defaultValue: 0,
        label: 'Quantidade',
      },
      {
        nome: 'peso',
        defaultValue: 0,
        label: 'Peso',
      },
      {
        nome: 'unitario',
        defaultValue: 0,
        label: 'Preço Unitário',
      },
      {
        nome: 'unitarioImposto',
        defaultValue: 0,
        label: 'Preço Unitário com Imposto',
      },
      {
        nome: 'validade',
        defaultValue: moment().format('YYYY-MM-DD'),
        label: 'Validade',
      },
      {
        nome: 'cg',
        defaultValue: 0,
        label: 'CG',
      },
    ];

    refs.current = inputs.map(
      (ref, index) => (refs.current[index] = React.createRef()),
    );

    function getTotal(peso, unidades, precoUnitario, produto) {
      if (produto) {
        if (produto.unidade.tipo === POR_UNIDADE) {
          return unidades * precoUnitario;
        }
        return peso * precoUnitario;
      }
      return 0;
    }

    useEffect(() => {
      if (produto) {
        setErros(['', '', '', '', '', '']);
        setUnidades(inputs[0].defaultValue);
        setPeso(inputs[1].defaultValue);
        setUnitario(inputs[2].defaultValue);
        setUnitarioImposto(inputs[3].defaultValue);
        setValidade(inputs[4].defaultValue);
        setCg(inputs[5].defaultValue);
      }
    }, [produto]);

    useEffect(() => {
      if (produto) {
        if (produto.unidade.tipo === POR_PESO_FIXO) {
          setPeso(unidades * produto.peso_medio);
        }
      }
    }, [unidades, produto]);

    function hideUnidades() {
      return produto
        ? produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
        : true;
    }

    function hidePeso() {
      return produto ? produto.unidade.tipo === POR_UNIDADE : true;
    }

    function hideUnitario() {
      return produto === null;
    }

    function hideUnitarioImposto() {
      return produto === null;
    }

    function hideValidade() {
      return produto === null;
    }

    function disabledPeso() {
      return produto ? produto.unidade.tipo === POR_PESO_FIXO : true;
    }

    function disabledUnitario() {
      return false;
    }

    function disabledUnitarioImposto() {
      return false;
    }

    function disabledValidade() {
      return false;
    }

    function disabledCg() {
      return false;
    }

    function validateUnidades() {
      let error = '';
      const condicao = produto
        ? produto.unidade.tipo !== POR_PESO_VARIAVEL_SEM_UNIDADE
        : false;
      if (condicao) {
        if (unidades <= 0 || Number.isNaN(unidades)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validatePeso() {
      let error = '';
      const condicao = produto
        ? produto.unidade.tipo === POR_PESO_VARIAVEL
        : false;
      if (condicao) {
        if (peso <= 0 || Number.isNaN(peso)) {
          error = 'Este campo é obrigatório';
        }
      }
      return error;
    }

    function validateUnitario() {
      let error = '';
      if (unitario <= 0 || Number.isNaN(unitario)) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateUnitarioImposto() {
      let error = '';
      if (unitarioImposto <= 0 || Number.isNaN(unitarioImposto)) {
        error = 'Este campo é obrigatório';
      } else if (unitarioImposto < unitario) {
        error =
          'O preço de compra com impostos, tem que ser maior que o preço de compra!';
      }
      return error;
    }

    function validateValidade() {
      let error = '';
      if (validade === null || validade === '') error = 'Data Inválida!';
      return error;
    }

    function validateCg() {
      let error = '';
      if (cg < 0 || Number.isNaN(cg)) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function getErros() {
      const errosOld = erros.slice();
      errosOld[0] = validateUnidades();
      errosOld[1] = validatePeso();
      errosOld[2] = validateUnitario();
      errosOld[3] = validateUnitarioImposto();
      errosOld[4] = validateValidade();
      errosOld[5] = validateCg();
      return errosOld;
    }

    useEffect(() => {
      setErros(getErros());
    }, [unidades, peso, unitario, validade, unitarioImposto, cg]);

    function resetValues() {
      setErros(['', '', '', '']);
      setUnidades(inputs[0].defaultValue);
      setPeso(inputs[1].defaultValue);
      setUnitario(inputs[2].defaultValue);
      setUnitarioImposto(inputs[3].defaultValue);
      setValidade(inputs[4].defaultValue);
      setCg(inputs[5].defaultValue);
    }

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (i === 2) {
          if (erros[i] === 'Este campo é obrigatório') return true;
        } else if (erros[i] !== '') return true;
      }
      return false;
    }

    const totalItem = getTotal(peso, unidades, unitario, produto);

    // YYYY-MM-DD

    function buildData(data) {
      const ano = parseInt(data.substring(0, 4), 10);
      const mes = parseInt(data.substring(5, 7), 10) - 1;
      const dia = parseInt(data.substring(8, 10), 10);

      const dataFormatada = new Date();
      dataFormatada.setFullYear(ano);
      dataFormatada.setDate(dia);
      dataFormatada.setMonth(mes);
      dataFormatada.setDate(dia);

      return dataFormatada;
    }

    function handleSubmit() {
      if (!hasError()) {
        buildData(validade);
        handleNewItem({
          unidades,
          peso,
          unitario,
          unitarioImposto,
          validade: buildData(validade),
          total: totalItem,
          cg,
        });
        resetValues();
      }
    }

    useImperativeHandle(ref, () => ({
      focus(index = 0) {
        if (refs.current[index].current) {
          refs.current[index].current.focus();
          refs.current[index].current.select();
        }
      },
      submit() {
        handleSubmit();
      },
      redefineValues(unidadesNew, pesoNew) {
        setUnidades(unidadesNew);
        setPeso(pesoNew);
      },
      valuesPrecificacao(dados) {
        if (
          produto.unidade.tipo === POR_UNIDADE &&
          produto.unidade.tipo === POR_PESO_FIXO
        ) {
          setUnidades(dados.qtde);
        } else {
          setPeso(dados.qtde);
        }

        setCg(dados.cg);
        setUnitario(dados.preco_compra);
        setUnitarioImposto(dados.preco_compra_impostos);
        setValidade(dados.validade);
      },
    }));

    function getRefNextInput(index) {
      let position = -1;
      switch (index) {
        case 0:
          if (!hidePeso() && !disabledPeso()) position = 1;
          else if (!disabledUnitario()) position = 2;
          else if (!hideUnitarioImposto()) position = 3;
          else if (!hideValidade()) position = 4;
          break;
        case 1:
          if (!disabledUnitario()) position = 2;
          else if (!hideUnitarioImposto()) position = 3;
          else if (!hideValidade()) position = 4;
          break;
        case 2:
          if (!hideUnitarioImposto()) position = 3;
          else if (!hideValidade()) position = 4;
          break;
        case 3:
          if (!hideValidade()) position = 4;
          else position = 5;
          break;
        case 4:
          position = 5;
          break;
        default:
          break;
      }
      return position;
    }

    function handleNextInput(index) {
      const position = getRefNextInput(index);
      if (position === -1) {
        if (refButton.current && !disabledSubmit) {
          refButton.current.click();
        }
      } else if (refs.current[position].current) {
        refs.current[position].current.focus();
        refs.current[position].current.select();
      }
    }

    return (
      <Paper elevation={3} style={{ opacity: '0.75' }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          padding={disabledSubmit ? '0px' : '15px'}
          overflow="auto"
        >
          {!hideUnidades() && (
            <IntegerInput
              name={inputs[0].nome}
              ref={refs.current[0]}
              handleEnter={() => handleNextInput(0)}
              label={inputs[0].label}
              handleKey={handleKey}
              value={Number.isNaN(unidades) ? '' : String(unidades)}
              onChange={(value) =>
                setUnidades(parseInt(value.target.value, 10))
              }
              error={erros[0] !== ''}
              helperText={erros[0]}
            />
          )}
          {!hidePeso() && (
            <PesoInput
              name={inputs[1].nome}
              ref={refs.current[1]}
              handleEnter={() => handleNextInput(1)}
              label={inputs[1].label}
              handleKey={handleKey}
              value={Number.isNaN(peso) ? '' : String(peso)}
              onChange={(value) => setPeso(parseFloat(value.target.value))}
              error={erros[1] !== ''}
              helperText={erros[1]}
              disabled={disabledPeso()}
            />
          )}
          {!hideUnitario() && (
            <PrecoInput
              name={inputs[2].nome}
              ref={refs.current[2]}
              handleEnter={() => handleNextInput(2)}
              label={inputs[2].label}
              handleKey={handleKey}
              value={Number.isNaN(unitario) ? '' : String(unitario)}
              onChange={(value) => setUnitario(parseFloat(value.target.value))}
              error={erros[2] === 'Este campo é obrigatório'}
              helperText={`${erros[2]}`}
              disabled={disabledUnitario()}
              escala={6}
            />
          )}
          {!hideUnitarioImposto() && (
            <PrecoInput
              name={inputs[3].nome}
              ref={refs.current[3]}
              handleEnter={() => handleNextInput(3)}
              label={inputs[3].label}
              handleKey={handleKey}
              value={
                Number.isNaN(unitarioImposto) ? '' : String(unitarioImposto)
              }
              onChange={(value) =>
                setUnitarioImposto(parseFloat(value.target.value))
              }
              error={erros[3] !== ''}
              helperText={erros[3]}
              disabled={disabledUnitarioImposto()}
              escala={6}
            />
          )}
          {!hideValidade() && (
            <DateInput
              name={inputs[4].nome}
              ref={refs.current[4]}
              handleEnter={() => handleNextInput(4)}
              label={inputs[4].label}
              handleKey={handleKey}
              value={validade}
              onChange={(value) => setValidade(value.target.value)}
              error={erros[4] !== ''}
              helperText={erros[4]}
              disabled={disabledValidade()}
            />
          )}
          {!(produto === null) && (
            <PrecoInput
              name={inputs[5].nome}
              ref={refs.current[5]}
              handleEnter={() => handleNextInput(5)}
              label={inputs[5].label}
              handleKey={handleKey}
              value={Number.isNaN(cg) ? '' : String(cg)}
              onChange={(value) => setCg(parseFloat(value.target.value))}
              error={erros[5] !== ''}
              helperText={erros[5]}
              escala={6}
            />
          )}
          {!(produto === null) && (
            <PrecoInput
              name="total"
              handleEnter={() => {}}
              label="Total"
              handleKey={handleKey}
              value={Number.isNaN(totalItem) ? '' : String(totalItem)}
              onChange={() => {}}
              disabled
              // escala={6}
            />
          )}
          {!disabledSubmit && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              ref={refButton}
              disabled={hasError()}
            >
              Adicionar à lista
            </Button>
          )}
        </Box>
      </Paper>
    );
  },
);

export default FormItemVenda;
