import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';

import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useSnackbar } from 'notistack';

import { createAPI, updateAPI, getListAllAPI, api } from '../../../services';
import {
  POR_PESO_FIXO,
  POR_UNIDADE,
  formatMoeda,
  STATUS_ATIVO,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
} from '../../../utils';
import {
  TextInput,
  AutoCompleteRemoto,
  PesoInput,
  IntegerInput,
  PrecoInput,
  PorcentagemInput,
  TeclaInput,
  SwitchInput,
  CfopInput,
  CstInput,
  NcmInput,
  CestInput,
} from '../../Inputs';
import DialogoSenha from '../DialogoTransacao/DialogoSenha';
import DialogoCreateEditCusto from './DialogoCreateEditCusto';
import DialogoDeletarCusto from './DialogoDeletarCusto';
import LocaisInput from './LocaisInput';

const FECHAR_VENDA_ACTION = 'F8';
const CANCELAR_VENDA_ACTION = 'F4';
const ABA_GERAL = 'F2';
const ABA_IMPOSTOS = 'F10';
const ABA_BALANCA = 'F7';
const DESBLOQUEAR_EDICAO_PRECO_VENDA = 'F9';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function contAlphaNumerico(value) {
  let contAlphaNumericos = 0;
  if (value) {
    for (let i = 0; i < value.length; i += 1) {
      if (value[i] !== '-' && value[i] !== '_') {
        contAlphaNumericos += 1;
      }
    }
  }
  return contAlphaNumericos;
}

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  root: {
    flexGrow: 1,
  },
}));

const NOME_POSITION = 0;
const CODIGO_POSITION = 1;
const UNIDADE_POSITION = 2;
const CATEGORIA_POSITION = 3;
const FAMILIA_POSITION = 4;
const ESTOQUE_MINIMO_POSITION = 5;
const PESO_MEDIO_POSITION = 6;
const TECLA_POSITION = 7;
const FORNECEDOR_POSITION = 8;
const LOCAIS_POSITION = 9;
const PRECO_VENDA_POSITION = 10;

const MARGEM_DESCONTO_POSITION = 11;

const CODIGO_CAIXA_NIVEL_1 = 12;
const UNIDADES_CAIXA_NIVEL_1 = 13;
const CODIGO_CAIXA_NIVEL_2 = 14;
const UNIDADES_CAIXA_NIVEL_2 = 15;
const CODIGO_CAIXA_NIVEL_3 = 16;
const UNIDADES_CAIXA_NIVEL_3 = 17;

const D1_POSITION = 0;
const DIAS_VALIDADE_POSITION = 1;
const TARA_POSITION = 2;
const EXIBIR_DIAS_VALIDADE_POSITION = 3;
const EXIBIR_VALIDADE_EMBALAGEM_POSITION = 4;

const IPI_POSITION = 0;
const PIS_POSITION = 1;
const COFINS_POSITION = 2;
const ICMS_AI_POSITION = 3;
const ICMS_INT_POSITION = 4;
const DF_POSITION = 5;
const RBC_POSITION = 6;
const NCM_POSITION = 7;
const CST_POSITION = 8;
const CFOP_POSITION = 9;

const CST_PIS_ENTRADA_POSITION = 10;
const CST_PIS_SAIDA_POSITION = 11;
const CST_COFINS_ENTRADA_POSITION = 12;
const CST_COFINS_SAIDA_POSITION = 13;
const CEST_POSITION = 14;

const DEFAULT_ID = -1;

const inputsGeral = [
  {
    nome: 'nome', // 0
    defaultValue: '',
    label: 'Nome',
  },
  {
    nome: 'codigo', // 1
    defaultValue: '',
    label: 'Código de Barras',
  },
  {
    nome: 'unidade_id', // 2
    defaultValue: null,
    label: 'Unidade',
  },
  {
    nome: 'categoria_id', // 3
    defaultValue: null,
    label: 'Categoria',
  },
  {
    nome: 'estoque_minimo', // 4
    defaultValue: 0,
    label: 'Estoque Mínimo',
  },
  {
    nome: 'peso_medio', // 5
    defaultValue: 0,
    label: 'Peso Médio',
  },
  {
    nome: 'tecla', // 6
    defaultValue: '',
    label: 'Tecla',
  },
  {
    nome: 'preco_venda', // 7
    defaultValue: 0,
    label: 'Preço de Venda',
  },
  {
    nome: 'preco_revenda', // 8
    defaultValue: 0,
    label: 'Preço de Revenda',
  },
  {
    nome: 'familia_produto_id', // 9
    defaultValue: null,
    label: 'Família',
  },
  {
    nome: 'fornecedor_id', // 10
    defaultValue: null,
    label: 'Fornecedor Padrão',
  },
  {
    nome: 'desconto_caixa', // 11
    defaultValue: null,
    label: 'Margem Desconto (%)',
  },
  {
    nome: 'codigo_caixa_nivel_1', // 12
    defaultValue: '',
    label: 'Código de barras',
  },
  {
    nome: 'unidades_caixa_nivel_1', // 13
    defaultValue: 0,
    label: 'Unidades',
  },
  {
    nome: 'codigo_caixa_nivel_2', // 14
    defaultValue: '',
    label: 'Código de barras',
  },
  {
    nome: 'unidades_caixa_nivel_2', // 15
    defaultValue: 0,
    label: 'Caixas',
  },
  {
    nome: 'codigo_caixa_nivel_3', // 16
    defaultValue: '',
    label: 'Código de barras',
  },
  {
    nome: 'unidades_caixa_nivel_3', // 17
    defaultValue: 0,
    label: 'Caixetas',
  },
  {
    nome: 'locais', // 18
    defaultValue: [],
    label: 'Locais',
  },
  {
    nome: 'peso_provavel', // 5
    defaultValue: 0,
    label: 'Peso Provável',
  },
];

const inputsBalanca = [
  {
    nome: 'd1',
    defaultValue: '',
    label: 'Nome reduzido',
  },
  {
    nome: 'dias_validade',
    defaultValue: 0,
    label: 'Dias de validade',
  },
  {
    nome: 'tara',
    defaultValue: null,
    label: 'Tara',
  },
  {
    nome: 'exibir_dias_validade',
    defaultValue: 0,
    label: 'Exibir dias validade',
  },
  {
    nome: 'exibir_data_embalagem',
    defaultValue: 0,
    label: 'Exibir validade embalagem',
  },
];

const inputsImpostos = [
  {
    nome: 'ipi',
    defaultValue: 0,
    label: 'IPI (%)',
  }, // 0
  {
    nome: 'pis',
    defaultValue: 0,
    label: 'PIS (%)',
  }, // 1
  {
    nome: 'cofins',
    defaultValue: 0,
    label: 'COFINS (%)',
  }, // 2
  {
    nome: 'icms_ai',
    defaultValue: 0,
    label: 'ICMS AI (%)',
  }, // 3
  {
    nome: 'icms_int',
    defaultValue: 0,
    label: 'ICMS INT (%)',
  }, // 4
  {
    nome: 'df',
    defaultValue: 0,
    label: 'DF (%)',
  }, // 5
  {
    nome: 'rbc',
    defaultValue: 0,
    label: 'RBC',
  }, // 6
  {
    nome: 'ncm',
    defaultValue: '',
    label: 'NCM',
  }, // 7
  {
    nome: 'cst',
    defaultValue: '',
    label: 'CST ICMS ENTRADA E SAÍDA',
  }, // 8
  {
    nome: 'cfop',
    defaultValue: '',
    label: 'CFOP',
  }, // 9
  // LALAL
  {
    nome: 'cst_pis_entrada',
    defaultValue: '',
    label: 'CST PIS ENTRADA',
  }, // 10
  {
    nome: 'cst_pis_saida',
    defaultValue: '',
    label: 'CST PIS SAÍDA',
  }, // 11
  {
    nome: 'cst_cofins_entrada',
    defaultValue: '',
    label: 'CST COFINS ENTRADA',
  }, // 12
  {
    nome: 'cst_cofins_saida',
    defaultValue: '',
    label: 'CST COFINS SAÍDA',
  }, // 13
  {
    nome: 'cest',
    defaultValue: '',
    label: 'CEST',
  }, // 14
];

const SidebarInputs = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [id, setId] = useState(DEFAULT_ID);
  const [nome, setNome] = useState('');
  const [codigo, setCodigo] = useState('');
  const [unidade, setUnidade] = useState(null);
  const [categoria, setCategoria] = useState(null);
  const [estoqueMinimo, setEstoqueMinimo] = useState(0);
  const [pesoMedio, setPesoMedio] = useState(0);
  const [tecla, setTecla] = useState('');
  const [precoVenda, setPrecoVenda] = useState(0);
  const [precoCompraMedio, setPrecoCompraMedio] = useState(0);
  const [familia, setFamilia] = useState(null);
  const [value, setValue] = React.useState(0);
  const [locais, setLocais] = useState([]);
  const [locaisOptions, setLocaisOptions] = useState([]);
  const [fornecedores, setFornecedores] = useState([]);
  const [fornecedoresOptions, setFornecedoresOptions] = useState([]);
  const [codigoCaixaNivel1, setCodigoCaixaNivel1] = useState('');
  const [codigoCaixaNivel2, setCodigoCaixaNivel2] = useState('');
  const [codigoCaixaNivel3, setCodigoCaixaNivel3] = useState('');
  const [unidadesCaixaNivel1, setUnidadesCaixaNivel1] = useState(0);
  const [unidadesCaixaNivel2, setUnidadesCaixaNivel2] = useState(0);
  const [unidadesCaixaNivel3, setUnidadesCaixaNivel3] = useState(0);
  const [pesoCaixaNivel1, setPesoCaixaNivel1] = useState(0);
  const [d1, setD1] = useState(0);
  const [diasValidade, setDiasValidade] = useState(0);
  const [exibirDiasValidade, setExibirDiasValidade] = useState(false);
  const [exibirValidadeEmbalagem, setExibirValidadeEmbalagem] = useState(false);
  const [tara, setTara] = useState(null);
  const [ipi, setIpi] = useState(0);
  const [pis, setPis] = useState(0);
  const [cofins, setCofins] = useState(0);
  const [icmsAi, setIcmsAi] = useState(0);
  const [icmsInt, setIcmsInt] = useState(0);
  const [df, setDf] = useState(0);
  const [rbc, setRbc] = useState(0);
  const [ncm, setNcm] = useState('');
  const [cst, setCst] = useState('');
  const [cfop, setCfop] = useState('');
  const [cstPisEntrada, setCstPisEntrada] = useState('');
  const [cstPisSaida, setCstPisSaida] = useState('');
  const [cstCofinsEntrada, setCstCofinsEntrada] = useState('');
  const [cstCofinsSaida, setCstCofinsSaida] = useState('');
  const [cest, setCest] = useState('');
  const [custos, setCustos] = useState([]);
  const [produtoBase, setProdutoBase] = useState(null);
  const [isIndustrializado, setIsIndustrializado] = useState(false);
  const [isPodeEditarPreco, setIsPodeEditarPreco] = useState(false);
  const [
    isPodeEditarPrecoConferenciaCaixaria,
    setIsPodeEditarPrecoConferenciaCaixaria,
  ] = useState(false);
  const [pesoProvavel, setPesoProvavel] = useState(0);

  const refDialogoCusto = useRef(null);
  const refDialogoDeletarCusto = useRef(null);
  const refsGeral = useRef([]);
  const refsBalanca = useRef([]);
  const refsImposto = useRef([]);
  const refDialogoSenha = useRef(null);

  const [carregando, setCarregando] = useState(false);

  refsGeral.current = inputsGeral.map(
    (ref, index) => (refsGeral.current[index] = React.createRef()),
  );

  refsBalanca.current = inputsBalanca.map(
    (ref, index) => (refsBalanca.current[index] = React.createRef()),
  );

  refsImposto.current = inputsImpostos.map(
    (ref, index) => (refsImposto.current[index] = React.createRef()),
  );

  const isPorPesoFixo = unidade ? unidade.tipo === POR_PESO_FIXO : false;
  const isPorUnidade = unidade ? unidade.tipo === POR_UNIDADE : false;
  const isPorPesoVariavel = unidade
    ? unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
    : false;

  const precoVendaCaixaNivel1 =
    precoVenda *
    (isPorPesoFixo || isPorUnidade
      ? unidadesCaixaNivel1 * (isPorPesoFixo ? pesoMedio : 1)
      : isPorPesoVariavel
      ? pesoCaixaNivel1
      : 0);

  const precoVendaCaixaNivel2 =
    precoVenda *
    (isPorPesoFixo || isPorUnidade
      ? unidadesCaixaNivel1 *
        unidadesCaixaNivel2 *
        (isPorPesoFixo ? pesoMedio : 1)
      : isPorPesoVariavel
      ? pesoCaixaNivel1 * unidadesCaixaNivel2
      : 0);

  const precoVendaCaixaNivel3 =
    precoVenda *
    (isPorPesoFixo || isPorUnidade
      ? unidadesCaixaNivel1 *
        unidadesCaixaNivel2 *
        unidadesCaixaNivel3 *
        (isPorPesoFixo ? pesoMedio : 1)
      : isPorPesoVariavel
      ? pesoCaixaNivel1 * unidadesCaixaNivel2 * unidadesCaixaNivel3
      : 0);

  useEffect(() => {
    async function getDadosLocais() {
      try {
        const data = await getListAllAPI(
          'locais_setores',
          ['id', 'asc'],
          { status: STATUS_ATIVO },
          [],
        );
        setLocaisOptions(data.data);

        const dataFornecedores = await getListAllAPI(
          'fornecedores',
          ['id', 'asc'],
          { status: STATUS_ATIVO },
          [],
        );
        setFornecedoresOptions(dataFornecedores.data);
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }
    if (open) getDadosLocais();
  }, [open]);

  function hidePesoMedio() {
    return !isPorPesoFixo;
  }

  function disabledPesoMedio() {
    return id > 0;
  }

  function disabledUnidade() {
    return id > 0;
  }

  function disabledCategoria() {
    return false;
  }

  function validateNome() {
    let error = '';
    if (!nome) error = 'Este campo é obrigatório';
    else if (nome.length > 80) {
      error = 'Número de caracteres maior que o permitido (máximo 80)';
    }
    return error;
  }

  function validatePesoMedio() {
    let error = '';
    if (isPorPesoFixo) {
      if (pesoMedio <= 0 || Number.isNaN(pesoMedio)) {
        error = 'Este campo é obrigatório para esse tipo de unidade!';
      }
    }
    return error;
  }

  function validatePesoProvavel() {
    let error = '';
    if (isPorPesoVariavel) {
      if (pesoProvavel < 0 || Number.isNaN(pesoProvavel)) {
        error = 'Este campo é obrigatório para esse tipo de unidade!';
      }
    }
    return error;
  }

  function validateCodigo() {
    let error = '';
    if (codigo) {
      if (codigo.length < 6) {
        error = 'Se preenchido esse campo deve ter no mínimo 6 caracteres!';
      } else if (codigo.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
      if (codigo === codigoCaixaNivel1) {
        error = 'O codigo do produto não pode ser igual o código da caixa!';
      }
      if (codigo === codigoCaixaNivel2) {
        error = 'O codigo do produto não pode ser igual o código da caixeta!';
      }
      if (codigo === codigoCaixaNivel3) {
        error = 'O codigo do produto não pode ser igual o código da caixa-mãe!';
      }
    }
    return error;
  }

  function validateCategoria() {
    let error = '';
    if (!categoria) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validateTecla() {
    let error = '';
    if (tecla) {
      const cont = contAlphaNumerico(tecla);
      if (cont !== 4) error = 'A tecla associada deve ter 4 caracteres!';
    }
    return error;
  }

  function validateUnidade() {
    let error = '';
    if (!unidade) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validatePrecoVenda() {
    let error = '';
    if (precoVenda < 0 || Number.isNaN(precoVenda)) {
      error = 'Este campo é obrigatório';
    }
    return error;
  }

  function validatePrecoRevenda() {
    const error = '';
    return error;
  }

  function validateEstoqueMinimo() {
    let error = '';
    if (estoqueMinimo < 0 || Number.isNaN(estoqueMinimo)) {
      error = 'Este campo é obrigatório';
    }

    return error;
  }

  function validateFamilia() {
    const error = '';

    return error;
  }

  function validateFornecedor() {
    const error = '';
    return error;
  }

  function validateCodigoCaixaNivel1() {
    let error = '';
    if (codigoCaixaNivel1) {
      if (codigoCaixaNivel1.length < 6) {
        error = 'Se preenchido esse campo deve ter no mínimo 6 caracteres!';
      } else if (codigoCaixaNivel1.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
      if (codigoCaixaNivel1 === codigo) {
        error = 'O codigo da caixa não pode ser igual o código do produto!';
      }
      if (codigoCaixaNivel1 === codigoCaixaNivel2) {
        error = 'O codigo da caixa não pode ser igual o código da caixeta!';
      }
      if (codigoCaixaNivel1 === codigoCaixaNivel3) {
        error = 'O codigo da caixa não pode ser igual o código da caixa-mãe!';
      }
    }
    return error;
  }

  function validateUnidadesCaixaNivel1() {
    let error = '';
    if (isPorPesoFixo || isPorUnidade) {
      if (unidadesCaixaNivel1 < 0 || Number.isNaN(unidadesCaixaNivel1)) {
        error = 'Este campo é obrigatório';
      }
    } else if (isPorPesoVariavel) {
      if (pesoCaixaNivel1 < 0 || Number.isNaN(pesoCaixaNivel1)) {
        error = 'Este campo é obrigatório';
      }
    }
    return error;
  }

  function validateCodigoCaixaNivel2() {
    let error = '';
    if (codigoCaixaNivel2) {
      if (codigoCaixaNivel2.length < 6) {
        error = 'Se preenchido esse campo deve ter no mínimo 6 caracteres!';
      } else if (codigoCaixaNivel2.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
      if (codigoCaixaNivel2 === codigoCaixaNivel1) {
        error = 'O codigo da caixeta não pode ser igual o código da caixa!';
      }
      if (codigoCaixaNivel2 === codigo) {
        error = 'O codigo da caixeta não pode ser igual o código do produto!';
      }
      if (codigoCaixaNivel2 === codigoCaixaNivel3) {
        error = 'O codigo da caixeta não pode ser igual o código da caixa-mãe!';
      }
    }
    return error;
  }

  function validateUnidadesCaixaNivel2() {
    let error = '';
    if (isPorPesoFixo || isPorUnidade) {
      if (unidadesCaixaNivel2 < 0 || Number.isNaN(unidadesCaixaNivel2)) {
        error = 'Este campo é obrigatório';
      } else if (
        (unidadesCaixaNivel1 <= 0 || Number.isNaN(unidadesCaixaNivel1)) &&
        unidadesCaixaNivel2 > 0
      ) {
        error = 'O nível 1 deve ser preenchido!';
      }
    } else if (isPorPesoVariavel) {
      if (unidadesCaixaNivel2 < 0 || Number.isNaN(unidadesCaixaNivel2)) {
        error = 'Este campo é obrigatório';
      } else if (
        (pesoCaixaNivel1 <= 0 || Number.isNaN(pesoCaixaNivel1)) &&
        unidadesCaixaNivel2 > 0
      ) {
        error = 'O nível 1 deve ser preenchido!';
      }
    }
    return error;
  }

  function validateCodigoCaixaNivel3() {
    let error = '';
    if (codigoCaixaNivel3) {
      if (codigoCaixaNivel3.length < 6) {
        error = 'Se preenchido esse campo deve ter no mínimo 6 caracteres!';
      } else if (codigoCaixaNivel3.length > 80) {
        error = 'Número de caracteres maior que o permitido (máximo 80)';
      }
      if (codigoCaixaNivel3 === codigoCaixaNivel1) {
        error = 'O codigo da caixa-mãe não pode ser igual o código da caixa!';
      }
      if (codigoCaixaNivel3 === codigoCaixaNivel2) {
        error = 'O codigo da caixa-mãe não pode ser igual o código da caixeta!';
      }
      if (codigoCaixaNivel3 === codigo) {
        error = 'O codigo da caixa-mãe não pode ser igual o código do produto!';
      }
    }
    return error;
  }

  function validateUnidadesCaixaNivel3() {
    let error = '';
    if (isPorPesoFixo || isPorUnidade) {
      if (unidadesCaixaNivel3 < 0 || Number.isNaN(unidadesCaixaNivel3)) {
        error = 'Este campo é obrigatório';
      } else if (
        (unidadesCaixaNivel2 <= 0 || Number.isNaN(unidadesCaixaNivel2)) &&
        unidadesCaixaNivel3 > 0
      ) {
        error = 'O nível 2 deve ser preenchido!';
      }
    } else if (isPorPesoVariavel) {
      if (unidadesCaixaNivel3 < 0 || Number.isNaN(unidadesCaixaNivel3)) {
        error = 'Este campo é obrigatório';
      } else if (
        (unidadesCaixaNivel2 <= 0 || Number.isNaN(unidadesCaixaNivel2)) &&
        unidadesCaixaNivel3 > 0
      ) {
        error = 'O nível 2 deve ser preenchido!';
      }
    }
    return error;
  }

  function validateIpi() {
    let error = '';
    if (ipi < 0 || Number.isNaN(ipi)) {
      error = 'Este campo é obrigatório';
    } else if (ipi > 100) {
      error = 'Não pode ultrapassar de 100%!';
    }
    return error;
  }

  function validatePis() {
    let error = '';
    if (pis < 0 || Number.isNaN(pis)) {
      error = 'Este campo é obrigatório';
    } else if (pis > 100) {
      error = 'Não pode ultrapassar de 100%!';
    }
    return error;
  }

  function validateCofins() {
    let error = '';
    if (cofins < 0 || Number.isNaN(cofins)) {
      error = 'Este campo é obrigatório';
    } else if (cofins > 100) {
      error = 'Não pode ultrapassar de 100%!';
    }
    return error;
  }

  function validateIcmsAi() {
    let error = '';
    if (icmsAi < 0 || Number.isNaN(icmsAi)) {
      error = 'Este campo é obrigatório';
    } else if (icmsAi > 100) {
      error = 'Não pode ultrapassar de 100%!';
    }
    return error;
  }

  function validateIcmsInt() {
    let error = '';
    if (icmsInt < 0 || Number.isNaN(icmsInt)) {
      error = 'Este campo é obrigatório';
    } else if (icmsInt > 100) {
      error = 'Não pode ultrapassar de 100%!';
    }
    return error;
  }

  function validateDf() {
    let error = '';
    if (df < 0 || Number.isNaN(df)) {
      error = 'Este campo é obrigatório';
    } else if (df > 100) {
      error = 'Não pode ultrapassar de 100%!';
    }
    return error;
  }

  function validateRbc() {
    let error = '';
    if (rbc < 0 || Number.isNaN(rbc)) {
      error = 'Este campo é obrigatório';
    } else if (rbc > 100) {
      error = 'Não pode ultrapassar de 100%!';
    }
    return error;
  }

  function validateNcm() {
    let error = '';
    if (ncm) {
      if (ncm.length < 8) error = 'O NCM deve ter 8 dígitos';
    }
    return error;
  }

  function validateCst() {
    let error = '';
    if (cst) {
      if (cst.length < 3) error = 'O CST deve ter 3 dígitos';
    }
    return error;
  }

  function validateCfop() {
    let error = '';
    if (cfop) {
      if (cfop.length < 4) error = 'O CFOP deve ter 4 dígitos';
    }
    return error;
  }

  function validateCstPisEntrada() {
    let error = '';
    if (cstPisEntrada) {
      if (cstPisEntrada.length < 3)
        error = 'O CST PIS ENTRADA deve ter 3 dígitos';
    }
    return error;
  }

  function validateCstPisSaida() {
    let error = '';
    if (cstPisSaida) {
      if (cstPisSaida.length < 3) error = 'O CST PIS SAÍDA deve ter 3 dígitos';
    }
    return error;
  }

  function validateCstCofinsEntrada() {
    let error = '';
    if (cstCofinsEntrada) {
      if (cstCofinsEntrada.length < 3)
        error = 'O CST COFINS ENTRADA deve ter 3 dígitos';
    }
    return error;
  }

  function validateCstCofinsSaida() {
    let error = '';
    if (cstCofinsSaida) {
      if (cstCofinsSaida.length < 3)
        error = 'O CST COFINS SAÍDA deve ter 3 dígitos';
    }
    return error;
  }

  function validateCest() {
    let error = '';
    if (cest) {
      if (cest.length < 7) error = 'O CEST deve ter 7 dígitos';
    }
    return error;
  }

  function validateD1() {
    let error = '';
    if (!isPorPesoFixo && !isPorUnidade) {
      if (!d1) error = 'Este campo é obrigatório';
      else if (d1.length > 25) {
        error = 'Número de caracteres maior que o permitido (máximo 25)';
      }
    }
    return error;
  }

  function validateDiasValidade() {
    let error = '';
    if (!isPorPesoFixo && !isPorUnidade) {
      if (diasValidade < 0 || Number.isNaN(diasValidade)) {
        error = 'Este campo é obrigatório';
      }
    }
    return error;
  }

  function validateExibirDiasValidade() {
    const error = '';

    return error;
  }

  function validateExibirValidadeEmbalagem() {
    const error = '';

    return error;
  }

  function validateTara() {
    let error = '';
    if (!isPorPesoFixo && !isPorUnidade) {
      if (!tara) {
        error = 'Este campo é obrigatório';
      }
    }
    return error;
  }

  function getErros() {
    const errosGeral = [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ];
    errosGeral[0] = validateNome();
    errosGeral[1] = validateCodigo();
    errosGeral[2] = validateUnidade();
    errosGeral[3] = validateCategoria();
    errosGeral[4] = validateEstoqueMinimo();
    errosGeral[5] = validatePesoMedio();
    errosGeral[6] = validateTecla();
    errosGeral[7] = validatePrecoVenda();
    errosGeral[8] = validatePrecoRevenda();
    errosGeral[9] = validateFamilia();
    errosGeral[10] = validateFornecedor();
    errosGeral[11] = '';
    errosGeral[12] = validateCodigoCaixaNivel1();
    errosGeral[13] = validateUnidadesCaixaNivel1();
    errosGeral[14] = validateCodigoCaixaNivel2();
    errosGeral[15] = validateUnidadesCaixaNivel2();
    errosGeral[16] = validateCodigoCaixaNivel3();
    errosGeral[17] = validateUnidadesCaixaNivel3();
    errosGeral[18] = '';
    errosGeral[19] = validatePesoProvavel();

    const errosBalanca = ['', '', '', '', ''];
    errosBalanca[0] = validateD1();
    errosBalanca[1] = validateDiasValidade();
    errosBalanca[2] = validateTara();
    errosBalanca[3] = validateExibirDiasValidade();
    errosBalanca[4] = validateExibirValidadeEmbalagem();

    const errosImpostos = [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ];
    errosImpostos[0] = validateIpi();
    errosImpostos[1] = validatePis();
    errosImpostos[2] = validateCofins();
    errosImpostos[3] = validateIcmsAi();
    errosImpostos[4] = validateIcmsInt();
    errosImpostos[5] = validateDf();
    errosImpostos[6] = validateRbc();
    errosImpostos[7] = validateNcm();
    errosImpostos[8] = validateCst();
    errosImpostos[9] = validateCfop();

    errosImpostos[10] = validateCstPisEntrada();
    errosImpostos[11] = validateCstPisSaida();
    errosImpostos[12] = validateCstCofinsEntrada();
    errosImpostos[13] = validateCstCofinsSaida();
    errosImpostos[14] = validateCest();

    return {
      errosGeral,
      errosBalanca,
      errosImpostos,
    };
  }

  const { errosGeral, errosBalanca, errosImpostos } = getErros();

  function hasError() {
    for (let i = 0; i < errosGeral.length; i += 1) {
      if (errosGeral[i] !== '') return true;
    }
    for (let i = 0; i < errosBalanca.length; i += 1) {
      if (errosBalanca[i] !== '') return true;
    }
    for (let i = 0; i < errosImpostos.length; i += 1) {
      if (errosImpostos[i] !== '') return true;
    }
    return false;
  }

  function contarErros() {
    let qtdeErrosGeral = 0;
    let qtdeErrosBalanca = 0;
    let qtdeErrosImposto = 0;
    for (let i = 0; i < errosGeral.length; i += 1) {
      if (errosGeral[i] !== '') {
        qtdeErrosGeral += 1;
      }
    }
    for (let i = 0; i < errosBalanca.length; i += 1) {
      if (errosBalanca[i] !== '') {
        qtdeErrosBalanca += 1;
      }
    }
    for (let i = 0; i < errosImpostos.length; i += 1) {
      if (errosImpostos[i] !== '') {
        qtdeErrosImposto += 1;
      }
    }
    return {
      qtdeErrosGeral,
      qtdeErrosBalanca,
      qtdeErrosImposto,
    };
  }

  const erroExistente = hasError();

  const { qtdeErrosGeral, qtdeErrosBalanca, qtdeErrosImposto } = contarErros();

  const handleCloseDialog = (idUpdate) => {
    setOpen(false);
    handleClose(idUpdate);
  };

  async function reiniciar() {
    try {
      const dataConfig = await getListAllAPI(
        'config',
        ['id', 'asc'],
        { id: 1 },
        [],
      );

      const dataTaraPadrao = await getListAllAPI(
        'taras_balanca',
        ['id', 'asc'],
        { id: 1 },
        [],
      );

      setId(DEFAULT_ID);
      setNome(inputsGeral[0].defaultValue);
      setCodigo(inputsGeral[1].defaultValue);
      setUnidade(inputsGeral[2].defaultValue);
      setCategoria(inputsGeral[3].defaultValue);
      setEstoqueMinimo(inputsGeral[4].defaultValue);
      setPesoMedio(inputsGeral[5].defaultValue);
      setTecla(inputsGeral[6].defaultValue);
      setPrecoVenda(inputsGeral[7].defaultValue);
      setFamilia(inputsGeral[9].defaultValue);
      setPrecoCompraMedio(0);
      setCodigoCaixaNivel1(inputsGeral[12].defaultValue);
      setUnidadesCaixaNivel1(inputsGeral[13].defaultValue);
      setCodigoCaixaNivel2(inputsGeral[14].defaultValue);
      setUnidadesCaixaNivel2(inputsGeral[15].defaultValue);
      setCodigoCaixaNivel3(inputsGeral[16].defaultValue);
      setUnidadesCaixaNivel3(inputsGeral[17].defaultValue);
      setLocais([]);
      setIpi(dataConfig.data[0].ipi);
      setPis(dataConfig.data[0].pis);
      setCofins(dataConfig.data[0].cofins);
      setIcmsAi(dataConfig.data[0].icms_ai);
      setIcmsInt(dataConfig.data[0].icms_int);
      setDf(0);
      setRbc(dataConfig.data[0].rbc);
      setNcm('');
      setCst('');
      setCfop('');
      setCstPisEntrada('');
      setCstPisSaida('');
      setCstCofinsEntrada('');
      setCstCofinsSaida('');
      setCest('');
      setD1('');
      setDiasValidade(0);
      setExibirDiasValidade(false);
      setExibirValidadeEmbalagem(false);
      setTara(dataTaraPadrao.data[0]);
      setCustos([]);
      setIsIndustrializado(false);
      setProdutoBase(null);
      setIsPodeEditarPreco(false);
      setPesoProvavel(0);
      setPesoCaixaNivel1(0);
      setCarregando(false);
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  async function reiniciarApartirFamilia(idFamilia) {
    try {
      const dataFamilias = await getListAllAPI(
        'familias_produtos',
        ['id', 'asc'],
        { id: [idFamilia] },
        [],
      );

      const dataConfig = await getListAllAPI(
        'config',
        ['id', 'asc'],
        { id: 1 },
        [],
      );

      const dataTaraPadrao = await getListAllAPI(
        'taras_balanca',
        ['id', 'asc'],
        { id: 1 },
        [],
      );

      if (dataFamilias.data.length <= 0) throw 'Esta família não existe!';
      setId(DEFAULT_ID);
      setNome(inputsGeral[0].defaultValue);
      setCodigo(inputsGeral[1].defaultValue);
      setUnidade(inputsGeral[2].defaultValue);
      setCategoria(inputsGeral[3].defaultValue);
      setEstoqueMinimo(inputsGeral[4].defaultValue);
      setPesoMedio(inputsGeral[5].defaultValue);
      setTecla(inputsGeral[6].defaultValue);
      setPrecoVenda(dataFamilias.data[0].preco_venda);
      setFamilia(dataFamilias.data[0]);
      setPrecoCompraMedio(0);
      setCodigoCaixaNivel1(inputsGeral[12].defaultValue);
      setUnidadesCaixaNivel1(inputsGeral[13].defaultValue);
      setCodigoCaixaNivel2(inputsGeral[14].defaultValue);
      setUnidadesCaixaNivel2(inputsGeral[15].defaultValue);
      setCodigoCaixaNivel3(inputsGeral[16].defaultValue);
      setUnidadesCaixaNivel3(inputsGeral[17].defaultValue);
      setLocais([]);
      setFornecedores([]);
      setIpi(dataConfig.data[0].ipi);
      setPis(dataConfig.data[0].pis);
      setCofins(dataConfig.data[0].cofins);
      setIcmsAi(dataConfig.data[0].icms_ai);
      setIcmsInt(dataConfig.data[0].icms_int);
      setDf(0);
      setRbc(dataConfig.data[0].rbc);
      setNcm('');
      setCst('');
      setCfop('');
      setCstPisEntrada('');
      setCstPisSaida('');
      setCstCofinsEntrada('');
      setCstCofinsSaida('');
      setCest('');
      setD1('');
      setDiasValidade(0);
      setExibirDiasValidade(false);
      setExibirValidadeEmbalagem(false);
      setTara(dataTaraPadrao.data[0]);
      setCustos([]);
      setIsIndustrializado(false);
      setProdutoBase(null);
      setIsPodeEditarPreco(false);
      setPesoProvavel(0);
      setPesoCaixaNivel1(0);
      setCarregando(false);
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  function adaptarCustosRetornoApi(custos) {
    const newCustos = custos.map((item) => ({
      nome: item.nome,
      preco: item.preco,
      peso: item.peso,
    }));
    return newCustos;
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'produtos',
        ['id', 'asc'],
        { id: [id] },
        [
          'unidade',
          'categoria',
          'familia',
          'dadosBalanca.taraobj',
          'dadosImposto',
          'fornecedorPadrao',
          'locais',
          'fornecedores',
          'custos',
          'produtoBase',
        ],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setNome(data.data[0].nome);
        setCodigo(data.data[0].codigo);
        setUnidade(data.data[0].unidade);
        setCategoria(data.data[0].categoria);
        setEstoqueMinimo(data.data[0].estoque_minimo);
        setPesoMedio(data.data[0].peso_medio);
        setTecla(data.data[0].tecla);
        setPrecoVenda(data.data[0].preco_venda);
        setFamilia(data.data[0].familia);
        setPrecoCompraMedio(data.data[0].precoCompraMedioImpostos);
        setCodigoCaixaNivel1(data.data[0].codigo_caixa_nivel_1);
        setUnidadesCaixaNivel1(data.data[0].unidades_caixa_nivel_1);
        setCodigoCaixaNivel2(data.data[0].codigo_caixa_nivel_2);
        setUnidadesCaixaNivel2(data.data[0].unidades_caixa_nivel_2);
        setCodigoCaixaNivel3(data.data[0].codigo_caixa_nivel_3);
        setUnidadesCaixaNivel3(data.data[0].unidades_caixa_nivel_3);
        setLocais(data.data[0].locais);
        setFornecedores(data.data[0].fornecedores);
        setIpi(data.data[0].dadosImposto[0].ipi);
        setPis(data.data[0].dadosImposto[0].pis);
        setCofins(data.data[0].dadosImposto[0].cofins);
        setIcmsAi(data.data[0].dadosImposto[0].icms_ai);
        setIcmsInt(data.data[0].dadosImposto[0].icms_int);
        setDf(data.data[0].dadosImposto[0].df);
        setRbc(data.data[0].dadosImposto[0].rbc);
        setNcm(data.data[0].dadosImposto[0].ncm);
        setCst(data.data[0].dadosImposto[0].cst);
        setCfop(data.data[0].dadosImposto[0].cfop);
        setCstPisEntrada(data.data[0].dadosImposto[0].cst_pis_entrada);
        setCstPisSaida(data.data[0].dadosImposto[0].cst_pis_saida);
        setCstCofinsEntrada(data.data[0].dadosImposto[0].cst_cofins_entrada);
        setCstCofinsSaida(data.data[0].dadosImposto[0].cst_cofins_saida);
        setCest(data.data[0].dadosImposto[0].cest);
        setD1(data.data[0].dadosBalanca[0].d1);
        setDiasValidade(data.data[0].dadosBalanca[0].dias_validade);
        setExibirDiasValidade(
          data.data[0].dadosBalanca[0].exibir_dias_validade,
        );
        setExibirValidadeEmbalagem(
          data.data[0].dadosBalanca[0].exibir_data_embalagem,
        );
        setTara(data.data[0].dadosBalanca[0].taraobj);
        setCustos(recalcularIds(adaptarCustosRetornoApi(data.data[0].custos)));
        setLocais(data.data[0].locais);
        setIsIndustrializado(
          data.data[0].is_industrializado
            ? data.data[0].is_industrializado
            : false,
        );
        setProdutoBase(data.data[0].produtoBase);
        setIsPodeEditarPreco(false);
        setPesoMedio(data.data[0].peso_provavel);
        setPesoCaixaNivel1(data.data[0].peso_caixa_nivel_1);
        setCarregando(false);
      } else {
        throw 'Este produto não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  function getIdsOptions() {
    const ids = [];
    for (let i = 0; i < locais.length; i += 1) {
      ids.push(locais[i].id);
    }
    return ids;
  }

  function getIdsOptionsFornecedores() {
    const ids = [];
    for (let i = 0; i < fornecedores.length; i += 1) {
      ids.push(fornecedores[i].id);
    }
    return ids;
  }

  async function handleSalvar() {
    if (!erroExistente) {
      setCarregando(true);
      try {
        let idLocal = -1;
        if (id <= 0) {
          const produtoCriado = await createAPI('produtos_impostos_balancas', {
            nome,
            codigo,
            unidade_id: unidade.id,
            categoria_id: categoria.id,
            estoque_minimo: estoqueMinimo,
            peso_medio: pesoMedio,
            tecla,
            preco_venda: precoVenda,
            preco_revenda: precoVenda,
            familia_produto_id: familia ? familia.id : null,
            fornecedor_id: 1,
            desconto_caixa: 0,
            codigo_caixa_nivel_1:
              isPorPesoFixo || isPorUnidade || isPorPesoVariavel
                ? codigoCaixaNivel1 || null
                : null,
            unidades_caixa_nivel_1:
              isPorPesoFixo || isPorUnidade
                ? unidadesCaixaNivel1
                : isPorPesoVariavel
                ? 0
                : 0,
            preco_venda_caixa_nivel_1: 0,
            codigo_caixa_nivel_2:
              isPorPesoFixo || isPorUnidade || isPorPesoVariavel
                ? codigoCaixaNivel2 || null
                : null,
            unidades_caixa_nivel_2:
              isPorPesoFixo || isPorUnidade || isPorPesoVariavel
                ? unidadesCaixaNivel2
                : 0,
            preco_venda_caixa_nivel_2: 0,
            codigo_caixa_nivel_3:
              isPorPesoFixo || isPorUnidade || isPorPesoVariavel
                ? codigoCaixaNivel3
                : null,
            unidades_caixa_nivel_3:
              isPorPesoFixo || isPorUnidade || isPorPesoVariavel
                ? unidadesCaixaNivel3
                : 0,
            preco_venda_caixa_nivel_3: 0,
            locais: getIdsOptions(),
            fornecedores: getIdsOptionsFornecedores(),
            ipi,
            pis,
            cofins,
            icms_ai: icmsAi,
            icms_int: icmsInt,
            ncm,
            df,
            cst,
            cfop,
            rbc,
            cst_pis_entrada: cstPisEntrada,
            cst_pis_saida: cstPisSaida,
            cst_cofins_entrada: cstCofinsEntrada,
            cst_cofins_saida: cstCofinsSaida,
            cest,
            d1: !isPorPesoFixo && !isPorUnidade ? d1 : 'vazio',
            dias_validade: diasValidade,
            exibir_dias_validade: exibirDiasValidade,
            exibir_data_embalagem: exibirValidadeEmbalagem,
            tara_balanca_id: tara.id,
            custos,
            is_industrializado: isIndustrializado,
            produto_base_id: produtoBase ? produtoBase.id : null,
            peso_provavel: isPorPesoVariavel ? pesoProvavel : 0,
            peso_caixa_nivel_1: isPorPesoVariavel ? pesoCaixaNivel1 : 0,
            peso_caixa_nivel_2: 0,
            peso_caixa_nivel_3: 0,
          });
          setCarregando(false);
          if (produtoCriado.data.id) {
            enqueueSnackbar('Produto criado com sucesso!', {
              variant: 'success',
            });
            idLocal = produtoCriado.data.id;
          } else {
            enqueueSnackbar(produtoCriado.data, {
              variant: 'error',
            });
            idLocal = -1;
          }
        } else {
          const produtoAtualizado = await updateAPI(
            'produtos_impostos_balancas',
            id,
            {
              nome,
              codigo,
              unidade_id: unidade.id,
              categoria_id: categoria.id,
              estoque_minimo: estoqueMinimo,
              peso_medio: pesoMedio,
              tecla,
              preco_venda: precoVenda,
              preco_revenda: precoVenda,
              familia_produto_id: familia ? familia.id : null,
              fornecedor_id: 1,
              desconto_caixa: 0,
              codigo_caixa_nivel_1:
                isPorPesoFixo || isPorUnidade || isPorPesoVariavel
                  ? codigoCaixaNivel1 || null
                  : null,
              unidades_caixa_nivel_1:
                isPorPesoFixo || isPorUnidade
                  ? unidadesCaixaNivel1
                  : isPorPesoVariavel
                  ? 0
                  : 0,
              preco_venda_caixa_nivel_1: 0,
              codigo_caixa_nivel_2:
                isPorPesoFixo || isPorUnidade || isPorPesoVariavel
                  ? codigoCaixaNivel2 || null
                  : null,
              unidades_caixa_nivel_2:
                isPorPesoFixo || isPorUnidade || isPorPesoVariavel
                  ? unidadesCaixaNivel2
                  : 0,
              preco_venda_caixa_nivel_2: 0,
              codigo_caixa_nivel_3:
                isPorPesoFixo || isPorUnidade || isPorPesoVariavel
                  ? codigoCaixaNivel3
                  : null,
              unidades_caixa_nivel_3:
                isPorPesoFixo || isPorUnidade || isPorPesoVariavel
                  ? unidadesCaixaNivel3
                  : 0,
              preco_venda_caixa_nivel_3: 0,
              locais: getIdsOptions(),
              fornecedores: getIdsOptionsFornecedores(),
              ipi,
              pis,
              cofins,
              icms_ai: icmsAi,
              icms_int: icmsInt,
              ncm,
              df,
              cst,
              cfop,
              rbc,
              cst_pis_entrada: cstPisEntrada,
              cst_pis_saida: cstPisSaida,
              cst_cofins_entrada: cstCofinsEntrada,
              cst_cofins_saida: cstCofinsSaida,
              cest,
              d1: !isPorPesoFixo && !isPorUnidade ? d1 : 'vazio',
              dias_validade: diasValidade,
              exibir_dias_validade: exibirDiasValidade,
              exibir_data_embalagem: exibirValidadeEmbalagem,
              tara_balanca_id: tara.id,
              custos,
              is_industrializado: isIndustrializado,
              produto_base_id: produtoBase ? produtoBase.id : null,
              peso_provavel: isPorPesoVariavel ? pesoProvavel : 0,
              peso_caixa_nivel_1: isPorPesoVariavel ? pesoCaixaNivel1 : 0,
              peso_caixa_nivel_2: 0,
              peso_caixa_nivel_3: 0,
            },
          );
          setCarregando(false);
          if (produtoAtualizado.data.id) {
            enqueueSnackbar('Produto atualizado com sucesso!', {
              variant: 'success',
            });
            idLocal = id;
          } else {
            enqueueSnackbar(produtoAtualizado.data, {
              variant: 'error',
            });
            idLocal = -1;
          }
        }
        handleCloseDialog(idLocal);
      } catch (erros) {
        setCarregando(false);
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog(-1);
      }
    } else {
      enqueueSnackbar('Existem erros no formulário!', {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      setOpen(true);
      setCarregando(true);
      reiniciar();
      setValue(0);
      setIsPodeEditarPrecoConferenciaCaixaria(false);
    },
    handleCreateApartirFamilia(idFamilia) {
      setOpen(true);
      setCarregando(true);
      reiniciarApartirFamilia(idFamilia);
      setValue(0);
      setIsPodeEditarPrecoConferenciaCaixaria(false);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
      setValue(0);
      setIsPodeEditarPrecoConferenciaCaixaria(false);
    },
    handleEditConferenciaCaixaria(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
      setValue(0);
      setIsPodeEditarPrecoConferenciaCaixaria(true);
    },
  }));

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        verificaCaixaria();
        break;
      case CANCELAR_VENDA_ACTION:
        handleCloseDialog(-1);
        break;
      case ABA_GERAL:
        setValue(0);
        break;
      case ABA_BALANCA:
        if (!isPorPesoFixo && !isPorUnidade) {
          setValue(1);
        }
        break;
      case ABA_IMPOSTOS:
        if (!isPorPesoFixo && !isPorUnidade) {
          setValue(2);
        } else {
          setValue(1);
        }
        break;
      case DESBLOQUEAR_EDICAO_PRECO_VENDA:
        if (refDialogoSenha.current) {
          refDialogoSenha.current.handleOpen();
        }
        break;
      default:
        break;
    }
  }

  function handleKey(keyCode, keyName) {
    handleActions(keyName);
  }

  function getRefNextInputGeral(index) {
    let position = -1;
    switch (index) {
      case 0:
        position = 1;
        break;
      case 1:
        if (!disabledUnidade()) position = 2;
        else if (!disabledCategoria()) position = 3;
        else position = 4;
        break;
      case 2:
        if (!disabledCategoria()) position = 3;
        else position = 4;
        break;
      case 3:
        position = 4;
        break;
      case 4:
        position = 5;
        break;
      case 5:
        if (!disabledPesoMedio() && !hidePesoMedio()) position = 6;
        else position = 7;
        break;
      case 6:
        position = 7;
        break;
      case 7:
        position = 8;
        break;
      case 8:
        position = 9;
        break;
      case 9:
        position = 10;
        break;
      case 10:
        if (isPorPesoFixo || isPorUnidade) {
          position = 11;
        } else {
          position = 0;
        }
        break;
      case 11:
        position = 12;
        break;
      case 12:
        position = 13;
        break;
      case 13:
        position = 14;
        break;
      case 14:
        position = 15;
        break;
      case 15:
        position = 16;
        break;
      case 16:
        position = 17;
        break;
      case 17:
        position = 0;
        break;
      default:
        break;
    }
    return position;
  }

  function getRefNextInputBalanca(index) {
    let position = -1;
    switch (index) {
      case 0:
        position = 1;
        break;
      case 1:
        position = 2;
        break;
      case 2:
        position = 3;
        break;
      case 3:
        position = 4;
        break;
      case 4:
        position = 0;
        break;
      default:
        break;
    }
    return position;
  }

  function getRefNextInputImposto(index) {
    let position = -1;
    switch (index) {
      case 0:
        position = 1;
        break;
      case 1:
        position = 2;
        break;
      case 2:
        position = 3;
        break;
      case 3:
        position = 4;
        break;
      case 4:
        position = 5;
        break;
      case 5:
        position = 6;
        break;
      case 6:
        position = 7;
        break;
      case 7:
        position = 8;
        break;
      case 8:
        position = 9;
        break;
      case 9:
        position = 10;
        break;
      case 10:
        position = 11;
        break;
      case 11:
        position = 12;
        break;
      case 12:
        position = 13;
        break;
      case 13:
        position = 14;
        break;
      case 14:
        position = 0;
        break;
      default:
        break;
    }
    return position;
  }

  function handleNextInputGeral(index) {
    const position = getRefNextInputGeral(index);
    if (position === -1) {
      refsGeral.current[0].current.focus();
      refsGeral.current[0].current.select();
    } else if (refsGeral.current[position].current) {
      refsGeral.current[position].current.focus();
      refsGeral.current[position].current.select();
    }
  }

  function handleNextInputBalanca(index) {
    const position = getRefNextInputBalanca(index);
    if (position === -1) {
      refsBalanca.current[0].current.focus();
      refsBalanca.current[0].current.select();
    } else if (refsBalanca.current[position].current) {
      refsBalanca.current[position].current.focus();
      refsBalanca.current[position].current.select();
    }
  }

  function handleNextInputImpostos(index) {
    const position = getRefNextInputImposto(index);
    if (position === -1) {
      refsImposto.current[0].current.focus();
      refsImposto.current[0].current.select();
    } else if (refsImposto.current[position].current) {
      refsImposto.current[position].current.focus();
      refsImposto.current[position].current.select();
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function limparCodigo(codigo) {
    if (codigo) {
      const codigoSemEspacos = codigo.trim();
      return codigoSemEspacos.replace(/[^a-z0-9]/gi, '');
    }
    return '';
  }

  const lucroVenda = precoCompraMedio <= 0 ? 0 : precoVenda - precoCompraMedio;

  const margemLucroVenda =
    precoCompraMedio <= 0 ? 0 : (lucroVenda / precoCompraMedio) * 100;

  function criarCusto() {
    if (refDialogoCusto.current) {
      refDialogoCusto.current.handleCreate();
    }
  }

  function editarCusto(id, nome, preco, peso) {
    if (refDialogoCusto.current) {
      refDialogoCusto.current.handleEdit(id, nome, preco, peso);
    }
  }

  function handleSave(id, nome, preco, peso) {
    const newArray = custos.slice().map((item) => {
      if (item.id === id) {
        return {
          id,
          nome,
          preco,
          peso,
        };
      }
      return item;
    });
    if (id < 0) {
      newArray.push({
        id: custos.length + 1,
        nome,
        preco,
        peso,
      });
    }
    setCustos(recalcularIds(newArray));
  }

  function recalcularIds(arrayOld) {
    const newArray = [];
    for (let i = 0; i < arrayOld.length; i += 1) {
      newArray.push({
        nome: arrayOld[i].nome,
        preco: arrayOld[i].preco,
        peso: arrayOld[i].peso,
        id: i + 1,
      });
    }
    return newArray;
  }

  function deletarCusto(id, nome, preco, peso) {
    if (refDialogoDeletarCusto.current) {
      refDialogoDeletarCusto.current.handleDelete(id, nome, preco, peso);
    }
  }

  function handleDeletarCusto(id) {
    const newArray = custos.slice().filter((item) => item.id !== id);
    setCustos(recalcularIds(newArray));
  }

  function getNivelAtual() {
    if (unidadesCaixaNivel3 > 0) return 3;
    if (unidadesCaixaNivel2 > 0) return 2;
    if (unidadesCaixaNivel1 > 0) return 1;
    return 0;
  }

  function getNivelOferta(
    nUnidadeCaixaNivel1,
    nUnidadeCaixaNivel2,
    nUnidadeCaixaNivel3,
  ) {
    if (nUnidadeCaixaNivel3 > 0) return 3;
    if (nUnidadeCaixaNivel2 > 0) return 2;
    if (nUnidadeCaixaNivel1 > 0) return 1;
    return 0;
  }

  async function verificaCaixaria() {
    if (id > 0) {
      if (isPorPesoFixo || isPorUnidade) {
        const response = await api.get(`/verificar-oferta-nivel/${id}`);
        const {
          nUnidadeKg,
          nUnidadeUn,
          nUnidadeCaixaNivel1,
          nUnidadeCaixaNivel2,
          nUnidadeCaixaNivel3,
        } = response.data;

        const nivelOferta = getNivelOferta(
          nUnidadeCaixaNivel1,
          nUnidadeCaixaNivel2,
          nUnidadeCaixaNivel3,
        );

        const nivelAtual = getNivelAtual();

        if (nivelOferta > nivelAtual) {
          enqueueSnackbar(
            'Não é possível diminuir o nível de caixaria, pois existem ofertas ativas que usam esta caixaria, exclua ou espere terminar estas ofertas para poder diminuir o nível de caixaria!',
            {
              variant: 'error',
            },
          );
        } else {
          handleSalvar();
        }
      } else {
        handleSalvar();
      }
    } else {
      handleSalvar();
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
    >
      <DialogTitle id="form-dialog-title">
        {id > 0 ? 'Editar Produto' : 'Novo Produto'}
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ height: '100%' }}>
          <Box
            display={{ xs: 'block', sm: 'flex' }}
            flexDirection="column"
            marginBottom="30px"
            height="100%"
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Box p="1em">
                {!isPodeEditarPreco && (
                  <div
                    style={{
                      margin: '10px 20px',
                    }}
                  >{`Editar Caixarias (${DESBLOQUEAR_EDICAO_PRECO_VENDA})`}</div>
                )}
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab
                    label={`Geral (${ABA_GERAL}) ${
                      qtdeErrosGeral > 0 ? `- ${qtdeErrosGeral} Erros` : ''
                    }`}
                    {...a11yProps(0)}
                  />
                  {!isPorPesoFixo && !isPorUnidade && (
                    <Tab
                      label={`Balanca (${ABA_BALANCA}) ${
                        qtdeErrosBalanca > 0
                          ? `- ${qtdeErrosBalanca} Erros`
                          : ''
                      }`}
                      {...a11yProps(3)}
                    />
                  )}
                  <Tab
                    label={`Impostos (${ABA_IMPOSTOS}) ${
                      qtdeErrosImposto > 0 ? `- ${qtdeErrosImposto} Erros` : ''
                    }`}
                    {...a11yProps(2)}
                  />
                  {isIndustrializado && (
                    <Tab label="Custos" {...a11yProps(3)} />
                  )}
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Box display="flex">
                    <Box flex={2} mr="1em">
                      <Typography variant="h5" gutterBottom>
                        Especificação do produto
                      </Typography>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TextInput
                            name={inputsGeral[0].nome}
                            ref={refsGeral.current[NOME_POSITION]}
                            handleEnter={() =>
                              handleNextInputGeral(NOME_POSITION)
                            }
                            label={inputsGeral[0].label}
                            handleKey={handleKey}
                            value={nome}
                            onChange={(value) => setNome(value.target.value)}
                            error={errosGeral[0] !== ''}
                            helperText={errosGeral[0]}
                            fullWidth
                            autoFocus
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <TextInput
                            name={inputsGeral[1].nome}
                            ref={refsGeral.current[CODIGO_POSITION]}
                            handleEnter={() =>
                              handleNextInputGeral(CODIGO_POSITION)
                            }
                            label={inputsGeral[1].label}
                            handleKey={handleKey}
                            value={codigo}
                            onChange={(value) =>
                              setCodigo(limparCodigo(value.target.value))
                            }
                            error={errosGeral[1] !== ''}
                            helperText={errosGeral[1]}
                            fullWidth
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <AutoCompleteRemoto
                            name={inputsGeral[2].nome}
                            ref={refsGeral.current[UNIDADE_POSITION]}
                            handleEnter={() =>
                              handleNextInputGeral(UNIDADE_POSITION)
                            }
                            label={inputsGeral[2].label}
                            handleKey={handleKey}
                            value={unidade}
                            onChange={(value) => setUnidade(value)}
                            error={errosGeral[2] !== ''}
                            helperText={errosGeral[2]}
                            fullWidth
                            toString={false}
                            getOptionSelected={(option, value) =>
                              option.nome === value.nome
                            }
                            getOptionLabel={(option) => `${option.nome}`}
                            nested={[]}
                            filters={{ ativo: true }}
                            resource="unidades"
                            disabled={disabledUnidade()}
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <AutoCompleteRemoto
                            name={inputsGeral[3].nome}
                            ref={refsGeral.current[CATEGORIA_POSITION]}
                            handleEnter={() =>
                              handleNextInputGeral(CATEGORIA_POSITION)
                            }
                            label={inputsGeral[3].label}
                            handleKey={handleKey}
                            value={categoria}
                            onChange={(value) => setCategoria(value)}
                            error={errosGeral[3] !== ''}
                            helperText={errosGeral[3]}
                            fullWidth
                            toString={false}
                            getOptionSelected={(option, value) =>
                              option.nome === value.nome
                            }
                            getOptionLabel={(option) => `${option.nome}`}
                            nested={[]}
                            filters={{ ativo: true }}
                            resource="categorias"
                            disabled={disabledCategoria()}
                          />
                        </Box>

                        <Box flex={1} ml="0.5em">
                          <AutoCompleteRemoto
                            name={inputsGeral[9].nome}
                            ref={refsGeral.current[FAMILIA_POSITION]}
                            handleEnter={() =>
                              handleNextInputGeral(FAMILIA_POSITION)
                            }
                            label={inputsGeral[9].label}
                            handleKey={handleKey}
                            value={familia}
                            onChange={(value) => setFamilia(value)}
                            error={errosGeral[9] !== ''}
                            helperText={errosGeral[9]}
                            fullWidth
                            toString={false}
                            getOptionSelected={(option, value) =>
                              option.nome === value.nome
                            }
                            getOptionLabel={(option) => `${option.nome}`}
                            nested={[]}
                            filters={{ ativo: true }}
                            resource="familias_produtos"
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          {isPorUnidade || isPorPesoFixo ? (
                            <IntegerInput
                              name={inputsGeral[4].nome}
                              ref={refsGeral.current[ESTOQUE_MINIMO_POSITION]}
                              handleEnter={() =>
                                handleNextInputGeral(ESTOQUE_MINIMO_POSITION)
                              }
                              label={inputsGeral[4].label}
                              handleKey={handleKey}
                              value={
                                Number.isNaN(estoqueMinimo)
                                  ? ''
                                  : String(estoqueMinimo)
                              }
                              onChange={(value) =>
                                setEstoqueMinimo(
                                  parseInt(value.target.value, 10),
                                )
                              }
                              error={errosGeral[4] !== ''}
                              helperText={errosGeral[4]}
                              fullWidth
                            />
                          ) : (
                            <PesoInput
                              name={inputsGeral[4].nome}
                              ref={refsGeral.current[ESTOQUE_MINIMO_POSITION]}
                              handleEnter={() =>
                                handleNextInputGeral(ESTOQUE_MINIMO_POSITION)
                              }
                              label={inputsGeral[4].label}
                              handleKey={handleKey}
                              value={
                                Number.isNaN(estoqueMinimo)
                                  ? ''
                                  : String(estoqueMinimo)
                              }
                              onChange={(value) =>
                                setEstoqueMinimo(
                                  parseFloat(value.target.value, 10),
                                )
                              }
                              error={errosGeral[4] !== ''}
                              helperText={errosGeral[4]}
                              fullWidth
                            />
                          )}
                        </Box>
                        {!hidePesoMedio() && (
                          <Box flex={1} mr="0.5em" ml="0.5em">
                            <PesoInput
                              name={inputsGeral[5].nome}
                              ref={refsGeral.current[PESO_MEDIO_POSITION]}
                              handleEnter={() =>
                                handleNextInputGeral(PESO_MEDIO_POSITION)
                              }
                              label={inputsGeral[5].label}
                              handleKey={handleKey}
                              value={
                                Number.isNaN(pesoMedio) ? '' : String(pesoMedio)
                              }
                              onChange={(value) =>
                                setPesoMedio(parseFloat(value.target.value))
                              }
                              error={errosGeral[5] !== ''}
                              helperText={errosGeral[5]}
                              fullWidth
                              disabled={disabledPesoMedio()}
                            />
                          </Box>
                        )}
                        {isPorPesoVariavel && (
                          <Box flex={1} mr="0.5em" ml="0.5em">
                            <PesoInput
                              name={inputsGeral[19].nome}
                              ref={refsGeral.current[19]}
                              handleEnter={() => handleNextInputGeral(19)}
                              label={inputsGeral[19].label}
                              handleKey={handleKey}
                              value={
                                Number.isNaN(pesoProvavel)
                                  ? ''
                                  : String(pesoProvavel)
                              }
                              onChange={(value) =>
                                setPesoProvavel(parseFloat(value.target.value))
                              }
                              error={errosGeral[19] !== ''}
                              helperText={errosGeral[19]}
                              fullWidth
                            />
                          </Box>
                        )}
                        <Box flex={1} ml="0.5em">
                          <TeclaInput
                            name={inputsGeral[6].nome}
                            ref={refsGeral.current[TECLA_POSITION]}
                            handleEnter={() =>
                              handleNextInputGeral(TECLA_POSITION)
                            }
                            label={inputsGeral[6].label}
                            handleKey={handleKey}
                            value={tecla}
                            onChange={(value) => setTecla(value.target.value)}
                            error={errosGeral[6] !== ''}
                            helperText={errosGeral[6]}
                            fullWidth
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em" mt="16px" mb="8px">
                          <LocaisInput
                            name={inputsGeral[10].nome}
                            ref={refsGeral.current[FORNECEDOR_POSITION]}
                            handleEnter={() =>
                              handleNextInputGeral(FORNECEDOR_POSITION)
                            }
                            label={inputsGeral[10].label}
                            handleKey={handleKey}
                            fullWidth
                            options={fornecedoresOptions}
                            value={fornecedores}
                            onChange={setFornecedores}
                          />
                        </Box>
                        <Box flex={1} mr="0.5em" mt="16px" mb="8px">
                          <LocaisInput
                            name={inputsGeral[18].nome}
                            ref={refsGeral.current[LOCAIS_POSITION]}
                            handleEnter={() =>
                              handleNextInputGeral(LOCAIS_POSITION)
                            }
                            label={inputsGeral[18].label}
                            handleKey={handleKey}
                            fullWidth
                            options={locaisOptions}
                            value={locais}
                            onChange={setLocais}
                          />
                        </Box>

                        <Box flex={1} ml="0.5em">
                          <SwitchInput
                            name="is_industrializado"
                            handleEnter={() => {}}
                            label="Industrializado"
                            handleKey={handleKey}
                            checked={isIndustrializado}
                            onChange={(value) =>
                              setIsIndustrializado(value.target.checked)
                            }
                            fullWidth
                          />
                        </Box>
                      </Box>

                      <Box mt="1em" />

                      <Typography variant="h5" gutterBottom>
                        Dados de Venda
                      </Typography>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <PrecoInput
                            name={inputsGeral[7].nome}
                            ref={refsGeral.current[PRECO_VENDA_POSITION]}
                            handleEnter={() =>
                              handleNextInputGeral(PRECO_VENDA_POSITION)
                            }
                            label={inputsGeral[7].label}
                            handleKey={handleKey}
                            value={
                              Number.isNaN(precoVenda) ? '' : String(precoVenda)
                            }
                            onChange={(value) =>
                              setPrecoVenda(parseFloat(value.target.value))
                            }
                            error={errosGeral[7] !== ''}
                            helperText={errosGeral[7]}
                            disabled={!isPodeEditarPreco}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" mr="0.5em">
                          <PrecoInput
                            name="lucro venda"
                            handleEnter={() => {}}
                            label="Lucro Venda"
                            handleKey={handleKey}
                            value={lucroVenda}
                            onChange={() => {}}
                            fullWidth
                            disabled
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PorcentagemInput
                            name="margem lucro venda"
                            handleEnter={() => {}}
                            label="Margem Lucro Venda"
                            handleKey={handleKey}
                            value={margemLucroVenda}
                            onChange={() => {}}
                            fullWidth
                            disabled
                          />
                        </Box>
                      </Box>

                      {(isPorPesoFixo || isPorUnidade || isPorPesoVariavel) && (
                        <>
                          <Box mt="1em" />
                          <Typography variant="h6" gutterBottom>
                            Caixa (Nível 1)
                          </Typography>
                          <Box display="flex" mb="30px">
                            <Box flex={1} mr="0.5em">
                              <TextInput
                                name={inputsGeral[12].nome}
                                ref={refsGeral.current[CODIGO_CAIXA_NIVEL_1]}
                                handleEnter={() =>
                                  handleNextInputGeral(CODIGO_CAIXA_NIVEL_1)
                                }
                                label={inputsGeral[12].label}
                                handleKey={handleKey}
                                value={codigoCaixaNivel1}
                                onChange={(value) =>
                                  setCodigoCaixaNivel1(
                                    limparCodigo(value.target.value),
                                  )
                                }
                                error={errosGeral[12] !== ''}
                                helperText={errosGeral[12]}
                                fullWidth
                              />
                            </Box>
                            <Box flex={1} ml="0.5em" mr="0.5em" display="flex">
                              {!isPorPesoVariavel ? (
                                <IntegerInput
                                  name={inputsGeral[13].nome}
                                  ref={
                                    refsGeral.current[UNIDADES_CAIXA_NIVEL_1]
                                  }
                                  handleEnter={() =>
                                    handleNextInputGeral(UNIDADES_CAIXA_NIVEL_1)
                                  }
                                  label={inputsGeral[13].label}
                                  handleKey={handleKey}
                                  value={
                                    Number.isNaN(unidadesCaixaNivel1)
                                      ? ''
                                      : String(unidadesCaixaNivel1)
                                  }
                                  onChange={(value) =>
                                    setUnidadesCaixaNivel1(
                                      parseInt(value.target.value, 10),
                                    )
                                  }
                                  error={errosGeral[13] !== ''}
                                  helperText={errosGeral[13]}
                                  disabled={
                                    !isPodeEditarPreco &&
                                    !isPodeEditarPrecoConferenciaCaixaria
                                  }
                                  fullWidth
                                />
                              ) : (
                                <PesoInput
                                  name={inputsGeral[13].nome}
                                  ref={
                                    refsGeral.current[UNIDADES_CAIXA_NIVEL_1]
                                  }
                                  handleEnter={() =>
                                    handleNextInputGeral(UNIDADES_CAIXA_NIVEL_1)
                                  }
                                  label="Peso"
                                  handleKey={handleKey}
                                  value={
                                    Number.isNaN(pesoCaixaNivel1)
                                      ? ''
                                      : String(pesoCaixaNivel1)
                                  }
                                  onChange={(value) =>
                                    setPesoCaixaNivel1(
                                      parseFloat(value.target.value),
                                    )
                                  }
                                  error={errosGeral[13] !== ''}
                                  helperText={errosGeral[13]}
                                  disabled={
                                    !isPodeEditarPreco &&
                                    !isPodeEditarPrecoConferenciaCaixaria
                                  }
                                  fullWidth
                                />
                              )}
                            </Box>
                            <Box flex={1} ml="0.5em">
                              <PrecoInput
                                handleEnter={() => {}}
                                label="Preço de venda"
                                handleKey={handleKey}
                                value={
                                  Number.isNaN(precoVendaCaixaNivel1)
                                    ? ''
                                    : String(precoVendaCaixaNivel1)
                                }
                                onChange={() => {}}
                                fullWidth
                                disabled
                              />
                            </Box>
                          </Box>
                          <Typography variant="h6" gutterBottom>
                            Caixeta (Nível 2)
                          </Typography>
                          <Box display="flex" mb="30px">
                            <Box flex={1} mr="0.5em">
                              <TextInput
                                name={inputsGeral[14].nome}
                                ref={refsGeral.current[CODIGO_CAIXA_NIVEL_2]}
                                handleEnter={() =>
                                  handleNextInputGeral(CODIGO_CAIXA_NIVEL_2)
                                }
                                label={inputsGeral[14].label}
                                handleKey={handleKey}
                                value={codigoCaixaNivel2}
                                onChange={(value) =>
                                  setCodigoCaixaNivel2(
                                    limparCodigo(value.target.value),
                                  )
                                }
                                error={errosGeral[14] !== ''}
                                helperText={errosGeral[14]}
                                fullWidth
                              />
                            </Box>
                            <Box flex={1} ml="0.5em" mr="0.5em" display="flex">
                              <IntegerInput
                                name={inputsGeral[15].nome}
                                ref={refsGeral.current[UNIDADES_CAIXA_NIVEL_2]}
                                handleEnter={() =>
                                  handleNextInputGeral(UNIDADES_CAIXA_NIVEL_2)
                                }
                                label={inputsGeral[15].label}
                                handleKey={handleKey}
                                value={
                                  Number.isNaN(unidadesCaixaNivel2)
                                    ? ''
                                    : String(unidadesCaixaNivel2)
                                }
                                onChange={(value) =>
                                  setUnidadesCaixaNivel2(
                                    parseInt(value.target.value, 10),
                                  )
                                }
                                error={errosGeral[15] !== ''}
                                helperText={errosGeral[15]}
                                disabled={
                                  !isPodeEditarPreco &&
                                  !isPodeEditarPrecoConferenciaCaixaria
                                }
                                fullWidth
                              />
                            </Box>
                            <Box flex={1} ml="0.5em">
                              <PrecoInput
                                handleEnter={() => {}}
                                label="Preço de venda"
                                handleKey={handleKey}
                                value={
                                  Number.isNaN(precoVendaCaixaNivel2)
                                    ? ''
                                    : String(precoVendaCaixaNivel2)
                                }
                                onChange={() => {}}
                                fullWidth
                                disabled
                              />
                            </Box>
                          </Box>
                          <Typography variant="h6" gutterBottom>
                            Caixa-Mãe (Nível 3)
                          </Typography>
                          <Box display="flex">
                            <Box flex={1} mr="0.5em">
                              <TextInput
                                name={inputsGeral[16].nome}
                                ref={refsGeral.current[CODIGO_CAIXA_NIVEL_3]}
                                handleEnter={() =>
                                  handleNextInputGeral(CODIGO_CAIXA_NIVEL_3)
                                }
                                label={inputsGeral[16].label}
                                handleKey={handleKey}
                                value={codigoCaixaNivel3}
                                onChange={(value) =>
                                  setCodigoCaixaNivel3(
                                    limparCodigo(value.target.value),
                                  )
                                }
                                error={errosGeral[16] !== ''}
                                helperText={errosGeral[16]}
                                fullWidth
                              />
                            </Box>
                            <Box flex={1} ml="0.5em" mr="0.5em" display="flex">
                              <IntegerInput
                                name={inputsGeral[17].nome}
                                ref={refsGeral.current[UNIDADES_CAIXA_NIVEL_3]}
                                handleEnter={() =>
                                  handleNextInputGeral(UNIDADES_CAIXA_NIVEL_3)
                                }
                                label={inputsGeral[17].label}
                                handleKey={handleKey}
                                value={
                                  Number.isNaN(unidadesCaixaNivel3)
                                    ? ''
                                    : String(unidadesCaixaNivel3)
                                }
                                onChange={(value) =>
                                  setUnidadesCaixaNivel3(
                                    parseInt(value.target.value, 10),
                                  )
                                }
                                error={errosGeral[17] !== ''}
                                helperText={errosGeral[17]}
                                disabled={
                                  !isPodeEditarPreco &&
                                  !isPodeEditarPrecoConferenciaCaixaria
                                }
                                fullWidth
                              />
                            </Box>
                            <Box flex={1} ml="0.5em">
                              <PrecoInput
                                handleEnter={() => {}}
                                label="Preço de venda"
                                handleKey={handleKey}
                                value={
                                  Number.isNaN(precoVendaCaixaNivel3)
                                    ? ''
                                    : String(precoVendaCaixaNivel3)
                                }
                                onChange={() => {}}
                                fullWidth
                                disabled
                              />
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                </TabPanel>
                {!isPorPesoFixo && !isPorUnidade && (
                  <TabPanel value={value} index={1}>
                    <Box display="flex">
                      <Box flex={2} mr="1em">
                        <Typography variant="h6" gutterBottom>
                          Dados balança
                        </Typography>

                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <TextInput
                              name={inputsBalanca[0].nome}
                              ref={refsBalanca.current[D1_POSITION]}
                              handleEnter={() =>
                                handleNextInputBalanca(D1_POSITION)
                              }
                              label={inputsBalanca[0].label}
                              handleKey={handleKey}
                              value={d1}
                              onChange={(value) => setD1(value.target.value)}
                              error={errosBalanca[0] !== ''}
                              helperText={errosBalanca[0]}
                              fullWidth
                              autoFocus
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <IntegerInput
                              name={inputsBalanca[1].nome}
                              ref={refsBalanca.current[DIAS_VALIDADE_POSITION]}
                              handleEnter={() =>
                                handleNextInputBalanca(DIAS_VALIDADE_POSITION)
                              }
                              label={inputsBalanca[1].label}
                              handleKey={handleKey}
                              value={
                                Number.isNaN(diasValidade)
                                  ? ''
                                  : String(diasValidade)
                              }
                              onChange={(value) =>
                                setDiasValidade(
                                  parseInt(value.target.value, 10),
                                )
                              }
                              error={errosBalanca[1] !== ''}
                              helperText={errosBalanca[1]}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <AutoCompleteRemoto
                              name={inputsBalanca[2].nome}
                              ref={refsBalanca.current[TARA_POSITION]}
                              handleEnter={() =>
                                handleNextInputBalanca(TARA_POSITION)
                              }
                              label={inputsBalanca[2].label}
                              handleKey={handleKey}
                              value={tara}
                              onChange={(value) => setTara(value)}
                              error={errosBalanca[2] !== ''}
                              helperText={errosBalanca[2]}
                              fullWidth
                              toString={false}
                              getOptionSelected={(option, value) =>
                                option.nome === value.nome
                              }
                              getOptionLabel={(option) => `${option.nome}`}
                              nested={[]}
                              filters={{}}
                              resource="taras_balanca"
                              disabled={false}
                            />
                          </Box>
                        </Box>
                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <SwitchInput
                              name={inputsBalanca[3].nome}
                              ref={
                                refsBalanca.current[
                                  EXIBIR_DIAS_VALIDADE_POSITION
                                ]
                              }
                              handleEnter={() =>
                                handleNextInputBalanca(
                                  EXIBIR_DIAS_VALIDADE_POSITION,
                                )
                              }
                              label={inputsBalanca[3].label}
                              handleKey={handleKey}
                              checked={exibirDiasValidade}
                              onChange={(value) =>
                                setExibirDiasValidade(value.target.checked)
                              }
                              error={errosBalanca[3] !== ''}
                              helperText={errosBalanca[3]}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} mr="0.5em">
                            <SwitchInput
                              name={inputsBalanca[4].nome}
                              ref={
                                refsBalanca.current[
                                  EXIBIR_VALIDADE_EMBALAGEM_POSITION
                                ]
                              }
                              handleEnter={() =>
                                handleNextInputBalanca(
                                  EXIBIR_VALIDADE_EMBALAGEM_POSITION,
                                )
                              }
                              label={inputsBalanca[4].label}
                              handleKey={handleKey}
                              checked={exibirValidadeEmbalagem}
                              onChange={(value) =>
                                setExibirValidadeEmbalagem(value.target.checked)
                              }
                              error={errosBalanca[4] !== ''}
                              helperText={errosBalanca[4]}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} ml="0.5em" />
                        </Box>
                      </Box>
                    </Box>
                  </TabPanel>
                )}
                <TabPanel
                  value={value}
                  index={isPorPesoFixo || isPorUnidade ? 1 : 2}
                >
                  <Box display="flex">
                    <Box flex={2} mr="1em">
                      <Typography variant="h6" gutterBottom>
                        Impostos
                      </Typography>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <PorcentagemInput
                            name={inputsImpostos[0].nome}
                            ref={refsImposto.current[IPI_POSITION]}
                            handleEnter={() =>
                              handleNextInputImpostos(IPI_POSITION)
                            }
                            label={inputsImpostos[0].label}
                            handleKey={handleKey}
                            value={Number.isNaN(ipi) ? '' : String(ipi)}
                            onChange={(value) =>
                              setIpi(parseFloat(value.target.value))
                            }
                            error={errosImpostos[0] !== ''}
                            helperText={errosImpostos[0]}
                            fullWidth
                            escala={2}
                            autoFocus
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PorcentagemInput
                            name={inputsImpostos[1].nome}
                            ref={refsImposto.current[PIS_POSITION]}
                            handleEnter={() =>
                              handleNextInputImpostos(PIS_POSITION)
                            }
                            label={inputsImpostos[1].label}
                            handleKey={handleKey}
                            value={Number.isNaN(pis) ? '' : String(pis)}
                            onChange={(value) =>
                              setPis(parseFloat(value.target.value))
                            }
                            error={errosImpostos[1] !== ''}
                            helperText={errosImpostos[1]}
                            fullWidth
                            escala={2}
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PorcentagemInput
                            name={inputsImpostos[2].nome}
                            ref={refsImposto.current[COFINS_POSITION]}
                            handleEnter={() =>
                              handleNextInputImpostos(COFINS_POSITION)
                            }
                            label={inputsImpostos[2].label}
                            handleKey={handleKey}
                            value={Number.isNaN(cofins) ? '' : String(cofins)}
                            onChange={(value) =>
                              setCofins(parseFloat(value.target.value))
                            }
                            error={errosImpostos[2] !== ''}
                            helperText={errosImpostos[2]}
                            fullWidth
                            escala={2}
                          />
                        </Box>
                      </Box>
                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <PorcentagemInput
                            name={inputsImpostos[3].nome}
                            ref={refsImposto.current[ICMS_AI_POSITION]}
                            handleEnter={() =>
                              handleNextInputImpostos(ICMS_AI_POSITION)
                            }
                            label={inputsImpostos[3].label}
                            handleKey={handleKey}
                            value={Number.isNaN(icmsAi) ? '' : String(icmsAi)}
                            onChange={(value) =>
                              setIcmsAi(parseFloat(value.target.value))
                            }
                            error={errosImpostos[3] !== ''}
                            helperText={errosImpostos[3]}
                            fullWidth
                            escala={2}
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PorcentagemInput
                            name={inputsImpostos[4].nome}
                            ref={refsImposto.current[ICMS_INT_POSITION]}
                            handleEnter={() =>
                              handleNextInputImpostos(ICMS_INT_POSITION)
                            }
                            label={inputsImpostos[4].label}
                            handleKey={handleKey}
                            value={Number.isNaN(icmsInt) ? '' : String(icmsInt)}
                            onChange={(value) =>
                              setIcmsInt(parseFloat(value.target.value))
                            }
                            error={errosImpostos[4] !== ''}
                            helperText={errosImpostos[4]}
                            fullWidth
                            escala={2}
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <PorcentagemInput
                            name={inputsImpostos[5].nome}
                            ref={refsImposto.current[DF_POSITION]}
                            handleEnter={() =>
                              handleNextInputImpostos(DF_POSITION)
                            }
                            label={inputsImpostos[5].label}
                            handleKey={handleKey}
                            value={Number.isNaN(df) ? '' : String(df)}
                            onChange={(value) =>
                              setDf(parseFloat(value.target.value))
                            }
                            error={errosImpostos[5] !== ''}
                            helperText={errosImpostos[5]}
                            fullWidth
                            escala={2}
                          />
                        </Box>
                      </Box>
                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <PorcentagemInput
                            name={inputsImpostos[6].nome}
                            ref={refsImposto.current[RBC_POSITION]}
                            handleEnter={() =>
                              handleNextInputImpostos(RBC_POSITION)
                            }
                            label={inputsImpostos[6].label}
                            handleKey={handleKey}
                            value={Number.isNaN(rbc) ? '' : String(rbc)}
                            onChange={(value) =>
                              setRbc(parseFloat(value.target.value))
                            }
                            error={errosImpostos[6] !== ''}
                            helperText={errosImpostos[6]}
                            fullWidth
                            escala={2}
                          />
                        </Box>
                        <Box flex={1} mr="0.5em" />
                        <Box flex={1} mr="0.5em" />
                      </Box>

                      <Typography variant="h6" gutterBottom>
                        Códigos
                      </Typography>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <NcmInput
                            name={inputsImpostos[7].nome}
                            ref={refsImposto.current[NCM_POSITION]}
                            handleEnter={() =>
                              handleNextInputImpostos(NCM_POSITION)
                            }
                            label={inputsImpostos[7].label}
                            handleKey={handleKey}
                            value={ncm}
                            onChange={(value) => setNcm(value.target.value)}
                            error={errosImpostos[7] !== ''}
                            helperText={errosImpostos[7]}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <CstInput
                            name={inputsImpostos[8].nome}
                            ref={refsImposto.current[CST_POSITION]}
                            handleEnter={() =>
                              handleNextInputImpostos(CST_POSITION)
                            }
                            label={inputsImpostos[8].label}
                            handleKey={handleKey}
                            value={cst}
                            onChange={(value) => setCst(value.target.value)}
                            error={errosImpostos[8] !== ''}
                            helperText={errosImpostos[8]}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <CfopInput
                            name={inputsImpostos[9].nome}
                            ref={refsImposto.current[CFOP_POSITION]}
                            handleEnter={() =>
                              handleNextInputImpostos(CFOP_POSITION)
                            }
                            label={inputsImpostos[9].label}
                            handleKey={handleKey}
                            value={cfop}
                            onChange={(value) => setCfop(value.target.value)}
                            error={errosImpostos[9] !== ''}
                            helperText={errosImpostos[9]}
                            fullWidth
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <CstInput
                            name={inputsImpostos[10].nome}
                            ref={refsImposto.current[CST_PIS_ENTRADA_POSITION]}
                            handleEnter={() =>
                              handleNextInputImpostos(CST_PIS_ENTRADA_POSITION)
                            }
                            label={inputsImpostos[10].label}
                            handleKey={handleKey}
                            value={cstPisEntrada}
                            onChange={(value) =>
                              setCstPisEntrada(value.target.value)
                            }
                            error={errosImpostos[10] !== ''}
                            helperText={errosImpostos[10]}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <CstInput
                            name={inputsImpostos[11].nome}
                            ref={refsImposto.current[CST_PIS_SAIDA_POSITION]}
                            handleEnter={() =>
                              handleNextInputImpostos(CST_PIS_SAIDA_POSITION)
                            }
                            label={inputsImpostos[11].label}
                            handleKey={handleKey}
                            value={cstPisSaida}
                            onChange={(value) =>
                              setCstPisSaida(value.target.value)
                            }
                            error={errosImpostos[11] !== ''}
                            helperText={errosImpostos[11]}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <CstInput
                            name={inputsImpostos[12].nome}
                            ref={
                              refsImposto.current[CST_COFINS_ENTRADA_POSITION]
                            }
                            handleEnter={() =>
                              handleNextInputImpostos(
                                CST_COFINS_ENTRADA_POSITION,
                              )
                            }
                            label={inputsImpostos[12].label}
                            handleKey={handleKey}
                            value={cstCofinsEntrada}
                            onChange={(value) =>
                              setCstCofinsEntrada(value.target.value)
                            }
                            error={errosImpostos[12] !== ''}
                            helperText={errosImpostos[12]}
                            fullWidth
                          />
                        </Box>
                      </Box>

                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <CstInput
                            name={inputsImpostos[13].nome}
                            ref={refsImposto.current[CST_COFINS_SAIDA_POSITION]}
                            handleEnter={() =>
                              handleNextInputImpostos(CST_COFINS_SAIDA_POSITION)
                            }
                            label={inputsImpostos[13].label}
                            handleKey={handleKey}
                            value={cstCofinsSaida}
                            onChange={(value) =>
                              setCstCofinsSaida(value.target.value)
                            }
                            error={errosImpostos[13] !== ''}
                            helperText={errosImpostos[13]}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <CestInput
                            name={inputsImpostos[14].nome}
                            ref={refsImposto.current[CEST_POSITION]}
                            handleEnter={() =>
                              handleNextInputImpostos(CEST_POSITION)
                            }
                            label={inputsImpostos[14].label}
                            handleKey={handleKey}
                            value={cest}
                            onChange={(value) => setCest(value.target.value)}
                            error={errosImpostos[14] !== ''}
                            helperText={errosImpostos[14]}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml="0.5em" />
                      </Box>
                    </Box>
                  </Box>
                </TabPanel>

                {isIndustrializado && (
                  <TabPanel
                    value={value}
                    index={!isPorPesoFixo && !isPorUnidade ? 3 : 2}
                  >
                    <Box>
                      <Box>
                        <AutoCompleteRemoto
                          name="produtoBase"
                          handleEnter={() => {}}
                          label="Produto Base"
                          handleKey={handleKey}
                          value={produtoBase}
                          onChange={(value) => setProdutoBase(value)}
                          fullWidth
                          toString={false}
                          getOptionSelected={(option, value) =>
                            option.nome === value.nome
                          }
                          getOptionLabel={(option) => `${option.nome}`}
                          filters={{ ativo: true }}
                          resource="produtos/search/autocomplete"
                          nested={['unidade']}
                        />
                      </Box>
                      <Box mt="1em" />

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          Custos
                        </Typography>
                        <Button
                          onClick={() => criarCusto()}
                          variant="contained"
                          color="primary"
                        >
                          Adicionar
                        </Button>
                      </div>
                      <Box mt="1em" />

                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Nome</StyledTableCell>
                              <StyledTableCell align="right">
                                Valor Unitário
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Qtde
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Total
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Ações
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {custos.map((row) => {
                              const { nome, preco, peso } = row;
                              return (
                                <StyledTableRow key={row.id}>
                                  <StyledTableCell component="th" scope="row">
                                    {nome}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {formatMoeda(preco)}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {peso}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {formatMoeda(preco * peso)}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    <Tooltip
                                      title="Editar"
                                      placement="bottom"
                                      aria-label="add2"
                                    >
                                      <IconButton
                                        aria-label="edit"
                                        classes={{ root: classes.root }}
                                        size="small"
                                        onClick={() =>
                                          editarCusto(
                                            row.id,
                                            row.nome,
                                            row.preco,
                                            row.peso,
                                          )
                                        }
                                      >
                                        <EditOutlinedIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      title="Deletar"
                                      placement="bottom"
                                      aria-label="add2"
                                    >
                                      <IconButton
                                        aria-label="delete"
                                        classes={{ root: classes.root }}
                                        size="small"
                                        onClick={() =>
                                          deletarCusto(
                                            row.id,
                                            row.nome,
                                            row.preco,
                                            row.peso,
                                          )
                                        }
                                      >
                                        <DeleteOutlineIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </TabPanel>
                )}
              </Box>
            )}
          </Box>
        </div>
        <DialogoSenha
          ref={refDialogoSenha}
          handleCancelar={() => {}}
          handleSalvar={() => {
            setIsPodeEditarPreco(true);
          }}
          title="Autorização editar preços caixaria"
          enqueueSnackbar={enqueueSnackbar}
          mensagemSucesso="Edição do preço das caixarias!!"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCloseDialog(-1)} color="primary">
          Cancelar (F4)
        </Button>
        <div className={classes.wrapper}>
          <Button
            color="primary"
            onClick={() => {
              verificaCaixaria();
            }}
            disabled={erroExistente || carregando}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
          >
            Cadastrar (F8)
          </Button>
          {carregando && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
      <DialogoCreateEditCusto
        ref={refDialogoCusto}
        handleClose={() => {}}
        handleSave={handleSave}
      />
      <DialogoDeletarCusto
        ref={refDialogoDeletarCusto}
        handleClose={() => {}}
        handleDelete={handleDeletarCusto}
      />
    </Dialog>
  );
});

export default SidebarInputs;
